import { Box, Input, MenuItem, Select, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Team, TeamCreate } from 'Settings/interfaces/Teams';
import {
  useCreateTeamMutation,
  useUpdateTeamMutation,
} from 'Settings/store/api';
import FormattedMessage from 'shared/components/FormattedMessage';
import { useTranslation } from 'react-i18next';
import { CommunicationApp } from '../enums/communication-app.enum';
import { ApplicationPermission } from '../../../../shared/enums/permission.enum';
import { AuthorizedContent } from '@frontegg/react';

interface Props {
  onClose: (shouldReload?: boolean) => void;
  team?: Team | null;
  onSave?: boolean;
  setOnSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function TeamDialogContent({
  onClose,
  team,
  onSave,
  setOnSave,
  setIsLoading,
}: Props) {
  const { t: translation } = useTranslation();
  const formElement = useRef<HTMLFormElement>(null);

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const [
    createTeam,
    { isLoading: isLoadingCreate, isSuccess: isCreated, isError: isNotCreated },
  ] = useCreateTeamMutation();
  const [
    updateTeam,
    { isLoading: isLoadingUpdate, isSuccess: isUpdated, isError: isNotUpdated },
  ] = useUpdateTeamMutation();

  const isLoading = isLoadingCreate || isLoadingUpdate;

  useEffect(() => {
    if (isCreated || isUpdated) {
      onClose(true);
      setOnSave(false);
    }
  }, [isCreated, isUpdated]);

  useEffect(() => {
    if (isNotUpdated || isNotCreated) {
      setOnSave(false);
    }
  }, [isNotCreated, isNotUpdated]);

  useEffect(() => {
    if (onSave) handleSubmit();
  }, [onSave]);

  async function handleSubmit() {
    const form = formElement.current;
    if (!form) return;

    const formData = new FormData(form);
    const item: TeamCreate = {
      name: formData.get('name') as string,
      communicationAppId:
        (formData.get('communicationAppId') as string) || undefined,
      communicationChannelId: formData.get('communicationChannelId') as string,
      owner: formData.get('owner') as string,
    };

    const errors = validateFormFields(item);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setOnSave(false);
    } else {
      setFormErrors({});
      team ? updateTeam({ id: team.id, item }) : createTeam(item);
    }
  }

  function validateFormFields(item: TeamCreate) {
    const errors: { [key: string]: string } = {};
    if (!item.name) {
      errors['name'] = translation('settings.details.requiredField', {
        param1: translation('settings.details.typeTeamName'),
      });
    }
    if (!item.owner) {
      errors['owner'] = translation('settings.details.requiredField', {
        param1: translation('settings.details.typeTeamOwner'),
      });
    }
    if (!item.communicationChannelId) {
      errors['communicationChannelId'] = translation(
        'settings.details.requiredField',
        {
          param1: translation('settings.details.communicationChannel'),
        }
      );
    }

    return errors;
  }

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <Box minWidth={'500px'} mb={3}>
      <form ref={formElement} onSubmit={handleSubmit}>
        <Typography className="input-label">
          <FormattedMessage
            id="settings.details.teamName"
            defaultMessage="Team Name"
          />
        </Typography>
        <Input
          fullWidth
          disableUnderline
          required
          className="input-dialog"
          name="name"
          placeholder={translation(`settings.details.typeTeamName`)}
          defaultValue={team?.name}
        />
        {formErrors['name'] && (
          <Typography variant="caption" color="error">
            {formErrors['name']}
          </Typography>
        )}
        <Box mb={3} />

        <Typography className="input-label">
          <FormattedMessage
            id="settings.details.teamOwner"
            defaultMessage="Team Owner"
          />
        </Typography>
        <Input
          fullWidth
          disableUnderline
          required
          className="input-dialog"
          name="owner"
          placeholder={translation(`settings.details.typeTeamOwner`)}
          defaultValue={team?.owner}
        />
        {formErrors['owner'] && (
          <Typography variant="caption" color="error">
            {formErrors['owner']}
          </Typography>
        )}
        <Box mb={3} />

        <Typography className="input-label">
          <FormattedMessage
            id="settings.details.communicationApp"
            defaultMessage="Communication App"
          />
        </Typography>
        <Select
          fullWidth
          className="input-dialog select-input"
          defaultValue={team?.communicationAppId}
          name="communicationAppId"
        >
          {Object.keys(CommunicationApp).map((appKey) => (
            <MenuItem key={appKey} value={appKey}>
              {CommunicationApp[appKey as keyof typeof CommunicationApp]}
            </MenuItem>
          ))}
        </Select>
        <Box mb={3} />

        <Typography className="input-label">
          <FormattedMessage
            id="settings.details.communicationChannel"
            defaultMessage="Communication Channel"
          />
        </Typography>
        <Input
          fullWidth
          disableUnderline
          required
          className="input-dialog"
          name="communicationChannelId"
          placeholder={translation(`settings.details.typeCommunicationChannel`)}
          defaultValue={team?.communicationChannelId}
        />
        {formErrors['communicationChannelId'] && (
          <Typography variant="caption" color="error">
            {formErrors['communicationChannelId']}
          </Typography>
        )}
      </form>
    </Box>
  );
}

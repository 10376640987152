import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import FlowItem from 'Flow/interfaces/item';
import { useEffect, useMemo, useRef, useState } from 'react';
import CommonIconMui from '../../../Common/components/IconMui';
import { useNavigate } from 'react-router-dom';
import {
  useGetExportFlowContentMutation,
  useImportFlowMutation,
  useSearchFlowsMutation,
} from '../../store/api';
import { useTranslation } from 'react-i18next';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import { getWorkflowGridColumns } from 'shared/fixtures/data/workflows.data';
import CommonPagination from 'Common/components/Pagination';
import { capitalize, debounce } from 'lodash';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { OrderState, OrderType } from 'Common/utils/sort';
import {
  AgGridEvent,
  GetRowIdParams,
  RowClickedEvent,
} from 'ag-grid-community';
import {
  useSaveWorkflowMutation,
  useUpdateWorkflowMutation,
} from 'WorkflowBuilder/store/api';
import {
  WorkflowDefinition,
  WorkflowDefinitionStatus,
} from 'shared/models/data/workflows.model';
import InfoDialog from 'Common/components/Dialogs/InfoDialog';

interface ErrorDataContent {
  statusCode: number;
  message: string;
}

interface ImportErrorResponse {
  status: number;
  data: ErrorDataContent;
}

interface DialogIcon {
  backgroundColor: string;
  element: JSX.Element;
}

interface DialogState {
  isDialogOpen: boolean;
  bodyTitle?: string;
  message?: string;
  iconConfig?: DialogIcon;
}

export default function FlowTable() {
  const { t: translation } = useTranslation();

  const gridRef = useRef<AgGridEvent | null>(null);

  const [searchFlows, { data: flowsPayload, isLoading }] =
    useSearchFlowsMutation();

  const [updateWorkflow, { isSuccess: isWorkflowUpdateSuccess }] =
    useUpdateWorkflowMutation();

  const [exportFlow, { data: exportContent }] =
    useGetExportFlowContentMutation();

  const [
    importFlow,
    { isLoading: isUploading, isSuccess, error: importError, data: newFlow },
  ] = useImportFlowMutation();

  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const [orderParams, setOrderParams] = useState<OrderState>({
    type: OrderType.DESC,
    field: 'createdAt',
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState<DialogState>({
    isDialogOpen: false,
  });

  const searchFlowsHandler = () => {
    searchFlows({
      order: orderParams,
      take: pageSize,
      skip: currentPage * pageSize,
      filter: {
        name: searchKeyword,
      },
    });
  };

  useEffect(() => {
    if (isWorkflowUpdateSuccess) searchFlowsHandler();
  }, [isWorkflowUpdateSuccess]);

  useEffect(() => {
    searchFlowsHandler();
  }, [orderParams, currentPage, pageSize, searchKeyword]);

  useMemo(() => {
    if (importError) {
      let errorMessage = (importError as ImportErrorResponse).data.message;
      // Couldn't detect message, set a generic error message
      if (!errorMessage)
        errorMessage = translation(`flows.details.failedImport`);
      // Open error dialog
      setDialogState({
        isDialogOpen: true,
        message: errorMessage,
        bodyTitle: translation(`flows.details.importError`),
        iconConfig: {
          backgroundColor: '#FFE9DA',
          element: <CommonIconMui icon="warning" size={40} color="#FF8227" />,
        },
      });
    } else if (isSuccess) {
      setDialogState({
        isDialogOpen: true,
        bodyTitle: translation(`flows.details.importDone`),
        message: translation(`flows.details.uploadedSuccessfully`, {
          param1: newFlow?.name,
        }),
        iconConfig: {
          backgroundColor: '#CEEEE0',
          element: <CommonIconMui icon="done" size={40} color="#23CD9C" />,
        },
      });
    }
  }, [importError, isSuccess]);

  function onDialogClose() {
    setDialogState({ isDialogOpen: false });
  }

  async function importHandler(e: any) {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      if (e.target) importFlow(e.target.result as object);
    };
  }

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber - 1);
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const onSearchKeywordChange = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword);
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    setOrderParams(columnOrderPayload[0]);
  };

  const onHandleImport = () => {
    const importInput = document.getElementById('workflow-import-input');

    importInput?.click();
  };

  const onUpdateWorkflowStatus = (
    status: WorkflowDefinitionStatus,
    workflowData: WorkflowDefinition
  ) => {
    updateWorkflow({
      id: workflowData.id,
      data: {
        status,
      },
    });
  };

  function downloadFile(content: any) {
    const blob = new Blob([JSON.stringify(exportContent)], {
      type: 'application/json',
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${content?.portableWorkflowDef?.name
      .replaceAll(' ', '_')
      .toLowerCase()}_${content['portableWorkflowDef']['version']}_export.json`;
    link.href = url;
    link.click();
  }

  useEffect(() => {
    if (exportContent) {
      downloadFile(exportContent);
    }
  }, [exportContent]);

  const onExportWorkflow = (id: string) => {
    exportFlow(id);
  };

  const onDeleteWorkflowCallback = (isDeleted: boolean) => {
    if (isDeleted) searchFlowsHandler();
  };

  return (
    <div className="flow-page-container">
      <HeaderComponent text="My Flows" />
      <div className="flow-page-body">
        <div className="flow-page-body-filters">
          <TextField
            InputProps={{
              startAdornment: (
                <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
              ),
            }}
            placeholder={capitalize(translation('common.search'))}
            className="flow-page-body-filters-search-input search-input"
            onChange={(event) => {
              debounce(onSearchKeywordChange, 200)(event.target.value);
            }}
          />

          <div className="flow-page-body-filters-end-buttons">
            <input
              id="workflow-import-input"
              type="file"
              hidden
              onChange={importHandler}
              accept=".json"
            />
            <IconButton
              className="flow-page-body-filters-import-button"
              onClick={onHandleImport}
              title="Import"
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_UP_ICON} />
            </IconButton>
            <Button
              className="opus-primary-button flow-page-body-filters-add-button"
              onClick={() => {
                navigate('/builder');
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />
              Add Flow
            </Button>
          </div>
        </div>
        <div className="flow-page-body-table">
          <CommonSimpleDataGrid
            gridRef={gridRef}
            columnDefs={getWorkflowGridColumns(
              {
                statusUpdateHandler: onUpdateWorkflowStatus,
                deleteHandler: onDeleteWorkflowCallback,
                exportHandler: onExportWorkflow,
              },
              translation
            )}
            rowData={flowsPayload?.data}
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            otherComponents={{
              NoDataBackdropComponent: (
                <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
              ),
            }}
            loadingOverlayComponent={() => <CircularProgress />}
            isLoading={isLoading}
            getRowId={(row: GetRowIdParams) => {
              return row.data.id;
            }}
            onSort={onHandleColumnOrderChange}
            onRowClicked={(event: RowClickedEvent) => {
              const shouldIgnoreElement = Boolean(
                event.eventPath?.find((value: EventTarget) =>
                  (value as Element)?.classList?.contains('ignore-row-click')
                )
              );

              if (shouldIgnoreElement) {
                return;
              }
              navigate(`/builder/${event.data.familyId}`);
            }}
          />
          <CommonPagination
            totalItems={flowsPayload?.totalItems || 0}
            currentPage={currentPage + 1}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
          />

          <InfoDialog
            dialogTitle={translation(`flows.details.importFlow`)}
            bodyTitle={dialogState.bodyTitle}
            iconConfiguration={dialogState.iconConfig}
            message={dialogState.message}
            isDialogOpen={dialogState.isDialogOpen}
            onClose={onDialogClose}
          />
        </div>
      </div>
    </div>
  );
}

import { Stack } from '@mui/material';

interface IconWrapperProps {
  children: JSX.Element;
  title?: string;
  className?: string;
}

export const IconWrapper = ({
  title,
  children,
  className,
}: IconWrapperProps): JSX.Element => {
  return (
    <Stack title={title} className={className}>
      {children}
    </Stack>
  );
};

import { ButtonBase, useTheme } from '@mui/material';
import { MouseEvent } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface Props {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  variant?: 'contained' | 'outlined';
  readonly?: boolean;
}

export default function DiagramPlusButton({
  onClick = () => {},
  variant = 'contained',
  readonly,
}: Props) {
  return (
    <ButtonBase
      onClick={(event: MouseEvent<HTMLButtonElement>) => {
        !readonly && onClick(event);
      }}
      className={`workflow-builder-plus-button ${
        readonly ? 'workflow-builder-plus-button-readonly' : ''
      } ${!readonly ? 'workflow-builder-plus-button-border' : ''}`}
    >
      {!readonly && <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />}
    </ButtonBase>
  );
}

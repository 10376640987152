import { IconButton, Switch } from '@mui/material';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { useDeleteFlowMutation } from 'Flow/store/api';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { capitalize } from 'lodash';
import moment from 'moment';
import CommonIconCell from 'shared/components/CommonIconCell';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { WorkflowDefinitionStatus } from 'shared/models/data/workflows.model';
import { dateTimeFormat } from 'shared/services/date.service';

export const getWorkflowGridColumns = (
  handlers: {
    statusUpdateHandler: any;
    exportHandler: any;
    deleteHandler: any;
  },
  translation: any
): Array<ColDef> => [
  {
    field: 'name',
    headerName: 'Flow Title',
    initialFlex: 3,
    cellRenderer: (params: any) => {
      const name = params.data.name;

      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          maxWidth={200}
        >
          {name}
        </TextOverflow>
      );
    },
  },
  {
    field: 'lastRunTime',
    headerName: 'Last Run Time',
    initialFlex: 4,
    cellRenderer: (params: any) => {
      return params.data.lastRunTime ? (
        moment(params.data.lastRunTime).format(dateTimeFormat)
      ) : (
        <></>
      );
    },
  },
  {
    field: 'solutionType',
    headerName: 'Type',
    initialFlex: 2,
    cellRenderer: (params: any) => capitalize(params.data.solutionType || ''),
  },
  {
    field: 'lastEditedBy',
    headerName: 'Last Edited By',
    initialFlex: 3,
    cellRenderer: (params: any) => {
      const lastEditedBy = params.data.lastEditedBy;

      return lastEditedBy ? (
        <CommonIconCell
          label={lastEditedBy.fullName}
          maxWidth={100}
          iconUrl={lastEditedBy.logoUrl}
        />
      ) : (
        <></>
      );
    },
  },
  {
    field: 'updatedAt',
    headerName: 'Last Edited',
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return params.data.updatedAt ? (
        moment(params.data.updatedAt).format(dateTimeFormat)
      ) : (
        <></>
      );
    },
  },
  {
    field: 'executionCount',
    headerName: '# Executions',
    initialFlex: 2,
  },
  {
    field: 'status',
    headerName: '',
    initialFlex: 1,
    sortable: false,
    suppressMovable: true,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => {
      const isSwitched =
        params.data.status === WorkflowDefinitionStatus.ENABLED;

      return (
        <Switch
          title="Update status"
          checked={isSwitched}
          onChange={() => {}}
          classes={{
            root: 'opus-switch-field-1 ignore-row-click',
          }}
          onClick={() => {
            handlers.statusUpdateHandler &&
              handlers.statusUpdateHandler(
                params.data.status === WorkflowDefinitionStatus.ENABLED
                  ? WorkflowDefinitionStatus.DISABLED
                  : WorkflowDefinitionStatus.ENABLED,
                params.data
              );
          }}
        />
      );
    },
  },
  {
    field: 'actions',
    suppressMovable: true,
    headerName: '',
    initialFlex: 2,
    sortable: false,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => {
      return (
        <div className="flow-page-table-actions-cell ignore-row-click">
          <IconButton
            title="Export"
            onClick={() => {
              handlers.exportHandler && handlers.exportHandler(params.data.id);
            }}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_DOWN_ICON} />
          </IconButton>
          <DeleteWithConfirm
            id={params.data.familyId}
            useMutation={useDeleteFlowMutation}
            title={translation(`common.areYouSure`)}
            message={translation('flows.details.removeFlowWarning', {
              param1: params.data.name,
            })}
            callback={handlers.deleteHandler}
          >
            <IconButton title="Delete">
              <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
            </IconButton>
          </DeleteWithConfirm>
        </div>
      );
    },
  },
];

export const workflowHistoryGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'startTime',
    headerName: 'Execution Time',
    cellRenderer: (params: any) =>
      moment(params.data?.startTime).format(dateTimeFormat),
    initialFlex: 3,
  },
  {
    field: 'findingMetadata',
    headerName: 'Finding Name',
    cellRenderer: (params: any) => params.data.findingMetadata?.title,
    initialFlex: 2,
    headerClass: 'column-disable-sort',
    sortable: false,
  },
  {
    field: 'findingMetadata',
    headerName: 'Finding Type',
    cellRenderer: (params: any) => params.data.findingMetadata?.type,
    initialFlex: 2,
    headerClass: 'column-disable-sort',
    sortable: false,
  },
  {
    field: 'workflowDefVersion',
    headerName: 'Version',
    initialFlex: 2,
  },
  {
    field: 'status',
    headerName: 'Status',
    initialFlex: 10,
    cellRenderer: (params: any) => capitalize(params.data.status),
  },
];

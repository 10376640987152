import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import ActionButtons from 'Common/components/ActionButtons';
import { FunctionComponent, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CommonCheckbox from '../CommonCheckbox';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

export interface ColumnVisibilityDef {
  value: string;
  label: string;
  onClick?: (value: string) => void;
  checked?: boolean;
}

interface AdvancedGridVisibilityPanelProps extends BaseComponentProps {
  columns: Array<ColumnVisibilityDef>;
  title?: string;
  toggleColumn?: (columnId: string) => void;
}

export const AdvancedGridVisibilityPanel: FunctionComponent<
  AdvancedGridVisibilityPanelProps
> = ({ columns, title, toggleColumn }) => {
  const options = useMemo(() => {
    return columns.map((column) => {
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          key={column.value}
        >
          <CommonCheckbox
            onChange={() => toggleColumn && toggleColumn(column.value)}
            checked={column.checked}
          />
          <Typography>{column.label}</Typography>
        </Box>
      );
    });
  }, [columns, toggleColumn]);

  return (
    <ActionButtons
      title={<h5 className="setting-modal-title header-6">{title}</h5>}
      baseButton={<OpusSvgIcon type={SVG_ICON_TYPES.GEAR_ICON} />}
      options={options}
      closeOnClick={false}
    />
  );
};

import { Stack, Typography } from '@mui/material';
import CardWrapper from 'Common/components/CardWrapper';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { FunctionComponent, useEffect, useState, useTransition } from 'react';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import IntegrationCount from '../IntegrationCount';
import {
  TenantDetails,
  TenantPlans,
} from '../../../Onboarding/models/onboarding-tenant-details.model';
import useCommonSelector from '../../../Common/utils/use-selector';
import { getTenantDetails } from '../../../Auth/store';

interface IntegrationItemCardProps {
  integration: IntegrationItem;
  onItemClick?: (item: IntegrationItem) => void;
  onActiveIntegrationsClick?: (item: IntegrationItem) => void;
}

export const IntegrationItemCard: FunctionComponent<
  IntegrationItemCardProps
> = ({ integration, onItemClick, onActiveIntegrationsClick }) => {
  const tenantDetails = useCommonSelector<TenantDetails | null>(
    getTenantDetails
  );
  const isDisabled =
    integration.plan === TenantPlans.Enterprise &&
    tenantDetails?.plan === TenantPlans.Freemium;

  const handleOnItemClick = () => {
    if (onItemClick) onItemClick(integration);
  };

  const handleOnActiveIntegrationsClick = () => {
    if (onActiveIntegrationsClick) onActiveIntegrationsClick(integration);
  };

  return (
    <CardWrapper
      className={`integration-item clickable-item ${
        isDisabled && 'integration-disabled'
      }`}
      onClick={isDisabled ? () => {} : handleOnItemClick}
    >
      <Stack direction="row" justifyContent="space-between">
        <CardWrapper className="integration-logo-container">
          <OpusImageIcon src={integration.logo} className="integration-logo" />
        </CardWrapper>
        {!!integration.count && (
          <IntegrationCount
            onClick={handleOnActiveIntegrationsClick}
            count={integration.count}
          />
        )}
      </Stack>
      <Stack className="integration-name">
        <Typography variant="inherit" className="header-6">
          {integration.name}
        </Typography>
      </Stack>
    </CardWrapper>
  );
};

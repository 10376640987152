import { FunctionComponent, useCallback } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CrossroadCondition } from '../CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';
import { CrossroadPanelCondition } from '../CrossroadPanelCondition/CrossroadPanelCondition';
import { Button, Switch } from '@mui/material';
import { ConditionProps } from '../CrossroadPanelConditionRoute/CrossroadPanelConditionRoute';

interface CrossroadPanelConditionListProps extends BaseComponentProps {
  routeIndex: number;
  conditions: Array<Array<CrossroadCondition>>;
  setDefaultRouteHandler: (index: number) => void;
  conditionProps: ConditionProps;
  isRouteFallback?: boolean;
}

export const CrossroadPanelConditionList: FunctionComponent<
  CrossroadPanelConditionListProps
> = ({
  conditions,
  conditionProps,
  setDefaultRouteHandler,
  routeIndex,
  isRouteFallback = false,
}) => {
  const renderConditions = (
    conditionItems: Array<CrossroadCondition>,
    rowIndex: number
  ) => {
    return conditionItems.map(
      (
        conditionItem: CrossroadCondition,
        index: number,
        conditionItemArray
      ) => {
        return (
          <div className="crossroad-condition-sub-list">
            <CrossroadPanelCondition
              {...conditionItem}
              {...conditionProps}
              routeIndex={routeIndex}
              rowIndex={rowIndex}
              columnIndex={index}
            />
            {index !== conditionItemArray.length - 1 ? (
              <div className="crossroad-condition-and-operator">And</div>
            ) : (
              <></>
            )}
          </div>
        );
      }
    );
  };

  const renderConditionLists = useCallback(() => {
    return conditions.map((conditionList, index, conditionListArray) => {
      return (
        <div className="crossroad-condition-sub-list">
          {renderConditions(conditionList, index)}
          {index !== conditionListArray.length - 1 ? (
            <div className="crossroad-condition-or-operator">
              <div className="crossroad-condition-or-operator-line"></div>
              Or
              <div className="crossroad-condition-or-operator-line"></div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    });
  }, [conditions]);

  const renderDefaultSwitch = () => {
    return (
      <div className="crossroad-condition-list-default">
        <div className="crossroad-condition-list-default-label">
          Set as default
        </div>
        <div className="crossroad-condition-list-default-switch">
          <Switch
            checked={isRouteFallback}
            onChange={(event, checked) => {
              setDefaultRouteHandler(routeIndex);
            }}
            classes={{
              root: 'opus-switch-field-1',
            }}
          />
        </div>
      </div>
    );
  };

  const renderAddButtons = () => {
    return (
      <div className="crossroad-panel-condition-list-add-buttons">
        <Button
          className="opus-secondary-button"
          onClick={() => {
            conditionProps.addAndConditionHandler(routeIndex);
          }}
        >
          <span className="crossroad-panel-condition-list-add-button-text">
            Add
          </span>
          <span className="crossroad-panel-condition-list-add-button-highlight-text">
            And
          </span>
          <span className="crossroad-panel-condition-list-add-button-text">
            rule
          </span>
        </Button>

        <Button
          className="opus-secondary-button"
          onClick={() => {
            conditionProps.addOrConditionHandler(routeIndex);
          }}
        >
          <span className="crossroad-panel-condition-list-add-button-text">
            Add
          </span>
          <span className="crossroad-panel-condition-list-add-button-highlight-text">
            Or
          </span>
          <span className="crossroad-panel-condition-list-add-button-text">
            rule
          </span>
        </Button>
      </div>
    );
  };

  return (
    <div className="crossroad-condition-list">
      {renderConditionLists()}
      {renderAddButtons()}
      {renderDefaultSwitch()}
    </div>
  );
};

import { useAuthUser } from '@frontegg/react';
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getInitialTicketFormValidationErrors } from 'shared/fixtures/data/ticket-create-form.data';
import { BulkCreateTicketData } from 'shared/models/data/bulk-create-ticket-data.model';
import {
  TicketCreateFormErrors,
  TicketCreateFormValues,
  TicketTypes,
} from 'shared/models/data/risk-table.model';
import {
  useFetchFindingsConnectionByFiltersMutation,
  useFetchTicketSuggestionMutation,
} from '../../../FindingDetails/store/api';
import {
  applicationTypeToTicketApplicationMap,
  TicketApplication,
  TicketApplicationNameToLabel,
} from '../../../FindingDetails/interfaces/create-ticket';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { ApplicationTypes } from '../../../Application/interfaces/applications.enum';

export const applicationToConnectionId = {
  [TicketApplication.Jira]: ApplicationTypes.JIRA_CLOUD_PLATFORM,
  [TicketApplication.JiraServer]: ApplicationTypes.JIRA_SERVER_PLATFORM,
  [TicketApplication.AzureDevOps]: ApplicationTypes.AZURE_DEVOPS,
};

interface TicketCreateFormProps {
  handleSubmit: (payload: BulkCreateTicketData) => void;
  handleModalClose: () => void;
  findingsIds: string[];
  onSave?: boolean;
  setOnSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  ticketType: TicketTypes;
  findingsCount: number;
  connectionList?: any[];
  defaultApplication?: TicketApplication;
  isConnectionListLoading: boolean;
}

export const TicketCreateForm: FunctionComponent<TicketCreateFormProps> = ({
  handleSubmit,
  handleModalClose,
  findingsIds,
  setOnSave,
  onSave,
  ticketType,
  setIsLoading,
  findingsCount,
  connectionList,
  defaultApplication,
  isConnectionListLoading,
}) => {
  const { t: translation } = useTranslation();
  const [hideTicketForm, setHideTicketForm] = useState<boolean>(
    findingsCount > 1
  );
  const [isAutomatedTicketAssignment, setIsAutomatedTicketAssignment] =
    useState<boolean>(false);

  const [application, setApplication] = useState<
    TicketApplication | undefined
  >();
  const formElement = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setApplication(defaultApplication);
  }, [defaultApplication]);

  const [
    fetchTicketSuggestion,
    { data: ticketSuggestion, isLoading: isLoadingTicketSuggestion },
  ] = useFetchTicketSuggestionMutation();

  const [fetchConnection, { data: connectionData }] =
    useFetchFindingsConnectionByFiltersMutation();

  useEffect(() => {
    if (isAutomatedTicketAssignment) {
      setIsLoading(isLoadingTicketSuggestion && !isAutomatedTicketAssignment);
    } else {
      setIsLoading(isLoadingTicketSuggestion);
    }
  }, [isLoadingTicketSuggestion, isAutomatedTicketAssignment]);

  const connectionListDependencies = useMemo(() => {
    if (connectionList?.length)
      return connectionList.map((connection) => connection.id);

    return [];
  }, [connectionList]);

  useEffect(() => {
    if (findingsIds.length > 0 && application && connectionList?.length)
      fetchTicketSuggestion({
        findingId: findingsIds[0],
        appId: application,
      });
  }, [
    connectionData,
    application,
    findingsIds[0],
    ...connectionListDependencies,
  ]);

  useEffect(() => {
    if (application)
      fetchConnection({
        applicationId: applicationToConnectionId[application],
      });
  }, [application]);

  const initialTicketFormValidationErrors =
    getInitialTicketFormValidationErrors(translation);

  const user = useAuthUser();

  const ticketFormDefaultValues = useMemo<TicketCreateFormValues>(() => {
    const defaultProjectKey: string =
      (ticketSuggestion && ticketSuggestion?.projectKey) ||
      (connectionData &&
        connectionData[0]?.parameters?.find(
          (parameter: any) => parameter.name === 'projectKey'
        )?.value);

    return {
      projectKey: defaultProjectKey,
      assigneeEmail:
        (ticketSuggestion && ticketSuggestion?.assigneeEmail) || user.email,
      ticketType: ticketType,
      ticketApplication: application || TicketApplication.Jira,
    };
  }, [user, connectionData, ticketSuggestion, findingsIds[0], application]);

  const ticketFormValidationErrors: TicketCreateFormErrors = useMemo(
    () => ({
      ...initialTicketFormValidationErrors,
      assigneeEmail: null,
      projectKey: null,
      notifyTicketAssignee: null,
    }),
    []
  );
  const [formErrors, setFormErrors] = useState<TicketCreateFormErrors>(
    ticketFormValidationErrors
  );
  const [formValues, setFormValues] = useState<TicketCreateFormValues>(
    ticketFormDefaultValues
  );

  useEffect(() => {
    setIsAutomatedTicketAssignment(
      ticketType !== TicketTypes.MULTI_FINDINGS_SINGLE_TICKET &&
        hideTicketForm &&
        findingsCount > 1
    );
  }, [ticketType, findingsCount, hideTicketForm]);

  useEffect(() => {
    if (onSave) onSubmit();
  }, [onSave]);

  useEffect(() => {
    if (hideTicketForm) {
      setFormErrors(ticketFormValidationErrors);
    } else {
      for (let formKey in ticketFormDefaultValues) {
        setFormValue(
          formKey as keyof TicketCreateFormValues,
          ticketFormDefaultValues[
            formKey as keyof TicketCreateFormValues
          ] as string
        );
      }
    }
  }, [ticketFormDefaultValues, hideTicketForm]);

  const setFormValue = (
    inputName: keyof TicketCreateFormValues,
    values: string | boolean
  ) => {
    setFormValues((previousFormValues) => ({
      ...previousFormValues,
      [inputName]: values,
    }));
    if ((values as string)?.length) {
      setFormErrors({
        ...formErrors,
        [inputName]: null,
      });
    } else {
      setFormErrors({
        ...formErrors,
        [inputName]: initialTicketFormValidationErrors[inputName],
      });
    }
  };

  const onSubmit = () => {
    const formHasError: boolean = Object.values(formErrors).some(
      (formErrorValue) => formErrorValue
    );
    if (!formHasError) {
      handleSubmit({
        ...formValues,
        projectKey: isAutomatedTicketAssignment ? '' : formValues.projectKey,
        assigneeEmail: isAutomatedTicketAssignment
          ? ''
          : formValues.assigneeEmail,
        ticketApplication: application || '',
        findings: [],
      });
      handleModalClose();
      setOnSave(false);
    }

    setOnSave(false);
  };

  useEffect(() => {
    if (ticketSuggestion) {
      setFormValues((prevValues) => ({
        ...prevValues,
        projectKey: ticketSuggestion.projectKey || '',
        assigneeEmail: ticketSuggestion.assigneeEmail || user.email,
      }));
    }
  }, [ticketSuggestion, user]);

  const renderTicketForm = () => {
    if (isAutomatedTicketAssignment) return <></>;
    if (isLoadingTicketSuggestion)
      return (
        <Box
          display={'flex'}
          justifyContent="center"
          alignItems={'center'}
          width="100%"
        >
          <CircularProgress />
        </Box>
      );
    return (
      <>
        <Grid item xs={12}>
          <Typography className="input-label">
            <FormattedMessage
              id="findings.ticket.project"
              defaultMessage="Project"
            />
          </Typography>
          <FormControl fullWidth className="input-dialog">
            <Input
              name="projectKey"
              placeholder={'Enter project key'}
              disableUnderline
              value={formValues.projectKey}
              onChange={(event: any) => {
                setFormValue('projectKey', event.target?.value);
              }}
              inputProps={{ required: true }}
            />
            {formErrors['projectKey'] && (
              <Typography variant="caption" color="error">
                {formErrors['projectKey']}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography className="input-label">
            <FormattedMessage
              id="findings.ticket.assigneeEmail"
              defaultMessage="Assignee Email"
            />
          </Typography>
          <FormControl fullWidth className="input-dialog">
            <Input
              name="assigneeEmail"
              placeholder={'Enter an assignee email'}
              value={formValues.assigneeEmail}
              onChange={(event: any) => {
                setFormValue('assigneeEmail', event.target?.value);
              }}
              disableUnderline
              inputProps={{ required: true }}
            />
            {formErrors['assigneeEmail'] && (
              <Typography variant="caption" color="error">
                {formErrors['assigneeEmail']}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </>
    );
  };
  const handleApplicationChange = (event: SelectChangeEvent) => {
    const selectedConnection = findSelectedConnection(event.target.value);
    const selectedApplicationType =
      selectedConnection?.id as keyof typeof applicationTypeToTicketApplicationMap;
    const selectedTicketApplication =
      applicationTypeToTicketApplicationMap[selectedApplicationType];
    setApplication(selectedTicketApplication);
    setFormValue('ticketApplication', selectedTicketApplication);
  };

  const renderSelectedValue = (selected: string) => {
    if (!defaultApplication) return '';
    const selectedConnection = findSelectedConnection(
      application ? applicationToConnectionId[application] : selected
    );
    const selectedApplicationType =
      selectedConnection?.id as keyof typeof applicationTypeToTicketApplicationMap;

    return (
      <Grid display={'flex'} alignItems="center">
        {selectedConnection ? (
          <OpusImageIcon
            src={selectedConnection.logo}
            className="organization-form-image-container"
          />
        ) : (
          <OpusSvgIcon type={SVG_ICON_TYPES.JIRA_ICON} />
        )}
        <Typography variant="inherit" className="body-1" ml={2}>
          {getTicketApplicationLabel(selectedApplicationType)}
        </Typography>
      </Grid>
    );
  };

  const renderConnectionOptions = () => {
    if (isConnectionListLoading) {
      return (
        <MenuItem disabled>
          <Box textAlign="center" py={4}>
            <CircularProgress size={10} />
          </Box>
        </MenuItem>
      );
    }

    return connectionList?.map((connection: any) => (
      <MenuItem key={connection.id} value={connection.id}>
        <Grid display={'flex'} alignItems="center">
          <OpusImageIcon
            src={connection.logo}
            className="organization-form-image-container"
          />
          <Typography variant="inherit" className="body-1" ml={2}>
            {getTicketApplicationLabel(connection.id)}
          </Typography>
        </Grid>
      </MenuItem>
    ));
  };

  const findSelectedConnection = (selected: string) => {
    return connectionList?.find(
      (connection: any) => connection.id === selected
    );
  };

  const getTicketApplicationLabel = (
    selectedApplicationType: keyof typeof applicationTypeToTicketApplicationMap
  ) => {
    const ticketApplication =
      applicationTypeToTicketApplicationMap[selectedApplicationType];
    return (
      TicketApplicationNameToLabel[ticketApplication] ||
      TicketApplicationNameToLabel[TicketApplication.Jira]
    );
  };

  const defaultValue = useMemo(
    () =>
      application
        ? applicationToConnectionId[application]
        : defaultApplication
        ? applicationToConnectionId[defaultApplication]
        : '',
    [application, defaultApplication]
  );

  return (
    <div className="common-form-container ticket-create-form">
      <form ref={formElement} onSubmit={onSubmit}>
        <Grid container minWidth={'500px'} rowGap={3}>
          {findingsCount > 1 && (
            <Grid item xs={12} className="multi-findings-warning">
              <OpusSvgIcon type={SVG_ICON_TYPES.INFO_CIRCLE_ICON} />
              <Typography
                variant="inherit"
                className="body-6"
                dangerouslySetInnerHTML={{
                  __html: translation('findings.ticket.multiFindingsWarning', {
                    param1: findingsCount,
                  }),
                }}
              ></Typography>
            </Grid>
          )}

          {isConnectionListLoading ? (
            <Box
              display={'flex'}
              justifyContent="center"
              alignItems={'center'}
              width="100%"
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  value={formValues['ticketApplication']}
                  className="input-dialog select-input"
                  name="ticketApplication"
                  defaultValue={defaultValue}
                  onChange={(event) => handleApplicationChange(event)}
                  renderValue={(selected) => renderSelectedValue(selected)}
                >
                  {renderConnectionOptions()}
                </Select>
              </Grid>

              {ticketType !== TicketTypes.MULTI_FINDINGS_SINGLE_TICKET &&
                findingsCount > 1 && (
                  <Grid item xs={12} className="multi-findings-automate">
                    <Box display="flex" alignItems={'center'} gap={'8px'}>
                      <OpusSvgIcon type={SVG_ICON_TYPES.FEATHER_ICON} />
                      <Typography variant="inherit" className="body-6">
                        <FormattedMessage
                          id="findings.ticket.automateTicket"
                          defaultMessage="Automate Ticket Assignment"
                        />
                      </Typography>
                    </Box>

                    <Switch
                      classes={{
                        root: 'opus-switch-field-1 ignore-row-click',
                      }}
                      defaultChecked={hideTicketForm}
                      onClick={() => {
                        setHideTicketForm(!hideTicketForm);
                      }}
                    />
                  </Grid>
                )}
              {renderTicketForm()}

              {!connectionList ||
                (connectionList?.length === 0 && (
                  <Grid item xs={12} className="no-active-connections-message">
                    <OpusSvgIcon
                      type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON}
                    />
                    <Typography variant="inherit" className="body-1">
                      <FormattedMessage
                        id="findings.ticket.noActiveConnections"
                        defaultMessage="Please configure a ticketing system first"
                      />
                    </Typography>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </form>
    </div>
  );
};

import { FunctionComponent, useEffect, useMemo } from 'react';
import { Divider, Grid } from '@mui/material';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import { RiskAssessmentSDLCWidgetItem } from 'Dashboard/interfaces/RiskAssessmentSDLCWidgetItem.model';
import { RiskAssessmentSDLCWidgetList } from 'Dashboard/interfaces/RiskAssessmentSDLCWidgetList';
import { selectdashboardFilter, getInitialFilterLoad } from 'Dashboard/store';
import { useGetDashboardPostureSdlcAnalyticsMutation } from 'Dashboard/store/api';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import RiskAssessmentCard from '../RiskAssessmentCard';
import { RiskAssessmentSurfaceCategory } from 'Dashboard/interfaces/RiskAssessmentSurfaceCategory.enum';
import FormattedMessage from 'shared/components/FormattedMessage';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';

interface RiskAssessmentSDLCWidgetProps
  extends BaseComponentProps,
    OrganizationalDataProps {}

export const RiskAssessmentSDLCWidget: FunctionComponent<
  RiskAssessmentSDLCWidgetProps
> = ({ scopeData, groupData }) => {
  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter,
  );

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);
  const [
    getDashboardAnalytics,
    { data: dashboardAnalytics, isLoading: dashboardAnalyticsLoading },
  ] = useGetDashboardPostureSdlcAnalyticsMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad)
      getDashboardAnalytics({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: dashboardFilter.findingTypes,
        type: PostureWidgetAnalyticsType.ACCUMULATIVE_SDLC_ARTIFACT,
      });
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter?.businessUnitId,
    dashboardFilter.findingTypes,
  ]);

  const renderWidgets = useMemo(() => {
    const renderWidgetsByType = (type: RiskAssessmentSurfaceCategory) => {
      const widgets = RiskAssessmentSDLCWidgetList[type];

      return (
        <Grid container className="widget-container">
          <Grid item xs={12}>
            <p className="label-2">
              <FormattedMessage
                id={`dashboards.riskAssessmentSurface.${type
                  .toString()
                  .toLowerCase()}`}
                defaultMessage={type.toString().toLowerCase()}
              ></FormattedMessage>
            </p>
          </Grid>
          <Grid container className="widget-container" gap={2}>
            {widgets.map((widget: RiskAssessmentSDLCWidgetItem) => (
              <Grid
                item
                xs={
                  type === RiskAssessmentSurfaceCategory.APPLICATION ? 5.8 : 3.8
                }
              >
                <RiskAssessmentCard
                  {...widget}
                  isLoading={
                    dashboardAnalyticsLoading ||
                    dashboardAnalytics === undefined
                  }
                  widgetData={
                    dashboardAnalytics
                      ? dashboardAnalytics[widget.type]
                      : undefined
                  }
                  scopeData={scopeData}
                  groupData={groupData}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      );
    };

    return renderWidgetsByType;
  }, [dashboardAnalytics]);

  return (
    <Grid container className="risk-assessment-dashboard-container">
      <Grid item xs={4.5}>
        {renderWidgets(RiskAssessmentSurfaceCategory.APPLICATION)}
      </Grid>
      <Grid item xs={0.4}>
        <div className="widget-divider">
          <Divider orientation="vertical" variant="inset" />
        </div>
      </Grid>
      <Grid item xs={7}>
        <div>{renderWidgets(RiskAssessmentSurfaceCategory.INFRASTRUCTURE)}</div>
      </Grid>
    </Grid>
  );
};

import { FunctionComponent, useEffect } from 'react';
import { DropdownProps } from 'shared/components/DataFilters/CategoryDropdown/CategoryDropdown';
import {
  LabelManagementDropdown,
  SystemLabel,
  SystemLabelType,
} from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';
import { useLabelData } from 'shared/hooks/useLabelData';

interface BulkActionDropdownProps extends DropdownProps {
  itemType: SystemLabelType;
  onSelectLabels: (labels: Array<SystemLabel>) => void;
  selectedLabels: Array<SystemLabel>;
}

export const BulkActionDropdown: FunctionComponent<BulkActionDropdownProps> = ({
  anchorEl,
  anchorOrigin,
  handleClose,
  itemType,
  onSelectLabels,
  open,
  selectedLabels,
}) => {
  const {
    addLabel,
    filteredAllLabels,
    filteredRecentLabels,
    loading,
    searchTerm,
    setSearchTerm,
    refetch,
  } = useLabelData({
    itemType,
    shouldFetch: open,
  });

  const selectLabelHandler = (labelItem: SystemLabel) => {
    const labelExists = Boolean(
      selectedLabels.find(
        (selectedLabel) => selectedLabel.label === labelItem.label
      )
    );

    if (labelExists) {
      onSelectLabels(
        selectedLabels.filter(
          (selectedLabel) => selectedLabel.label !== labelItem.label
        )
      );
    } else {
      onSelectLabels([...selectedLabels, labelItem]);
    }
  };

  const addLabelHandler = (label: string) => {
    addLabel(label);

    selectLabelHandler({ label: label.toLowerCase() });
  };

  return (
    <LabelManagementDropdown
      rootClassName="bulk-label-management-dropdown"
      open={Boolean(anchorEl)}
      handleClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      addLabel={addLabelHandler}
      filteredAllLabels={filteredAllLabels}
      filteredRecentLabels={filteredRecentLabels}
      loading={loading}
      onLabelSelect={selectLabelHandler}
      refetch={refetch}
      saveLabels={() => {}}
      displaySaveButton={false}
      savingLabels={false}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      selectedLabels={selectedLabels}
    />
  );
};

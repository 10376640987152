import { FunctionComponent, ReactNode } from 'react';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

type LifecycleCountType = 'runtimeCount' | 'artifactCount' | 'codeCount';

interface LifecycleBoxProps {
  icon: ReactNode;
  propertyName: LifecycleCountType;
}

const lifecycleBoxes: Array<LifecycleBoxProps> = [
  {
    icon: <OpusSvgIcon type={SVG_ICON_TYPES.LIFECYCLE_CODE_ICON} />,
    propertyName: 'codeCount',
  },
  {
    icon: <OpusSvgIcon type={SVG_ICON_TYPES.LIFECYCLE_ARTIFACT_ICON} />,
    propertyName: 'artifactCount',
  },
  {
    icon: <OpusSvgIcon type={SVG_ICON_TYPES.LIFECYCLE_RUNTIME_ICON} />,
    propertyName: 'runtimeCount',
  },
];

type RiskLifecycleCellProps = Partial<Record<LifecycleCountType, number>>;

export const RiskLifecycleCell: FunctionComponent<RiskLifecycleCellProps> = (
  props = {
    artifactCount: 0,
    codeCount: 0,
    runtimeCount: 0,
  }
) => {
  return (
    <div className="risk-lifecycle-cell-container">
      {lifecycleBoxes.map((lifecycleBoxProps: LifecycleBoxProps) => (
        <div
          className={`risk-lifecycle-box ${
            props[lifecycleBoxProps.propertyName]
              ? 'risk-lifecycle-box-active'
              : ''
          }`}
        >
          <div className="risk-lifecycle-box-icon">
            {lifecycleBoxProps.icon}
          </div>
          {props[lifecycleBoxProps.propertyName] &&
          (props[lifecycleBoxProps.propertyName] as number) > 1 ? (
            <div className="risk-lifecycle-box-count">
              {props[lifecycleBoxProps.propertyName]}
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </div>
  );
};

import DiagramEdgeType from 'WorkflowBuilder/interfaces/edge-type';
import DiagramNode, { NodeType } from 'WorkflowBuilder/interfaces/node';
import DiagramStep, {
  DIAGRAM_STEP_TYPE,
} from 'WorkflowBuilder/interfaces/step';
import { Edge } from 'react-flow-renderer';
import logo from '../icons/ms-drive.svg';
import { isPathActive } from './diagram-fns';

function step2node(
  step: DiagramStep,
  isLeaf: boolean,
  nodeType?: NodeType,
  removeEdgeCallback?: (source: string, target: string) => void
): DiagramNode {
  return {
    id: step.workflowSeqId.toString(),
    type: nodeType || NodeType.HTTP,
    position: {
      x: step.diagramData?.position?.x || 0,
      y: step.diagramData?.position?.y || 0,
    },
    draggable: !step.readonly,
    data: {
      id: step?.diagramData?.id,
      label: step.name,
      icon: step?.icon || logo,
      isLeaf,
      seq: step.workflowSeqId,
      onAddCrossroad: step.diagramData?.onAddCrossroad,
      displayCrossroadPanel: step.diagramData?.displayCrossroadPanel,
      handleEditNode: step.diagramData?.handleEditNode,
      displayReadonlyStepDetails: step.diagramData?.displayReadonlyStepDetails,
      displayTriggerPanel: step.diagramData?.displayTriggerPanel,
      readonly: step.readonly,
      status: step.diagramData?.status,
      results: step.diagramData?.results,
      applicationId: step.applicationId,
      actionTemplateId: step.actionTemplateId,
      params: step.diagramData.params,
      value: step.diagramData.value,
    },
  };
}

/**
 * NOTE: this utility doesn't check whether all necessary edges are present.
 * @param steps
 * @returns
 */
export function steps2nodes(steps: DiagramStep[]): DiagramNode[] {
  return steps.map((step) =>
    step2node(step, step.routes.length === 0, step.nodeType)
  );
}

/**
 * NOTE: this utility doesn't check whether target nodes exist.
 * @param steps
 * @returns
 */
export function steps2edges(
  steps: DiagramStep[],
  removeEdgeCallback?: (source: string, target: string) => void
): Edge[] {
  const edges: Edge[] = [];
  for (const step of steps) {
    for (const route of step.routes) {
      const targetId = route.nextWorkflowSeqId;
      if (!targetId) {
        continue;
      }
      const source = step.workflowSeqId.toString();
      const target = targetId.toString();

      let type: DiagramEdgeType | string = route.conditions
        ? DiagramEdgeType.CONDITION
        : DiagramEdgeType.PLUS_BUTTON;

      if (step.readonly && type === DiagramEdgeType.PLUS_BUTTON) {
        type = '';
      }

      const edge: Edge = {
        id: `${source}-${target}`,
        source,
        target,
        type,
        data: {
          name: route?.name,
          isActive: isPathActive(route.nextWorkflowSeqId, steps),
          removeEdgeCallback,
          readonly: step.readonly,
          onAddCrossroad: step.diagramData.onAddCrossroad,
        },
      };
      edges.push(edge);
    }
  }

  return edges;
}

export function getStepsToRemove(
  startingStep: DiagramStep,
  steps: Array<DiagramStep>,
  stepsToBeRemoved: Array<number> = []
) {
  const startingRoutes = startingStep.routes;

  if (startingRoutes.length === 0) {
    return stepsToBeRemoved;
  }

  steps.forEach((step: DiagramStep) => {
    if (
      startingRoutes.find(
        (route) => route.nextWorkflowSeqId === step.workflowSeqId
      )
    ) {
      stepsToBeRemoved?.push(step.workflowSeqId);

      getStepsToRemove(step, steps, stepsToBeRemoved);
    }
  });

  return stepsToBeRemoved;
}

export function getCrossroadStepsToRemove(
  startingStep: DiagramStep,
  steps: Array<DiagramStep>
) {
  const stepsToBeRemoved: Array<Number> = [startingStep.workflowSeqId];

  const startingRoutes = startingStep.routes;

  steps.forEach((step: DiagramStep) => {
    if (
      startingRoutes.find(
        (route) => route.nextWorkflowSeqId === step.workflowSeqId
      ) &&
      step.type === DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY
    ) {
      stepsToBeRemoved?.push(step.workflowSeqId);
    }
  });

  return stepsToBeRemoved;
}

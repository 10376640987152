import { Box, Stack, Typography } from '@mui/material';
import CodeBlock from 'Common/components/CodeBlock';
import {
  CodePreviewProps,
  CodeExtensionsTypes,
  CodeThemeTypes,
} from 'shared/models/props/iac-props.model';
import { CodeBlockBuilder } from '../../../Common/components/CodeBlock/CodeBlockBuilder/code-block.builder';
import CopyButton from 'Common/components/CopyButton';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { EditorView } from 'codemirror';
const iaCCodeBuilder = new CodeBlockBuilder();

export default function CodePreview({
  eventId,
  label,
  code,
  copyButton = false,
  theme = CodeThemeTypes.BBEDIT,
}: CodePreviewProps) {
  return (
    <Stack className="code-preview-container">
      <Box className="code-preview-header">
        <div className="headerIcon">
          <OpusSvgIcon type={SVG_ICON_TYPES.SQUARE_TERMINAL_ICON} />
        </div>
        <Typography variant="inherit" className="header-8">
          {label}
        </Typography>
      </Box>
      <Stack className="code-preview-snippet">
        {copyButton && (
          <CopyButton
            textToCopy={code}
            buttonIcon={SVG_ICON_TYPES.COPY_ICON_GRAY}
          />
        )}

        <CodeBlock
          extensions={[
            iaCCodeBuilder.extensionBuilder(CodeExtensionsTypes.YAML),
            EditorView.lineWrapping,
          ]}
          theme={iaCCodeBuilder.themeBuilder(theme)}
          readOnly={true}
          editable={false}
          value={code}
          basicSetup={{
            lineNumbers: false,
          }}
        ></CodeBlock>
      </Stack>
    </Stack>
  );
}

import { FunctionComponent, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import {
  getFilterState,
  getFindingTypes,
  getInitialFilterLoad,
  selectdashboardFilter,
} from 'Dashboard/store';
import { useGetDashboardAnalyticsMutation } from 'Dashboard/store/api';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OperationalMetricType,
  OperationalWidgetAnalyticsType,
  OperationalWidgetDataTrend,
  OperationalWidgetDataTrendImpact,
} from 'shared/models/data/operational-widget-data.model';
import DashboardWidgetCard from '../DashboardWidgetCard';
import { NumberFormatHandler } from 'shared/handlers/number-format.handler';

interface OperationalWidgetCardProps {
  title: string;
  subTitle?: string;
  icon?: ReactNode;
  type: OperationalMetricType;
  isSelected?: boolean;
  onItemClick: any;
}

const numberFormatHandler = new NumberFormatHandler();

export const OperationalWidgetCard: FunctionComponent<
  OperationalWidgetCardProps
> = ({ title, subTitle, icon, type, isSelected, onItemClick }) => {
  const { t: translation } = useTranslation();
  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const filterState = useCommonSelector(getFilterState);

  const findingTypes = useMemo(() => {
    return getFindingTypes(filterState);
  }, [filterState['findingType']]);

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const [
    getDashboardAnalytics,
    { data: dashboardAnalytics, isLoading: dashboardAnalyticsLoading },
  ] = useGetDashboardAnalyticsMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad)
      getDashboardAnalytics({
        findingTypes: findingTypes,
        businessUnitIds: dashboardFilter.businessUnitId,
        timeline: dashboardFilter.timeline,
        type,
      });
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter?.businessUnitId,
    findingTypes,
    dashboardFilter?.timeline,
  ]);

  const getTrendImpactClassName = (
    trendImpact: OperationalWidgetDataTrendImpact
  ): string => {
    switch (trendImpact) {
      case OperationalWidgetDataTrendImpact.NEGATIVE:
        return 'operational-widget-card-negative-trend';
      case OperationalWidgetDataTrendImpact.POSITIVE:
        return 'operational-widget-card-positive-trend';
      case OperationalWidgetDataTrendImpact.NEUTRAL:
        return 'operational-widget-card-neutral-trend';
      default:
        return '';
    }
  };

  const getTrendIcon = (trend: OperationalWidgetDataTrend) => {
    switch (trend) {
      case OperationalWidgetDataTrend.INCREASING:
        return <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_UP_ICON} />;
      case OperationalWidgetDataTrend.DECREASING:
        return <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_DOWN_ICON} />;
      case OperationalWidgetDataTrend.SAME:
        return <></>;
      default:
        return <></>;
    }
  };

  const getTrendPercentage = (value: number) => {
    return value <= 1000
      ? `${value}%`
      : translation('dashboards.widgets.overSpecificValue', {
          value: '1000%',
        });
  };

  const renderTrendArea = () => {
    return (
      <div
        className={`operational-widget-card-body-trend ${getTrendImpactClassName(
          dashboardAnalytics?.trendImpact
        )}`}
        title={subTitle}
      >
        <span>{getTrendPercentage(dashboardAnalytics?.trendPercentage)}</span>
        {getTrendIcon(dashboardAnalytics?.trend)}
      </div>
    );
  };

  const transformedDashboardAnalytics = useMemo(() => {
    if (dashboardAnalytics) {
      if (Number.isNaN(dashboardAnalytics.value)) return dashboardAnalytics;

      return {
        ...dashboardAnalytics,
        value: numberFormatHandler.formatNumberToString(
          dashboardAnalytics.value
        ),
      };
    }

    return undefined;
  }, [dashboardAnalytics]);

  return (
    <DashboardWidgetCard
      icon={icon}
      title={title}
      data={transformedDashboardAnalytics}
      isLoading={dashboardAnalyticsLoading}
      renderAddornment={renderTrendArea}
      onClick={onItemClick}
      classes={{
        container: `operational-widget-card-container ${
          isSelected ? 'operational-widget-card-selected-container' : ''
        }`,
      }}
      tooltip={dashboardAnalytics?.value}
    />
  );
};

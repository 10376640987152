import { ColDef, ColGroupDef } from 'ag-grid-community';
import Status from 'Common/components/Status';
import moment from 'moment';
import RiskChip from 'Risk/components/RiskChip';
import CommonChip from 'shared/components/CommonChip';
import CommonIconCell from 'shared/components/CommonIconCell';
import FormattedMessage from 'shared/components/FormattedMessage';
import MoreActionsMenu from 'shared/components/MoreActionsMenu';
import { ResourceCell } from 'shared/components/ResourceCell/ResourceCell';
import { TabListItem } from 'shared/components/TabList/TabList';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { SingleFindingTab } from 'shared/enums/findings.enum';
import { RiskFindingDetailsHandler } from 'shared/handlers/risk-finding-details.handler';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { dateTimeFormat } from 'shared/services/date.service';

export const singleFindingTabListItems: Array<TabListItem> = [
  {
    id: SingleFindingTab.DETAILS,
    label: SingleFindingTab.DETAILS,
  },
  {
    id: SingleFindingTab.WORKFLOW,
    label: SingleFindingTab.WORKFLOW,
  },
  {
    id: SingleFindingTab.ACTIVITY,
    label: SingleFindingTab.ACTIVITY,
  },
];
export const riskFindingDetailsHandler = new RiskFindingDetailsHandler();

export const findingOwnersTableColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'isPrimary',
    headerName: 'Email',
    sortable: true,
    resizable: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    flex: 7,
    cellRenderer: (props: any) => {
      return (
        <div className="finding-owners-email-cell">
          <p className="finding-owners-email-cell-text">{props.data.email}</p>
          {props.data.isPrimary ? (
            <div className="finding-owners-email-cell-is-primary">
              Primary Owner
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    },
  },
  {
    field: 'creationReason',
    headerName: 'Creation Reason',
    sortable: true,
    resizable: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    flex: 5,
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'column-disable-sort',
    width: 80,
    cellRenderer: (props: any) => {
      return (
        <div className="finding-owners-actions-cell">
          <MoreActionsMenu
            actions={[
              {
                name: 'Set Primary',
                onClick: () => {
                  props.data.setPrimaryOwner &&
                    props.data.setPrimaryOwner({
                      resourceId: props.data.resourceId,
                      ownerId: props.data.id,
                    });
                },
                icon: SVG_ICON_TYPES.USER_PEN_ICON,
                disabled: props.data.isPrimary,
              },
            ]}
          />
        </div>
      );
    },
  },
];

export const AffectedPackagesColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'packageName',
    headerName: 'Package Name',
    initialFlex: 3,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => (
      <p title={params.data.packageName}>{params.data.packageName}</p>
    ),
  },
  {
    field: 'installedVersion',
    headerName: 'Installed Version',
    initialFlex: 2,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => (
      <p title={params.data.installedVersion}>{params.data.installedVersion}</p>
    ),
  },
  {
    field: 'cveId',
    headerName: 'CVE-ID',
    initialFlex: 3,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => (
      <p title={params.data.cveId}>{params.data.cveId}</p>
    ),
  },
  {
    field: 'cvssScore',
    headerName: 'CVSS',
    initialFlex: 1,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => (
      <p title={params.data.cvssScore}>{params.data.cvssScore}</p>
    ),
  },
  {
    field: 'fixedVersion',
    headerName: 'Fixed Version',
    initialFlex: 3,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => (
      <p title={params.data.fixedVersion}>{params.data.fixedVersion}</p>
    ),
  },
];
export const AffectedPackagesAdditionalColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'isOsLevel',
    headerName: 'OS Level',
    initialFlex: 2,
    headerClass: 'column-disable-sort',
    cellRenderer: (params: any) => {
      if (params.data?.isOsLevel === undefined) return <></>;

      const messageId = params.data.isOsLevel ? 'common.yes' : 'common.no';
      const defaultMessage = params.data.isOsLevel ? 'Yes' : 'No';

      return (
        <p>
          <FormattedMessage
            defaultMessage={defaultMessage}
            id={messageId}
            capitalize
          />
        </p>
      );
    },
  },
];

export const resourceAssociatedFindingsTableColumns: Array<
  ColDef | ColGroupDef
> = [
  {
    field: 'title',
    headerName: 'Risk Name',
    headerClass: 'column-disable-sort',
    initialFlex: 6,
    width: 230,
  },
  {
    field: 'findingSource',
    headerName: 'Sensor',
    headerClass: 'column-disable-sort',
    initialFlex: 1,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label=""
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
    width: 150,
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    sortable: true,
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return <RiskChip riskScore={params?.data?.riskScore} />;
    },
    width: 120,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClass: 'column-disable-sort',
    initialFlex: 2,
    width: 180,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
  },
  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    sortable: true,
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return (
        <div className="cell-text-renderer">
          {moment(params.data?.externalCreatedAt).format(dateTimeFormat)}
        </div>
      );
    },
    width: 200,
  },
];

export const relatedFindingsTableColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'title',
    headerName: 'Risk Name',
    headerClass: 'column-disable-sort',
    initialFlex: 3,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="cell-text-renderer"
        >
          {params?.data?.title}
        </TextOverflow>
      );
    },
  },
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    headerClass: 'column-disable-sort',
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogo}
        />
      );
    },
    width: 230,
  },
  {
    field: 'findingSource',
    headerName: 'Event Source',
    headerClass: 'column-disable-sort',
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return (
        <CommonIconCell
          iconUrl={params?.data?.sourceAppLogoUrl}
          label={params?.data?.findingSource}
          maxWidth={140}
          data={
            params?.data?.duplicatedApplications?.length === 0
              ? []
              : riskFindingDetailsHandler.transformAppListToCommonIconsList(
                  params?.data?.duplicatedApplications,
                  'applicationId',
                  'sourceAppLogoUrl'
                )
          }
        />
      );
    },
    width: 150,
  },
  {
    field: 'riskScore',
    headerName: 'Risk Score',
    headerClass: 'column-disable-sort',
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return <RiskChip riskScore={params?.data?.riskScore} />;
    },
    width: 120,
  },

  {
    field: 'businessUnitName',
    headerName: 'Service',
    headerClass: 'column-disable-sort',
    initialFlex: 2,
    cellRenderer: (params: any) => {
      return params?.data?.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
    width: 150,
  },

  {
    field: 'externalCreatedAt',
    headerName: 'First Seen',
    headerClass: 'column-disable-sort',
    initialFlex: 3,
    cellRenderer: (params: any) => {
      return (
        <div className="cell-text-renderer">
          {moment(params.data?.externalCreatedAt).format(dateTimeFormat)}
        </div>
      );
    },
    width: 200,
  },
];

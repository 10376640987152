export enum ResourceRiskAttributeTypes {
  EXTERNAL_FACING = 'externalFacing',
  CROWN_JEWELS = 'crownJewels',
  SENSITIVE_DATA = 'sensitiveData',
}

export enum ResourceRiskAttributeLabel {
  SENSITIVE_DATA = 'Sensitive Data',
  EXTERNAL_FACING = 'External Facing',
  CROWN_JEWELS = 'Crown Jewels',
}

import { createApi } from '@reduxjs/toolkit/query/react';
import connectionItemsFixture from '../../Connection/fixtures/items';
import ConnectionItem, {
  ConnectionItemCreate,
  ConnectionItemTest,
} from '../../Connection/interfaces/item';
import { ConnectionTestResult } from '../interfaces/connection-test-result';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import {
  CloudProviderType,
  IntegrationRepoRequest,
  IntegrationRepoResponse,
} from 'shared/models/data/integrations.model';
import { ConnectionList } from 'Integrations/interfaces/ConnectionsList.model';

/* eslint-disable */
const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));
const mockItems = connectionItemsFixture;
/* eslint-enable */

const connectionApi = createApi({
  reducerPath: 'connectionApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: ['Connections'],
  endpoints: (builder) => ({
    integrationsFetch: builder.query<IntegrationItem[], void>({
      query: () => 'integrations',
      transformResponse: async (response) => {
        return response as IntegrationItem[];
      },
      providesTags: ['Connections'],
    }),
    connectionsFetch: builder.query<ConnectionItem[], void>({
      query: () => 'connections',
      transformResponse: async (response) => {
        return response as ConnectionItem[];
      },
      providesTags: ['Connections'],
    }),
    checkConnectionExists: builder.mutation<any, string>({
      query: (connectionId) => ({
        url: `connections/${connectionId}`,
        method: 'GET',
      }),
      invalidatesTags: (result, error, arg) => {
        if (!error && result.id) return ['Connections'];
        return [];
      },
    }),
    connectionDelete: builder.mutation<void, string>({
      query: (id) => ({
        url: `integrations/connections/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Connections'],
      transformResponse: async (response) => {
        // await delay(3000);  // dev; simulating latency
        // throw new Error('Test error (manual testing).'); // dev; simulating error
      },
    }),
    connectionCreate: builder.mutation({
      query: (itemCreate) => ({
        url: `connections`,
        method: 'POST',
        body: itemCreate,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return ['Connections'];
      },
    }),
    connectionUpdate: builder.mutation<Promise<void>, ConnectionItem>({
      query: (itemUpdate) => ({
        url: `connections/${itemUpdate.id}`,
        method: 'PATCH',
        body: itemUpdate,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return ['Connections'];
      },
    }),
    connectionUpdateWithRepo: builder.mutation({
      query: (itemUpdate) => ({
        url: `integrations/scm/${itemUpdate.id}`,
        method: 'PATCH',
        body: itemUpdate,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return ['Connections'];
      },
    }),
    connectionTest: builder.mutation<ConnectionTestResult, ConnectionItemTest>({
      query: (itemTest) => ({
        url: `connections/test`,
        method: 'POST',
        body: itemTest,
      }),
      transformResponse: async (response: any) => {
        return response as ConnectionTestResult;
      },
    }),
    businessEnvironmentsFetch: builder.query<any, any>({
      query: (filters) => ({
        url: 'business-unit-environment/getsByFilter',
        method: 'POST',
        body: filters,
      }),
    }),

    getIntegrationRepos: builder.mutation({
      query: (integrationId: string) => ({
        url: `integrations/scm/${integrationId}/repos`,
        method: 'GET',
      }),
    }),

    setIntegrationRepos: builder.mutation({
      query: (body: IntegrationRepoRequest) => ({
        url: `integrations/scm/${body.id}/repos`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return ['Connections'];
      },
    }),

    fetchConnectionsForIntegrationType: builder.query<ConnectionList, string>({
      query: (integrationId) => ({
        url: `integrations/${integrationId}/connections`,
        method: 'GET',
      }),
      providesTags: ['Connections'],
    }),

    createCloudConnection: builder.mutation({
      query: (payload) => ({
        method: 'POST',
        url: `integrations/cloud/${payload.cloudProvider}`,
        body: payload,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return ['Connections'];
      },
    }),

    createMultiCloudConnection: builder.mutation({
      query: (payload) => ({
        method: 'POST',
        url: `integrations/cloud/${payload.cloudProvider}/multi`,
        body: payload,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return ['Connections'];
      },
    }),

    verifyCloudConnection: builder.mutation({
      query: (payload) => ({
        method: 'POST',
        url: `integrations/cloud/${payload.cloudProvider}/verify`,
        body: payload.body,
      }),
    }),

    updateCloudConnection: builder.mutation({
      query: (payload) => ({
        method: 'PATCH',
        url: `integrations/cloud/${payload.cloudProvider}/update/${payload.id}`,
        body: payload,
      }),
      invalidatesTags: (result, error) => {
        if (error) return [];

        return ['Connections'];
      },
    }),

    getCloudConnectionTemplates: builder.mutation({
      query: (cloudProvider: CloudProviderType) => ({
        method: 'GET',
        url: `integrations/cloud/${cloudProvider}/templates`,
      }),
    }),
  }),
});

export default connectionApi;

export const {
  useConnectionsFetchQuery,
  useConnectionDeleteMutation,
  useCheckConnectionExistsMutation,
  useConnectionCreateMutation,
  useConnectionUpdateMutation,
  useConnectionTestMutation,
  useBusinessEnvironmentsFetchQuery,
  useIntegrationsFetchQuery,
  useGetIntegrationReposMutation,
  useConnectionUpdateWithRepoMutation,
  useFetchConnectionsForIntegrationTypeQuery,
  useCreateMultiCloudConnectionMutation,
  useCreateCloudConnectionMutation,
  useUpdateCloudConnectionMutation,
  useVerifyCloudConnectionMutation,
  useGetCloudConnectionTemplatesMutation,
} = connectionApi;

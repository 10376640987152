export enum SearchOperator {
  IN = 'IN', // Includes operator
  LIKE = 'LIKE', // String pattern match operator
  EQ = 'EQ', // Equal operator
  NEQ = 'NEQ', // Not equal operator
  GT = 'GT', // Greater than operator
  LT = 'LT', // Less than operator
  GTE = 'GTE', // Greater than or equal operator
  LTE = 'LTE', // Less than or equal operator
}

export enum OrderByOperator {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SearchFilterParams {
  name: string;
  operator?: SearchOperator;
  values: Array<string | number>;
}

export interface SearchPaginationParams {
  take?: number;
  skip?: number;
}

export interface SearchOrderParams {
  field: string;
  type?: OrderByOperator;
}

export interface SearchParams {
  filters?: Array<SearchFilterParams>;
  pagination?: SearchPaginationParams;
  orderBy?: Array<SearchOrderParams>;
}

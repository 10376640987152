import React from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import TimelineComponent from 'Common/components/TimelineComponent/TimelineComponent';
import IntelligenceThreatActorsItem from './IntelligenceThreatActorsItem';
import { VulnerabilityMetadataThreatActor } from 'shared/models/data/vulnerability.model';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';

interface FindingContentIntelligenceThreatActorsProps
  extends BaseComponentProps {
  threatActors: Array<VulnerabilityMetadataThreatActor>;
}

export const FindingContentIntelligenceThreatActors: React.FC<
  FindingContentIntelligenceThreatActorsProps
> = ({ threatActors }) => {
  const { t: translation } = useTranslation();

  const renderBody = () => {
    if (threatActors.length === 0)
      return <NoDataToDisplayCard displayDescription={false} />;

    return (
      <TimelineComponent
        data={threatActors}
        // isLoading={isLoading}
        noEventsMessageId="findings.details.noEvents"
        noEventsDefaultMessage="No events"
        renderEvent={renderEvent}
        isLoading={false}
      />
    );
  };

  const renderEvent = (
    event: VulnerabilityMetadataThreatActor,
    index: number,
  ) => <IntelligenceThreatActorsItem key={index} {...event} />;

  return (
    <div className=" finding-content-item finding-threat-actors-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-section-threat-actors"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.MASK_ICON} />}
        title={translation(`findings.sections.threatActors`)}
      >
        {renderBody()}
      </ContentSection>
    </div>
  );
};

import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { FunctionComponent, ReactNode, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface AccordionSectionProps extends BaseComponentProps {
  title: string;
  helperText?: string;
  description?: string;
  sectionIcon?: ReactNode;
  toggleIcon?: ReactNode;
  rootClassName?: string;
  iconClassName?: string;
  defaultExpanded?: boolean;
  summary?: React.ReactElement;
}

export const AccordionSection: FunctionComponent<AccordionSectionProps> = ({
  title,
  helperText,
  description,
  sectionIcon,
  toggleIcon,
  rootClassName,
  iconClassName,
  children,
  defaultExpanded = true,
  summary,
}) => {
  const [expanded, setExpanded] = useState<string | false>(
    defaultExpanded ? 'panel1' : false
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Accordion
      classes={{
        root: rootClassName || '',
      }}
      expanded={expanded === 'panel1'}
      onChange={handleChange('panel1')}
    >
      <AccordionSummary expandIcon={toggleIcon ? toggleIcon : <ExpandMore />}>
        {summary ? (
          summary
        ) : (
          <div className="accordion-section-header">
            {sectionIcon ? (
              <div
                className={`accordion-section-icon ${
                  iconClassName?.length ? iconClassName : ''
                }`}
              >
                {sectionIcon}
              </div>
            ) : (
              <></>
            )}
            <div className="accordion-section-title-description">
              <div className="accordion-section-title">
                <div className="accordion-section-title-text">{title}</div>
                {helperText?.length ? (
                  <div className="accordion-section-helper-text">
                    {helperText}
                  </div>
                ) : (
                  <></>
                )}
              </div>

              {description?.length ? (
                <div className="accordion-section-description">
                  {description}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

import { useEffect, useRef, useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Snackbar,
  Typography,
} from '@mui/material';
import commonConfig from 'Config';
import {
  CloudAccountConnect,
  useConnectCloudAccountMutation,
} from '../../../store/api';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { convertMarkdownToTextWithLink } from 'Common/utils/utilsFunction';
import CopyButton from 'Common/components/CopyButton';

interface Props {
  onClose: (shouldReload?: boolean) => void;
  onSave?: boolean;
  setOnSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

let externalId = uuid4();

export default function ConnectAWSAccoutDialogContent({
  onClose,
  onSave,
  setOnSave,
  setIsLoading,
}: Props) {
  const { t: translation } = useTranslation();
  const formElement = useRef<HTMLFormElement>(null);
  const [
    connectCloudAccount,
    { isLoading, isSuccess: isConnected, isError: isNotConnected },
  ] = useConnectCloudAccountMutation();
  useEffect(() => {
    externalId = uuid4();
  }, []);

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    setIsLoading(isLoading);
    setFormErrors({});
  }, [isLoading]);

  useEffect(() => {
    if (isNotConnected) {
      setOnSave(false);
      setFormErrors({});
    }
  }, [isNotConnected]);

  useEffect(() => {
    if (onSave) handleSubmit();
  }, [onSave]);

  useEffect(() => {
    if (isConnected) {
      onClose(true);
      setFormErrors({});
    }
  }, [isConnected]);

  async function handleSubmit() {
    const form = formElement.current;
    if (!form) return;
    const formData = new FormData(form);
    const item: CloudAccountConnect = {
      cloudWorkspaceName: formData.get('cloudWorkspaceName') as string,
      externalID: formData.get('externalID') as string,
      roleARN: formData.get('roleARN') as string,
      allowsRemediation: false,
      accountType: 'member',
    };
    const errors = validateFormFields(item);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setOnSave(false);
    } else {
      setFormErrors({});
      connectCloudAccount(item);
    }
  }

  const getMarkdownDescription = () => {
    const desc = translation('settings.details.cloudConnectionDescription', {
      url: commonConfig.cloudFormationReadRoleUrl,
    });
    return convertMarkdownToTextWithLink(desc);
  };

  function validateFormFields(item: CloudAccountConnect) {
    const errors: { [key: string]: string } = {};

    if (!item.cloudWorkspaceName) {
      errors['cloudWorkspaceName'] = translation(
        'settings.details.requiredField',
        {
          param1: translation('settings.details.accountName'),
        }
      );
    }

    if (!item.roleARN) {
      errors['roleARN'] = translation('settings.details.requiredField', {
        param1: translation('settings.details.roleARN'),
      });
    }
    return errors;
  }

  return (
    <Grid container>
      <Grid item xs={12} ml={1} mb={1.5}>
        <Typography className="title">
          <FormattedMessage
            id="settings.details.createAWSStack"
            defaultMessage="Create AWS stack to connect your AWS account"
          />
        </Typography>
      </Grid>
      <Grid item xs={12} mb={2} ml={1} className="description-container">
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: getMarkdownDescription(),
          }}
        ></Typography>
      </Grid>
      <Grid item xs={12} ml={1}>
        <form ref={formElement} onSubmit={handleSubmit}>
          <Typography className="input-label" variant="body2">
            <FormattedMessage
              id="settings.details.accountName"
              defaultMessage="Account name"
            />
          </Typography>
          <FormControl
            fullWidth
            error={!!formErrors['cloudWorkspaceName']}
            className="input-dialog"
          >
            <Input
              placeholder="Please type account name"
              disableUnderline
              name="cloudWorkspaceName"
              inputProps={{ required: true }}
            />
            {formErrors['cloudWorkspaceName'] && (
              <FormHelperText>
                {formErrors['cloudWorkspaceName']}
              </FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />
          <Typography className="input-label">
            <FormattedMessage
              id="settings.details.roleARN"
              defaultMessage="Role ARN"
            />
          </Typography>
          <FormControl
            fullWidth
            error={!!formErrors['roleARN']}
            className="input-dialog"
          >
            <Input
              placeholder="Please type role ARN"
              disableUnderline
              name="roleARN"
              inputProps={{ required: true }}
            />
            {formErrors['roleARN'] && (
              <FormHelperText>{formErrors['roleARN']}</FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />

          <Typography className="input-label">
            <FormattedMessage
              id="settings.details.externalID"
              defaultMessage="External ID"
            />
          </Typography>
          <div style={{ display: 'flex' }}>
            <FormControl
              fullWidth
              error={!!formErrors['externalID']}
              className="input-dialog"
            >
              <Input
                fullWidth
                disableUnderline
                className="input-dialog"
                readOnly
                name="externalID"
                value={externalId}
                inputProps={{ required: true }}
                endAdornment={<CopyButton textToCopy={externalId}></CopyButton>}
              />
              {formErrors['externalID'] && (
                <FormHelperText>{formErrors['externalID']}</FormHelperText>
              )}
            </FormControl>
          </div>
          <Box mb={3} />
        </form>
      </Grid>
      <Snackbar
        open={isNotConnected}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
      >
        <Alert severity="error" sx={{ minWidth: '100px !important' }}>
          <FormattedMessage
            id="settings.details.failedConnectionCloudEnv"
            defaultMessage="Failed to connect to Cloud Environment!"
          />
        </Alert>
      </Snackbar>
    </Grid>
  );
}

import { WorkflowTrigger } from './workflow-trigger.model';

export enum WorkflowDefinitionStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export interface WorkflowDefinition {
  id: string;
  familyId: string;
  name: string;
  updatedAt: string; //date-time
  workflow: {
    startTime: string; //date-time
  };
  solutionType?: string;
  rootWorkflowSeqId?: number;
  stepDefs?: Array<any>;
  triggers?: WorkflowTrigger[];
  lastEditedBy?: any;
  lastRunTime?: string; //date-time
  executionCount?: number;
  status?: WorkflowDefinitionStatus;
}

export interface Workflow {
  id: string;
  tenantId: string;
  createdAt: string; //date-time
  updatedAt: string; //date-time
  findingId: string;
  rootWorkflowSeqId: number;
  startTime: string; //date-time
  status: string;
  workflowDefId: string;
  workflowDefVersion?: number;
  findingMetadata?: any;
}

import { FunctionComponent } from 'react';
import { BaseChartLegendContainerProps } from 'shared/models/props/chart-props.model';

export const BaseChartLegendContainer: FunctionComponent<
  BaseChartLegendContainerProps
> = ({ id = '', display = false, legendPosition = 'top' }) => {
  return (
    <div
      id={id}
      style={{ visibility: display ? 'visible' : 'hidden' }}
      className={
        legendPosition === 'top' || legendPosition === 'bottom'
          ? 'base-chart-legend-container-row'
          : 'base-chart-legend-container-column'
      }
    ></div>
  );
};

import ReactSlider from 'react-slider';
import './Slider.css';

interface SliderProps {
  marks: Array<number>;
  activeIndex: number;
  onAfterChange?: (index: number) => void;
}

export const Slider = ({ marks, activeIndex, onAfterChange }: SliderProps) => {
  return (
    <ReactSlider
      className="horizontal-list-slider"
      marks={marks}
      markClassName="horizontal-list-slider-mark"
      min={0}
      max={marks.length - 1}
      thumbClassName="horizontal-list-slider-thumb"
      trackClassName="horizontal-list-slider-track"
      renderThumb={(props) => <div {...props}></div>}
      onAfterChange={onAfterChange}
      value={activeIndex}
    />
  );
};

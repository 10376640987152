import React, { ReactEventHandler } from 'react';
import { Typography } from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import CardWrapper from 'Common/components/CardWrapper';

interface IntegrationCountProps {
  count: number;
  onClick?: () => void;
}

export const IntegrationCount: React.FC<IntegrationCountProps> = ({
  count,
  onClick,
}) => {
  return (
    <CardWrapper className="active-integrations" onClick={onClick}>
      <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />
      <Typography variant="inherit" className="header-8">
        {count}
      </Typography>
    </CardWrapper>
  );
};

export default IntegrationCount;

import { useAuth, useAuthActions, useLoginWithRedirect } from '@frontegg/react';
import authConstants from 'Auth/constants';
import { setAccessToken } from 'Auth/store';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

interface AuthMainProps {
  children: ReactElement;
}

export default function AuthMain({ children }: AuthMainProps) {
  const dispatch = useCommonDispatch();
  const location = useLocation();

  const { user, isAuthenticated } = useAuth();
  hotjar.initialize(3294151, 6);

  useEffect(() => {
    if (hotjar.initialized() && user) {
      hotjar.identify(user.email, {
        tenantId: user.tenantId,
        name: user.name,
        email: user.email,
      });
      addUserGuidingCode(user);
    }
  }, [user]);

  function addUserGuidingCode(user: any) {
    const script = document.createElement('script');
    script.innerHTML =
      "(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','NAS788684KGID');";
    document.head.appendChild(script);
    (window as any).userGuiding.identify(user.email, {
      name: user.name,
      email: user.email,
      created_at: user.createdAt,
      last_login: user.lastLogin,
      tenantId: user.tenantId,
      company: {},
    });
  }

  const loginWithRedirect = useLoginWithRedirect();

  const authenticateUser = () => {
    if (user?.accessToken) {
      dispatch(setAccessToken(user.accessToken));
      localStorage.setItem(authConstants.authAccessToken, user?.accessToken);
    }

    if (!isAuthenticated) {
      const accessToken = localStorage.getItem(authConstants.authAccessToken);

      if (!accessToken) {
        localStorage.setItem(
          authConstants.authOriginalRouteKey,
          location.pathname
        );
      }

      loginWithRedirect();
    }
  };

  useEffect(() => {
    authenticateUser();
  }, [user, isAuthenticated]);

  if (isAuthenticated) {
    return children;
  }

  return null;
}

import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EdgeProps, getBezierPath, getEdgeCenter } from 'react-flow-renderer';
import { MenuTabsRoutes } from 'Common/components/Menu/types';
import { IconButton } from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

export default function DiagramConditionEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps) {
  const textContainerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });
  const foreignObjectWidth = 160;
  const foreignObjectHeight = 32;

  const [textContainerHeight, setTextContainerHeight] =
    useState(foreignObjectHeight);

  const [displayDeleteButton, setDisplayDeleteButton] =
    useState<boolean>(false);

  useEffect(() => {
    if (textContainerRef?.current?.clientHeight)
      setTextContainerHeight(textContainerRef?.current?.clientHeight + 5);
  }, [data?.name]);

  const getIsActiveClass = () => {
    return location.pathname.includes(MenuTabsRoutes.BUILDER)
      ? ''
      : data?.isActive
      ? 'workflow-builder-condition-edge-active'
      : 'workflow-builder-condition-edge-not-active';
  };

  const onRemoveEdge = () => {
    data.removeEdgeCallback && data.removeEdgeCallback(source, target);
  };

  const renderDeleteButton = () => {
    if (displayDeleteButton)
      return (
        <IconButton
          onClick={onRemoveEdge}
          className="workflow-builder-condition-edge-delete-button"
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON} />
        </IconButton>
      );

    return <></>;
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      {data?.name?.length > 0 && (
        <foreignObject
          width={foreignObjectWidth}
          height={textContainerHeight}
          x={edgeCenterX - foreignObjectWidth / 2}
          y={edgeCenterY - textContainerHeight / 2}
          className={`workflow-builder-condition-edge-container`}
          requiredExtensions="http://www.w3.org/1999/xhtml"
          onMouseEnter={() => {
            if (!data.readonly) setDisplayDeleteButton(true);
          }}
          onMouseLeave={() => {
            if (!data.readonly) setDisplayDeleteButton(false);
          }}
        >
          <div
            ref={textContainerRef}
            className={`workflow-builder-condition-edge-label ${getIsActiveClass()}`}
          >
            {data?.name}
          </div>
          {renderDeleteButton()}
        </foreignObject>
      )}
    </>
  );
}

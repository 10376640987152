import { MutationDefinition } from '@reduxjs/toolkit/dist/query';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { SelectChip } from './SelectChip/SelectChip';

export interface SelectChipCategory {
  id: string;
  label: string;
  optionList: Array<AutocompleteOption>;
  loaded: boolean;
  delimiter?: string;
  mutationProps?: SelectChipGroupMutationProps;
}

export type SelectChipGroupMutationProps = {
  params: { [key: string]: string };
  fetchMutation: any;
};

export type SelectChipChangePayload = Record<string, Array<AutocompleteOption>>;

export interface SelectChipGroupProps extends BaseComponentProps {
  categories: Array<SelectChipCategory>;
  onChange: (changePayload: SelectChipChangePayload) => void;
  displayMode: 'single' | 'multiple';
  delimiter?: string;
  labels?: {
    addLabel?: string;
  };
  mutationProps?: SelectChipGroupMutationProps;
  defaultValues?: SelectChipChangePayload;
  rootClassName?: string;
}

export const SelectChipGroup: FunctionComponent<SelectChipGroupProps> = ({
  categories,
  onChange,
  displayMode,
  labels,
  delimiter,
  defaultValues,
  mutationProps,
  rootClassName = '',
}) => {
  const [selectedChipValues, setSelectedChipValues] =
    useState<SelectChipChangePayload>(defaultValues || {});

  useEffect(() => {
    if (defaultValues) {
      setSelectedChipValues(defaultValues);
    }
  }, [defaultValues]);

  const onChangeSelectChipValue = (
    categoryId: string,
    values: Array<AutocompleteOption>,
  ) => {
    setSelectedChipValues((previousSelectedChipValues) => {
      const updatedSelectedChipValues = {
        ...previousSelectedChipValues,
        [categoryId]: values,
      };

      if (values.length === 0) {
        delete updatedSelectedChipValues[categoryId];
      }

      onChange(updatedSelectedChipValues);

      return updatedSelectedChipValues;
    });
  };

  const renderSelectedChips = () => {
    if (displayMode === 'multiple' && Object.keys(selectedChipValues).length) {
      return Object.keys(selectedChipValues).map((chipId, index: number) => {
        const category = categories.find((category) => category.id === chipId);

        if (selectedChipValues[category?.id || '']?.length) {
          const selectedChipValueList = Object.values(selectedChipValues);

          return (
            <div className="select-chip-with-delimiter">
              {selectedChipValueList[index - 1]?.length ? (
                <div className="select-chip-delimiter-text">
                  {delimiter || 'AND'}
                </div>
              ) : (
                <></>
              )}

              <SelectChip
                label={category?.label || ''}
                categories={[category as SelectChipCategory]}
                displayMode="single"
                onChange={onChangeSelectChipValue}
                selectedValues={selectedChipValues}
                mutationProps={category?.mutationProps || mutationProps}
                enableClearIcon
              />
            </div>
          );
        }

        return <></>;
      });
    }

    return <></>;
  };

  return (
    <div className={`select-chip-group-container ${rootClassName}`}>
      {renderSelectedChips()}
      <SelectChip
        label={labels?.addLabel || 'Add'}
        categories={categories}
        onChange={onChangeSelectChipValue}
        selectedValues={selectedChipValues}
        displayMode={displayMode}
        mutationProps={mutationProps}
      />
    </div>
  );
};

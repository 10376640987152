import { ExtendedFilterOption } from 'Dashboard/Comparative/interfaces/fixtures/data/comparative-dashboard-filter-option.data';
import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import { FilterItem } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';

export const CloudWorkspacesFilters: Array<FilterItem<ExtendedFilterOption>> = [
  {
    id: 'cloudProvider',
    name: 'Provider',
    type: TypeFilter.MULTI_SELECT,
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    loaded: false,
  },
  {
    id: 'type',
    name: 'Workspace Type',
    type: TypeFilter.MULTI_SELECT,
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    loaded: false,
  },
  {
    id: 'environmentType',
    name: 'Environment Type',
    type: TypeFilter.MULTI_SELECT,
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    loaded: false,
  },
  {
    id: 'service',
    name: 'Services ',
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    type: TypeFilter.MULTI_SELECT,
    loaded: false,
  },
];

import { SeverityTextMapper } from 'FindingDetails/interfaces/severity';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CommonSeverityChips extends BaseComponentProps {
  severityNumber: number;
  displayShortLabel?: boolean;
}

export const CommonSeverityChip: FunctionComponent<CommonSeverityChips> = ({
  severityNumber,
  displayShortLabel = false,
}) => {
  const severity: string = SeverityTextMapper[severityNumber];

  const getLabel = () => {
    if (displayShortLabel) return severity.charAt(0);
    else return severity;
  };
  return (
    <div
      className={`common-severity-chip-container common-severity-chip-${severity?.toLowerCase()}-container`}
    >
      {getLabel()}
    </div>
  );
};

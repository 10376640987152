import FindingsTagWithIcon from 'FindingDetails/components/FindingsTagWithIcon/FindingsTagWithIcon';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { VulnerabilityMetadataScores } from 'shared/models/data/vulnerability.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
export enum RiskTagsType {
  PREDICTIVE = 'predictive',
  THREATS = 'threats',
  PATCHES = 'patches',
}
export const riskTagsList: RiskTagsList = {
  [RiskTagsType.PREDICTIVE]: {
    type: RiskTagsType.PREDICTIVE,
    label: 'Exploit Status',
    list: [
      {
        icon: SVG_ICON_TYPES.MESSAGES_ICON,
        label: 'Discussed',
        key: 'discussionsCount',
      },
      {
        icon: SVG_ICON_TYPES.LIST_CHECK_ICON,
        label: 'Proof of Concept',
        key: 'pocCount',
      },
      {
        icon: SVG_ICON_TYPES.LAPTOP_CODE_ICON,
        label: 'Pentester Framework',
        key: 'pentesterFrameworkCount',
      },
      {
        icon: SVG_ICON_TYPES.UNLOCK_ICON,
        label: 'In The Wild',
        key: 'inTheWildCount',
      },
    ],
  },
  [RiskTagsType.THREATS]: {
    type: RiskTagsType.THREATS,
    label: 'Threats',
    list: [
      {
        icon: SVG_ICON_TYPES.BIOHAZARD_ICON,
        label: 'Malware',
        key: 'malwareCount',
      },
      {
        icon: SVG_ICON_TYPES.SKULL_ICON,
        label: 'Exploits',
        key: 'exploitCount',
      },
      {
        icon: SVG_ICON_TYPES.HOOD_CLOAK_ICON,
        label: 'Threats actors',
        key: 'threatsActorCount',
      },
      {
        icon: SVG_ICON_TYPES.SACK_DOLLAR_ICON,
        label: 'Ransomware',
        key: 'ransomwareCount',
      },
    ],
  },
  [RiskTagsType.PATCHES]: {
    type: RiskTagsType.PATCHES,
    label: 'Patches',
    list: [
      {
        icon: SVG_ICON_TYPES.SCREWDRIVER_WRENCH_ICON,
        label: 'Workarounds',
        key: 'workaroundsCount',
      },
      {
        icon: SVG_ICON_TYPES.BADGE_CHECK_ICON,
        label: 'Patch',
        key: 'patchesCount',
      },
      {
        icon: SVG_ICON_TYPES.WRENCH_ICON,
        label: 'Patch Upgrades',
        key: 'patchUpgradeCount',
      },
    ],
  },
};
interface RiskTag {
  icon: SVG_ICON_TYPES;
  label: string;
  key: string;
}

interface RiskCategory {
  type: string;
  label: string;
  list: RiskTag[];
}

interface RiskTagsList {
  [key: string]: RiskCategory;
}

interface FindingContentScoreTagsProps
  extends Pick<
      VulnerabilityMetadataScores,
      RiskTagsType.PREDICTIVE | RiskTagsType.PATCHES | RiskTagsType.THREATS
    >,
    BaseComponentProps {}

export const FindingContentScoreTags: FunctionComponent<
  FindingContentScoreTagsProps
> = (props) => {
  const { t: translation } = useTranslation();

  return (
    <div className="finding-content-item finding-intelligence-risks">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-risks-tags"
      >
        <div className="risk-tags-container">
          {Object.keys(riskTagsList).map((type) => {
            const category =
              props[
                type as
                  | RiskTagsType.PREDICTIVE
                  | RiskTagsType.PATCHES
                  | RiskTagsType.THREATS
              ];

            return (
              <div className="risk-tag-row">
                <div className="title">
                  <p className="body-2">{riskTagsList[type].label}</p>
                </div>
                <div className="tag-list">
                  {riskTagsList[type].list.map((tag) => {
                    return (
                      <FindingsTagWithIcon
                        icon={tag.icon}
                        label={tag.label}
                        value={
                          category
                            ? category[tag.key as keyof typeof category]
                            : 0
                        }
                      ></FindingsTagWithIcon>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </ContentSection>
    </div>
  );
};

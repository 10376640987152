import { langs } from '@uiw/codemirror-extensions-langs';
import {
  CodeExtensionsTypes,
  CodeThemeTypes,
} from '../../../../shared/models/props/iac-props.model';
import { createTheme } from '@uiw/codemirror-themes';
import { tags as t } from '@lezer/highlight';
import { bbedit } from '@uiw/codemirror-theme-bbedit';

export class CodeBlockBuilder {
  private lightGrayTheme: any;

  constructor() {
    this.lightGrayTheme = this.createLightGray();
  }

  extensionBuilder(extensionType: CodeExtensionsTypes) {
    switch (extensionType) {
      case CodeExtensionsTypes.JSON:
        return langs.json();

      default:
        return langs.yaml();
    }
  }

  themeBuilder(themeType: CodeThemeTypes) {
    switch (themeType) {
      case CodeThemeTypes.LIGHT:
        return 'light';

      case CodeThemeTypes.DARK:
        return 'dark';

      case CodeThemeTypes.DARK:
        return 'dark';

      case CodeThemeTypes.BBEDIT:
        return bbedit;

      case CodeThemeTypes.CUSTOM:
        return undefined;
      default:
        return this.lightGrayTheme;
    }
  }

  private createLightGray() {
    return createTheme({
      theme: 'light',
      settings: {
        background: '#f7f7f7',
        foreground: '#75baff',
        caret: '#5d00ff',
        selection: '#036dd626',
        selectionMatch: '#036dd626',
        lineHighlight: '#8a91991a',
        gutterBackground: '#fff',
        gutterForeground: '#8a919966',
      },
      styles: [
        { tag: t.comment, color: '#787b8099' },
        { tag: t.variableName, color: '#0080ff' },
        { tag: [t.string, t.special(t.brace)], color: '#5c6166' },
        { tag: t.number, color: '#5c6166' },
        { tag: t.bool, color: '#5c6166' },
        { tag: t.null, color: '#5c6166' },
        { tag: t.keyword, color: '#5c6166' },
        { tag: t.operator, color: '#5c6166' },
        { tag: t.className, color: '#5c6166' },
        { tag: t.definition(t.typeName), color: '#5c6166' },
        { tag: t.typeName, color: '#5c6166' },
        { tag: t.angleBracket, color: '#5c6166' },
        { tag: t.tagName, color: '#5c6166' },
        { tag: t.attributeName, color: '#5c6166' },
      ],
    });
  }
}

import ConnectionTemplateItem, {
  ConnectionTemplateType,
} from 'ConnectionTemplate/interfaces/item';
import { useSearchConnectionTemplatesMutation } from 'ConnectionTemplate/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { useEffect, useMemo } from 'react';
import { IntegrationsDataHandler } from 'shared/handlers/integrations-data.handler';
import {
  usePollingSubscriptionByConnectionIdMutation,
  useWebhookFetchByConnectionIdMutation,
} from 'Integrations/Webhook/store/api';
import { PollingSubscription } from 'Integrations/Webhook/interfaces/polling-subscription';
import WebhookItem from 'Integrations/Webhook/interfaces/item';
import {
  IntegrationItem,
  IntegrationItemSubCategory,
} from 'Integrations/interfaces/IntegrationItem.model';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';

interface IntegrationMetadata {
  connectionTemplate?: ConnectionTemplateItem;
  loadingConnectionTemplate: boolean;
  pollingSubscription?: PollingSubscription;
  isLoadingPollingSubscription?: boolean;
  webhookConnection?: WebhookItem;
}

const integrationsDataHandler = new IntegrationsDataHandler();

export function useFetchIntegrationMetadata(
  fetchItems: boolean,
  integrationItem: IntegrationItem,
  connectionId?: string
): IntegrationMetadata {
  const { id: applicationId, subCategory } = integrationItem;

  const [
    fetchPollingSubscriptionByConnectionId,
    { data: pollingSubscription, isLoading: isLoadingPollingSubscription },
  ] = usePollingSubscriptionByConnectionIdMutation();
  const [
    searchConnectionTemplates,
    { data: connectionTemplates, isLoading: loadingConnectionTemplate },
  ] = useSearchConnectionTemplatesMutation();

  const [fetchWebhookByConnectionId, { data: webhookConnection }] =
    useWebhookFetchByConnectionIdMutation();

  const connectionTemplate = useMemo<ConnectionTemplateItem | undefined>(() => {
    return connectionTemplates?.length
      ? connectionTemplates[connectionTemplates.length - 1]
      : undefined;
  }, [connectionTemplates]);

  useEffect(() => {
    if (fetchItems)
      searchConnectionTemplates({
        applicationId,
      });
    if (connectionId) {
      if (subCategory === IntegrationItemSubCategory.POLLING) {
        fetchPollingSubscriptionByConnectionId(connectionId);
      } else if (subCategory === IntegrationItemSubCategory.WEBHOOK) {
        fetchWebhookByConnectionId(connectionId);
      }
    }
  }, [applicationId, fetchItems]);

  return {
    connectionTemplate,
    loadingConnectionTemplate,
    pollingSubscription,
    isLoadingPollingSubscription,
    webhookConnection,
  };
}

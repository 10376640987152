import React from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowTextDisplay,
  OverflowDirection,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { VulnerabilityMetadataThreat } from 'shared/models/data/vulnerability.model';
import moment from 'moment';
import { findingIntelligenceDateNumberFormat } from '../FindingContentIntelligence/FindingContentIntelligence';

interface IntelligenceThreatItemProps
  extends BaseComponentProps,
    VulnerabilityMetadataThreat {}

const IntelligenceThreatItem: React.FC<IntelligenceThreatItemProps> = ({
  aliases,
  publishedDate,
  sources,
  tags,
  title,
}) => {
  const { t: translation } = useTranslation();

  const renderTags = () =>
    tags.map((tag, index) => (
      <div key={index} className="threat-tag">
        <p className="body-1 bold">{tag}</p>
      </div>
    ));

  const renderSources = () =>
    sources.map((source, index) => (
      <InteractiveLabel
        key={index}
        label={source.label}
        link={source.url}
        linkIcon
      />
    ));

  return (
    <div className="finding-threats-item-container">
      <div className="indicator">
        <OpusSvgIcon type={SVG_ICON_TYPES.BIOHAZARD_ICON} />
      </div>
      <p className="label-4">{title}</p>
      <div className="threat-details">
        <div className="row">
          <p className="opus-body-text-1">
            {translation('findings.details.aliases')}
          </p>
          <TextOverflow
            type={OverflowTextDisplay.ELLIPSIS}
            direction={OverflowDirection.WIDTH}
            className="aliases-container"
          >
            <p className="body-2">{aliases.join(', ')}</p>
          </TextOverflow>
        </div>
        <div className="row">
          <p className="opus-body-text-1">
            {translation('findings.details.tags')}
          </p>
          <div className="tag-list">{renderTags()}</div>
        </div>
        <div className="row">
          <p className="opus-body-text-1">
            {translation('findings.details.publishDate')}
          </p>
          <p className="body-2">
            {moment(publishedDate).format(findingIntelligenceDateNumberFormat)}
          </p>
        </div>
        <div className="row">
          <p className="opus-body-text-1">
            {translation('findings.details.source')}
          </p>
          <div className="sources-container">{renderSources()}</div>
        </div>
      </div>
    </div>
  );
};

export default IntelligenceThreatItem;

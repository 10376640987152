import { Box, Button, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from './logo.svg';
import classes from './Logo.module.css';

export default function AppLogo() {
  const navigate = useNavigate();
  function goHomePage() {
    navigate('/', { replace: true });
  }

  return (
    <Box className={classes.appLogo}>
      <Button onClick={goHomePage} title="Home">
        <Logo />
      </Button>
    </Box>
  );
}

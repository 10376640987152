import {
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Box,
} from '@mui/material';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { setFindingSelectedWorkflow } from 'FindingDetails/store';
import { useEffect, useState } from 'react';
import { SolutionTypeEnum } from 'FindingDetails/interfaces/SolutionType.enum';
import { useFetchFindingWorkflowsMutation } from 'FindingDetails/store/api';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import ActionButton from '../../../shared/components/ActionButton';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { dateTimeFormat } from 'shared/services/date.service';

export default function WorkflowHeader({ findingId }: any) {
  const { t: translation } = useTranslation();
  const [selectedWorkflow, setSelectedWorkflow] = useState<string>('');
  const dispatch = useCommonDispatch();
  const [fetchFindingWorkflows, { data: availableWorkflows, isLoading }] =
    useFetchFindingWorkflowsMutation();

  const handleOnChange = (event: SelectChangeEvent) => {
    dispatch(setFindingSelectedWorkflow(event.target.value));
    setSelectedWorkflow(event.target.value);
  };

  useEffect(() => {
    fetchFindingWorkflows(findingId);
  }, []);

  const refreshWorkflows = () => {
    fetchFindingWorkflows(findingId);
  };

  useEffect(() => {
    if (!selectedWorkflow && availableWorkflows?.length) {
      let defaultWorkflowId = '';

      let mitigationWorkflow = availableWorkflows?.find(
        (workflow) => workflow.solutionType === SolutionTypeEnum.MITIGATION
      );
      defaultWorkflowId = mitigationWorkflow?.id || availableWorkflows[0].id;
      dispatch(setFindingSelectedWorkflow(defaultWorkflowId));
      setSelectedWorkflow(defaultWorkflowId);
    }
    return () => {
      dispatch(setFindingSelectedWorkflow(''));
      setSelectedWorkflow('');
    };
  }, [availableWorkflows]);

  return (
    <>
      <div className="finding-content-workflow-refresh-container">
        <ActionButton
          onClick={refreshWorkflows}
          icon={SVG_ICON_TYPES.RERUN_ICON}
        >
          {translation(`common.refresh`)}
        </ActionButton>
      </div>
      <div className="finding-content-workflow-select-container">
        <Box className="findingWorkflows">
          <Select
            value={selectedWorkflow}
            className="workflow-select-container"
            displayEmpty={true}
            onChange={handleOnChange}
            IconComponent={() => (
              <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />
            )}
          >
            {availableWorkflows?.map((workflow, index: number) => (
              <MenuItem key={index} value={workflow.id}>
                <Typography className="workflowLabel">
                  {workflow.workflowDefName} (
                  {moment(workflow.createdAt).format(dateTimeFormat)})
                </Typography>
              </MenuItem>
            ))}
            {isLoading && (
              <MenuItem value={''} selected>
                <Typography className="workflowLabel">
                  <FormattedMessage
                    id="flows.details.loadingWorkflows"
                    defaultMessage="Loading Workflows"
                  />
                </Typography>
              </MenuItem>
            )}
            {!availableWorkflows?.length && !isLoading && (
              <MenuItem value={''} selected>
                <Typography className="workflowLabel">
                  <FormattedMessage
                    id="flows.details.noWorkflowsFound"
                    defaultMessage="No workflows found"
                  />
                </Typography>
              </MenuItem>
            )}
          </Select>
        </Box>
      </div>
    </>
  );
}

import { Skeleton } from '@mui/material';
import { useGetLatestFindingExceptionByFindingQuery } from 'FindingDetails/store/api';
import { FunctionComponent } from 'react';
import FindingExceptionStatusBox from 'shared/components/FindingExceptionStatusBox';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { BaseFindingComponentProps } from 'shared/models/props/base-finding-component-props.model';

interface FindingContentExceptionProps extends BaseFindingComponentProps {}

export const FindingContentException: FunctionComponent<
  FindingContentExceptionProps
> = ({ findingId }) => {
  let [urlSearchParams] = useQueryParams();
  const { data: findingException, isLoading: findingExceptionsLoading } =
    useGetLatestFindingExceptionByFindingQuery(
      findingId || urlSearchParams.openFindingId
    );

  const renderFindingException = () => {
    if (findingExceptionsLoading)
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={80} width={'100%'} />
        </div>
      );

    if (!findingException) return <></>;

    return <FindingExceptionStatusBox {...findingException} hasApprovalRole />;
  };

  return renderFindingException();
};

import { Typography } from '@mui/material';

export default function CommonNotFound() {
  return (
    <div>
      <Typography component="h1" variant="h2">
        404 - Not Found
      </Typography>
    </div>
  );
}

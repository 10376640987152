import React, { useEffect, useState } from 'react';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import FormControlLabel from '@mui/material/FormControlLabel';
import CommonCheckbox from 'shared/components/CommonCheckbox';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import OrganizationNodeLabel from '../OrganizationNodeLabel';
import { OrganizationNodeDataHandler } from 'shared/handlers/organization-data-node.handler';

interface OrganizationSelectableTreeProps {
  data: OrganizationNode[];
  onSelectedNodesChange: (scopeId: string, selectedNodes: string[]) => void;
  preSelectedNodes: string[];
}

export const OrganizationSelectableTree: React.FC<
  OrganizationSelectableTreeProps
> = ({
  data,
  onSelectedNodesChange,
  preSelectedNodes,
}: OrganizationSelectableTreeProps) => {
  const organizationNodeDataHandler = new OrganizationNodeDataHandler(data[0]);
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleToggle = (event: any, nodeIds: string[]) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      setExpanded(nodeIds);
    }
  };

  useEffect(() => {
    if (Array.isArray(preSelectedNodes) && preSelectedNodes.length > 0) {
      setSelectedNodes(preSelectedNodes);
    } else if (typeof data[0].id === 'string') {
      setSelectedNodes([data[0].id]);
    }
  }, []);

  const getParentIdsRecursively = (nodeId: string, parentIds: string[]) => {
    const element = organizationNodeDataHandler.findObjectById(nodeId);
    if (element) {
      parentIds.push(element.id);
      getParentIdsRecursively(element.parentId, parentIds);
    }
  };

  useEffect(() => {
    if (Array.isArray(preSelectedNodes) && preSelectedNodes.length > 0) {
      const parentIds: string[] = [];

      setSelectedNodes(preSelectedNodes);
      preSelectedNodes.forEach((nodeId) => {
        getParentIdsRecursively(nodeId, parentIds);
      });
      setExpanded([...expanded, ...parentIds]);
    } else if (typeof data[0].id === 'string') {
      setSelectedNodes([data[0].id]);
    }
  }, []);

  useEffect(() => {
    onSelectedNodesChange(data[0].id, selectedNodes);
  }, [selectedNodes, onSelectedNodesChange]);

  const handleCheckboxChange = (node: OrganizationNode) => {
    const isNodeSelected = selectedNodes.includes(node.id);
    let updatedSelectedNodes = [...selectedNodes];
    let shouldUpdate = false;

    if (!isNodeSelected) {
      updatedSelectedNodes.push(node.id);
      updatedSelectedNodes = deselectDescendants(node, updatedSelectedNodes);
      deselectAncestors(node, updatedSelectedNodes);
      shouldUpdate = true;
    } else {
      const nodeIndex = updatedSelectedNodes.indexOf(node.id);
      if (nodeIndex !== -1) {
        updatedSelectedNodes.splice(nodeIndex, 1);
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      setSelectedNodes(updatedSelectedNodes);
    }
  };

  const deselectDescendants = (
    node: OrganizationNode,
    updatedSelectedNodes: string[]
  ) => {
    let descendantList = [
      ...node.accessibleDataNodes,
      ...node.accessibleLogicalNodes,
    ];

    const indexToRemove = descendantList.indexOf(node.id);
    if (indexToRemove !== -1) {
      descendantList.splice(indexToRemove, 1);
    }

    return updatedSelectedNodes.filter(
      (selectedNodeId) => !descendantList.includes(selectedNodeId)
    );
  };

  const deselectAncestors = (
    node: OrganizationNode,
    updatedSelectedNodes: string[]
  ) => {
    let parent = organizationNodeDataHandler.findObjectById(node.parentId);

    while (parent) {
      if (updatedSelectedNodes.includes(parent.id) && parent.id !== node.id) {
        const parentIndex = updatedSelectedNodes.indexOf(parent.id);
        if (parentIndex !== -1) {
          updatedSelectedNodes.splice(parentIndex, 1);
        }
      }
      parent = organizationNodeDataHandler.findObjectById(parent.parentId);
    }
  };

  const renderTree = (nodes: OrganizationNode[]) => (
    <>
      {nodes.map((node: OrganizationNode) => (
        <TreeItem
          key={node.id}
          nodeId={node.id}
          collapseIcon={node.children.length ? <RemoveIcon /> : <></>}
          expandIcon={node.children.length ? <AddIcon /> : <></>}
          label={renderLabel(node)}
        >
          {Array.isArray(node.children) && renderTree(node.children)}
        </TreeItem>
      ))}
    </>
  );

  const renderLabel = (node: OrganizationNode) => (
    <FormControlLabel
      classes={{
        root: 'user-org-node-label-container',
        label: 'user-org-node-label-text',
      }}
      control={
        <CommonCheckbox
          onChange={(event) => {
            event.stopPropagation();
            handleCheckboxChange(node);
          }}
          checked={selectedNodes.includes(node.id)}
        />
      }
      label={<OrganizationNodeLabel type={node.type} label={node.name} />}
    />
  );

  return (
    <TreeView onNodeToggle={handleToggle} expanded={expanded}>
      {renderTree(data)}
    </TreeView>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import ApplicationItem, {
  applicationInitBulk,
  ApplicationLogo,
  FindingFileImportApplicationItem,
} from 'Application/interfaces/item';
import { filterFindingsSources } from 'Application/utils';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';

const applicationApi = createApi({
  reducerPath: 'applicationApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  endpoints: (builder) => ({
    applicationsWebhooksEnabledFetch: builder.query<ApplicationItem[], void>({
      query: () => ({
        url: 'applications/search',
        method: 'POST',
        body: {
          isWebhookEnabled: true,
        },
      }),
      transformResponse: async (response) => {
        return applicationInitBulk(response as any[]);
      },
    }),
    applicationsFetch: builder.query<ApplicationItem[], void>({
      query: () => 'connection-template/full',
      transformResponse: async (response) => {
        return applicationInitBulk(response as any[]);
      },
    }),
    findingSourcesFetch: builder.query<ApplicationItem[], void>({
      query: () => 'application/onboarding',
      transformResponse: async (response) => {
        const apps = applicationInitBulk(response as any[]);
        return filterFindingsSources(apps);
      },
    }),
    applicationLogosFetch: builder.query<ApplicationLogo[], void>({
      query: () => 'applications/metadata/logos',
      transformResponse: async (response) => {
        return response as ApplicationLogo[];
      },
    }),
    searchApplications: builder.mutation({
      query: (body) => ({
        method: 'POST',
        url: 'applications/search',
        body,
      }),
    }),
    fetchFindingFileImportApplications: builder.query<
      FindingFileImportApplicationItem[],
      void
    >({
      query: () => ({
        method: 'GET',
        url: 'applications/GetFindingFileImportApplications',
      }),
    }),
  }),
});

export default applicationApi;

export const {
  useApplicationsWebhooksEnabledFetchQuery,
  useApplicationsFetchQuery,
  useFindingSourcesFetchQuery,
  useApplicationLogosFetchQuery,
  useSearchApplicationsMutation,
  useFetchFindingFileImportApplicationsQuery,
} = applicationApi;

import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import { TicketApplication } from 'FindingDetails/interfaces/create-ticket';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import { useGetConnectionByCategoryMutation } from 'Integrations/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkCreateTicketData } from 'shared/models/data/bulk-create-ticket-data.model';
import { TicketTypes } from 'shared/models/data/risk-table.model';
import { TicketModalConfiguration } from 'shared/models/data/ticket-modal-configuration.model';
import TicketCreateForm from '../TicketCreateForm';
import { applicationToConnectionId } from '../TicketCreateForm/TicketCreateForm';

interface TicketCreateModalProps {
  handleModalClose: () => void;
  configuration: TicketModalConfiguration;
  handleBulkTicketCreation: (payload: BulkCreateTicketData) => void;
  ticketType: TicketTypes;
  findingsCount: number;
}

export const TicketCreateModal: FunctionComponent<TicketCreateModalProps> = ({
  handleModalClose,
  configuration,
  handleBulkTicketCreation,
  ticketType,
  findingsCount,
}) => {
  const { t: translation } = useTranslation();

  const [onSave, setOnSave] = useState<boolean>(false);

  const [isJiraConnected, setIsJiraConnected] = useState<boolean>(false);
  const [isAzureConnected, setIsAzureConnected] = useState<boolean>(false);
  const [isJiraServerConnected, setIsJiraServerConnected] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [
    fetchConnections,
    { data: connectionList, isLoading: isConnectionListLoading },
  ] = useGetConnectionByCategoryMutation();

  useEffect(() => {
    fetchConnections(IntegrationCategory.TICKETING);
  }, [configuration.isOpen]);

  useEffect(() => {
    if (!isConnectionListLoading) {
      setIsJiraConnected(
        connectionList?.find(
          (connection: any) =>
            connection.id === applicationToConnectionId[TicketApplication.Jira]
        )
      );
      setIsAzureConnected(
        connectionList?.find(
          (connection: any) =>
            connection.id ===
            applicationToConnectionId[TicketApplication.AzureDevOps]
        )
      );

      setIsJiraServerConnected(
        connectionList?.find(
          (connection: any) =>
            connection.id ===
            applicationToConnectionId[TicketApplication.JiraServer]
        )
      );
    }
  }, [isConnectionListLoading]);

  const defaultApplication = useMemo(() => {
    if (isJiraConnected) return TicketApplication.Jira;
    if (isAzureConnected) return TicketApplication.AzureDevOps;
    if (isJiraServerConnected) return TicketApplication.JiraServer;

    return undefined;
  }, [isJiraConnected, isAzureConnected, isJiraServerConnected]);

  return (
    <CommonDialog
      classes="common-dialog-ticket-create"
      open={configuration.isOpen}
      onClose={() => {
        setOnSave(false);
        handleModalClose();
      }}
      loadingButtonText={translation('common.create')}
      isLoading={isLoading}
      title={configuration.title}
      icon={configuration.icon}
      saveButtonText={translation('common.create')}
      buttonRequiredPermissions={[]}
      onSave={() => {
        setOnSave(true);
      }}
      isOnSubmitDisabled={
        !isAzureConnected && !isJiraConnected && !isJiraServerConnected
      }
      closeButtonText={translation('common.cancel')}
    >
      <TicketCreateForm
        findingsCount={findingsCount}
        ticketType={ticketType}
        handleSubmit={handleBulkTicketCreation}
        setOnSave={setOnSave}
        onSave={onSave}
        handleModalClose={handleModalClose}
        findingsIds={configuration.findingsIds}
        setIsLoading={setIsLoading}
        defaultApplication={defaultApplication}
        connectionList={connectionList}
        isConnectionListLoading={isConnectionListLoading}
      />
    </CommonDialog>
  );
};

import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommonButton,
  CommonButtonType,
} from 'shared/components/CommonButton/CommonButton';
import FormInput from 'shared/components/FormInput';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { TabList, TabListItem } from 'shared/components/TabList/TabList';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export enum AWSAccountType {
  MGMT = 'mgmt',
  SINGLE = 'single',
}

enum AwsIntegrationManagementTab {
  SINGLE = 'Single',
  MULTIPLE = 'Multiple',
}

const awsIntegrationManagementTabToAccountType: Record<
  AwsIntegrationManagementTab,
  AWSAccountType
> = {
  [AwsIntegrationManagementTab.SINGLE]: AWSAccountType.SINGLE,
  [AwsIntegrationManagementTab.MULTIPLE]: AWSAccountType.MGMT,
};

const awsIntegrationManagementTabs: Array<TabListItem> = [
  {
    id: AwsIntegrationManagementTab.SINGLE,
    label: AwsIntegrationManagementTab.SINGLE,
  },
  {
    id: AwsIntegrationManagementTab.MULTIPLE,
    label: AwsIntegrationManagementTab.MULTIPLE,
  },
];

export const getAwsMultiDeployInstructionItems = (
  translation: any
): Array<string> => {
  return [
    translation(
      `integrations.details.awsMultiDeployCreateProcessInstructionOne`
    ),
    translation(
      `integrations.details.awsMultiDeployCreateProcessInstructionTwo`
    ),
    translation(
      `integrations.details.awsMultiDeployCreateProcessInstructionThree`
    ),
    translation(
      `integrations.details.awsMultiDeployCreateProcessInstructionFour`
    ),
    translation(
      `integrations.details.awsMultiDeployCreateProcessInstructionFive`
    ),
  ];
};

interface AwsIntegrationManagementSectionProps extends BaseComponentProps {
  teamOwnerProps: {
    value?: string;
    changeHandler: (value: string) => void;
    errorMessage?: string;
  };
  template?: ConnectionTemplateItem;
  setAccountType: (type: AWSAccountType) => void;
}

export const AwsIntegrationManagementSection: FunctionComponent<
  AwsIntegrationManagementSectionProps
> = ({
  teamOwnerProps: { value, changeHandler, errorMessage },
  template,
  setAccountType,
}) => {
  const { t: translation } = useTranslation();

  const [activeTabId, setActiveTabId] = useState<AwsIntegrationManagementTab>(
    AwsIntegrationManagementTab.SINGLE
  );

  const multiDeployInstructions: Array<string> =
    getAwsMultiDeployInstructionItems(translation);

  const singleDeployLink = useMemo<string | undefined>(() => {
    return template?.definition?.props?.deployLink;
  }, [template]);

  const multiDeployLink = useMemo<string | undefined>(() => {
    return template?.definition?.props?.multiDeploy?.deployLink;
  }, [template]);

  const deployLink = useMemo<string>(() => {
    if (activeTabId === AwsIntegrationManagementTab.SINGLE)
      return singleDeployLink || '';

    return multiDeployLink || '';
  }, [singleDeployLink, multiDeployLink, activeTabId]);

  const deployLinkDescription = useMemo<string>(() => {
    if (activeTabId === AwsIntegrationManagementTab.SINGLE)
      return template?.description;

    return template?.definition?.props?.multiDeploy?.description;
  }, [template, activeTabId]);

  const renderMultiDeployInstructions = () => {
    return multiDeployInstructions.map((label: string, index: number) => {
      return (
        <div className="aws-integration-management-section-multiple-instruction">
          <div className="aws-integration-management-section-multiple-instruction-index">
            {index + 1}
          </div>
          <div className="aws-integration-management-section-multiple-instruction-label">
            {label}
          </div>
        </div>
      );
    });
  };

  const renderDeployStack = () => {
    return (
      <div className="aws-integration-management-deploy-stack">
        <div className="aws-integration-management-deploy-stack-text">
          {deployLinkDescription}
        </div>
        <div className="aws-integration-management-deploy-stack-button">
          <CommonButton type={CommonButtonType.TEXT}>
            <a
              href={deployLink}
              className="cloud-integration-deploy-button"
              target="_blank"
            >
              Deploy Stack
              <OpusSvgIcon type={SVG_ICON_TYPES.DEPLOY_ICON} />
            </a>
          </CommonButton>
        </div>
      </div>
    );
  };

  const renderTabContent = () => {
    if (activeTabId === AwsIntegrationManagementTab.SINGLE) {
      return (
        <div className="aws-integration-management-section-single">
          {renderDeployStack()}

          <FormInput
            label={translation(
              `integrations.details.awsDeployTeamOwnerInputName`
            )}
            name="roleArn"
            placeholder={translation(
              `integrations.details.awsDeployTeamOwnerInputPlaceholder`
            )}
            value={value}
            onChange={changeHandler}
            errorMessage={errorMessage}
          />
        </div>
      );
    }

    return (
      <div className="aws-integration-management-section-multiple">
        <div className="aws-integration-management-section-multiple-description">
          {translation(`integrations.details.awsMultiDeployDescription`)}
        </div>
        {renderDeployStack()}

        <div className="aws-integration-management-section-multiple-instructions">
          <div className="aws-integration-management-section-multiple-instruction-title">
            {translation(
              `integrations.details.awsMultiDeployCreateProcessTitle`
            )}
          </div>
          {renderMultiDeployInstructions()}
        </div>

        <FormInput
          label={translation(
            `integrations.details.awsMultiDeployTeamOwnerInputName`
          )}
          name="roleArn"
          placeholder={translation(
            `integrations.details.awsDeployTeamOwnerInputPlaceholder`
          )}
          value={value}
          onChange={changeHandler}
          errorMessage={errorMessage}
        />
      </div>
    );
  };

  return (
    <div className="aws-integration-management-section-container">
      <TabList
        activeItemId={activeTabId}
        items={awsIntegrationManagementTabs}
        isUnderlined
        onSelect={(id: AwsIntegrationManagementTab) => {
          setActiveTabId(id);
          setAccountType(awsIntegrationManagementTabToAccountType[id]);
          changeHandler('');
        }}
      />
      <div className="aws-integration-management-section-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

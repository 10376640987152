import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FindingExceptionModel } from 'shared/models/data/finding-exception.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { FormattedMessage } from '../FormattedMessage/FormattedMessage';
import {
  findingExceptionNegativeStatusList,
  findingExceptionStatusToLabel,
} from 'shared/fixtures/data/finding-exception.data';
import { formatDate } from 'Common/utils/utilsFunction';
import { AvatarChip } from '../AvatarChip/AvatarChip';
import { Button } from '@mui/material';
import { FindingExceptionRevokeModal } from '../FindingExceptionRevokeModal/FindingExceptionRevokeModal';
import {
  useApproveFindingExceptionMutation,
  useCancelFindingExceptionMutation,
  useRevokeFindingExceptionMutation,
} from 'FindingDetails/store/api';
import { FindingExceptionStatus } from 'shared/enums/finding-exception.enum';
import { AuthorizedContent, useAuthUser } from '@frontegg/react';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import Status from 'Common/components/Status';

interface FindingExceptionStatusBoxProps
  extends BaseComponentProps,
    FindingExceptionModel {
  hasApprovalRole?: boolean;
}

export const FindingExceptionStatusBox: FunctionComponent<
  FindingExceptionStatusBoxProps
> = ({
  status,
  request,
  response,
  expirationDate,
  id,
  findingId,
  revokeTimeCount,
  revokeTimeType,
}) => {
  const user = useAuthUser();

  const [declineDialogOpen, setDeclineDialogOpen] = useState<boolean>(false);

  const { t: translation } = useTranslation();

  const [
    approveFindingException,
    { isLoading: approveFindingExceptionLoading },
  ] = useApproveFindingExceptionMutation();

  const [revokeFindingException, { isLoading: revokeFindingExceptionLoading }] =
    useRevokeFindingExceptionMutation();

  const [cancelFindingException, { isLoading: cancelFindingExceptionLoading }] =
    useCancelFindingExceptionMutation();

  const handleDeclineDialogOpen = () => {
    setDeclineDialogOpen(true);
  };

  const handleDeclineDialogClose = () => {
    setDeclineDialogOpen(false);
  };

  const handleFindingExceptionApprove = () => {
    approveFindingException({
      findingId,
      exceptionId: id,
    });
  };

  const handleFindingExceptionRevoke = () => {
    revokeFindingException({
      findingId,
      exceptionId: id,
    });
  };

  const handleFindingExceptionCancel = () => {
    cancelFindingException({
      findingId,
      exceptionId: id,
    });
  };

  const renderAnswerButtonRow = () => {
    return (
      <div className="finding-exception-status-box-footer">
        <Button className="base-opus-button" onClick={handleDeclineDialogOpen}>
          {translation(`exceptions.buttons.decline`)}
        </Button>
        <Button
          className="base-opus-button opus-primary-button"
          onClick={handleFindingExceptionApprove}
        >
          {' '}
          {approveFindingExceptionLoading
            ? translation(`exceptions.buttons.approving`)
            : translation(`exceptions.buttons.approve`)}
        </Button>
      </div>
    );
  };

  const renderRevokeButtonRow = () => {
    return (
      <div className="finding-exception-status-box-footer">
        <div></div>
        <Button
          className="base-opus-text-button"
          onClick={handleFindingExceptionRevoke}
        >
          {revokeFindingExceptionLoading
            ? translation(`exceptions.buttons.revokingException`)
            : translation(`exceptions.buttons.revokeException`)}
        </Button>
      </div>
    );
  };

  const renderCancelButtonRow = () => {
    return (
      <div className="finding-exception-status-box-footer">
        <div></div>
        <Button
          className="base-opus-text-button"
          onClick={handleFindingExceptionCancel}
        >
          {cancelFindingExceptionLoading
            ? translation(`exceptions.buttons.cancelingException`)
            : translation(`exceptions.buttons.cancelRequest`)}
        </Button>
      </div>
    );
  };

  const renderButtonRow = () => {
    if (findingExceptionNegativeStatusList.includes(status)) return <></>;

    return (
      <>
        <AuthorizedContent
          requiredPermissions={[ApplicationPermission.EXCEPTIONS_WRITE_APPROVE]}
          render={(isAuthorized) => {
            if (!isAuthorized) return <></>;

            if (status === FindingExceptionStatus.PENDING)
              return renderAnswerButtonRow();
            return <></>;
          }}
        />
        <AuthorizedContent
          requiredPermissions={[ApplicationPermission.EXCEPTIONS_WRITE_APPROVE]}
          render={(isAuthorized) => {
            if (!isAuthorized) return <></>;

            if (status === FindingExceptionStatus.PENDING) return <></>;

            if (status === FindingExceptionStatus.ACTIVE)
              return renderRevokeButtonRow();

            return <></>;
          }}
        />
        {user.id === request.user.id &&
        status === FindingExceptionStatus.PENDING ? (
          renderCancelButtonRow()
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderExceptionParametersTable = () => {
    if (status === FindingExceptionStatus.PENDING) {
      return (
        <div className="finding-exceptions-status-box-body-table">
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.expirationTime'}
                defaultMessage="Expiration Time"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-expiration-time`}
            >
              {`${revokeTimeCount} ${revokeTimeType}`}
            </div>
          </div>
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.requestedBy'}
                defaultMessage="Requested By"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-requested-by`}
            >
              <AvatarChip
                label={request.user.name}
                avatarUrl={request.user.avatarUrl}
              />
            </div>
          </div>
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.status'}
                defaultMessage="Status"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`finding-exception-status`}
            >
              <Status label={findingExceptionStatusToLabel[status]} readonly />
            </div>
          </div>
        </div>
      );
    }

    if (
      status === FindingExceptionStatus.REVOKED ||
      status === FindingExceptionStatus.CANCELED ||
      status === FindingExceptionStatus.EXPIRED
    ) {
      return (
        <div className="finding-exceptions-status-box-body-table">
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.expirationTime'}
                defaultMessage="Expiration Time"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-expiration-time`}
            >
              {`${revokeTimeCount} ${revokeTimeType}`}
            </div>
          </div>
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.requestedBy'}
                defaultMessage="Requested By"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-requested-by`}
            >
              <AvatarChip
                label={request.user.name}
                avatarUrl={request.user.avatarUrl}
              />
            </div>
          </div>
        </div>
      );
    }

    if (status === FindingExceptionStatus.ACTIVE && response?.user) {
      return (
        <div className="finding-exceptions-status-box-body-table">
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.requestedBy'}
                defaultMessage="Requested By"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-requested-by`}
            >
              <AvatarChip
                label={request.user.name}
                avatarUrl={request.user.avatarUrl}
              />
            </div>
          </div>
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.approvedBy'}
                defaultMessage="Approved By"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-approved-by`}
            >
              <AvatarChip
                label={response.user.name}
                avatarUrl={response.user.avatarUrl}
              />
            </div>
          </div>
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.expirationDate'}
                defaultMessage="Expiration Date"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-expiration-date`}
            >
              {formatDate(new Date(expirationDate))}
            </div>
          </div>
        </div>
      );
    }

    if (status === FindingExceptionStatus.DECLINED && response?.user) {
      return (
        <div className="finding-exceptions-status-box-body-table">
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.requestedBy'}
                defaultMessage="Requested By"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-requested-by`}
            >
              <AvatarChip
                label={request.user.name}
                avatarUrl={request.user.avatarUrl}
              />
            </div>
          </div>
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.declinedBy'}
                defaultMessage="Declined By"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-declined-by`}
            >
              <AvatarChip
                label={response.user.name}
                avatarUrl={response.user.avatarUrl}
              />
            </div>
          </div>
          <div className="finding-exceptions-status-box-body-table-row">
            <div className="finding-exceptions-status-box-body-table-bold-cell finding-exceptions-status-box-body-table-cell">
              <FormattedMessage
                key={'exceptions.details.expirationTime'}
                defaultMessage="Expiration Time"
              />
              :
            </div>
            <div
              className="finding-exceptions-status-box-body-table-cell"
              id={`exception-expiration-time`}
            >
              {`${revokeTimeCount} ${revokeTimeType}`}
            </div>
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className="finding-exception-status-box-container">
      <div className="finding-exception-status-box-header">
        <div className="finding-exception-status-box-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CALENDAR_CLOCK_ICON} />
        </div>
        <div className="finding-exception-status-box-title">
          <FormattedMessage
            key={'exceptions.details.exceptionStatus'}
            defaultMessage="Exception Status"
          />
        </div>
      </div>
      <div className="finding-exception-status-box-body">
        <div className="finding-exception-status-box-body-description">
          {request.message}
        </div>
        {renderExceptionParametersTable()}
      </div>
      {renderButtonRow()}

      <FindingExceptionRevokeModal
        isOpen={declineDialogOpen}
        handleClose={handleDeclineDialogClose}
        exceptionId={id}
        findingId={findingId as string}
      />
    </div>
  );
};

import { AuthorizedContent } from '@frontegg/react';
import { Box, Button, Grid, Menu, MenuItem, Typography } from '@mui/material';
import { TreeItem, TreeItemProps } from '@mui/x-tree-view';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { useDeleteOrganizationNodeMutation } from 'Organization/store/api';
import { useState } from 'react';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OrganizationNodeContent,
  OrganizationNodeContentProps,
} from '../OrganizationNodeContent/OrganizationNodeContent';
import OrganizationNodeLabel from '../OrganizationNodeLabel';

type CombinedProps = Omit<TreeItemProps, 'children'> &
  OrganizationNodeContentProps;

export interface OrganizationTreeItemNodeProps extends CombinedProps {
  onButtonClick?: (data: OrganizationNodeType) => void;
  handleEditButton: (data: OrganizationNodeType) => void;
}

export const OrganizationTreeItemNode = ({
  type,
  label,
  onButtonClick,
  handleEditButton,
  ...props
}: OrganizationTreeItemNodeProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onButtonClick) {
      onButtonClick(type);
    }
  };

  const handleNodeClick = () => {
    if (type !== OrganizationNodeType.DATA) return;
    window.open(
      `${window.location.origin}/settings?activeTabId=business-units&mode=view&businessUnitId=${props.id}`,
      '_blank'
    );
  };

  const labelId = label
    ?.toString()
    .split(' ')
    .map((label) => label.toLowerCase())
    .join('-');

  const elementId = `organization-tree-item-node-${type}-${labelId}`;

  return (
    <TreeItem
      {...(props as TreeItemProps)}
      classes={{
        root: 'organization-tree-item-node',
        focused: 'organization-tree-item-node-focused',
        selected: 'organization-tree-item-node-selected',
      }}
      id={elementId}
      label={
        <Box
          onClick={handleNodeClick}
          display={'flex'}
          justifyContent="space-between"
          alignItems={'center'}
        >
          <Box
            display="flex"
            alignItems={'center'}
            gap={2}
            id={`${elementId}-label`}
          >
            <OrganizationNodeLabel
              type={type}
              label={label}
            ></OrganizationNodeLabel>
            <OrganizationNodeContent
              {...props}
              type={type}
              parentElementId={elementId}
            />
          </Box>
          <Box display={'flex'} columnGap={1} alignItems="center">
            {type !== OrganizationNodeType.DATA && (
              <AuthorizedContent
                requiredPermissions={[
                  ApplicationPermission.SETTINGS_ORGANIZATION_WRITE,
                ]}
              >
                <Grid>
                  <Button
                    className={`opus-secondary-button organization-node-add-button ${
                      type === OrganizationNodeType.ROOT && 'root-btn'
                    }`}
                    onClick={handleButtonClick}
                  >
                    <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />

                    {type === OrganizationNodeType.ROOT && (
                      <Typography variant="inherit">Add Scopes</Typography>
                    )}
                  </Button>
                </Grid>
              </AuthorizedContent>
            )}
            {type !== OrganizationNodeType.DATA && (
              <AuthorizedContent
                requiredPermissions={[
                  ApplicationPermission.SETTINGS_ORGANIZATION_WRITE,
                ]}
              >
                <Button
                  id="basic-button"
                  aria-controls={open ? 'node-action-menu' : undefined}
                  aria-haspopup="true"
                  className="common-button-secondary organization-node-action-button"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_STROKE_ICON} />
                </Button>
                <Menu
                  id="node-action-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(e: React.MouseEvent<HTMLElement>) => {
                    handleClose(e);
                  }}
                  classes={{ paper: 'node-action-menu-container' }}
                  MenuListProps={{
                    'aria-labelledby': 'node-action-menu',
                  }}
                >
                  {props.children.length === 0 && (
                    <MenuItem
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        handleClose(e);
                      }}
                    >
                      <DeleteWithConfirm
                        id={props.id}
                        title="Delete Node"
                        message="Are you sure you want to delete this node?"
                        useMutation={useDeleteOrganizationNodeMutation}
                        callback={() => {}}
                      >
                        <Button className="delete-action-button">
                          <Typography variant="inherit" className="body-1">
                            <FormattedMessage
                              id="common.delete"
                              defaultMessage="Delete"
                              capitalize
                            />
                          </Typography>
                        </Button>
                      </DeleteWithConfirm>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={(event) => {
                      handleEditButton(type);
                      handleClose(event);
                    }}
                  >
                    <Button className="delete-action-button">
                      <Typography variant="inherit" className="body-1">
                        <FormattedMessage
                          id="common.edit"
                          defaultMessage="Edit"
                          capitalize
                        />
                      </Typography>
                    </Button>
                  </MenuItem>
                </Menu>
              </AuthorizedContent>
            )}
          </Box>
        </Box>
      }
    />
  );
};

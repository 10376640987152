import { ChangeEvent, FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { InputLabelWrapper } from '../InputLabelWrapper/InputLabelWrapper';

interface FormInputProps extends BaseComponentProps {
  name: string;
  label: string;
  value?: string;
  onChange?: (changeValue: string) => void;
  placeholder?: string;
  errorMessage?: string;
  components?: {
    EndIcon?: JSX.Element;
    TitleRow?: JSX.Element;
  };
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  id?: string;
}

export const FormInput: FunctionComponent<FormInputProps> = ({
  name,
  label,
  value,
  onChange,
  placeholder,
  errorMessage,
  components = {},
  disabled = false,
  readOnly = false,
  type,
  id,
}) => {
  const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (onChange) onChange(event.target.value);
  };

  const renderInputTitle = () => {
    if (components?.TitleRow) {
      return <div className="form-input-title">{components.TitleRow}</div>;
    }

    return <></>;
  };
  return (
    <div className="form-input-container">
      {renderInputTitle()}
      <InputLabelWrapper label={label} errorMessage={errorMessage}>
        <div className="form-input-body">
          <div className="form-input-control">
            <input
              readOnly={readOnly}
              type={type}
              name={name}
              defaultValue={value}
              onChange={inputChangeHandler}
              placeholder={placeholder}
              className={`text-field-1 ${
                errorMessage ? 'input-validation-error-1' : ''
              }`}
              disabled={disabled}
              id={id}
            />

            {components.EndIcon ? (
              <div className="form-input-control-end-icon">
                {components.EndIcon}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </InputLabelWrapper>
    </div>
  );
};

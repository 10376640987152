import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import WebhookItem, {
  WebhookItemUpdate,
} from 'Integrations/Webhook/interfaces/item';
import { WebhookItemCreate } from '../interfaces/item';
import {
  PollingSubscription,
  PollingSubscriptionItemCreate,
  PollingTemplate,
} from '../interfaces/polling-subscription';

export interface WebhookResponse {
  webhooks: WebhookItem[];
  pollingSubscriptions: PollingSubscription[];
}

const webhookApi = createApi({
  reducerPath: 'webhookApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  tagTypes: ['Webhooks'],
  endpoints: (builder) => ({
    pollingTemplatesFetch: builder.query<PollingTemplate[], void>({
      query: () => ({
        url: 'webhooks/polling-templates',
        method: 'GET',
      }),
    }),
    pollingTemplatesFetchByAppId: builder.query<PollingTemplate, string>({
      query: (id) => ({
        url: `webhooks/polling-templates/${id}`,
        method: 'GET',
      }),
      providesTags: ['Webhooks'],
    }),
    pollingSubscriptionByConnectionId: builder.mutation<any, any>({
      query: (connectionId) => ({
        url: `webhooks/polling-subscriptions/${connectionId}`,
        method: 'GET',
      }),
      invalidatesTags: ['Webhooks'],
    }),
    pollingSubscriptionCreate: builder.mutation<
      Promise<void>,
      PollingSubscriptionItemCreate
    >({
      query: (itemCreate) => ({
        url: `webhooks/polling-subscriptions`,
        method: 'POST',
        body: itemCreate,
      }),
      invalidatesTags: ['Webhooks'],
    }),
    pollingSubscriptionEdit: builder.mutation<
      Promise<void>,
      PollingSubscription
    >({
      query: (itemUpdate: PollingSubscription) => ({
        url: `webhooks/polling-subscriptions/${itemUpdate.id}`,
        method: 'PATCH',
        body: itemUpdate,
      }),
      invalidatesTags: ['Webhooks'],
    }),
    pollingSubscriptionDelete: builder.mutation<Promise<void>, string>({
      query: (id) => ({
        url: `webhooks/polling-subscriptions/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Webhooks'],
    }),
    webhooksFetch: builder.query<WebhookResponse, void>({
      query: () => 'webhooks',
      transformResponse: async (response) => {
        return response as WebhookResponse;
      },
      providesTags: ['Webhooks'],
    }),
    webhookFetchByWebhookId: builder.query<WebhookItem, string>({
      query: (webhookId) => ({
        url: 'webhooks/search',
        method: 'POST',
        body: {
          id: webhookId,
        },
      }),
    }),
    webhookFetchByConnectionId: builder.mutation<WebhookItem, string>({
      query: (connectionId) => ({
        url: `webhooks/${connectionId}`,
        method: 'GET',
      }),
      invalidatesTags: ['Webhooks'],
    }),
    webhookDelete: builder.mutation<void, string>({
      query: (id) => ({
        url: `webhooks/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Webhooks'],
      transformResponse: async (response) => {
        // await delay(3000);  // dev; simulating latency
        // throw new Error('Test error (manual testing).'); // dev; simulating error
      },
    }),
    webhookCreate: builder.mutation<WebhookItem, WebhookItemCreate>({
      query: (itemCreate) => ({
        url: `webhooks`,
        method: 'POST',
        body: itemCreate,
      }),
      invalidatesTags: ['Webhooks'],
    }),
    webhookGenerate: builder.mutation<WebhookItem, string>({
      query: (applicationId) => ({
        url: `webhooks`,
        method: 'POST',
        body: { applicationId: applicationId },
      }),
      transformResponse: async (response) => {
        return response as WebhookItem;
      },
      invalidatesTags: ['Webhooks'],
    }),

    webhookUpdate: builder.mutation<WebhookItem, WebhookItemUpdate>({
      query: (itemUpdate) => ({
        url: `webhooks/${itemUpdate.id}`,
        method: 'PATCH',
        body: itemUpdate,
      }),
      invalidatesTags: ['Webhooks'],
    }),
  }),
});

export default webhookApi;

export const {
  usePollingTemplatesFetchQuery,
  usePollingSubscriptionCreateMutation,
  usePollingSubscriptionEditMutation,
  usePollingSubscriptionDeleteMutation,
  useWebhooksFetchQuery,
  useWebhookFetchByWebhookIdQuery,
  useWebhookDeleteMutation,
  useWebhookCreateMutation,
  useWebhookGenerateMutation,
  useWebhookUpdateMutation,
  usePollingTemplatesFetchByAppIdQuery,
  usePollingSubscriptionByConnectionIdMutation,
  useWebhookFetchByConnectionIdMutation,
} = webhookApi;

import { createApi } from '@reduxjs/toolkit/query/react';
import ConnectionTemplateItem, {
  InitConnectionResponse,
} from 'ConnectionTemplate/interfaces/item';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import { ConnectionItemCreate } from '../../Integrations/Connection/interfaces/item';
import ApplicationItem, {
  applicationInitBulk,
} from 'Application/interfaces/item';
import commonConfig from 'Config';

const connectionTemplateApi = createApi({
  reducerPath: 'connectionTemplateApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  endpoints: (builder) => ({
    fetchConnectionTemplates: builder.query<ApplicationItem[], void>({
      query: () => 'connections/templates',
      transformResponse: async (response) => {
        return applicationInitBulk(response as any[]);
      },
    }),
    searchConnectionTemplates: builder.mutation({
      query: (filters) => ({
        method: 'POST',
        url: `connections/templates/search`,
        body: filters,
      }),
    }),
    fetchConnectionTemplateById: builder.query<ConnectionTemplateItem, string>({
      query: (id) => ({
        url: `connections/templates/${id}`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as ConnectionTemplateItem;
      },
    }),
    initiateNewConnection: builder.mutation<
      InitConnectionResponse,
      ConnectionItemCreate
    >({
      query: (connectionItem: ConnectionItemCreate) => ({
        url: `connections/oauth/login`,
        method: 'POST',
        body: connectionItem,
      }),
      transformResponse: async (response) => {
        return response as InitConnectionResponse;
      },
    }),
  }),
});

export default connectionTemplateApi;

export const {
  useFetchConnectionTemplateByIdQuery,
  useInitiateNewConnectionMutation,
  useFetchConnectionTemplatesQuery,
  useSearchConnectionTemplatesMutation,
} = connectionTemplateApi;

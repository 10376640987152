import { CircularProgress } from '@mui/material';
import { AgGridEvent, RowClickedEvent } from 'ag-grid-community';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { resourceAssociatedFindingsTableColumns } from 'shared/fixtures/data/findings.data';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FindingOperational } from 'FindingDetails/interfaces/FindingsWithResources';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useSearchParams } from 'react-router-dom';
import { OrderState, OrderType } from 'Common/utils/sort';
import FindingDetailsModal from 'FindingDetails/components/FindingDetailsModal';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { GridType } from 'Risk/store';

export interface ResourceAssetDetailsDrawerAssociatedFindingsData {
  findings: {
    totalItems: number;
    data: Array<FindingOperational>;
  };
}

interface ResourceAssetDetailsDrawerAssociatedFindingsProps
  extends BaseComponentProps {
  resourceId: string;
}

const navigationHandler = new NavigationHandler();

export const ResourceAssetDetailsDrawerAssociatedFindings: FunctionComponent<
  ResourceAssetDetailsDrawerAssociatedFindingsProps
> = ({ resourceId }) => {
  const { t: translation } = useTranslation();

  const [, , getUrlSearchParams] = useQueryParams();

  const gridRef = useRef<AgGridEvent | null>(null);

  const [fetchFindingsWithResource, { isLoading, data: findingData }] =
    useFetchFindingsWithResourceMutation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [orderParams, setOrderParams] = useState<OrderState | null>({
    field: 'riskScore',
    type: OrderType.DESC,
  });

  const selectedFindingId = useMemo<string | null>(() => {
    return searchParams.get('openFindingId');
  }, [searchParams.get('openFindingId')]);

  const findingDetailsModelOpen = useMemo<boolean>(() => {
    return !!selectedFindingId;
  }, [selectedFindingId]);
  const onFindingClicked = (event: RowClickedEvent) => {
    const findingId = event.data?.findingId;

    const updatedSearchParams = new URLSearchParams(getUrlSearchParams());

    updatedSearchParams.set('openFindingId', findingId);

    setSearchParams(updatedSearchParams);
  };

  const handleFindingDetailsModalClose = () => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (updatedReactiveSearchParams.has('openFindingId'))
      updatedReactiveSearchParams.delete('openFindingId');

    setSearchParams(updatedReactiveSearchParams);
  };

  useEffect(() => {
    if (resourceId)
      fetchFindingsWithResource({
        filter: {
          loweredResourceId: [
            {
              value: resourceId,
            },
          ],
          state: {
            value: FindingState.ACTIVE,
            label: FindingState.ACTIVE,
          },
        },
        removeDuplications: false,
        order: orderParams as OrderState,
        take: pageSize,
        skip: currentPage && pageSize ? (currentPage - 1) * pageSize : 0,
      });
  }, [
    currentPage,
    pageSize,
    resourceId,
    orderParams?.field,
    orderParams?.type,
  ]);

  const onPageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    setCurrentPage(1);
    setPageSize(selectedPageSize);
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    if (columnOrderPayload.length) {
      const newOrderParams = {
        field: columnOrderPayload[0].field,
        type: columnOrderPayload[0].type,
      };

      setOrderParams(newOrderParams);
    } else {
      setOrderParams(null);
    }
  };

  const renderSectionBody = () => {
    if (!isLoading && findingData?.data?.length === 0) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div
        className={`finding-content-table-body ${
          isLoading ? 'finding-content-table-body-loading' : ''
        }`}
      >
        <CommonSimpleDataGrid
          rowData={findingData?.data}
          columnDefs={resourceAssociatedFindingsTableColumns}
          paginationProps={{
            pageSize: pageSize,
            currentPage: currentPage,
            onPageChange,
            onPageSizeChange,
            totalItems: findingData?.totalItems || 0,
          }}
          isLoading={isLoading}
          sortModel={orderParams as OrderState}
          onSort={onHandleColumnOrderChange}
          defaultColDef={{
            resizable: true,
            sortable: false,
            lockPinned: true,
            lockPosition: true,
            lockVisible: true,
          }}
          onRowClicked={onFindingClicked}
          otherComponents={{
            NoDataBackdropComponent: <NoDataBackdrop />,
          }}
          loadingOverlayComponent={() => (
            <div className="finding-content-table-body-loading-wrapper">
              <CircularProgress />
            </div>
          )}
          gridRef={gridRef}
          domLayout="autoHeight"
        />
      </div>
    );
  };

  const handleGoToRisks = () => {
    navigationHandler.handleRiskNavigation(GridType.None, {
      state: {
        id: FindingState.ACTIVE,
        name: FindingState.ACTIVE,
      },
      loweredResourceId: [
        {
          id: resourceId,
          name: resourceId,
        },
      ],
    });
  };

  return (
    <div
      className="finding-content-item finding-content-related-findings-container"
      id="related-findings-resource"
    >
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section related-findings-content-table"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.RADAR_ICON} />}
        title="Associated Findings"
        sectionNavigateName={
          findingData?.data?.length
            ? translation(`findings.subSections.goToRisksView`)
            : undefined
        }
        onSectionNavigate={handleGoToRisks}
      >
        {renderSectionBody()}
        <FindingDetailsModal
          open={findingDetailsModelOpen}
          handleClose={handleFindingDetailsModalClose}
          handleCloud2CodeModalOpen={() => {}}
          findingId={selectedFindingId as string}
          setFindingId={(id: string) => {}}
        />
      </ContentSection>
    </div>
  );
};

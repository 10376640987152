import {
  RiskCustomizationDto,
  RiskFactorSettingsModel,
  RuleProperty,
  RuleStatus,
} from 'Settings/interfaces/RiskCustomizationConfig';
import { RiskCustomizationConfigTypes } from 'Settings/interfaces/RiskCustomizationConfigTypes.enum';
import {
  CategoryState,
  ExtendedFilterCategory,
} from 'shared/models/data/data-filter.model';
import { SettingsDataHandler } from './settings-data.handler';

export type RiskCustomizationBaseFormData = Record<
  'name' | 'description',
  string
>;

export type RiskCustomizationAdditionalFormData = {
  properties: Record<string, CategoryState>;
  values: RiskCustomizationDto;
};

export type RiskCustomizationDefaultFormData = RiskCustomizationBaseFormData &
  RiskCustomizationAdditionalFormData;

export class RiskCustomizationDataHandler extends SettingsDataHandler {
  getDefaultFactors() {
    const defaultFactors = {} as any;

    for (const key of Object.values(RiskCustomizationConfigTypes)) {
      defaultFactors[key] = {
        weight: 0,
        multiplier: 0.1,
      };
    }
    return { factors: defaultFactors } as RiskCustomizationDto;
  }
  transformRiskCustomizationDataToFormData(
    riskConfig: any
  ): RiskCustomizationDefaultFormData {
    let ruleProperties: Record<string, CategoryState> =
      this.transformRulePropertiesToFormProperties(riskConfig.properties);

    return {
      name: riskConfig.name,
      description: riskConfig.description as string,
      properties: ruleProperties,
      values: riskConfig.values,
    };
  }
  transformFactors(
    factors: Record<string, any>
  ): Record<string, RiskFactorSettingsModel> {
    return Object.fromEntries(
      Object.entries(factors).map(([key, value]) => [
        key,
        typeof value === 'number' ? { weight: value, multiplier: 0.1 } : value,
      ])
    );
  }
  transformRiskCustomizationFormDataToPostData(
    formData: RiskCustomizationBaseFormData,
    additionalFormData: RiskCustomizationAdditionalFormData,
    categories?: Array<ExtendedFilterCategory>
  ): any {
    let properties: Array<RuleProperty> =
      this.transformFormPropertiesToRuleProperties(
        additionalFormData.properties,
        categories
      );

    const transformedFactors = this.transformFactors(
      additionalFormData.values.factors
    );

    return {
      name: formData.name,
      description: formData.description,
      properties,
      values: { factors: transformedFactors },
      status: RuleStatus.ENABLED,
    };
  }
}

export enum CloudProviderType {
  AWS = 'AWS',
  AZURE = 'Azure',
  GCP = 'GCP',
  GITHUB = 'Github',
  GITLAB = 'Gitlab',
  AZURE_REPOS = 'AzureRepos',
  ON_PREM = 'On-Premises',
  DOMAIN = 'Domain',
}

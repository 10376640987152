import { Box, Button, CircularProgress, Grid } from '@mui/material';
import CardWrapper from 'Common/components/CardWrapper';
import ForbiddenPage from 'Common/components/ForbiddenPage';
import { useEffect, useState } from 'react';
import {
  useDeleteRiskCustomizationItemMutation,
  useFetchRiskCustomizationItemsQuery,
  useUpdateRiskCustomuzationMutation,
} from 'Settings/store/api';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import AddIcon from '@mui/icons-material/Add';

import { AuthorizedContent } from '@frontegg/react';
import FormattedMessage from 'shared/components/FormattedMessage';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SettingsTabPanel } from 'Settings/interfaces/SettingTabPanel.enum';
import { DrawerMode } from 'shared/enums/business-unit.enum';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import RiskCustomizationDrawerPanel from './RiskCustomizationDrawerPanel';
import {
  RiskConfig,
  RuleStatus,
} from 'Settings/interfaces/RiskCustomizationConfig';
import React from 'react';
import RiskAndSlaCard from '../RiskAndSlaCard/RiskAndSlaCard';
import SearchField from 'Common/components/SearchField/SearchField';

export const RiskCustomization: React.FC = () => {
  const { data: riskFactories, isLoading } =
    useFetchRiskCustomizationItemsQuery('', {
      refetchOnMountOrArgChange: true,
    });
  const { t: translation } = useTranslation();
  const [searchParams, setSearchParams] = useQueryParams();
  const permissionActions = usePermissions();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const [filteredRiskFactories, setFilteredData] = useState<any[] | undefined>(
    []
  );
  const [formCancelationModalOpen, setFormCancelationModalOpen] =
    useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const hasReadRiskScorePermission = permissionActions.includes(
    ApplicationPermission.SETTINGS_RISK_SCORE_READ
  );

  useEffect(() => {
    setFilteredData(riskFactories);
  }, [isLoading, riskFactories]);

  const [updateRiskCustomization] = useUpdateRiskCustomuzationMutation();

  const drawerOpenHandler = () => {
    setSearchParams({
      activeTabId: SettingsTabPanel.RISK_CUSTOMIZATION,
      mode: DrawerMode.CREATE,
    });
  };

  useEffect(() => {
    setPopoverOpen(!!searchParams.mode);
  }, [searchParams]);

  const drawerCloseHandler = () => {
    setPopoverOpen(false);
    setSearchParams({
      activeTabId: SettingsTabPanel.RISK_CUSTOMIZATION,
    });
  };

  const rowClickHandler = (id: string) => {
    setSearchParams({
      ...searchParams,
      mode: DrawerMode.EDIT,
      ruleId: id as string,
    });
  };

  const updateStatus = (checked: boolean, id: string) => {
    const updatedItem = riskFactories?.find((item) => item.id === id);
    updateRiskCustomization({
      ...updatedItem,
      id: id,
      status: checked ? RuleStatus.ENABLED : RuleStatus.DISABLED,
    });
  };

  const getDefaultValues = () => {
    return riskFactories?.find((item) => item.isDefault)?.values;
  };

  const renderBody = () => {
    if (isLoading)
      return (
        <Box textAlign="center" p="2rem">
          <CircularProgress />
        </Box>
      );
    if (filteredRiskFactories && filteredRiskFactories.length > 0)
      return (
        <>
          {filteredRiskFactories?.map((element: RiskConfig) => (
            <RiskAndSlaCard
              requiredWritePermission={
                ApplicationPermission.SETTINGS_RISK_SCORE_WRITE
              }
              isDefault={element.isDefault}
              name={element.name}
              id={element.id}
              description={element.description}
              properties={element.properties}
              onEditClick={rowClickHandler}
              onSwitchClicked={updateStatus}
              useDeleteMutation={useDeleteRiskCustomizationItemMutation}
              status={element.status}
              attributes={element.values}
              disabledTooltipContent={translation(
                'settings.details.disabledDefaultMessage',
                {
                  param: translation(
                    'settings.details.riskDisabledDefaultMessage'
                  ),
                }
              )}
            ></RiskAndSlaCard>
          ))}
        </>
      );

    return (
      <div className="no-data-container">{translation(`common.noData`)}</div>
    );
  };

  const handlePopoverClose = () => {
    formChanged ? setFormCancelationModalOpen(true) : drawerCloseHandler();
  };

  if (!hasReadRiskScorePermission) return <ForbiddenPage />;

  return (
    <>
      <div className="risk-customization-container">
        <CardWrapper padding={2} className={`settings-header`} height={'64px'}>
          <Grid className="settings-header-container" container>
            <Grid item className="settings-header-filters">
              <>
                <SearchField
                  setTableItems={(items) => {
                    setFilteredData(items);
                  }}
                  items={riskFactories}
                  filterPropertyName={'name'}
                ></SearchField>
              </>
            </Grid>
            <Grid item className="settings-header-action-button">
              <AuthorizedContent
                requiredPermissions={[ApplicationPermission.SETTINGS_BUS_WRITE]}
                render={(isAuthorized) => (
                  <Button
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={() => drawerOpenHandler()}
                    className="opus-primary-button settings-header-button"
                    disabled={!isAuthorized}
                  >
                    <FormattedMessage
                      id="settings.details.addRiskCustomization"
                      defaultMessage="Add Risk Customization"
                    />
                  </Button>
                )}
              />
            </Grid>
          </Grid>
        </CardWrapper>
        <div className="risk-list-container"> {renderBody()}</div>
        <Box mt={2} className="settings-pagination" mx={2}></Box>
      </div>

      <PopoverWrapper
        showPopover={popoverOpen}
        handleClose={handlePopoverClose}
      >
        <RiskCustomizationDrawerPanel
          onCancel={drawerCloseHandler}
          defaultAttributes={getDefaultValues()}
          detectFormChanged={(isChanged: boolean) => setFormChanged(isChanged)}
          cancelationModalProps={{
            onClose: () => {
              setFormCancelationModalOpen(false);
            },
            onOpen: () => {
              setFormCancelationModalOpen(true);
            },
            open: formCancelationModalOpen,
          }}
        />
      </PopoverWrapper>
    </>
  );
};

import OperationalWidgetCard from 'Dashboard/components/OperationalWidgetCard';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { DashboardWidgetHandler } from 'shared/handlers/dashboard-widget.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import DashboardReportsRiskMetricCard from './components/DashboardReportsRiskMetricCard';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';

interface DashboardReportsRiskTrendMetricsWidgetProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {
  timeFrame: TimelineFilter;
}

const dashboardWidgetHandler = new DashboardWidgetHandler();

export const DashboardReportsRiskTrendMetricsWidget: FunctionComponent<
  DashboardReportsRiskTrendMetricsWidgetProps
> = ({ businessUnitIds, findingTypes, timeFrame }) => {
  const { t: translation } = useTranslation();

  const renderWidgetCards = () => {
    const widgets = dashboardWidgetHandler.getOperationalWidgets(translation);

    return widgets.map((widget) => (
      <DashboardReportsRiskMetricCard
        {...widget}
        businessUnitIds={businessUnitIds}
        timeFrame={timeFrame}
        findingTypes={findingTypes}
      />
    ));
  };

  return (
    <div className="dashboard-reports-risk-metrics-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.RADAR_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Trends Metrics ({timeFrame})
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-metrics-widget-body">
        {renderWidgetCards()}
      </div>
    </div>
  );
};

import { Chart } from 'chart.js';
import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { ChartData } from 'shared/models/data/chart.model';
import { SimpleLineChartProps } from 'shared/models/props/chart-props.model';
import { v4 as uuid4 } from 'uuid';
import BaseChart from '../BaseChart';

export const SimpleLineChart: FunctionComponent<
  SimpleLineChartProps<ChartData>
> = ({
  fetchWidgetData,
  isWidgetDataLoading,
  widgetData,
  displayLegend = false,
  displayLabels = false,
  enableTooltip = false,
  aspectRatio = 3,
  isResponsive = true,
  legendPosition = 'top',
  style = {},
  applyColorList = false,
  customLegendContainerId,
  options,
}) => {
  const chartId = useMemo(() => {
    return uuid4();
  }, []);

  const chartOptions = useMemo(() => {
    return {
      ...options,
      aspectRatio,
      responsive: isResponsive,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: enableTooltip,
        },
        datalabels: {
          display: displayLabels,
        },
        htmlLegend: {
          containerID:
            customLegendContainerId || `chart-legend-container-${chartId}`,
        },
      },
      scales: {
        x: {
          ticks: {
            padding: 20, // set the padding around the x-axis text
            maxTicksLimit: 5,
            font: {
              size: 11, // set the font size to 14 pixels
              family: 'Arial', // set the font family of the x-axis text to Arial
              width: 10,
              height: 10,
            },
            maxRotation: 0,
          },
        },
        y: {
          ticks: {
            maxTicksLimit: 5,
            font: {
              size: 11, // set the font size to 14 pixels
              family: 'Arial', // set the font family of the x-axis text to Arial
              width: 10,
              height: 10,
            },
          },
        },
      },
      ...options,
    };
  }, []);

  return (
    <BaseChart
      options={chartOptions}
      type="line"
      {...{
        fetchWidgetData,
        isWidgetDataLoading,
        widgetData,
        style,
        applyColorList,
        displayLegend,

        legendPosition,
        chartId,
      }}
    />
  );
};

import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { v4 as uuid4 } from 'uuid';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CommonButton } from 'shared/components/CommonButton';
import { IntegrationModalAccordion } from '../IntegrationModalAccordion';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  awsIntegrationFormDefaultSchema,
  awsIntegrationFormRequiredFields,
  awsIntegrationInputKeysToInputLabels,
} from 'shared/fixtures/data/integrations.data';
import CopyButton from 'Common/components/CopyButton';
import {
  useCreateCloudConnectionMutation,
  useCreateMultiCloudConnectionMutation,
  useVerifyCloudConnectionMutation,
} from 'Integrations/Connection/store/api';
import {
  CloudProviderType,
  IntegrationActionType,
} from 'shared/models/data/integrations.model';
import { AlertBox, AlertBoxType } from 'shared/components/AlertBox/AlertBox';
import { CircularProgress } from '@mui/material';
import { AlertExpandBox } from 'shared/components/AlertExpandBox/AlertExpandBox';
import { IntegrationsDataHandler } from 'shared/handlers/integrations-data.handler';
import { useTranslation } from 'react-i18next';
import Autocomplete from 'Common/components/Autocomplete';
import { businessUnitEnvironmentTypesOptionList } from 'shared/fixtures/data/business-unit.data';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNodeDataHandler as OrganizationNodeOptionDataHandler } from 'shared/handlers/organization-node-data.handler';
import AwsIntegrationManagementSection from '../AwsIntegrationManagementSection';
import { AWSAccountType } from '../AwsIntegrationManagementSection/AwsIntegrationManagementSection';

interface AwsIntegrationModalCreateFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

const integrationsDataHandler = new IntegrationsDataHandler();

const organizationNodeOptionDataHandler =
  new OrganizationNodeOptionDataHandler();

export const AwsIntegrationModalCreateForm: FunctionComponent<
  AwsIntegrationModalCreateFormProps
> = ({
  onCancel,
  connectionTemplateData,
  connectionTemplateDataLoading,
  rootClassName,
  renderButtonRow,
  onCreateSuccess,
}) => {
  const { t: translation } = useTranslation();

  const [accountType, setAccountType] = useState<AWSAccountType>(
    AWSAccountType.SINGLE
  );

  const {
    setValue: setFormValue,
    clearErrors: clearInputErrors,
    getValues: getFormValues,
    setError: setFormError,
    formState: { errors: formErrors },
    watch: watchFormValue,
  } = useForm({
    resolver: yupResolver(yup.object().shape(awsIntegrationFormDefaultSchema)),
    defaultValues: {
      id: '',
      externalId: uuid4(),
      environmentType: '',
      name: '',
      readRoleArn: '',
      writeRoleArn: '',
      writeApplicationIds: [] as Array<string>,
      scopeId: '',
    },
  });
  const scopeIdFormValue = watchFormValue('scopeId');
  const writeApplicationIds = watchFormValue('writeApplicationIds');

  const [
    createCloudConnection,
    {
      data: createCloudConnectionData,
      isLoading: createCloudConnectionLoading,
      error: createCloudConnectionError,
      isSuccess: createCloudConnectionSuccess,
      reset: createCloudConnectionReset,
    },
  ] = useCreateCloudConnectionMutation();

  const [
    createMultiCloudConnection,
    {
      data: createMultiCloudConnectionData,
      isLoading: createMultiCloudConnectionLoading,
      error: createMultiCloudConnectionError,
      isSuccess: createMultiCloudConnectionSuccess,
      reset: createMultiCloudConnectionReset,
    },
  ] = useCreateMultiCloudConnectionMutation();

  const createHandler = useMemo<any>(() => {
    if (accountType === AWSAccountType.SINGLE) return createCloudConnection;

    return createMultiCloudConnection;
  }, [accountType, createCloudConnection, createMultiCloudConnection]);

  const createResetHandler = useMemo<any>(() => {
    if (accountType === AWSAccountType.SINGLE)
      return createCloudConnectionReset;
    return createMultiCloudConnectionReset;
  }, [
    accountType,
    createCloudConnectionReset,
    createMultiCloudConnectionReset,
  ]);

  const createLoading = useMemo<boolean>(() => {
    return createCloudConnectionLoading || createMultiCloudConnectionLoading;
  }, [createCloudConnectionLoading, createMultiCloudConnectionLoading]);

  const createError = useMemo<any>(() => {
    return createCloudConnectionError || createMultiCloudConnectionError;
  }, [createCloudConnectionError, createMultiCloudConnectionError]);

  const createSuccess = useMemo<boolean>(() => {
    return createCloudConnectionSuccess || createMultiCloudConnectionSuccess;
  }, [createCloudConnectionSuccess, createMultiCloudConnectionSuccess]);

  const [
    verifyConnection,
    {
      isLoading: verifyConnectionLoading,
      error: verifyConnectionError,
      data: verifyConnectionPayload,
      isSuccess: verifyConnectionSuccess,
      reset: verifyConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  const [
    verifySubConnection,
    {
      isLoading: verifySubConnectionLoading,
      error: verifySubConnectionError,
      data: verifySubConnectionPayload,
      isSuccess: verifySubConnectionSuccess,
      reset: verifySubConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  const [fetchScopeList, { data: scopeData, isLoading: scopeDataLoading }] =
    useGetUserAvailableNodesOfTypeMutation();
  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);

  const scopeAutocompleteList: Array<AutocompleteOption> = useMemo(() => {
    return scopeData
      ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeData,
          true
        )
      : [];
  }, [scopeData]);

  useEffect(() => {
    if (createSuccess) {
      onCreateSuccess &&
        onCreateSuccess(
          createCloudConnectionData?.connection?.id ||
            (createMultiCloudConnectionData?.length &&
              createMultiCloudConnectionData[0]?.connection?.id)
        );
      onCancel();
    }
  }, [createSuccess]);

  const inputChangeHandler = (
    inputName: keyof typeof awsIntegrationFormDefaultSchema,
    inputValue: string
  ) => {
    if (inputValue.length) {
      clearInputErrors(inputName);
    }

    setFormValue(inputName, inputValue?.trim());
  };

  const writeArnHandler = (writeArnValue: string) => {
    if (writeArnValue.length) {
      clearInputErrors('writeRoleArn');
    }

    setFormValue('writeRoleArn', writeArnValue?.trim());
  };

  const validateFormBeforeSubmit = () => {
    let valid = true;

    const formValues = getFormValues();

    for (const requiredInputKey of awsIntegrationFormRequiredFields) {
      const typedInputKey =
        requiredInputKey as keyof typeof awsIntegrationFormDefaultSchema;
      if (formValues[typedInputKey].length === 0) {
        valid = false;
        setFormError(typedInputKey, {
          type: 'required',
          message: `${awsIntegrationInputKeysToInputLabels[typedInputKey]} is required`,
        });
      }
    }

    return valid;
  };

  const validateWriteRoleARN = () => {
    let valid = true;
    const writeRoleArn = getFormValues().writeRoleArn;

    if (writeRoleArn.length === 0) {
      valid = false;
      setFormError('writeRoleArn', {
        type: 'required',
        message: 'Write role ARN is required',
      });
    }

    return valid;
  };

  const selectedScopeOption = useMemo<AutocompleteOption | undefined>(() => {
    return integrationsDataHandler.getSelectedScopeOption(
      scopeAutocompleteList,
      scopeIdFormValue
    );
  }, [scopeAutocompleteList, scopeIdFormValue]);

  const connectSubApplicationHandler = (applicationId: string) => {
    const isSubFormValid = validateWriteRoleARN();

    if (isSubFormValid) {
      const subApplicationIds: Array<string> =
        getFormValues().writeApplicationIds || [];

      if (subApplicationIds.includes(applicationId)) {
        setFormValue(
          'writeApplicationIds',
          subApplicationIds.filter((id) => id !== applicationId)
        );
      } else {
        setFormValue('writeApplicationIds', [
          ...subApplicationIds,
          applicationId,
        ]);
      }
    }
  };

  const connectHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      const createPayload = {
        ...formValues,
        scopeId:
          formValues.scopeId && formValues.scopeId !== 'None'
            ? formValues.scopeId
            : null,
        cloudProvider: CloudProviderType.AWS,
        accountType,
      };

      createHandler(createPayload);
    }
  };

  const verifyHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      verifyConnection({
        cloudProvider: CloudProviderType.AWS,
        body: {
          ...formValues,
          scopeId:
            formValues.scopeId && formValues.scopeId !== 'None'
              ? formValues.scopeId
              : null,
          awsAppId: CloudProviderType.AWS,
          accountType,
        },
      });
    }
  };

  const verifySubApplicationHandler = (applicationId: string) => {
    resetApiState();
    const isSubFormValid = validateWriteRoleARN();

    if (isSubFormValid) {
      const formValues = getFormValues();

      verifySubConnection({
        cloudProvider: CloudProviderType.AWS,
        body: {
          writeRoleArn: formValues.writeRoleArn,
          externalId: formValues.externalId,
          awsAppId: applicationId,
        },
      });
    }
  };

  const resetApiState = () => {
    createResetHandler();
    verifyConnectionReset();
    verifySubConnectionReset();
  };

  const renderAlertBox = () => {
    if (createError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.CREATING,
            'AWS'
          )}
          description={
            (createError as any)?.data?.message ||
            (createError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            (verifyConnectionError as any)?.data?.message ||
            (verifyConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifySubConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            (verifySubConnectionError as any)?.data?.message ||
            (verifySubConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload?.success === false) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            verifyConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (
      verifySubConnectionPayload &&
      verifySubConnectionPayload?.success === false
    ) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            verifySubConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    if (verifySubConnectionPayload && verifySubConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    return <></>;
  };

  const renderDeployStack = () => {
    if (connectionTemplateDataLoading) {
      return <CircularProgress size={24} />;
    }
    return (
      <div className="cloud-integration-modal-form-deploy-stack">
        <div className="cloud-integration-modal-form-deploy-stack-text">
          {connectionTemplateData?.read?.description}
        </div>
        <div className="cloud-integration-modal-form-deploy-stack-button">
          <CommonButton type={CommonButtonType.TEXT}>
            <a
              href={connectionTemplateData?.read?.definition?.props?.deployLink}
              className="cloud-integration-deploy-button"
              target="_blank"
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.DEPLOY_ICON} />
              Deploy Stack
            </a>
          </CommonButton>
        </div>
      </div>
    );
  };

  const renderConnectButtonRow = () => {
    if (renderButtonRow) {
      return renderButtonRow({
        connectHandler,
        verifyHandler,
        connecting: createLoading,
        verifying: verifyConnectionLoading,
      });
    }
    return (
      <div className="integration-modal-button-row">
        <div className="integration-modal-cancel-buttons">
          <CommonButton type={CommonButtonType.SECONDARY} onClick={onCancel}>
            Cancel
          </CommonButton>
        </div>
        <div className="integration-modal-connection-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={verifyHandler}
          >
            {verifyConnectionLoading
              ? 'Verifying Connectivity'
              : 'Verify Connectivity'}
          </CommonButton>
          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={connectHandler}
          >
            {createLoading ? 'Connecting' : 'Connect'}
          </CommonButton>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderAlertBox()}
      <div className={`cloud-integration-modal-form-wrapper ${rootClassName}`}>
        <div className="cloud-integration-modal-form-area">
          <form className="cloud-integration-modal-form">
            <FormInput
              label="Workspace Name"
              name="workspaceName"
              placeholder="Please type workspace name"
              value={getFormValues().name}
              onChange={(value: string) => {
                inputChangeHandler('name', value);
              }}
              errorMessage={formErrors?.name?.message}
            />
            <InputLabelWrapper label="Scope">
              {scopeAutocompleteList === null || scopeDataLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Autocomplete
                  model="scopeId"
                  optionList={scopeAutocompleteList}
                  initialSelectedValues={selectedScopeOption}
                  onChangeCallBack={(
                    model: string,
                    option: AutocompleteOption | Array<AutocompleteOption>
                  ) => {
                    inputChangeHandler(
                      'scopeId',
                      (option as AutocompleteOption).value
                    );
                  }}
                  classes={{
                    root: 'multi-select-field-1',
                    paper: 'multi-select-field-paper-1',
                  }}
                  single
                  areOptionsLoaded
                />
              )}
            </InputLabelWrapper>

            <InputLabelWrapper
              label="Workspace Environment Type"
              errorMessage={formErrors?.environmentType?.message}
            >
              <Autocomplete
                model="workspaceEnvironmentType"
                optionList={businessUnitEnvironmentTypesOptionList}
                onChangeCallBack={(
                  model: string,
                  option: AutocompleteOption | Array<AutocompleteOption>
                ) => {
                  inputChangeHandler(
                    'environmentType',
                    (option as AutocompleteOption).value
                  );
                }}
                classes={{
                  root: 'multi-select-field-1',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: formErrors.environmentType
                    ? 'input-validation-error-1'
                    : '',
                }}
                single
              />
            </InputLabelWrapper>

            <FormInput
              label="External ID"
              name="externalId"
              placeholder="Please type workspace name"
              value={getFormValues().externalId}
              onChange={(value: string) => {
                inputChangeHandler('externalId', value);
              }}
              components={{
                EndIcon: (
                  <CopyButton
                    textToCopy={getFormValues().externalId}
                  ></CopyButton>
                ),
              }}
              disabled
            />

            <AwsIntegrationManagementSection
              template={connectionTemplateData?.read}
              teamOwnerProps={{
                value: getFormValues().readRoleArn,
                changeHandler: (value: string) => {
                  inputChangeHandler('readRoleArn', value);
                },
                errorMessage: formErrors?.readRoleArn?.message,
              }}
              setAccountType={(type: AWSAccountType) => {
                setAccountType(type);
              }}
            />
          </form>

          <div className="cloud-integration-modal-accordion-area">
            <IntegrationModalAccordion
              parentApplicationId="AWS"
              title="Write Access"
              selectedApplicationIds={writeApplicationIds}
              arnProps={{
                enableArn: true,
                onArnValueChange: writeArnHandler,
                arnValidationError: formErrors.writeRoleArn?.message,
                arnValue: getFormValues().writeRoleArn,
              }}
              subApplicationProps={{
                onConnect: connectSubApplicationHandler,
                onVerify: verifySubApplicationHandler,
              }}
              template={connectionTemplateData?.write}
            />
          </div>
        </div>

        <div className="cloud-integration-modal-button-area">
          {renderConnectButtonRow()}
        </div>
      </div>
    </>
  );
};

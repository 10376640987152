import { Checkbox, CheckboxProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

interface CommonCheckboxProps extends CheckboxProps {}

export const CommonCheckbox: FunctionComponent<CommonCheckboxProps> = (
  props
) => {
  return (
    <Checkbox
      classes={{
        root: 'common-checkbox',
      }}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.UNCHECKED_CHECKBOX_ICON} />}
      checkedIcon={<OpusSvgIcon type={SVG_ICON_TYPES.CHECKED_CHECKBOX_ICON} />}
      indeterminateIcon={
        <OpusSvgIcon type={SVG_ICON_TYPES.INDETERMINATE_CHECKBOX_ICON} />
      }
      {...props}
    />
  );
};

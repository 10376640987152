import { Typography } from '@mui/material';
import {
  ResourceRiskAttributeLabel,
  ResourceRiskAttributeTypes,
} from 'Assets/interfaces/ResourceRiskAttributes.enum';
import CardWrapper from 'Common/components/CardWrapper';
import { FunctionComponent } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface ResourceAttributesProps extends BaseComponentProps {
  attributes: {
    [key: string]: boolean | null;
  };
  displayLabel?: boolean;
}

export const ResourceAttributes: FunctionComponent<ResourceAttributesProps> = ({
  attributes,
  displayLabel = false,
}) => {
  const resourceAttributes = [
    {
      id: ResourceRiskAttributeTypes.CROWN_JEWELS,
      label: ResourceRiskAttributeLabel.CROWN_JEWELS,
      icon: SVG_ICON_TYPES.CROWN_ICON,
    },
    {
      id: ResourceRiskAttributeTypes.SENSITIVE_DATA,
      label: ResourceRiskAttributeLabel.SENSITIVE_DATA,
      icon: SVG_ICON_TYPES.EYES_ICON,
    },
    {
      id: ResourceRiskAttributeTypes.EXTERNAL_FACING,
      label: ResourceRiskAttributeLabel.EXTERNAL_FACING,
      icon: SVG_ICON_TYPES.ARROW_UP_RIGHT_FROM_SQUARE_ICON,
    },
  ];

  if (!attributes) {
    return null;
  }

  return (
    <div className="resource-attributes-container">
      {Object.entries(attributes).map(([key, value], index) => {
        if (value && resourceAttributes.some((attr) => attr.id === key)) {
          const item = resourceAttributes.find((attr) => attr.id === key)!;

          return displayLabel ? (
            <CardWrapper key={index} className="attribute-content-with-label">
              <OpusSvgIcon type={item.icon} title={item.id} />
              <p className="body-2">{item.label}</p>
            </CardWrapper>
          ) : (
            <CardWrapper key={index} padding={1} className="attribute-content">
              <OpusSvgIcon type={item.icon} title={item.id} />
            </CardWrapper>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ResourceAttributes;

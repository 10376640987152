import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { AssetType } from 'Risk/interfaces/AssetType.enum';
import { useSearchParams } from 'react-router-dom';
import ResourceAssetDetailsDrawerAssociatedFindings from '../ResourceAssetDetailsDrawerAssociatedFindings';
import ResourceAssetDetailsDrawerAdditionalData from '../ResourceAssetDetailsDrawerAdditionalData';
import ResourceAssetDetailsDrawerHeader from '../ResourceAssetDetailsDrawerHeader';
import ResourceAssetDetailsDrawerOwners from '../ResourceAssetDetailsDrawerOwners';
import ResourceAssetDetailsDrawerResourceInfo from '../ResourceAssetDetailsDrawerResourceInfo';
import { useFetchAssetsDetailsByIdMutation } from 'Assets/store/api';
import { ResourcePerSensorData } from 'Assets/interfaces/ResourcePerSensorData.model';
import { ResourceDetailsTab } from 'Assets/interfaces/ResourceDetailsTab.enum';
import { TabList, TabListItem } from 'shared/components/TabList/TabList';
import ResourceContentActivity from '../ResourceContentActivity';

interface ResourceAssetDetailsDrawerContentProps extends BaseComponentProps {}

export interface ResourceAssetDetailsDrawerContentSectionProps<T> {
  isLoading: boolean;
  getData: () => T;
  error?: any;
}
export const tabList: Array<TabListItem> = [
  {
    id: ResourceDetailsTab.DETAILS,
    label: ResourceDetailsTab.DETAILS,
  },

  {
    id: ResourceDetailsTab.ACTIVITY,
    label: ResourceDetailsTab.ACTIVITY,
  },
];

export const ResourceAssetDetailsDrawerContent: FunctionComponent<
  ResourceAssetDetailsDrawerContentProps
> = () => {
  const [searchParams] = useSearchParams();
  const [activeTabId, setActiveTabId] = useState<ResourceDetailsTab>(
    ResourceDetailsTab.DETAILS
  );
  const openResourceId = useMemo<string | null>(() => {
    return searchParams.get('openResourceId') || null;
  }, [searchParams.get('openResourceId')]);

  const changeTabHandler = (tabId: ResourceDetailsTab) => {
    setActiveTabId(tabId);
  };
  const [
    searchResourceData,
    {
      data: resourceData,
      isLoading: resourceDataLoading,
      error: resourceDataError,
    },
  ] = useFetchAssetsDetailsByIdMutation();

  useEffect(() => {
    if (openResourceId) {
      searchResourceData({
        id: openResourceId,
        type: AssetType.ResourceDataPerSensor,
      });
    }
  }, [openResourceId]);

  const renderTabList = () => {
    return (
      <TabList
        items={tabList}
        activeItemId={activeTabId}
        onSelect={changeTabHandler}
        isUnderlined
      />
    );
  };

  const renderContentForTabs = () => {
    switch (activeTabId) {
      case ResourceDetailsTab.DETAILS:
        return (
          <>
            <ResourceAssetDetailsDrawerResourceInfo
              resourceData={resourceData?.assetData}
              resourcePerSensorData={resourceData?.perSensorData}
              resourceDataError={resourceDataError}
              resourceDataLoading={resourceDataLoading}
            />
            <ResourceAssetDetailsDrawerOwners />
            <ResourceAssetDetailsDrawerAssociatedFindings
              resourceId={resourceData?.assetData?.externalResourceId}
            />
            <ResourceAssetDetailsDrawerAdditionalData />
          </>
        );
      case ResourceDetailsTab.ACTIVITY:
        return <ResourceContentActivity />;
    }
  };

  return (
    <div className="resource-asset-details-drawer-content-container">
      <ResourceAssetDetailsDrawerHeader
        resourceData={resourceData?.assetData}
        resourceDataError={resourceDataError}
        resourceDataLoading={resourceDataLoading}
      />

      <div className="finding-content-tab-list">{renderTabList()}</div>

      <div className="resource-content-body">{renderContentForTabs()}</div>
    </div>
  );
};

import { ApplicationTypes } from 'Application/interfaces/applications.enum';

export enum TicketApplication {
  Jira = 'jira',
  JiraServer = 'jira-server',
  AzureDevOps = 'azure-devops',
}

export const TicketApplicationNameToLabel = {
  [TicketApplication.Jira]: 'Jira',
  [TicketApplication.JiraServer]: 'Jira Server',
  [TicketApplication.AzureDevOps]: 'Azure DevOps',
};

type SelectedApplicationTypes =
  | ApplicationTypes.JIRA_CLOUD_PLATFORM
  | ApplicationTypes.JIRA_SERVER_PLATFORM
  | ApplicationTypes.AZURE_DEVOPS;

export const applicationTypeToTicketApplicationMap: Record<
  SelectedApplicationTypes,
  TicketApplication
> = {
  [ApplicationTypes.JIRA_CLOUD_PLATFORM]: TicketApplication.Jira,
  [ApplicationTypes.JIRA_SERVER_PLATFORM]: TicketApplication.JiraServer,
  [ApplicationTypes.AZURE_DEVOPS]: TicketApplication.AzureDevOps,
};
export interface OpenTicketData {
  ticketApplication: TicketApplication;
  findingId: string;
  projectKey: string;
  assigneeEmail: string;
  notifyTicketAssignee: boolean;
}

export interface TicketSuggestionParams {
  appId: TicketApplication;
  findingId: string;
}

export interface TicketSuggestion {
  projectKey?: string;
  assigneeEmail?: string;
}

interface OpenTicketResponseMetadata {
  appId: string;
  title: string;
  link: string;
  itemId: string;
}

export interface OpenTicketResponse {
  id: string;
  findingId: string;
  status: string;
  metadata: OpenTicketResponseMetadata;
  warningMessage?: string;
}

import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import FindingContentDescription from '../FindingContentDescription';
import FindingContentStatus from '../FindingContentStatus';
import FindingContentEvidence from '../FindingContentEvidence';
import FindingContentSourceInformation from '../FindingContentSourceInformation';
import FindingContentAffectedPackages from '../FindingContentAffectedPackages';
import FindingContentAdditionalData from '../FindingContentAdditionalData';
import FindingContentOwners from '../FindingContentOwners';
import FindingContentException from '../FindingContentException';
import FindingContentActions from '../FindingContentActions';
import FindingContentRiskScoreBreakdown from '../FindingContentRiskScoreBreakdown';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import FindingContentRelatedFindings from '../FindingContentRelatedFindings';
import { SummaryResponse } from 'FindingDetails/store/api';

interface FindingContentDetailsProps extends BaseComponentProps {
  description?: string;
  handleCloud2CodeModalOpen: () => void;
  findingId: string;
  findingData?: SummaryResponse;
  setFindingId: (id: string) => void;
}

export const FindingContentDetails: FunctionComponent<
  FindingContentDetailsProps
> = ({
  description,
  handleCloud2CodeModalOpen,
  findingId,
  findingData,
  setFindingId,
}) => {
  return (
    <div className="finding-content-details-container">
      <FindingContentException findingId={findingId} />
      <FindingContentActions findingId={findingId} />
      <FindingContentDescription description={description} />
      <FindingContentRiskScoreBreakdown findingId={findingId} />
      <FindingContentStatus findingId={findingId} />
      <FindingContentEvidence
        findingId={findingId}
        handleCloud2CodeModalOpen={handleCloud2CodeModalOpen}
        workspaceExternalId={findingData?.workspaceExternalId}
      />
      <FindingContentOwners findingId={findingId} />
      <FindingContentSourceInformation findingId={findingId} />
      <FindingContentRelatedFindings
        findingData={findingData}
        setFindingId={setFindingId}
      />
      <FindingContentAffectedPackages findingId={findingId} />
      <FindingContentAdditionalData findingId={findingId} />
    </div>
  );
};

import { Box, SxProps } from '@mui/material';
import * as React from 'react';
import styles from './CardWrapper.module.css';

interface CardProps {
  children: React.ReactNode;
  sx?: SxProps;
  padding?: number;
  height?: string;
  className?: string;
  onClick?: (e?: any) => void;
}
export default function CardWrapper({
  height = 'auto',
  children,
  padding = 2,
  sx: extraStyle,
  className: extraClassName,
  onClick = () => {},
}: CardProps) {
  return (
    <Box
      sx={extraStyle}
      className={`${styles.cardWrapper} ${extraClassName}`}
      height={height}
      padding={padding}
      onClick={onClick}
    >
      {children}
    </Box>
  );
}

import {
  RolePermission,
  User,
} from 'shared/models/data/authenticated-user.model';
import { uniq } from 'lodash';
import { ApplicationPermission } from '../enums/permission.enum';

export class UserPermissionHandler {
  extractPermissionListFromUserModel(user: User): Array<ApplicationPermission> {
    return uniq(
      user.permissions?.map(
        (permission: RolePermission) => permission.key as ApplicationPermission
      )
    );
  }
}

import { WorkflowTriggerDto } from 'WorkflowBuilder/components/Layout/Layout';
import getDiagramStepsFixtures from 'WorkflowBuilder/fixtures/steps';
import { LAYOUT_MODE } from 'WorkflowBuilder/interfaces/layout';
import { NodeType } from 'WorkflowBuilder/interfaces/node';
import DiagramStep, {
  DIAGRAM_STEP_TYPE,
} from 'WorkflowBuilder/interfaces/step';
import { Node } from 'react-flow-renderer';

export interface NodeDimensions {
  x: number;
  y: number;
  height: number;
  width: number;
}

export const getDiagramCenterPosition = (
  node: NodeDimensions
): Partial<Record<'x' | 'y' | 'zoom' | 'duration', number>> => {
  let centerOptions: Partial<Record<'x' | 'y' | 'zoom' | 'duration', number>> =
    {};

  if (node) {
    const x = node.x + (node.width ? node.width / 2 : 0);
    const y = node.y + (node.height ? node.height / 2 : 0);
    const zoom = 1.85;

    centerOptions = {
      x,
      y,
      zoom,
      duration: 200,
    };
  }

  return centerOptions;
};

export const sealDiagramDataOnCondition = (
  steps: Array<DiagramStep>,
  mode: LAYOUT_MODE
) => {
  if (mode === LAYOUT_MODE.EDIT) {
    return steps;
  }

  return steps.map((step: DiagramStep) => ({
    ...step,
    readonly: true,
  }));
};
export const isPathActive = (
  nextWorkflowId: number | undefined,
  steps: DiagramStep[]
) => {
  if (nextWorkflowId) {
    const nextWorkflow = steps.find(
      (step: DiagramStep) => step.workflowSeqId === nextWorkflowId
    );
    return !!nextWorkflow?.diagramData.results;
  }
  return false;
};

export const getXCoordinateForEmptyCrossroadCondition = (
  previousStep: DiagramStep,
  itemIndex: number
): number => {
  return previousStep
    ? itemIndex % 2 === 0
      ? previousStep.diagramData.position.x + 300 * itemIndex
      : previousStep.diagramData.position.x - 300 * itemIndex
    : 0;
};

export const addButtonNodesToCrossroads = (
  steps: Array<DiagramStep>
): Array<DiagramStep> => {
  const transformedSteps: Array<DiagramStep> = steps.reduce(
    (accumulator: Array<DiagramStep>, currentValue: DiagramStep) => {
      const containerAccumulator = accumulator;

      if (currentValue.type === 'crossroad') {
        const currentCrossroadContainer: Array<any> = [];
        for (let i = 1; i < currentValue.routes.length + 1; i++) {
          const route = currentValue.routes[i - 1];

          const parentStep = steps.find(
            (step: DiagramStep) =>
              step.workflowSeqId === route.nextWorkflowSeqId
          );

          if (!parentStep) {
            const lastCrossroadContainerItem =
              currentCrossroadContainer[currentCrossroadContainer.length - 1];

            const crossroadChild = {
              name: route?.name as string,
              type: DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY,
              workflowSeqId: route.nextWorkflowSeqId as number,
              diagramData: {
                position: {
                  x: getXCoordinateForEmptyCrossroadCondition(
                    lastCrossroadContainerItem || currentValue,
                    i
                  ),
                  y: currentValue.diagramData.position.y + 150,
                },
              },
              nodeType: NodeType.PLUS,
              routes: [],
            };

            containerAccumulator.push(crossroadChild);

            currentCrossroadContainer.push(crossroadChild);
          }
        }
      }

      return containerAccumulator;
    },
    [] as Array<DiagramStep>
  );

  return [...steps, ...transformedSteps];
};

export const getWorkflowDataSteps = (
  workflowData: any,
  mode: LAYOUT_MODE
): Array<DiagramStep> => {
  if (workflowData && workflowData.stepDefs) {
    const existingSteps = sealDiagramDataOnCondition(
      addButtonNodesToCrossroads(
        workflowData.stepDefs.map((stepDef: DiagramStep) => {
          if (stepDef.type === DIAGRAM_STEP_TYPE.TRIGGER) {
            return {
              ...stepDef,
              diagramData: {
                ...stepDef.diagramData,
                value: workflowData?.triggers
                  ? workflowData?.triggers?.map(
                      (trigger: WorkflowTriggerDto) => {
                        return trigger.value;
                      }
                    )
                  : [],
              },
              nodeType: stepDef.type,
            };
          }

          return {
            ...stepDef,
            nodeType: stepDef.type,
          };
        })
      ),
      mode
    );

    return existingSteps?.length > 0
      ? existingSteps
      : sealDiagramDataOnCondition(getDiagramStepsFixtures(), mode);
  }

  return [];
};

import { useAuthUser } from '@frontegg/react';
import { FunctionComponent, useMemo, Fragment } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { UserPermissionHandler } from 'shared/handlers/user-permission.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { PermissionGuard } from '../PermissionGuard/PermissionGuard';
import CommonNotFound from 'Common/components/NotFound';
import { ForbiddenPage } from 'Common/components/ForbiddenPage/ForbiddenPage';
import { routeList } from 'shared/fixtures/data/route-list.data';
import { RouteModel } from 'shared/models/data/route.model';
import { ApplicationPermission } from '../../enums/permission.enum';

const userPermissionHandler = new UserPermissionHandler();

interface RouteContainerProps extends BaseComponentProps {}

export const RouteContainer: FunctionComponent<RouteContainerProps> = () => {
  const user = useAuthUser();

  const userPermissions = useMemo<Array<ApplicationPermission>>(() => {
    return user
      ? userPermissionHandler.extractPermissionListFromUserModel(user)
      : [];
  }, [user]);

  return userPermissions?.length ? (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard/risk" replace />} />
      {routeList.map((routeModel: RouteModel) => (
        <Route
          path={routeModel.path}
          element={
            <PermissionGuard
              name={routeModel.name}
              userPermissions={userPermissions}
              permissions={routeModel.permissions}
              featureFlag={routeModel.featureFlag}
            >
              {routeModel.component}
            </PermissionGuard>
          }
        />
      ))}
      <Route path="*" element={<CommonNotFound />} />
    </Routes>
  ) : (
    <ForbiddenPage />
  );
};

import { ChangeEvent, FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OptionListType } from '../OptionList/OptionList';
import { FilterOption } from 'shared/models/data/data-filter.model';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CircularProgress, TextField } from '@mui/material';
import OptionListItem from '../OptionListItem';

export interface BaseOptionListProps extends BaseComponentProps {
  mode: OptionListType;
  title: string;
  options: Array<FilterOption>;
  selectedOptions?: Array<FilterOption>;
  onOptionsSelect?: (
    options: Array<FilterOption>,
    isAllSelected?: boolean
  ) => void;
  optionsLoading?: boolean;
  onAllSelect?: (options: Array<FilterOption>) => void;
  allSelected?: boolean;
  enableAllSelection?: boolean;
  onSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
  renderHeaderComponent?: () => ReactNode;
  disableSearch?: boolean;
}

export const BaseOptionList: FunctionComponent<BaseOptionListProps> = ({
  mode,
  title,
  options,
  onOptionsSelect,
  selectedOptions,
  optionsLoading,
  onAllSelect,
  allSelected,
  enableAllSelection,
  onSearch,
  actionHandlers,
  renderHeaderComponent,
  disableSearch,
}) => {
  const { t: translation } = useTranslation();

  const isOptionSelected = (option: FilterOption) => {
    return Boolean(
      selectedOptions?.find(
        (selectedOption: FilterOption) => selectedOption.value === option.value
      )
    );
  };

  const renderTitleArea = () => {
    return (
      <div className="filter-title-container">
        {actionHandlers?.goBackHandler ? (
          <div className="filter-go-back-button">
            <div
              onClick={() => {
                actionHandlers.goBackHandler && actionHandlers.goBackHandler();
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="filter-title">{title}</div>
      </div>
    );
  };

  const renderSearchInput = () => {
    if (disableSearch) return <></>;

    return (
      <TextField
        className="filter-input filter-main-input"
        placeholder={translation(`common.searchPlaceholder`)}
        onChange={onSearch}
        InputProps={{
          className: 'search-filter-input',
          startAdornment: (
            <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
          ),
        }}
      />
    );
  };

  const renderAllOption = (optionData: Array<FilterOption>) => {
    if (mode === OptionListType.MULTIPLE && enableAllSelection)
      return (
        <OptionListItem
          value="All"
          label={`All (${options.length})`}
          checked={allSelected}
          enableCheckbox
          onClick={() => {
            onAllSelect && onAllSelect(optionData);
          }}
        />
      );

    return <></>;
  };

  const onOptionSelect = (value: string, label?: string) => {
    let currentSelectedOptions: Array<FilterOption> = [
      ...(selectedOptions || []),
    ];

    if (
      currentSelectedOptions.find(
        (selectedOption) => selectedOption.value === value
      )
    ) {
      currentSelectedOptions = currentSelectedOptions.filter(
        (selectedOption) => selectedOption.value !== value
      );
    } else {
      if (mode === OptionListType.MULTIPLE) {
        currentSelectedOptions = [
          ...currentSelectedOptions,
          {
            value,
            label,
          },
        ];
      } else {
        currentSelectedOptions = [
          {
            value,
            label,
          },
        ];
      }
    }

    onOptionsSelect &&
      onOptionsSelect(
        currentSelectedOptions,
        options.length === currentSelectedOptions.length
      );
  };

  const renderOptions = () => {
    if (optionsLoading)
      return (
        <div className="option-list-loading">
          <CircularProgress size={24} />
        </div>
      );

    if (options.length)
      return (
        <>
          {renderAllOption(options)}

          {options.map((option: FilterOption) => (
            <OptionListItem
              {...option}
              enableCheckbox={mode === OptionListType.MULTIPLE}
              checked={isOptionSelected(option)}
              onClick={onOptionSelect}
            />
          ))}
        </>
      );

    return (
      <div className="option-list-no-data option-list-text">
        {translation(`common.noData`)}
      </div>
    );
  };

  return (
    <div className="option-list">
      {renderTitleArea()}
      {renderSearchInput()}
      {renderHeaderComponent ? renderHeaderComponent() : <></>}
      {renderOptions()}
    </div>
  );
};

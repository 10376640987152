import React from 'react';
import { AuthorizedContent } from '@frontegg/react';
import { Stack } from '@mui/material';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import TimelineEvent from 'FindingDetails/components/Timeline';
import TimelineEventAddComment from 'FindingDetails/components/Timeline/TimelineAddComment';
import {
  useFetchTimelineQuery,
  usePostFindingTimelineCommentMutation,
} from 'FindingDetails/store/api';
import TimelineComponent from 'Common/components/TimelineComponent/TimelineComponent';

interface FindingContentActivityProps extends BaseComponentProps {}

export const FindingContentActivity: React.FC<
  FindingContentActivityProps
> = () => {
  const [urlSearchParams] = useQueryParams();
  const { data, isLoading } = useFetchTimelineQuery(
    urlSearchParams.openFindingId
  );

  const renderEvent = (event: any, index: number) => (
    <TimelineEvent key={index} event={event} />
  );

  const renderAddComment = () => (
    <AuthorizedContent
      requiredPermissions={[ApplicationPermission.FINDINGS_ACTIVITY_WRITE]}
    >
      <TimelineEventAddComment
        id={urlSearchParams.openFindingId}
        useMutation={usePostFindingTimelineCommentMutation}
      />
    </AuthorizedContent>
  );

  return (
    <Stack className="activity-container">
      <AuthorizedContent
        requiredPermissions={[ApplicationPermission.FINDINGS_ACTIVITY_READ]}
      >
        <TimelineComponent
          data={data}
          isLoading={isLoading}
          noEventsMessageId="findings.details.noEvents"
          noEventsDefaultMessage="No events"
          renderEvent={renderEvent}
          renderDefaultTimelineEvent={renderAddComment}
        />
      </AuthorizedContent>
    </Stack>
  );
};

import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface AvatarChipProps extends BaseComponentProps {
  avatarUrl?: string;
  label: string;
}

export const AvatarChip: FunctionComponent<AvatarChipProps> = ({
  avatarUrl,
  label,
}) => {
  return (
    <div className="avatar-chip-container">
      <div className="avatar-chip-icon">
        {avatarUrl?.length && <img src={avatarUrl} />}
      </div>

      <div className="avatar-chip-label">{label}</div>
    </div>
  );
};

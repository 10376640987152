import { createTheme } from '@mui/material';

// TODO: integrate with dark theme
export const darkPalette = {
  background: {
    default: 'black',
  },
  text: {
    primary: 'white',
    disabled: '#ffffff40',
  },
};

//
// theme
//
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 37.5,
      md: 56.25,
      lg: 75,
      xl: 96,
    },
    unit: 'rem',
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#71FFD6',
          height: '5px',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '& path': {
              fill: 'white',
            },
            '& span': {
              color: 'white',
            },
          },
          textTransform: 'initial',
          fontSize: '16px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: '2.5rem',
          borderRadius: '1.5em',
          paddingLeft: '1.5rem',
          paddingRight: '1.5rem',
          fontSize: '1rem',
          fontWeight: 700,
          lineHeight: 1.35,
          textTransform: 'none',
          // TODO: button sizes
        },
        // @ts-ignore
        containedSuccess: {
          // it works, but TypeScript doesn't recognize it for some reason
          color: 'white',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: '0.5rem',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '1.5rem',
          '&:last-child': {
            paddingBottom: '1.5rem',
          },
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '0.5em',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        head: {
          fontWeight: 700,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: 14,
          color: '#0A0B50',
        },
        h4: {
          fontSize: 20,
          fontWeight: 700,
          color: '#0A0B50',
        },
        h5: {
          fontSize: 18,
          fontWeight: 600,
          color: '#0A0B50',
        },
        h6: {
          fontSize: 16,
          fontWeight: 600,
          color: '#0A0B50',
        },
      },
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h1',
          h3: 'h1',
          h4: 'h1', // the "h4" variant is the largest within current design
          h5: 'h2',
          h6: 'h3',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '*::-webkit-scrollbar': {
            backgroundColor: '#FFFFFF',
            width: '16px',
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: '#FFFFFF',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#BABAC0',
            borderRadius: '16px',
            border: '4px solid #fff',
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: '#f9f9fb',
    },
    error: {
      main: '#E84545',
    },
    primary: {
      main: '#0A0B50',
    },
    success: {
      main: '#33C600',
    },
    text: {
      primary: '#2E2B4E',
      secondary: '#2E2B4E80',
    },
    warning: {
      main: '#FFC501',
    },
  },
  spacing: (factor: number) => `${0.5 * factor}rem`, // 1 unit = 0.5rem
  typography: {
    fontFamily: '"HEX Franklin v0.2"',
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
  },
});

export default theme;

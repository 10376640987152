import {
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonModalContainer } from '../CommonModalContainer';
import { CircularProgress, Paper, Popover, Tooltip } from '@mui/material';
import TabList from '../TabList';
import { TabListItem } from '../TabList/TabList';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useGetWorkflowTokenGroupsByTypeMutation } from 'Flow/store/api';
import { useTranslation } from 'react-i18next';
import FormattedMessage from '../FormattedMessage';
import { DiagramStepContext } from 'WorkflowBuilder/components/Builder/Builder';
import TokenInputPanelStepTree from '../TokenInputPanelStepTree';
import {
  NodeType,
  stepTypesWithNoPayload,
} from 'WorkflowBuilder/interfaces/node';
import {
  WorkflowFindingContext,
  WorkflowFindingContextProps,
} from 'WorkflowBuilder/components/Layout/Layout';
import { TreeItem } from '@mui/x-tree-view';
import TokenInputFindingContent from '../TokenInputFindingContent';

enum TokenGroupType {
  SYSTEM = 'SYSTEM',
  STEPS = 'STEPS',
  FUNCTIONS = 'FUNCTIONS',
}

export interface TokenItem {
  label: string;
  value: string;
  description?: string;
}

interface TokenGroup {
  type: TokenGroupType;
  category: string;
  items: Array<TokenItem>;
}

const systemTokens: Array<TokenGroup> = [
  {
    type: TokenGroupType.SYSTEM,
    category: 'Finding',
    items: [
      {
        value: 'finding.severity',
        label: 'Severity',
      },
      {
        value: 'finding.title',
        label: 'Title',
      },
    ],
  },
  {
    type: TokenGroupType.SYSTEM,
    category: 'Bu',
    items: [
      {
        value: 'bu.name',
        label: 'Name',
      },
      {
        value: 'bu.criticality',
        label: 'Criticality',
      },
    ],
  },
];

const functionTokens: Array<TokenGroup> = [
  {
    type: TokenGroupType.FUNCTIONS,
    category: 'Functions',
    items: [
      {
        value: `map: ''`,
        label: 'Map',
      },
      {
        value: `split: ''`,
        label: 'Split',
      },
    ],
  },
  {
    type: TokenGroupType.FUNCTIONS,
    category: 'Opus Functions',
    items: [
      {
        value: `map: ''`,
        label: 'Map',
      },
      {
        value: `split: ''`,
        label: 'Split',
      },
    ],
  },
];

interface TokenInputPanelProps extends BaseComponentProps {
  open: boolean;
  handleClose: () => void;
  anchorEl: any;
  selectionHandlers: {
    selectSystemTokenHandler: (item: TokenItem) => void;
    selectFunctionTokenHandler: (item: TokenItem) => void;
    selectStepTokenHandler: (id: string) => void;
    selectFindingContentTokenHandler: (id: string) => void;
  };
}

enum TokenModalTab {
  SYSTEM = 'SYSTEM',
  STEPS = 'STEPS',
  FUNCTIONS = 'FUNCTIONS',
  FINDING = 'FINDING',
}

const tabListItems: Array<TabListItem> = [
  {
    id: TokenModalTab.SYSTEM,
    label: 'System',
  },
  {
    id: TokenModalTab.STEPS,
    label: 'Steps',
  },
  {
    id: TokenModalTab.FUNCTIONS,
    label: 'Functions',
  },
  {
    id: TokenModalTab.FINDING,
    label: 'Finding',
  },
];

export const TokenInputPanel: FunctionComponent<TokenInputPanelProps> = ({
  open,
  handleClose,
  anchorEl,
  selectionHandlers: {
    selectFunctionTokenHandler,
    selectSystemTokenHandler,
    selectStepTokenHandler,
    selectFindingContentTokenHandler,
  },
}) => {
  const { t: translation } = useTranslation();

  const diagramSteps = useContext(DiagramStepContext);

  const { activeFindingId } = useContext<WorkflowFindingContextProps>(
    WorkflowFindingContext
  );

  const [
    getSystemTokens,
    { data: systemTokens, isLoading: systemTokensLoading },
  ] = useGetWorkflowTokenGroupsByTypeMutation();

  const [
    getFunctionTokens,
    { data: functionTokens, isLoading: functionTokensLoading },
  ] = useGetWorkflowTokenGroupsByTypeMutation();

  useEffect(() => {
    if (open) {
      getSystemTokens('system');
      getFunctionTokens('function');
    }
  }, [open]);

  const [activeTabId, setActiveTabId] = useState<TokenModalTab>(
    TokenModalTab.SYSTEM
  );

  const selectActiveTabHandler = (tabId: TokenModalTab) => {
    setActiveTabId(tabId);
  };

  const renderTabApiContent = (
    isLoading: boolean,
    data: any,
    renderContent: () => ReactNode
  ) => {
    if (isLoading) return <CircularProgress size={25} />;

    if ((!isLoading && !data) || (!isLoading && data?.length === 0))
      return <FormattedMessage id="common.noData" defaultMessage="No data" />;

    return renderContent();
  };

  const renderSystemTokens = () => {
    return (
      <div className="token-input-panel-content-groups">
        {systemTokens?.map((systemToken: TokenGroup) => (
          <div className="token-input-panel-content-group">
            <div className="token-input-panel-content-group-title">
              {systemToken.category}
            </div>
            <div className="token-input-panel-content-group-items">
              {systemToken.items.map((systemTokenItem: TokenItem) => (
                <Tooltip title={systemTokenItem.description}>
                  <div
                    className="token-input-panel-content-group-item token-input-panel-content-group-system-item"
                    onClick={() => {
                      selectSystemTokenHandler(systemTokenItem);
                    }}
                  >
                    {systemTokenItem.label}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderFunctionTokens = () => {
    return (
      <div className="token-input-panel-content-groups">
        {functionTokens?.map((functionToken: TokenGroup) => (
          <div className="token-input-panel-content-group">
            <div className="token-input-panel-content-group-title">
              {functionToken.category}
            </div>
            <div className="token-input-panel-content-group-items">
              {functionToken.items.map((functionTokenItem: TokenItem) => (
                <Tooltip title={functionTokenItem.description}>
                  <div
                    className="token-input-panel-content-group-item token-input-panel-content-group-function-item"
                    onClick={() => {
                      selectFunctionTokenHandler(functionTokenItem);
                    }}
                  >
                    {functionTokenItem.label}
                  </div>
                </Tooltip>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderStepTokens = () => {
    return (
      <div className="token-input-panel-step-tokens-container">
        {diagramSteps.steps?.map((step) => {
          if (stepTypesWithNoPayload.includes(step.nodeType as NodeType))
            return <></>;

          return (
            <TokenInputPanelStepTree
              id={step.id as string}
              seqId={step.workflowSeqId}
              icon={step.icon as string}
              title={step.name as string}
              selectStepTokenHandler={selectStepTokenHandler}
            />
          );
        })}
      </div>
    );
  };

  const renderFunctionContentTokens = () => {
    return (
      <TokenInputFindingContent
        findingId={activeFindingId as string}
        selectFindingContentTokenHandler={selectFindingContentTokenHandler}
      />
    );
  };

  const renderTabConent = () => {
    switch (activeTabId) {
      case TokenModalTab.SYSTEM:
        return renderTabApiContent(
          systemTokensLoading,
          systemTokens,
          renderSystemTokens
        );
      case TokenModalTab.FUNCTIONS:
        return renderTabApiContent(
          functionTokensLoading,
          functionTokens,
          renderFunctionTokens
        );
      case TokenModalTab.STEPS:
        return renderStepTokens();
      case TokenModalTab.FINDING:
        return renderFunctionContentTokens();
      default:
        return <></>;
    }
  };

  return (
    <Popover
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'center',
      }}
      classes={{
        paper: 'token-input-panel-container',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <div className="token-input-panel-body">
        <div className="token-input-panel-header">
          <div className="token-input-panel-tabs">
            <TabList
              items={tabListItems}
              activeItemId={activeTabId}
              onSelect={selectActiveTabHandler}
              isUnderlined
            />
          </div>
          <div className="token-input-panel-close-icon" onClick={handleClose}>
            <OpusSvgIcon type={SVG_ICON_TYPES.TIMES_SECONDARY_ICON} />
          </div>
        </div>

        <div className="token-input-panel-content">{renderTabConent()}</div>
      </div>
    </Popover>
  );
};

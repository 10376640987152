import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import { RiskCustomizationConfigTypes } from 'Settings/interfaces/RiskCustomizationConfigTypes.enum';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface RiskBadgeParams {
  data: {
    factors: {
      [key in RiskCustomizationConfigTypes]?: {
        cause: string[];
      };
    };
  };
}

const RiskBadge = ({
  params,
  configType,
  iconType,
}: {
  params: RiskBadgeParams;
  configType: RiskCustomizationConfigTypes;
  iconType: SVG_ICON_TYPES;
}) => (
  <div className="risk-badge-container">
    {params.data?.factors[configType] && (
      <CommonTooltip
        title={
          <div className="risk-badge-tooltip">
            {params.data.factors[configType]?.cause.map(
              (cause, index, array) => (
                <p className="tooltip-title" key={cause}>
                  {cause}
                  {index !== array.length - 1 && ',\u00A0'}
                </p>
              )
            )}
          </div>
        }
      >
        <div className="risk-badge-icon">
          <OpusSvgIcon type={iconType} />
        </div>
      </CommonTooltip>
    )}
  </div>
);

export default RiskBadge;

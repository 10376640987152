import { FunctionComponent, useEffect } from 'react';
import {
  CommonModalContainer,
  CommonModalContainerProps,
} from '../CommonModalContainer/CommonModalContainer';
import { useTranslation } from 'react-i18next';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { InputLabelWrapper } from '../InputLabelWrapper/InputLabelWrapper';
import { CommonButton, CommonButtonType } from '../CommonButton/CommonButton';
import { FormattedMessage } from '../FormattedMessage/FormattedMessage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { findingExceptionRevokeFormSchema } from 'shared/fixtures/data/finding-exception.data';
import { useDeclineFindingExceptionMutation } from 'FindingDetails/store/api';
import { AlertExpandBox } from '../AlertExpandBox/AlertExpandBox';
import { AlertBoxType } from '../AlertBox/AlertBox';

interface FindingExceptionRevokeModalProps
  extends Partial<CommonModalContainerProps> {
  exceptionId: string;
  findingId: string;
}

export const FindingExceptionRevokeModal: FunctionComponent<
  FindingExceptionRevokeModalProps
> = ({ handleClose, isOpen, exceptionId, findingId }) => {
  const { t: translation } = useTranslation();

  const [
    declineFindingException,
    {
      isLoading: declineFindingExceptionLoading,
      isSuccess: declineFindingExceptionSuccess,
      error: declineFindingExceptionError,
      reset: declineFindingExceptionReset,
    },
  ] = useDeclineFindingExceptionMutation();

  const {
    register,
    handleSubmit,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(findingExceptionRevokeFormSchema),
    defaultValues: {
      message: '',
    },
  });

  useEffect(() => {
    if (declineFindingExceptionSuccess) modalCloseHandler();
  }, [declineFindingExceptionSuccess]);

  const modalCloseHandler = () => {
    resetForm();
    declineFindingExceptionReset();
    handleClose && handleClose();
  };

  const formSubmitHandler = () => {
    handleSubmit(formValidHandler)();
  };

  const formValidHandler = (formValues: any) => {
    declineFindingException({ ...formValues, findingId, exceptionId });
  };

  const renderErrorMessage = () => {
    if (declineFindingExceptionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          description={(declineFindingExceptionError as any)?.data?.message}
          onClose={declineFindingExceptionReset}
          title={translation(`exceptions.details.actionErrorMessage`)}
        />
      );
    }
    return <></>;
  };

  return (
    <CommonModalContainer
      handleClose={modalCloseHandler}
      isOpen={Boolean(isOpen)}
      title={translation(`exceptions.modals.declineRequestExceptionTitle`)}
      components={{
        TitleIcon: <OpusSvgIcon type={SVG_ICON_TYPES.LIGHT_EMERGENCY_ICON} />,
      }}
      rootClassName="finding-exception-modal-container"
    >
      <div className="finding-exception-form-container">
        <form className="finding-exception-form">
          {renderErrorMessage()}
          <InputLabelWrapper
            label={translation(`exceptions.forms.declineThisRequest`)}
            isOptional
          >
            <textarea
              rows={4}
              className="text-area-field-1"
              {...register('message')}
            />
          </InputLabelWrapper>
        </form>
        <div className="finding-exception-form-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={modalCloseHandler}
          >
            <FormattedMessage
              key={`common.cancel`}
              defaultMessage="Cancel"
              capitalize
            />
          </CommonButton>

          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={formSubmitHandler}
          >
            <FormattedMessage
              key={
                declineFindingExceptionLoading
                  ? `exceptions.buttons.decline`
                  : `exceptions.buttons.declining`
              }
              defaultMessage={
                declineFindingExceptionLoading ? 'Declining' : 'Decline'
              }
              capitalize
            />
          </CommonButton>
        </div>
      </div>
    </CommonModalContainer>
  );
};

import { useEffect, useRef, useState } from 'react';
import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  Snackbar,
  Typography,
} from '@mui/material';
import commonConfig from 'Config';
import {
  AzureAccountConnect,
  useConnectAzureAccountMutation,
} from '../../../store/api';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { convertMarkdownToTextWithLink } from 'Common/utils/utilsFunction';
import CopyButton from '../../../../Common/components/CopyButton';

interface Props {
  onClose: (shouldReload?: boolean) => void;
  onSave?: boolean;
  setOnSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ConnectCloudAzureDialogContent({
  onClose,
  onSave,
  setOnSave,
  setIsLoading,
}: Props) {
  const { t: translation } = useTranslation();
  const [tenantId, setTenantId] = useState('');
  const terminalGetSubscriptionsText = `az ad sp list --filter "appId eq '017961fd-f05f-4564-b0e2-c83722743baa'" | jq -r .[0].id`;
  const formElement = useRef<HTMLFormElement>(null);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

  const tenantIdChangeHandler = (event: any) => {
    setTenantId(event.target.value);
  };

  const [
    connectAzureAccount,
    { isLoading, isSuccess: isConnected, isError: isNotConnected },
  ] = useConnectAzureAccountMutation();

  useEffect(() => {
    setIsLoading(isLoading);
    setFormErrors({});
  }, [isLoading]);

  useEffect(() => {
    if (isNotConnected) {
      setOnSave(false);
      setFormErrors({});
    }
  }, [isNotConnected]);

  useEffect(() => {
    if (isConnected) {
      onClose(true);
      setFormErrors({});
    }
  }, [isConnected]);

  async function handleSubmit() {
    const form = formElement.current;
    if (!form) return;
    const formData = new FormData(form);
    const item: AzureAccountConnect = {
      azureTenantId: tenantId,
      subscriptionId: formData.get('azureSubscriptionId') as string,
      cloudWorkspaceAlias: formData.get('workspaceAlias') as string,
    };
    const errors = validateFormFields(item);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setOnSave(false);
    } else {
      setFormErrors({});
      connectAzureAccount(item);
    }
  }

  useEffect(() => {
    if (onSave) handleSubmit();
  }, [onSave]);

  const getMarkdownWithLink = (translationPath: string, url: string) => {
    const desc = translation(translationPath, {
      url: url,
    });
    return convertMarkdownToTextWithLink(desc);
  };

  const validateFormFields = (item: AzureAccountConnect) => {
    const errors: { [key: string]: string } = {};

    if (!item.azureTenantId) {
      errors['azureTenantId'] = 'Tenant ID is required';
    }

    if (!item.subscriptionId) {
      errors['azureSubscriptionId'] = 'Subscription ID is required';
    }

    if (!item.cloudWorkspaceAlias) {
      errors['workspaceAlias'] = 'Workspace Alias is required';
    }

    return errors;
  };

  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <form ref={formElement} onSubmit={handleSubmit}>
          <Typography
            mb={1}
            className="input-title"
            dangerouslySetInnerHTML={{
              __html: getMarkdownWithLink(
                'settings.details.azureTenantIdTitle',
                'https://portal.azure.com/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Properties'
              ),
            }}
          ></Typography>
          <FormControl
            fullWidth
            error={!!formErrors['azureTenantId']}
            className="input-dialog"
          >
            <Input
              fullWidth
              disableUnderline
              className="input-dialog"
              name="tenantId"
              inputProps={{ required: true }}
              placeholder={'Tenant ID'}
              onChange={tenantIdChangeHandler}
            />
            {formErrors['azureTenantId'] && (
              <FormHelperText>{formErrors['azureTenantId']}</FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />
          <Typography
            className="input-title"
            dangerouslySetInnerHTML={{
              __html: getMarkdownWithLink(
                'settings.details.azureConnectOpusAppTitle',
                `https://login.microsoftonline.com/${tenantId}/oauth2/authorize?client_id=017961fd-f05f-4564-b0e2-c83722743baa&response_type=code&redirect_uri=${commonConfig.globalGwBaseUrl}/internal/connections/callback`
              ),
            }}
          ></Typography>
          <Box mb={3} />
          <Typography
            className="input-title"
            dangerouslySetInnerHTML={{
              __html: getMarkdownWithLink(
                'settings.details.azureGetAppObjIdTitle',
                ''
              ),
            }}
          ></Typography>
          <Grid item xs={12} mb={2} ml={1} mt={1}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: getMarkdownWithLink(
                  'settings.details.azureGetAppObjIdText',
                  'https://portal.azure.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview'
                ),
              }}
            ></Typography>
          </Grid>
          <Input
            fullWidth
            disableUnderline
            className="input-dialog"
            name="tenantId"
            inputProps={{ required: true }}
            value={terminalGetSubscriptionsText}
            readOnly
            endAdornment={
              <CopyButton
                textToCopy={terminalGetSubscriptionsText}
              ></CopyButton>
            }
          ></Input>

          <Box mb={3} />
          <Typography
            className="input-title"
            dangerouslySetInnerHTML={{
              __html: getMarkdownWithLink(
                'settings.details.azureConnectSubscriptionTitle',
                'https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fopus-cf-dev-us-east-1.s3.amazonaws.com%2FOpus%2Fopus_azure_rols_arm.json'
              ),
            }}
          ></Typography>
          <Grid item xs={12} mb={2} ml={1} mt={1}>
            <Typography
              variant="inherit"
              className="body-1"
              color={'#7F7F7F'}
              dangerouslySetInnerHTML={{
                __html: getMarkdownWithLink(
                  'settings.details.azureConnectSubscriptionText',
                  ''
                ),
              }}
            ></Typography>
          </Grid>
          <Box mb={3} />
          <Typography className="input-label">
            <FormattedMessage
              id="settings.details.azureEnterSubscriptionTitle"
              defaultMessage="Enter your Subscription ID here"
            />
          </Typography>
          <FormControl
            fullWidth
            error={!!formErrors['azureSubscriptionId']}
            className="input-dialog"
          >
            <Input
              fullWidth
              disableUnderline
              className="input-dialog"
              name="azureSubscriptionId"
              inputProps={{ required: true }}
              placeholder={'Your subscription id'}
            />
            {formErrors['azureSubscriptionId'] && (
              <FormHelperText>
                {formErrors['azureSubscriptionId']}
              </FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />
          <Typography className="input-label">
            <FormattedMessage
              id="settings.details.azureEnterWorkspaceAliasTitle"
              defaultMessage="Enter a name for this cloud workspace (Alias)"
            />
          </Typography>
          <FormControl
            fullWidth
            error={!!formErrors['workspaceAlias']}
            className="input-dialog"
          >
            <Input
              fullWidth
              disableUnderline
              className="input-dialog"
              name="workspaceAlias"
              inputProps={{ required: true }}
              placeholder={'Alias name'}
            />
            {formErrors['workspaceAlias'] && (
              <FormHelperText>{formErrors['workspaceAlias']}</FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />
        </form>
      </Grid>
      <Snackbar
        open={isNotConnected}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
      >
        <Alert severity="error" sx={{ minWidth: '100px !important' }}>
          <FormattedMessage
            id="settings.details.failedConnectionCloudEnv"
            defaultMessage="Failed to connect to Cloud Environment!"
          />
        </Alert>
      </Snackbar>
    </Grid>
  );
}

import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { CustomFilterItem } from 'shared/components/CommonFavouritesFilter/CommonFavouritesFilter';
import { FindingContentType } from 'shared/enums/findings.enum';

export enum ResourceReducerTags {
  Assets = 'Assets',
  ActivityComment = 'ActivityComment',
}

const assetsApi = createApi({
  reducerPath: 'assetsApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: [ResourceReducerTags.Assets, ResourceReducerTags.ActivityComment],
  endpoints: (builder) => ({
    searchAssets: builder.mutation({
      query: (data) => ({
        url: `assets/search`,
        method: 'POST',
        body: data,
      }),
    }),
    fetchAssetsCount: builder.query<any, void>({
      query: () => `assets/count`,
      transformResponse: async (response) => {
        return response as { [key: string]: number };
      },
    }),
    createCustomFilter: builder.mutation({
      query: (body) => ({
        url: `custom-filters`,
        method: 'POST',
        body: body,
      }),
      transformResponse: async (response) => {
        return response as CustomFilterItem;
      },
    }),
    fetchAssetsDetailsById: builder.mutation({
      query: ({ id, type }) => ({
        url: `assets/${id}/${type}`,
        method: 'POST',
      }),
    }),
    fetchResourceTimeline: builder.query<any[], string>({
      query: (findingId: string) =>
        `findings/${findingId}/content/${FindingContentType.TIMELINE}`,
      transformResponse: async (response) => {
        return response as any[];
      },
      providesTags: [ResourceReducerTags.ActivityComment],
    }),
    postResourceTimelineComment: builder.mutation({
      query: ({ id, body }) => ({
        url: `assets/${id}/timeline/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [ResourceReducerTags.ActivityComment],
    }),
  }),
});

export default assetsApi;

export const {
  useSearchAssetsMutation,
  useFetchAssetsCountQuery,
  useCreateCustomFilterMutation,
  useFetchAssetsDetailsByIdMutation,
  useFetchResourceTimelineQuery,
  usePostResourceTimelineCommentMutation,
} = assetsApi;

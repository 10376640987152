import { Popover, PopoverOrigin } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CategoryDropdownItem from '../CategoryDropdownItem';
import { ExtendedFilterCategory } from 'shared/models/data/data-filter.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FilterChipGroupChangeHandlerProps } from '../FilterChipGroup/FilterChipGroup';

export interface DropdownProps {
  anchorEl: any;
  open: boolean;
  handleClose: () => void;
  anchorOrigin: PopoverOrigin;
}

interface CategoryDropdownProps
  extends BaseComponentProps,
    DropdownProps,
    FilterChipGroupChangeHandlerProps {
  title?: string;
  enableBackButton?: boolean;
  categories: Array<ExtendedFilterCategory>;
}

export const CategoryDropdown: FunctionComponent<CategoryDropdownProps> = ({
  anchorEl,
  open,
  handleClose,
  categories,
  onChange,
  anchorOrigin,
  enableBackButton = false,
  title,
  categoryState,
}) => {
  const renderTitleArea = () => {
    if (enableBackButton === false && title === undefined) return <></>;

    return (
      <div className="category-dropdown-title-container">
        {enableBackButton ? (
          <div
            onClick={() => {
              handleClose();
            }}
            className="category-dropdown-back-button"
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
          </div>
        ) : (
          <></>
        )}
        {title?.length ? (
          <div className="category-dropdown-title">{title}</div>
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderCategories = () => {
    return categories.map((category: ExtendedFilterCategory) => (
      <CategoryDropdownItem
        {...category}
        onChange={onChange}
        categoryState={categoryState}
      />
    ));
  };

  return (
    <Popover
      id="data-filter-category-dropdown"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      className={`category-dropdown`}
    >
      {renderTitleArea()}
      {renderCategories()}
    </Popover>
  );
};

import { DeltaMap } from 'AutomationManagement/store';
import { getResourceLogoUrl } from 'FindingDetails/components/ResourceItemCell/utils';
import { ResourceTitleMapper } from 'FindingDetails/interfaces/resource';
import {
  AutomationRule,
  AutomationRuleGroup,
  AutomationRuleProperty,
  AutomationRulePropertyOperator,
  AutomationRulePropertyType,
  AutomationRuleValue,
  AutomationRuleWorkflowDefinition,
} from 'shared/models/data/automation-rule-group.model';

export default class AutomationManagementDataHandler {
  static transformRulesByTriggersToAutomationRuleGroups(
    rulesByTriggers: Array<any>,
    translation: any
  ): Array<AutomationRuleGroup> {
    const automationRuleGroups: Array<AutomationRuleGroup> = rulesByTriggers
      .filter((ruleByTrigger) => !ruleByTrigger.isHidden)
      .map((ruleByTrigger) => {
        const isResourceKnown =
          ruleByTrigger?.resourceType in ResourceTitleMapper;

        return {
          findingType: {
            application: {
              id: ruleByTrigger?.applicationId,
              name: 'application',
              logo: ruleByTrigger?.applicationLogo,
            },
            name: ruleByTrigger?.findingType,
            title: ruleByTrigger?.title,
            resource: {
              name: isResourceKnown
                ? translation(`global.resources.${ruleByTrigger?.resourceType}`)
                : ruleByTrigger?.resourceType,
              logo: isResourceKnown
                ? getResourceLogoUrl(ruleByTrigger?.resourceType)
                : '',
              type: ruleByTrigger?.resourceType,
            },
            severity: ruleByTrigger?.severity,
          },
          id: ruleByTrigger?.findingType,
          options: {
            businessEnvironmentTypes: [],
            businessUnitIds: [],
          },
          rules: ruleByTrigger?.rules,
          remediationTypeLogos: ruleByTrigger?.remediationTypeLogos,
        };
      });

    return automationRuleGroups;
  }

  static transformWorkflowDefinitionToOptionValues(
    data: Array<any>
  ): Array<AutomationRuleValue> {
    return data.map((item) => ({
      value: item.familyId,
      label: item.name,
    }));
  }

  static getAutomationRuleWorkflowDef(
    workflowDefId: string,
    workflowDefinitions: Array<any>
  ): AutomationRuleWorkflowDefinition {
    const workflowDefinition = workflowDefinitions?.find(
      (workflowDefinition: any) => workflowDefinition.familyId === workflowDefId
    );

    return {
      familyId: workflowDefinition?.familyId,
      id: workflowDefId,
      name: workflowDefinition?.name,
      managementType: workflowDefinition?.managementType,
    };
  }

  static getAutomationRuleProperty(
    name: string,
    values: Array<string>
  ): AutomationRuleProperty {
    return {
      operator: AutomationRulePropertyOperator.IN,
      type: name as AutomationRulePropertyType,
      values,
    };
  }

  static transformLocalRulesToBulkPostData(deltaRuleMaps: {
    create: DeltaMap;
    update: DeltaMap;
    delete: DeltaMap;
  }): {
    create: Array<AutomationRule>;
    update: Array<AutomationRule>;
    delete: Array<AutomationRule>;
  } {
    return {
      create: deltaRuleMaps.create ? Object.values(deltaRuleMaps.create) : [],
      update: deltaRuleMaps.update ? Object.values(deltaRuleMaps.update) : [],
      delete: deltaRuleMaps.delete ? Object.values(deltaRuleMaps.delete) : [],
    };
  }
}

import * as yup from 'yup';

export const emailValidationExpression =
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const resourceOwnerCreateFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email field is required')
    .matches(emailValidationExpression, {
      message: 'Email field should be a valid email',
    }),
  mobilePhone: yup.string(),
  managerEmail: yup
    .string()
    .ensure()
    .when({
      is: (val: string) => val.length > 0,
      then: yup.string().matches(emailValidationExpression, {
        message: 'Manager Email field should be a valid email',
      }),
    }),
  isPrimary: yup.boolean(),
});

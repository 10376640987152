import React, { useState, useEffect, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { PollingSubscription } from 'Integrations/Webhook/interfaces/polling-subscription';
import {
  usePollingSubscriptionByConnectionIdMutation,
  usePollingSubscriptionCreateMutation,
  usePollingSubscriptionDeleteMutation,
  usePollingSubscriptionEditMutation,
  usePollingTemplatesFetchByAppIdQuery,
} from 'Integrations/Webhook/store/api';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { VisibilityControlContent } from 'shared/components/VisibilityControlContent/VisibilityControlContent';
import { IntegrationAdditionalContentFormProps } from '../BaseIntegrationModalEditForm/BaseIntegrationModalEditForm';
import { Parameter } from 'ConnectionTemplate/interfaces/item';

interface PollingContentProps {
  connectionId?: string;
  id: string;
  submited: boolean;
  setSubmited: any;
  pollingSubscription?: PollingSubscription;
  formProps?: IntegrationAdditionalContentFormProps;
  isVisible: boolean;
  toggleVisibility: (visible: boolean) => void;
}

export const PollingContent: React.FC<PollingContentProps> = ({
  connectionId,
  id,
  submited,
  setSubmited,
  pollingSubscription,
  formProps,
  isVisible,
  toggleVisibility,
}) => {
  const [pollingTemplate, setPollingTemplate] = useState<any>([]);
  const [pollingFormValues, setPollingFormValues] = useState<
    Record<string, string>
  >({});
  const prevPollingVisible = useRef(isVisible);

  const [
    fetchPollingSubscriptionByConnectionId,
    { data: pollingData, isLoading: isLoadingPollingSubscription },
  ] = usePollingSubscriptionByConnectionIdMutation();
  const [isNewPollingConnection, setIsNewPollingConnection] = useState<boolean>(
    !pollingSubscription
  );
  const [isToggleDisabled, setIsToggleDisabled] = useState<boolean>(
    !connectionId
  );
  const [update, { isSuccess: isUpdated }] =
    usePollingSubscriptionEditMutation();

  const [create, { isSuccess: isCreated }] =
    usePollingSubscriptionCreateMutation();

  const [deleteSubscription, { isSuccess: isDeleted }] =
    usePollingSubscriptionDeleteMutation();

  const {
    isLoading,
    data: applications,
    isError,
  } = usePollingTemplatesFetchByAppIdQuery(id, {
    skip: !isVisible || !connectionId,
  } as any);

  useEffect(() => {
    if (pollingTemplate?.parameters?.length) {
      formProps?.setParameters &&
        formProps?.setParameters(pollingTemplate?.parameters);

      setPollingFormValues((prevPollingFormValues) => {
        const defaultValues = pollingTemplate?.parameters?.reduce(
          (accumulator: any, currentValue: Parameter) => {
            accumulator[currentValue.name] = currentValue.defaultValue || '';

            return accumulator;
          },
          {}
        );

        return {
          ...defaultValues,
          ...prevPollingFormValues,
        };
      });
    }
  }, [formProps?.setParameters, pollingTemplate?.parameters]);

  useEffect(() => {
    formProps?.setValues && formProps?.setValues(pollingFormValues);
  }, [formProps?.setValues, pollingFormValues]);

  useEffect(() => {
    const performActions = async () => {
      const filledParams = Object.keys(pollingFormValues).length !== 0;
      if (submited && filledParams) {
        let params = [...pollingTemplate.parameters];
        params = params.map((param: any) => {
          return { ...param, value: pollingFormValues[param.name] };
        });

        try {
          if (!isNewPollingConnection) {
            let payload;
            if (pollingSubscription) {
              payload = pollingSubscription;
            } else {
              const polling: any = await fetchPollingSubscriptionByConnectionId(
                connectionId
              );
              payload = polling?.data;
            }

            await update({
              ...payload,
              parameters: params,
            });
          } else {
            await create({
              pollingTemplateId: applications?.id || '',
              connectionId: connectionId || '',
              parameters: params,
            });
            await setIsNewPollingConnection(false);
          }

          setSubmited(false);
        } catch (error) {
          console.error('Error occurred:', error);
        }
      }
    };

    if (isVisible) performActions();

    return () => {
      setSubmited(false);
    };
  }, [submited]);

  useEffect(() => {
    prevPollingVisible.current = isVisible;
  }, [isVisible]);

  const handlePollingVisibilityChange = (visible: boolean) => {
    toggleVisibility(visible);

    if (!visible && (pollingSubscription || pollingData) && !isLoading) {
      deleteSubscription(pollingSubscription?.id || pollingData.id);
      setIsNewPollingConnection(true);
      setPollingFormValues(() => ({}));
    }
  };

  useEffect(() => {
    if (isError) setIsToggleDisabled(true);
  }, [isError]);

  useEffect(() => {
    if (isVisible && connectionId) {
      setPollingTemplate(applications);
      if (isNewPollingConnection) {
        setPollingFormValues(() => ({}));
      } else {
        const updatedFormValues = applications?.parameters.reduce(
          (values, param) => {
            const fieldParam = pollingSubscription?.parameters.find(
              (item) => item.name === param.name
            );
            values[param.name] = fieldParam?.value || '';
            return values;
          },
          { ...pollingFormValues }
        );

        setPollingFormValues(updatedFormValues as Record<string, string>);
      }
    }
  }, [isVisible, connectionId, applications, isLoading]);

  const handleFormInputChange = (name: string) => (changeValue: string) => {
    setPollingFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: changeValue,
    }));
  };

  return (
    <>
      <VisibilityControlContent
        label="Enable Polling"
        disabled={isToggleDisabled}
        visible={isVisible}
        onVisibilityChange={handlePollingVisibilityChange}
      >
        {isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <div className="polling-content">
            {pollingTemplate?.parameters?.map((input: any) => {
              if (input.hidden) return <></>;

              return (
                <FormInput
                  key={input.name}
                  label={input.displayName}
                  name={input.name}
                  onChange={handleFormInputChange(input.name)}
                  value={
                    pollingFormValues[input.name] || input.defaultValue || ''
                  }
                  errorMessage={
                    formProps?.errors ? formProps?.errors[input.name] : ''
                  }
                />
              );
            })}
          </div>
        )}
      </VisibilityControlContent>
    </>
  );
};

import { FunctionComponent, useMemo, useState } from 'react';
import moment from 'moment';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { PersonPicker } from 'shared/components/DropdownPicker';
import { AvailableUser } from 'FindingDetails/interfaces/Users';
import BusinessImpactChip from 'shared/components/BusinessImpactChip';
import { useFetchCampaignQuery } from 'Campaigns/store/api';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { CampaignType } from 'shared/models/data/campaigns.model';
import StakeholdersGroup from 'Settings/components/StakeholdersGroup/StakeholdersGroup';
import { BusinessUnitStakeholder } from 'shared/models/data/extended-business-unit.model';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import CampaignGridActions from 'Campaigns/components/CampaignGridActions';

const useNavigateBackDifferentPath = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBack = () => {
    const currentPath = location.pathname;

    window.history.go(-1);

    const handlePopState = () => {
      const previousPath = window.location.pathname;

      if (previousPath !== currentPath) {
        window.removeEventListener('popstate', handlePopState);
      } else {
        window.history.go(-1);
      }
    };

    window.addEventListener('popstate', handlePopState);
  };

  return navigateBack;
};

// Example usage in a component
const MyComponent = () => {
  const navigateBack = useNavigateBackDifferentPath();

  return <button onClick={navigateBack}>Go Back to Different Path</button>;
};

export default MyComponent;

interface CampaignTrackerHeaderProps {}
export const CampaignTrackerHeader: FunctionComponent<
  CampaignTrackerHeaderProps
> = ({}) => {
  const navigateBack = useNavigateBackDifferentPath();

  const location = useLocation();

  const { id } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    data: campaignData,
    isFetching: campaignDataLoading,
    error: campaignDataError,
    refetch: refetchCampaignData,
  } = useFetchCampaignQuery(id || '', {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });

  const isCritical = useMemo(() => {
    if (!campaignData?.remainingDays) return false;

    if (!campaignData?.startTime || !campaignData?.endDate) return false;

    const totalDuration =
      new Date(campaignData.endDate).getTime() -
      new Date(campaignData.startTime).getTime();
    const tenPercentOfDuration = totalDuration * 0.1;
    const remainingTime = campaignData.remainingDays * 24 * 60 * 60 * 1000;

    return remainingTime <= tenPercentOfDuration;
  }, [campaignData]);

  if (campaignDataLoading)
    return (
      <Box className="main-content-skeleton">
        <Skeleton variant="rectangular" height={100} width={'100%'} />{' '}
      </Box>
    );

  if (campaignDataError || !campaignData) {
    return <></>;
  }
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const renderPriority = () => (
    <div className="priority">
      <p className="body-1">Priority</p>
      <BusinessImpactChip impact={campaignData.priority} />
    </div>
  );

  const renderAssignee = () => (
    <div className="assignees">
      <p className="body-1">Assignees</p>
      <StakeholdersGroup
        maxStakeholders={4}
        stakeholders={
          campaignData.stakeholders.map((user) => {
            return {
              id: user.id,
              fullName: user.name,
              avatarUrl: user.profilePictureUrl,
            } as BusinessUnitStakeholder;
          }) as BusinessUnitStakeholder[]
        }
      />
    </div>
  );

  const renderProgressBar = () => (
    <div className="completation-line">
      <div className="completation-line-progress-root">
        <div
          style={{ width: `${campaignData.completionRate * 100}%` }}
          className={`completation-line-progress-bar ${
            campaignData.completionRate * 100 < 50
              ? 'red'
              : campaignData.completionRate * 100 < 80
              ? 'orange'
              : 'green'
          }`}
        ></div>
      </div>
    </div>
  );

  const renderSettingsMenu = () => (
    <CampaignGridActions
      campaignData={campaignData}
      enableEdit
      onDeleteSuccess={() => {
        navigateBack();
      }}
    />
  );

  const renderCompletionRate = () => (
    <div className="completion-general-data">
      <p className="body-6 ">Completion Rate</p>
      <div className="completation-rate">
        <p>{`${campaignData.totalFindings - campaignData.activeFindings}/${
          campaignData.totalFindings
        }`}</p>
      </div>
      <p className="body-6 ">with</p>
      <CommonTooltip placement="top" title={renderTooltipContent()}>
        <div className={`remaining-days ${isCritical ? 'critical' : ''}`}>
          <p className="label-4">{campaignData.remainingDays || '-'}</p>
        </div>
      </CommonTooltip>
      <p className="body-6 ">Remaining Days</p>
    </div>
  );

  const renderTooltipContent = () => {
    return (
      <div className="remaining-days-tooltip">
        <div className="start-date">
          <p className="title body-2">Start Date</p>
          <p className="date body-1">
            {moment(campaignData.startTime).format('MMM D, YYYY')}
          </p>
        </div>
        <div className="end-date">
          <p className="body-2 title">End Date</p>
          <p className="date body-1">
            {moment(campaignData.endDate).format('MMM D, YYYY')}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="campaign-tracker-header">
      <div className="header-details">
        <div className="campaign-tracker-title">
          <div
            className={`go-back-arrow clickable-item ${
              window.history.length === 1 ? 'go-back-arrow-disabled' : ''
            }`}
            onClick={() => {
              if (window.history.length > 1) {
                navigateBack();
              }
            }}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
          </div>
          <div className="divider" />
          <p className="header-4" title={campaignData?.name}>
            {campaignData?.name}
          </p>
          {campaignData?.type === CampaignType.LIVE && (
            <div className="status">
              <div className="campaign-status-icon"></div>
              <p className="label-4">LIVE</p>
            </div>
          )}
        </div>
        <div className="campaign-tracker-info">
          {renderPriority()}
          <div className="finding-content-dot"></div>
          <div className="owner">
            <p className="body-1">Owner</p>
            <PersonPicker
              readonly
              selectedPerson={campaignData.ownerUser as AvailableUser}
            ></PersonPicker>
          </div>
          <div className="finding-content-dot"></div>
          {renderAssignee()}
        </div>
      </div>
      <div className="header-completion-rate">
        <div className="completion-percentage">
          <p>
            {campaignData.completionRate
              ? Math.round(campaignData.completionRate * 100)
              : '0'}
            %
          </p>
        </div>
        <div className="completion-info-container">
          {renderCompletionRate()}
          {renderProgressBar()}
        </div>
        <div className="settings">{renderSettingsMenu()}</div>
      </div>
    </div>
  );
};

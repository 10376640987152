export enum FindingExceptionStatus {
  PENDING = 'pending',
  ACTIVE = 'active',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  REVOKED = 'revoked',
  CANCELED = 'canceled',
}

export enum FindingExceptionTarget {
  FINDING = 'finding',
  RESOURCE = 'resource',
}

export enum FindingExceptionTimeOption {
  MONTHS = 'Months',
  DAYS = 'Days',
}

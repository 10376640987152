import { CircularProgress, Skeleton } from '@mui/material';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import {
  useFetchResourceOwnersQuery,
  useSetPrimaryResourceOwnerMutation,
} from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { findingOwnersTableColumns } from 'shared/fixtures/data/findings.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { ResourceOwner } from 'shared/models/data/resource-owner.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export interface ResourceAssetDetailsDrawerOwnersData {
  owners: Array<ResourceOwner>;
}

interface ResourceAssetDetailsDrawerOwnersProps extends BaseComponentProps {}

export const ResourceAssetDetailsDrawerOwners: FunctionComponent<
  ResourceAssetDetailsDrawerOwnersProps
> = () => {
  const { t: translation } = useTranslation();
  const [searchParams] = useSearchParams();
  const openResourceId = useMemo<string | null>(() => {
    return searchParams.get('openResourceId') || null;
  }, [searchParams.get('openResourceId')]);

  const [
    setPrimaryOwner,
    {
      isLoading: primaryOwnerLoading,
      isSuccess: primaryOwnerSuccess,
      reset: resetPrimaryOwner,
    },
  ] = useSetPrimaryResourceOwnerMutation();

  const {
    isFetching: ownersLoading,
    data: ownersData,
    error: ownersDataError,
    isSuccess: ownersDataSuccess,
  } = useFetchResourceOwnersQuery(
    {
      resourceId: openResourceId,
    },
    { skip: !Boolean(openResourceId) }
  );

  useEffect(() => {
    if (ownersDataSuccess) resetPrimaryOwner();
  }, [ownersDataSuccess]);

  const renderSectionBody = () => {
    if (ownersLoading || primaryOwnerLoading || openResourceId === undefined) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={80} width={'100%'} />
        </div>
      );
    }

    if (
      ownersData === undefined ||
      ownersData?.length === 0 ||
      ownersDataError
    ) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-grid-wrapper">
        <CommonSimpleDataGrid
          columnDefs={findingOwnersTableColumns}
          rowData={ownersData?.map((data) => ({
            ...data,
            setPrimaryOwner,
          }))}
          domLayout="autoHeight"
          isLoading={false}
          loadingOverlayComponent={() => <CircularProgress />}
          otherComponents={{
            NoDataBackdropComponent: <NoDataBackdrop />,
          }}
        />
      </div>
    );
  };

  return (
    <div className="finding-content-item finding-content-owners-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.USER_ICON} />}
        title="Owner"
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

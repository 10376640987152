export enum SingleFindingTab {
  DETAILS = 'Details',
  WORKFLOW = 'Workflow',
  ACTIVITY = 'Activity',
  INTELLIGENCE = 'Intelligence',
}

export enum FindingContentType {
  SUMMARY = 'summary',
  TIMELINE = 'timeline',
  INTERACTION = 'interaction',
  ACTIONS_TAKEN = 'actions-taken',
  RELATIONS = 'relations',
  RESOURCES = 'resources',
  IAC = 'iac',
  OPERATIONAL_STATUS = 'operational-status',
  EVIDENCE = 'evidence',
  SOURCE_INFORMATION = 'source-information',
  RISK_SCORE_BREAKDOWN = 'risk-score-breakdown',
  AFFECTED_PACKAGES = 'affected-packages',
}

export enum FindingLayer {
  ARTIFACT = 'Artifact',
  RUNTIME = 'Runtime',
  CODE = 'Code',
}

import { FunctionComponent, useMemo } from 'react';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OnboardingContent } from '../OnboardingContent/OnboardingContent';
import { useState } from 'react';
import {
  OnboardingStatus,
  TenantDetails,
  TenantPlans,
} from '../../models/onboarding-tenant-details.model';
import useCommonSelector from '../../../Common/utils/use-selector';
import { getTenantDetails } from '../../../Auth/store';

interface OnboardingModalProps extends BaseComponentProps {}

export const OnboardingModal: FunctionComponent<OnboardingModalProps> = () => {
  const [openOnboardingDialog, setOpenOnboardingDialogs] =
    useState<boolean>(false);
  const tenantDetails = useCommonSelector<TenantDetails | null>(
    getTenantDetails
  );
  useMemo(() => {
    if (tenantDetails) {
      const shouldOpenOnboarding =
        tenantDetails.plan === TenantPlans.Freemium &&
        tenantDetails.status === OnboardingStatus.NotStarted;

      setOpenOnboardingDialogs(shouldOpenOnboarding);
    }
  }, [tenantDetails]);

  return (
    <CommonModalContainer
      rootClassName="onboarding-modal"
      isOpen={openOnboardingDialog}
      handleClose={() => {
        setOpenOnboardingDialogs(false);
      }}
      title=""
    >
      <OnboardingContent
        closeModal={() => {
          setOpenOnboardingDialogs(false);
        }}
      />
    </CommonModalContainer>
  );
};

import { Button, Skeleton } from '@mui/material';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { useGetFindingEvidenceContentQuery } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FindingItem from 'shared/components/FindingItem';
import IconBox from 'shared/components/IconBox';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import Cloud2CodeWorkflow from 'Cloud2Code/components/Cloud2CodeWorkflow';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { GridType } from 'Risk/store';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';

interface FindingContentEvidenceProps extends BaseComponentProps {
  handleCloud2CodeModalOpen?: () => void;
  findingId?: string;
  showOnlyFlow?: boolean;
  isLoadingEvidanceData?: boolean;
  workspaceExternalId?: string;
}

const navigationHandler = new NavigationHandler();

export const FindingContentEvidence: FunctionComponent<
  FindingContentEvidenceProps
> = ({
  handleCloud2CodeModalOpen,
  findingId,
  showOnlyFlow,
  isLoadingEvidanceData,
  workspaceExternalId,
}) => {
  let [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();

  const navigate = useNavigate();

  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const [getUserAvailableNodesOfType, { data: nodeData }] =
    useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    getUserAvailableNodesOfType({
      nodesType: OrganizationNodeType.LOGICAL,
    });
  }, []);

  const { t: translation } = useTranslation();
  const [searchParams] = useQueryParams();
  const [showMoreTags, setShowMoreTags] = useState<boolean>(false);

  const {
    data: findingContentEvidenceData,
    isLoading: findingContentEvidenceDataLoading,
    error: findingContentEvidenceDataError,
  } = useGetFindingEvidenceContentQuery(
    findingId || searchParams.openFindingId,
    {
      skip: !findingId && !searchParams.openFindingId ? true : false,
    }
  );

  const renderResourceTags = () => {
    if (
      !findingContentEvidenceData?.resource?.tags ||
      findingContentEvidenceData?.resource?.tags?.length === 0
    ) {
      return <p>{translation(`common.noTagsAvailable`)}</p>;
    }

    const tags = findingContentEvidenceData?.resource?.tags as Array<any>;

    if (showMoreTags) {
      return (
        <>
          {tags.map((tag) => (
            <div className="finding-section-resource-tag">
              <div className="finding-section-resource-tag-name">
                {tag.tagName}:
              </div>
              <div className="finding-section-resource-tag-value">
                {tag.tagValue}
              </div>
            </div>
          ))}

          <Button
            className="base-opus-text-button finding-section-resource-tag-show-more"
            onClick={() => {
              setShowMoreTags(false);
            }}
          >
            {translation(`common.showLess`)}
          </Button>
        </>
      );
    }

    const mainTags = tags.slice(0, 5);
    const additionalTags = tags.slice(5);

    if (additionalTags.length) {
      return (
        <>
          {mainTags.map((tag) => {
            return (
              <div className="finding-section-resource-tag">
                <div className="finding-section-resource-tag-name">
                  {tag.tagName}:
                </div>
                <div className="finding-section-resource-tag-value">
                  {tag.tagValue}
                </div>
              </div>
            );
          })}
          <Button
            className="base-opus-text-button finding-section-resource-tag-show-more"
            onClick={() => {
              setShowMoreTags(true);
            }}
          >
            {translation(`common.showMore`)}
          </Button>
        </>
      );
    }

    return mainTags.map((tag) => {
      return (
        <div className="finding-section-resource-tag">
          <div className="finding-section-resource-tag-name">
            {tag.tagName}:
          </div>
          <div className="finding-section-resource-tag-value">
            {tag.tagValue}
          </div>
        </div>
      );
    });
  };

  const renderEnvironmentSubSection = () => {
    if (findingContentEvidenceData?.environment) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.SQUARE_CODE_ICON} />}
          title={translation(`findings.subSections.environment`)}
        >
          <div className="finding-content-environment-sub-section-body">
            <FindingItem title={translation(`findings.subSections.service`)}>
              {findingContentEvidenceData?.environment?.businessUnitName ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    navigationHandler.handleRiskNavigation(GridType.None, {
                      businessUnitId: [
                        {
                          id: findingContentEvidenceData?.environment
                            ?.businessUnitId,
                          name: findingContentEvidenceData?.environment
                            ?.businessUnitName,
                        },
                      ],
                    });
                  }}
                >
                  <InteractiveLabel
                    icon={<OpusSvgIcon type={SVG_ICON_TYPES.BRIEFCASE_ICON} />}
                    label={
                      findingContentEvidenceData?.environment?.businessUnitName
                    }
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.group`)}>
              {findingContentEvidenceData?.environment
                ?.businessUnitGroupName ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    const groupNode = nodeData?.find(
                      (nodeDataItem: OrganizationNode) =>
                        nodeDataItem.name ===
                        findingContentEvidenceData?.environment
                          ?.businessUnitGroupName
                    );

                    navigationHandler.handleRiskNavigation(GridType.None, {
                      groupId: {
                        id: groupNode?.id,
                        name: groupNode?.name,
                      },
                    });
                  }}
                >
                  <InteractiveLabel
                    icon={
                      <OpusSvgIcon type={SVG_ICON_TYPES.PEOPLE_GROUP_ICON} />
                    }
                    label={
                      findingContentEvidenceData?.environment
                        ?.businessUnitGroupName
                    }
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem
              title={translation(`findings.subSections.workspace`)}
              tooltipText={workspaceExternalId}
            >
              {findingContentEvidenceData?.environment?.cloudProvider ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    navigationHandler.handleRiskNavigation(GridType.None, {
                      workspace: [
                        {
                          id: findingContentEvidenceData?.environment
                            ?.cloudWorkspaceId,
                          name: findingContentEvidenceData?.environment
                            ?.cloudEnvironmentAlias,
                        },
                      ] as Array<FilterOption>,
                    });
                  }}
                >
                  <IconBox
                    icon={
                      <div className="finding-content-workspace-icon">
                        <img
                          src={
                            findingContentEvidenceData?.environment
                              ?.cloudProviderLogo
                          }
                        ></img>
                      </div>
                    }
                    helperText={
                      findingContentEvidenceData?.environment
                        ?.cloudWorkspaceExternalId
                    }
                    label={
                      findingContentEvidenceData?.environment
                        ?.cloudEnvironmentAlias
                    }
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.type`)}>
              {findingContentEvidenceData?.environment
                ?.businessUnitEnvironmentType ? (
                <InteractiveLabel
                  icon={<OpusSvgIcon type={SVG_ICON_TYPES.CLOUD_ICON} />}
                  label={
                    findingContentEvidenceData?.environment
                      ?.businessUnitEnvironmentType
                  }
                />
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderResourceAdditionalProperties = () => {
    if (
      findingContentEvidenceData?.resource?.additionalProperties &&
      Object.keys(findingContentEvidenceData?.resource?.additionalProperties)
        ?.length
    ) {
      const additionalProperties =
        findingContentEvidenceData?.resource?.additionalProperties;

      return (
        <div className="finding-content-resource-sub-section-additonal-properties-row">
          {Object.keys(additionalProperties)?.map((additionalPropertyKey) => (
            <FindingItem title={additionalPropertyKey}>
              <p
                title={
                  additionalProperties[
                    additionalPropertyKey as keyof typeof additionalProperties
                  ]
                }
              >
                {
                  additionalProperties[
                    additionalPropertyKey as keyof typeof additionalProperties
                  ]
                }
              </p>
            </FindingItem>
          ))}
        </div>
      );
    }

    return <></>;
  };

  const handleGoToResourceView = (resourceId: string) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (updatedReactiveSearchParams.has('openFindingId'))
      updatedReactiveSearchParams.delete('openFindingId');

    if (updatedReactiveSearchParams.has('openCodeEventId'))
      updatedReactiveSearchParams.delete('openCodeEventId');

    updatedReactiveSearchParams.set('openResourceId', resourceId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const handleGoToRootCause = () => {
    const codeEventId = findingContentEvidenceData?.cloudToCode?.id;
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (
      updatedReactiveSearchParams.has('openResourceId') &&
      updatedReactiveSearchParams.has('openFindingId')
    ) {
      updatedReactiveSearchParams.delete('openFindingId');
    }

    updatedReactiveSearchParams.set('openCodeEventId', codeEventId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const renderResourceSubSection = () => {
    if (findingContentEvidenceData?.resource) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.BOX_ICON} />}
          title={translation(`findings.subSections.resource`)}
          sectionNavigateName={
            findingContentEvidenceData?.resource?.resourceId
              ? translation(`findings.subSections.goToResource`)
              : undefined
          }
          onSectionNavigate={() => {
            handleGoToResourceView(
              findingContentEvidenceData?.resource?.assetUniqueId
            );
          }}
        >
          <div className="finding-content-resource-sub-section-body">
            <div className="finding-content-resource-sub-section-main-row">
              <FindingItem
                title={translation(`findings.subSections.resourceName`)}
              >
                <IconBox
                  icon={
                    <div className="finding-content-resource-logo">
                      <img
                        src={
                          findingContentEvidenceData?.resource?.resourceTypeLogo
                        }
                      ></img>
                    </div>
                  }
                  helperText={findingContentEvidenceData?.resource?.type}
                  label={findingContentEvidenceData?.resource?.name}
                />
              </FindingItem>
              <FindingItem
                title={translation(`findings.subSections.resourceId`)}
              >
                <div
                  className="finding-content-resource-item-body-collapsable-text"
                  title={findingContentEvidenceData?.resource?.resourceId}
                >
                  {findingContentEvidenceData?.resource?.resourceId}
                </div>
              </FindingItem>
            </div>
            {renderResourceAdditionalProperties()}
            <div className="finding-content-resource-sub-section-tags-row">
              <FindingItem title={translation(`findings.subSections.tags`)}>
                {renderResourceTags()}
              </FindingItem>
            </div>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderCloudToCodeSection = () => {
    if (findingContentEvidenceData?.cloudToCode) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_NETWORK_ICON} />}
          title={translation(`findings.subSections.cloudToCode`)}
          sectionNavigateName={
            !showOnlyFlow
              ? translation(`findings.subSections.goToRootCause`)
              : undefined
          }
          onSectionNavigate={handleGoToRootCause}
        >
          <div className="finding-content-cloud-to-code-sub-section-body">
            {!showOnlyFlow && (
              <div className="finding-content-cloud-to-code-sub-section-main-row finding-content-section-row">
                <FindingItem
                  title={translation(`findings.subSections.fileName`)}
                >
                  {' '}
                  <p>{findingContentEvidenceData?.cloudToCode?.fileName}</p>
                </FindingItem>
                <FindingItem
                  title={translation(`findings.subSections.repository`)}
                >
                  <InteractiveLabel
                    label={
                      findingContentEvidenceData?.cloudToCode?.repositoryName
                    }
                    icon={
                      <div className="finding-content-application-icon">
                        <img
                          src={
                            findingContentEvidenceData?.cloudToCode
                              ?.repositoryLogoUrl
                          }
                        ></img>
                      </div>
                    }
                    link={
                      findingContentEvidenceData?.cloudToCode?.repositoryLink
                    }
                  />
                </FindingItem>

                <FindingItem
                  title={translation(`findings.subSections.associatedFindings`)}
                >
                  <p>
                    {findingContentEvidenceData?.cloudToCode?.findingsCount}
                  </p>
                </FindingItem>

                <FindingItem
                  title={translation(
                    `findings.subSections.suggestedFixAvailable`
                  )}
                >
                  <p>
                    {translation(
                      `common.${
                        findingContentEvidenceData?.cloudToCode
                          ?.codeFixSuggestion
                          ? 'yes'
                          : 'no'
                      }`
                    )}
                  </p>
                </FindingItem>
              </div>
            )}
            <div className="finding-content-cloud-to-code-sub-section-workflow-row">
              <Cloud2CodeWorkflow
                flowData={findingContentEvidenceData?.cloudToCode?.flow}
              />
            </div>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderSectionBody = () => {
    if (findingContentEvidenceDataLoading || isLoadingEvidanceData) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }

    if (
      !findingContentEvidenceDataLoading &&
      (findingContentEvidenceData === undefined ||
        findingContentEvidenceDataError ||
        (!findingContentEvidenceData?.environment &&
          !findingContentEvidenceData?.resource &&
          !findingContentEvidenceData?.cloudToCode))
    ) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-section-body">
        {!showOnlyFlow && (
          <>
            {renderEnvironmentSubSection()}
            {renderResourceSubSection()}
          </>
        )}

        {renderCloudToCodeSection()}
      </div>
    );
  };

  return (
    <div className="finding-content-item finding-content-evidence-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.FINGERPRINT_ICON} />}
        title={translation(`findings.sections.evidence`)}
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import { MenuItemProps } from 'Integrations/Common/MenuItem/MenuItem';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import { IntegrationItemSubCategory } from 'Integrations/interfaces/IntegrationItem.model';
import { OnboardingSecurityHubForm } from 'Onboarding/components/OnboardinSecurityHubForm/OnboardingSecurityHubForm';
import { OnboardingAwsForm } from 'Onboarding/components/OnboardingAwsForm/OnboardingAwsForm';
import { OnboardingGithubForm } from 'Onboarding/components/OnboardingGithubForm/OnboardingGithubForm';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { BusinessUnitImpact } from 'shared/enums/business-unit.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BusinessUnitMetadata } from 'shared/models/data/business-unit.model';
import {
  ExtendedBusinessUnitCreateModel,
  ExtendedBusinessUnitModel,
} from 'shared/models/data/extended-business-unit.model';
import {
  OnboardingState,
  OnboardingStep,
  OnboardingStepAction,
  OnboardingStepId,
  OnboardingStepStatus,
} from 'shared/models/data/onboarding.model';

export const onboardingMenuList: MenuItemProps[] = [
  {
    title: 'All',
    icon: <OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_VIRUS_ICON} />,
    isActive: true,
    onClick: () => {},
  },
  {
    title: IntegrationCategory.CLOUD_PROVIDER,
    icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLOUD_ICON} />,
    isActive: false,
    onClick: () => {},
  },
  {
    title: IntegrationCategory.FINDINGS_SOURCE,
    icon: <OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_KEYHOLE_ICON} />,
    isActive: false,
    onClick: () => {},
  },
  {
    title: IntegrationCategory.SCM,
    icon: <OpusSvgIcon type={SVG_ICON_TYPES.CODE_FORK_ICON} />,
    isActive: false,
    onClick: () => {},
  },
];

export const defaultOnboardingBusinessUnit: ExtendedBusinessUnitCreateModel = {
  name: 'My First Application',
  businessUnitCriticality: BusinessUnitImpact.HBI,
  associatedAccounts: [],
  tags: 'Owner',
  ticketingSystems: [],
  riskAttributes: [],
  associatedDomains: [],
  assetGroups: [],
  users: [],
};

export const onboardingDefaultSteps: Array<OnboardingStep> = [
  {
    id: OnboardingStepId.AWS,
    nextStepId: OnboardingStepId.SECURITY_HUB,
    applicationId: ApplicationTypes.AWS,
    description:
      'Build a resilient risk management program leveraging data-driven and risk-driven approach by adding contextual information to each security finding and help.',
    previousStepId: null,
    name: 'AWS',
    integrationItem: {
      category: IntegrationCategory.CLOUD_PROVIDER,
      count: 0,
      id: 'AWS',
      logo: 'https://res.cloudinary.com/opus-security/image/upload/d_application-logos:application-default-logo.png/application-logos/AWS.png',
      name: 'AWS',
    },
    renderContent: (props) => <OnboardingAwsForm {...props} />,
    required: true,
    status: OnboardingStepStatus.EMPTY,
    shouldNavigateAfterCreate: true,
  },
  {
    id: OnboardingStepId.SECURITY_HUB,
    nextStepId: OnboardingStepId.GITHUB,
    applicationId: ApplicationTypes.SECURITY_HUB,
    previousStepId: OnboardingStepId.AWS,
    description:
      'Prioritize vulnerabilities based on contextual data, Opus assist organization to prioritize security issues, based both on characteristics of the security findings and information as to how the security finding might affect the business',
    name: 'AWS Security Hub',
    integrationItem: {
      category: IntegrationCategory.FINDINGS_SOURCE,
      subCategory: IntegrationItemSubCategory.WEBHOOK,
      count: 0,
      id: 'SecurityHub',
      logo: 'https://res.cloudinary.com/opus-security/image/upload/d_application-logos:application-default-logo.png/application-logos/SecurityHub.png',
      name: 'AWS Security Hub',
    },
    renderContent: (props) => (
      <OnboardingSecurityHubForm {...props} businessUnitRequired={false} />
    ),
    required: true,
    status: OnboardingStepStatus.EMPTY,
    shouldNavigateAfterCreate: false,
  },
  {
    id: OnboardingStepId.GITHUB,
    nextStepId: null,
    applicationId: ApplicationTypes.GITHUB,
    previousStepId: OnboardingStepId.SECURITY_HUB,
    name: 'Github',
    description:
      'Integrates with existing cloud and security tools to gain visibility into security findings across the entire organization and automate remediation flow',
    integrationItem: {
      category: IntegrationCategory.SCM,
      count: 0,
      id: 'Github',
      logo: 'https://res.cloudinary.com/opus-security/image/upload/d_application-logos:application-default-logo.png/application-logos/Github.png',
      name: 'Github',
    },
    renderContent: (props) => <OnboardingGithubForm {...props} />,
    required: false,
    status: OnboardingStepStatus.EMPTY,
    shouldNavigateAfterCreate: false,
  },
];

export const onboardingInitialState: OnboardingState = {
  activeStepId: null,
  isComplete: false,
  connectionIds: {},
};

import React from 'react';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

export abstract class DataGridTable<P> extends React.Component<
  P & DataGridProps,
  {}
> {
  render() {
    const { components, ...otherProps } = this.props;

    return (
      <DataGrid
        components={{
          ...components,
          ColumnUnsortedIcon: () => (
            <OpusSvgIcon type={SVG_ICON_TYPES.SORT_GROUP_ICON} fill="#BDD4FF" />
          ),
          ColumnSortedAscendingIcon: () => (
            <div className="data-grid-table-column-title-sort-icon-container">
              <OpusSvgIcon
                type={SVG_ICON_TYPES.SORT_ASCENDING_ICON}
                width="13px"
                fill="#31156F"
              />
              <div className="data-grid-table-column-title-sort-icon-container-placeholder-box"></div>
            </div>
          ),
          ColumnSortedDescendingIcon: () => (
            <div className="data-grid-table-column-title-sort-icon-container">
              <div className="data-grid-table-column-title-sort-icon-container-placeholder-box"></div>
              <OpusSvgIcon
                type={SVG_ICON_TYPES.SORT_DESCENDING_ICON}
                width="13px"
                fill="#31156F"
              />
            </div>
          ),
        }}
        classes={{
          root: 'data-grid-table-container',
          main: 'data-grid-table',
          virtualScroller: 'data-grid-table-virtual-scroller',
          virtualScrollerRenderZone:
            'data-grid-table-virtual-scroller-render-zone',
          columnHeaders: 'data-grid-table-column-header-container',
          columnHeadersInner: 'data-grid-table-column-header-inner',
          columnSeparator: 'data-grid-table-column-separator',
          columnHeaderTitle: 'data-grid-table-column-title',
          columnHeaderTitleContainer: 'data-grid-table-column-title-container',
          'columnHeader--sorted': 'data-grid-table-column-title-sort',
          'columnHeader--sortable': 'data-grid-table-column-title-sort',
          sortIcon: 'data-grid-table-column-sort-icon',
        }}
        {...otherProps}
      />
    );
  }
}

import { useEffect, useMemo, useRef, useState, useCallback } from 'react';
import {
  useCreateBusinessUnitMutation,
  useSearchServicesMutation,
  useUpdateBusinessUnitMutation,
} from '../../store/api';
import AddIcon from '@mui/icons-material/Add';
import {
  getSettingsSearchParams,
  setSearchParams as setSearchParamsStore,
  SettingsFilterItem,
} from 'Settings/store';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import { BusinessUnitViewPanel } from 'shared/components/BusinessUnitViewPanel/BusinessUnitViewPanel';
import { DrawerMode } from 'shared/enums/business-unit.enum';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SettingsTabPanel } from 'Settings/interfaces/SettingTabPanel.enum';
import { BusinessUnitFormPanel } from 'shared/components/BusinessUnitEditPanel/BusinessUnitFormPanel';
import SettingsGridColumns from '../SettingsLayout/settings-data-grid';
import { AuthorizedContent } from '@frontegg/react';
import { TextField, Button, CircularProgress, Grid } from '@mui/material';
import { Box } from '@mui/system';
import {
  RowClickedEvent,
  GetRowIdParams,
  AgGridEvent,
} from 'ag-grid-community';
import CardWrapper from 'Common/components/CardWrapper';
import CommonPagination from 'Common/components/Pagination';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { OrderState } from 'Common/utils/sort';
import useCommonSelector from 'Common/utils/use-selector';

export default function BusinessUnitList() {
  const { t: translation } = useTranslation();
  const dispatch = useCommonDispatch();
  const [searchParams, setSearchParams] = useQueryParams();
  const gridRef = useRef<AgGridEvent | null>(null);
  const searchParamsStore = useCommonSelector(getSettingsSearchParams);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const [searchServices, { isLoading, data: serviceList }] =
    useSearchServicesMutation();
  const userPermissions = usePermissions();

  useEffect(() => {
    searchServices({
      ...searchParamsStore,
    });
  }, [searchParamsStore]);

  const [
    businessUnitActionCancelationModalOpen,
    setBusinessUnitActionCancelationModalOpen,
  ] = useState<boolean>(false);

  const openCancelationModalHandler = () => {
    setBusinessUnitActionCancelationModalOpen(true);
  };

  const closeCancelationModalHandler = () => {
    setBusinessUnitActionCancelationModalOpen(false);
  };

  const [
    createBusinessUnit,
    {
      isLoading: creatingBusinessUnit,
      error: createBusinessUnitError,
      isSuccess: createBusinessUnitSuccess,
      reset: createBusinessUnitReset,
    },
  ] = useCreateBusinessUnitMutation();

  const [
    updateBusinessUnit,
    {
      isLoading: updatingBusinessUnit,
      error: updateBusinessUnitError,
      isSuccess: updateBusinessUnitSuccess,
      reset: updateBusinessUnitReset,
    },
  ] = useUpdateBusinessUnitMutation();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  const itemDeletedHandler = () => {
    drawerCloseHandler();
    searchServices({
      ...searchParamsStore,
    });
  };

  useEffect(() => {
    if (searchParams.mode) {
      setPopoverOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (createBusinessUnitSuccess || updateBusinessUnitSuccess) {
      drawerDefiniteCloseHandler();
      searchServices({
        ...searchParamsStore,
      });
      createBusinessUnitReset();
      updateBusinessUnitReset();
    }
  }, [createBusinessUnitSuccess, updateBusinessUnitSuccess]);

  const hasBUReadPermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_BUS_READ
  );

  const drawerOpenHandler = () => {
    setSearchParams({
      activeTabId: SettingsTabPanel.SERVICE,
      mode: DrawerMode.CREATE,
    });
  };

  const rowClickHandler = (rowId: string) => {
    setSearchParams({
      ...searchParams,
      mode: DrawerMode.VIEW,
      businessUnitId: rowId,
    });
  };

  const editClickHandler = () => {
    setSearchParams({
      ...searchParams,
      mode: DrawerMode.EDIT,
    });
  };

  const drawerCloseHandler = () => {
    const drawerMode = searchParams.mode as DrawerMode;

    drawerDefiniteCloseHandler();
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    if (columnOrderPayload.length) {
      dispatch(
        setSearchParamsStore({
          ...searchParamsStore,
          order: {
            field: columnOrderPayload[0].field,
            type: columnOrderPayload[0].type,
          },
        })
      );
    }
  };

  const drawerDefiniteCloseHandler = () => {
    setPopoverOpen(false);
    setSearchParams({
      activeTabId: SettingsTabPanel.SERVICE,
    });
  };
  const handlePopoverClose = () => {
    const drawerMode = searchParams.mode as DrawerMode;
    if (drawerMode === DrawerMode.VIEW) {
      drawerDefiniteCloseHandler();
    } else {
      formChanged
        ? setBusinessUnitActionCancelationModalOpen(true)
        : drawerCloseHandler();
    }
  };

  const renderDrawerContent = () => {
    const drawerMode = searchParams.mode as DrawerMode;

    switch (drawerMode) {
      case DrawerMode.CREATE:
        return (
          <BusinessUnitFormPanel
            detectFormChanged={(isChanged: boolean) =>
              setFormChanged(isChanged)
            }
            formProps={{
              error: createBusinessUnitError,
              loading: creatingBusinessUnit,
              onSubmit: createBusinessUnit,
              onCancel: drawerDefiniteCloseHandler,
            }}
            cancelationModalProps={{
              open: businessUnitActionCancelationModalOpen,
              onClose: closeCancelationModalHandler,
              onOpen: openCancelationModalHandler,
            }}
          />
        );

      case DrawerMode.EDIT:
        return (
          <BusinessUnitFormPanel
            detectFormChanged={(isChanged: boolean) =>
              setFormChanged(isChanged)
            }
            formProps={{
              error: updateBusinessUnitError,
              loading: updatingBusinessUnit,
              onSubmit: updateBusinessUnit,
              onCancel: drawerDefiniteCloseHandler,
            }}
            cancelationModalProps={{
              open: businessUnitActionCancelationModalOpen,
              onClose: closeCancelationModalHandler,
              onOpen: openCancelationModalHandler,
            }}
          />
        );

      case DrawerMode.VIEW:
        return (
          <BusinessUnitViewPanel
            onEditClick={editClickHandler}
            onItemDeleted={itemDeletedHandler}
          />
        );
      default:
        return <></>;
    }
  };
  const servicesColumns = useMemo(() => {
    return SettingsGridColumns.getServiceColumns({
      t: translation,
    });
  }, []);

  const searchInputValue = useMemo(() => {
    if (searchParamsStore.filter && searchParamsStore.filter.name) {
      return (searchParamsStore.filter.name as SettingsFilterItem).value || '';
    }
    return '';
  }, [searchParamsStore]);

  const onSearch = useCallback(
    (searchInput: string) => {
      let filterObj = { ...searchParamsStore.filter };
      filterObj.name = { value: searchInput };
      dispatch(
        setSearchParamsStore({
          ...searchParamsStore,
          filter: filterObj,
        })
      );
    },
    [searchParamsStore, dispatch]
  );

  const onPageChange = (pageNumber: number) => {
    dispatch(
      setSearchParamsStore({
        ...searchParamsStore,
        skip: searchParamsStore.take * (pageNumber - 1),
      })
    );
  };

  const onPageSizeChange = (pageSize: number) => {
    dispatch(
      setSearchParamsStore({
        ...searchParamsStore,
        take: pageSize,
        skip: 0,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(
        setSearchParamsStore({
          ...searchParamsStore,
          filter: {},
        })
      );
    };
  }, []);

  if (!hasBUReadPermission) return <ForbiddenPage />;

  return (
    <>
      <Box m={4} gap={2} display="grid">
        <CardWrapper padding={2} className={`settings-header`} height={'64px'}>
          <Grid className="settings-header-container" container>
            <Grid item className="settings-header-filters">
              <>
                <TextField
                  value={searchInputValue}
                  className="filter-input filter-main-input"
                  placeholder={translation(`common.searchPlaceholder`)}
                  onChange={(e: any) => {
                    onSearch(e.target.value);
                  }}
                  InputProps={{
                    className: 'search-filter-input',
                    startAdornment: (
                      <OpusSvgIcon
                        type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON}
                      />
                    ),
                  }}
                />
              </>
            </Grid>
            <Grid item className="settings-header-action-button">
              <AuthorizedContent
                requiredPermissions={[ApplicationPermission.SETTINGS_BUS_WRITE]}
                render={(isAuthorized) => (
                  <Button
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={() => drawerOpenHandler()}
                    className="opus-primary-button settings-header-button"
                    disabled={!isAuthorized}
                  >
                    <FormattedMessage
                      id="settings.details.addBusinessUnit"
                      defaultMessage="Add Service"
                    />
                  </Button>
                )}
              />
            </Grid>
          </Grid>
        </CardWrapper>
        <Box height={'58vh'}>
          <CommonSimpleDataGrid
            rowData={serviceList?.data}
            columnDefs={servicesColumns}
            rowSelection="multiple"
            defaultColDef={{
              resizable: true,
              sortable: true,
            }}
            gridRef={gridRef}
            containerClassName="settings-data-grid"
            suppressRowClickSelection
            onRowClicked={(event: RowClickedEvent) => {
              const targetElement = event?.event?.target as HTMLElement;
              if (
                targetElement.tagName === 'A' ||
                (targetElement.parentNode as Element | null)?.tagName === 'A'
              ) {
                event?.event?.stopPropagation();
              } else {
                rowClickHandler(event.data.id);
              }
            }}
            visibilityControlProps={{
              enableVisibilityControls: false,
              columns: [],
            }}
            onSort={onHandleColumnOrderChange}
            isLoading={isLoading}
            keepCurrentSelections
            otherComponents={{
              NoDataBackdropComponent: <NoDataBackdrop />,
            }}
            loadingOverlayComponent={() => <CircularProgress />}
            getRowId={(row: GetRowIdParams) => {
              return row.data.id;
            }}
          />
        </Box>
        <Box mt={2} className="settings-pagination" mx={2}>
          <CommonPagination
            totalItems={serviceList?.totalItems || 0}
            pageSize={searchParamsStore.take}
            currentPage={
              searchParamsStore.skip
                ? searchParamsStore.skip / searchParamsStore.take + 1
                : 1
            }
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
          />
        </Box>
      </Box>

      <PopoverWrapper
        showPopover={popoverOpen}
        handleClose={handlePopoverClose}
      >
        {renderDrawerContent()}
      </PopoverWrapper>
    </>
  );
}

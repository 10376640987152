import { ReactNode } from 'react';
import { string } from 'yup';

interface SimpleTagProps {
  value?: number | string;
  icon?: ReactNode;
  warning?: boolean;
  bordered?: boolean;
  classes?: string;
}

export const SimpleTag: React.FC<SimpleTagProps> = ({
  value,
  icon,
  warning = false,
  bordered = true,
  classes,
}) => {
  return (
    <div
      className={`simple-tag-component ${classes} ${
        bordered ? 'bordered' : ''
      } ${warning ? 'warning' : 'info'}`}
    >
      <>
        {value && <span className="label-1">{value}</span>}
        {icon && icon}
      </>
    </div>
  );
};

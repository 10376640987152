export enum DiagramNodeTypes {
  STEP_NODE = 'stepNode',
  CROSSROAD = 'crossRoad',
  AWS = 'aws',
  HTTP = 'HTTP',
  http = 'http',
}

export enum DiagramModalType {
  PICKER = 'picker',
  ENTITY = 'entity',
  ACTION = 'action',
  ACTION_TEMPLATE = 'actionTemplate',
  CROSSROAD_DETAILS = 'crossRoadDetails',
}

export enum DiagramAction {
  CALL = 'Call',
  SEND_MESSAGE = 'Send Message',
  CREATE_CHANNEL = 'Create Channel',
}

export const rootWorkflowTriggerSeqId = 0;

import { useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  CloudWorkspaceCreate,
  useCreateCloudWorkspaceMutation,
  useUpdateCloudWorkspaceMutation,
} from '../../../store/api';
import { CloudWorkspace } from '../../../interfaces/CloudWorkspace';
import { CloudProviderType } from '../../../interfaces/CloudProviderType.enum';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { EnvironmentType } from 'Settings/interfaces/EnvironmentType.enum';

const codeRepoCloudProviders = [
  CloudProviderType.AZURE_REPOS,
  CloudProviderType.GITHUB,
  CloudProviderType.GITLAB,
];

interface Props {
  onClose: (shouldReload?: boolean) => void;
  workspace?: CloudWorkspace | null;
  onSave?: boolean;
  setOnSave: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSubmitResponse: React.Dispatch<React.SetStateAction<any>>;
}

export default function CodeRepositoryDialogContent({
  onClose,
  workspace,
  onSave,
  setIsLoading,
  setOnSave,
  setSubmitResponse,
}: Props) {
  const { t: translation } = useTranslation();

  const formElement = useRef<HTMLFormElement>(null);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [
    createCloudWorkspace,
    {
      isLoading: isLoadingCreate,
      isSuccess: isCreated,
      isError: isCreateError,
      error: createErrorMessage,
    },
  ] = useCreateCloudWorkspaceMutation();
  const [
    updateCloudWorkspace,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isUpdated,
      isError: isUpdateError,
      error: updateErrorMessage,
    },
  ] = useUpdateCloudWorkspaceMutation();
  const isLoading = isLoadingCreate || isLoadingUpdate;

  const [workspaceType, setWorkspaceType] = useState<CloudProviderType>();

  const [environmentType, setEnvironmentType] =
    useState<EnvironmentType | null>(() => {
      if (
        workspace &&
        !workspace?.environmentType &&
        codeRepoCloudProviders.includes(
          workspace.cloudProvider as CloudProviderType
        )
      ) {
        return EnvironmentType.CODE;
      }

      return workspace?.environmentType as EnvironmentType;
    });

  useEffect(() => {
    if (isCreated || isUpdated) {
      onClose(true);
    } else {
      setOnSave(false);
      setFormErrors({});
    }
    setIsLoading(isLoading);
  }, [isCreated, isUpdated, isLoading]);

  async function handleSubmit() {
    const form = formElement.current;
    if (!form) return;
    const formData = new FormData(form);
    const item: CloudWorkspaceCreate = {
      cloudProvider: formData.get('cloudProvider') as string,
      externalId: formData.get('externalId') as string,
      alias: formData.get('alias') as string,
      environmentType: environmentType as string,
      additionalInfo: {},
    };
    const errors = validateFormFields(item);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setOnSave(false);
    } else {
      setFormErrors({});
      workspace
        ? updateCloudWorkspace({ id: workspace.id, item })
        : createCloudWorkspace(item);
    }
  }

  function validateFormFields(item: CloudWorkspaceCreate) {
    const errors: { [key: string]: string } = {};

    if (!item.cloudProvider) {
      errors['cloudProvider'] = translation('settings.details.requiredField', {
        param1: translation('settings.details.sourceCodeType'),
      });
    }

    if (!item.externalId) {
      errors['externalId'] = translation('settings.details.requiredField', {
        param1: translation('settings.details.workspaceId'),
      });
    }
    if (!item.alias) {
      errors['alias'] = translation('settings.details.requiredField', {
        param1: translation('settings.details.workspaceAlias'),
      });
    }

    if (!environmentType) {
      errors['environmentType'] = translation(
        'settings.details.requiredField',
        {
          param1: translation('settings.details.workspaceEnvironmentType'),
        }
      );
    }

    return errors;
  }

  useEffect(() => {
    if (onSave) handleSubmit();
  }, [onSave]);

  const handleProviderChange = (cloudProvider: CloudProviderType) => {
    setWorkspaceType(cloudProvider);

    if (codeRepoCloudProviders.includes(cloudProvider)) {
      setEnvironmentType(EnvironmentType.CODE);
    } else {
      setEnvironmentType(null);
    }
  };

  useEffect(() => {
    const isError = isUpdateError || isCreateError;
    const error = isError
      ? ((isUpdateError ? updateErrorMessage : createErrorMessage) as any)?.data
          ?.message
      : null;
    const isCreate = isCreateError && isError;
    const isUpdate = isUpdateError && isError;

    setSubmitResponse({ error, isCreate, isUpdate });
  }, [isCreateError, isUpdateError]);

  const renderWorkspaceEnvironmentTypeInput = () => {
    const isInputDisabled =
      (workspace &&
        workspaceType === undefined &&
        codeRepoCloudProviders.includes(
          workspace.cloudProvider as CloudProviderType
        )) ||
      (workspaceType && codeRepoCloudProviders.includes(workspaceType));

    return (
      <>
        <Typography className="input-label">
          <FormattedMessage
            id="settings.details.workspaceEnvironmentType"
            defaultMessage="Workspace Environment Type"
          />
        </Typography>
        <FormControl
          fullWidth
          error={!!formErrors['environmentType']}
          className="input-dialog"
        >
          <Select
            fullWidth
            className="input-dialog select-input"
            name="environmentType"
            value={environmentType || ''}
            inputProps={{ required: true }}
            disabled={isInputDisabled}
            onChange={(event) => {
              setEnvironmentType(event.target.value as EnvironmentType);
            }}
          >
            {Object.values(EnvironmentType).map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {formErrors['environmentType'] && (
            <FormHelperText>{formErrors['environmentType']}</FormHelperText>
          )}
        </FormControl>
      </>
    );
  };

  return (
    <Grid container spacing={24}>
      <Grid item xs={12}>
        <form ref={formElement} onSubmit={handleSubmit}>
          <Typography className="input-label">
            <FormattedMessage
              id="settings.details.sourceCodeType"
              defaultMessage="Source Code Type"
            />
          </Typography>
          <FormControl
            fullWidth
            error={!!formErrors['cloudProvider']}
            className="input-dialog"
          >
            <Select
              fullWidth
              className="input-dialog select-input"
              name="cloudProvider"
              defaultValue={workspace?.cloudProvider || ''}
              inputProps={{ required: true }}
              onChange={(event) => {
                handleProviderChange(event.target.value as CloudProviderType);
              }}
            >
              {Object.values(CloudProviderType).map((value, index) => (
                <MenuItem key={index} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {formErrors['cloudProvider'] && (
              <FormHelperText>{formErrors['cloudProvider']}</FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />
          {renderWorkspaceEnvironmentTypeInput()}
          <Box mb={3} />
          <Typography className="input-label">
            <FormattedMessage
              id="settings.details.workspaceAlias"
              defaultMessage="Workspace Alias"
            />
          </Typography>
          <FormControl
            fullWidth
            error={!!formErrors['alias']}
            className="input-dialog"
          >
            <Input
              fullWidth
              disableUnderline
              inputProps={{ required: true }}
              className="input-dialog"
              name="alias"
              placeholder={translation(`settings.details.enterTheAccountName`)}
              defaultValue={workspace?.alias}
            />
            {formErrors['alias'] && (
              <FormHelperText>{formErrors['alias']}</FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />
          <Typography className="input-label">
            <FormattedMessage
              id="settings.details.workspaceId"
              defaultMessage="Workspace ID"
            />
          </Typography>
          <FormControl
            fullWidth
            error={!!formErrors['externalId']}
            className="input-dialog"
          >
            <Input
              fullWidth
              disableUnderline
              className="input-dialog"
              placeholder={translation(`settings.details.enterTheWorkspaceId`)}
              name="externalId"
              defaultValue={workspace?.externalId}
              inputProps={{ required: true }}
            />
            {formErrors['externalId'] && (
              <FormHelperText>{formErrors['externalId']}</FormHelperText>
            )}
          </FormControl>
          <Box mb={3} />
        </form>
      </Grid>
    </Grid>
  );
}

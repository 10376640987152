import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';
import {
  defaultFilter,
  defaultOrder,
  defaultPage,
  defaultPageSize,
} from 'FindingDetails/consts';
import {
  FilterProperties,
  FindingsFilterProperties,
} from 'FindingDetails/interfaces/FindingsWithResources';
import { OrderState } from '../../Common/utils/sort';

const initialState: FindingsFilterProperties = {
  filter: defaultFilter,
  order: defaultOrder,
  page: defaultPage,
  pageSize: defaultPageSize,
  workflows: [],
  workflowId: '',
};

export const findingSlice = createSlice({
  name: 'findings',
  initialState,
  reducers: {
    setFindingsState: (
      state,
      action: PayloadAction<FindingsFilterProperties>
    ) => {
      state.filter = action.payload.filter
        ? action.payload.filter
        : defaultFilter;
      state.order = action.payload.order ? action.payload.order : defaultOrder;
      state.page = action.payload.page ? action.payload.page : defaultPage;
      state.pageSize = action.payload.pageSize
        ? action.payload.pageSize
        : defaultPageSize;
    },
    setFindingsOrder: (state, action: PayloadAction<OrderState>) => {
      state.order = action.payload;
      state.page = defaultPage;
    },
    setFindingsFilter: (state, action: PayloadAction<FilterProperties>) => {
      state.filter = action.payload;
      state.order = defaultOrder;
      state.page = defaultPage;
    },
    setFindingsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setFindingsPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      state.page = defaultPage;
    },
    setFindingSelectedWorkflow: (state, action: PayloadAction<any>) => {
      state.workflowId = action.payload;
    },
  },
});

export const {
  setFindingsState,
  setFindingsFilter,
  setFindingsOrder,
  setFindingsPage,
  setFindingsPageSize,
  setFindingSelectedWorkflow,
} = findingSlice.actions;
export const getFindingsFilterState = (state: RootState) => state.finding;

export const getFindingSelectedWorkflowState = (state: RootState) =>
  state.finding.workflowId;

const findingReducer = findingSlice.reducer;
export default findingReducer;

export interface AutomationRuleGroup {
  id: string;
  findingType: {
    name: string;
    title: string;
    severity: number;
    application: {
      id: string;
      name: string;
      logo: string;
    };
    resource: {
      name: string;
      logo: string;
      type: string;
    };
  };
  rules: Array<AutomationRule>;
  remediationTypeLogos?: Array<string>;
}

export interface AutomationRule {
  id: string;
  findingType: string;
  findingSourceApplicationID: string;
  properties: Array<AutomationRuleProperty>;
  workflowDef: AutomationRuleWorkflowDefinition;
  status: AutomationRuleStatus;
  runMode: AutomationRuleRunMode;
  resourceType?: string;
  draft?: boolean;
}

export interface AutomationRuleUpdateDto {
  id?: string;
  dto: AutomationRule;
}

export interface AutomationRuleProperty {
  type: AutomationRulePropertyType;
  operator: AutomationRulePropertyOperator;
  values: Array<string>;
}

export enum AutomationRulePropertyType {
  BUSINESS_UNIT_IDS = 'business-unit-ids',
  BUSINESS_UNIT_ENVIRONMENT_IDS = 'business-unit-environment-ids',
  TAGS = 'tags',
  BUSINESS_UNIT_ENVIRONMENT_TYPES = 'business-unit-environment-types',
}

export enum AutomationRulePropertyOperator {
  IN = 'in',
}

export enum AutomationRuleRunMode {
  MANUAL = 'manual',
  AUTO = 'auto',
}

export enum AutomationRuleStatus {
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export enum AutomationRuleWorkflowDefType {
  CUSTOMER = 'customer',
  OPUS = 'opus',
}

export interface AutomationRuleWorkflowDefinition {
  id: string;
  familyId: string;
  name: string;
  managementType: string;
}

export interface AutomationRuleValue {
  value: string;
  label: string;
}

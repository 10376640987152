import * as React from 'react';
import DiagramStep, {
  DIAGRAM_STEP_TYPE,
  Operator,
  Route,
} from 'WorkflowBuilder/interfaces/step';
import {
  NodeType,
  stepTypesWithNoPayload,
} from 'WorkflowBuilder/interfaces/node';
import { getStepsToRemove } from '../../utils/step-transform';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { DiagramStepContext } from '../Builder/Builder';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { Button, IconButton } from '@mui/material';
import CrossroadPanelConditionRouteList from './components/CrossroadPanelConditionRouteList';
import {
  CrossroadConditionRoute,
  defaultCondition,
} from './components/CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';
import AlertDialog from '../AlertDialog';

interface CrossroadPanelProps {
  children?: any;
  display: boolean;
  steps: DiagramStep[];
  setSteps: any;
  activeStepId: number | null;
  onClose: () => void;
  onRemoveNode?: (seqId: number) => void;
}

interface TitleProps {
  value?: string;
  editable?: boolean;
  tempValue?: string;
}

export function CrossroadPanel({
  display,
  activeStepId,
  steps,
  setSteps,
  onClose,
  onRemoveNode,
}: CrossroadPanelProps) {
  const { t: translation } = useTranslation();

  const [initialLoadingController, setInitialLoadingController] =
    React.useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = React.useState<boolean>(false);
  const [activeInput, setActiveInput] = React.useState<string | null>(null);
  const [titleState, setTitleState] = React.useState<TitleProps>({
    value: translation(`common.crossroad`),
    tempValue: translation(`common.crossroad`),
    editable: false,
  });
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [conditionRoutes, setConditionRoutes] = React.useState<Array<any>>([]);
  const [additionalDummySteps, setAdditionalDummySteps] = React.useState<
    Array<DiagramStep>
  >([]);

  const [stepsToRemoveState, setStepsToRemoveState] = React.useState<
    Array<Number>
  >([]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setActiveInput(isExpanded ? panel : null);
    };

  const updateConditions = (steps: DiagramStep[]) => {
    if (activeStepId) {
      const activeStep = steps.find((step: DiagramStep) => {
        return step.workflowSeqId === activeStepId;
      });

      if (activeStep) {
        const stepConditionRoutes = activeStep.routes?.filter(
          (route: Route) => route.conditions
        );
        setConditionRoutes(stepConditionRoutes);
      }
    }
  };

  React.useEffect(() => {
    updateConditions(steps);

    const activeStep = steps.find(
      (step: DiagramStep) => step.workflowSeqId === activeStepId
    );

    setTitleState({
      ...titleState,
      value: activeStep?.name,
      tempValue: activeStep?.name,
    });

    return () => {
      setConditionRoutes([]);
      setAdditionalDummySteps([]);
      setStepsToRemoveState([]);
    };
  }, [activeStepId, steps]);

  React.useEffect(() => {
    setInitialLoadingController(true);

    setTimeout(() => {
      setInitialLoadingController(false);
    }, 500);
  }, [activeStepId]);

  const findPreviousPayloadStep = (
    steps: Array<DiagramStep>,
    seqId: number
  ): DiagramStep | undefined => {
    const step = steps.find((step: DiagramStep) =>
      step.routes.find(
        (route) => route.nextWorkflowSeqId && +route.nextWorkflowSeqId === seqId
      )
    );

    if (!step) return undefined;

    if (stepTypesWithNoPayload?.includes(step?.nodeType as NodeType)) {
      return findPreviousPayloadStep(steps, step.workflowSeqId as number);
    }

    return step;
  };

  const findHighestConditionRouteIndexFromCrossroad = (
    steps: Array<DiagramStep>
  ) => {
    let highestConditionRouteIndex: number = 0;
    for (let step of steps) {
      if (step.type === DIAGRAM_STEP_TYPE.CROSSROAD) {
        for (let route of step.routes) {
          const seqId = route.nextWorkflowSeqId ? +route.nextWorkflowSeqId : 0;
          if (highestConditionRouteIndex < seqId) {
            highestConditionRouteIndex = seqId;
          }
        }
      }
    }

    return highestConditionRouteIndex;
  };

  const findHighestConditionRouteIndexFromRoutes = (routes: Array<Route>) => {
    let highestConditionRouteIndex: number = 0;

    for (let route of routes) {
      const seqId = route.nextWorkflowSeqId ? +route.nextWorkflowSeqId : 0;
      if (highestConditionRouteIndex < seqId) {
        highestConditionRouteIndex = seqId;
      }
    }

    return highestConditionRouteIndex;
  };

  const onAddCondition = () => {
    if (activeStepId) {
      const activeStep = steps.find((step: DiagramStep) => {
        return step.workflowSeqId === activeStepId;
      });

      const highestConditionRouteIndexFromCrossroad: number =
        findHighestConditionRouteIndexFromCrossroad(steps);

      const highestConditionRouteIndexFromRoutes: number =
        findHighestConditionRouteIndexFromRoutes(conditionRoutes);

      let highestConditionRouteIndex =
        highestConditionRouteIndexFromCrossroad >
        highestConditionRouteIndexFromRoutes
          ? highestConditionRouteIndexFromCrossroad
          : highestConditionRouteIndexFromRoutes;

      const crossroadSteps = steps.filter((step: DiagramStep) => {
        return activeStep?.routes.find((route: Route) => {
          return (
            route.nextWorkflowSeqId &&
            +route.nextWorkflowSeqId === +step.workflowSeqId
          );
        });
      });

      const routeStepPosition = {
        x: 0,
        y: 0,
      };

      const composedCrossroadSteps = [
        ...crossroadSteps,
        ...additionalDummySteps,
      ];

      if (!composedCrossroadSteps.length) {
        routeStepPosition.x = activeStep?.diagramData.position.x as number;
        routeStepPosition.y =
          (activeStep?.diagramData.position.y as number) + 300;

        highestConditionRouteIndex = activeStep?.workflowSeqId as number;
      } else if (composedCrossroadSteps.length === 2) {
        routeStepPosition.x =
          (activeStep?.diagramData?.position.x as number) + 12;
        routeStepPosition.y =
          (activeStep?.diagramData?.position.y as number) + 200;
      } else if (composedCrossroadSteps.length % 2 === 0) {
        routeStepPosition.x =
          (composedCrossroadSteps[1].diagramData.position.x as number) +
          (250 * composedCrossroadSteps.length) / 2;
        routeStepPosition.y =
          (composedCrossroadSteps[1].diagramData.position.y as number) - 50;
      } else {
        routeStepPosition.x =
          (composedCrossroadSteps[0].diagramData.position.x as number) -
          Math.ceil((250 * composedCrossroadSteps.length) / 2);
        routeStepPosition.y =
          (composedCrossroadSteps[0].diagramData.position.y as number) - 50;
      }

      const newConditionRoute = {
        name: 'Lorem Ipsum',
        conditions: [[defaultCondition]],
        nextWorkflowSeqId:
          highestConditionRouteIndex !== undefined
            ? highestConditionRouteIndex + 1
            : 0,
      };

      const additionalDummyStep: DiagramStep = {
        name: `${NodeType.CROSSROAD}_${activeStepId}_route_${
          (highestConditionRouteIndex as number) + 1
        }`,
        workflowSeqId:
          highestConditionRouteIndex !== undefined
            ? highestConditionRouteIndex + 1
            : 0,
        type: DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY,
        diagramData: {
          position: routeStepPosition,
        },
        nodeType: NodeType.PLUS,
        routes: [],
      };

      setConditionRoutes((oldConditionRoutes) => [
        ...oldConditionRoutes,
        newConditionRoute,
      ]);
      setAdditionalDummySteps((oldAdditionalDummySteps) => [
        ...oldAdditionalDummySteps,
        additionalDummyStep,
      ]);
    }
  };

  const onUpdateConditionConfiguration = (
    condition: any,
    configuration: {
      name?: string;
      expressionA?: string;
      expressionB?: string;
      operator?: Operator;
    } = {
      operator: Operator.EQUAL,
    }
  ) => {
    setConditionRoutes((oldConditionRoutes) => {
      return oldConditionRoutes.map((conditionRoute) => {
        if (condition.nextWorkflowSeqId === conditionRoute.nextWorkflowSeqId) {
          return {
            ...conditionRoute,
            condition: {
              ...conditionRoute.condition,
              ...configuration,
            },
          };
        }
        return conditionRoute;
      });
    });
  };

  const onUpdateDiagramSteps = () => {
    setSteps((oldSteps: Array<DiagramStep>) => {
      return [
        ...oldSteps.map((step: DiagramStep) => {
          if (activeStepId === step.workflowSeqId) {
            return {
              ...step,
              name: titleState.value,
              routes: conditionRoutes,
            };
          }
          return step;
        }),
        ...additionalDummySteps,
      ].filter((step: any) => !stepsToRemoveState.includes(step.workflowSeqId));
    });

    onClose();
  };

  function onRemovePath(workflowSeqId: number) {
    const startingStep = steps.find(
      (step) => +step.workflowSeqId === +workflowSeqId
    );

    const stepsToRemove = startingStep
      ? getStepsToRemove(startingStep, steps, [+startingStep?.workflowSeqId])
      : [];

    setConditionRoutes((oldConditionRoutes) => {
      return oldConditionRoutes.filter((route) => {
        return +route.nextWorkflowSeqId !== +workflowSeqId;
      });
    });

    setAdditionalDummySteps((oldAdditionalDummySteps) => {
      return oldAdditionalDummySteps.filter(
        (step: DiagramStep) => +step.workflowSeqId !== +workflowSeqId
      );
    });

    setStepsToRemoveState([...stepsToRemoveState, ...stepsToRemove]);
  }
  const handleTitleEditing = ({ value, editable, tempValue }: TitleProps) => {
    setTitleState({
      value: value,
      editable: editable,
      tempValue: tempValue,
    });
  };

  React.useEffect(() => {
    if (loading) {
      onClose();
    }
    return () => {
      setLoading(false);
    };
  }, [loading]);

  const closePanel = () => {
    onClose();
    setActiveInput(null);
    setExpanded(false);
  };

  const renderTitleInput = () => {
    if (titleState.editable) {
      return (
        <div className="workflow-builder-panel-header-text-input-container">
          <input
            className="text-field-1 workflow-builder-panel-header-text-input"
            value={titleState.tempValue}
            onChange={(event) => {
              const inputValue = event.target.value;
              setTitleState((prevTitleState) => ({
                ...prevTitleState,
                tempValue: inputValue,
              }));
            }}
          ></input>
          <IconButton
            onClick={() => {
              setTitleState((prevTitleState) => ({
                ...prevTitleState,
                value: prevTitleState.tempValue,
                editable: false,
              }));
            }}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />
          </IconButton>
          <IconButton
            onClick={() => {
              setTitleState((prevTitleState) => ({
                ...prevTitleState,
                editable: false,
                value: prevTitleState.value,
                tempValue: prevTitleState.value,
              }));
            }}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.TIMES_SECONDARY_ICON} />
          </IconButton>
        </div>
      );
    }

    return (
      <h5
        className="workflow-builder-panel-header-text"
        onClick={() => {
          setTitleState((prevTitleState) => ({
            ...prevTitleState,
            editable: true,
          }));
        }}
        title={titleState.value}
      >
        {titleState.value}
      </h5>
    );
  };

  return (
    <DiagramStepContext.Provider value={{ steps }}>
      <PopoverWrapper
        showPopover={display}
        handleClose={closePanel}
        classes="workflow-builder-panel workflow-builder-crossroad-panel"
        hideBackdrop
      >
        <div className="workflow-builder-panel-content">
          <div className="workflow-builder-panel-header">
            <div className="workflow-builder-panel-header-icon">
              <OpusSvgIcon type={SVG_ICON_TYPES.SITEMAP_ICON} />
            </div>
            {renderTitleInput()}

            <div className="workflow-builder-panel-header-id">
              {`${translation(`common.id`)} ${activeStepId}`}
            </div>
          </div>

          <div className="workflow-builder-panel-body">
            <CrossroadPanelConditionRouteList
              routes={conditionRoutes}
              setRoutes={setConditionRoutes}
              onAddRoute={onAddCondition}
              onRemovePath={onRemovePath}
            />
          </div>

          <div className="workflow-builder-panel-footer workflow-builder-crossroad-panel-footer">
            <div className="workflow-builder-crossroad-panel-footer-start-buttons">
              <Button
                className="opus-secondary-button workflow-builder-panel-close-button"
                onClick={() => {
                  setOpenAlertDialog(true);
                }}
              >
                <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON} />
                <FormattedMessage
                  capitalize
                  id="flows.details.deleteCrossroad"
                  defaultMessage="Delete Crossroad"
                />
              </Button>
            </div>
            <div className="workflow-builder-crossroad-panel-footer-end-buttons">
              <Button
                className="opus-secondary-button workflow-builder-panel-close-button"
                onClick={closePanel}
              >
                <FormattedMessage
                  capitalize
                  id="common.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                className="opus-primary-button workflow-builder-panel-save-button"
                onClick={() => {
                  onUpdateDiagramSteps();
                }}
              >
                <FormattedMessage
                  capitalize
                  id="common.save"
                  defaultMessage="Save"
                />
              </Button>
            </div>
          </div>
        </div>
        <AlertDialog
          open={openAlertDialog}
          text={
            'Removing the crossroad will remove all the routes and nodes connected to it. Are you sure you want to proceed?'
          }
          onHandleConfirm={() => {
            onRemoveNode && activeStepId && onRemoveNode(activeStepId);
            setOpenAlertDialog(false);
          }}
          onHandleClose={() => {
            setOpenAlertDialog(false);
          }}
        />
      </PopoverWrapper>
    </DiagramStepContext.Provider>
  );
}

import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import TokenInputPanel from '../TokenInputPanel';
import { TokenItem } from '../TokenInputPanel/TokenInputPanel';
import {
  createEditor,
  Transforms,
  Descendant,
  Editor,
  Element as SlateElement,
  NodeEntry,
} from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { NodeInsertNodesOptions } from 'slate/dist/interfaces/transforms/node';

enum TokenType {
  SYSTEM_TOKEN = 'SYSTEM_TOKEN',
  HELPER_TOKEN = 'HELPER_TOKEN',
  FUNCTION_TOKEN = 'FUNCTION_TOKEN',
  EMPTY_TOKEN = 'EMPTY_TOKEN',
}

interface TokenInputBlock {
  type: TokenType;
  children: any;
}

interface TokenInputProps extends BaseComponentProps {
  content: string;
  onChange: (value: string) => void;
}

const withMyCustomNormalization = (editor: ReactEditor) => {
  editor.normalizeNode = ([node, path]: any) => {
    if (
      SlateElement.isElement(node) &&
      (node as any).type !== TokenType.EMPTY_TOKEN &&
      Editor.isEmpty(editor, node)
    ) {
      Transforms.removeNodes(editor, { at: path });
      return;
    }
  };

  return editor;
};

export const TokenInput: FunctionComponent<TokenInputProps> = ({
  content,
  onChange,
}) => {
  const editor = useMemo<ReactEditor>(
    () => withMyCustomNormalization(withHistory(withReact(createEditor()))),
    []
  );

  const contentEditableContainerRef = useRef<HTMLDivElement | null>(null);

  const [tokenModalOpen, setTokenModalOpen] = useState<boolean>(false);

  const getInitialNodes = useCallback(() => {
    return parseStringToSlateNodes(content);
  }, [content]);

  useEffect(() => {
    const initialNodes = getInitialNodes();

    if (editor.children.length)
      Transforms.removeNodes(editor, {
        at: {
          anchor: Editor.start(editor, []),
          focus: Editor.end(editor, []),
        },
      });
    Transforms.insertNodes(editor, initialNodes);
  }, [editor]);

  const getRawTextValue = useCallback((nodes: Array<Descendant>) => {
    const nodesText = nodes.map((node: any) => {
      const nodeText = node.children[0]?.text;
      const nodeType = node.type;

      if (nodeType === TokenType.SYSTEM_TOKEN) {
        return `<<${nodeText}>>`;
      }

      if (nodeType === TokenType.FUNCTION_TOKEN) {
        return `| ${nodeText}`;
      }

      return nodeText;
    });

    return nodesText.join('');
  }, []);

  const parseStringToSlateNodes = (input: string): Array<any> => {
    if (!input) {
      return [{ type: TokenType.EMPTY_TOKEN, children: [{ text: '' }] }];
    }

    const tokens: Array<any> = [];

    let currentIndex = 0;

    const findNextSpecialIndex = (fromIndex: number): number => {
      const specialChars = ['{{', '}}', '<<'];
      const indexes = specialChars
        .map((char) => input.indexOf(char, fromIndex))
        .filter((index) => index !== -1);
      if (indexes.length === 0) return -1;
      return Math.min(...indexes);
    };

    while (currentIndex < input.length) {
      if (input.startsWith('{{', currentIndex)) {
        tokens.push({ type: TokenType.EMPTY_TOKEN, children: [{ text: '' }] });

        tokens.push({
          type: TokenType.HELPER_TOKEN,
          children: [{ text: '{{' }],
        });

        tokens.push({ type: TokenType.EMPTY_TOKEN, children: [{ text: '' }] });

        currentIndex += 2;
      } else if (input.startsWith('}}', currentIndex)) {
        tokens.push({ type: TokenType.EMPTY_TOKEN, children: [{ text: '' }] });

        tokens.push({
          type: TokenType.HELPER_TOKEN,
          children: [{ text: '}}' }],
        });
        tokens.push({ type: TokenType.EMPTY_TOKEN, children: [{ text: '' }] });

        currentIndex += 2;
      } else if (input.startsWith('<<', currentIndex)) {
        const endIndex = input.indexOf('>>', currentIndex);
        if (endIndex !== -1) {
          const parameterText = input.slice(currentIndex + 2, endIndex);
          tokens.push({
            type: TokenType.EMPTY_TOKEN,
            children: [{ text: '' }],
          });

          tokens.push({
            type: TokenType.SYSTEM_TOKEN,
            children: [{ text: parameterText }],
          });
          tokens.push({
            type: TokenType.EMPTY_TOKEN,
            children: [{ text: '' }],
          });

          currentIndex = endIndex + 2;
        } else {
          console.error('No matching >> found for <<');
          break;
        }
      } else {
        const nextIndex = findNextSpecialIndex(currentIndex);
        const text =
          nextIndex !== -1
            ? input.slice(currentIndex, nextIndex)
            : input.slice(currentIndex);
        tokens.push({ type: TokenType.EMPTY_TOKEN, children: [{ text }] });
        currentIndex = nextIndex !== -1 ? nextIndex : input.length;
      }
    }

    if (tokens.length === 0) {
      tokens.push({ type: TokenType.EMPTY_TOKEN, children: [{ text: '' }] });
    }

    return tokens;
  };

  const selectParameter = (parameter: TokenItem) => {
    const selectedNode: any = getSelectedNode(editor);

    if (selectedNode?.type !== TokenType.EMPTY_TOKEN)
      insertToken(editor, TokenType.EMPTY_TOKEN, '');

    insertToken(editor, TokenType.HELPER_TOKEN, '{{');
    insertToken(editor, TokenType.EMPTY_TOKEN, '');

    insertToken(editor, TokenType.SYSTEM_TOKEN, parameter.value);
    insertToken(editor, TokenType.EMPTY_TOKEN, '');

    insertToken(editor, TokenType.HELPER_TOKEN, '}}');
    insertToken(editor, TokenType.EMPTY_TOKEN, '');
  };

  const selectStep = (id: string) => {
    const selectedNode: any = getSelectedNode(editor);

    if (selectedNode?.type !== TokenType.EMPTY_TOKEN)
      insertToken(editor, TokenType.EMPTY_TOKEN, '');

    insertToken(editor, TokenType.HELPER_TOKEN, '{{');
    insertToken(editor, TokenType.EMPTY_TOKEN, '');

    insertToken(editor, TokenType.SYSTEM_TOKEN, id);
    insertToken(editor, TokenType.EMPTY_TOKEN, '');

    insertToken(editor, TokenType.HELPER_TOKEN, '}}');
    insertToken(editor, TokenType.EMPTY_TOKEN, '');
  };

  const selectFindingContent = (id: string) => {
    selectStep(id);
  };

  const getAllEditorNodes = (): Array<TokenInputBlock> => {
    const nodes = Array.from(Editor.nodes(editor));

    return nodes.map((node: NodeEntry<any>) => node[0].children).flat();
  };

  const selectFunction = (fn: TokenItem) => {
    const nodes = getAllEditorNodes();

    const nodeTypes = nodes.map((node: TokenInputBlock) => node?.type);

    const lastHelperTokenIndex = nodeTypes.lastIndexOf(TokenType.HELPER_TOKEN);

    const lastFunctionTokenIndex = nodeTypes.lastIndexOf(
      TokenType.FUNCTION_TOKEN
    );

    if (
      lastHelperTokenIndex > 0 &&
      lastHelperTokenIndex > lastFunctionTokenIndex
    ) {
      insertToken(editor, TokenType.FUNCTION_TOKEN, fn.value, {
        at: [lastHelperTokenIndex - 1],
      });

      insertToken(editor, TokenType.EMPTY_TOKEN, '', {
        at: [lastHelperTokenIndex - 1],
      });
    } else {
      const selectedNode: any = getSelectedNode(editor);

      if (selectedNode?.type !== TokenType.EMPTY_TOKEN)
        insertToken(editor, TokenType.EMPTY_TOKEN, '');

      insertToken(editor, TokenType.FUNCTION_TOKEN, fn.value);
      insertToken(editor, TokenType.EMPTY_TOKEN, '');
    }
  };

  const handleTextChange = (text: string) => {
    onChange && onChange(text);
  };

  const handleTokenModalOpen = () => {
    setTokenModalOpen(true);
  };

  const handleTokenModalClose = () => {
    setTokenModalOpen(false);
  };

  const renderElement = useCallback((props: any) => {
    switch (props.element.type) {
      case TokenType.SYSTEM_TOKEN:
        return (
          <span
            contentEditable
            {...props.attributes}
            className="token-parameter"
          >
            {props.children}
          </span>
        );
      case TokenType.FUNCTION_TOKEN:
        return (
          <span
            {...props.attributes}
            contentEditable
            className="token-function"
          >
            {props.children}
          </span>
        );
      case TokenType.HELPER_TOKEN:
        return (
          <span {...props.attributes} contentEditable className="token-helper">
            {props.children}
          </span>
        );
      case TokenType.EMPTY_TOKEN:
        return (
          <p {...props.attributes} contentEditable className="token-empty">
            {props.children}
          </p>
        );
      default:
        return <p {...props.attributes}>{props.children}</p>;
    }
  }, []);

  const insertToken = (
    editor: any,
    type: string,
    token: string,
    options?: NodeInsertNodesOptions<any>
  ) => {
    const systemToken = {
      type: type,
      children: [{ text: token }],
    };

    Transforms.insertNodes(editor, systemToken, options);
    Transforms.move(editor);
  };

  const getSelectedNode = (editor: ReactEditor) => {
    const { selection } = editor;

    if (!selection) {
      return null;
    }

    const [node] = Editor.node(editor, selection);

    if (SlateElement.isElement(node)) {
      return node;
    }

    const [parent] = Editor.parent(editor, selection.anchor.path);
    if (SlateElement.isElement(parent)) {
      return parent;
    }

    return node;
  };

  const updateNodeText = (
    editor: ReactEditor,
    path: Array<number>,
    newText: string
  ) => {
    const range = Editor.range(editor, path);

    Transforms.delete(editor, { at: range });

    Transforms.insertText(editor, newText, { at: path });
  };

  const valueChangeHandler = (nodes: Array<Descendant>) => {
    handleTextChange(getRawTextValue(nodes));
    // if (nodes.length === 0) {
    //   insertToken(editor, TokenType.EMPTY_TOKEN, '');
    // } else {
    //   const selection = editor.selection;

    //   const selectedNode: any = getSelectedNode(editor);

    //   const selectedNodeText: string = selectedNode?.children[0]?.text;

    //   if (selectedNode?.type === TokenType.EMPTY_TOKEN) {
    //     if (selectedNodeText?.includes('{{')) {
    //       const updatedText = selectedNodeText.replace('{{', '');

    //       updateNodeText(
    //         editor,
    //         selection?.anchor.path as Array<number>,
    //         updatedText
    //       );

    //       insertToken(editor, TokenType.HELPER_TOKEN, '{{');
    //       insertToken(editor, TokenType.EMPTY_TOKEN, '');
    //       insertToken(editor, TokenType.HELPER_TOKEN, '}}');
    //       insertToken(editor, TokenType.EMPTY_TOKEN, '');
    //     } else if (selectedNodeText?.includes('<<')) {
    //       const updatedText = selectedNodeText.replace('<<', '');

    //       updateNodeText(
    //         editor,
    //         selection?.anchor.path as Array<number>,
    //         updatedText
    //       );

    //       insertToken(editor, TokenType.EMPTY_TOKEN, '');
    //       insertToken(editor, TokenType.SYSTEM_TOKEN, 'parameter');
    //       insertToken(editor, TokenType.EMPTY_TOKEN, '');
    //     } else if (selectedNodeText?.includes('|')) {
    //       const updatedText = selectedNodeText.replace('|', '');

    //       updateNodeText(
    //         editor,
    //         selection?.anchor.path as Array<number>,
    //         updatedText
    //       );

    //       insertToken(editor, TokenType.EMPTY_TOKEN, '');
    //       insertToken(editor, TokenType.FUNCTION_TOKEN, 'function');
    //       insertToken(editor, TokenType.EMPTY_TOKEN, '');
    //     }
    //   }
    // }
  };

  return (
    <>
      <div className="token-input-container" ref={contentEditableContainerRef}>
        <Slate
          editor={editor}
          initialValue={[]}
          onValueChange={valueChangeHandler}
        >
          <Editable renderElement={renderElement} className="token-input" />
        </Slate>
        <div
          className="token-input-end-andornment"
          onClick={() => {
            handleTokenModalOpen();
          }}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.CURLY_BRACKETS_ICON} />
        </div>
      </div>

      <TokenInputPanel
        open={tokenModalOpen}
        handleClose={handleTokenModalClose}
        anchorEl={contentEditableContainerRef.current}
        selectionHandlers={{
          selectFunctionTokenHandler: selectFunction,
          selectSystemTokenHandler: selectParameter,
          selectStepTokenHandler: selectStep,
          selectFindingContentTokenHandler: selectFindingContent,
        }}
      />
    </>
  );
};

import { FunctionComponent } from 'react';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { BaseIntegrationModal } from '../BaseIntegrationModal/BaseIntegrationModal';

interface JiraIntegrationModalProps extends CommonIntegrationModalProps {}

export const JiraIntegrationModal: FunctionComponent<
  JiraIntegrationModalProps
> = (props) => {
  return <BaseIntegrationModal {...props} />;
};

import { FunctionComponent } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface RedirectProps extends BaseComponentProps {
  getUrl: (params: any) => string;
}

export const Redirect: FunctionComponent<RedirectProps> = ({ getUrl }) => {
  const params = useParams();

  return <Navigate to={getUrl(params)} />;
};

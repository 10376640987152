import { FunctionComponent, useMemo } from 'react';
import { DoughnutChartProps } from 'shared/models/props/chart-props.model';
import { ChartData } from 'shared/models/data/chart.model';
import BaseChart from '../BaseChart';
import { v4 as uuid4 } from 'uuid';
import { ChartOptions } from 'chart.js';

export const DoughnutChartWidget: FunctionComponent<
  DoughnutChartProps<ChartData>
> = ({
  fetchWidgetData,
  isWidgetDataLoading,
  widgetData,
  displayLegend = false,
  displayLabels = false,
  enableTooltip = false,
  aspectRatio = 3,
  isResponsive = true,
  cutoutPercentage,
  legendPosition = 'top',
  style = {},
  applyColorList = false,
  customLegendContainerId = null,
  displayPercentage = false,
  options,
  onElementClick,
}) => {
  const chartId = useMemo(() => {
    return uuid4();
  }, []);

  const chartOptions = useMemo<ChartOptions<'doughnut'>>(() => {
    return {
      ...(options || {}),
      aspectRatio,
      responsive: isResponsive,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: enableTooltip,
        },
        datalabels: {
          display: displayLabels,
          formatter: (value: string) => {
            if (displayPercentage) {
              return value + '%';
            }

            return value;
          },
          color: 'white',
          font: {
            weight: 700,
            size: 12,
          },
          ...(options?.plugins?.datalabels || {}),
        },
        htmlLegend: {
          containerID:
            customLegendContainerId || `chart-legend-container-${chartId}`,
        },
      },
      cutout: cutoutPercentage,
    };
  }, [options]);

  return (
    <BaseChart
      options={chartOptions as ChartOptions}
      type="doughnut"
      {...{
        fetchWidgetData,
        isWidgetDataLoading,
        widgetData,
        style,
        applyColorList,
        displayLegend,
        legendPosition,
        chartId,
      }}
      onElementClick={onElementClick}
    />
  );
};

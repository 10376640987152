import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { DashboardRiskAssessmentTypes } from './DashboardRiskAssessmentTypes.enum';
import { RiskAssessmentSDLCWidgetItem } from './RiskAssessmentSDLCWidgetItem.model';
import { RiskAssessmentSurfaceCategory } from './RiskAssessmentSurfaceCategory.enum';

export const RiskAssessmentSDLCWidgetList: {
  [key in RiskAssessmentSurfaceCategory]: RiskAssessmentSDLCWidgetItem[];
} = {
  [RiskAssessmentSurfaceCategory.INFRASTRUCTURE]: [
    {
      title: DashboardRiskAssessmentTypes.CLOUD_MISCONFIGURATION,
      icon: SVG_ICON_TYPES.NETWORK_WIRED_ICON,
      type: PostureWidgetAnalyticsType.ACCUMULATIVE_RISK_SURFACE_CLOUD_MISCONFIGURATION,
      group: RiskAssessmentSurfaceCategory.INFRASTRUCTURE,
      findingTypes: ['Cloud Misconfiguration'],
    },
    {
      title: DashboardRiskAssessmentTypes.CLOUD_VULNERABILITIES,
      icon: SVG_ICON_TYPES.SENSOR_CLOUD_ICON,
      type: PostureWidgetAnalyticsType.ACCUMULATIVE_RISK_SURFACE_VULNERABILITY,
      group: RiskAssessmentSurfaceCategory.INFRASTRUCTURE,
      findingTypes: ['Cloud Vulnerability'],
    },
    {
      title: DashboardRiskAssessmentTypes.ON_PREM,
      icon: SVG_ICON_TYPES.SERVER_ICON,
      type: PostureWidgetAnalyticsType.ACCUMULATIVE_RISK_SURFACE_ONPREM_VULNERABILITIES,
      group: RiskAssessmentSurfaceCategory.INFRASTRUCTURE,
      findingTypes: ['On-Premises Vulnerability'],
    },
  ],
  [RiskAssessmentSurfaceCategory.APPLICATION]: [
    {
      title: DashboardRiskAssessmentTypes.CODE_VULNERABILITIES,
      icon: SVG_ICON_TYPES.CODE_ICON,
      type: PostureWidgetAnalyticsType.ACCUMULATIVE_RISK_SURFACE_CODE_VULNERABILITIES,
      group: RiskAssessmentSurfaceCategory.APPLICATION,
      findingTypes: ['SAST', 'SCA', 'Secrets'],
    },
    {
      title: DashboardRiskAssessmentTypes.SOFTWARE_VULNERABILITIES,
      icon: SVG_ICON_TYPES.BOX_ICON,
      type: PostureWidgetAnalyticsType.ACCUMULATIVE_RISK_SURFACE_APP_VULNERABILITY,
      group: RiskAssessmentSurfaceCategory.APPLICATION,
      findingTypes: ['Container Vulnerability'],
    },
  ],
};

import { FunctionComponent, useState } from 'react';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { VisibilityControlContent } from 'shared/components/VisibilityControlContent/VisibilityControlContent';
import { CommonButton } from 'shared/components/CommonButton';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseIntegrationModal } from '../BaseIntegrationModal/BaseIntegrationModal';

interface SecurityHubIntegrationModalProps
  extends CommonIntegrationModalProps {}

export const SecurityHubIntegrationModal: FunctionComponent<
  SecurityHubIntegrationModalProps
> = (props) => {
  return <BaseIntegrationModal {...props} />;
};

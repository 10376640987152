import { FunctionComponent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CampaignModel } from 'shared/models/data/campaigns.model';
import BusinessImpactChip from 'shared/components/BusinessImpactChip';

interface ActiveCampaignCarouselCardProps
  extends BaseComponentProps,
    CampaignModel {}

export const ActiveCampaignCarouselCard: FunctionComponent<
  ActiveCampaignCarouselCardProps
> = ({ id, activeFindings, completionRate, name, remainingDays, priority }) => {
  const navigate = useNavigate();

  const onNavigateToCampaignDetails = () => {
    navigate(`/campaigns/${id}`);
  };

  const completionRatePercentage = useMemo<number>(() => {
    return Math.round(completionRate * 100);
  }, [completionRate]);

  return (
    <div
      className="active-campaign-carousel-card-container clickable-item"
      onClick={onNavigateToCampaignDetails}
    >
      <Grid container gap={'4px'}>
        <Grid
          item
          xs={12}
          className="active-campaign-carousel-card-title"
          title={name}
        >
          <h5>{name}</h5>
        </Grid>
        <Grid item className="active-campaign-carousel-card-business-priority">
          <BusinessImpactChip impact={priority} />
        </Grid>
      </Grid>
      <div className="active-campaign-carousel-card-completion-rate-container">
        <div className="active-campaign-carousel-card-completion-rate-text-container">
          <b className="active-campaign-carousel-card-completion-rate">
            {completionRatePercentage}%
          </b>
          <p className="active-campaign-carousel-card-completion-text">
            Completion Rate
          </p>
        </div>
        <div
          className={`active-campaign-carousel-card-completion-rate-indicator-container`}
        >
          <div
            style={{
              width: `${completionRatePercentage}%`,
            }}
            className={`active-campaign-carousel-card-completion-rate-inner-indicator-container ${
              completionRatePercentage < 50
                ? 'red'
                : completionRatePercentage < 80
                ? 'orange'
                : 'green'
            }`}
          ></div>
        </div>
      </div>
      <div className="active-campaign-carousel-card-days-remaining-container">
        <OpusSvgIcon type={SVG_ICON_TYPES.SIMPLE_CALENDAR_ICON} />
        <div className="active-campaign-carousel-card-days-counter">
          {remainingDays}
        </div>
        <div className="active-campaign-carousel-card-days-text body-2">
          Days Remaining
        </div>
      </div>
    </div>
  );
};

import {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  ReactNode,
  useRef,
} from 'react';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {
  ListItem,
  ListItem as OpusListItem,
  ListProps,
} from '../../models/props/list-props.model';
import { CircularProgress, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import { useTranslation } from 'react-i18next';

interface OpusNestedListItemTextProps extends ListItem {
  parentId: string;
  checked: boolean;
  handleChecked: (id: string, parentId: string) => void;
  components?: {
    CheckedIcon?: ReactNode;
    UnCheckedIcon?: ReactNode;
  };
  searchKeyword?: string;
}
interface OpusNestedListProps extends BaseComponentProps {
  parentId: string;
  handleChecked: (id: string, parentId: string) => void;
  checkedOptions: any;
  items?: Array<ListItem>;
  getItemsMutation?: any;
  itemsRequestPayload?: any;
  components?: {
    CheckedIcon?: ReactNode;
    UnCheckedIcon?: ReactNode;
  };
  searchKeyword?: string;
}

export const OpusNestedListItemText: FunctionComponent<
  OpusNestedListItemTextProps
> = ({
  hasCheckbox,
  id,
  checked,
  icon,
  handleChecked,
  components,
  label,
  subLabel,
  parentId,
}) => {
  const itemRef = useRef<HTMLDivElement | null>(null);

  const isOnScreen = useOnScreen(itemRef);

  return (
    <div ref={itemRef}>
      <ListItemButton
        onClick={(e) => {
          hasCheckbox && handleChecked(id, parentId);
        }}
        key={id}
      >
        {isOnScreen ? (
          <>
            {hasCheckbox && (
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': label }}
                  checkedIcon={components?.CheckedIcon}
                  icon={components?.UnCheckedIcon}
                />
              </ListItemIcon>
            )}
            {icon && (
              <ListItemIcon>
                <img src={icon as string} alt={label as string} />
              </ListItemIcon>
            )}
            <ListItemText
              className="nested-list-text-container"
              classes={{
                primary: 'nested-list-text-primary',
                secondary: 'nested-list-text-secondary',
              }}
              primary={label}
              secondary={subLabel}
              title={label}
            />
          </>
        ) : (
          <></>
        )}
      </ListItemButton>
    </div>
  );
};

export const OpusNestedList: FunctionComponent<OpusNestedListProps> = ({
  parentId,
  items,
  getItemsMutation,
  itemsRequestPayload,
  components,
  checkedOptions,
  handleChecked,
  searchKeyword = '',
}) => {
  const { t: translation } = useTranslation();

  const [getItems, { data: itemsPayload, isLoading: itemsLoading }] =
    getItemsMutation();

  useEffect(() => {
    if (!items) {
      getItems(itemsRequestPayload);
    }
  }, [items]);

  const apiItemOptions = useMemo(() => {
    return itemsPayload?.options[0]
      ? itemsPayload?.options[0]?.children
          ?.map((option: any) => ({
            ...option,
            icon: option.logo,
            display: true,
            hasCheckbox: true,
          }))
          ?.filter((option: ListItem) =>
            option.label.toLowerCase().includes(searchKeyword.toLowerCase())
          )
      : [];
  }, [itemsPayload, searchKeyword]);

  const itemOptions = useMemo<Array<ListItem>>(() => {
    return items || apiItemOptions;
  }, [items, itemsPayload, apiItemOptions]);

  const renderNestedListBody = () => {
    if (itemsLoading)
      return (
        <div className="nested-list-loading-container">
          <CircularProgress size={20} />
        </div>
      );

    if (itemOptions.length === 0)
      return (
        <div className="nested-list-no-data-container">
          {translation(`common.noData`)}
        </div>
      );

    return itemOptions.map((childItem: OpusListItem) => {
      return (
        childItem.display && (
          <OpusNestedListItemText
            {...childItem}
            checked={Boolean(checkedOptions[parentId]?.includes(childItem.id))}
            handleChecked={handleChecked}
            components={components}
            parentId={parentId}
            searchKeyword={searchKeyword}
          />
        )
      );
    });
  };

  return (
    <List component="div" disablePadding className="nested-list-container">
      {renderNestedListBody()}
    </List>
  );
};

export const OpusList: FunctionComponent<ListProps> = ({
  items,
  classes,
  sx,
  subheader,
  disablePadding,
  dense,
  components,
  listItemClasses,
  preCheckedOptions = {},
  preOpenPanels = {},
  saveCheckedValues,
  displayChildCounter = false,
  searchKeyword = '',
}) => {
  const [openPanels, setOpenPanels] = useState<any>(preOpenPanels);
  const [checkedOptions, setCheckedOptions] = useState<any>(preCheckedOptions);

  const handleClick = (id: string) => {
    setOpenPanels({ ...openPanels, [id]: !Boolean(openPanels[id]) });
  };

  const handleChecked = (id: string, parentId: string) => {
    const updatedCheckedOptions = {
      ...checkedOptions,
    };

    if (
      updatedCheckedOptions[parentId] &&
      updatedCheckedOptions[parentId].includes(id)
    ) {
      updatedCheckedOptions[parentId] = updatedCheckedOptions[parentId].filter(
        (itemId: string) => itemId !== id
      );
    } else if (
      updatedCheckedOptions[parentId] &&
      !updatedCheckedOptions[parentId].includes(id)
    ) {
      updatedCheckedOptions[parentId] = [
        ...updatedCheckedOptions[parentId],
        id,
      ];
    } else if (!updatedCheckedOptions[parentId]) {
      updatedCheckedOptions[parentId] = [id];
    }

    setCheckedOptions(updatedCheckedOptions);

    saveCheckedValues &&
      saveCheckedValues(
        Object.values(updatedCheckedOptions).flat() as Array<string>,
        updatedCheckedOptions,
        openPanels
      );
  };

  useEffect(() => {
    setOpenPanels(preOpenPanels);
    setCheckedOptions(preCheckedOptions);

    return () => {
      setOpenPanels({});
      setCheckedOptions({});
    };
  }, [preOpenPanels, preCheckedOptions]);

  return (
    <List
      sx={sx}
      classes={classes}
      subheader={subheader}
      disablePadding={disablePadding}
      dense={dense}
    >
      {items?.map((item: OpusListItem) => {
        return (
          item.display && (
            <div key={item.id}>
              <ListItemButton
                onClick={(e) => {
                  item.onClick && item.onClick(e);
                  handleClick(item.id);
                }}
                classes={listItemClasses}
              >
                {item.hasCheckbox && (
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkedOptions[item.id]}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': item.label }}
                      checkedIcon={components?.CheckedIcon}
                      icon={components?.UnCheckedIcon}
                    />
                  </ListItemIcon>
                )}
                {item.icon && (
                  <ListItemIcon>
                    {typeof item.icon === 'string' ? (
                      <img height={20} src={item.icon} alt={item.label} />
                    ) : (
                      item.icon
                    )}
                  </ListItemIcon>
                )}

                <ListItemText primary={item.label} />
                {displayChildCounter && item.children && (
                  <div className={listItemClasses?.counter || ''}>
                    {
                      item.children.filter(
                        (child: ListItem) => checkedOptions[child.id]
                      ).length
                    }
                  </div>
                )}

                <div className={listItemClasses?.counter}>
                  {item.selectedCounter || 0}
                </div>

                {openPanels[item.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              {(item.children || item.getChildrenMutation) && (
                <Collapse
                  in={openPanels[item.id]}
                  component="li"
                  timeout="auto"
                  unmountOnExit
                >
                  <OpusNestedList
                    parentId={item.id}
                    handleChecked={handleChecked}
                    checkedOptions={checkedOptions}
                    items={item.children}
                    components={components}
                    getItemsMutation={item.getChildrenMutation}
                    itemsRequestPayload={item.childrenRequestPayload}
                    searchKeyword={searchKeyword}
                  />
                </Collapse>
              )}
            </div>
          )
        );
      })}
    </List>
  );
};

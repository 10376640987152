import { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import {
  ConfigurationFieldValue,
  MultiSelectConfigurationFieldProps,
  configurationFieldDefaultValues,
} from '../MultiSelectConfigurationField/MultiSelectConfigurationField';
import { AutocompleteOption } from 'FindingDetails/store/api';
import InputLabelWrapper from '../InputLabelWrapper';
import { Button } from '@mui/material';
import Autocomplete from 'Common/components/Autocomplete';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

export const MultiInputConfigurationField: FunctionComponent<
  MultiSelectConfigurationFieldProps
> = ({
  fieldLabels,
  fieldOptions,
  values,
  onChange,
  classes = {},
  getIconMethods,
  addLabel = 'Add New',
  defaultDisabledOptions,
  isInputDisabled = false,
}) => {
  const [configurationValues, setConfigurationValues] = useState<
    Array<ConfigurationFieldValue>
  >(values?.length ? values : configurationFieldDefaultValues);

  useEffect(() => {
    if (values?.length) {
      const updatedConfigurationValues = configurationValues.map(
        (configValue) => {
          const updatedValues = configValue.values.map((value) => ({
            ...value,
            disabled: isInputDisabled,
          }));

          return {
            ...configValue,
            values: updatedValues,
          };
        }
      );
      setConfigurationValues(updatedConfigurationValues);
    }
  }, [values, isInputDisabled]);

  const onAddConfiguration = () => {
    setConfigurationValues((oldConfigurationValues) => {
      const newConfiguration = {
        ...configurationFieldDefaultValues[0],
        values: configurationFieldDefaultValues[0].values.map((value) => ({
          ...value,
          disabled: false,
        })),
      };

      return [...oldConfigurationValues, newConfiguration];
    });
  };

  const onDeleteConfiguration = (index: number) => {
    setConfigurationValues((oldConfigurationValues) => {
      if (oldConfigurationValues.length === 1) {
        return configurationFieldDefaultValues;
      }

      return oldConfigurationValues.filter(
        (configurationValue, idx: number) => idx !== index
      );
    });
  };

  const onMultiSelectChangeCallback = (
    rowIndex: number,
    itemIndex: number,
    option: AutocompleteOption
  ) => {
    if (option) {
      setConfigurationValues((oldConfigurationValues) => {
        const updatedConfigurationValues = [...oldConfigurationValues];

        return updatedConfigurationValues.map(
          (configurationValue, index: number) => {
            if (index === rowIndex) {
              const configurationValues = configurationValue.values.map(
                (valueItem, index: number) => {
                  if (index === itemIndex) {
                    return {
                      ...option,
                      id: valueItem.id,
                      disabled: valueItem.disabled,
                    };
                  }

                  return valueItem;
                }
              );

              return {
                values: configurationValues,
                disableDelete: configurationValue.disableDelete,
              };
            }

            return configurationValue;
          }
        ) as Array<ConfigurationFieldValue>;
      });
    }
  };

  useEffect(() => {
    const updatedConfigurationValues = configurationValues.map(
      (configValue) => {
        const updatedValues = configValue.values.map((value) => ({
          ...value,
          disabled: isInputDisabled,
        }));
        return { ...configValue, values: updatedValues };
      }
    );

    setConfigurationValues(updatedConfigurationValues);
  }, []);

  useEffect(() => {
    if (onChange) {
      const filteredConfigurationValues = configurationValues.filter(
        (configValue) => configValue.values[0]?.value?.length
      );
      onChange(filteredConfigurationValues);
    }
  }, [configurationValues]);

  const getIcon = (field: string, option: AutocompleteOption) => {
    if (getIconMethods && getIconMethods[field]) {
      return getIconMethods[field](option.iconUrl as string);
    }

    return <></>;
  };

  const getFilteredOptions = (index: number) => {
    const selectedValues = configurationValues
      .filter((_, idx) => idx !== index)
      .map((configValue) => configValue.values[0]?.value);
    return fieldOptions[0].filter(
      (option) => !selectedValues.includes(option.value)
    );
  };

  return (
    <div className="multi-select-configuration">
      <div className="multi-select-configuration-row">
        <InputLabelWrapper label={fieldLabels[0]}>
          <Autocomplete
            model={fieldLabels[0]}
            optionList={getFilteredOptions(0)}
            disabled={configurationValues[0]?.values[0]?.disabled}
            onChangeCallBack={(
              model: string,
              option: AutocompleteOption | Array<AutocompleteOption>
            ) => {
              const singleOption = option as AutocompleteOption;
              onMultiSelectChangeCallback(0, 0, singleOption);
            }}
            classes={{
              root: 'multi-select-field-1',
              paper: 'multi-select-field-paper-1',
              ...classes,
            }}
            single
            values={
              configurationValues[0]
                ? configurationValues[0].values[0]
                : undefined
            }
            initialSelectedValues={
              configurationValues[0]
                ? configurationValues[0].values[0]
                : undefined
            }
            getIcon={(option: AutocompleteOption) =>
              getIcon(fieldLabels[0], option)
            }
            enableCheckbox
            readonly={configurationValues[0]?.values[0]?.disabled}
          />
        </InputLabelWrapper>

        <InputLabelWrapper label={fieldLabels[1]}>
          <input
            className={`text-field-1 ${classes.inputRoot || ''}`}
            type="number"
            value={
              configurationValues[0]
                ? configurationValues[0].values[1].value
                : undefined
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const updatedValue = event.target.value;
              onMultiSelectChangeCallback(0, 1, {
                value: updatedValue,
              });
            }}
          />
        </InputLabelWrapper>

        <Button
          onClick={() => {
            onDeleteConfiguration(0);
          }}
          className="base-opus-text-button delete-button"
          disabled={
            (configurationValues[0].values[0]?.value.length === 0 &&
              configurationValues[0].values[1]?.value.length === 0) ||
            configurationValues[0].disableDelete
          }
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
        </Button>
      </div>

      {configurationValues.slice(1).map((configurationValue, index: number) => (
        <div className="multi-select-configuration-row" key={index + 1}>
          <Autocomplete
            model={fieldLabels[0]}
            optionList={getFilteredOptions(index + 1)}
            onChangeCallBack={(
              model: string,
              option: AutocompleteOption | Array<AutocompleteOption>
            ) => {
              const singleOption = option as AutocompleteOption;
              onMultiSelectChangeCallback(index + 1, 0, singleOption);
            }}
            classes={{
              root: 'multi-select-field-1',
              paper: 'multi-select-field-paper-1',
            }}
            single
            values={
              configurationValues[index + 1]
                ? configurationValues[index + 1].values[0]
                : undefined
            }
            initialSelectedValues={
              configurationValues[index + 1]
                ? configurationValues[index + 1].values[0]
                : undefined
            }
            getIcon={(option: AutocompleteOption) =>
              getIcon(fieldLabels[0], option)
            }
            enableCheckbox
            readonly={configurationValues[index + 1]?.values[0]?.disabled}
            disabled={configurationValues[index + 1]?.values[0]?.disabled}
          />

          <input
            className={`text-field-1 ${classes.inputRoot || ''}`}
            type="number"
            value={
              configurationValues[index + 1]
                ? configurationValues[index + 1].values[1].value
                : undefined
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const updatedValue = event.target.value;
              onMultiSelectChangeCallback(index + 1, 1, {
                value: updatedValue,
              });
            }}
          />
          <Button
            onClick={() => {
              onDeleteConfiguration(index + 1);
            }}
            disabled={
              defaultDisabledOptions
                ? defaultDisabledOptions.includes(
                    configurationValues[index + 1].values[0].value
                  )
                : false
            }
            className="base-opus-text-button delete-button"
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
          </Button>
        </div>
      ))}

      <div className="multi-select-configuration-button">
        <Button
          className="base-opus-text-button multi-select-configuration-button multi-select-configuration-add-button"
          onClick={onAddConfiguration}
          disabled={configurationValues.length === 5}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />

          <span>{addLabel}</span>
        </Button>
      </div>
    </div>
  );
};

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

interface SingleChoiceInteractionProps {
  options: string[];
  selectedOption: string;
  onChange: (selected: string[]) => void;
}

export default function SingleChoiceInteraction({
  options,
  selectedOption,
  onChange,
}: SingleChoiceInteractionProps) {
  return (
    <RadioGroup
      name="interaction-panel"
      value={selectedOption}
      onChange={(event) => {
        onChange([event.currentTarget.value]);
      }}
      className="finding-content-radio-button-group"
    >
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          value={option}
          control={<Radio />}
          label={option}
        />
      ))}
    </RadioGroup>
  );
}

import { IconButton } from '@mui/material';
import {
  useFetchActiveFindingsCountByPropertyMutation,
  useFetchCampaignMetricsMutation,
  useGetCampaignMetricsQuery,
} from 'Campaigns/store/api';
import ComparativeContent from 'Dashboard/Comparative/components/ComparativeContent';
import DashboardWidgetCard from 'Dashboard/components/DashboardWidgetCard';
import { FindingsWithResource } from 'FindingDetails/interfaces/FindingsWithResources';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import {
  CampaignActiveFindingsCountCategoryOptions,
  campaignBreakdownStatisticsFilterCategories,
} from 'shared/fixtures/data/campaigns-grid.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  ApiStaticOptionSourceProperties,
  CategoryState,
  ExtendedFilterCategory,
  MultiSelectState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CampaignBreakdownStatisticsProps extends BaseComponentProps {}

export const CampaignBreakdownStatistics: FunctionComponent<
  CampaignBreakdownStatisticsProps
> = () => {
  const { id: campaignId } = useParams();

  const [
    fetchCampaignMetrics,
    { isLoading: campaignMetricsLoading, data: campaignMetricsData },
  ] = useFetchCampaignMetricsMutation();

  const [
    fetchCampaignActiveFindingsCountByProperty,
    {
      isLoading: campaignActiveFindingsCountLoading,
      data: campaignActiveFindingsCountData,
    },
  ] = useFetchActiveFindingsCountByPropertyMutation();

  const [filterState, setFilterState] = useState<Record<string, CategoryState>>(
    {}
  );

  const scopeId = useMemo<string>(() => {
    if (filterState.scopeId) {
      const singleSelectState = filterState.scopeId as SingleSelectState;

      return singleSelectState?.selectedOptions
        ? singleSelectState.selectedOptions[0]?.value
        : undefined;
    }

    return undefined;
  }, [filterState.scopeId]);

  const groupId = useMemo<string>(() => {
    if (filterState.groupId) {
      const singleSelectState = filterState.groupId as SingleSelectState;

      return singleSelectState?.selectedOptions
        ? singleSelectState.selectedOptions[0]?.value
        : undefined;
    }

    return undefined;
  }, [filterState.groupId]);

  const [selectedChartProperty, setSelectedChartProperty] =
    useState<AutocompleteOption>(CampaignActiveFindingsCountCategoryOptions[0]);

  const [displayChart, setDisplayChart] = useState<boolean>(true);

  useEffect(() => {
    if (campaignId)
      fetchCampaignActiveFindingsCountByProperty({
        id: campaignId,
        property: selectedChartProperty.value,
        scopeId,
        groupId,
      });
  }, [selectedChartProperty, groupId, scopeId]);

  useEffect(() => {
    if (campaignId) {
      fetchCampaignMetrics({
        id: campaignId,
        scopeId,
        groupId,
      });
    }
  }, [campaignId, scopeId, groupId]);

  const onFilterChange = (categoryId: string, state: CategoryState) => {
    setFilterState((prevFilterState) => {
      const updatedFilterState = { ...prevFilterState };

      if (categoryId === 'scopeId' && updatedFilterState['groupId']) {
        delete updatedFilterState['groupId'];
      }

      if (Object.keys(state).length > 0) {
        return {
          ...updatedFilterState,
          [categoryId]: state,
        };
      }

      return { ...updatedFilterState };
    });
  };

  const enrichCategoryGroupItem = (
    categories: Array<ExtendedFilterCategory>
  ): Array<ExtendedFilterCategory> => {
    const selectedScopeOption = filterState['scopeId'] as SingleSelectState;

    const selectedScopeId = selectedScopeOption?.selectedOptions
      ? selectedScopeOption?.selectedOptions[0]?.value
      : undefined;

    if (selectedScopeId) {
      return categories.map((category: ExtendedFilterCategory) => {
        if (category.id === 'groupId') {
          return {
            ...category,
            state: {
              ...category.state,
              source: {
                ...(category.state as SingleSelectState).source,
                body: {
                  ...((
                    (category.state as SingleSelectState)
                      .source as ApiStaticOptionSourceProperties
                  ).body || {}),
                  startNodeId: selectedScopeId,
                },
              },
            },
          };
        }
        return category;
      });
    }

    return categories;
  };

  const renderChart = () => {
    if (displayChart)
      return (
        <div className="campaign-breakdown-statistics-chart">
          <ComparativeContent
            disableTable
            title="Active Findings"
            categoryOptions={CampaignActiveFindingsCountCategoryOptions}
            disableComparativeData
            externalWidgetData={campaignActiveFindingsCountData}
            isExternalWidgetDataLoading={campaignActiveFindingsCountLoading}
            selectedProperty={selectedChartProperty}
            onPropertyChange={(propertyName) => {
              setSelectedChartProperty(propertyName);
            }}
          />
        </div>
      );

    return <></>;
  };

  return (
    <div className="campaign-breakdown-statistics-container">
      <div className="campaign-breakdown-statistics-header">
        <div className="campaign-breakdown-statistics-title">
          <div className="campaign-breakdown-statistics-title-text">
            Breakdown Statistics
          </div>
          <div className="campaign-breakdown-statistics-title-collapse-icon">
            <IconButton
              onClick={() => {
                setDisplayChart((prevDisplayChart) => !prevDisplayChart);
              }}
            >
              <OpusSvgIcon
                type={
                  displayChart
                    ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                    : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
                }
              />
            </IconButton>
          </div>
        </div>

        <div className="campaign-breakdown-statistics-filters">
          <FilterChipGroup
            categories={enrichCategoryGroupItem(
              campaignBreakdownStatisticsFilterCategories
            )}
            categoryState={filterState}
            onChange={onFilterChange}
            staticCategoryIds={['scopeId', 'groupId']}
            disableAddButton
          />
        </div>
      </div>

      <div className="campaign-breakdown-statistics-body">
        <div className="campaign-breakdown-statistics-metrics">
          <DashboardWidgetCard
            icon={<OpusSvgIcon type={SVG_ICON_TYPES.BUG_ICON} />}
            title="Active Findings"
            data={{
              value: campaignMetricsData?.activeFindings || 0,
            }}
            isLoading={campaignMetricsLoading}
          />
          <DashboardWidgetCard
            icon={<OpusSvgIcon type={SVG_ICON_TYPES.TELESCOPE_ICON} />}
            title="Resolved Findings"
            data={{
              value: campaignMetricsData?.resolvedFindings || 0,
            }}
            isLoading={campaignMetricsLoading}
          />
          <DashboardWidgetCard
            icon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
            title="Open Tickets"
            data={{
              value: campaignMetricsData?.openTickets || 0,
            }}
            isLoading={campaignMetricsLoading}
          />
          <DashboardWidgetCard
            icon={<OpusSvgIcon type={SVG_ICON_TYPES.BRAKE_WARNING_ICON} />}
            title="Pending Exceptions"
            data={{
              value: campaignMetricsData?.pendingExceptions || 0,
            }}
            isLoading={campaignMetricsLoading}
          />
          <DashboardWidgetCard
            icon={
              <OpusSvgIcon type={SVG_ICON_TYPES.TRIANGLE_EXCLAMATION_ICON} />
            }
            title="Active Exceptions"
            data={{
              value: campaignMetricsData?.activeExceptions || 0,
            }}
            isLoading={campaignMetricsLoading}
          />
        </div>
        {renderChart()}
      </div>
    </div>
  );
};

import React, { FC, useState } from 'react';
import { Checkbox } from '@mui/material';
import classNames from 'classnames';
import CommonCheckbox from '../CommonCheckbox';
interface FilterMainItemProps {
  item: any;
  styleClass?: string;
  clickItem: (value?: any) => void;
  disabled?: boolean;
  checked?: boolean;
}

const FilterMainItem: FC<FilterMainItemProps> = ({
  item,
  clickItem,
  styleClass,
  disabled = false,
  checked = false,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    clickItem();
  };

  return (
    <div className={classNames('filter-item', styleClass)} title={item.name}>
      <CommonCheckbox
        checked={checked || item.checked}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'controlled' }}
        disabled={disabled}
      />
      <span className="body-1 item-text">{item.name}</span>
    </div>
  );
};

export default FilterMainItem;

import { IconWrapper } from 'shared/icons';
import { OpusImageIconProps } from 'shared/icons/interface';
import { IconFactory } from 'shared/icons';
import _ from 'lodash';

const iconFactory = new IconFactory();

interface OpusIconProps extends OpusImageIconProps {
  title?: string;
  className?: string;
}

export const OpusImageIcon = (props: OpusIconProps) => {
  const { title, className } = props;

  return (
    <IconWrapper title={title} className={className}>
      {iconFactory.getImageIcon(_.omit(props, 'title'))}
    </IconWrapper>
  );
};

import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import PageHeader from '../PageHeader';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface PageContainerProps extends BaseComponentProps {
  title: string | ReactJSXElement;
  sideElement?: ReactJSXElement;
  containerClass?: string;
}

export const PageContainer: FunctionComponent<PageContainerProps> = ({
  title,
  sideElement,
  containerClass,
  children,
}) => {
  return (
    <div className={`page-container ${containerClass || ''}`}>
      <PageHeader title={title} sideElement={sideElement} />
      <div className="page-body-container">{children}</div>
    </div>
  );
};

import { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface IconBoxProps extends BaseComponentProps {
  icon?: ReactNode;
  label: string;
  helperText?: string;
}

export const IconBox: FunctionComponent<IconBoxProps> = ({
  icon,
  label,
  helperText,
}) => {
  return (
    <div className="icon-box">
      <div className="icon-box-icon">{icon || <></>}</div>
      <div className="icon-box-text">
        <div className="icon-box-label" title={label}>
          {label}
        </div>
        <div className="icon-box-helper-text">{helperText}</div>
      </div>
    </div>
  );
};

import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import SettingsGridColumns from '../SettingsLayout/settings-data-grid';
import UserDialog from './UserDialog';
import {
  useDeleteUserMutation,
  useFetchUsersExtendedMutation,
} from 'Auth/store/api';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import ForbiddenPage from 'Common/components/ForbiddenPage';

export const UsersList = () => {
  const { t: translation } = useTranslation();

  const [shouldIgnoreRowClick, setShouldIgnoreRowClick] =
    useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<any[]>([]);
  const [isDialogLoading, setIsLoading] = useState<boolean>(false);
  const [fetchUsers, { data: userList, isLoading: isLoadingUsers }] =
    useFetchUsersExtendedMutation();
  const userPermissions = usePermissions();
  const [selectedUser, setSelectedUser] = useState<string>('');
  const hasUsersReadPermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_USERS_READ
  );
  const hasUsersWritePermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_USERS_WRITE
  );

  useEffect(() => {
    if (userList) {
      setTableItems(userList);
    }
  }, [userList]);

  function onDelete(decision: boolean) {
    if (decision) fetchUsers();
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEditDialogOpen = (rowId: string) => {
    if (!hasUsersWritePermission) return;

    setSelectedUser(rowId);
    handleDialogOpen();
  };
  const teamColumns = useMemo(() => {
    return [
      ...SettingsGridColumns.getUsersColumns({
        t: translation,
        useDeleteMutation: useDeleteUserMutation,
        onDelete,
        setShouldIgnoreRowClick,
        requiredPermissions: [ApplicationPermission.SETTINGS_USERS_WRITE],
        onUpdate: handleEditDialogOpen,
      }),
    ];
  }, []);

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose(shouldReload?: boolean) {
    setDialogOpen(false);
    setSelectedUser('');
    if (shouldReload === true) {
      fetchUsers();
    }
  }
  if (!hasUsersReadPermission) return <ForbiddenPage />;

  return (
    <>
      <SettingsLayout
        headerProps={{
          setTableItems: setTableItems,
          items: userList || [],
          filterPropertyName: 'name',
          onButtonClick: handleDialogOpen,
          buttonLabel: (
            <FormattedMessage
              id="settings.details.addUsers"
              defaultMessage="Add Users"
            />
          ),
          buttonType: 'button',
          disableButton: false,
          buttonRequiredPermissions: [
            ApplicationPermission.SETTINGS_USERS_WRITE,
          ],
        }}
        tableProps={{
          className: 'setting-users-table',
          rowData: tableItems,
          columnDefs: teamColumns,
          onItemClick: (item) => {},
          isLoading: isLoadingUsers,
          deleteMutation: useDeleteUserMutation,
          ignoreRowClick: shouldIgnoreRowClick,
        }}
        fetchData={() => {}}
      />

      <UserDialog
        open={dialogOpen}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.CALENDAR_ICON} />}
        closeButtonText={translation('common.cancel')}
        loadingButtonText={translation('common.connecting')}
        saveButtonText={
          selectedUser
            ? translation('common.save')
            : translation('common.create')
        }
        onClose={handleDialogClose}
        title={selectedUser ? 'Edit user' : 'Create user'}
        buttonRequiredPermissions={[ApplicationPermission.SETTINGS_USERS_WRITE]}
        isLoading={isDialogLoading}
        userId={selectedUser}
        setIsLoading={setIsLoading}
        children={undefined}
      />
    </>
  );
};

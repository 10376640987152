import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Translations } from 'shared/models/translations.model';

export default class TranslationService {
  static getTranslationManager(translations: Translations) {
    i18n
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        resources: translations,
        fallbackLng: 'en',
        debug: true,
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
          escapeValue: false,
          formatSeparator: ',',
        },
      });

    return i18n;
  }
}

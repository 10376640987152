import { FunctionComponent, useEffect, useState } from 'react';
import {
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IconWrapper } from 'shared/icons';
import OpusPngIcon from 'shared/components/IconComponents/OpusPngIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface StatusProps {
  label: string;
  readonly: boolean;
  onStatusChange?: (status: string) => {};
  isLoading?: boolean;
  statusChangedSuccessfully?: boolean;
  showIcon?: boolean;
}

export const getStatusIconsWithTranslations: any = (translation: any) => ({
  [translation(`common.status.open`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.CLOCK_ICON} />
  ),
  [translation(`common.status.inProgress`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_ICON} />
  ),
  [translation(`common.status.inReview`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_ICON} />
  ),
  [translation(`common.status.resolved`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />
  ),
  [translation(`common.status.suppress`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_BASE_ICON} />
  ),
  [translation(`assets.details.status.new`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.CLOCK_ICON} />
  ),
  [translation(`assets.details.status.suppressed`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_ICON} />
  ),
  [translation(`findings.details.status.inProgress`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_ICON} />
  ),
  [translation(`findings.details.status.inReview`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_ICON} />
  ),
  [translation(`findings.details.status.resolved`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />
  ),
  [translation(`findings.details.status.pendingException`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.HOURGLASS_ICON} />
  ),
  [translation(`findings.details.status.exception`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.LIGHTBULB_ICON} />
  ),
  [translation(`findings.details.status.pendingApproval`)]: (
    <OpusSvgIcon type={SVG_ICON_TYPES.HOURGLASS_CLOCK_ICON} />
  ),
});

export const Status: FunctionComponent<StatusProps> = ({
  label,
  readonly = false,
  onStatusChange,
  isLoading,
  showIcon = true,
}) => {
  const { t: translation } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentStatus, setCurrentStatus] = useState<string>(label);

  useEffect(() => {
    setCurrentStatus(label);
  }, [label]);

  const openStatus = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (readonly) return;
    event.preventDefault();
    event.stopPropagation();
    (event as any).ignore = true;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function listItemClick(status: string) {
    if (!readonly && onStatusChange) {
      onStatusChange(status);
      setCurrentStatus(status);
    }
    handleClose();
  }

  const statusToIcon = getStatusIconsWithTranslations(translation);

  function renderStatusList() {
    return (
      <List>
        {Object.keys(statusToIcon).map((status) => (
          <ListItem
            onClick={() => listItemClick(status)}
            key={status}
            disablePadding
          >
            <ListItemButton>
              {showIcon && (
                <ListItemIcon sx={{ minWidth: '30px' }}>
                  <IconWrapper title={status}>
                    {statusToIcon[status]}
                  </IconWrapper>
                </ListItemIcon>
              )}
              <ListItemText className="body-2 " primary={status} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    );
  }

  if (isLoading) return <CircularProgress size={14} />;
  if (!label) return <></>;

  return (
    <>
      <Stack
        direction="row"
        onClick={handleClick}
        className={`status-container ${!readonly && 'clickable-item'}`}
      >
        {showIcon && (
          <IconWrapper title={currentStatus} className="icon">
            {statusToIcon[currentStatus]}
          </IconWrapper>
        )}
        <Typography className="body-2">{currentStatus}</Typography>
        {!readonly && (
          <OpusPngIcon
            title="Expand More"
            icon="expand_more"
            color="#667085"
            size={18}
          />
        )}
      </Stack>
      <Popover
        id="status-popover"
        open={openStatus}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {renderStatusList()}
      </Popover>
    </>
  );
};

import {
  FindingStatus,
  FindingImmutableStatus,
} from 'FindingDetails/interfaces/status';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { StatusItem } from 'shared/models/data/status-item.mode';

export const StatusItemsList: Array<StatusItem> = [
  {
    icon: SVG_ICON_TYPES.CLOCK_ICON,
    id: FindingStatus.OPEN,
  },
  {
    icon: SVG_ICON_TYPES.ELLIPSIS_ICON,
    id: FindingStatus.IN_PROGRESS,
  },
  {
    icon: SVG_ICON_TYPES.VISIBILITY_ICON,
    id: FindingStatus.IN_REVIEW,
  },
  {
    icon: SVG_ICON_TYPES.CHECK_ICON,
    id: FindingStatus.RESOLVED,
  },
  {
    icon: SVG_ICON_TYPES.CIRCLE_EXCLAMATION_BASE_ICON,
    id: FindingStatus.SUPPRESS,
  },
  {
    icon: SVG_ICON_TYPES.HOURGLASS_ICON,
    id: FindingImmutableStatus.PENDING_EXCEPTION,
    hidden: true,
  },
  {
    icon: SVG_ICON_TYPES.LIGHTBULB_ICON,
    id: FindingImmutableStatus.EXCEPTION,
    hidden: true,
  },
];

import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import FindingContent from '../FindingContent';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { useFetchSummaryQuery } from '../../store/api';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useTranslation } from 'react-i18next';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { BaseFindingComponentProps } from 'shared/models/props/base-finding-component-props.model';

export interface FindingDetailsModalProps extends BaseFindingComponentProps {
  open: boolean;
  handleClose: () => void;
  handleCloud2CodeModalOpen: () => void;
  setFindingId: (id: string) => void;
}

interface FindingDetailsWrapperProps extends BaseComponentProps {
  openModal: boolean;
}

export const FindingDetailsWrapper: FunctionComponent<
  FindingDetailsWrapperProps
> = ({ openModal, children }) => {
  const { t: translation } = useTranslation();
  const [pageNotFound, setPageNotFound] = useState(false);
  const [urlSearchParams] = useQueryParams();
  const { isLoading: isFindingSummaryLoading, error: findingSummaryError } =
    useFetchSummaryQuery(urlSearchParams.openFindingId);

  useEffect(() => {
    if (openModal && !isFindingSummaryLoading && findingSummaryError) {
      setPageNotFound(true);
    }
  }, [openModal, findingSummaryError, isFindingSummaryLoading]);

  const Page404 = () => {
    return (
      <div className="finding-details-page-not-found-page-container">
        <div className="finding-details-page-not-found">
          <OpusSvgIcon type={SVG_ICON_TYPES.RADAR_GRAY_ICON} />
          <h1> {translation(`findings.details.findingMissing`)}</h1>
          <h2>{translation(`findings.details.findingMissingInfoMessage`)}</h2>
        </div>
      </div>
    );
  };

  return <>{pageNotFound ? <Page404 /> : children}</>;
};

export const FindingDetailsModal: FunctionComponent<
  FindingDetailsModalProps
> = ({
  open,
  handleClose,
  handleCloud2CodeModalOpen,
  findingId,
  setFindingId,
}) => {
  return (
    <>
      <PopoverWrapper
        classes="finding-detail-popover"
        showPopover={open}
        handleClose={handleClose}
      >
        <FindingDetailsWrapper openModal={open}>
          <FindingContent
            findingId={findingId}
            handleCloud2CodeModalOpen={handleCloud2CodeModalOpen}
            setFindingId={setFindingId}
          />
        </FindingDetailsWrapper>
      </PopoverWrapper>
    </>
  );
};

import { Button, Skeleton } from '@mui/material';
import MultiChoiceInteraction from 'FindingDetails/components/ThreatIntelWidget/Sidebar/MultiChoiceInteraction';
import SingleChoiceInteraction from 'FindingDetails/components/ThreatIntelWidget/Sidebar/SingleChoiceInteraction';
import {
  useFetchInteractionQuery,
  usePostFindingInteractionMutation,
} from 'FindingDetails/store/api';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseFindingComponentProps } from 'shared/models/props/base-finding-component-props.model';

interface FindingContentActionsProps extends BaseFindingComponentProps {}

export const FindingContentActions: FunctionComponent<
  FindingContentActionsProps
> = ({ findingId }) => {
  const { t: translation } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [searchParams] = useQueryParams();
  const { data, isLoading: interactionDataLoading } = useFetchInteractionQuery(
    findingId || searchParams.openFindingId
  );

  const [
    postInteraction,
    { isLoading: postInteractionLoading, isSuccess: postInteractionSuccess },
  ] = usePostFindingInteractionMutation();

  function onInteractionChange(selectedOptions: string[]) {
    setSelectedOptions(selectedOptions);
  }

  function handleInteraction() {
    if (!data?.stepId || !data?.options) return;
    let results: any;

    if (!multipleChoice && selectedOptions.length === 1) {
      results = { [selectedOptions[0]]: true };
    } else {
      let optionsMap = new Map<string, boolean>();
      for (let option of data.options) {
        optionsMap.set(option, selectedOptions.includes(option));
      }
      results = Object.fromEntries(optionsMap);
    }
    const bodyResults = Object.keys(results).filter(
      (resultKey) => results[resultKey]
    );

    postInteraction({
      findingId,
      body: {
        stepId: data.stepId,
        results: bodyResults,
      },
    });
  }

  const multipleChoice: boolean = data?.multipleChoice || false;

  const renderActionOptions = () => {
    if (multipleChoice) {
      return (
        <MultiChoiceInteraction
          options={data?.options || []}
          selectedOptions={selectedOptions}
          onChange={onInteractionChange}
        />
      );
    }

    return (
      <SingleChoiceInteraction
        options={data?.options || []}
        selectedOption={selectedOptions.length === 1 ? selectedOptions[0] : ''}
        onChange={onInteractionChange}
      />
    );
  };

  function renderSelectedOptions() {
    return (
      <div className="finding-content-actions-container">
        <div className="finding-content-actions-title-container">
          <div className="finding-content-actions-title-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
          </div>

          <div className="finding-content-actions-title-text">
            {translation(`findings.details.submittedActions`)}
          </div>
        </div>
        <div className="finding-content-actions-body">
          <div className="finding-content-actions-body-description">
            {translation(`findings.details.selectedActions`)}:
          </div>
          <div className="finding-content-actions-body-selected-options">
            {selectedOptions?.map((selectedOption) => (
              <div className="finding-content-actions-body-selected-option">
                <div className="finding-content-actions-body-selected-option-icon">
                  <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />
                </div>
                <div className="finding-content-actions-body-selected-option-text">
                  {selectedOption}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const renderSuggestedActions = () => {
    return (
      <div className="finding-content-actions-container">
        <div className="finding-content-actions-title-container">
          <div className="finding-content-actions-title-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
          </div>

          <div className="finding-content-actions-title-text">
            {translation(`findings.subSections.availableActions`)}
          </div>
        </div>
        <div className="finding-content-actions-body">
          <div className="finding-content-actions-body-title">
            {data?.title}
          </div>
          <div className="finding-content-actions-body-description">
            {data?.message}
          </div>
          <div className="finding-content-actions-body-options">
            {renderActionOptions()}
          </div>
        </div>

        <div className="finding-content-actions-footer">
          <Button
            className="opus-primary-button"
            title={data?.interactingStepName}
            onClick={handleInteraction}
          >
            {data?.submitButtonText ||
              translation(`findings.subSections.confirmActions`)}
          </Button>
        </div>
      </div>
    );
  };

  function renderBody() {
    if (interactionDataLoading || postInteractionLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }

    const interactionAvailable = data?.options && data?.options.length > 0;

    if (!interactionAvailable) {
      return <></>;
    }

    return (
      <>
        {!postInteractionLoading &&
          postInteractionSuccess &&
          renderSelectedOptions()}
        {!postInteractionLoading &&
          !postInteractionSuccess &&
          interactionAvailable &&
          renderSuggestedActions()}
      </>
    );
  }

  return renderBody();
};

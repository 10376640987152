import { createPortal } from 'react-dom';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import {
  Alert,
  Button,
  Chip,
  Snackbar,
  SnackbarProps,
  Typography,
} from '@mui/material';
import {
  storeRuleToAdd,
  selectDeltaMaps,
  clearDeltaRuleGroup,
  storeRuleToUpdate,
  storeRuleToDelete,
  selectRuleGroupsSearchParams,
} from 'AutomationManagement/store';
import {
  useBulkPostRulesMutation,
  useFetchTriggersForRemediationRulesMutation,
} from 'AutomationManagement/store/api';
import { CommonDispatch } from 'Common/store';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect, useState } from 'react';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import AutomationManagementDataHandler from 'shared/handlers/automation-management-data.handler';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  AutomationRule,
  AutomationRuleProperty,
  AutomationRuleRunMode,
  AutomationRuleStatus,
} from 'shared/models/data/automation-rule-group.model';
import {
  AutomationManagementRuleInputChangeBody,
  AutomationManagementTableRowProps,
} from 'shared/models/props/automation-management-props.model';
import { OpusAutocompleteOption } from 'shared/models/props/opus-autocomplete-props.model';
import AutomationManagementRuleRow from '../AutomationManagementRuleRow';
import { v4 as uuid4 } from 'uuid';
import FormattedMessage from 'shared/components/FormattedMessage';
import { ApplicationPermission } from '../../../../../shared/enums/permission.enum';
import { AuthorizedContent } from '@frontegg/react';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';

const PortaledSnackbar = (props: SnackbarProps) => {
  return createPortal(
    <Snackbar {...props}>{props.children}</Snackbar>,
    document.getElementById('portal-root') as HTMLElement
  );
};

export const AutomationManagementTableRow: FunctionComponent<
  AutomationManagementTableRowProps
> = ({ row, searchRuleGroups }) => {
  const [getWorkflowDefinitionByTriggerType, { data: workflowDefinitions }] =
    useFetchTriggersForRemediationRulesMutation();

  const [showSuccessSaveMessage, setShowSuccessSaveMessage] =
    useState<boolean>(false);

  const ruleGroupsSearchParams = useCommonSelector(
    selectRuleGroupsSearchParams
  );

  const deltaMaps = useCommonSelector(selectDeltaMaps);

  const [
    bulkPostRules,
    { isLoading: savingRules, isSuccess: savedRulesSuccessfully },
  ] = useBulkPostRulesMutation();

  useEffect(() => {
    if (savedRulesSuccessfully) {
      searchRuleGroups && searchRuleGroups(ruleGroupsSearchParams);
    }
  }, [savedRulesSuccessfully]);

  const [openPanel, setOpenPanel] = useState<boolean>(false);

  useEffect(() => {
    if (openPanel && !workflowDefinitions) {
      getWorkflowDefinitionByTriggerType({
        body: {
          triggerType: row?.findingType?.name,
          triggerApplicationID: row?.rules[0]?.findingSourceApplicationID,
          triggerResourceType: row?.rules[0]?.resourceType,
        },
      });
    }
  }, [openPanel]);

  useEffect(() => {
    if (savedRulesSuccessfully) {
      dispatch(clearDeltaRuleGroup({ findingType: row?.findingType?.name }));

      setShowSuccessSaveMessage(true);

      setTimeout(() => {
        setShowSuccessSaveMessage(false);
      }, 3000);
    }
  }, [savedRulesSuccessfully]);

  // const deltaRuleGroups = useCommonSelector(selectDeltaRuleGroups);

  const dispatch: CommonDispatch = useCommonDispatch();

  const addRuleForType = () => {
    dispatch(
      storeRuleToAdd({
        findingType: row?.findingType?.name,
        rule: {
          id: uuid4(),
          findingSourceApplicationID: row?.findingType?.application?.id,
          findingType: row?.findingType?.name,
          properties: [],
          runMode: AutomationRuleRunMode.MANUAL,
          status: AutomationRuleStatus.DISABLED,
          workflowDef: {
            familyId: '',
            id: '',
            name: '',
            managementType: '',
          },
          resourceType: row?.findingType?.resource?.type,
          draft: true,
        },
      })
    );
  };

  const handleRuleUpdate = (
    configuration: AutomationManagementRuleInputChangeBody
  ) => {
    const { propertyPath, rule, values } = configuration;

    let updatedRule = { ...rule };

    if (propertyPath === 'workflowDef') {
      const workflowDef =
        AutomationManagementDataHandler.getAutomationRuleWorkflowDef(
          (values as Array<OpusAutocompleteOption>)[0]?.value,
          workflowDefinitions
        );
      updatedRule.workflowDef = workflowDef;
    } else if (propertyPath.includes('property')) {
      const property =
        AutomationManagementDataHandler.getAutomationRuleProperty(
          propertyPath.replace('property.', ''),
          typeof values === 'string'
            ? [values]
            : (values as Array<OpusAutocompleteOption>).map(
                (value: OpusAutocompleteOption) => value.value
              )
        );
      if (
        rule?.properties?.find(
          (rule: AutomationRuleProperty) =>
            rule.type === propertyPath.replace('property.', '')
        )
      ) {
        updatedRule.properties = [
          ...updatedRule.properties.filter(
            (property: AutomationRuleProperty) =>
              property.type !== propertyPath.replace('property.', '')
          ),
          property,
        ];
      } else {
        updatedRule.properties = [...updatedRule.properties, property];
      }
    } else {
      (updatedRule[propertyPath as keyof AutomationRule] as any) = values;
    }

    dispatch(
      storeRuleToUpdate({
        rule: updatedRule,
        findingType: row?.findingType?.name,
      })
    );
  };

  const handleRuleDelete = (findingType: string, rule: AutomationRule) => {
    dispatch(
      storeRuleToDelete({
        rule,
        findingType,
      })
    );
  };

  const handleBulkSaveRuleGroup = () => {
    const rulesToSave =
      AutomationManagementDataHandler.transformLocalRulesToBulkPostData({
        create: deltaMaps.create[row?.findingType.name],
        delete: deltaMaps.delete[row?.findingType?.name],
        update: deltaMaps.update[row?.findingType?.name],
      });

    if (
      rulesToSave &&
      (rulesToSave?.create?.length ||
        rulesToSave?.update?.length ||
        rulesToSave?.delete?.length)
    ) {
      bulkPostRules(rulesToSave);
    }
  };

  return (
    <>
      <div className="automation-management-table-row">
        <div className="automation-management-table-row-information">
          <div className="automation-management-table-row-finding-information">
            {row?.findingType?.application?.name && (
              <OpusImageIcon
                src={row?.findingType?.application?.logo}
                alt={row?.findingType?.application?.name}
                title={row?.findingType?.application?.name}
                imageClassName="automation-management-table-logo"
              />
            )}

            <Typography className="automation-management-table-row-finding-information-title">
              {row?.findingType?.title}
            </Typography>

            {row?.findingType?.severity && (
              <CommonSeverityChip severityNumber={row?.findingType?.severity} />
            )}

            {row?.findingType?.resource?.name && (
              <Chip
                className="automation-management-table-chip"
                icon={
                  row?.findingType?.resource?.logo ? (
                    <OpusImageIcon
                      src={row?.findingType?.resource?.logo}
                      alt={row?.findingType?.resource?.name}
                      title={row?.findingType?.resource?.name}
                      imageClassName="automation-management-table-logo"
                    />
                  ) : (
                    <></>
                  )
                }
                label={row?.findingType?.resource?.name}
              />
            )}
          </div>

          <div className="automation-management-table-row-rules-information">
            {row?.remediationTypeLogos?.map((remediationTypeLogo) => {
              return remediationTypeLogo ? (
                <OpusImageIcon
                  src={remediationTypeLogo}
                  alt="remediation-logo"
                  imageClassName="automation-management-table-logo"
                />
              ) : (
                <></>
              );
            })}
            <Typography className="automation-management-table-row-rules-counter">
              {row?.rules?.length}
              <FormattedMessage
                id={
                  !row?.rules?.length || row?.rules?.length === 1
                    ? 'automationManagement.details.ruleDefined'
                    : 'automationManagement.details.rulesDefined'
                }
                params={{
                  param1: '',
                }}
                defaultMessage="Rule defined"
              />
            </Typography>
          </div>

          <div
            className="automation-management-table-row-slide-control"
            onClick={() => {
              setOpenPanel(!openPanel);
            }}
          >
            {openPanel ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
        </div>

        <div
          className="automation-management-table-row-rules"
          style={{
            overflow: 'hidden',
            height: openPanel ? '100%' : '0px',
          }}
        >
          {row?.rules?.map((rule: any, index: number) => {
            return (
              <AutomationManagementRuleRow
                key={rule?.id || index}
                index={index}
                rule={rule}
                isOpen={openPanel}
                workflowDefinitions={workflowDefinitions}
                findingType={row?.findingType?.name}
                manageRuleFns={{
                  handleRuleUpdate,
                  handleRuleDelete,
                }}
              />
            );
          })}

          <AuthorizedContent
            requiredPermissions={[ApplicationPermission.AUTOMATIONS_WRITE]}
          >
            <div className="automation-management-table-row-button-group-container">
              <div
                className="automation-management-table-row-add-button"
                onClick={addRuleForType}
              >
                <OpusSvgIcon type={SVG_ICON_TYPES.PLUS_BUTTON_ICON} />
                <FormattedMessage
                  id="automationManagement.details.addRule"
                  defaultMessage="Add Rule"
                />
              </div>
              <Button
                className="base-opus-button alternative-opus-button"
                onClick={handleBulkSaveRuleGroup}
              >
                <FormattedMessage
                  id="common.save"
                  defaultMessage="Save"
                  capitalize
                />
              </Button>
            </div>
          </AuthorizedContent>
        </div>
      </div>
      <PortaledSnackbar
        open={savingRules}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="info" sx={{ width: '100%' }}>
          <FormattedMessage
            id="automationManagement.details.savingAutomationRuleConfiguration"
            defaultMessage="Saving automation rule configuration"
          />
        </Alert>
      </PortaledSnackbar>
      <PortaledSnackbar
        open={showSuccessSaveMessage}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          <FormattedMessage
            id="automationManagement.details.automationRuleConfigurationSavedSuccessfully"
            defaultMessage="Automation rule configuration saved successfully"
          />
        </Alert>
      </PortaledSnackbar>
    </>
  );
};

import { FunctionComponent, useEffect, useMemo } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import PostureWidgetCard from '../PostureWidgetCard';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import { DashboardSection } from '../DashboardSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useTranslation } from 'react-i18next';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import useCommonSelector from 'Common/utils/use-selector';
import {
  getFilterState,
  getFindingTypes,
  getInitialFilterLoad,
  selectdashboardFilter,
  setdashboardFilters,
} from 'Dashboard/store';
import {
  useGetDashboardPostureAnalyticsMutation,
  useGetUserAvailableNodesOfTypeMutation,
} from 'Dashboard/store/api';
import NoDataToDisplayCard from '../NoDataCard';
import DoughnutChartWidget from 'shared/components/DoughnutChartWidget';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import { ChartOptions } from 'chart.js';
import VerticalSimpleBarChart from 'shared/components/VerticalSimpleBarChart';
import DashboardChartCard from '../DashboardChartCard';
import { Grid } from '@mui/material';
import TopCampaignsTableWidget from '../TopCampaignsTableWidget';
import TopRiskOwnerTableWidget from '../TopRiskOwnerTableWidget';
import TopRootCausesTableWidget from '../TopRootCausesTableWidget';
import { RiskAssessmentSDLCWidget } from '../RiskAssessmentSDLCWidget/RiskAssessmentSDLCWidget';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { GridType } from 'Risk/store';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { FindingState, SlaStatus } from 'shared/fixtures/data/risk-grid.data';
import {
  FindingImmutableStatus,
  FindingStatus,
} from 'FindingDetails/interfaces/status';
import { SeverityValueMapper } from 'FindingDetails/interfaces/severity';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';
import { capitalize } from 'lodash';
import Autocomplete from 'Common/components/Autocomplete';
import {
  operationalDashboardFilterOptions,
  TimelineFilter,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { AutocompleteOption } from 'FindingDetails/store/api';
import useCommonDispatch from 'Common/utils/use-dispatch';
import EntitledContent from 'shared/components/EntitledContent';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import {
  MultiSelectState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { serviceAttributes } from 'shared/fixtures/data/dashboard.data';

interface PostureContentProps
  extends BaseComponentProps,
    OrganizationalDataProps {}

const navigationHandler = new NavigationHandler();

export const PostureContent: FunctionComponent<PostureContentProps> = ({
  scopeData,
  groupData,
}) => {
  const { t: translation } = useTranslation();
  const dispatch = useCommonDispatch();

  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const filterState = useCommonSelector(getFilterState);

  const hasServiceAttributesFilter = useMemo<boolean>(
    () => {
      for (const attribute of serviceAttributes) {
        if (
          (filterState[attribute] as MultiSelectState)?.selectedOptions?.length
        ) {
          return true;
        }
      }
      return false;
    },
    serviceAttributes.map((attr) => filterState[attr])
  );

  const findingTypes = useMemo(() => {
    return getFindingTypes(filterState);
  }, [filterState['findingType']]);

  const [
    getBySeverityWidgetData,
    { data: bySeverityWidgetData, isLoading: bySeverityWidgetDataLoading },
  ] = useGetDashboardPostureAnalyticsMutation();

  const [
    getByCategoryWidgetData,
    { data: byCategoryWidgetData, isLoading: byCategoryWidgetDataLoading },
  ] = useGetDashboardPostureAnalyticsMutation();

  const selectTimeline = (timeline: TimelineFilter) => {
    dispatch(
      setdashboardFilters({
        ...dashboardFilter,
        timeline,
      })
    );
  };

  const businessUnitNavigationFilter = useMemo<
    Partial<{ businessUnitId: Array<FilterOption> }>
  >(() => {
    if (filterState['businessUnitId']) {
      const typedState = filterState['businessUnitId'] as MultiSelectState;
      const selectedOptions = typedState.selectedOptions;

      return typedState.allSelected
        ? hasServiceAttributesFilter
          ? {
              businessUnitId:
                selectedOptions?.map((selectedOption) => ({
                  id: selectedOption.value,
                  name: selectedOption.label as string,
                })) || [],
            }
          : {}
        : {
            businessUnitId:
              selectedOptions?.map((selectedOption) => ({
                id: selectedOption.value,
                name: selectedOption.label as string,
              })) || [],
          };
    }

    return {};
  }, [filterState['businessUnitId'], hasServiceAttributesFilter]);

  const groupNavigationFilter = useMemo<
    Partial<{ groupId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    if (filterState['groupId']) {
      const typedState = filterState['groupId'] as SingleSelectState;
      const selectedOptions = typedState.selectedOptions;

      return selectedOptions?.length
        ? selectedOptions[0].value !== 'All'
          ? {
              groupId: {
                id: selectedOptions[0].value,
                name: selectedOptions[0]?.label as string,
              },
            }
          : {}
        : {};
    }

    return {};
  }, [filterState['groupId'], businessUnitNavigationFilter]);

  const scopeNavigationFilter = useMemo<
    Partial<{ scopeId: FilterOption }>
  >(() => {
    if (businessUnitNavigationFilter.businessUnitId) return {};

    if (filterState['scopeId']) {
      const typedState = filterState['scopeId'] as SingleSelectState;
      const selectedOptions = typedState.selectedOptions;

      return selectedOptions?.length
        ? selectedOptions[0].value !== 'All'
          ? {
              scopeId: {
                id: selectedOptions[0].value,
                name: selectedOptions[0]?.label as string,
              },
            }
          : {}
        : {};
    }

    return {};
  }, [filterState['scopeId'], businessUnitNavigationFilter]);

  const findingTypeNavigationFilter = useMemo<
    Partial<{ findingType: Array<FilterOption> }>
  >(() => {
    return findingTypes?.length
      ? {
          findingType: findingTypes.map((findingType) => ({
            id: findingType,
            name: findingType,
          })),
        }
      : {};
  }, [findingTypes]);

  useEffect(() => {
    if (dashboardFilterInitialLoad) {
      getByCategoryWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: findingTypes,
        type: PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_CATEGORY,
      });
      getBySeverityWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: findingTypes,
        type: PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_SEVERITY,
      });
    }
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter.businessUnitId,
    findingTypes,
  ]);

  const renderChartWidget = (
    type: PostureWidgetAnalyticsType,
    widgetData: any,
    isLoading: boolean
  ) => {
    if ((!widgetData || widgetData?.datasets.length == 0) && !isLoading) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }
    if (type === PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_CATEGORY) {
      return (
        <>
          <DoughnutChartWidget
            fetchWidgetData={() => {}}
            isWidgetDataLoading={isLoading}
            widgetData={widgetData}
            aspectRatio={1.3}
            style={
              presentationalConfigurationForWidgets['posture_by_category'].style
            }
            applyColorList
            cutoutPercentage={45}
            customLegendContainerId="posture-dashboard-section-by-category-chart-legend-container"
            options={
              presentationalConfigurationForWidgets['posture_by_category']
                .options as ChartOptions<'doughnut'>
            }
            onElementClick={(value, label) => {
              const category = label.replace(/\s\(\d+\)$/, '');

              if (category !== 'Other') {
                navigationHandler.handleRiskNavigation(GridType.None, {
                  state: {
                    id: FindingState.ACTIVE,
                    name: FindingState.ACTIVE,
                  },
                  category: [
                    {
                      id: category,
                      name: category,
                    },
                  ],
                  ...businessUnitNavigationFilter,
                  ...findingTypeNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                });
              }
            }}
          />
          {!isLoading && (
            <div id="posture-dashboard-section-by-category-chart-legend-container"></div>
          )}
        </>
      );
    } else if (
      type === PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_SEVERITY
    ) {
      return (
        <>
          <VerticalSimpleBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={isLoading}
            widgetData={widgetData}
            style={
              presentationalConfigurationForWidgets['posture_by_severity'].style
            }
            options={
              presentationalConfigurationForWidgets['posture_by_severity']
                .options as ChartOptions<'bar'>
            }
            customLegendContainerId="posture-dashboard-section-by-severity-chart-legend-container"
            enableTooltip
            applyColorList
            onElementClick={(value, label) => {
              navigationHandler.handleRiskNavigation(GridType.None, {
                state: {
                  id: FindingState.ACTIVE,
                  name: FindingState.ACTIVE,
                },
                severity: [
                  {
                    id: SeverityValueMapper[label],
                    name: label,
                  },
                ],
                ...businessUnitNavigationFilter,
                ...findingTypeNavigationFilter,
                ...scopeNavigationFilter,
                ...groupNavigationFilter,
              });
            }}
          />
          {!isLoading && (
            <div id="posture-dashboard-section-by-severity-chart-legend-container"></div>
          )}
        </>
      );
    }
  };

  return (
    <div className="posture-content-container">
      <DashboardSection
        title={translation(`dashboards.widgets.totalMetrics`)}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_PIE_ICON} />}
      >
        <div className="operation-metrics-container">
          <div className="operation-metrics-cards">
            <PostureWidgetCard
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.BUG_ICON} />}
              title={translation(`dashboards.widgets.openFindings`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_OPEN_FINDINGS}
              onClick={() => {
                navigationHandler.handleRiskNavigation(GridType.None, {
                  state: {
                    id: FindingState.ACTIVE,
                    name: FindingState.ACTIVE,
                  },
                  ...businessUnitNavigationFilter,
                  ...findingTypeNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                });
              }}
            />
            <PostureWidgetCard
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.TELESCOPE_ICON} />}
              title={translation(`dashboards.widgets.rootCauses`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_ROOT_CAUSES}
              onClick={() => {
                navigationHandler.handleRiskNavigation(GridType.RootCause, {
                  state: {
                    id: FindingState.ACTIVE,
                    name: FindingState.ACTIVE,
                  },
                  ...businessUnitNavigationFilter,
                  ...findingTypeNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                });
              }}
            />
            <PostureWidgetCard
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.SIREN_ON_ICON} />}
              title={translation(`dashboards.widgets.totalRisk`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_RISKS}
              onClick={() => {
                navigationHandler.handleRiskNavigation(GridType.Risk, {
                  state: {
                    id: FindingState.ACTIVE,
                    name: FindingState.ACTIVE,
                  },
                  ...businessUnitNavigationFilter,
                  ...findingTypeNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                });
              }}
            />

            <PostureWidgetCard
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.BRAKE_WARNING_ICON} />}
              title={translation(`dashboards.widgets.pendingExceptions`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_PENDING_EXCEPTIONS}
              onClick={() => {
                navigationHandler.handleRiskNavigation(
                  GridType.None,
                  {
                    status: [
                      {
                        id: FindingImmutableStatus.PENDING_EXCEPTION,
                        name: FindingImmutableStatus.PENDING_EXCEPTION,
                      },
                    ],
                    ...businessUnitNavigationFilter,
                    ...findingTypeNavigationFilter,
                    ...scopeNavigationFilter,
                    ...groupNavigationFilter,
                  },
                  true
                );
              }}
            />
            <PostureWidgetCard
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_CHECK_ICON} />}
              title={translation(`dashboards.widgets.resolvedFindings`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_RESOLVED_FINDINGS}
              onClick={() => {
                navigationHandler.handleRiskNavigation(
                  GridType.None,
                  {
                    status: [
                      {
                        id: FindingStatus.RESOLVED,
                        name: FindingStatus.RESOLVED,
                      },
                    ],
                    ...businessUnitNavigationFilter,
                    ...findingTypeNavigationFilter,
                    ...scopeNavigationFilter,
                    ...groupNavigationFilter,
                  },
                  true
                );
              }}
            />
            <PostureWidgetCard
              icon={
                <OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON} />
              }
              title={translation(`dashboards.widgets.slaAttainmentRate`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_SLA}
              onClick={() => {
                navigationHandler.handleRiskNavigation(GridType.None, {
                  state: {
                    id: FindingState.ACTIVE,
                    name: FindingState.ACTIVE,
                  },
                  slaStatus: {
                    id: SlaStatus.BREACHED,
                    name: capitalize(SlaStatus.BREACHED),
                  },
                  ...businessUnitNavigationFilter,
                  ...findingTypeNavigationFilter,
                  ...scopeNavigationFilter,
                  ...groupNavigationFilter,
                });
              }}
            />
            <PostureWidgetCard
              icon={
                <OpusSvgIcon type={SVG_ICON_TYPES.SCREWDRIVER_WRENCH_ICON} />
              }
              title={translation(`dashboards.widgets.totalMttr`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_AVERAGE_MTTR}
            />
            <PostureWidgetCard
              icon={
                <OpusSvgIcon type={SVG_ICON_TYPES.TRIANGLE_EXCLAMATION_ICON} />
              }
              title={translation(`dashboards.widgets.activeExceptions`)}
              type={PostureWidgetAnalyticsType.ACCUMULATIVE_ACTIVE_EXCEPTIONS}
              onClick={() => {
                navigationHandler.handleRiskNavigation(
                  GridType.None,
                  {
                    status: [
                      {
                        id: FindingImmutableStatus.EXCEPTION,
                        name: FindingImmutableStatus.EXCEPTION,
                      },
                    ],
                    ...businessUnitNavigationFilter,
                    ...findingTypeNavigationFilter,
                    ...scopeNavigationFilter,
                    ...groupNavigationFilter,
                  },
                  true
                );
              }}
            />
          </div>

          <div className="operation-metrics-charts">
            <DashboardChartCard
              title={translation(`dashboards.widgets.findingsBySeverity`)}
              className="posture-dashboard-section-by-severity-chart-container"
            >
              {renderChartWidget(
                PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_SEVERITY,
                bySeverityWidgetData,
                bySeverityWidgetDataLoading
              )}
            </DashboardChartCard>
            <DashboardChartCard
              className="posture-dashboard-section-by-category-chart-container"
              title={translation(`dashboards.widgets.byCategory`)}
            >
              {renderChartWidget(
                PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_CATEGORY,
                byCategoryWidgetData,
                byCategoryWidgetDataLoading
              )}
            </DashboardChartCard>
          </div>
        </div>
      </DashboardSection>
      <DashboardSection
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.STAR_CHRISTMAS_ICON} />}
        title={translation(`dashboards.widgets.riskAssessment`)}
        filter={
          <EntitledContent flag={SystemFeatureFlag.DASHBOARD_SCORE_CARD_WIDGET}>
            <Autocomplete
              model=""
              onChangeCallBack={(model, option) => {
                selectTimeline(
                  (option as AutocompleteOption).value as TimelineFilter
                );
              }}
              classes={{
                root: 'operational-dashboard-section-timeline-filter-root',
                inputRoot:
                  'operational-dashboard-section-timeline-filter-input-root',
                input: 'operational-dashboard-section-timeline-filter-input',
                listbox:
                  'operational-dashboard-section-timeline-filter-listbox',
              }}
              optionList={operationalDashboardFilterOptions}
              single
              placeholder="Select Timeline"
              initialSelectedValues={{
                value: dashboardFilter.timeline,
                label: dashboardFilter.timeline,
              }}
            />
          </EntitledContent>
        }
      >
        {/* <EntitledContent flag={SystemFeatureFlag.DASHBOARD_SCORE_CARD_WIDGET}>
          <ScoreCardDomainWidget />
        </EntitledContent> */}
        <RiskAssessmentSDLCWidget scopeData={scopeData} groupData={groupData} />
      </DashboardSection>
      <DashboardSection
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_CHECK_ICON} />}
        title={translation(`dashboards.widgets.proactiveSecurity`)}
      >
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 3 }}>
          <Grid item xs={12}>
            <TopCampaignsTableWidget
              scopeData={scopeData}
              groupData={groupData}
            />
          </Grid>
          <Grid item xs={6}>
            <TopRiskOwnerTableWidget />
          </Grid>
          <Grid item xs={6}>
            <TopRootCausesTableWidget />
          </Grid>
        </Grid>
      </DashboardSection>
    </div>
  );
};

import OpusAvatarIcon from 'shared/components/IconComponents/OpusAvatarIcon';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import FindingEvent, { TimeLineActorTypes } from './interfaces/finding-event';
import * as React from 'react';

interface Props {
  event: FindingEvent;
}

export default function TimelineEventAvatar({ event }: Props) {
  return event.actorType === TimeLineActorTypes.USER ? (
    <OpusAvatarIcon
      className="actor-logo-container"
      src={event.actorLogo}
      title={event.actorName}
      sx={{ width: '24px', height: '24px' }}
    />
  ) : (
    <OpusImageIcon
      className="actor-logo-container"
      src={event.appLogo}
      imageClassName="system-logo-image"
      title={event.applicationId}
    />
  );
}

import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { InputLabelWrapper } from '../InputLabelWrapper/InputLabelWrapper';
import Autocomplete from 'Common/components/Autocomplete';
import { Button } from '@mui/material';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

export interface ConfigurationFieldAutocompleteOption
  extends AutocompleteOption {
  disabled?: boolean;
}

export type ConfigurationFieldValue = {
  values: Array<ConfigurationFieldAutocompleteOption>;
  disableDelete: boolean;
};

export interface MultiSelectConfigurationFieldProps extends BaseComponentProps {
  fieldLabels: Array<string>;
  fieldOptions: Array<Array<AutocompleteOption>>;
  values?: Array<ConfigurationFieldValue>;
  onChange?: (configurationValues: Array<ConfigurationFieldValue>) => void;
  classes?: {
    inputRoot?: string;
  };
  getIconMethods?: {
    [key: string]: (iconUrl: string) => JSX.Element;
  };
  defaultDisabledOptions?: string[];
  addLabel?: string | React.ReactElement;
  ids?: Array<string>;
  isInputDisabled?: boolean;
}

export const configurationFieldDefaultValues: Array<ConfigurationFieldValue> = [
  {
    values: [
      { value: '', label: '' },
      { value: '', label: '' },
    ],
    disableDelete: false,
  },
];

export const MultiSelectConfigurationField: FunctionComponent<
  MultiSelectConfigurationFieldProps
> = ({
  fieldLabels,
  fieldOptions,
  values,
  onChange,
  classes = {},
  getIconMethods,
  ids = [],
}) => {
  const [configurationValues, setConfigurationValues] = useState<
    Array<ConfigurationFieldValue>
  >(values?.length ? values : configurationFieldDefaultValues);

  const onAddConfiguration = () => {
    setConfigurationValues((oldConfigurationValues) => {
      return [...oldConfigurationValues, configurationFieldDefaultValues[0]];
    });
  };

  const onDeleteConfiguration = (index: number) => {
    setConfigurationValues((oldConfigurationValues) => {
      if (oldConfigurationValues.length === 1) {
        return configurationFieldDefaultValues;
      }

      return oldConfigurationValues.filter(
        (configurationValue, idx: number) => idx !== index
      );
    });
  };

  const onMultiSelectChangeCallback = (
    rowIndex: number,
    itemIndex: number,
    option: AutocompleteOption
  ) => {
    if (option) {
      setConfigurationValues((oldConfigurationValues) => {
        const updatedConfigurationValues = [...oldConfigurationValues];

        return updatedConfigurationValues.map(
          (configurationValue, index: number) => {
            if (index === rowIndex) {
              const configurationValues = configurationValue.values.map(
                (valueItem, index: number) => {
                  if (index === itemIndex) {
                    return {
                      ...option,
                      id: valueItem.id,
                    };
                  }

                  return valueItem;
                }
              );

              return {
                values: configurationValues,
                disableDelete: configurationValue.disableDelete,
              };
            }

            return configurationValue;
          }
        ) as Array<ConfigurationFieldValue>;
      });
    }
  };

  useEffect(() => {
    if (onChange) {
      const filteredConfigurationValues = configurationValues.filter(
        (configurationValue) => configurationValue.values[0]?.value?.length
      );

      onChange(filteredConfigurationValues);
    }
  }, [configurationValues]);

  const getIcon = (field: string, option: AutocompleteOption) => {
    if (getIconMethods && getIconMethods[field]) {
      return getIconMethods[field](option.iconUrl as string);
    }

    return <></>;
  };

  return (
    <div className="multi-select-configuration">
      <div className="multi-select-configuration-row">
        <InputLabelWrapper label={fieldLabels[0]}>
          <Autocomplete
            model={fieldLabels[0]}
            optionList={fieldOptions[0]}
            onChangeCallBack={(
              model: string,
              option: AutocompleteOption | Array<AutocompleteOption>
            ) => {
              const singleOption = option as AutocompleteOption;
              onMultiSelectChangeCallback(0, 0, singleOption);
            }}
            classes={{
              root: 'multi-select-field-1',
              paper: 'multi-select-field-paper-1',
              ...classes,
            }}
            single
            values={
              configurationValues[0]
                ? configurationValues[0].values[0]
                : undefined
            }
            initialSelectedValues={
              configurationValues[0]
                ? configurationValues[0].values[0]
                : undefined
            }
            getIcon={(option: AutocompleteOption) =>
              getIcon(fieldLabels[0], option)
            }
            enableCheckbox
            readonly={configurationValues[0]?.values[0]?.disabled}
            id={ids[0]}
          />
        </InputLabelWrapper>

        <InputLabelWrapper label={fieldLabels[1]}>
          <Autocomplete
            model={fieldLabels[1]}
            optionList={fieldOptions[1]}
            classes={{
              root: 'multi-select-field-1',
              paper: 'multi-select-field-paper-1',
              ...classes,
            }}
            single
            onChangeCallBack={(
              model: string,
              option: AutocompleteOption | Array<AutocompleteOption>
            ) => {
              const singleOption = option as AutocompleteOption;
              onMultiSelectChangeCallback(0, 1, singleOption);
            }}
            values={
              configurationValues[0]
                ? configurationValues[0].values[1]
                : undefined
            }
            initialSelectedValues={
              configurationValues[0]
                ? configurationValues[0].values[1]
                : undefined
            }
            getIcon={(option: AutocompleteOption) =>
              getIcon(fieldLabels[1], option)
            }
            enableCheckbox
            readonly={configurationValues[0]?.values[1]?.disabled}
            id={ids[1]}
          />
        </InputLabelWrapper>

        <Button
          onClick={() => {
            onDeleteConfiguration(0);
          }}
          className="base-opus-text-button multi-select-configuration-button"
          disabled={
            (configurationValues[0].values[0]?.value.length === 0 &&
              configurationValues[0].values[1]?.value.length === 0) ||
            configurationValues[0].disableDelete
          }
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
          <span>Delete</span>
        </Button>
      </div>

      {configurationValues.slice(1).map((configurationValue, index: number) => (
        <div className="multi-select-configuration-row">
          <Autocomplete
            model={fieldLabels[0]}
            optionList={fieldOptions[0]}
            onChangeCallBack={(
              model: string,
              option: AutocompleteOption | Array<AutocompleteOption>
            ) => {
              const singleOption = option as AutocompleteOption;
              onMultiSelectChangeCallback(index + 1, 0, singleOption);
            }}
            classes={{
              root: 'multi-select-field-1',
              paper: 'multi-select-field-paper-1',
            }}
            single
            values={
              configurationValues[index + 1]
                ? configurationValues[index + 1].values[0]
                : undefined
            }
            initialSelectedValues={
              configurationValues[index + 1]
                ? configurationValues[index + 1].values[0]
                : undefined
            }
            getIcon={(option: AutocompleteOption) =>
              getIcon(fieldLabels[0], option)
            }
            enableCheckbox
            readonly={configurationValues[index + 1]?.values[0]?.disabled}
            id={ids[0] ? `${ids[0]}-${index}` : undefined}
          />

          <Autocomplete
            model={fieldLabels[1]}
            optionList={fieldOptions[1]}
            onChangeCallBack={(
              model: string,
              option: AutocompleteOption | Array<AutocompleteOption>
            ) => {
              const singleOption = option as AutocompleteOption;
              onMultiSelectChangeCallback(index + 1, 1, singleOption);
            }}
            classes={{
              root: 'multi-select-field-1',
              paper: 'multi-select-field-paper-1',
            }}
            single
            values={
              configurationValues[index + 1]
                ? configurationValues[index + 1].values[1]
                : undefined
            }
            initialSelectedValues={
              configurationValues[index + 1]
                ? configurationValues[index + 1].values[1]
                : undefined
            }
            getIcon={(option: AutocompleteOption) =>
              getIcon(fieldLabels[1], option)
            }
            enableCheckbox
            readonly={configurationValues[index + 1]?.values[1]?.disabled}
            id={ids[1] ? `${ids[1]}-${index}` : undefined}
          />
          <Button
            onClick={() => {
              onDeleteConfiguration(index + 1);
            }}
            disabled={configurationValues[index + 1].disableDelete}
            className="base-opus-text-button multi-select-configuration-button"
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
            <span>Delete</span>
          </Button>
        </div>
      ))}

      <div className="multi-select-configuration-button">
        <Button
          className="base-opus-text-button multi-select-configuration-button multi-select-configuration-add-button"
          onClick={onAddConfiguration}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />

          <span>Add New</span>
        </Button>
      </div>
    </div>
  );
};

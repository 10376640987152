import { convertMarkdownToTextWithLink } from 'Common/utils/utilsFunction';
import { FunctionComponent } from 'react';
import { CommonButton } from 'shared/components/CommonButton';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface IntegrationModalContentWrapperProps extends BaseComponentProps {
  description?: string;
  tutorialLink?: string;
  tutorialName?: string;
}

export const IntegrationModalContentWrapper: FunctionComponent<
  IntegrationModalContentWrapperProps
> = ({ description = '', tutorialLink, tutorialName, children }) => {
  const renderDescription = () => {
    if (!description) return;
    const desc = convertMarkdownToTextWithLink(description);
    return (
      <div
        className="integration-modal-content-description"
        dangerouslySetInnerHTML={{
          __html: desc,
        }}
      />
    );
  };
  return (
    <div className="integration-modal-content-wrapper">
      {renderDescription()}
      {tutorialLink ? (
        <div className="integration-modal-content-tutorial-link">
          <OpusSvgIcon type={SVG_ICON_TYPES.BOOK_SPARKLES_ICON} />

          <a href={tutorialLink} target="_blank">
            {tutorialName}
          </a>
        </div>
      ) : (
        <></>
      )}

      <div className="integration-modal-content-body">{children}</div>
    </div>
  );
};

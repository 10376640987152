import { isEqual, sortBy } from 'lodash';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { DropdownProps } from 'shared/components/DataFilters/CategoryDropdown/CategoryDropdown';
import {
  LabelManagementDropdown,
  SystemLabelType,
} from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';
import { useLabelData } from 'shared/hooks/useLabelData';
import { useLabelSelection } from 'shared/hooks/useLabelSelection';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useSaveLabelsMutation } from 'shared/store/api';

interface ItemLabelDropdownProps extends BaseComponentProps, DropdownProps {
  itemId: string;
  itemType: SystemLabelType;
  saveSuccessCallback: () => void;
}

export const ItemLabelDropdown: FunctionComponent<ItemLabelDropdownProps> = ({
  anchorEl,
  anchorOrigin,
  handleClose,
  open,
  itemId,
  itemType,
  saveSuccessCallback,
}) => {
  const [
    saveActiveLabelItems,
    {
      isLoading: savingActiveLabelItems,
      isSuccess: savingActiveLabelItemsSuccess,
      isError: savingActiveLabelItemsError,
    },
  ] = useSaveLabelsMutation();

  const {
    activeLabels,
    addLabel,
    allLabels,
    filteredAllLabels,
    filteredRecentLabels,
    loading,
    recentLabels,
    searchTerm,
    setSearchTerm,
    refetch,
    selectLabel,
    selectedLabels,
    setSelectedLabels,
  } = useLabelData({
    itemId,
    itemType,
    shouldFetch: open,
  });

  const displaySaveButton = useMemo<boolean>(() => {
    const selectedLabelsList = selectedLabels.map(
      (selectedLabelItem) => selectedLabelItem.label
    );

    const activeLabelsList = activeLabels.map(
      (activeLabelItem) => activeLabelItem.label
    );

    return (
      !loading && !isEqual(sortBy(selectedLabelsList), sortBy(activeLabelsList))
    );
  }, [selectedLabels, activeLabels, loading]);

  const saveLabelsHandler = () => {
    const items =
      selectedLabels?.map((selectedLabel) => ({
        itemId: itemId,
        itemType: itemType,
        label: selectedLabel.label,
      })) || [];

    saveActiveLabelItems({
      items,
      itemId,
      itemType,
    });
  };

  useEffect(() => {
    if (savingActiveLabelItemsSuccess) {
      saveSuccessCallback();
    }
  }, [savingActiveLabelItemsSuccess]);

  return (
    <LabelManagementDropdown
      open={Boolean(anchorEl)}
      handleClose={handleClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      addLabel={addLabel}
      displaySaveButton={displaySaveButton}
      filteredAllLabels={filteredAllLabels}
      filteredRecentLabels={filteredRecentLabels}
      loading={loading}
      onLabelSelect={selectLabel}
      refetch={refetch}
      saveLabels={saveLabelsHandler}
      savingLabels={savingActiveLabelItems}
      savingLabelsSuccess={savingActiveLabelItemsSuccess}
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      selectedLabels={selectedLabels}
    />
  );
};

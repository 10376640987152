import { FunctionComponent } from 'react';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { BaseIntegrationModal } from '../BaseIntegrationModal/BaseIntegrationModal';
import { IntegrationRepositoryTable } from '../IntegrationRepositoryTable/IntegrationRepositoryTable';

interface GithubIntegrationModalProps extends CommonIntegrationModalProps {}

export const GithubIntegrationModal: FunctionComponent<
  GithubIntegrationModalProps
> = (props) => {
  return <BaseIntegrationModal {...props} />;
};

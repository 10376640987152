import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import ItemWithIcon from 'Common/components/ItemWithIcon';
import RiskChip from 'Risk/components/RiskChip';
import AvatarBox from 'shared/components/AvatarBox';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import { CommunicationApp } from 'Settings/components/TeamsList/enums/communication-app.enum';
import { BusinessUnitAssociationRule } from 'Settings/interfaces/BusinessUnit';
import { BusinessUnitRuleTypeEnum } from 'shared/models/data/extended-business-unit.model';

export interface OrganizationNodeContentProps extends OrganizationNode {
  parentElementId?: string;
}
export const OrganizationNodeContent: React.FC<
  OrganizationNodeContentProps
> = ({
  id,
  name,
  children,
  parentId,
  accessibleDataNodes,
  accessibleLogicalNodes,
  accessibleScopesNodes,
  allowedScopes,
  type,
  metadata,
  parentElementId,
}: OrganizationNodeContentProps) => {
  const renderIconWithTitle = (
    title: string | number,
    iconType: SVG_ICON_TYPES,
    className?: string
  ) => (
    <div className="node-content-item">
      <ItemWithIcon
        title={title}
        icon={<OpusSvgIcon type={iconType} className={className} />}
        maxWidth={200}
        missingTitleKey={''}
      />
    </div>
  );

  const resourceTagsCount = useMemo<number>(() => {
    if (type === OrganizationNodeType.DATA) {
      const resourceTags = metadata?.buMetadata?.associationRules?.find(
        (associationRule: BusinessUnitAssociationRule) =>
          associationRule.type === BusinessUnitRuleTypeEnum.RESOURCE_TAGS
      );

      return resourceTags?.rules?.length || 0;
    }

    return 0;
  }, [metadata?.buMetadata]);

  const getAppIcon = () => {
    let icon = null;
    if (metadata.communicationAppId === ApplicationTypes.SLACK)
      icon = SVG_ICON_TYPES.SLACK_MAIN_ICON;
    if (metadata.communicationAppId === ApplicationTypes.MICROSOFT_TEAMS)
      icon = SVG_ICON_TYPES.MICROSOFT_TEAMS_ICON;
    if (!icon) return;
    const title =
      CommunicationApp[
        metadata.communicationAppId as keyof typeof CommunicationApp
      ];

    return renderIconWithTitle(title, icon, 'app-icon');
  };

  return (
    <Box className="organization-node-content">
      <div
        className="node-content-item"
        id={parentElementId ? `${parentElementId}-avatar` : undefined}
      >
        <AvatarBox
          name={metadata?.ownerMetadata?.name || 'Unassigned'}
          profileUrl={metadata?.ownerMetadata?.profilePictureUrl}
        ></AvatarBox>
      </div>
      <div id={parentElementId ? `${parentElementId}-application` : undefined}>
        {getAppIcon()}
      </div>
      {type !== OrganizationNodeType.DATA && (
        <>
          {accessibleScopesNodes.length > 0 && (
            <div
              id={
                parentElementId ? `${parentElementId}-scope-count` : undefined
              }
            >
              {renderIconWithTitle(
                accessibleScopesNodes.length,
                SVG_ICON_TYPES.CROSSHAIRS_ICON
              )}
            </div>
          )}
          {accessibleLogicalNodes.length > 0 && (
            <div
              id={
                parentElementId ? `${parentElementId}-group-count` : undefined
              }
            >
              {renderIconWithTitle(
                accessibleLogicalNodes.length,
                SVG_ICON_TYPES.GROUP_WITH_PEOPLE_ICON
              )}
            </div>
          )}
          {accessibleDataNodes.length > 0 && (
            <div
              id={
                parentElementId ? `${parentElementId}-service-count` : undefined
              }
            >
              {renderIconWithTitle(
                accessibleDataNodes.length,
                SVG_ICON_TYPES.BRIEFCASE_ICON
              )}
            </div>
          )}
        </>
      )}
      {type === OrganizationNodeType.DATA && (
        <>
          {metadata?.buMetadata?.associatedRepos.length > 0 && (
            <div
              id={
                parentElementId
                  ? `${parentElementId}-service-repo-count`
                  : undefined
              }
            >
              {renderIconWithTitle(
                metadata?.buMetadata?.associatedRepos.length,
                SVG_ICON_TYPES.DATABASE_ICON
              )}
            </div>
          )}{' '}
          {metadata?.buMetadata?.associatedAccounts?.length > 0 && (
            <div
              id={
                parentElementId
                  ? `${parentElementId}-service-account-count`
                  : undefined
              }
            >
              {renderIconWithTitle(
                metadata?.buMetadata?.associatedAccounts?.length,
                SVG_ICON_TYPES.CLOUD_ICON
              )}
            </div>
          )}
          {resourceTagsCount ? (
            <div
              id={
                parentElementId
                  ? `${parentElementId}-service-resource-tags-count`
                  : undefined
              }
            >
              {renderIconWithTitle(resourceTagsCount, SVG_ICON_TYPES.TAGS_ICON)}{' '}
            </div>
          ) : (
            <></>
          )}
        </>
      )}
      {type === OrganizationNodeType.DATA && metadata.severity !== undefined && (
        <div
          className="node-content-item"
          id={parentElementId ? `${parentElementId}-risk-score` : undefined}
        >
          <span className="body-1">Risk Score</span>
          <RiskChip riskScore={metadata.severity} />
        </div>
      )}
    </Box>
  );
};

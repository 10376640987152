import ConnectionItem, {
  initBulk,
} from 'Integrations/Connection/interfaces/item';

const connectionItemsFixture: ConnectionItem[] = initBulk([
  {
    id: 'connection-1',
    name: 'Connection One',
  },
  {
    id: 'connection-2',
    name: 'Connectin Two',
  },
  {
    id: 'connection-3',
    name: 'Connection Three',
  },
]);

export default connectionItemsFixture;

import * as React from 'react';
import {
  Stack,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CloudWorkspaceDialogType } from '../../../Settings/interfaces/CloudWorkspaceType.enum';

export interface OpusSimpleMenuItem {
  id: string;
  label: string;
  cloud_workspace_type: CloudWorkspaceDialogType;
}

export interface OpusSimple {
  buttonLabel: React.ReactElement;
  items?: Array<OpusSimpleMenuItem>;
  onSelect: (item: OpusSimpleMenuItem) => void;
  itemsLoading?: boolean;
  buttonClassName?: string;
  disabled?: boolean;
}

export const OpusSimpleMenu: React.FC<OpusSimple> = ({
  buttonLabel,
  items,
  onSelect,
  itemsLoading,
  buttonClassName = 'opus-primary-button',
  disabled = false,
}: OpusSimple) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemSelect = (event: any, item: OpusSimpleMenuItem) => {
    setAnchorEl(null);
    onSelect(item);
  };

  return (
    <div>
      <Button
        id="basic-button"
        className={buttonClassName}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disabled={disabled}
      >
        <AddIcon fontSize="small" />
        <Box ml={1} />
        {buttonLabel}
      </Button>
      {itemsLoading ? (
        <Stack flexDirection="row" justifyContent="center">
          <CircularProgress />
        </Stack>
      ) : items?.length && !disabled ? (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {items?.map((item: OpusSimpleMenuItem) => (
            <MenuItem
              key={item.id}
              onClick={(event) => handleItemSelect(event, item)}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      ) : (
        <div></div>
      )}
    </div>
  );
};

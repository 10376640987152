import { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export interface IconContainerProps extends BaseComponentProps {
  icon: string | ReactNode;
  name?: string;
  badge?: number | string;
  innerRef?: any;
}

export const IconContainer: FunctionComponent<IconContainerProps> = ({
  name,
  icon,
  badge,
  innerRef,
}) => {
  return (
    <div className="icon-button-container" ref={innerRef}>
      {typeof icon === 'string' ? (
        <img className="icon-button-item" src={icon} alt={name} />
      ) : (
        icon
      )}
      {badge ? <div className="icon-button-badge">{badge}</div> : <></>}
    </div>
  );
};

import React from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import TimelineComponent from 'Common/components/TimelineComponent/TimelineComponent';
import IntelligenceThreatActorsItem, {
  FindingContentIntelligenceDiscussionsItem,
} from './FindingContentIntelligenceDiscussionsItem';
import { VulnerabilityMetadataDiscussion } from 'shared/models/data/vulnerability.model';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';

interface FindingContentIntelligenceDiscussionsProps
  extends BaseComponentProps {
  discussions: Array<VulnerabilityMetadataDiscussion>;
}

export const FindingContentIntelligenceDiscussions: React.FC<
  FindingContentIntelligenceDiscussionsProps
> = ({ discussions }) => {
  const { t: translation } = useTranslation();

  const renderBody = () => {
    if (discussions.length === 0)
      return <NoDataToDisplayCard displayDescription={false} />;

    return (
      <TimelineComponent
        data={discussions}
        // isLoading={isLoading}
        noEventsMessageId="findings.details.noEvents"
        noEventsDefaultMessage="No events"
        renderEvent={renderEvent}
        isLoading={false}
      />
    );
  };

  const renderEvent = (
    event: VulnerabilityMetadataDiscussion,
    index: number,
  ) => <FindingContentIntelligenceDiscussionsItem key={index} {...event} />;

  return (
    <div className=" finding-content-item finding-discussions-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-section-discussions-actors"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.MESSAGES_ICON} />}
        title={
          discussions?.length === 10
            ? translation(`findings.sections.discussionsLast10`)
            : translation(`findings.sections.discussions`)
        }
      >
        {renderBody()}
      </ContentSection>
    </div>
  );
};

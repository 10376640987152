import { useEffect, useState } from 'react';
import { FunctionComponent, useMemo } from 'react';
import {
  BusinessUnitCloudAccount,
  BusinessUnitCodeRepository,
  BusinessUnitRuleTypeEnum,
  BusinessUnitStakeholder,
} from 'shared/models/data/extended-business-unit.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ContentSection } from '../ContentSection/ContentSection';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BusinessImpactChip } from '../BusinessImpactChip/BusinessImpactChip';
import {
  BusinessUnitComplianceRequirements,
  BusinessUnitImpact,
  BusinessUnitRiskAttribute,
} from 'shared/enums/business-unit.enum';
import { AvatarBox } from '../AvatarBox/AvatarBox';
import { InteractiveLabel } from '../InteractiveLabel/InteractiveLabel';
import { CheckboxLabel } from '../CheckboxLabel/CheckboxLabel';
import { IconBox } from '../IconBox/IconBox';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import {
  useFetchBusinessUnitQuery,
  useDeleteBusinessUnitMutation,
} from 'Settings/store/api';
import { AuthorizedContent } from '@frontegg/react';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { CommunicationApp } from 'Settings/components/TeamsList/enums/communication-app.enum';
import { useTranslation } from 'react-i18next';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import CommonIconMui from 'Common/components/IconMui/Icon';
import { ActionResponseModal } from 'Risk/components/ActionResponseModal';

interface BusinessUnitViewPanelProps extends BaseComponentProps {
  onEditClick?: () => void;
  onItemDeleted?: () => void;
}

export const BusinessUnitViewPanel: FunctionComponent<
  BusinessUnitViewPanelProps
> = ({ onEditClick, onItemDeleted }) => {
  const { t: translation } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);
  const [failureModalOpen, setFailureModalOpen] = useState<boolean>(false);

  const [searchParams] = useQueryParams();

  const { data: businessUnit, isFetching: businessUnitLoading } =
    useFetchBusinessUnitQuery(searchParams.businessUnitId, {
      skip: searchParams.businessUnitId?.length === 0,
      refetchOnMountOrArgChange: true,
    });

  const [
    deleteBusinessUnit,
    { data: deleteBUResponse, isLoading: deletingBusinessUnit },
  ] = useDeleteBusinessUnitMutation();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const navigateToEditPage = () => {
    onEditClick && onEditClick();
  };

  useEffect(() => {
    if (deleteBUResponse?.success == true) {
      setConfirmDialogOpen(false);
      onItemDeleted && onItemDeleted();
    }
    if (deleteBUResponse?.success == false) {
      setConfirmDialogOpen(false);
      setFailureModalOpen(true);
    }
  }, [deleteBUResponse]);

  const handleDelete = () => {
    deleteBusinessUnit(searchParams.businessUnitId);
  };

  const handleDeleteWithConfirm = () => {
    setConfirmDialogOpen(true);
  };

  const resourceTags = useMemo(() => {
    return (
      businessUnit?.associationRules?.find(
        (rule) => rule.type === BusinessUnitRuleTypeEnum.RESOURCE_TAGS
      )?.rules || []
    );
  }, [businessUnit]);

  return businessUnitLoading ? (
    <div className="business-unit-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="business-unit-view-panel">
      <div className="business-unit-view-panel-header">
        <div className="business-unit-view-panel-title">
          <div className="business-unit-view-panel-title-text">
            {businessUnit?.name}
          </div>
          <Box
            className="business-unit-view-panel-impact"
            display="flex"
            alignItems={'center'}
            columnGap={1}
          >
            <Grid item display="flex" columnGap={1}>
              <BusinessImpactChip
                impact={
                  businessUnit?.businessUnitImpact || BusinessUnitImpact.LBI
                }
              />
            </Grid>
            {businessUnit?.orgStructureInfo?.groupInfo?.name && (
              <Grid item display={'flex'} alignItems={'center'} columnGap={1}>
                <OpusSvgIcon type={SVG_ICON_TYPES.CROSSHAIRS_ICON} />
                <Typography variant="inherit" className="body-1">
                  {businessUnit?.orgStructureInfo?.scopeInfo?.name}
                </Typography>
              </Grid>
            )}
            {businessUnit?.orgStructureInfo?.groupInfo?.name && (
              <Grid item display={'flex'} alignItems={'center'} columnGap={1}>
                <OpusSvgIcon type={SVG_ICON_TYPES.GROUP_WITH_PEOPLE_ICON} />
                <Typography variant="inherit" className="body-1">
                  {businessUnit?.orgStructureInfo?.groupInfo?.name}
                </Typography>
              </Grid>
            )}
          </Box>
        </div>
        <div>
          <AuthorizedContent
            requiredPermissions={[ApplicationPermission.SETTINGS_BUS_WRITE]}
            render={(isAuthorized) => {
              return (
                <>
                  <Button
                    aria-controls={open ? 'node-action-menu' : undefined}
                    className="body-1 business-unit-view-panel-header-menu-button"
                    classes={{ root: 'custom-root' }}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleMenuClick}
                  >
                    <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_STROKE_ICON} />
                  </Button>
                  <Menu
                    id="node-action-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={(e: React.MouseEvent<HTMLElement>) => {
                      handleClose(e);
                    }}
                    classes={{ paper: 'node-action-menu-container' }}
                    MenuListProps={{
                      'aria-labelledby': 'node-action-menu',
                    }}
                  >
                    <MenuItem
                      disabled={!isAuthorized}
                      onClick={(event) => {
                        navigateToEditPage();
                        handleClose(event);
                      }}
                    >
                      <Button className="delete-action-button">
                        <Typography variant="inherit" className="body-1">
                          Edit
                        </Typography>
                      </Button>
                    </MenuItem>
                    <MenuItem
                      disabled={!isAuthorized}
                      onClick={(event) => {
                        handleDeleteWithConfirm();
                        handleClose(event);
                      }}
                    >
                      <Button className="delete-action-button">
                        <Typography variant="inherit" className="body-1">
                          Delete
                        </Typography>
                      </Button>
                    </MenuItem>
                  </Menu>
                </>
              );
            }}
          />
        </div>
        <div className="business-unit-view-panel-title-helper-text">
          {businessUnit?.description}
        </div>
      </div>
      <div className="business-unit-view-panel-body">
        <ContentSection
          title="Related Environments"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_NETWORK_ICON} />}
          rootClassName="business-unit-view-panel-section"
          iconClassName="business-unit-view-panel-section-icon"
        >
          <div className="business-unit-view-panel-section-body">
            <ContentSection
              title={`Code Repo (${businessUnit?.associatedRepos?.length})`}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.DATABASE_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.associatedRepos?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border business-unit-view-panel-sub-section-bottom-border business-unit-view-panel-code-repo`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.associatedRepos?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              <div className="business-unit-view-panel-code-repo-container">
                {businessUnit?.associatedRepos?.map(
                  (codeRepository: BusinessUnitCodeRepository) => {
                    return (
                      <InteractiveLabel
                        icon={
                          codeRepository.logoUrl?.length ? (
                            <img
                              height={18}
                              width={18}
                              src={codeRepository.logoUrl}
                            ></img>
                          ) : (
                            <></>
                          )
                        }
                        label={codeRepository.name || ''}
                      />
                    );
                  }
                )}
              </div>
            </ContentSection>
            <ContentSection
              title={`Runtime Environments (${businessUnit?.associatedAccounts?.length})`}
              icon={
                <OpusSvgIcon type={SVG_ICON_TYPES.LIFECYCLE_RUNTIME_ICON} />
              }
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.associatedAccounts?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border business-unit-view-panel-sub-section-bottom-border business-unit-view-panel-cloud-environments`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.associatedAccounts?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              <div className="business-unit-view-panel-cloud-environments-container">
                {businessUnit?.associatedAccounts?.map(
                  (cloudEnvironment: BusinessUnitCloudAccount) => {
                    return (
                      <IconBox
                        icon={
                          <div className="business-unit-view-panel-icon-container">
                            <img
                              src={cloudEnvironment.cloudProviderLogo}
                              alt={cloudEnvironment.alias}
                              className="business-unit-view-panel-icon"
                            />
                          </div>
                        }
                        helperText={cloudEnvironment.type}
                        label={cloudEnvironment.alias}
                      />
                    );
                  }
                )}
              </div>
            </ContentSection>

            <ContentSection
              title={translation(`settings.details.resourceTagsSectionTitle`)}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                resourceTags?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border business-unit-view-panel-sub-section-bottom-border  business-unit-view-panel-sub-section-tags-section`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                resourceTags?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              {resourceTags?.length ? (
                <div className="business-unit-view-panel-sub-section-tags-container">
                  {resourceTags.map((rule) => (
                    <div className="business-unit-view-panel-sub-section-tag">
                      {rule.key} = {rule.value}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </ContentSection>
            <ContentSection
              title={`Linked Domains (${businessUnit?.associatedDomains?.length})`}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_CHECK_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.associatedDomains?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled business-unit-view-panel-remove-body'
              } business-unit-view-panel-sub-section-linked-domains`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.associatedDomains?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              <div className="business-unit-view-panel-sub-section-linked-domains-container">
                {businessUnit?.associatedDomains?.map(
                  (linkedDomain: string, index) => {
                    return (
                      <span>
                        <span className="business-unit-view-panel-sub-section-linked-domain-text">
                          {linkedDomain}
                        </span>
                        {index !==
                        (businessUnit?.associatedDomains?.length as number) -
                          1 ? (
                          <span>,</span>
                        ) : (
                          <></>
                        )}
                      </span>
                    );
                  }
                )}
              </div>
            </ContentSection>

            {/* <ContentSection
              title={`${translation(`settings.details.assetGroups`)} (${
                businessUnit?.assetGroups?.length
              })`}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_CHECK_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.assetGroups?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled business-unit-view-panel-remove-body'
              } business-unit-view-panel-sub-section-linked-domains`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.assetGroups?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              <div className="business-unit-view-panel-sub-section-linked-domains-container">
                {businessUnit?.assetGroups?.map(
                  (linkedDomain: string, index) => {
                    return (
                      <span>
                        <span className="business-unit-view-panel-sub-section-linked-domain-text">
                          {linkedDomain}
                        </span>
                        {index !==
                        (businessUnit?.assetGroups?.length as number) - 1 ? (
                          <span>,</span>
                        ) : (
                          <></>
                        )}
                      </span>
                    );
                  }
                )}
              </div>
            </ContentSection> */}
          </div>
        </ContentSection>

        <ContentSection
          title="Method of Communication"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.SATELLITE_DISH_ICON} />}
          rootClassName="business-unit-view-panel-section"
          iconClassName="business-unit-view-panel-section-icon"
        >
          <div className="business-unit-view-panel-section-body">
            <ContentSection
              title={`Stakeholders (${businessUnit?.stakeholders?.length})`}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.USER_GROUP_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.stakeholders?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border business-unit-view-panel-sub-section-bottom-border business-unit-view-panel-stakeholders`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.stakeholders?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              <div className="business-unit-view-panel-stakeholders-container">
                {businessUnit?.stakeholders?.map(
                  (stakeholder: BusinessUnitStakeholder) => (
                    <AvatarBox
                      name={stakeholder.fullName}
                      role={stakeholder.role}
                      profileUrl={stakeholder.avatarUrl}
                    />
                  )
                )}
              </div>
            </ContentSection>
            <div className="business-unit-view-panel-content-row">
              <ContentSection
                title={`Ticketing System`}
                icon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
                rootClassName={`business-unit-view-panel-sub-section ${
                  businessUnit?.ticketingSystems?.length
                    ? ''
                    : 'business-unit-view-panel-sub-section-disabled'
                } business-unit-view-panel-sub-section-no-border`}
                iconClassName={`business-unit-view-panel-sub-section-icon ${
                  businessUnit?.ticketingSystems?.length
                    ? ''
                    : 'business-unit-view-panel-sub-section-icon-disabled'
                }`}
              >
                {businessUnit?.ticketingSystems?.length ? (
                  <>
                    <InteractiveLabel
                      label={businessUnit?.ticketingSystems[0]?.name || ''}
                      link={businessUnit?.ticketingSystems[0].link}
                      icon={
                        businessUnit?.ticketingSystems[0]?.logoUrl?.length ? (
                          <img
                            height={18}
                            width={18}
                            src={businessUnit?.ticketingSystems[0]?.logoUrl}
                          ></img>
                        ) : (
                          <></>
                        )
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </ContentSection>

              <ContentSection
                title={translation(`settings.details.projectName`)}
                icon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
                rootClassName={`business-unit-view-panel-sub-section ${
                  businessUnit?.ticketingSystems?.length
                    ? ''
                    : 'business-unit-view-panel-sub-section-disabled'
                } business-unit-view-panel-sub-section-no-border`}
                iconClassName={`business-unit-view-panel-sub-section-icon ${
                  businessUnit?.ticketingSystems?.length
                    ? ''
                    : 'business-unit-view-panel-sub-section-icon-disabled'
                }`}
              >
                {businessUnit?.ticketingSystems?.length ? (
                  <>
                    <InteractiveLabel
                      label={
                        businessUnit?.ticketingSystems[0]?.projectName || ''
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </ContentSection>
            </div>

            <ContentSection
              title={`Communication Application`}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.SATELLITE_DISH_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.communicationAppId
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border business-unit-view-panel-sub-section-bottom-border`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.communicationAppId
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              <div>
                <InteractiveLabel
                  label={
                    CommunicationApp[
                      businessUnit?.communicationAppId as keyof typeof CommunicationApp
                    ] || ''
                  }
                />
              </div>
            </ContentSection>
            <ContentSection
              title={`Related Team`}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.PEOPLE_GROUP_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section business-unit-view-panel-sub-section-bottom-border ${
                businessUnit?.team
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border  business-unit-view-panel-sub-section-related-team`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.team
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              <div className="business-unit-view-panel-sub-section-related-team-container">
                {businessUnit?.team?.name}
              </div>
            </ContentSection>

            <ContentSection
              title={translation(`settings.details.ownerTagsSectionTitle`)}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.TAGS_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.tags?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled business-unit-view-panel-remove-body'
              } business-unit-view-panel-sub-section-no-border  business-unit-view-panel-sub-section-tags-section`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.tags?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              {businessUnit?.tags?.length ? (
                <div className="business-unit-view-panel-sub-section-tags-container">
                  {businessUnit?.tags?.split(',')?.map((tag) => (
                    <div className="business-unit-view-panel-sub-section-tag">
                      {tag}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </ContentSection>
          </div>
        </ContentSection>

        <ContentSection
          title="General Configuration"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.GEAR_ICON} />}
          rootClassName="business-unit-view-panel-section"
          iconClassName="business-unit-view-panel-section-icon"
        >
          <div className="business-unit-view-panel-section-body business-unit-view-panel-general-configuration-section-body">
            <ContentSection
              title={`Risk Attributes`}
              icon={
                <OpusSvgIcon type={SVG_ICON_TYPES.HEXAGON_EXCLAMATION_ICON} />
              }
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.riskAttributes?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border business-unit-view-panel-sub-section-bottom-border business-unit-view-panel-sub-section-risk-attributes`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.riskAttributes?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              {Object.values(BusinessUnitRiskAttribute).map((value) => {
                return (
                  <CheckboxLabel
                    checked={Boolean(
                      businessUnit?.riskAttributes?.includes(value)
                    )}
                    label={value}
                    disabled
                  />
                );
              })}
            </ContentSection>

            <ContentSection
              title={translation(`settings.details.complianceRequirements`)}
              icon={<OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_CHECK_ICON} />}
              rootClassName={`business-unit-view-panel-sub-section ${
                businessUnit?.riskAttributes?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-disabled'
              } business-unit-view-panel-sub-section-no-border business-unit-view-panel-sub-section-risk-attributes`}
              iconClassName={`business-unit-view-panel-sub-section-icon ${
                businessUnit?.riskAttributes?.length
                  ? ''
                  : 'business-unit-view-panel-sub-section-icon-disabled'
              }`}
            >
              {Object.values(BusinessUnitComplianceRequirements).map(
                (value) => {
                  return (
                    <CheckboxLabel
                      checked={Boolean(
                        businessUnit?.complianceRequirements?.includes(value)
                      )}
                      label={value}
                      disabled
                    />
                  );
                }
              )}
            </ContentSection>
          </div>
        </ContentSection>
      </div>

      <CommonDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        title={translation(`settings.details.deleteBusinessUnit`)}
        saveButtonText={'Delete'}
        onSave={handleDelete}
        isLoading={deletingBusinessUnit}
        loadingButtonText={'Deleting...'}
        icon={<CommonIconMui icon="delete" size={20} isOutlined />}
      >
        <Box minWidth={'450px'} mb={3}>
          <Typography variant="inherit" className="body-1" color={'#3d3d3d'}>
            {translation(`settings.details.deleteBusinessUnitConfirm`)}
          </Typography>
        </Box>
      </CommonDialog>

      <ActionResponseModal
        isOpen={failureModalOpen}
        handleClose={() => setFailureModalOpen(false)}
        title={translation(`settings.details.failedToDeleteBusinessUnit`)}
        listsDetails={[
          {
            warningMessage: translation(
              `settings.details.failedToDeleteBusinessUnitDescription`
            ),
            displayItems: [`${deleteBUResponse?.data}`],
            listTitle:
              translation(`common.reason`).charAt(0).toUpperCase() +
              translation(`common.reason`).slice(1),
            listTitleSuffix: '',
          },
        ]}
      />
    </div>
  );
};

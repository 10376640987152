import { useFetchUsersMutation } from 'Auth/store/api';
import { SolutionTypeEnum } from 'FindingDetails/interfaces/SolutionType.enum';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { useSearchFlowsMutation } from 'Flow/store/api';
import { useFetchTeamsMutation } from 'Settings/store/api';
import { useEffect, useMemo } from 'react';
import { TeamDataHandler } from 'shared/handlers/team-data.handler';
import { UserDataHandler } from 'shared/handlers/user-data.handler';

type NotificationSettingsOptionList = Record<
  'emailOptionList' | 'teamOptionList' | 'flowOptionList',
  { isLoading: boolean; options: Array<AutocompleteOption> }
>;

const userDataHandler = new UserDataHandler();
const teamDataHandler = new TeamDataHandler();

export function useFetchNotificationSettingsOptionItems(): NotificationSettingsOptionList {
  const [fetchUsers, { data: userList, isLoading: userListLoading }] =
    useFetchUsersMutation();
  const [fetchTeams, { data: teamList, isLoading: teamListLoading }] =
    useFetchTeamsMutation();
  const [searchFlows, { data: flowsPayload, isLoading: flowsPayloadLoading }] =
    useSearchFlowsMutation();

  useEffect(() => {
    fetchUsers();
    fetchTeams();
    searchFlows({
      filter: {
        solutionType: SolutionTypeEnum.USER,
      },
    });
  }, []);

  const userEmailList = useMemo<Array<AutocompleteOption>>(() => {
    if (userList) {
      return userDataHandler.transformUserListToAutocompleteEmailList(userList);
    }

    return [];
  }, [userList]);

  const teamOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (teamList) {
      return teamDataHandler.transformTeamListToAutocompleteTeamList(teamList);
    }

    return [];
  }, [teamList]);

  const flowOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (flowsPayload?.data) {
      return flowsPayload?.data.map((flow) => ({
        value: flow.familyId,
        label: flow.name,
      }));
    }

    return [];
  }, [flowsPayload]);

  return {
    emailOptionList: { isLoading: userListLoading, options: userEmailList },
    teamOptionList: { isLoading: teamListLoading, options: teamOptionList },
    flowOptionList: { isLoading: flowsPayloadLoading, options: flowOptionList },
  };
}

import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Button, Input, Snackbar, Stack, Typography } from '@mui/material';
import dialogStyles from '../Dialogs/GenericDialog/GenericDialog.module.css';
import useCommonSelector from '../../utils/use-selector';
import { useBumpCampaignMutation } from '../../../Campaigns/store/api';
import { AdvanceFilterHandler } from '../../../shared/handlers/advance-filter-data.handler';
import { useBumpFindingsMutation } from '../../../FindingDetails/store/api';
import classNames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import buttonStyles from '../Buttons/Buttons.module.css';
import FormattedMessage from '../../../shared/components/FormattedMessage';
import GenericDialog from '../Dialogs/GenericDialog';
import {
  GridType,
  getActiveGridTab,
  getGridFilterSelections,
} from 'Risk/store';

const advanceFilterDataHandler = new AdvanceFilterHandler();

export const Bump: FunctionComponent = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const activeTabId = useCommonSelector(getActiveGridTab);
  const advanceFilterSelections = useCommonSelector(getGridFilterSelections);
  const updatedSearchParams =
    advanceFilterDataHandler.translateAdvanceFilterComponentValuesToSearchParams(
      advanceFilterSelections
    );

  const days = useRef<HTMLInputElement>(null);

  const [
    bumpCampaign,
    { isLoading: isLoadingBumpCampaigns, isSuccess: isSuccessBumpCampaigns },
  ] = useBumpCampaignMutation();
  const [
    bumpFindings,
    { isLoading: isLoadingBumpFindings, isSuccess: isSuccessBumpFindings },
  ] = useBumpFindingsMutation();
  const isLoading = isLoadingBumpCampaigns || isLoadingBumpFindings;
  const isSuccess = isSuccessBumpCampaigns && isSuccessBumpFindings;

  useEffect(() => {
    if (isSuccess) handleDialogClose();
  }, [isSuccess]);

  function handleDialogOpen(e: any) {
    e.preventDefault();
    setDialogOpen(true);
  }

  function handleDialogClose() {
    setDialogOpen(false);
  }

  function handleSubmit() {
    if (days.current) {
      const daysValue: number = +days.current.value;
      bumpCampaign(daysValue);
      bumpFindings({
        days: daysValue,
        params: { filter: updatedSearchParams },
      });
      days.current.value = '';
    }
  }

  return activeTabId === GridType.None ? (
    <form onSubmit={handleDialogOpen}>
      <Stack direction="row" spacing={1}>
        <Box width="100">
          <Input
            className={dialogStyles.input}
            disableUnderline
            inputProps={{ required: true }}
            inputRef={days}
            type="number"
            sx={{ width: 75, height: 30 }}
          />
        </Box>
        <Button
          type="submit"
          className={classNames(['opus-filter-button add-filter-btn'])}
          sx={{ height: 30 }}
        >
          <span className="label-1" style={{ color: '#7547DC' }}>
            Bump
          </span>
        </Button>
      </Stack>
      <GenericDialog
        open={dialogOpen}
        onClose={() => handleDialogClose()}
        sx={{ minWidth: 400 }}
      >
        <Stack direction="column" alignItems="center">
          <Typography variant="h4" mb={1}>
            Are you sure?
          </Typography>
          <Typography variant="h6" fontWeight={400} textAlign="center">
            {Object.keys(updatedSearchParams).length === 0
              ? 'This bump will effect all findings'
              : 'This bump is based on a filter, are you sure you want to bump only filtered findings?'}
          </Typography>
          <Box mb={4} />
          {!isLoading && !isSuccess && (
            <Stack direction="row" spacing={2}>
              <Button
                className={`${buttonStyles.button} ${buttonStyles.regular}`}
                onClick={() => handleDialogClose()}
              >
                <FormattedMessage
                  capitalize
                  id="common.cancel"
                  defaultMessage="Cancel"
                />
              </Button>
              <Button
                className={`${buttonStyles.button} ${buttonStyles.actionButton}`}
                onClick={handleSubmit}
              >
                <FormattedMessage
                  capitalize
                  id="common.bump"
                  defaultMessage="Bump"
                />
              </Button>
            </Stack>
          )}
          {(isLoading || isSuccess) && (
            <Button
              disabled={true}
              className={`${buttonStyles.button} ${buttonStyles.regular}`}
            >
              <FormattedMessage
                capitalize
                id="common.bumping"
                defaultMessage="Bumping.."
              />
            </Button>
          )}
          <Box mb={4} />
        </Stack>
      </GenericDialog>
    </form>
  ) : (
    <></>
  );
};

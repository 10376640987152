import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { SystemLabel } from '../LabelManagementDropdown/LabelManagementDropdown';

interface LabelListProps extends BaseComponentProps {
  items: Array<SystemLabel>;
}

export const LabelList: FunctionComponent<LabelListProps> = ({ items }) => {
  return (
    <div className="label-list">
      {items.map((item) => (
        <div className="label-list-item" title={item.label}>
          {item.label}
        </div>
      ))}
    </div>
  );
};

export interface IntegrationRepo {
  id: string;
  displayName: string;
  selected: boolean;
}

export interface IntegrationRepoRequest {
  id: string;
  repos: Array<string>;
  selectAll: boolean;
}

export interface IntegrationRepoResponse {
  repos: IntegrationRepo[];
  selectAll: boolean;
}

export enum CloudProviderType {
  AWS = 'AWS',
  AZURE = 'Azure',
  GCP = 'GCP',
}

export enum IntegrationActionType {
  CREATING = 'CREATING',
  UPDATING = 'UPDATING',
  VERIFYING = 'VERIFYING',
}

export interface CloudProviderIntegration {
  id?: string;
  name: string;
  environmentType?: string;
}

export interface AwsIntegration extends CloudProviderIntegration {
  externalId: string;
  readRoleArn: string;
  scopeId?: string;
  writeRoleArn?: string;
  writeApplicationIds?: string[];
}

export interface AzureIntegration extends CloudProviderIntegration {
  azureTenantId: string;
  opusAppObjectId: string;
  azureSubscriptionId: string;
  writeApplicationIds?: string[];
  scopeId?: string;
}

export interface GCPIntegration extends CloudProviderIntegration {
  credJson: string;
  projectId: string;
  scopeId?: string;
  writeApplicationIds?: string[];
}

export interface AwsIntegrationVerify {
  awsAppId: string;
  externalId: string;
  readRoleArn: string;
  writeRoleArn?: string;
  writeApplicationIds?: string[];
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';
import { MenuItems, SelectionItems } from 'DashboardFilter/store';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import { ComparativeFilterType } from '../interfaces/Comparative';
import {
  comparativeDashboardFilter,
  ComparativeFilter,
} from '../interfaces/fixtures/data/comparative-dashboard-filter-option.data';

interface DashboardTypes {
  filter: ComparativeFilterType;
  listItems: MenuItems;
}

export const initialSelectedFilter: keyof typeof ComparativeFilter = 'group';
export const dashboardFilterInitialState: ComparativeFilterType = {
  selectedFindingsFilter: 'accumulative_open_findings_by_sla_status',
  selectedFilter: initialSelectedFilter,
  selectedScope: ['All'],
  selectedGroup: ['All'],
  selectedServices: ['All'],
  findingTypes: [],
};

const initialState: DashboardTypes = {
  filter: dashboardFilterInitialState,
  listItems: comparativeDashboardFilter,
};

export const comparativeSlice = createSlice({
  name: 'comparative',
  initialState,
  reducers: {
    setComparativeFilters: (
      state,
      action: PayloadAction<ComparativeFilterType>
    ) => {
      state.filter = action.payload;
    },
    setListFilter: (
      state,
      action: PayloadAction<
        MenuItems | { list: MenuItems; isSelectingItem: boolean }
      >
    ) => {
      const updatedSelections: SelectionItems = [];

      const listMenu = Array.isArray(action.payload)
        ? [...action.payload]
        : action.payload.list;
      const modifiedArray = listMenu.map((object) => {
        if (
          object.dateRange ||
          object.numberInput ||
          object.items.some((item: any) => item.checked)
        ) {
          updatedSelections.push({
            id: object.id,
            type: object.type as TypeFilter,
            selectionValues:
              object.dateRange ||
              object.numberInput ||
              object.items?.filter((item: any) => item.checked),
          });
        }

        return {
          ...object,
          items: object.items.map((item) => {
            if (!item.hasOwnProperty('checked')) {
              return {
                ...item,
                checked: false,
              };
            }
            return { ...item };
          }),
        };
      });

      state.listItems = modifiedArray;
    },
    setListItems: (state, action: PayloadAction<MenuItems>) => {
      state.listItems = action.payload;
    },
    setSelectedScope: (state, action: PayloadAction<string[]>) => {
      state.filter.selectedScope = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<string[]>) => {
      state.filter.selectedGroup = action.payload;
    },
    setSelectedServices: (state, action: PayloadAction<string[]>) => {
      state.filter.selectedServices = action.payload;
    },
    setSelectedFindingTypes: (state, action: PayloadAction<string[]>) => {
      state.filter.findingTypes = action.payload;
    },
  },
});

export const {
  setComparativeFilters,
  setListFilter,
  setListItems,
  setSelectedGroup,
  setSelectedScope,
  setSelectedServices,
  setSelectedFindingTypes,
} = comparativeSlice.actions;

export const selectComparativeFilters = (state: RootState) =>
  state.comparative.filter;

export const getFilterList = (state: RootState) => state.comparative.listItems;
export const getSelectedScope = (state: RootState) =>
  state.comparative.filter.selectedScope;
export const getSelectedGroup = (state: RootState) =>
  state.comparative.filter.selectedGroup;

const comparativeReducer = comparativeSlice.reducer;

export default comparativeReducer;

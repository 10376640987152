import { ColDef, ColGroupDef } from 'ag-grid-community';
import {
  CampaignState,
  CampaignStatus,
} from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import Status from 'Common/components/Status';
import moment from 'moment';
import RiskChip from 'Risk/components/RiskChip';
import { BusinesUnitCriticality } from 'Settings/components/BusinessUnitList/enums/business-unit-criticality.enum';
import AvatarBox from 'shared/components/AvatarBox';
import BusinessImpactChip from 'shared/components/BusinessImpactChip';
import CommonChip from 'shared/components/CommonChip';
import CommonIconCell from 'shared/components/CommonIconCell';
import { CommonDataGridUtilityCell } from 'shared/components/CommonSimpleDataGrid/enums/common-data-grid.enum';
import IconBox from 'shared/components/IconBox';
import { ResourceCell } from 'shared/components/ResourceCell/ResourceCell';
import { FilterItem } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import SlaChip from 'shared/components/SlaChip';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { BusinessUnitImpact } from 'shared/enums/business-unit.enum';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import {
  CampaignCriticality,
  CampaignOwner,
  CampaignType,
  CampaignStatus as NewCampaignStatus,
  SingleCampaignModel,
} from 'shared/models/data/campaigns.model';
import {
  ExtendedFilterCategory,
  FilterType,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import {
  lastTimelineEventColumn,
  lastTimelineEventVisibilityConfiguration,
} from './risk-grid.data';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import AvatarChip from 'shared/components/AvatarChip';
import { VisibilityControlColumn } from 'shared/components/CommonSimpleDataGrid/CommonSimpleDataGrid';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import CampaignGridActions from 'Campaigns/components/CampaignGridActions';
import { MouseEvent, MouseEventHandler } from 'react';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { dateTimeFormat } from 'shared/services/date.service';

export const campaignsFilterItems: Array<FilterItem> = [
  {
    id: 'state',
    name: 'State',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: CampaignState.ACTIVE,
        name: CampaignState.ACTIVE,
      },
      {
        id: CampaignState.NON_ACTIVE,
        name: CampaignState.NON_ACTIVE,
      },
    ],
    loaded: true,
  },
  {
    id: 'status',
    name: 'Status',
    type: TypeFilter.MULTI_SELECT,
    items: [
      {
        id: CampaignStatus.NEW,
        name: CampaignStatus.NEW,
      },
      {
        id: CampaignStatus.ACTIVE,
        name: CampaignStatus.ACTIVE,
      },
      {
        id: CampaignStatus.FINISHED,
        name: CampaignStatus.FINISHED,
      },
      {
        id: CampaignStatus.ARCHIVED,
        name: CampaignStatus.ARCHIVED,
      },
    ],
    loaded: true,
  },
  {
    id: 'businessPriority',
    name: 'Service Severity',
    type: TypeFilter.MULTI_SELECT,
    items: [
      {
        id: 'Low',
        name: 'Low',
      },
      {
        id: 'Moderate',
        name: 'Medium',
      },
      {
        id: 'High',
        name: 'High',
      },
      {
        id: 'Urgent',
        name: 'Urgent',
      },
    ],
    loaded: true,
  },
  {
    id: 'startTime',
    name: 'Time Frame',
    type: TypeFilter.DATE_PICKER_RANGE,
    items: [],
  },
  {
    id: 'assignees',
    name: 'Assigned Person',
    type: TypeFilter.MULTI_SELECT,
    items: [],
  },
  {
    id: 'days',
    name: 'Remaining Days',
    type: TypeFilter.NUMBER,
    loaded: true,
    items: [],
  },
];

export const campaignTableColumns: Array<ColDef | ColGroupDef> = [
  {
    field: 'name',
    headerName: 'Title',
    initialWidth: 300,
    comparator: () => 0,
    cellRenderer: (params: any) => {
      const name = params.data?.name;
      const status = params.data?.status as NewCampaignStatus;
      const type = params.data?.type as CampaignType;

      return (
        <div className="campaign-title-row">
          <div className="campaign-title-text" title={name}>
            {name}
          </div>

          <div className="campaign-title-icons">
            {type === CampaignType.LIVE ? (
              <>
                <div className="campaign-title-active">
                  <div className="campaign-title-active-inner-circle"></div>
                </div>
                <div className="campaign-title-live">LIVE</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    initialWidth: 150,
    comparator: () => 0,
    cellRenderer: (params: any) => {
      const status = params.data?.status;

      return <CommonChip label={status} />;
    },
  },
  {
    field: 'priority',
    headerName: 'Priority',
    initialWidth: 150,
    comparator: () => 0,
    cellRenderer: (params: any) => {
      const priority = params.data?.priority;

      return <BusinessImpactChip impact={priority} />;
    },
  },
  {
    field: 'ownerUserId',
    headerName: 'Owner',
    initialWidth: 200,
    comparator: () => 0,
    cellRenderer: (params: any) => {
      const owner = params.data.ownerUser as CampaignOwner;

      if (owner)
        return (
          <AvatarBox
            displayAvatar
            name={owner.name}
            role={owner.email}
            profileUrl={owner.profilePictureUrl}
          />
        );

      return '-';
    },
  },
  {
    field: 'remainingDays',
    headerName: 'Remaining Days',
    initialWidth: 170,
    comparator: () => 0,
    cellRenderer: (params: any) => {
      const remainingDays = params.data?.remainingDays;

      if (remainingDays === null || remainingDays === undefined) return '-';

      return remainingDays;
    },
  },
  {
    field: 'activeFindings',
    headerName: 'Active Findings',
    initialWidth: 150,
    comparator: () => 0,
    cellRenderer: (params: any) => {
      const activeFindings = params.data?.activeFindings;

      if (activeFindings === null || activeFindings === undefined) return '-';

      return activeFindings;
    },
  },
  {
    field: 'completionRate',
    headerName: 'Completion Rate',
    initialFlex: 1,
    comparator: () => 0,
    cellRenderer: (params: any) => {
      const completionRate = params.data?.completionRate || 0;

      const completionRatePercentage = Math.round(completionRate * 100);

      let className = '';

      if (completionRatePercentage >= 0 && completionRatePercentage < 50) {
        className = 'business-impact-high';
      } else if (
        completionRatePercentage >= 40 &&
        completionRatePercentage < 80
      ) {
        className = 'business-impact-medium';
      } else {
        className = 'business-impact-low';
      }

      return (
        <div
          className={`business-impact-chip ${className}`}
        >{`${completionRatePercentage}%`}</div>
      );
    },
  },

  {
    field: 'actions',
    headerName: '',
    sortable: false,
    resizable: false,
    headerClass: 'column-disable-sort',
    width: 100,
    cellRenderer: (params: any) => {
      return (
        <div>
          <CampaignGridActions
            campaignData={params.data}
            onStatusChange={(status) => {
              const rowData = params.api.getDisplayedRowAtIndex(
                params.rowIndex
              ).data;

              const updatedData = { ...rowData, status: status };

              params.node.setData(updatedData);
            }}
            onEditSuccess={(data: SingleCampaignModel) => {
              const rowData = params.api.getDisplayedRowAtIndex(
                params.rowIndex
              ).data;

              const updatedData = { ...rowData, ...data };

              params.node.setData(updatedData);
            }}
            onDeleteSuccess={() => {
              params.context?.resetCampaigns &&
                params.context?.resetCampaigns();
            }}
            enableEdit
          />
        </div>
      );
    },
  },
];

export const campaignFindingGridColumns: Array<ColDef | ColGroupDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
  },
  {
    field: 'title',
    headerName: 'Risk Name',
    sortable: true,
    width: 250,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="cell-text-renderer"
        >
          {params?.data?.title}
        </TextOverflow>
      );
    },
  },
  {
    field: 'riskScore',
    headerName: 'Score',
    sortable: true,
    cellRenderer: (params: any) => {
      return <RiskChip riskScore={params?.data?.riskScore} />;
    },
    width: 180,
  },
  {
    field: 'slaBreachDate',
    headerName: 'SLA',
    width: 100,
    cellRenderer: (params: any) => {
      return <SlaChip isUtc slaDate={params.data?.slaBreachDate} />;
    },
  },
  {
    field: 'resourceName',
    headerName: 'Resource Name',
    sortable: true,
    cellRenderer: (params: any) => {
      return (
        <ResourceCell
          type={params.data?.resourceType}
          name={params?.data?.resourceName}
          iconUrl={params?.data?.resourceTypeLogo}
        />
      );
    },
    width: 300,
  },
  {
    field: 'businessUnitName',
    headerName: 'Service',
    sortable: true,
    cellRenderer: (params: any) => {
      return params?.data?.businessUnitName ? (
        <CommonChip label={params?.data?.businessUnitName} />
      ) : (
        <div>-</div>
      );
    },
    width: 150,
  },
  {
    field: 'status',
    headerName: 'Status',
    sortable: true,
    cellRenderer: (params: any) => {
      return <Status label={params.data?.status} readonly />;
    },
    width: 180,
  },
  {
    field: 'itemRelationMetadataItemId',
    headerName: 'Ticket',
    width: 200,
    cellRenderer: (params: any) => {
      return params?.data.itemRelationMetadata ? (
        <CommonIconCell
          iconUrl={params?.data.itemRelationMetadata?.appLogo}
          label={params?.data?.itemRelationMetadata?.itemId}
          linkUrl={params?.data?.itemRelationMetadata?.link}
          maxWidth={130}
        />
      ) : (
        <div>-</div>
      );
    },
  },
  {
    field: 'updatedDate',
    headerName: 'Last Update',
    flex: 1,
    cellRenderer: (params: any) => {
      return (
        <div className="cell-text-renderer">
          {moment(params.data?.updatedAt).format(dateTimeFormat)}
        </div>
      );
    },
  },
  {
    field: 'category',
    headerName: 'Risk Category',
    hide: true,
    sortable: true,
    width: 200,
    cellRenderer: (params: any) => {
      return <CommonIconCell label={params?.data?.category} maxWidth={140} />;
    },
  },
  {
    field: 'severity',
    headerName: 'Severity',
    hide: true,
    width: 110,
    cellRenderer: (params: any) => {
      return <CommonSeverityChip severityNumber={params.data?.severity} />;
    },
  },

  {
    field: 'ownerUserId',
    headerName: 'Resource Owner',
    hide: true,
    width: 250,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          maxWidth={250}
          className="body-2"
        >
          {params?.data?.ownerUserId || '-'}
        </TextOverflow>
      );
    },
  },
  {
    field: 'assigneeUserId',
    headerName: 'Assignee',
    hide: true,
    width: 150,
    cellRenderer: (params: any) => {
      return (
        <AvatarChip
          avatarUrl={params.data?.assigneeProfilePicture}
          label={params.data?.assigneeUserName}
        />
      );
    },
  },
  {
    field: 'operatingSystem',
    headerName: 'Operating System',
    width: 200,
    hide: true,
    cellRenderer: (params: any) => {
      return (
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
          className="cell-text-renderer"
        >
          {params?.data?.resourceOperatingSystem}
        </TextOverflow>
      );
    },
  },
  lastTimelineEventColumn,
];

export const defaultHiddenCampaignFindingsGridColumns: Array<VisibilityControlColumn> =
  [
    {
      value: 'category',
      label: 'Risk Category',
    },
    {
      value: 'severity',
      label: 'Severity',
    },
    {
      value: 'ownerUserId',
      label: 'Resource Owner',
    },
    {
      value: 'assigneeUserId',
      label: 'Assignee',
    },
    {
      value: 'operatingSystem',
      label: 'Operating System',
    },
    lastTimelineEventVisibilityConfiguration,
  ];

export const campaignFilterCategories: Array<ExtendedFilterCategory> = [
  {
    id: 'ownerUserId',
    label: 'Owner',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.STATIC,
      source: {
        options: [],
      },
    },
  },
  {
    id: 'priority',
    label: 'Priority',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.STATIC,
      source: {
        options: [
          {
            value: CampaignCriticality.HBI,
            label: CampaignCriticality.HBI,
          },
          {
            value: CampaignCriticality.MBI,
            label: CampaignCriticality.MBI,
          },
          {
            value: CampaignCriticality.LBI,
            label: CampaignCriticality.LBI,
          },
        ],
      },
    },
  },
  {
    id: 'status',
    label: 'Status',
    type: FilterType.MULTI_SELECT,
    state: {
      sourceType: OptionSourceType.STATIC,
      source: {
        options: [
          {
            value: NewCampaignStatus.ACTIVE,
            label: NewCampaignStatus.ACTIVE,
          },
          {
            value: NewCampaignStatus.INACTIVE,
            label: NewCampaignStatus.INACTIVE,
          },
          {
            value: NewCampaignStatus.COMPLETED,
            label: NewCampaignStatus.COMPLETED,
          },
        ],
      },
    },
  },
  {
    id: 'type',
    label: 'Live',
    type: FilterType.SINGLE_SELECT,
    state: {
      sourceType: OptionSourceType.STATIC,
      source: {
        options: [
          {
            value: CampaignType.STATIC,
            label: 'No',
          },
          {
            value: CampaignType.LIVE,
            label: 'Yes',
          },
        ],
      },
    },
  },
];

export const campaignBreakdownStatisticsFilterCategories: Array<ExtendedFilterCategory> =
  [
    {
      id: 'scopeId',
      label: 'Scope',
      type: FilterType.SINGLE_SELECT,
      state: {
        sourceType: OptionSourceType.API_STATIC,
        source: {
          url: 'organization-structures/getUserAvailableNodesOfType',
          body: {
            nodesType: 'scope',
          },
          optionsTransformer: (options: Array<OrganizationNode>) => {
            return options.map((option: OrganizationNode) => ({
              value: option.id,
              label: option.name,
            }));
          },
        },
      },
    },
    {
      id: 'groupId',
      label: 'Group',
      type: FilterType.SINGLE_SELECT,
      state: {
        sourceType: OptionSourceType.API_STATIC,
        source: {
          url: 'organization-structures/getUserAvailableNodesOfType',
          body: {
            nodesType: 'logical',
          },
          optionsTransformer: (options: Array<OrganizationNode>) => {
            return options.map((option: OrganizationNode) => ({
              value: option.id,
              label: option.name,
            }));
          },
        },
      },
    },
  ];

export const CampaignActiveFindingsCountCategoryOptions: Array<AutocompleteOption> =
  [
    {
      value: 'slaStatus',
      label: 'By Sla',
    },
    {
      value: 'severity',
      label: 'By Severity',
    },
    {
      value: 'slaBreachDateInSeconds',
      label: 'SLA Breach Age',
    },
  ];

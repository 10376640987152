import React from 'react';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
import ComparativeContent from './components/ComparativeContent';
import ComparativeHeader from './components/ComparativeHeader';

const ComparativeMain: React.FC = () => {
  return (
    <div className="comparative-dashboard-page-container">
      <HeaderComponent text="Comparative" />
      <div className="dashboard-page-body">
        <ComparativeHeader />

        <ComparativeContent />
      </div>
    </div>
  );
};
export default ComparativeMain;

import { Alert, Button } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ActionButtons from 'Common/components/ActionButtons';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  createMultiFindingTicketActionMenuItem,
  createTicketActionMenuItem,
  unlinkActionMenuItem,
} from 'shared/fixtures/data/action-menu-list.data';
import { ActionMenuListItem } from 'shared/models/data/action-menu-list.model';
import FormattedMessage from 'shared/components/FormattedMessage';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import CampaignCreateModal from '../CampaignCreateModal';
import TicketCreateModal from '../TicketCreateModal';
import { TicketModalConfiguration } from 'shared/models/data/ticket-modal-configuration.model';
import {
  useBulkCreateTicketForFindingsMutation,
  useBulkUnlinkTicketForFindingsMutation,
  useCreateCampaignMutation,
} from 'Risk/store/api';
import { BulkCreateTicketData } from 'shared/models/data/bulk-create-ticket-data.model';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import { CampaignCreateFormValues } from 'shared/models/data/campaigns.model';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { useNavigate } from 'react-router-dom';
import { GridSelectionDtoHandler } from 'shared/handlers/grid-selection-dto.handler';
import commonConfig from 'Config';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { AuthorizedContent } from '@frontegg/react';
import { FindingTicketActionTypes } from 'shared/enums/FindingTicketActionTypes';
import { useTranslation } from 'react-i18next';
import { GridType } from 'Risk/store';
import { TicketTypes } from 'shared/models/data/risk-table.model';
import ActionTicketResponseModal from '../ActionTicketResponseModal/ActionTicketResponseModal';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';

interface FilterActionsProps extends BaseComponentProps {
  onCancel: () => void;
  itemCount: number;
  selectionProps: any;
  getSelectionList: any;
  disableCampaignCreation?: boolean;
  tagFilter: any;
  vulnerabilityFilter: any;
  genericFilter: any;
}

export type FilterActionButtonPayload = { [key: string]: ActionMenuListItem };

const advanceFilterDataHandler = new AdvanceFilterHandler();

const gridSelectionDtoHandler = new GridSelectionDtoHandler();

const findingBulkTicketActionHardLimit =
  commonConfig.bulkTicketingActionFindingLimit || 25;
const findingBulkActionHardLimit = commonConfig.bulkActionFindingLimit || 1600;

export const FilterActions: FunctionComponent<FilterActionsProps> = ({
  onCancel,
  itemCount,
  selectionProps,
  getSelectionList,
  disableCampaignCreation = false,
  tagFilter,
  vulnerabilityFilter,
  genericFilter,
}) => {
  const { t: translation } = useTranslation();
  const [ticketType, setTicketType] = useState<TicketTypes>();

  const selection = gridSelectionDtoHandler.transformGridSelectionPropsToBffDto(
    selectionProps.gridType,
    selectionProps
  );
  const isTicketActionMenuDisabled: boolean = useMemo(
    () => itemCount > 0 && itemCount > findingBulkTicketActionHardLimit,
    [itemCount]
  );
  const navigate = useNavigate();

  const additionalSearchParams = genericFilter;

  const [ticketModalConfiguration, setTicketModalConfiguration] =
    useState<TicketModalConfiguration>({
      title: '',
      isOpen: false,
      errorMessage: '',
      findingsIds: selection.selectedFindingIds,
      ticketType: TicketTypes.SINGLE_FINDING_TICKET,
    });

  const [campaignModalOpen, setCampaignModalOpen] = useState<boolean>(false);

  const [bulkActionResponseModalOpen, setBulkActionResponseModalOpen] =
    useState<boolean>(true);

  const [bulkSuccessTicketAlert, setBulkSuccessTicketAlert] =
    useState<boolean>(false);

  const isActionMenuDisabled: boolean = useMemo(
    () => itemCount > 0 && itemCount > findingBulkActionHardLimit,
    [itemCount]
  );
  const [
    bulkCreateTicketForFindings,
    {
      data: bulkCreateTicketResponse,
      isLoading: bulkCreateTicketLoading,
      reset: resetBulkCreateTicketData,
    },
  ] = useBulkCreateTicketForFindingsMutation();

  const [
    bulkUnlinkTicketForFindings,
    {
      data: bulkUnlinkTicketResponse,
      isLoading: bulkUnlinkTicketLoading,
      reset: resetBulkUnlinkTicketData,
    },
  ] = useBulkUnlinkTicketForFindingsMutation();

  const [
    createCampaign,
    {
      isLoading: createCampaignLoading,
      data: createCampaignPayload,
      reset: createCampaignReset,
    },
  ] = useCreateCampaignMutation();

  const [
    displayWarningMessageForFindings,
    setDisplayWarningMessageForFindings,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (bulkCreateTicketResponse || bulkUnlinkTicketResponse) {
      setBulkActionResponseModalOpen(true);
      if (bulkCreateTicketResponse) {
        setBulkSuccessTicketAlert(true);
      }
    }
  }, [bulkCreateTicketResponse, bulkUnlinkTicketResponse]);

  useEffect(() => {
    if (createCampaignPayload) {
      createCampaignReset();
      navigate('/campaigns');
    }
  }, [createCampaignPayload]);

  useEffect(() => {
    if (itemCount > findingBulkTicketActionHardLimit) {
      setDisplayWarningMessageForFindings(true);
    }
  }, [itemCount]);

  const userPermissions = usePermissions();
  const hasExternalItemsWrite = userPermissions.includes(
    ApplicationPermission.FINDINGS_EXTERNAL_ITEM_WRITE
  );

  const handleBulkUnlinkAction = () => {
    setTicketType(TicketTypes.UNLINK_TICKET);
    bulkUnlinkTicketForFindings({
      findings: [],
      projectKey: FindingTicketActionTypes.UNLINK_TICKETS,
      selectionProps:
        gridSelectionDtoHandler.transformGridSelectionPropsToBffDto(
          selectionProps.gridType,
          selectionProps
        ),
      filter: {
        filter: additionalSearchParams,
        vulnerabilityFilter: vulnerabilityFilter,
        tagFilter: tagFilter,
      },
    });
  };

  const handleBulkTicketCreation = (
    ticketCreatePayload: BulkCreateTicketData
  ) => {
    setTicketType(ticketCreatePayload?.ticketType);
    bulkCreateTicketForFindings({
      ...ticketCreatePayload,
      findings: [],
      selectionProps:
        gridSelectionDtoHandler.transformGridSelectionPropsToBffDto(
          selectionProps.gridType,
          selectionProps
        ),
      filter: {
        filter: additionalSearchParams,
        vulnerabilityFilter: vulnerabilityFilter,
        tagFilter: vulnerabilityFilter,
      },
      ticketType:
        selection.gridType === GridType.RootCause
          ? TicketTypes.CODE_EVENT_TICKET
          : ticketCreatePayload.ticketType,
    });
  };

  const handleCampaignCreation = (
    campaignCreatePayload: CampaignCreateFormValues
  ) => {
    createCampaign({
      ...campaignCreatePayload,
      startTime: campaignCreatePayload.startTime[0],
      endDate: campaignCreatePayload.endDate[0],
      filter: {
        ...additionalSearchParams,
        type: [],
      },
      attachedItems: [],
      selectionProps:
        gridSelectionDtoHandler.transformGridSelectionPropsToBffDto(
          selectionProps.gridType,
          selectionProps,
          'campaign'
        ),
    });

    setCampaignModalOpen(false);
  };

  const getTitle = () => {
    const isRootCause = selection.gridType === GridType.RootCause;
    const title = isRootCause
      ? translation('findings.ticket.createRootTicket')
      : translation('findings.ticket.createTicket');

    if (selection.selectedFindingIds.length > 1) {
      return title + 's';
    }

    return title;
  };

  const handleCreateTicketsModalOpen = () => {
    setTicketModalConfiguration({
      isOpen: true,
      errorMessage: translation('findings.ticket.createTicketFailed'),
      title: getTitle(),
      findingsIds: selection.selectedFindingIds,
      icon: createTicketActionMenuItem.getIcon(),
      ticketType: TicketTypes.SINGLE_FINDING_TICKET,
    });
  };

  const handleCreateMultiFindingTicketsModalOpen = () => {
    setTicketModalConfiguration({
      isOpen: true,
      errorMessage: translation('findings.ticket.createTicketFailed'),
      title: translation('findings.ticket.createMultiFindingsTicket'),
      findingsIds: selection.selectedFindingIds,
      icon: createTicketActionMenuItem.getIcon(),
      ticketType: TicketTypes.MULTI_FINDINGS_SINGLE_TICKET,
    });
  };

  const handleResponseModalClose = () => {
    resetBulkCreateTicketData();
    resetBulkUnlinkTicketData();
    setBulkActionResponseModalOpen(false);
  };

  const handleBulkTicketSuccessClose = () => {
    setBulkSuccessTicketAlert(false);
  };

  const buttons = useMemo<FilterActionButtonPayload>(() => {
    const defaultButtons: FilterActionButtonPayload = {
      [FindingTicketActionTypes.CREATE_TICKETS]: {
        ...createTicketActionMenuItem,
        disabled: !hasExternalItemsWrite,
        handleClick: handleCreateTicketsModalOpen,
      },
      [FindingTicketActionTypes.UNLINK_TICKETS]: {
        ...unlinkActionMenuItem,
        disabled: !hasExternalItemsWrite,
        handleClick: () => {},
      },
    };

    if (selectionProps.gridType !== GridType.RootCause) {
      const {
        [FindingTicketActionTypes.CREATE_TICKETS]: createTicket,
        ...restOfButtons
      } = defaultButtons;

      const modifiedButtons: FilterActionButtonPayload = {
        [FindingTicketActionTypes.CREATE_TICKETS]: createTicket,
        [FindingTicketActionTypes.CREATE_MULTI_FINDING_TICKET]: {
          ...createMultiFindingTicketActionMenuItem,
          disabled: !hasExternalItemsWrite,
          handleClick: handleCreateMultiFindingTicketsModalOpen,
        },
        ...restOfButtons,
      };

      return modifiedButtons;
    }

    return defaultButtons;
  }, [selectionProps]);

  const buttonComponents = Object.values(buttons).map((button) => {
    const { id, label, getIcon, disabled } = button;
    const icon = getIcon ? getIcon() : null;
    if (id === FindingTicketActionTypes.UNLINK_TICKETS)
      return (
        <DeleteWithConfirm
          id={''}
          setShouldIgnoreRowClick={null}
          useMutation={useBulkUnlinkTicketForFindingsMutation}
          title={
            selection.selectedFindingIds.length > 1
              ? translation('findings.ticket.unlinkTickets')
              : translation('findings.ticket.unlinkTicket')
          }
          modalIcon={<OpusSvgIcon type={SVG_ICON_TYPES.LINK_SLASH_ICON} />}
          message={
            selection.selectedFindingIds.length > 1
              ? translation('findings.ticket.unlinkTicketsWarnings', {
                  param1: selection.selectedFindingIds.length,
                })
              : translation('findings.ticket.unlinkTicketWarning')
          }
          callback={() => {}}
          confimButtonLabel={translation('findings.ticket.unlink')}
          loadingConfimButtonLabel={translation('findings.ticket.unlinking')}
          customDelete={handleBulkUnlinkAction}
        >
          <Button
            key={id}
            startIcon={icon}
            className="risk-action-base-button"
            disabled={disabled}
          >
            {label}
          </Button>
        </DeleteWithConfirm>
      );
    else
      return (
        <Button
          onClick={button.handleClick}
          key={id}
          startIcon={icon}
          className="risk-action-base-button"
          disabled={disabled}
        >
          {label}
        </Button>
      );
  });

  const returnNotificationModalForBulkOperational = (
    responseItems: Array<any>,
    openState: any,
    onCloseCalkbackFN: any
  ) => {
    return (
      <ActionTicketResponseModal
        isOpen={openState}
        handleClose={onCloseCalkbackFN}
        responsePayload={responseItems}
        type={ticketType}
      />
    );
  };

  const returnNotificationModalForUnlinkBulkOperational = (
    responseItems: Array<any>,
    openState: any,
    onCloseCalkbackFN: any
  ) => {
    return (
      <ActionTicketResponseModal
        isOpen={openState}
        handleClose={onCloseCalkbackFN}
        responsePayload={responseItems}
        type={ticketType}
      />
    );
  };

  const renderNotificationForBulkOperation = () => {
    if (bulkCreateTicketResponse || bulkUnlinkTicketResponse) {
      if (bulkUnlinkTicketResponse && bulkUnlinkTicketResponse.length) {
        return returnNotificationModalForUnlinkBulkOperational(
          bulkUnlinkTicketResponse,
          bulkActionResponseModalOpen,
          handleResponseModalClose
        );
      }

      if (bulkCreateTicketResponse && bulkCreateTicketResponse.length) {
        return returnNotificationModalForBulkOperational(
          bulkCreateTicketResponse,
          bulkActionResponseModalOpen,
          handleResponseModalClose
        );
      }

      return <></>;
    }

    return <></>;
  };

  const getWarningMessage = () => {
    if (isActionMenuDisabled)
      return translation(`findings.errorMessages.actionsBulkLimitMessage`, {
        bulkActionHardLimit: findingBulkActionHardLimit,
      });
    if (isTicketActionMenuDisabled)
      return translation(`findings.errorMessages.ticketBulkLimitMessage`, {
        bulkActionHardLimit: findingBulkTicketActionHardLimit,
      });
  };

  const renderProcessingMessage = () => {
    if (itemCount === 1) {
      if (bulkCreateTicketLoading) {
        return 'Creating ticket...';
      }
    }

    return 'Performing bulk operation...';
  };

  const renderCampaignCreateButton = () => {
    if (disableCampaignCreation) {
      return <></>;
    }

    return (
      <AuthorizedContent
        requiredPermissions={[ApplicationPermission.CAMPAIGNS_WRITE]}
      >
        <Button
          className="action-base-button run-campaign"
          startIcon={<OpusSvgIcon type={SVG_ICON_TYPES.CAMPAIGN_ICON} />}
          onClick={() => {
            setCampaignModalOpen(true);
          }}
          disabled={itemCount === 0}
        >
          <FormattedMessage
            id="risk.runCampaign"
            defaultMessage="Run Campaign"
          />
        </Button>
      </AuthorizedContent>
    );
  };

  return (
    <div className="risk-actions">
      {(bulkCreateTicketLoading || bulkUnlinkTicketLoading) && (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          className="risk-view-snackbar"
        >
          <Alert severity="info">{renderProcessingMessage()} </Alert>
        </CommonSnackbarContainer>
      )}

      {createCampaignLoading && (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          className="risk-view-snackbar"
        >
          <Alert severity="info">Creating campaign...</Alert>
        </CommonSnackbarContainer>
      )}

      <CommonSnackbarContainer
        open={displayWarningMessageForFindings}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoHideDuration={3000}
        onClose={() => {
          setDisplayWarningMessageForFindings(false);
        }}
        className="risk-view-snackbar"
      >
        <Alert severity="warning">{getWarningMessage()}</Alert>
      </CommonSnackbarContainer>

      {renderNotificationForBulkOperation()}
      <div className="risk-actions-selected-items">
        {itemCount}{' '}
        {itemCount > 1
          ? translation(`common.items`)
          : translation(`common.item`)}{' '}
        {translation(`common.selected`)}
      </div>

      <CampaignCreateModal
        modalOpen={campaignModalOpen}
        handleModalClose={() => {
          setCampaignModalOpen(false);
        }}
        handleCampaignCreation={handleCampaignCreation}
      />
      <TicketCreateModal
        handleModalClose={() => {
          setTicketModalConfiguration({
            ...ticketModalConfiguration,
            isOpen: false,
          });
        }}
        findingsCount={itemCount}
        configuration={ticketModalConfiguration}
        handleBulkTicketCreation={handleBulkTicketCreation}
        ticketType={ticketModalConfiguration.ticketType}
      />
      <div className="risk-actions-buttons">
        <ActionsMenu
          tagFilter={tagFilter}
          genericFilter={genericFilter}
          vulnerabilityFilter={vulnerabilityFilter}
          itemCount={itemCount}
          selectionProps={selectionProps}
          isDisabled={isActionMenuDisabled}
        ></ActionsMenu>
        <ActionButtons
          baseButton={
            <Button
              className="action-base-button open-ticket"
              startIcon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
              endIcon={<KeyboardArrowDownIcon />}
              disabled={
                isTicketActionMenuDisabled ||
                Object.keys(buttons).length === 0 ||
                !hasExternalItemsWrite
              }
            >
              <FormattedMessage
                id="risk.ticketActions"
                defaultMessage="Ticket Actions"
              />
            </Button>
          }
          options={buttonComponents}
          classes={{
            menu: 'risk-actions-buttons-menu',
          }}
        />
      </div>
      <Button
        className="action-base-button action-cancel-button"
        onClick={() => {
          onCancel();
        }}
      >
        <OpusSvgIcon type={SVG_ICON_TYPES.TIMES_SECONDARY_ICON} />
      </Button>
    </div>
  );
};

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import { OpusAutocomplete } from 'shared/components/OpusAutocomplete';
import { Fragment, FunctionComponent, useMemo } from 'react';
import {
  AutomationRuleProperty,
  AutomationRulePropertyType,
  AutomationRuleRunMode,
  AutomationRuleStatus,
  AutomationRuleValue,
} from 'shared/models/data/automation-rule-group.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { AutomationManagementRuleRowProps } from 'shared/models/props/automation-management-props.model';
import AutomationManagementDataHandler from 'shared/handlers/automation-management-data.handler';
import { debounce } from 'lodash';
import { selectGlobalOptions } from 'AutomationManagement/store';
import { AutomationManagementRuleRowInputs } from 'shared/models/data/automation-management.interface';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import useCommonSelector from 'Common/utils/use-selector';
import AutomationManagementRemediationList from '../AutomationManagementRemediationList';
import OpusSwitch from 'shared/components/OpusSwitch';
import FormattedMessage from 'shared/components/FormattedMessage';
import { useTranslation } from 'react-i18next';
import { ApplicationPermission } from '../../../../../shared/enums/permission.enum';
import { AuthorizedContent } from '@frontegg/react';

export const AutomationManagementRuleRow: FunctionComponent<
  AutomationManagementRuleRowProps
> = (props: AutomationManagementRuleRowProps) => {
  const { t: translation } = useTranslation();

  const {
    rule,
    index,
    findingType,
    workflowDefinitions,
    manageRuleFns: { handleRuleUpdate, handleRuleDelete },
  } = props;

  const options = useCommonSelector(selectGlobalOptions);

  const debounceHandleRuleUpdate = debounce(handleRuleUpdate, 100);

  const inputValues: AutomationManagementRuleRowInputs = useMemo(() => {
    return {
      businessUnits: rule?.properties
        ?.find(
          (property: AutomationRuleProperty) =>
            property.type === AutomationRulePropertyType.BUSINESS_UNIT_IDS
        )
        ?.values?.map((value: string) => {
          return {
            value,
            label: options?.businessUnitIds?.find(
              (businessUnit: any) => businessUnit.value === value
            )?.label as string,
          };
        }) as Array<AutomationRuleValue>,
      environments: rule?.properties
        ?.find(
          (property: AutomationRuleProperty) =>
            property.type ===
            AutomationRulePropertyType.BUSINESS_UNIT_ENVIRONMENT_TYPES
        )
        ?.values?.map((value: string) => {
          return {
            value,
            label: options?.businessEnvironmentTypes?.find(
              (businessEnvironmentType: any) =>
                businessEnvironmentType?.value === value
            )?.label as string,
          };
        }) as Array<AutomationRuleValue>,
      tags: rule?.properties?.find(
        (property: AutomationRuleProperty) =>
          property.type === AutomationRulePropertyType.TAGS
      )?.values[0] as string,
      remediations: rule?.workflowDef?.familyId
        ? [
            {
              value: rule?.workflowDef?.familyId,
              label: rule?.workflowDef?.name,
            },
          ]
        : [],
      runMode: rule?.runMode === AutomationRuleRunMode.MANUAL,
      status: rule?.status === AutomationRuleStatus.ENABLED,
    };
  }, [rule]);

  const selectedWorkflowDefinition = useMemo(() => {
    if (rule?.workflowDef?.id && workflowDefinitions?.length) {
      return workflowDefinitions?.find(
        (workflowDefinition: any) =>
          workflowDefinition.familyId === rule?.workflowDef?.familyId
      );
    }

    return null;
  }, [rule?.workflowDef, workflowDefinitions]);

  return (
    <div className="automation-management-table-row-rule-container">
      <div className="automation-management-table-row-rule-selections">
        <div>
          <b>
            {index + 1}.{' '}
            <FormattedMessage id="common.for" defaultMessage="For" capitalize />
          </b>
          <OpusAutocomplete
            label={translation(`automationManagement.labels.businessUnits`)}
            onChange={(values) => {
              debounceHandleRuleUpdate({
                propertyPath: `property.${AutomationRulePropertyType.BUSINESS_UNIT_IDS}`,
                values,
                rule,
              });
            }}
            options={options.businessUnitIds || []}
            mode="multiple"
            values={inputValues.businessUnits}
          />
        </div>
        <div>
          <b>
            {' '}
            <FormattedMessage id="common.and" defaultMessage="and" />
          </b>
          <OpusAutocomplete
            label={translation(`automationManagement.labels.environments`)}
            onChange={(values) => {
              debounceHandleRuleUpdate({
                propertyPath: `property.${AutomationRulePropertyType.BUSINESS_UNIT_ENVIRONMENT_TYPES}`,
                values,
                rule,
              });
            }}
            options={options.businessEnvironmentTypes || []}
            mode="multiple"
            values={inputValues.environments}
          />
        </div>
        <div>
          <b>
            {' '}
            <FormattedMessage
              id="automationManagement.details.withTag"
              defaultMessage="With tag"
            />
          </b>
          <TextField
            className="automation-management-table-row-tag-input"
            onChange={(event) => {
              debounceHandleRuleUpdate({
                propertyPath: 'property.tags',
                values: event.target.value,
                rule,
              });
            }}
            defaultValue={inputValues.tags}
          />
        </div>
        <div>
          <b>
            {' '}
            <FormattedMessage
              id="automationManagement.details.remediation"
              defaultMessage="Remediation"
            />
          </b>
          <OpusAutocomplete
            values={inputValues.remediations}
            onChange={(values) => {
              debounceHandleRuleUpdate({
                propertyPath: 'workflowDef',
                values: values,
                rule,
              });
            }}
            options={
              workflowDefinitions
                ? AutomationManagementDataHandler.transformWorkflowDefinitionToOptionValues(
                    workflowDefinitions
                  )
                : []
            }
            mode="single"
          />
        </div>
        <div>
          <FormControlLabel
            checked={inputValues.runMode}
            onChange={(event, checked) => {
              handleRuleUpdate({
                propertyPath: 'runMode',
                values: checked
                  ? AutomationRuleRunMode.MANUAL
                  : AutomationRuleRunMode.AUTO,
                rule,
              });
            }}
            control={
              <Checkbox
                icon={<OpusSvgIcon type={SVG_ICON_TYPES.UNCHECKED_ICON} />}
                checkedIcon={<OpusSvgIcon type={SVG_ICON_TYPES.CHECKED_ICON} />}
              />
            }
            label={
              <FormattedMessage
                id="automationManagement.details.requireApproval"
                defaultMessage="Require approval"
              />
            }
          />
          {selectedWorkflowDefinition && (
            <OpusImageIcon
              alt="opus-logo"
              src={selectedWorkflowDefinition?.typeLogoUrl}
              imageClassName="automation-management-table-logo"
            />
          )}
          {selectedWorkflowDefinition && (
            <Tooltip
              classes={{
                tooltip: 'automation-management-rule-row-tooltip',
              }}
              placement="right"
              title={
                <Fragment>
                  <AutomationManagementRemediationList
                    items={
                      selectedWorkflowDefinition?.remediationActions?.map(
                        (remediationAction: any) => ({
                          value: remediationAction,
                          icon: (
                            <OpusSvgIcon
                              type={SVG_ICON_TYPES.SHIELD_CHECK_ICON}
                            />
                          ),
                        })
                      ) || []
                    }
                  />
                </Fragment>
              }
            >
              <IconButton>
                <OpusSvgIcon type={SVG_ICON_TYPES.INFO_ICON} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
      <AuthorizedContent
        requiredPermissions={[ApplicationPermission.AUTOMATIONS_WRITE]}
      >
        <div className="automation-management-table-row-rule-controls">
          <OpusSwitch
            onChange={(event, checked) => {
              handleRuleUpdate({
                propertyPath: 'status',
                values: checked
                  ? AutomationRuleStatus.ENABLED
                  : AutomationRuleStatus.DISABLED,
                rule,
              });
            }}
            checked={inputValues.status}
          />
          <Button
            className="base-opus-button"
            onClick={() => {
              handleRuleDelete(findingType, rule);
            }}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON} />
          </Button>
        </div>
      </AuthorizedContent>
    </div>
  );
};

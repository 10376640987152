import { Parameter as ConnectionTemplateParameter } from 'ConnectionTemplate/interfaces/item';
import { v4 as uuid } from 'uuid';

export interface Parameter extends ConnectionTemplateParameter {
  type?: string;
  value: any;
}

//
// entity
//
export default interface ConnectionItem {
  id: string;
  name: string;
  scopeId?: string | null;
  applicationId: string;
  connectionTemplateId: string;
  parameters: Parameter[];
  definition: object;
  cloudWorkspaceIds?: Array<string>;
  businessUnitIds?: Array<string>;
  isFallback?: boolean;
  applicationLogo?: string;
  metadata?: ConnectionMetadata;
}

export interface MetadataRepos {
  selectedRepos: string[];
  selectedAll: boolean;
  selectedCount: number;
}

export interface AWSCloudProviderMetadata {
  readRoleArn: string;
  externalId: string;
  writeRoleArn?: string;
  writeApplicationIds?: string[];
}

export interface CloudProviderMetadata {
  accountId: string;
  cloudWorkspaceId: string;
  aws?: AWSCloudProviderMetadata;
}

export interface ScmMetadata {
  repos: MetadataRepos;
  lastScanDate: Date;
}

export interface ConnectionMetadata {
  scm?: ScmMetadata;
  cloudProvider?: CloudProviderMetadata;
}

export interface ConnectionItemCreate extends Omit<ConnectionItem, 'id'> {
  cloudWorkspaceIds?: Array<string>;
  isFallback?: boolean;
}

export interface ConnectionItemTest extends ConnectionItemCreate {
  id?: string;
}

export function init(raw: any): ConnectionItem {
  return {
    id: raw.id ?? uuid(),
    name: raw.name ?? '',
    applicationId: raw.applicationId ?? uuid(),
    connectionTemplateId: raw.connectionTemplateId ?? uuid(),
    parameters: raw.parameters ?? [],
    definition: raw.definition ?? {},
  };
}

export function initBulk(raw: any[]): ConnectionItem[] {
  return raw.map(init);
}

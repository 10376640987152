import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import FindingContentHeader from './components/FindingContentHeader';
import TabList from 'shared/components/TabList';
import { SingleFindingTab } from 'shared/enums/findings.enum';
import FindingContentDetails from './components/FindingContentDetails';
import FindingContentWorkflow from './components/FindingContentWorkflow';
import FindingContentActivity from './components/FindingContentActivity';
import { singleFindingTabListItems } from 'shared/fixtures/data/findings.data';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { useFetchFindingSummaryMutation } from 'FindingDetails/store/api';
import { BaseFindingComponentProps } from 'shared/models/props/base-finding-component-props.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { DuplicationState } from 'Risk/interfaces/DuplicationState.enum';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import FindingContentIntelligence from './components/FindingContentIntelligence';
import { useFeatureEntitlements } from '@frontegg/react';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';

interface FindingContentProps extends BaseFindingComponentProps {
  handleCloud2CodeModalOpen: () => void;
  setFindingId: (id: string) => void;
}

export const FindingContent: FunctionComponent<FindingContentProps> = ({
  handleCloud2CodeModalOpen,
  findingId,
  setFindingId,
}) => {
  const [searchParams] = useQueryParams();

  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();
  const { t: translation } = useTranslation();

  const [activeTabId, setActiveTabId] = useState<SingleFindingTab>(
    SingleFindingTab.DETAILS
  );
  const { isEntitled } = useFeatureEntitlements(
    SystemFeatureFlag.FINDING_VIEW_INTELLIGENCE_TAB
  );

  const [
    fetchFindingData,
    { data: findingData, isLoading: isLoadingSummaryData },
  ] = useFetchFindingSummaryMutation();

  const changeTabHandler = (tabId: SingleFindingTab) => {
    setActiveTabId(tabId);
  };

  const fetchFindingDataHandler = () => {
    fetchFindingData(findingId || searchParams.openFindingId);
  };

  useEffect(() => {
    fetchFindingDataHandler();
  }, [findingId, searchParams.openFindingId]);

  useEffect(() => {
    if (reactiveSearchParams.has('findingActiveTabId')) {
      setActiveTabId(
        reactiveSearchParams.get('findingActiveTabId') as SingleFindingTab
      );
    }
  }, [reactiveSearchParams.has('findingActiveTabId')]);

  const renderContentForTabs = () => {
    switch (activeTabId) {
      case SingleFindingTab.DETAILS:
        return (
          <FindingContentDetails
            findingId={findingId}
            description={findingData?.description}
            findingData={findingData}
            handleCloud2CodeModalOpen={handleCloud2CodeModalOpen}
            setFindingId={setFindingId}
          />
        );
      case SingleFindingTab.WORKFLOW:
        return <FindingContentWorkflow />;
      case SingleFindingTab.ACTIVITY:
        return <FindingContentActivity />;
      case SingleFindingTab.INTELLIGENCE:
        return (
          <FindingContentIntelligence
            vulnerabilityId={findingData?.mostSignificantVulnerabilityId}
          />
        );
    }
  };

  const tablist = useMemo(() => {
    if (isEntitled && findingData?.mostSignificantVulnerabilityId)
      return [
        ...singleFindingTabListItems,
        {
          id: SingleFindingTab.INTELLIGENCE,
          label: SingleFindingTab.INTELLIGENCE,
        },
      ];
    return singleFindingTabListItems;
  }, [findingData?.mostSignificantVulnerabilityId]);

  const renderTabList = () => {
    return (
      <TabList
        items={tablist}
        activeItemId={activeTabId}
        onSelect={changeTabHandler}
        isUnderlined
      />
    );
  };

  const scrollToRelatedFindings = () => {
    const element = document.querySelector('#related-findings');
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 50);
    }
  };

  return (
    <div className="finding-content-container">
      <div className="finding-content-header-and-tabs">
        <FindingContentHeader
          findingData={findingData}
          isLoadingData={isLoadingSummaryData}
          refreshFindingData={fetchFindingDataHandler}
        />
        {findingData &&
          findingData?.duplicationState !== DuplicationState.SINGLE && (
            <div className="related-finding-warning">
              <OpusSvgIcon
                type={SVG_ICON_TYPES.CLIPBOARD_COPY_ICON}
              ></OpusSvgIcon>
              <p className="label-4">
                {translation(`findings.details.relatedFindingsInfo`)}
              </p>
              <div
                className="clickable-item  check-info-btn label-4"
                onClick={scrollToRelatedFindings}
              >
                {translation(`findings.details.checkInfo`)}
              </div>
            </div>
          )}
        <div className="finding-content-tab-list">{renderTabList()}</div>
      </div>

      <div className="finding-content-body">{renderContentForTabs()}</div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import useCommonDispatch from 'Common/utils/use-dispatch';
import CardWrapper from 'Common/components/CardWrapper';
import { AutocompleteOption } from 'FindingDetails/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import useCommonSelector from 'Common/utils/use-selector';

import {
  comparativeDashboardFilter,
  ComparativeFilter,
  comparativeFilterOptions,
} from 'Dashboard/Comparative/interfaces/fixtures/data/comparative-dashboard-filter-option.data';
import ScopeGroupFilter from '../ScopeGroupFilter/ScopeGroupFilter';
import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import {
  initialSelectedFilter,
  getFilterList,
  selectComparativeFilters,
  setComparativeFilters,
  setListFilter,
  setListItems,
  setSelectedGroup,
  getSelectedGroup,
  setSelectedScope,
  getSelectedScope,
  setSelectedServices,
} from 'Dashboard/Comparative/store';

export const ComparativeHeader: React.FC = () => {
  const dispatch = useCommonDispatch();
  const headerFilterIds: string[] = [
    DashboardFilterType.SCOPE,
    DashboardFilterType.GROUP,
    DashboardFilterType.FINDING_TYPE,
  ];
  const comparativeFilter = useCommonSelector(selectComparativeFilters);
  const comparativeListItems = useCommonSelector(getFilterList);
  const [selectedGroup, setSelectedGroupId] = useState<FilterOption>();
  const [selectedFilterValue, setSelectedFilterValue] =
    useState<AutocompleteOption>();
  const [optionList, setOptionList] = useState<AutocompleteOption[]>(
    comparativeFilterOptions
  );

  const handleSelectFindingFilter = (
    filter: keyof typeof ComparativeFilter
  ) => {
    dispatch(
      setComparativeFilters({
        ...comparativeFilter,
        selectedFilter: filter,
      })
    );
    setSelectedFilterValue(
      comparativeFilterOptions.find((opt) => opt.value === filter)
    );
  };

  useEffect(() => {
    if (
      comparativeFilter.selectedScope[0] === DashboardFilterType.ALL &&
      comparativeFilter.selectedGroup[0] === DashboardFilterType.ALL
    ) {
      setOptionList(comparativeFilterOptions);
    } else {
      setOptionList(
        comparativeFilterOptions.filter((option) => option.value !== 'scope')
      );

      // A specific group was selected, set filter to service
      if (comparativeFilter.selectedGroup[0] !== DashboardFilterType.ALL) {
        handleSelectFindingFilter('service');
      }
      // Scope comparison was selected, reset to initial filter
      else if (comparativeFilter.selectedFilter === 'scope') {
        handleSelectFindingFilter(initialSelectedFilter);
      }
    }
  }, [comparativeFilter.selectedScope, comparativeFilter.selectedGroup]);

  useEffect(() => {
    const groupFilter = comparativeListItems.find(
      (item) => item.id === DashboardFilterType.GROUP
    );
    if (groupFilter) {
      const selectedGroupItem = groupFilter.items?.find((item) => item.checked);
      setSelectedGroupId(selectedGroupItem);
    }
  }, [comparativeListItems]);

  return (
    <CardWrapper>
      <div className="comparative-dashboard-section-container">
        <div className="comparative-dashboard-section-header">
          <ScopeGroupFilter
            getters={{
              getFilterList: getFilterList,
              getSelectedGroup: getSelectedGroup,
              getSelectedScope: getSelectedScope,
            }}
            setters={{
              setListFilter: setListFilter,
              setListItems: setListItems,
              setSelectedGroup: setSelectedGroup,
              setSelectedScope: setSelectedScope,
              setSelectedServices: setSelectedServices,
            }}
            filterItems={comparativeDashboardFilter.filter((item) =>
              headerFilterIds.includes(item.id)
            )}
          />
          <Autocomplete
            model=""
            onChangeCallBack={(model, option) => {
              handleSelectFindingFilter(
                (option as AutocompleteOption)
                  .value as keyof typeof ComparativeFilter
              );
            }}
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot:
                'comparative-dashboard-section-timeline-filter-input-root',
            }}
            values={selectedFilterValue}
            optionList={optionList}
            single
            enableCheckbox
            placeholder="Select filter"
            initialSelectedValues={{
              value: comparativeFilter.selectedFilter,
              label:
                ComparativeFilter[
                  comparativeFilter.selectedFilter as keyof typeof ComparativeFilter
                ],
            }}
          />
        </div>
      </div>
    </CardWrapper>
  );
};

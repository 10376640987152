import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CrossroadPanelConditionRoute } from '../CrossroadPanelConditionRoute/CrossroadPanelConditionRoute';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CrossroadConditionOperator } from 'WorkflowBuilder/interfaces';

export interface CrossroadCondition {
  expressionA: string;
  expressionB: string;
  operator: CrossroadConditionOperator;
}

export interface CrossroadConditionRoute {
  nextWorkflowSeqId?: number;
  name?: string;
  conditions?: Array<Array<CrossroadCondition>>;
  isFallback?: boolean;
}

export const defaultCondition: CrossroadCondition = {
  expressionA: 'string',
  expressionB: 'string',
  operator: CrossroadConditionOperator.EQUAL,
};

export const defaultConditionRoute: CrossroadConditionRoute = {
  name: 'Lorem Ipsum',
  conditions: [[defaultCondition]],
  isFallback: false,
};

interface CrossroadPanelConditionRouteListProps extends BaseComponentProps {
  routes?: Array<CrossroadConditionRoute>;
  setRoutes: Dispatch<SetStateAction<Array<CrossroadConditionRoute>>>;
  onAddRoute: () => void;
  onRemovePath: (workflowSeqId: number) => void;
}

export const CrossroadPanelConditionRouteList: FunctionComponent<
  CrossroadPanelConditionRouteListProps
> = ({
  routes = [defaultConditionRoute, defaultConditionRoute],
  setRoutes,
  onAddRoute,
  onRemovePath,
}) => {
  const { t: translation } = useTranslation();

  const renderRoutes = () => {
    return routes.map((route: CrossroadConditionRoute, index: number) => (
      <CrossroadPanelConditionRoute
        {...route}
        routeIndex={index}
        deleteRouteHandler={deleteRouteHandler}
        updateRouteNameHandler={updateRouteNameHandler}
        setDefaultRouteHandler={setDefaultRouteHandler}
        conditionProps={{
          deleteHandler: deleteRouteConditionHandler,
          addAndConditionHandler: addAndConditionHandler,
          addOrConditionHandler: addOrConditionHandler,
          updateHandler: updateRouteConditionValuesHandler,
        }}
      />
    ));
  };

  const addNewRouteHandler = () => {
    onAddRoute();
  };

  const deleteRouteHandler = (workflowSeqId: number) => {
    onRemovePath(workflowSeqId);
  };

  const setDefaultRouteHandler = (routeIndex: number) => {
    setRoutes((prevRouteList) =>
      prevRouteList.map((route: CrossroadConditionRoute, index: number) => {
        if (routeIndex === index)
          return {
            ...route,
            isFallback: !route.isFallback,
            conditions: [],
          };

        return {
          ...route,
          isFallback: false,
        };
      })
    );
  };

  const deleteRouteConditionHandler = (
    routeIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => {
    setRoutes((prevRouteList) => {
      return prevRouteList
        .map((route: CrossroadConditionRoute, index: number) => {
          if (routeIndex === index) {
            return {
              ...route,
              conditions: route.conditions?.map((condition, conditionIndex) => {
                if (conditionIndex === rowIndex) {
                  return condition.filter(
                    (conditionItem, conditionItemIndex) =>
                      conditionItemIndex !== columnIndex
                  );
                }

                return condition;
              }),
            };
          }

          return route;
        })
        .map((route) => ({
          ...route,
          conditions: route.conditions?.filter(
            (conditionList) => conditionList.length
          ),
        }));
    });
  };

  const updateRouteNameHandler = (routeIndex: number, nameValue: string) => {
    setRoutes((prevRouteList) =>
      prevRouteList.map((route: CrossroadConditionRoute, index: number) => {
        if (index === routeIndex) {
          return {
            ...route,
            name: nameValue,
          };
        }

        return route;
      })
    );
  };

  const addAndConditionHandler = (routeIndex: number) => {
    setRoutes((prevRouteList) => {
      return prevRouteList.map((route: CrossroadConditionRoute, index) => {
        if (index === routeIndex) {
          return {
            ...route,
            conditions: route.conditions?.length
              ? route.conditions?.map(
                  (conditionList, conditionIndex, conditionListArray) => {
                    if (conditionIndex === conditionListArray.length - 1) {
                      return [...conditionList, defaultCondition];
                    }

                    return conditionList;
                  }
                )
              : [[defaultCondition]],
          };
        }

        return route;
      });
    });
  };

  const addOrConditionHandler = (routeIndex: number) => {
    setRoutes((prevRouteList) => {
      return prevRouteList.map((route: CrossroadConditionRoute, index) => {
        if (index === routeIndex) {
          return {
            ...route,
            conditions: [...(route.conditions || []), [defaultCondition]],
          };
        }

        return route;
      });
    });
  };

  const updateRouteConditionValuesHandler = (
    location: {
      routeIndex: number;
      rowIndex: number;
      columnIndex: number;
    },
    property: 'expressionA' | 'expressionB' | 'operator',
    value: string
  ) => {
    const { routeIndex, rowIndex, columnIndex } = location;

    setRoutes((prevRouteList) => {
      return prevRouteList.map(
        (route: CrossroadConditionRoute, index: number) => {
          if (routeIndex === index) {
            return {
              ...route,
              conditions: route.conditions?.map((condition, conditionIndex) => {
                if (conditionIndex === rowIndex) {
                  return condition.map((conditionItem, conditionItemIndex) => {
                    if (conditionItemIndex === columnIndex) {
                      return {
                        ...conditionItem,
                        [property]: value,
                      };
                    }

                    return conditionItem;
                  });
                }

                return condition;
              }),
            };
          }

          return route;
        }
      );
    });
  };

  return (
    <div className="crossroad-condition-route-list">
      {renderRoutes()}
      <div
        className="crossroad-condition-route-list-add-button"
        onClick={addNewRouteHandler}
      >
        <div className="crossroad-condition-route-list-add-button-text">
          {translation(`flows.details.addNewPath`)}
        </div>
        <IconButton className="crossroad-condition-route-list-add-button-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />
        </IconButton>
      </div>
    </div>
  );
};

import { useState } from 'react';
import { ButtonProps, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface CopyButtonProps extends ButtonProps {
  textToCopy: any;
  buttonIcon?: SVG_ICON_TYPES;
}

export default function CopyButton({
  textToCopy,
  buttonIcon,
}: CopyButtonProps) {
  const { t: translation } = useTranslation();
  const [copyText, setCopyText] = useState<string>(
    translation(`common.copyToClipboard`)
  );

  const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopyClick = async () => {
    await copyTextToClipboard(textToCopy);
    setCopyText(translation(`common.copiedToClipboard`));
    setTimeout(() => {
      setCopyText(translation(`common.copyToClipboard`));
    }, 1500);
  };

  return (
    <Tooltip title={copyText} leaveDelay={1000}>
      <IconButton
        aria-label="copy"
        className="copyButton"
        onClick={handleCopyClick}
      >
        {buttonIcon ? (
          <OpusSvgIcon type={buttonIcon} />
        ) : (
          <OpusSvgIcon type={SVG_ICON_TYPES.COPY_ICON} />
        )}
      </IconButton>
    </Tooltip>
  );
}

import { FunctionComponent, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  AlertBoxProps,
  AlertBoxType,
  getAlertBoxClassname,
  getAlertBoxIcon,
} from '../AlertBox/AlertBox';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useTranslation } from 'react-i18next';
import { CommonButton } from '../CommonButton';
import { CommonButtonType } from '../CommonButton/CommonButton';

interface AlertExpandBoxProps extends BaseComponentProps, AlertBoxProps {
  title: string;
  description: string;
}

export const getAlertExpandBoxClassname = (type: AlertBoxType) => {
  return getAlertBoxClassname(type);
};

export const getAlertExpandBoxIcon = (type: AlertBoxType) => {
  return getAlertBoxIcon(type);
};

export const AlertExpandBox: FunctionComponent<AlertExpandBoxProps> = ({
  description,
  onClose,
  title,
  type,
}) => {
  const { t: translation } = useTranslation();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const toggleExpandedHandler = () => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  };

  const renderExpandedArea = () => {
    if (isExpanded) {
      return (
        <div className="alert-expand-box-expanded-area">{description}</div>
      );
    }

    return <></>;
  };

  return (
    <div
      className={`alert-expand-box-container ${getAlertExpandBoxClassname(
        type
      )}`}
    >
      <div className="alert-expand-box-title-area">
        <div className="alert-expand-box-title-icon">
          {getAlertExpandBoxIcon(type)}
        </div>
        <div className="alert-expand-box-title-text">{title}</div>
        <div className="alert-expand-box-title-expand">
          <CommonButton
            type={CommonButtonType.TEXT}
            onClick={toggleExpandedHandler}
          >
            <span>{translation(`common.learnMore`)}</span>
            <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />
          </CommonButton>
        </div>
      </div>
      {renderExpandedArea()}

      <div className="alert-expand-box-close-icon" onClick={onClose}>
        <OpusSvgIcon type={SVG_ICON_TYPES.TIMES_ICON} height={8} width={8} />
      </div>
    </div>
  );
};

import dagre from 'dagre';
import { v4 as uuid4 } from 'uuid';
import { IaCNode } from 'IaCFindings/interfaces/IaCFinding';
import { Edge, Position } from 'react-flow-renderer';

export default class IaCWorkflowService {
  static flattenStepData(data: IaCNode[], depth = 0, parentId = null) {
    return data?.reduce(
      (
        r: {
          id: any;
          depth: number;
          parentId: null;
        }[],
        { children, id, ...rest }: any,
        index: number
      ) => {
        if (!id) id = uuid4();
        const obj = { ...rest, id, depth, parentId };
        r.push(obj);
        if (children.length) {
          r.push(
            ...IaCWorkflowService.flattenStepData(children, depth + 1, id)
          );
        }

        return r;
      },
      []
    );
  }

  static convertToNodes(formattedStepsData: any[]) {
    return formattedStepsData.map((step, index) =>
      IaCWorkflowService.step2node(step, index)
    );
  }

  static step2node(step: any, index: number): any {
    return {
      id: step.id,
      position: step.position,
      sourcePosition: Position.Right,
      draggable: false,
      type: 'iacNode',
      data: {
        label: step.title,
        subLabel: step.subTitle,
        icon: step?.icon,
        count: step.count,
        depth: index,
      },
    };
  }

  static convertToEdges(steps: any) {
    const edges: Edge[] = [];

    steps.map((step: { id: any }) => {
      const targets = steps.filter((item: { parentId: any }) => {
        return item.parentId === step.id;
      });

      if (!targets) {
        return;
      }
      for (const target of targets) {
        const edge: Edge = {
          id: uuid4(),
          source: step.id,
          type: 'smoothstep',
          target: target.id,
        };
        edges.push(edge);
      }
    });
    return edges;
  }

  static getLayoutedElements = (
    nodes: any[],
    edges: any[],
    direction = 'LR'
  ) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 172;
    const nodeHeight = 36;
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node: { id: any }) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge: { source: any; target: any }) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach(
      (node: {
        id: any;
        targetPosition: string;
        sourcePosition: string;
        position: { x: number; y: number };
        data: any;
      }) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? 'left' : 'top';
        node.sourcePosition = isHorizontal ? 'right' : 'bottom';

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        };

        if (node.data?.subLabel) {
          node.position.y -= 7.5;
        }

        return node;
      }
    );
    return { nodes, edges };
  };
}

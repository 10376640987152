import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { HorizontalStackedBarChartProps } from 'shared/models/props/chart-props.model';
import { v4 as uuid4 } from 'uuid';
import BaseChart from '../BaseChart';
import { ChartData } from 'shared/models/data/chart.model';
import tinycolor from 'tinycolor2';

export const HorizontalStackedBarChar: FunctionComponent<
  HorizontalStackedBarChartProps<ChartData>
> = ({
  fetchWidgetData,
  isWidgetDataLoading,
  widgetData,
  displayLegend = false,
  displayLabels = false,
  enableTooltip = false,
  aspectRatio = 1,
  isResponsive = true,
  legendPosition = 'top',
  borderWidth = 0,
  style = {},
  applyColorList = false,
  customLegendContainerId,
  displayAsImage = false,
  dynamicHeight,
  options: customOptions,
  plugins = [],
}) => {
  const [base64ImageUrl, setBase64ImageUrl] = useState<string | null>(null);

  const chartRef = useRef();

  const chartId = useMemo(() => {
    return uuid4();
  }, []);

  const chartOptions = useMemo(() => {
    return {
      indexAxis: 'y' as const,
      devicePixelRatio: 2,
      aspectRatio,
      layout: {
        padding: {
          left: 35,
        },
      },
      scales: {
        y: {
          stacked: true,
          grid: {
            lineWidth: 0,
          },
          ticks: {
            autoSkip: false,
          },
        },
        x: {
          stacked: true,
          grid: {
            drawBorder: false,
          },
        },
      },
      elements: {
        bar: {
          borderWidth,
        },
      },
      maintainAspectRatio: false,
      responsive: isResponsive,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: enableTooltip,
        },
        datalabels: {
          display: displayLabels,
          color: (props: any) => {
            const backgroundColor = tinycolor(props.dataset.backgroundColor);
            return backgroundColor.darken(40).toString();
          },
          fontWeight: 'bold',
          formatter: (value: any) => {
            if (typeof value === 'number') {
              if (value === 0) {
                return '';
              }
            }

            return value;
          },
        },
        htmlLegend: {
          containerID:
            customLegendContainerId || `chart-legend-container-${chartId}`,
        },
      },
    };
  }, [widgetData]);

  const mergedOptions = useMemo(() => {
    return {
      ...chartOptions,
      ...customOptions,
      plugins: {
        ...chartOptions.plugins,
        ...(customOptions?.plugins || {}),
      },
    };
  }, [chartOptions, customOptions]);

  useEffect(() => {
    if (chartRef.current) {
      const base64Image = (chartRef.current as any).toBase64Image();

      setBase64ImageUrl(base64Image);
    }
  }, [chartRef.current]);

  const renderChart = () => {
    if (displayAsImage && base64ImageUrl) {
      return <img src={base64ImageUrl} />;
    }

    return (
      <BaseChart
        options={mergedOptions}
        type="bar"
        chartRef={chartRef}
        {...{
          fetchWidgetData,
          isWidgetDataLoading,
          widgetData,
          style,
          applyColorList,
          displayLegend,
          legendPosition,
          chartId,
          dynamicHeight,
          customLegendContainerId,
        }}
        plugins={plugins}
      />
    );
  };

  return renderChart();
};

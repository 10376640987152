import { yupResolver } from '@hookform/resolvers/yup';
import ConnectionTemplateItem, {
  ConnectionTemplateType,
  Parameter,
} from 'ConnectionTemplate/interfaces/item';
import { SectionBreaker } from 'Dashboard/components/Dashboard/SectionBreaker/SectionBreaker';
import ConnectionItem from 'Integrations/Connection/interfaces/item';
import {
  useConnectionTestMutation,
  useConnectionUpdateMutation,
  useConnectionUpdateWithRepoMutation,
} from 'Integrations/Connection/store/api';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AlertBox, AlertBoxType } from 'shared/components/AlertBox/AlertBox';
import { CommonButton } from 'shared/components/CommonButton';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { integrationsFormDefaultSchema } from 'shared/fixtures/data/integrations.data';
import { IntegrationsDataHandler } from 'shared/handlers/integrations-data.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import * as yup from 'yup';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { CircularProgress } from '@mui/material';
import { InputLabelWrapper } from 'shared/components/InputLabelWrapper/InputLabelWrapper';
import { capitalize } from 'lodash';
import { FormInput } from 'shared/components/FormInput/FormInput';
import Autocomplete from 'Common/components/Autocomplete';
import { useFetchIntegrationMetadata } from 'shared/hooks/useFetchIntegrationMetadata';
import { useFetchConnectionByIdMutation } from 'WorkflowBuilder/store/api';
import { IntegrationRepositoryTable } from '../IntegrationRepositoryTable/IntegrationRepositoryTable';
import { PollingSubscription } from 'Integrations/Webhook/interfaces/polling-subscription';
import WebhookItem from 'Integrations/Webhook/interfaces/item';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import {
  IntegrationItem,
  IntegrationItemSubCategory,
} from 'Integrations/interfaces/IntegrationItem.model';
import { useFetchDefaultConnectionForApplication } from 'shared/hooks/useFetchDefaultConnectionForApplication';
import { useTranslation } from 'react-i18next';
import { AlertExpandBox } from 'shared/components/AlertExpandBox/AlertExpandBox';
import { IntegrationActionType } from 'shared/models/data/integrations.model';
import { OrganizationNodeDataHandler as OrganizationNodeOptionDataHandler } from 'shared/handlers/organization-node-data.handler';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';

export interface IntegrationAdditionalContentFormProps {
  errors?: Record<string, string>;
  setValues?: (values: Record<string, string>) => void;
  setParameters?: (parameters: Array<Parameter>) => void;
}

interface BaseIntegrationModalEditFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {
  renderAdditionalContent?: (
    isVisible: boolean,
    toggleVisibility: (visible: boolean) => void,
    data?: PollingSubscription | WebhookItem,
    formProps?: IntegrationAdditionalContentFormProps
  ) => JSX.Element;
  onConnectHandler?: any;
  onConnectionTemplateChange?: (template?: ConnectionTemplateItem) => void;
  handleOnCreate?: (connection: IntegrationItem) => void;
}

const integrationsDataHandler = new IntegrationsDataHandler();

const organizationNodeOptionDataHandler =
  new OrganizationNodeOptionDataHandler();

export const BaseIntegrationModalEditForm: FunctionComponent<
  BaseIntegrationModalEditFormProps
> = ({
  renderAdditionalContent,
  onCancel,
  integrationItem,
  open,
  connectionId,
  onConnectHandler,
  onConnectionTemplateChange,
  rootClassName,
  renderButtonRow: propsRenderButtonRow,
  businessUnitRequired = true,
  handleOnCreate = () => {},
}) => {
  const { t: translation } = useTranslation();

  const {
    connectionTemplate,
    loadingConnectionTemplate,
    pollingSubscription,
    isLoadingPollingSubscription,
    webhookConnection,
  } = useFetchIntegrationMetadata(open, integrationItem, connectionId);

  const [additionalContentParameters, setAdditionalContentParameters] =
    useState<Array<Parameter>>([]);

  const [additionalContentValues, setAdditionalContentValues] = useState<
    Record<string, string>
  >({});

  const [additionalContentErrors, setAdditionalContentErrors] = useState<
    Record<string, string>
  >({});

  const hasPollingSubscription = useMemo(() => {
    return !!pollingSubscription;
  }, [pollingSubscription]);

  const [additionalContentVisible, setAdditionalContentVisible] =
    useState<boolean>(false);

  useEffect(() => {
    setAdditionalContentVisible(hasPollingSubscription);
  }, [hasPollingSubscription]);

  const validateAdditionalContent = () => {
    if (!additionalContentVisible) return true;

    let updatedErrors = {
      ...additionalContentErrors,
    };
    for (const parameter of additionalContentParameters) {
      if (
        (parameter.required === undefined || parameter.required === true) &&
        (parameter.hidden === undefined || parameter.hidden === false)
      ) {
        if (additionalContentValues[parameter.name]?.length) {
          if (updatedErrors[parameter.name]) {
            delete updatedErrors[parameter.name];
          }
        } else {
          updatedErrors[
            parameter.name
          ] = `${parameter.displayName} is required`;
        }
      }
    }

    setAdditionalContentErrors(updatedErrors);
    return Object.keys(updatedErrors)?.length === 0;
  };

  const formDataInitialized = useRef<boolean>(false);

  const environmentsInitialized = useRef<boolean>(false);

  const defaultConnectionPayload = useFetchDefaultConnectionForApplication(
    integrationItem?.id
  );

  const [selectedRepoIds, setSelectedRepoIds] = useState<
    Array<string> | undefined
  >(undefined);

  const [selectedAllRepos, setSelectedAllRepos] = useState<boolean>(false);

  const formSchema = useMemo(() => {
    if (connectionTemplate) {
      return yup.object().shape({
        ...integrationsFormDefaultSchema,
        ...connectionTemplate.parameters.reduce(
          (previousValue: any, currentValue: any) => {
            if (currentValue.hidden) return previousValue;

            if (
              currentValue.required === undefined ||
              currentValue.required === true
            )
              return {
                ...previousValue,
                [currentValue.name]: yup
                  .string()
                  .required()
                  .default(currentValue.defaultValue || ''),
              };

            return {
              ...previousValue,
              [currentValue.name]: yup
                .string()
                .default(currentValue.defaultValue || ''),
            };
          },
          {}
        ),
      });
    }

    return yup.object().shape(integrationsFormDefaultSchema);
  }, [connectionTemplate]);

  const {
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    watch: watchFormValue,
    reset: resetFormState,
    getFieldState,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      isFallback: false,
      name: '',
      enviroments: [],
      scopeId: '',
    } as any,
    mode: 'all',
  });

  const environments = watchFormValue('enviroments');

  const [
    fetchConnection,
    { data: connectionData, isLoading: fetchingConnection },
  ] = useFetchConnectionByIdMutation();

  const [
    updateConnection,
    {
      isLoading: updatingConnection,
      isSuccess: updateConnectionSuccess,
      error: updateConnectionError,
      reset: resetUpdateConnection,
    },
  ] = useConnectionUpdateMutation();

  const [
    updateWithRepos,
    {
      isLoading: settingRepos,
      isSuccess: settingReposSuccess,
      error: setReposError,
      data: setReposData,
    },
  ] = useConnectionUpdateWithRepoMutation();

  const [
    getUserAvailableNodesOfType,
    { data: nodeData, isLoading: nodeDataLoading },
  ] = useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    if (integrationItem.category !== IntegrationCategory.SCM) {
      const scopeId = getValues().scopeId;

      if (!scopeId || scopeId === 'None') {
        getUserAvailableNodesOfType({
          nodesType: OrganizationNodeType.DATA,
        });
      } else {
        getUserAvailableNodesOfType({
          nodesType: OrganizationNodeType.DATA,
          startNodeId: scopeId || connectionData?.scopeId,
        });
      }
    }
  }, [getValues().scopeId, connectionData]);

  const nodeDataAutocompleteList: Array<AutocompleteOption> | null =
    useMemo(() => {
      return nodeData
        ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
            nodeData
          )
        : null;
    }, [nodeData]);

  const [
    testConnection,
    {
      data: testConnectionPayload,
      isLoading: testingConnection,
      error: testConnectionError,
      reset: resetTestConnection,
    },
  ] = useConnectionTestMutation();

  const formDefaultValues = useMemo(() => {
    if (connectionData && open) {
      return integrationsDataHandler.transformIntegrationDataToFormData(
        connectionData,
        []
      );
    }

    return undefined;
  }, [connectionData]);

  useEffect(() => {
    return () => {
      formDataInitialized.current = false;
      environmentsInitialized.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      connectionData &&
      integrationItem.category === IntegrationCategory.SCM
    ) {
      const selectedRepos =
        connectionData?.metadata?.scm?.repos?.selectedRepos || [];

      setSelectedRepoIds(selectedRepos);
    }
  }, [connectionData]);

  useEffect(() => {
    if (!settingRepos && (updateConnectionSuccess || settingReposSuccess)) {
      handleOnCreate &&
        handleOnCreate({ ...integrationItem, id: connectionId as string });
      closeHandler();
    }
  }, [updateConnectionSuccess, settingReposSuccess]);

  useEffect(() => {
    if (formDefaultValues && !formDataInitialized.current) {
      for (const formKey in formDefaultValues) {
        if (formKey !== 'enviroments') {
          setValue(formKey, formDefaultValues[formKey]);
          clearErrors(formKey);
        }
      }
      formDataInitialized.current = true;
    }
  }, [formDefaultValues, formDataInitialized]);

  useEffect(() => {
    if (
      connectionData &&
      nodeDataAutocompleteList &&
      !environmentsInitialized.current
    ) {
      const selectedEnvironments = nodeDataAutocompleteList?.filter(
        (optionItem) =>
          connectionData.businessUnitIds?.includes(optionItem.value)
      );

      fieldValueChangeHandler('enviroments', selectedEnvironments);
      environmentsInitialized.current = true;
    }
  }, [connectionData, nodeDataAutocompleteList]);

  useEffect(() => {
    if (connectionId) fetchConnection(connectionId);
  }, [connectionId]);

  const resetApiState = () => {
    resetUpdateConnection();
    resetTestConnection();
  };

  const [fetchScopeList, { data: scopeData, isLoading: scopeDataLoading }] =
    useGetUserAvailableNodesOfTypeMutation();
  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);

  const scopeAutocompleteList: Array<AutocompleteOption> | null =
    useMemo(() => {
      return scopeData
        ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
            scopeData,
            true
          )
        : null;
    }, [scopeData]);

  const selectedScopeOption = useMemo<AutocompleteOption | undefined>(() => {
    return scopeAutocompleteList?.find(
      (option) => option.value === getValues().scopeId
    );
  }, [scopeAutocompleteList, getValues().scopeId]);

  const fieldValueChangeHandler = (fieldName: string, changeValue: any) => {
    if (changeValue.length) {
      clearErrors(fieldName);
    }
    setValue(fieldName, changeValue);
  };

  const validateFormBeforeSubmit = () => {
    const isAdditionalContentValid = validateAdditionalContent();

    if (!isAdditionalContentValid) return;

    let isValid = true;
    const formValues = getValues();
    let schemaFields = formSchema.fields;

    if (
      integrationItem?.category === IntegrationCategory.SCM ||
      businessUnitRequired === false
    ) {
      delete schemaFields['enviroments'];
    }

    for (const schemaFieldKey in schemaFields) {
      const fieldOptions = schemaFields[schemaFieldKey];

      if (
        schemaFieldKey === 'enviroments' &&
        (formValues.isFallback ||
          (formValues.scopeId && formValues.scopeId !== 'None'))
      ) {
        clearErrors('enviroments');
      } else if (
        (fieldOptions.type === 'string' || fieldOptions.type === 'array') &&
        fieldOptions.spec.presence === 'required' &&
        (formValues[schemaFieldKey] === undefined ||
          formValues[schemaFieldKey]?.length === 0) &&
        schemaFieldKey !== 'scopeId'
      ) {
        isValid = false;
        setError(schemaFieldKey, {
          message: `${
            schemaFieldKey === 'enviroments'
              ? `Service or Scope`
              : schemaFieldKey
          } is required`,
        });
      }
    }

    return isValid;
  };

  const closeHandler = () => {
    resetFormState();
    resetApiState();
    onCancel();
  };

  const connectHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const postValues =
        integrationsDataHandler.transformIntegrationsFormDataToPostData(
          getValues(),
          integrationItem,
          connectionTemplate
        ) as ConnectionItem;

      onConnectHandler && onConnectHandler(true);

      const updatedConnectionData = {
        ...postValues,
        scopeId:
          postValues.scopeId && postValues.scopeId !== 'None'
            ? postValues.scopeId
            : null,
        id: connectionId as string,
      };

      if (selectedRepoIds) {
        updateWithRepos({
          id: connectionId as string,
          repos: selectedAllRepos ? [] : selectedRepoIds,
          selectAll: selectedAllRepos,
          connection: updatedConnectionData,
        });
      } else {
        updateConnection(updatedConnectionData);
      }
    }
  };

  const testHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();
    const formValues =
      integrationsDataHandler.transformIntegrationsFormDataToPostData(
        getValues(),
        integrationItem,
        connectionTemplate
      ) as ConnectionItem;
    if (isFormValid) {
      testConnection({
        ...formValues,
        id: connectionId,
        scopeId:
          formValues.scopeId && formValues.scopeId !== 'None'
            ? formValues.scopeId
            : null,
      });
    }
  };

  const renderRepositoryTable = () => {
    if (selectedRepoIds) {
      return (
        <IntegrationRepositoryTable
          integrationId={connectionData?.id}
          onSelect={(selectedIds: Array<string>, selectAll?: boolean) => {
            setSelectedRepoIds(selectedIds);
            setSelectedAllRepos(Boolean(selectAll));
          }}
          selectionIds={selectedRepoIds}
          selectedAll={Boolean(
            connectionData?.metadata?.scm?.repos?.selectedAll
          )}
        />
      );
    }

    return <></>;
  };

  const renderAlertBox = () => {
    if (updateConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.UPDATING,
            integrationItem.name
          )}
          description={
            (updateConnectionError as any)?.data?.message ||
            (updateConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (testConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            integrationItem.name
          )}
          description={
            (testConnectionError as any)?.data?.error +
            '  ' +
            (testConnectionError as any)?.data?.message
          }
        />
      );
    }

    if (testConnectionPayload && testConnectionPayload?.status === 'failed') {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            integrationItem.name
          )}
          description={testConnectionPayload?.results?.error}
        />
      );
    }

    if (setReposError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.UPDATING,
            integrationItem.name
          )}
          description={
            (setReposData as any)?.data?.message ||
            (setReposError as any)?.data?.error
          }
        />
      );
    }

    if (testConnectionPayload && testConnectionPayload.status === 'done') {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    return <></>;
  };

  const renderTestButton = () => {
    return (
      <CommonButton type={CommonButtonType.SECONDARY} onClick={testHandler}>
        {testingConnection ? 'Verifying Connectivity' : 'Verify Connectivity'}
      </CommonButton>
    );
  };

  const renderConnectButtonRow = () => {
    return (
      <div className="integration-modal-button-row">
        <div className="integration-modal-cancel-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={closeHandler}
          >
            Cancel
          </CommonButton>
        </div>
        <div className="integration-modal-connection-buttons">
          {renderTestButton()}
          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={connectHandler}
          >
            {updatingConnection || settingRepos ? 'Updating' : 'Update'}
          </CommonButton>
        </div>
      </div>
    );
  };

  const renderButtonRow = () => {
    if (loadingConnectionTemplate) {
      return <></>;
    }

    if (propsRenderButtonRow)
      return propsRenderButtonRow({
        verifyHandler: testHandler,
        connectHandler,
        connecting: updatingConnection || settingRepos,
        verifying: testingConnection,
      });

    return renderConnectButtonRow();
  };

  useEffect(() => {
    onConnectionTemplateChange &&
      onConnectionTemplateChange(connectionTemplate);
  }, [connectionTemplate]);

  const getScopeLabel = (scopeId: string) => {
    return organizationNodeOptionDataHandler.getScopeLabel(
      scopeId,
      scopeAutocompleteList || []
    );
  };

  const renderEnvironmentsSelect = () => {
    if (integrationItem?.category === IntegrationCategory.SCM) {
      return <></>;
    }

    return (
      <InputLabelWrapper label="Services">
        {nodeDataLoading ? (
          <CircularProgress size={24} />
        ) : (
          <Autocomplete
            enableCheckbox
            classes={{
              root: 'multi-select-field-1',
              paper: 'multi-select-field-paper-1',
              inputRoot: errors.enviroments ? 'input-validation-error-1' : '',
            }}
            model="environments"
            label="Services"
            initialSelectedValues={environments}
            values={environments}
            onChangeCallBack={(model, values) => {
              const typedValues = values as Array<AutocompleteOption>;

              fieldValueChangeHandler('enviroments', typedValues);
            }}
            optionList={nodeDataAutocompleteList}
            areOptionsLoaded
          />
        )}
      </InputLabelWrapper>
    );

    return <></>;
  };

  const renderFormSectionBreaker = () => {
    if (integrationItem?.category === IntegrationCategory.SCM) {
      return <></>;
    }

    if (renderAdditionalContent || connectionTemplate) {
      return <SectionBreaker />;
    }

    return <></>;
  };

  const renderAdditionalContentMemoized = useMemo(() => {
    if (renderAdditionalContent && !isLoadingPollingSubscription) {
      if (integrationItem?.subCategory === IntegrationItemSubCategory.POLLING) {
        return renderAdditionalContent(
          additionalContentVisible,
          (visible: boolean) => {
            setAdditionalContentVisible(visible);
          },
          pollingSubscription,
          {
            errors: additionalContentErrors,
            setValues: (values) => setAdditionalContentValues(values),
            setParameters: (parameters) =>
              setAdditionalContentParameters(parameters),
          }
        );
      }
      if (integrationItem?.subCategory === IntegrationItemSubCategory.WEBHOOK) {
        return renderAdditionalContent(
          additionalContentVisible,
          (visible: boolean) => {
            setAdditionalContentVisible(visible);
          },
          webhookConnection
        );
      }
    }
    return <></>;
  }, [
    renderAdditionalContent,
    setAdditionalContentValues,
    setAdditionalContentParameters,
    pollingSubscription,
    isLoadingPollingSubscription,
    integrationItem,
    webhookConnection,
    additionalContentErrors,
    additionalContentVisible,
  ]);

  const renderIsDefaultCheckbox = () => {
    if (integrationItem?.category === IntegrationCategory.SCM) {
      return <></>;
    }

    return (
      <InputLabelWrapper
        defaultChecked={Boolean(formDefaultValues?.isFallback)}
        onToggle={(checked) => setValue('isFallback', checked)}
        showCheckbox
        label="Default Connection"
        disabled={
          Boolean(formDefaultValues?.isFallback)
            ? false
            : Boolean(defaultConnectionPayload?.connection)
        }
      />
    );
  };

  const renderDynamicInputs = () => {
    if (loadingConnectionTemplate) {
      return <CircularProgress size={20} />;
    }

    if (!connectionTemplate) {
      return <></>;
    }

    return connectionTemplate.parameters?.length ? (
      connectionTemplate.parameters?.map((parameter: any) => {
        if (parameter.hidden) return <></>;
        return (
          <FormInput
            label={parameter.displayName}
            name={parameter.name}
            value={
              formDefaultValues
                ? formDefaultValues[parameter.name]
                : parameter.defaultValue
            }
            onChange={(changeValue: string) => {
              fieldValueChangeHandler(parameter.name, changeValue);
            }}
            errorMessage={capitalize(
              errors[parameter.name]?.message?.toString()
            )}
          />
        );
      })
    ) : (
      <></>
    );
  };

  const renderForm = () => {
    if (fetchingConnection) {
      return <CircularProgress size={24} />;
    }

    return (
      <form className="integration-modal-form">
        <div className="integration-modal-form-main-inputs">
          <FormInput
            label="Name"
            name="name"
            onChange={(changeValue) => {
              fieldValueChangeHandler('name', changeValue);
            }}
            value={formDefaultValues?.name}
            placeholder=""
            errorMessage={capitalize(errors.name?.message?.toString())}
          />

          <InputLabelWrapper
            label="Scope"
            errorMessage={errors.enviroments?.message?.toString()}
          >
            {scopeAutocompleteList === null || scopeDataLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Autocomplete
                model="scopeId"
                optionList={scopeAutocompleteList}
                values={selectedScopeOption}
                initialSelectedValues={selectedScopeOption}
                onChangeCallBack={(
                  model: string,
                  option: AutocompleteOption | Array<AutocompleteOption>
                ) => {
                  fieldValueChangeHandler('enviroments', []);

                  fieldValueChangeHandler(
                    'scopeId',
                    (option as AutocompleteOption).value
                  );
                }}
                classes={{
                  root: 'multi-select-field-1',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: errors.enviroments
                    ? 'input-validation-error-1'
                    : '',
                }}
                single
                areOptionsLoaded
              />
            )}
          </InputLabelWrapper>
          {renderEnvironmentsSelect()}
          {renderIsDefaultCheckbox()}
          {renderFormSectionBreaker()}
        </div>

        <div className="integration-modal-form-additional-inputs">
          {renderDynamicInputs()}
          {renderAdditionalContentMemoized}
        </div>
      </form>
    );
  };

  return (
    <div className={`integration-modal-form-container ${rootClassName}`}>
      {renderAlertBox()}
      <div className="integration-modal-content">
        <div className="integration-modal-content-body">
          {renderForm()}
          {renderRepositoryTable()}
        </div>
        <div className="integration-modal-content-footer">
          {renderButtonRow()}
        </div>
      </div>
    </div>
  );
};

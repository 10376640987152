import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../Common/store';

// state definition
interface DiagramState {
  /**
   * `workflowSeqId` of a tool node to add a new node after
   */
  afterTool: number | null;
  beforeNode: number | null;
  crossroadParent: number | null;
  activeTool: number | null;
}

// initial state
const initialState: DiagramState = {
  afterTool: null,
  beforeNode: null,
  crossroadParent: null,
  activeTool: null,
};

// slice
export const diagramSlice = createSlice({
  name: 'diagram',
  initialState,
  reducers: {
    setAfterTool: (state, action: PayloadAction<number | null>) => {
      state.afterTool = action.payload;
    },
    setBeforeNode: (state, action: PayloadAction<number | null>) => {
      state.beforeNode = action.payload;
    },

    setCrossroadParent: (state, action: PayloadAction<number | null>) => {
      state.crossroadParent = action.payload;
    },

    setActiveTool: (state, action: PayloadAction<number | null>) => {
      state.activeTool = action.payload;
    },
  },
});

// actions
export const {
  setAfterTool,
  setBeforeNode,
  setCrossroadParent,
  setActiveTool,
} = diagramSlice.actions;

// selectors
export const selectAfterTool = (state: RootState) => state.diagram.afterTool;
export const selectBeforeNode = (state: RootState) => state.diagram.beforeNode;
export const selectCrossroadParent = (state: RootState) =>
  state.diagram.crossroadParent;

export const selectActiveTool = (state: RootState) => state.diagram.activeTool;

// exporting reducer
const diagramReducer = diagramSlice.reducer;
export default diagramReducer;

export enum ApplicationPermission {
  ANALYTICS_READ = 'analytics.read',
  RISK_READ = 'risk.read',
  FINDINGS_READ = 'findings.read',
  FINDINGS_WRITE = 'findings.write',
  FINDINGS_ACTIVITY_READ = 'findings.activity.read',
  FINDINGS_ACTIVITY_WRITE = 'findings.activity.write',
  FINDINGS_EXTERNAL_ITEM_READ = 'findings.externalItem.read',
  FINDINGS_EXTERNAL_ITEM_WRITE = 'findings.externalItem.write',
  CAMPAIGNS_READ = 'campaigns.read',
  CAMPAIGNS_WRITE = 'campaigns.write',
  WORKFLOWS_READ = 'workflows.read',
  WORKFLOWS_WRITE = 'workflows.write',
  AUTOMATIONS_READ = 'automation.read',
  AUTOMATIONS_WRITE = 'automation.write',
  INTEGRATIONS_TAB = 'integrations.tab',
  INTEGRATIONS_READ = 'integrations.read',
  INTEGRATIONS_WRITE = 'integrations.write',
  SETTINGS_GLOBAL = 'settings.global',
  SETTINGS_BUS_READ = 'settings.bus.read',
  SETTINGS_BUS_VIEW = 'settings.bus.view',
  SETTINGS_BUS_WRITE = 'settings.bus.write',
  SETTINGS_WORKSPACES_READ = 'settings.workspaces.read',
  SETTINGS_WORKSPACES_VIEW = 'settings.workspaces.view',
  SETTINGS_WORKSPACES_WRITE = 'settings.workspaces.write',
  SETTINGS_SLA_READ = 'settings.sla.read',
  SETTINGS_SLA_VIEW = 'settings.sla.view',
  SETTINGS_SLA_WRITE = 'settings.sla.write',
  SETTINGS_TEAMS_READ = 'settings.teams.read',
  SETTINGS_TEAMS_VIEW = 'settings.teams.view',
  SETTINGS_TEAMS_WRITE = 'settings.teams.write',
  SETTINGS_APPLICATIONS_READ = 'settings.applications.read',
  SETTINGS_SYSTEM_ACTIONS = 'settings.system.actions',
  SETTINGS_NOTIFICATION_RULES_READ = 'settings.notification-rules.read',
  SETTINGS_NOTIFICATION_RULES_VIEW = 'settings.notification-rules.view',
  SETTINGS_NOTIFICATION_RULES_WRITE = 'settings.notification-rules.write',
  SETTINGS_USERS_READ = 'settings.users.read',
  SETTINGS_USERS_VIEW = 'settings.users.view',
  SETTINGS_USERS_WRITE = 'settings.users.write',
  SETTINGS_ORGANIZATION_READ = 'settings.organization.read',
  SETTINGS_ORGANIZATION_VIEW = 'settings.organization.view',
  SETTINGS_ORGANIZATION_WRITE = 'settings.organization.write',
  SETTINGS_REPORTS_RULES_READ = 'settings.reports-rules.read',
  SETTINGS_REPORTS_RULES_VIEW = 'settings.reports-rules.view',
  SETTINGS_REPORTS_RULES_WRITE = 'settings.reports-rules.write',
  EVENTS_READ = 'events.read',
  EVENTS_WRITE = 'events.write',
  EXCEPTIONS_READ = 'exceptions.read',
  FINDING_EXCEPTIONS_READ = 'finding.exceptions.read',
  EXCEPTIONS_WRITE_REQUEST = 'exceptions.write.request',
  EXCEPTIONS_WRITE_APPROVE = 'exceptions.write.approve',
  SETTINGS_RISK_SCORE_READ = 'settings.risk-score.read',
  SETTINGS_RISK_SCORE_WRITE = 'settings.risk-score.write',
}

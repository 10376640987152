import { GridType } from 'Risk/store';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ApplicationPermission } from '../../enums/permission.enum';
import { usePermissions } from '../../hooks/usePermissionActions';
import { UserDetailsService } from 'shared/services/users/user-details.service';
import { useAuthUser } from '@frontegg/react';
import { Role } from 'Auth/store/api';

export interface TabListItem {
  id: any;
  label?: string;
  iconUrl?: string;
  props?: any;
  permissions?: ApplicationPermission[];
  roles?: Role[];
}

interface TabListProps extends BaseComponentProps {
  items: Array<TabListItem>;
  onSelect?: (id: any) => void;
  activeItemId: any;
  label?: string | JSX.Element;
  isUnderlined?: boolean;
}

const userDetailsService = new UserDetailsService();

export const TabList: FunctionComponent<TabListProps> = ({
  items,
  onSelect,
  activeItemId,
  label,
  isUnderlined = false,
}) => {
  const userPermissions = usePermissions();
  const user = useAuthUser();

  const onHandleSelect = (id: GridType) => {
    onSelect && onSelect(id);
  };

  return (
    <div className={`tab-list-container`}>
      {label ? (
        <div className="tab-list-container-label">{label}</div>
      ) : (
        <div></div>
      )}
      <div className={`tab-list-body ${isUnderlined && 'underlined'}`}>
        {items.map((item: TabListItem) => {
          const hasAllRoles = (item.roles || []).every((role) =>
            userDetailsService.hasRoleWithId(user, role)
          );

          return hasAllRoles ? (
            <button
              key={item.id}
              className={`tab-list-item ${
                activeItemId === item.id ? 'tab-list-item-active' : ''
              }`}
              disabled={
                !(item.permissions || []).every((permission) =>
                  userPermissions.includes(permission)
                )
              }
              type="button"
              onClick={() => onHandleSelect(item.id)}
            >
              <div className="tab-list-item-content">
                {item.iconUrl && (
                  <img
                    src={item.iconUrl}
                    alt={item.label}
                    className="tab-list-item-icon"
                  ></img>
                )}
                {item.label && <p>{item.label}</p>}
              </div>
            </button>
          ) : null;
        })}
      </div>
    </div>
  );
};

import { Box, Typography } from '@mui/material';
import { NodeProps } from 'reactflow';
import { Handle, Position } from 'react-flow-renderer';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import styles from 'FindingDetails/components/ResourceItemCell/ResourceItemCell.module.css';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

function IaCWorkflowNode({ data, depth }: any) {
  return (
    <Box className="iacNode" display={'flex'} p={1}>
      <Handle type="target" position={Position.Left} className="nodeHandle" />
      <div>
        {data.icon && (
          <OpusImageIcon
            className={`${styles.resourceIconWrapper} iacIcon`}
            imageClassName={styles.logo}
            src={data.icon}
            title={data.label}
          />
        )}
      </div>
      <div className="iac-node-label-group">
        <Typography className="iac-node-label" title={data.label}>
          {data.label}
        </Typography>
        {data.subLabel ? (
          <Typography className="iac-node-sub-label" title={data.subLabel}>
            {data.subLabel}
          </Typography>
        ) : (
          <></>
        )}
      </div>
      {data.count && (
        <div className="bug-count">
          <OpusSvgIcon type={SVG_ICON_TYPES.BRAKE_WARNING_ICON} />
          <Typography variant="inherit" className="label-4 ">
            {data.count}
          </Typography>
        </div>
      )}
      <Handle type="source" className="nodeHandle" position={Position.Right} />
    </Box>
  );
}

export default IaCWorkflowNode;

import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import OpusImageIcon from '../../../shared/components/IconComponents/OpusImageIcon';
import React from 'react';

export function ForbiddenPage() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box className="container">
      <Stack
        direction="column"
        spacing={1.5}
        maxWidth={362}
        alignItems="center"
      >
        <OpusImageIcon
          className="logoWrapper"
          title={'No Permission'}
          alt={'No Permission'}
          src="https://res.cloudinary.com/opus-security/image/upload/opus-logos/OpusNotFound.svg"
          imageClassName="logo"
        />
        <h1 className="page-header-title header-3">We Apologize</h1>
        <Typography fontSize={16} color="#7F7F7F" textAlign="center">
          But you don't have access permission to this page/resource.
        </Typography>
        <Button className="actionButton" onClick={goBack}>
          Back to the previous page
        </Button>
      </Stack>
    </Box>
  );
}

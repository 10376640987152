import React from 'react';

export interface HtmlContentProps {
  data: string;
}

export const CommonHtmlContent: React.FC<HtmlContentProps> = ({ data }) => {
  const modifiedData = data
    .replace(/<!--/g, '&lt;!--')
    .replace(/-->/g, '--&gt;');

  return <span dangerouslySetInnerHTML={{ __html: modifiedData }} />;
};

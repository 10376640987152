import { FC, ReactChild, useEffect, useMemo, useRef, useState } from 'react';
import { Tooltip, Typography, TypographyProps } from '@mui/material';
import styles from './TextOverflow.module.css';

export enum OverflowTextDisplay {
  ELLIPSIS = 'ellipsis',
  FULL = 'full',
}

export enum OverflowDirection {
  HEIGHT,
  WIDTH,
  ALL,
}

export interface TextOverflowProps extends TypographyProps {
  children: ReactChild;
  maxWidth?: number;
  maxHeight?: number;
  type: OverflowTextDisplay.ELLIPSIS | OverflowTextDisplay.FULL;
  direction?: OverflowDirection;
}

export const TextOverflow: FC<TextOverflowProps> = ({
  type = OverflowTextDisplay.ELLIPSIS,
  direction = OverflowDirection.HEIGHT,
  children,
  maxHeight,
  maxWidth,
  ...props
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const [parentWidth, setParentWidth] = useState<number | undefined>(
    ref.current?.parentElement?.offsetWidth
  );
  const parentElement = document.querySelector('.ag-cell') as HTMLElement;
  const [isOverflowWidth, setIsOverflowWidth] = useState(false);

  const enableTooltip = () => {
    const div = ref.current as HTMLDivElement;
    const isOverflowHeight =
      direction === OverflowDirection.HEIGHT ||
      direction === OverflowDirection.ALL
        ? maxHeight
          ? maxHeight < div.scrollHeight
          : div.offsetHeight < div.scrollHeight
        : false;

    const isOverflowWidth =
      direction === OverflowDirection.WIDTH ||
      direction === OverflowDirection.ALL
        ? maxWidth
          ? maxWidth < div.scrollWidth
          : div.offsetWidth < div.scrollWidth
        : false;

    setIsOverflowWidth(isOverflowWidth);

    if (isOverflowHeight) {
      div.classList.add(styles.truncatedTextHeight);
    }

    setTooltipEnabled(isOverflowWidth || isOverflowHeight);
  };

  const makeScrollableText = () => {
    const div = ref.current as HTMLDivElement;
    div.classList.add(styles.scrollableText);
    setTooltipEnabled(false);
  };

  useEffect(() => {
    if (!ref.current) return;
    if (parentElement) {
      setParentWidth(ref.current?.parentElement?.offsetWidth);
    } else {
      setParentWidth(maxWidth);
    }
    if (type === OverflowTextDisplay.ELLIPSIS) enableTooltip();
    if (type === OverflowTextDisplay.FULL) makeScrollableText();
  }, [ref.current?.parentElement?.offsetWidth, parentElement?.offsetWidth]);

  const classNames = useMemo(() => {
    if (isOverflowWidth) return 'truncated-text-width';
    return '';
  }, [isOverflowWidth]);

  const renderContent = () => (
    <Typography
      ref={ref}
      {...props}
      variant="inherit"
      className={classNames}
      sx={{
        maxWidth: parentWidth !== undefined ? `${parentWidth - 10}px` : 'unset',
      }}
    >
      {children}
    </Typography>
  );

  return (
    <>
      {ref.current && tooltipEnabled ? (
        <Tooltip title={children}>{renderContent()}</Tooltip>
      ) : (
        renderContent()
      )}
    </>
  );
};

import FormattedMessage from 'shared/components/FormattedMessage';
import PageContainer from 'shared/components/PageContainer';
import SettingsNavPage from '../SettingNavPage';

export default function SettingsMain() {
  return (
    <PageContainer
      title={<FormattedMessage id="settings.title" defaultMessage="Settings" />}
    >
      <SettingsNavPage />
    </PageContainer>
  );
}

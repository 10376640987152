import { FormControl, Grid, Input, Typography } from '@mui/material';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import FormattedMessage from '../FormattedMessage';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

export interface CustomFilterModalProps {
  handleModalClose: () => void;
  open: boolean;
  onSave: (name: string) => void;
}
export const CustomFilterModal: React.FC<CustomFilterModalProps> = ({
  handleModalClose,
  open,
  onSave,
}: CustomFilterModalProps) => {
  const { t: translation } = useTranslation();
  const formElement = useRef<HTMLFormElement>(null);
  const [filterName, setFilterName] = useState<string>('');

  useEffect(() => {
    if (!open) setFilterName('');
  }, [open]);

  return (
    <CommonDialog
      open={open}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.STAR_ICON} />}
      title={translation('findings.filter.newQuickFilter')}
      saveButtonText={translation('common.save')}
      loadingButtonText={translation('common.saving')}
      closeButtonText={translation('common.cancel')}
      onClose={() => {
        handleModalClose();
      }}
      onSave={() => {
        onSave(filterName);
      }}
      isOnSubmitDisabled={!filterName}
    >
      <div className="common-form-container quick-filter-create-form">
        <form ref={formElement} onSubmit={() => {}}>
          <Grid container rowGap={3}>
            <Grid item xs={12}>
              <Typography className="input-label">
                <FormattedMessage
                  id="findings.filter.newQuickFilterLabel"
                  defaultMessage="Your new quick filter name"
                />
              </Typography>
              <FormControl fullWidth className="input-dialog">
                <Input
                  name="filterName"
                  placeholder={'Quick Filter Name'}
                  disableUnderline
                  value={filterName}
                  onChange={(event: any) => {
                    setFilterName(event.target?.value);
                  }}
                  inputProps={{ required: true }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </div>
    </CommonDialog>
  );
};

import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import {
  useGetBusinessUnitListWithMetadataQuery,
  useGetUserAvailableNodesOfTypeMutation,
} from 'Dashboard/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { FunctionComponent, ReactNode, useEffect, useMemo } from 'react';
import { MinimalOrganizationNode } from 'shared/models/data/organization-node.model';
import {
  ReportRuleContentWidgetId,
  ReportRuleProperty,
  ReportRulePropertyType,
  operationalOverviewWidgetIds,
} from 'shared/models/data/report-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import DashboardReportsRiskTrendMetricsWidget from '../DashboardReportsRiskTrendMetricsWidget';
import DashboardReportsRiskTrendsWidget from '../DashboardReportsRiskTrendsWidget';
import { DashboardReportsRiskActiveCampaigns } from '../DashboardReportsRiskActiveCampaigns';
import DashboardReportsRiskOperationalOverviewWidget from '../DashboardReportsRiskOperationalOverviewWidget';
import DashboardReportsRiskTotalMetricsCard from '../DashboardReportsRiskTotalMetricsWidget/components/DashboardReportsRiskTotalMetricsCard';
import { DashboardReportsRiskTotalMetricsWidget } from '../DashboardReportsRiskTotalMetricsWidget';
import DashboardReportsRiskFindingBySeverityWidget from '../DashboardReportsRiskFindingBySeverityWidget';
import DashboardReportsRiskFindingByCategoryWidget from '../DashboardReportsRiskFindingByCategoryWidget';
import { UniqueBusinessUnitId } from 'Dashboard/components/BusinessUnitDashboardSection/BusinessUnitDashboardSection';
import { useSearchParams } from 'react-router-dom';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { OperationalWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';

export interface DashboardReportsRiskWidgetProps {
  businessUnitIds: Array<string>;
  findingTypes: Array<string>;
}

interface DashboardReportsRiskProps extends BaseComponentProps {
  widgetIds: Array<ReportRuleContentWidgetId>;
  searchParams?: Array<ReportRuleProperty>;
}

const initialReportSelectedTrendMetricTypes: Array<OperationalWidgetAnalyticsType> =
  [
    OperationalWidgetAnalyticsType.TOTAL_NEW_FINDINGS,
    OperationalWidgetAnalyticsType.RESOLVED_FINDINGS,
  ];

export const DashboardReportsRisk: FunctionComponent<
  DashboardReportsRiskProps
> = ({ widgetIds, searchParams }) => {
  const [params] = useSearchParams();

  const timeFrame = useMemo<TimelineFilter>(() => {
    if (params.get('configuration')) {
      const timeFrameOption: AutocompleteOption = JSON.parse(
        params.get('configuration') as string
      ).timeFrame;

      return timeFrameOption.value as TimelineFilter;
    }

    return TimelineFilter['60_DAYS'];
  }, [params.get('configuration')]);

  const trendMetricsTypes = useMemo<
    Array<OperationalWidgetAnalyticsType>
  >(() => {
    if (params.get('configuration')) {
      const trendMetricsTypeOptions: Array<AutocompleteOption> = JSON.parse(
        params.get('configuration') as string
      ).trendMetricsTypes;

      return trendMetricsTypeOptions?.length
        ? (trendMetricsTypeOptions.map(
            (option) => option.value
          ) as Array<OperationalWidgetAnalyticsType>)
        : initialReportSelectedTrendMetricTypes;
    }

    return initialReportSelectedTrendMetricTypes;
  }, [params.get('configuration')]);

  const [
    getUserAvailableNodesOfType,
    { data: nodeData, isLoading: nodeDataLoading },
  ] = useGetUserAvailableNodesOfTypeMutation();

  const getParamValues = (
    params: Array<ReportRuleProperty>,
    type: ReportRulePropertyType
  ) => {
    return (
      params
        ?.find((searchParam) => searchParam.type === type)
        ?.values?.map((valueItem: AutocompleteOption) => valueItem.value) || []
    );
  };

  const selectedBusinessUnitIds = useMemo<Array<string>>(() => {
    return searchParams
      ? getParamValues(searchParams, ReportRulePropertyType.SERVICE)
      : [];
  }, [searchParams]);

  const selectedFindingTypes = useMemo<Array<string>>(() => {
    return searchParams
      ? getParamValues(searchParams, ReportRulePropertyType.DOMAIN)
      : [];
  }, [searchParams]);

  const scopeIds = useMemo<Array<string>>(() => {
    return searchParams
      ? getParamValues(searchParams, ReportRulePropertyType.SCOPE)
      : [];
  }, [searchParams]);

  const groupIds = useMemo<Array<string>>(() => {
    return searchParams
      ? getParamValues(searchParams, ReportRulePropertyType.GROUP)
      : [];
  }, [searchParams]);

  const isAllGroupSelected = groupIds[0] === DashboardFilterType.ALL;
  const isAllScopeSelected = scopeIds[0] === DashboardFilterType.ALL;

  const businessUnitIds = useMemo<Array<string>>(() => {
    if (selectedBusinessUnitIds.length > 0) {
      return selectedBusinessUnitIds;
    }

    if (nodeData) {
      const nodeIds =
        nodeData?.map((node: MinimalOrganizationNode) => node?.id) || [];

      if (isAllGroupSelected && isAllScopeSelected)
        return [...nodeIds, UniqueBusinessUnitId.NO_BUSINESS_UNIT];

      return nodeIds;
    }
    return [];
  }, [
    nodeData,
    isAllGroupSelected,
    isAllScopeSelected,
    selectedBusinessUnitIds,
  ]);

  useEffect(() => {
    if (!isAllGroupSelected) {
      getUserAvailableNodesOfType({
        nodesType: OrganizationNodeType.DATA,
        startNodeId: groupIds[0],
      });
    } else if (!isAllScopeSelected) {
      getUserAvailableNodesOfType({
        nodesType: OrganizationNodeType.DATA,
        startNodeId: scopeIds[0],
      });
    } else {
      getUserAvailableNodesOfType({
        nodesType: OrganizationNodeType.DATA,
      });
    }
  }, [scopeIds, groupIds, isAllGroupSelected, isAllScopeSelected]);

  const renderRiskSection = (
    sectionType: ReportRuleContentWidgetId,
    SectionComponent: FunctionComponent<DashboardReportsRiskWidgetProps>
  ) => {
    return widgetIds.includes(sectionType) ? (
      <SectionComponent
        businessUnitIds={businessUnitIds}
        findingTypes={selectedFindingTypes}
      />
    ) : (
      <></>
    );
  };

  return (
    <div className="dashboard-reports-risk-container">
      {renderRiskSection(
        ReportRuleContentWidgetId.TOTAL_METRICS,
        DashboardReportsRiskTotalMetricsWidget
      )}
      {renderRiskSection(
        ReportRuleContentWidgetId.FINDING_BY_SEVERITY,
        DashboardReportsRiskFindingBySeverityWidget
      )}

      {renderRiskSection(
        ReportRuleContentWidgetId.FINDING_BY_CATEGORY,
        DashboardReportsRiskFindingByCategoryWidget
      )}

      {widgetIds.includes(ReportRuleContentWidgetId.TRENDS_METRICS) ? (
        <DashboardReportsRiskTrendMetricsWidget
          businessUnitIds={businessUnitIds}
          timeFrame={timeFrame}
          findingTypes={selectedFindingTypes}
        />
      ) : (
        <></>
      )}

      {widgetIds.includes(ReportRuleContentWidgetId.TRENDS_GRAPH) ? (
        <DashboardReportsRiskTrendsWidget
          businessUnitIds={businessUnitIds}
          timeFrame={timeFrame}
          trendMetricsTypes={trendMetricsTypes}
          findingTypes={selectedFindingTypes}
        />
      ) : (
        <></>
      )}

      {operationalOverviewWidgetIds.some(
        (widgetId: ReportRuleContentWidgetId) => widgetIds.includes(widgetId)
      ) ? (
        <DashboardReportsRiskOperationalOverviewWidget
          businessUnitIds={businessUnitIds}
          findingTypes={selectedFindingTypes}
        />
      ) : (
        <></>
      )}

      {renderRiskSection(
        ReportRuleContentWidgetId.ACTIVE_CAMPAIGNS,
        DashboardReportsRiskActiveCampaigns
      )}
    </div>
  );
};

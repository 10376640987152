export enum CampaignCreateFormBusinessPriority {
  LOW = 'Low',
  MODERATE = 'Moderate',
  HIGH = 'High',
  URGENT = 'Urgent',
}

export enum CampaignCreateFormDateRange {
  ONE_WEEK = '1 Week',
  TWO_WEEKS = '2 Weeks',
  MONTH = '1 Month',
  QUARTER = '3 Months',
  CUSTOM = 'Custom',
}

export enum TypeFilter {
  MULTI_SELECT = 'MultiSelection',
  SINGLE_SELECT = 'SingleSelection',
  DATE_PICKER_RANGE = 'DatePickerRange',
  NUMBER = 'NUMBER',
}

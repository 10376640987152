import { Button, Menu, MenuItem, Popover, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import styles from './more-actions-menu.module.scss';

interface ActionItem {
  name: string;
  icon?: SVG_ICON_TYPES;
  onClick?: () => void;
  disabled?: boolean;
}

interface MoreActionsMenuProps extends BaseComponentProps {
  actions?: ActionItem[];
}

export const MoreActionsMenu: FunctionComponent<MoreActionsMenuProps> = ({
  actions,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id={styles.menuButton}
        aria-controls={open ? 'node-action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuClick}
        className={styles.menuButton}
      >
        <OpusSvgIcon type={SVG_ICON_TYPES.ELLIPSIS_STROKE_ICON} />
      </Button>
      <Popover
        anchorEl={anchorEl}
        id="more-actions-menu"
        open={open}
        onClose={handleClose}
        className={`popover-filter`}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {actions?.map((action: any) => (
          <MenuItem
            onClick={() => {
              action.onClick && action.onClick();
              setAnchorEl(null);
            }}
            disabled={action.disabled}
            className="dropdown-menu-item"
          >
            {action.icon && (
              <OpusSvgIcon type={action.icon} className={'chip-status-icon'} />
            )}
            <Typography className="menu-item-text">{action.name}</Typography>
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

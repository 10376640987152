import { FunctionComponent, useEffect } from 'react';
import {
  CommonModalContainer,
  CommonModalContainerProps,
} from '../CommonModalContainer/CommonModalContainer';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { InputLabelWrapper } from '../InputLabelWrapper/InputLabelWrapper';
import { useTranslation } from 'react-i18next';
import { CommonButton } from '../CommonButton';
import { CommonButtonType } from '../CommonButton/CommonButton';
import { FormattedMessage } from '../FormattedMessage/FormattedMessage';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FindingExceptionTarget } from 'shared/enums/finding-exception.enum';
import Autocomplete from 'Common/components/Autocomplete';
import {
  findingExceptionCreateFormSchema,
  findingExceptionTimeOptions,
} from 'shared/fixtures/data/finding-exception.data';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import {
  AutocompleteOption,
  useCreateFindingExceptionMutation,
} from 'FindingDetails/store/api';
import { AlertExpandBox } from '../AlertExpandBox/AlertExpandBox';
import { AlertBoxType } from '../AlertBox/AlertBox';
import { useQueryParams } from 'shared/hooks/useQueryParams';

interface FindingExceptionCreateModalProps
  extends Partial<CommonModalContainerProps> {}

export const FindingExceptionCreateModal: FunctionComponent<
  FindingExceptionCreateModalProps
> = ({ handleClose, isOpen }) => {
  const { t: translation } = useTranslation();

  const [{ openFindingId: findingId }] = useQueryParams();

  const [
    createFindingException,
    {
      isLoading: createFindingExceptionLoading,
      isSuccess: createFindingExceptionSuccess,
      reset: createFindingExceptionReset,
      error: createFindingExceptionError,
    },
  ] = useCreateFindingExceptionMutation();

  const {
    register,
    setValue: setFormValue,
    formState: { errors: formErrors },
    handleSubmit,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(findingExceptionCreateFormSchema),
    defaultValues: {
      findingId,
      message: '',
      revokeTimeCount: 1,
      revokeTimeType: findingExceptionTimeOptions[0].value,
    },
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (createFindingExceptionSuccess) modalCloseHandler();
  }, [createFindingExceptionSuccess]);

  const modalCloseHandler = () => {
    handleClose && handleClose();
    resetForm();
    createFindingExceptionReset();
  };

  const revokeTimeTypeHandler = (autocompleteOption: AutocompleteOption) => {
    setFormValue('revokeTimeType', autocompleteOption.value);
  };

  const formSubmitHandler = () => {
    handleSubmit(formValidHandler)();
  };

  const formValidHandler = (formValues: any) => {
    createFindingException(formValues);
  };

  const renderErrorMessage = () => {
    if (createFindingExceptionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          description={(createFindingExceptionError as any)?.data?.message}
          onClose={createFindingExceptionReset}
          title={translation(`exceptions.details.createErrorMessage`)}
        />
      );
    }
    return <></>;
  };

  return (
    <CommonModalContainer
      handleClose={modalCloseHandler}
      isOpen={Boolean(isOpen)}
      title={translation(`exceptions.modals.createRequestExceptionTitle`)}
      components={{
        TitleIcon: <OpusSvgIcon type={SVG_ICON_TYPES.LIGHT_EMERGENCY_ICON} />,
      }}
      rootClassName="finding-exception-modal-container"
    >
      <div className="finding-exception-form-container">
        <form className="finding-exception-form">
          {renderErrorMessage()}

          <InputLabelWrapper
            label={translation(`exceptions.forms.createExceptionRule`)}
            classes={{
              label: 'finding-exception-bold-label',
            }}
          >
            <div className="finding-exception-radio-group-container">
              <RadioGroup name="finding-exception-radio-group">
                <FormControlLabel
                  value={FindingExceptionTarget.FINDING}
                  control={
                    <Radio
                      checked
                      icon={
                        <OpusSvgIcon
                          type={SVG_ICON_TYPES.RADIO_BUTTON_UNCHECKED_ICON}
                        />
                      }
                      checkedIcon={
                        <OpusSvgIcon
                          type={SVG_ICON_TYPES.RADIO_BUTTON_CHECKED_ICON}
                        />
                      }
                    />
                  }
                  label={translation(`exceptions.forms.thisSpecificAlert`)}
                />
              </RadioGroup>
            </div>
          </InputLabelWrapper>

          <InputLabelWrapper
            label={translation(`exceptions.forms.revokeExceptionIn`)}
            components={{
              EndIcon: <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />,
            }}
            errorMessage={formErrors.revokeTimeCount?.message as string}
          >
            <div className="finding-exception-input-group-container">
              <input
                type="number"
                className={`text-field-1 ${
                  formErrors?.revokeTimeCount?.message
                    ? 'input-validation-error-1'
                    : ''
                }`}
                min={1}
                {...register('revokeTimeCount')}
              />
              <div className="finding-exception-revoke-time-option-input">
                <Autocomplete
                  model="finding-exception-time"
                  single
                  optionList={findingExceptionTimeOptions}
                  onChangeCallBack={(model: string, autocompleteOption) => {
                    revokeTimeTypeHandler(
                      autocompleteOption as AutocompleteOption
                    );
                  }}
                  classes={{
                    root: 'multi-select-field-1',
                    paper: 'multi-select-field-paper-1',
                  }}
                  initialSelectedValues={findingExceptionTimeOptions[0]}
                />
              </div>
            </div>
          </InputLabelWrapper>

          <div className="finding-exception-form-breaker"></div>
          <InputLabelWrapper
            label={translation(`exceptions.forms.explanationForYourRequest`)}
            errorMessage={formErrors?.message?.message as string}
          >
            <textarea
              rows={4}
              className={`text-area-field-1 ${
                formErrors?.message?.message ? 'input-validation-error-1' : ''
              }`}
              {...register('message')}
            />
          </InputLabelWrapper>
        </form>
        <div className="finding-exception-form-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={modalCloseHandler}
          >
            <FormattedMessage
              key={`common.cancel`}
              defaultMessage="Cancel"
              capitalize
            />
          </CommonButton>

          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={formSubmitHandler}
          >
            <FormattedMessage
              key={
                createFindingExceptionLoading
                  ? `exceptions.buttons.sendingRequest`
                  : `exceptions.buttons.sendRequest`
              }
              defaultMessage={
                createFindingExceptionLoading
                  ? 'Sending Request'
                  : 'Send Request'
              }
              capitalize
            />
          </CommonButton>
        </div>
      </div>
    </CommonModalContainer>
  );
};

import { IconButton, Tooltip } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { darkPalette } from 'Theme';
import CommonIconMui from '../IconMui/Icon';
import classes from './IconLink.module.css';
import classNames from 'classnames';
interface CommonIconLinkProps {
  icon: string;
  to: string;
  isOutlined?: boolean;
  tooltipTitle?: string;
  tooltipIndex?: number;
  styleIcon?: string;
  textStyle?: string;
}

export default function CommonIconLinkMui({
  icon,
  to,
  isOutlined = false,
  tooltipTitle = '',
  tooltipIndex,
  textStyle,
  styleIcon,
}: CommonIconLinkProps) {
  const { pathname } = useLocation();
  const { primary, disabled } = darkPalette.text;

  function getIsActive(): boolean {
    if (to === '/') {
      return pathname === to;
    }
    return !!pathname.match(to);
  }

  const color = getIsActive() ? primary : disabled;

  return (
    <div className={classes.iconLink}>
      <IconButton component={Link} to={to}>
        <CommonIconMui
          isOutlined={isOutlined}
          icon={icon}
          color={color}
          styleIcon={styleIcon}
        />
      </IconButton>
      <span
        className={classNames([classes.menuIconText, textStyle])}
        color={color}
      >
        {tooltipTitle}
      </span>
    </div>
  );
}

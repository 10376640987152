import { FunctionComponent } from 'react';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { FindingContentIntelligenceReferencesSection } from '../FindingContentIntelligenceReferencesSection/FindingContentIntelligenceReferencesSection';
import {
  VulnerabilityMetadataReferences,
  VulnerabilityMetadataScores,
  VulnerabilityMetadataReferenceType,
} from 'shared/models/data/vulnerability.model';
import { capitalize } from 'lodash';

export const referenceSectionIcons: Record<
  VulnerabilityMetadataReferenceType,
  SVG_ICON_TYPES
> = {
  [VulnerabilityMetadataReferenceType.CVE]: SVG_ICON_TYPES.FILE_ICON,
  [VulnerabilityMetadataReferenceType.EXPLOITS]: SVG_ICON_TYPES.SKULL_ICON,
  [VulnerabilityMetadataReferenceType.MALWARES]: SVG_ICON_TYPES.BIOHAZARD_ICON,
  [VulnerabilityMetadataReferenceType.PATCHES]: SVG_ICON_TYPES.BADGE_CHECK_ICON,
};

interface FindingContentIntelligenceReferencesProps
  extends BaseComponentProps,
    VulnerabilityMetadataScores {
  references: VulnerabilityMetadataReferences;
}

export const FindingContentIntelligenceReferences: FunctionComponent<
  FindingContentIntelligenceReferencesProps
> = ({ references, threats, patches }) => {
  const calculateValue = (key: VulnerabilityMetadataReferenceType) => {
    switch (key) {
      case VulnerabilityMetadataReferenceType.CVE:
        return references[key].length;
      case VulnerabilityMetadataReferenceType.MALWARES:
        return threats.malwareCount;
      case VulnerabilityMetadataReferenceType.PATCHES:
        return Object.values(patches).reduce((acc, value) => acc + value, 0);
      case VulnerabilityMetadataReferenceType.EXPLOITS:
        return threats.exploitCount;
      default:
        return 0;
    }
  };

  return (
    <div className="finding-content-item finding-intelligence-references">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-section-risks"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.CLIPBOARD_LIST_ICON} />}
        title="References"
      >
        {(
          Object.keys(references) as Array<VulnerabilityMetadataReferenceType>
        ).map((referenceKey: VulnerabilityMetadataReferenceType) => {
          const referenceLinks = references[referenceKey];
          return (
            <FindingContentIntelligenceReferencesSection
              titleProps={{
                icon: referenceSectionIcons[referenceKey],
                label: capitalize(referenceKey),
                value: calculateValue(referenceKey),
              }}
              links={referenceLinks}
            />
          );
        })}
      </ContentSection>
    </div>
  );
};

import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';
import { AutocompleteOption } from 'FindingDetails/store/api';
import ConnectionItem, {
  Parameter,
} from 'Integrations/Connection/interfaces/item';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { ExtendedBusinessUnitListItemModel } from 'shared/models/data/extended-business-unit.model';
import { IntegrationActionType } from 'shared/models/data/integrations.model';

export class IntegrationsDataHandler {
  transformBusinessUnitsToOptionList(
    businessUnits: Array<ExtendedBusinessUnitListItemModel>
  ): Array<AutocompleteOption> {
    return businessUnits.map((businessUnit) => ({
      value: businessUnit.id,
      label: businessUnit.name,
    }));
  }

  transformIntegrationsFormDataToPostData(
    formData: any,
    application: IntegrationItem,
    connectionTemplate?: ConnectionTemplateItem
  ): Partial<ConnectionItem> {
    let connectionItem: Partial<ConnectionItem> = {
      name: formData.name?.trim(),
      businessUnitIds: formData.enviroments.map(
        (environment: AutocompleteOption) => environment.value
      ),
      cloudWorkspaceIds: [],
      applicationId: application.id,
      connectionTemplateId: connectionTemplate?.id || '',
      definition: connectionTemplate?.definition || {},
      isFallback: formData.isFallback,
      parameters: [],
      metadata: {},
      scopeId:
        formData.scopeId && formData.scopeId !== 'None'
          ? formData.scopeId
          : null,
    };

    delete formData.name;
    delete formData.enviroments;
    delete formData.isFallback;

    if (connectionTemplate) {
      const parameters: Array<Parameter> =
        connectionTemplate.parameters?.map((parameter) => {
          return {
            ...parameter,
            value: formData[parameter.name]?.trim() || '',
          };
        }) || [];

      connectionItem.parameters = parameters;
    }

    return connectionItem;
  }

  transformIntegrationDataToFormData(
    integration: ConnectionItem,
    businessUnitsOptions?: Array<AutocompleteOption>
  ): {
    [key: string]: any;
  } {
    const additionalFormData = integration.parameters.reduce(
      (previousValue: any, currentValue: Parameter) => {
        previousValue[currentValue.name] = currentValue.value;
        return previousValue;
      },
      {}
    );

    const businessUnitSelections =
      businessUnitsOptions?.filter((businessUnitOption) => {
        return integration.businessUnitIds?.includes(
          businessUnitOption?.value as string
        );
      }) || [];

    return {
      name: integration.name,
      enviroments: businessUnitSelections,
      scopeId: integration?.scopeId || 'None',
      isFallback: integration.isFallback,
      ...additionalFormData,
    };
  }

  getIntegrationErrorMessage(
    translations: any,
    integrationActionType: IntegrationActionType,
    applicationName: string
  ): string {
    switch (integrationActionType) {
      case IntegrationActionType.VERIFYING:
        return translations(`connections.errors.verifyErrorMessage`, {
          applicationName,
        });
      default:
        return translations(`connections.errors.completeErrorMessage`, {
          applicationName,
        });
    }
  }
  getSelectedScopeOption(scopeList: AutocompleteOption[], scopeId?: string) {
    const id = scopeId || 'None';
    return scopeList.find((option) => option.value === id);
  }
}

import { useValidateJwtTokenMutation } from 'Auth/store/api';
import ForbiddenPage from 'Common/components/ForbiddenPage';
import { FunctionComponent, useEffect } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import DashboardReportsHeader from './components/DashboardReportsHeader';
import DashboardReportsDescription from './components/DashboardReportsDescription';
import DashboardReportsWidgets from './components/DashboardReportsWidgets';

interface DashboardReportsProps extends BaseComponentProps {}

export const DashboardReports: FunctionComponent<
  DashboardReportsProps
> = () => {
  const [
    validateJwtToken,
    {
      isSuccess: validJwtToken,
      isLoading: validatingJwtToken,
      isError: invalidJwtToken,
    },
  ] = useValidateJwtTokenMutation();

  useEffect(() => {
    validateJwtToken({});
  }, []);

  const renderReports = () => {
    if (validatingJwtToken) return <></>;

    if (invalidJwtToken) return <ForbiddenPage />;

    if (validJwtToken)
      return (
        <div className="dashboard-reports-container">
          <DashboardReportsHeader />
          <DashboardReportsDescription />
          <DashboardReportsWidgets />
        </div>
      );

    return <></>;
  };

  return renderReports();
};

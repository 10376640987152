import { FunctionComponent } from 'react';
import AutomationManagementViewHeader from './components/AutomationManagementHeader';
import AutomationManagementTable from './components/AutomationManagementTable';

export const AutomationManagement: FunctionComponent = () => {
  return (
    <div className="automation-management-view-container">
      <AutomationManagementViewHeader />
      <AutomationManagementTable />
    </div>
  );
};

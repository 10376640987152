import { Divider, Grid, GridProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MenuItem, MenuItemProps } from '../MenuItem/MenuItem';

interface SideMenuNavigationProps {
  menuList: MenuItemProps[];
  grid?: GridProps;
}
export const SideMenuNavigation: React.FC<SideMenuNavigationProps> = ({
  menuList,
  grid,
}) => {
  return (
    <Grid
      container
      className="side-menu-container"
      {...grid}
      flexDirection="column"
    >
      <>
        {menuList.map((menu) => (
          <>
            <MenuItem {...menu} />
          </>
        ))}
      </>
    </Grid>
  );
};

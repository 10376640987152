import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  FindingExceptionStatus,
  FindingExceptionTimeOption,
} from 'shared/enums/finding-exception.enum';
import * as yup from 'yup';

export const findingExceptionTimeOptions: Array<AutocompleteOption> = [
  {
    value: FindingExceptionTimeOption.DAYS,
    label: FindingExceptionTimeOption.DAYS,
  },
  {
    value: FindingExceptionTimeOption.MONTHS,
    label: FindingExceptionTimeOption.MONTHS,
  },
];

export const findingExceptionStatusToLabel: {
  [key in FindingExceptionStatus]: string;
} = {
  [FindingExceptionStatus.ACTIVE]: 'Active',
  [FindingExceptionStatus.DECLINED]: 'Declined',
  [FindingExceptionStatus.PENDING]: 'Pending Approval',
  [FindingExceptionStatus.EXPIRED]: 'Expired',
  [FindingExceptionStatus.REVOKED]: 'Revoked',
  [FindingExceptionStatus.CANCELED]: 'Canceled',
};

export const findingExceptionNegativeStatusList: Array<FindingExceptionStatus> =
  [
    FindingExceptionStatus.DECLINED,
    FindingExceptionStatus.EXPIRED,
    FindingExceptionStatus.REVOKED,
    FindingExceptionStatus.CANCELED,
  ];

export const findingExceptionCreateFormSchema = yup.object().shape({
  findingId: yup.string().required().label('Finding ID'),
  message: yup.string().required().label('Explanation Message'),
  revokeTimeCount: yup
    .number()
    .required()
    .min(1)
    .label('Revoke Time Count')
    .typeError('Revoke Time Count is a required field'),
  revokeTimeType: yup.string().required().label('Revoke Time Type'),
});

export const findingExceptionRevokeFormSchema = yup.object().shape({
  message: yup.string(),
});

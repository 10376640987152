import { useTranslation } from 'react-i18next';
import FindingItem from 'shared/components/FindingItem';
import { RiskTag } from './FindingContentMetrics';

interface FindingContentMetricRowProps {
  title?: string;
  data: Array<RiskTag>;
}
export const FindingContentMetricRow: React.FC<
  FindingContentMetricRowProps
> = ({ title, data }) => {
  const { t: translation } = useTranslation();

  return (
    <div className="finding-content-item finding-intelligence-metrics-row">
      {title && (
        <div className="title">
          <p className="body-1 bold">{title}</p>
        </div>
      )}
      <div className="metrics-data">
        {data.map(({ type, value }) => {
          return (
            <FindingItem title={type}>
              <p>{value || 'N/A'}</p>
            </FindingItem>
          );
        })}
      </div>
    </div>
  );
};

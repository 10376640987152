import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ActionListDetails } from '../ActionListDetails';
import { ActionListDetailsProps } from '../ActionListDetails/ActionListDetails';

export interface ActionResponseModalProps extends BaseComponentProps {
  isOpen: boolean;
  handleClose: () => void;
  title: string;
  listsDetails: Array<ActionListDetailsProps>;
}

export const ActionResponseModal: FunctionComponent<
  ActionResponseModalProps
> = ({ listsDetails, title, isOpen, handleClose }) => {
  return (
    <CommonModalContainer
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <div className="risk-action-response-container">
        {listsDetails?.map((list: ActionListDetailsProps) => (
          <ActionListDetails {...list} />
        ))}
        <div className="risk-action-response-list-buttons">
          <Button
            className="action-base-button action-response-base-button"
            onClick={handleClose}
          >
            Got it
          </Button>
        </div>
      </div>
    </CommonModalContainer>
  );
};

import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import FindingContentDescription from '../FindingContentDescription';
import FindingContentIntelligenceScores from '../FindingContentIntelligenceScores';
import FindingContentIntelligenceVendors from '../FindingContentIntelligenceVendors';
import FindingContentIntelligenceThreatActors from '../FindingContentIntelligenceThreatActors';
import FindingContentIntelligenceWeaknesses from '../FindingContentIntelligenceWeaknesses';
import FindingContentIntelligenceDiscussions from '../FindingContentIntelligenceDiscussions';
import FindingContentIntelligenceThreats from '../FindingContentIntelligenceThreats';
import FindingContentIntelligenceReferences from '../FindingContentIntelligenceReferences';
import { VulnerabilityMetadata } from 'shared/models/data/vulnerability.model';
import { CircularProgress, Skeleton } from '@mui/material';
import { useFetchVulnerabilityMetadataByIdQuery } from 'FindingDetails/store/api';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';

export const findingIntelligenceDateMonthFormat = 'DD MMM YYYY';
export const findingIntelligenceDateNumberFormat = 'DD/MM/YYYY';

interface FindingContentIntelligenceProps extends BaseComponentProps {
  vulnerabilityId?: string;
}

export const FindingContentIntelligence: FunctionComponent<
  FindingContentIntelligenceProps
> = ({ vulnerabilityId }) => {
  const hasVulnerabilityId = Boolean(vulnerabilityId);

  const {
    data: vulnerabilityData,
    isLoading: vulnerabilityDataLoading,
    error: vulnerabilityDataError,
  } = useFetchVulnerabilityMetadataByIdQuery(vulnerabilityId || '', {
    skip: !hasVulnerabilityId,
  });

  const renderContent = () => {
    if (vulnerabilityDataLoading)
      return (
        <div className="finding-intelligence-content-loading">
          <CircularProgress />
        </div>
      );

    if (
      !hasVulnerabilityId ||
      (vulnerabilityDataLoading === false && vulnerabilityData === undefined) ||
      vulnerabilityDataError
    )
      return <NoDataToDisplayCard displayDescription={false} />;

    return renderBody();
  };

  const renderBody = () => {
    if (!vulnerabilityData) return <></>;

    return (
      <div className="finding-content-details-container finding-intelligence-content">
        <FindingContentDescription
          description={vulnerabilityData.description}
        />
        <FindingContentIntelligenceScores {...vulnerabilityData.scores} />
        <FindingContentIntelligenceVendors
          vendors={vulnerabilityData.mostAffectedVendors}
        />
        <FindingContentIntelligenceThreats
          threats={vulnerabilityData.threats}
        />
        <FindingContentIntelligenceThreatActors
          threatActors={vulnerabilityData.threatActors}
        />
        <FindingContentIntelligenceWeaknesses
          weaknesses={vulnerabilityData.weaknesses}
        />
        <FindingContentIntelligenceDiscussions
          discussions={vulnerabilityData.discussions}
        />
        <FindingContentIntelligenceReferences
          {...vulnerabilityData.scores}
          references={vulnerabilityData.references}
        />
      </div>
    );
  };

  return renderContent();
};

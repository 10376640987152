import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
interface CopyLinkButtonProps {
  excludeSearchParams?: string[];
}

export default function CopyLinkButton({
  excludeSearchParams,
}: CopyLinkButtonProps) {
  const { t: translation } = useTranslation();
  const [currentWindowLocation, setCurrentWindowLocation] = useState(
    window.location
  );
  const [copyUrl, setCopyUrl] = useState<string>(
    translation(`common.copyLink`)
  );

  useEffect(() => {
    setCurrentWindowLocation(window.location);
  }, [
    window.location.search,
    window.location.pathname,
    window.location.origin,
  ]);

  const copyParseUrlToClipboard = async () => {
    let searchParams = new URLSearchParams(currentWindowLocation.search);
    const searchParamsObj: any = {};
    searchParams.forEach((value, key) => {
      searchParamsObj[key] = value;
    });

    for (const key in searchParamsObj) {
      if (excludeSearchParams && excludeSearchParams.includes(key)) {
        searchParams.delete(key);
      }
    }
    const newSearch = searchParams.toString();
    const newPathname = currentWindowLocation.pathname;
    const urlOrigin = currentWindowLocation.origin;
    const urlToCopy = `${urlOrigin}${newPathname}?${newSearch}`;

    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(urlToCopy);
    } else {
      return document.execCommand('copy', true, urlToCopy);
    }
  };

  const handleCopyClick = async () => {
    await copyParseUrlToClipboard();
    setCopyUrl(translation(`common.copiedLink`));
    setTimeout(() => {
      setCopyUrl(translation(`common.copyLink`));
    }, 1500);
  };

  return (
    <Tooltip title={copyUrl} leaveDelay={1000}>
      <IconButton
        aria-label={translation(`common.copyLink`)}
        id="copy-link-button-root"
        onClick={handleCopyClick}
      >
        <OpusSvgIcon type={SVG_ICON_TYPES.HUG_ICON} />
      </IconButton>
    </Tooltip>
  );
}

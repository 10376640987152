import { createTheme } from '@mui/material';
import defaultTheme from 'Theme';

const diagramTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 7,
          fontWeight: 700,
          textTransform: 'none',
        },
        // @ts-ignore
        containedSuccess: {
          // it works, but TypeScript doesn't recognize it for some reason
          color: 'white',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 7,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 7,
        },
      },
    },
    MuiTypography: defaultTheme.components?.MuiTypography,
  },
  palette: {
    primary: {
      main: '#6664ED',
    },
    success: {
      main: '#5CC597',
    },
  },
  spacing: (factor: number) => `${0.5 * factor}rem`, // 1 unit = 0.5rem
  typography: {
    fontFamily: '"HEX Franklin v0.2"',
  },
});

export default diagramTheme;

import { BaseCommonIconCell } from 'shared/components/CommonIconCell/CommonIconCell';

export class RiskFindingDetailsHandler {
  transformAppListToCommonIconsList(
    apps: Array<any>,
    labelKey: string,
    iconKey: string
  ): Array<BaseCommonIconCell> {
    return apps?.map((app: any) => ({
      label: app[labelKey],
      iconUrl: app[iconKey],
    }));
  }
}

import { FunctionComponent, useMemo, useState } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  ApiStaticOptionSourceProperties,
  KeyValueSelection,
  KeyValueSource,
  KeyValueState,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useKeyValueOptions } from './useKeyValueOptions';
import KeyValueSelectionRow from './KeyValueSelectionRow';
import { Button } from '@mui/material';

interface KeyValueInputProps extends BaseComponentProps, KeyValueState {
  title: string;
  onChange: (selections: Array<KeyValueSelection>) => void;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
}

export const KeyValueInput: FunctionComponent<KeyValueInputProps> = ({
  title,
  selections,
  keySource,
  valueSource,
  actionHandlers,
  onChange,
}) => {
  const renderTitleArea = () => {
    return (
      <div className="filter-title-container">
        {actionHandlers?.goBackHandler ? (
          <div className="filter-go-back-button">
            <div
              onClick={() => {
                actionHandlers.goBackHandler && actionHandlers.goBackHandler();
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="filter-title">{title}</div>
      </div>
    );
  };

  const addTagHandler = () => {
    onChange([
      ...(selections || []),
      {
        key: {
          value: '',
          label: '',
        },
        values: [],
      },
    ]);
  };

  const removeTagHandler = (index: number) => {
    if (index === 0 && selections?.length === 1) {
      onChange([
        {
          key: {
            value: '',
            label: '',
          },
          values: [],
        },
      ]);

      return;
    }

    const filteredSelections =
      selections?.filter((selection, itemIndex) => index !== itemIndex) || [];
    onChange(filteredSelections);
  };

  const changeTagHandler = (index: number, selection: KeyValueSelection) => {
    const updatedSelections =
      selections?.map((selectionItem, itemIndex) => {
        if (itemIndex === index) return selection;

        return selectionItem;
      }) || [];

    onChange(updatedSelections);
  };

  const renderSelections = () => {
    return selections?.map((selection, index) => (
      <KeyValueSelectionRow
        selection={selection}
        onChange={(selection: KeyValueSelection) => {
          changeTagHandler(index, selection);
        }}
        onRemove={() => {
          removeTagHandler(index);
        }}
        keySource={keySource}
        valueSource={valueSource}
        disableRemoveButton={Boolean(
          index === 0 &&
            selections.length === 1 &&
            selections[0].key.value.length === 0 &&
            selections[0].values?.length === 0
        )}
      />
    ));
  };
  return (
    <div className="key-value-input">
      {renderTitleArea()}
      <div className="key-value-selections">
        <div className="key-value-selection-area">{renderSelections()}</div>
        <div className="key-value-add-button">
          <Button className="base-opus-text-button" onClick={addTagHandler}>
            <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />
            <span>Add Tag</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

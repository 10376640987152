import { Divider } from '@mui/material';
import FindingsTagWithIcon from 'FindingDetails/components/FindingsTagWithIcon/FindingsTagWithIcon';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FindingContentMetricRow } from './FindingContentMetricRow';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  VulnerabilityCvssV3,
  VulnerabilityMetadataScores,
} from 'shared/models/data/vulnerability.model';
import { FunctionComponent } from 'react';
import moment from 'moment';
import { findingIntelligenceDateMonthFormat } from '../FindingContentIntelligence/FindingContentIntelligence';

export interface RiskTag {
  type: string;
  key: string;
  value?: string;
}

export interface RiskCategory {
  type: string;
  title: string;
  data: RiskTag[];
  key: string;
}

export interface RiskTagsList {
  [key: string]: RiskCategory;
}

interface FindingContentMetricsProps
  extends BaseComponentProps,
    Pick<VulnerabilityMetadataScores, 'cvssv3' | 'epss'> {}

export const FindingContentMetrics: FunctionComponent<
  FindingContentMetricsProps
> = ({ cvssv3, epss }) => {
  const { t: translation } = useTranslation();

  const metricsData: Array<RiskCategory> = [
    {
      type: 'base',
      title: 'Base  Metrics (Exploitability Metrics)',
      key: 'exploitabilityMetrics',
      data: [
        {
          type: 'Vector',
          key: 'vector',
        },
        {
          type: 'Attack Vector (AV)*',
          key: 'attackVector',
        },
        {
          type: 'Attack Complexity (AC)*',
          key: 'attackComplexity',
        },
        {
          type: 'Privileges Required (PR)*',
          key: 'privilegesRequired',
        },
        {
          type: 'User Interaction (UI)*',
          key: 'userInteraction',
        },
        {
          type: 'Scope (S)*',
          key: 'scope',
        },
      ],
    },
    {
      type: 'impact',
      title: 'Base  Metrics (Impact Metrics)',
      key: 'impactMetrics',
      data: [
        {
          type: 'Confidentiality Impact (C)*',
          key: 'confidentialityImpact',
        },
        {
          type: 'Integrity Impact (I)*',
          key: 'integrityImpact',
        },
        {
          type: 'Availability Impact (A)*',
          key: 'availabilityImpact',
        },
      ],
    },
    {
      type: 'base',
      title: 'Temporal Metrics',
      key: 'temporalMetrics',
      data: [
        {
          type: 'Temporal Vector',
          key: 'vector',
        },
        {
          type: 'Exploit Code Maturity (E)*',
          key: 'exploitCodeMaturity',
        },
        {
          type: 'Remediation Level (RL)*',
          key: 'remediationLevel',
        },
        {
          type: 'Report Confidence (RC)*',
          key: 'reportConfidence',
        },
      ],
    },
  ];

  const epssData: Array<RiskTag> = [
    {
      type: 'Date Updated',
      key: 'lastModifiedDate',
      value: moment(epss.lastModifiedDate).format(
        findingIntelligenceDateMonthFormat
      ),
    },
    {
      type: 'Percentile',
      key: 'percentile',
      value: epss.percentile,
    },
    {
      type: '30 day trend',
      key: 'last30DaysTrend',
      value: epss.last30DaysTrend,
    },
  ];

  return (
    <div className="finding-content-item finding-intelligence-metrics">
      {cvssv3 && (
        <div className="metric-title-container">
          <p className="body-1 bold">CVSS V3 </p>
          <div className="warning-tag">
            <p className="header-7 ">
              {Number(cvssv3.exploitabilityMetrics.score).toFixed(2)}
            </p>
          </div>
        </div>
      )}
      {cvssv3 &&
        metricsData.map(({ title, data, key }) => {
          const dataCategory = cvssv3[key as keyof VulnerabilityCvssV3];

          const mappedData = data.map((dataItem) => ({
            ...dataItem,
            value: dataCategory[dataItem.key as keyof typeof dataCategory],
          }));

          return (
            <>
              <FindingContentMetricRow title={title} data={mappedData} />
              <Divider className="widget-divider" />
            </>
          );
        })}
      <div className="metric-title-container">
        <p className="body-1 bold">EPSS</p>
        <div className="warning-tag">
          <p className="header-7 ">
            {epss.probability === null ? 'N/A' : Number(epss.probability)}
          </p>
        </div>
      </div>
      <FindingContentMetricRow data={epssData} />
    </div>
  );
};

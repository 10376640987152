import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { AuthorizedContent } from '@frontegg/react';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

export interface CommonDialogProps {
  open: boolean;
  onClose: () => void;
  onSave?: (e?: React.MouseEvent<HTMLElement>) => any;
  closeButtonText?: string;
  saveButtonText?: string;
  title: ReactNode | string;
  children: ReactNode;
  icon?: JSX.Element;
  description?: string;
  buttonRequiredPermissions?: ApplicationPermission[];
  isLoading?: boolean;
  loadingButtonText?: string;
  classes?: string;
  displayCloseButton?: boolean;
  isOnSubmitDisabled?: boolean;
}

export const CommonDialog: React.FC<CommonDialogProps> = ({
  open,
  onClose,
  onSave,
  closeButtonText = 'Close',
  saveButtonText = 'Save',
  title,
  children,
  icon,
  description,
  buttonRequiredPermissions = [],
  isLoading = false,
  loadingButtonText = 'Saving...',
  classes,
  displayCloseButton = true,
  isOnSubmitDisabled = false,
}) => {
  const handleOnSave = (e: React.MouseEvent<HTMLElement>) => {
    if (!isLoading && onSave) {
      onSave(e);
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={`common-dialog ${classes}`}
    >
      <Stack className="dialog-title">
        <Stack className="title-container">
          <Stack direction="row" alignItems="center" spacing={2}>
            <div className="icon-container">{icon}</div>
            <Typography className="title" variant="inherit">
              {title}
            </Typography>
          </Stack>
          <IconButton
            className="common-modal-header-close-button"
            onClick={onClose}
          >
            <OpusSvgIcon
              type={SVG_ICON_TYPES.TIMES_SECONDARY_ICON}
              className="common-modal-header-close-icon"
            />
          </IconButton>
        </Stack>
        {description && (
          <Stack direction="row" alignItems="center" m={1}>
            {description}
          </Stack>
        )}
      </Stack>
      <DialogContent className="dialog-content">{children}</DialogContent>
      <DialogActions className="dialog-actions">
        {onClose && displayCloseButton && (
          <Button className="action-close " onClick={onClose}>
            {closeButtonText}
          </Button>
        )}
        {onSave ? (
          buttonRequiredPermissions.length ? (
            <AuthorizedContent requiredPermissions={buttonRequiredPermissions}>
              <Button
                className="action-save opus-primary-button"
                onClick={handleOnSave}
                disabled={isLoading}
              >
                {isLoading ? loadingButtonText : saveButtonText}
              </Button>
            </AuthorizedContent>
          ) : (
            <Button
              className="action-save opus-primary-button"
              onClick={handleOnSave}
              disabled={isLoading || isOnSubmitDisabled}
            >
              {isLoading ? loadingButtonText : saveButtonText}
            </Button>
          )
        ) : (
          <></>
        )}
      </DialogActions>
    </Dialog>
  );
};

import { FunctionComponent, useMemo } from 'react';
import { FormattedMessageProps } from 'shared/models/props/formatted-message-props.model';
import { useTranslation } from 'react-i18next';

export const FormattedMessage: FunctionComponent<FormattedMessageProps> = ({
  id,
  defaultMessage,
  params = {},
  capitalize,
  upperCase,
}) => {
  const { t: translation } = useTranslation();

  const text = useMemo(() => {
    const initialText = (
      id ? translation(`${id}`, params) : defaultMessage
    ) as string;

    if (capitalize) {
      const updatedText = initialText[0]
        .toUpperCase()
        .concat(initialText.substring(1, initialText.length));
      return updatedText;
    }

    if (upperCase) {
      return initialText.toUpperCase();
    }

    return initialText;
  }, [id]);

  return <span>{text}</span>;
};

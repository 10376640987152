import { FunctionComponent, useMemo } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  ReportRuleContentGroupType,
  ReportRuleContentWidgetId,
} from 'shared/models/data/report-rule.model';
import { useSearchParams } from 'react-router-dom';
import ReportOpenFindingsWidget from './components/ReportOpenFindingsWidget';
import DashboardReportsRisk from '../DashboardReportsRisk';

interface DashboardReportsWidgetsProps extends BaseComponentProps {}

export const DashboardReportsWidgets: FunctionComponent<
  DashboardReportsWidgetsProps
> = () => {
  const [params] = useSearchParams();

  const contentGroupType = useMemo<ReportRuleContentGroupType>(() => {
    return params.get('type') as ReportRuleContentGroupType;
  }, [params.get('type')]);

  const type = useMemo(() => {
    if (params.has('type')) {
      const groupType: ReportRuleContentGroupType = params.get(
        'type'
      ) as ReportRuleContentGroupType;
      return groupType === ReportRuleContentGroupType.COMPARATIVE_PER_GROUP
        ? 'group'
        : 'service';
    }

    return 'group';
  }, [params]);

  const widgetIds = useMemo(() => {
    if (params.has('widgets')) {
      try {
        return JSON.parse(params.get('widgets') as string);
      } catch (err) {
        return [];
      }
    }

    return [];
  }, [params]);

  const filters = useMemo(() => {
    if (params.has('filters')) {
      try {
        return JSON.parse(params.get('filters') as string);
      } catch (err) {
        return [];
      }
    }

    return [];
  }, [params]);

  const renderOperationalContent = () => {
    return (
      <DashboardReportsRisk widgetIds={widgetIds} searchParams={filters} />
    );
  };

  const renderOpenFindingsWidgetComponentsByIds = () => {
    if (widgetIds.length) {
      return widgetIds.map((widgetId: ReportRuleContentWidgetId) => {
        return (
          <ReportOpenFindingsWidget
            type={type}
            searchParams={filters}
            widgetId={widgetId}
          />
        );
      });
    }

    return <></>;
  };

  const renderWidgetComponentsByContentGroupType = () => {
    switch (contentGroupType) {
      case ReportRuleContentGroupType.COMPARATIVE_PER_GROUP:
      case ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE:
        return renderOpenFindingsWidgetComponentsByIds();
      case ReportRuleContentGroupType.RISK:
        return renderOperationalContent();
      default:
        return <></>;
    }
  };

  return (
    <div className="dashboard-reports-widgets">
      {renderWidgetComponentsByContentGroupType()}
    </div>
  );
};

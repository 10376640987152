import { UniqueBusinessUnitId } from 'Dashboard/components/BusinessUnitDashboardSection/BusinessUnitDashboardSection';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { StaticFilterOptions } from 'shared/components/FilterDropdown/FilterDropdown';
import { DataFitlerHandler } from 'shared/handlers/data-filter.handler';
import {
  ExtendedFilterCategory,
  FilterOption,
  FilterType,
  OptionSourceType,
} from 'shared/models/data/data-filter.model';
import { DataFilterOption } from 'shared/models/data/data-filters.model';

const dataFilterHandler = new DataFitlerHandler();

export const businessImpactOptions: FilterOption[] = [
  { value: 'HBI', label: 'HBI' },
  { value: 'MBI', label: 'MBI' },
  { value: 'LBI', label: 'LBI' },
];

export const riskAttributesOptions: FilterOption[] = [
  { value: 'InternetFacing', label: 'Internet Facing' },
  { value: 'CrownJewels', label: 'Crown Jewels' },
  { value: 'CustomerFacing', label: 'Customer Facing' },
  { value: 'PII', label: 'PII' },
  { value: 'PCI', label: 'PCI' },
  { value: 'PHI', label: 'PHI' },
];

export const complianceRequirementsOptions: FilterOption[] = [
  { value: 'PCI-DSS', label: 'PCI-DSS' },
  { value: 'HIPAA', label: 'HIPAA' },
  { value: 'GDPR', label: 'GDPR' },
  { value: 'CCPA', label: 'CCPA' },
  { value: 'SOC2', label: 'SOC2' },
  { value: 'ISO', label: 'ISO' },
  { value: 'FedRAMP', label: 'FedRAMP' },
  { value: 'Other', label: 'Other' },
];

export enum ServiceAttribute {
  RiskAttributes = 'riskAttributes',
  BusinessImpact = 'businessImpact',
  ComplianceRequirements = 'complianceRequirements',
}

export const serviceAttributes = [
  ServiceAttribute.RiskAttributes,
  ServiceAttribute.BusinessImpact,
  ServiceAttribute.ComplianceRequirements,
];

export function getFilteredDashboardFilterCategories(
  isServiceAttributesEnabled: boolean
): Array<ExtendedFilterCategory> {
  return [
    {
      id: 'scopeId',
      label: 'Scope',
      type: FilterType.SINGLE_SELECT,
      state: {
        sourceType: OptionSourceType.API_STATIC,
        source: {
          url: 'organization-structures/getUserAvailableNodesOfType',
          body: {
            nodesType: 'scope',
          },
          optionsTransformer: (options: Array<OrganizationNode>) => {
            return options.map((option: OrganizationNode) => ({
              value: option.id,
              label: option.name,
            }));
          },
        },
        extraOptions: [
          {
            value: 'All',
            label: 'All',
          },
        ],
      },
    },
    {
      id: 'groupId',
      label: 'Group',
      type: FilterType.SINGLE_SELECT,
      state: {
        sourceType: OptionSourceType.API_STATIC,
        source: {
          url: 'organization-structures/getUserAvailableNodesOfType',
          body: {
            nodesType: 'logical',
          },
          optionsTransformer: (options: Array<OrganizationNode>) => {
            return options.map((option: OrganizationNode) => ({
              value: option.id,
              label: option.name,
            }));
          },
        },
        extraOptions: [
          {
            value: 'All',
            label: 'All',
          },
        ],
        sortMethod: (optionData) =>
          optionData.sort((optionA, optionB) =>
            optionA.value === 'All' ? -1 : 1
          ),
      },
    },
    {
      id: 'businessUnitId',
      label: 'Service',
      type: FilterType.MULTI_SELECT,
      state: {
        sourceType: OptionSourceType.API_STATIC,
        source: {
          url: 'organization-structures/getUserAvailableNodesOfType',
          body: {
            nodesType: 'data',
          },
          optionsTransformer: (options: Array<OrganizationNode>) => {
            return options.map((option: OrganizationNode) => ({
              value: option.id,
              label: option.name,
            }));
          },
        },
        sortMethod: (options: Array<DataFilterOption>) =>
          dataFilterHandler.sortFilterOptions(options, ['None']),
      },
      categories: [
        ...(isServiceAttributesEnabled
          ? [
              {
                id: 'serviceAttributes',
                label: 'Service Attributes',
                displayNestedSelectionIndicator: true,
                categories: [
                  {
                    id: 'businessImpact',
                    label: 'Business Impact',
                    type: FilterType.MULTI_SELECT,
                    displaySelectionCounter: true,
                    state: {
                      sourceType: OptionSourceType.STATIC,
                      source: {
                        options: businessImpactOptions,
                      },
                    },
                    disableSearch: true,
                  },
                  {
                    id: 'riskAttributes',
                    label: 'Risk Attributes',
                    type: FilterType.MULTI_SELECT,
                    displaySelectionCounter: true,
                    state: {
                      sourceType: OptionSourceType.STATIC,
                      source: {
                        options: riskAttributesOptions,
                      },
                    },
                    disableSearch: true,
                  },
                  {
                    id: 'complianceRequirements',
                    label: 'Compliance Requirements',
                    type: FilterType.MULTI_SELECT,
                    displaySelectionCounter: true,
                    state: {
                      sourceType: OptionSourceType.STATIC,
                      source: {
                        options: complianceRequirementsOptions,
                      },
                    },
                    disableSearch: true,
                  },
                ],
              },
            ]
          : []),
      ],
    },
    {
      id: 'findingType',
      label: 'Domain',
      type: FilterType.MULTI_SELECT,
      state: {
        sourceType: OptionSourceType.API_STATIC,
        source: {
          url: 'config/filters/search/findingType',
          optionsTransformer:
            dataFilterHandler.transformApiFilterOptionsPayload,
        },
      },
    },
  ];
}

import { CircularProgress } from '@mui/material';
import { AgGridEvent, RowClickedEvent } from 'ag-grid-community';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { FindingsWithResource } from 'FindingDetails/interfaces/FindingsWithResources';
import {
  SummaryResponse,
  useFetchFindingsWithResourceMutation,
} from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { DuplicationState } from 'Risk/interfaces/DuplicationState.enum';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { relatedFindingsTableColumns } from 'shared/fixtures/data/findings.data';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface FindingContentRelatedFindingsProps {
  findingData?: SummaryResponse;
  setFindingId: (id: string) => void;
}

export const FindingContentRelatedFindings: FunctionComponent<
  FindingContentRelatedFindingsProps
> = ({ findingData, setFindingId }) => {
  const { t: translation } = useTranslation();

  const gridRef = useRef<AgGridEvent | null>(null);

  const [, setReactiveSearchParams] = useSearchParams();

  const [, , getUrlSearchParams] = useQueryParams();
  const [pageSize, setPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [fetchFindingsWithResource, { isLoading, data: findingsWithResource }] =
    useFetchFindingsWithResourceMutation();

  const hasCurrentFinding = useMemo<boolean>(() => {
    return Boolean(
      findingsWithResource?.data?.find(
        (findingWithResource: FindingsWithResource) =>
          findingWithResource?.findingId === findingData?.id
      ) || false
    );
  }, [findingsWithResource, findingData]);

  const findingsToDisplay = useMemo<Array<FindingsWithResource>>(() => {
    return hasCurrentFinding
      ? findingsWithResource?.data?.filter(
          (findingWithResource: FindingsWithResource) =>
            findingWithResource?.findingId !== findingData?.id
        ) || []
      : findingsWithResource?.data || [];
  }, [findingsWithResource, hasCurrentFinding]);

  useEffect(() => {
    if (
      !findingData ||
      findingData.duplicationState !== DuplicationState.SINGLE
    ) {
      fetchFindingsWithResource({
        filter: {
          duplicationIndicator: [
            {
              value: findingData?.duplicationIndicator || '',
              label: findingData?.duplicationIndicator,
            },
          ],
        },
        removeDuplications: false,
        take: pageSize,
        skip: currentPage && pageSize ? (currentPage - 1) * pageSize : 0,
      });
    }
  }, [currentPage, pageSize, findingData]);

  const onFindingClicked = (event: RowClickedEvent) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (updatedReactiveSearchParams.has('openCodeEventId'))
      updatedReactiveSearchParams.delete('openCodeEventId');

    updatedReactiveSearchParams.set('openFindingId', event.data.findingId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const onPageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  const renderSectionBody = () => {
    if (!isLoading && findingsWithResource?.data?.length === 0) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div
        className={`finding-content-table-body ${
          isLoading ? 'finding-content-table-body-loading' : ''
        }`}
      >
        <CommonSimpleDataGrid
          rowData={findingsToDisplay}
          columnDefs={relatedFindingsTableColumns}
          paginationProps={{
            pageSize: pageSize,
            currentPage: currentPage,
            onPageChange,
            onPageSizeChange,
            totalItems: findingsWithResource?.totalItems || 0,
          }}
          isLoading={isLoading}
          domLayout="autoHeight"
          defaultColDef={{
            resizable: true,
            sortable: false,
            lockPinned: true,
            lockPosition: true,
            lockVisible: true,
          }}
          onRowClicked={onFindingClicked}
          otherComponents={{
            NoDataBackdropComponent: <NoDataBackdrop />,
          }}
          loadingOverlayComponent={() => (
            <div className="finding-content-table-body-loading-wrapper">
              <CircularProgress />
            </div>
          )}
          gridRef={gridRef}
        />
      </div>
    );
  };

  if (!findingData || findingData.duplicationState === DuplicationState.SINGLE)
    return null;

  return (
    <div
      className="finding-content-item finding-content-related-findings-container"
      id="related-findings"
    >
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section related-findings-content-table"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.RADAR_ICON} />}
        title={translation(`findings.sections.relatedFindings`)}
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

import React, { FC } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
} from '@mui/material';
import { NodeProps } from 'WorkflowBuilder/interfaces/node';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  CrossroadCondition,
  CrossroadConditionRoute,
} from 'WorkflowBuilder/components/CrossroadPanel/components/CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';

interface FindingCrossroadPanelProps {
  data: NodeProps;
  isPathActive: (nextWorkflowId: number | undefined) => boolean;
}

// pass it under the FC generic
export const FindingCrossroadPanel: FC<FindingCrossroadPanelProps> = ({
  data,
  isPathActive,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [activeInput, setActiveInput] = React.useState<string | null>(null);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setActiveInput(isExpanded ? panel : null);
    };

  const isExpanded = (index: number) => expanded === `panel${index}`;

  const renderConditions = (conditionItems: Array<CrossroadCondition>) => {
    return conditionItems.map(
      (
        conditionItem: CrossroadCondition,
        index: number,
        conditionItemArray
      ) => {
        return (
          <div className="finding-workflow-crossroad-condition-sub-list">
            <div className="finding-workflow-crossroad-condition-parameter">
              {conditionItem.expressionA}
            </div>
            <div className="finding-workflow-crossroad-condition-operator">
              {conditionItem.operator}
            </div>
            <div className="finding-workflow-crossroad-condition-parameter">
              {conditionItem.expressionB}
            </div>

            {index !== conditionItemArray.length - 1 ? (
              <div className="finding-workflow-crossroad-condition-and-operator">
                And
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      }
    );
  };

  const renderConditionLists = (
    conditions: Array<Array<CrossroadCondition>>
  ) => {
    return conditions.map((conditionList, index, conditionListArray) => {
      return (
        <div className="finding-workflow-crossroad-condition-sub-list">
          {renderConditions(conditionList)}
          {index !== conditionListArray.length - 1 ? (
            <div className="finding-workflow-crossroad-condition-or-operator">
              <div className="finding-workflow-crossroad-condition-or-operator-line"></div>
              Or
              <div className="finding-workflow-crossroad-condition-or-operator-line"></div>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    });
  };

  return (
    <Stack className="finding-workflow-panel-body-crossroad">
      {data.results.length > 0 &&
        data.results.map(
          (conditionRoute: CrossroadConditionRoute, index: number) => {
            return (
              <Accordion
                key={index}
                expanded={isExpanded(index)}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  padding: '0 !important',
                  '& .MuiCollapse-root': {
                    borderRadius: '10px',
                    backgroundColor: '#F5F8FD',
                  },
                }}
                className="finding-workflow-panel-body-crossroad-path"
              >
                <AccordionSummary
                  expandIcon={
                    <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />
                  }
                  aria-controls="panel1bh-content"
                  id={`panel1bh-header-${index}`}
                  className="finding-workflow-panel-body-crossroad-summary"
                >
                  {isPathActive(conditionRoute.nextWorkflowSeqId) ? (
                    <OpusSvgIcon type={SVG_ICON_TYPES.OCTAGON_CHECK_ICON} />
                  ) : (
                    <></>
                  )}
                  <p className="finding-workflow-panel-body-crossroad-summary-title">
                    {conditionRoute?.name}
                  </p>
                </AccordionSummary>
                <AccordionDetails className="finding-workflow-panel-body-crossroad-details">
                  {conditionRoute.conditions ? (
                    renderConditionLists(conditionRoute.conditions)
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          }
        )}
    </Stack>
  );
};

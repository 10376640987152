import { Button, Skeleton } from '@mui/material';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FindingItem from 'shared/components/FindingItem';
import IconBox from 'shared/components/IconBox';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import Cloud2CodeWorkflow from 'Cloud2Code/components/Cloud2CodeWorkflow';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';
import { useSearchParams } from 'react-router-dom';
import { AssetType } from 'Risk/interfaces/AssetType.enum';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { GridType } from 'Risk/store';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { useFetchAssetsDetailsByIdMutation } from 'Assets/store/api';
import TabList from 'shared/components/TabList';
import { TabListItem } from 'shared/components/TabList/TabList';
import ResourceAttributes from 'Assets/components/ResourceAttributes/ResourceAttributes';
import { ResourcePerSensorDetails } from 'Assets/interfaces/ResourcePerSensorData.model';

interface ResourceAssetDetailsDrawerResourceInfoProps
  extends BaseComponentProps {
  resourceData?: any;
  resourcePerSensorData?: { [key: string]: ResourcePerSensorDetails };
  resourceDataLoading: boolean;
  resourceDataError: any;
}

export enum ResourceTypeTab {
  DEFAULT = 'recommended',
}

const navigationHandler = new NavigationHandler();

export const ResourceAssetDetailsDrawerResourceInfo: FunctionComponent<
  ResourceAssetDetailsDrawerResourceInfoProps
> = ({
  resourcePerSensorData,
  resourceDataError,
  resourceDataLoading,
  resourceData,
}) => {
  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const getSensorTabList: Array<TabListItem> = useMemo(() => {
    if (!resourcePerSensorData) return [];

    return Object.entries(resourcePerSensorData).map(([key, data]) => {
      const isDefaultTab =
        key.toLocaleLowerCase() === ResourceTypeTab.DEFAULT.toLocaleLowerCase();
      return {
        id: key,
        label: isDefaultTab ? ResourceTypeTab.DEFAULT : undefined,
        iconUrl: !isDefaultTab ? data.appLogo : undefined,
      } as TabListItem;
    });
  }, [resourcePerSensorData]);

  let [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();
  const { t: translation } = useTranslation();
  const [showMoreTags, setShowMoreTags] = useState<boolean>(false);
  const [activeResourceTab, setActiveResourceTab] = useState<string>(
    ResourceTypeTab.DEFAULT
  );

  const [fetchServiceFilterInformationForField, { data: serviceOptions }] =
    useFetchFilterInformationForFieldMutation();

  const [getUserAvailableNodesOfType, { data: nodeData }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [
    searchCloudToCodeData,
    {
      data: cloudToCodeData,
      isLoading: cloudToCodeDataLoading,
      error: cloudToCodeDataError,
    },
  ] = useFetchAssetsDetailsByIdMutation();

  useEffect(() => {
    getUserAvailableNodesOfType({
      nodesType: OrganizationNodeType.LOGICAL,
    });
    fetchServiceFilterInformationForField({
      field: 'businessUnitId',
    });
  }, []);

  const [
    searchEnvironmentData,
    {
      data: environmentData,
      isLoading: environmentDataLoading,
      error: environmentDataError,
    },
  ] = useFetchAssetsDetailsByIdMutation();

  const openResourceId = useMemo<string | null>(() => {
    return reactiveSearchParams.get('openResourceId') || null;
  }, [reactiveSearchParams.get('openResourceId')]);

  useEffect(() => {
    if (openResourceId) {
      searchEnvironmentData({
        type: AssetType.EnvironmentData,
        id: openResourceId,
      });

      searchCloudToCodeData({
        type: AssetType.CloudToCodeData,
        id: openResourceId,
      });
    }
  }, [openResourceId]);

  const displayTabList = useMemo(() => {
    return (
      resourcePerSensorData && Object.keys(resourcePerSensorData).length > 2
    );
  }, [resourcePerSensorData]);

  const renderResourceTags = () => {
    if ((!resourceDataLoading && !resourcePerSensorData) || resourceDataError) {
      return <></>;
    }

    if (resourcePerSensorData) {
      const tags = resourcePerSensorData[activeResourceTab]?.tags as Array<any>;
      if (!tags || tags?.length === 0) {
        return <p>{translation(`common.noTagsAvailable`)}</p>;
      }

      if (showMoreTags) {
        return (
          <>
            {tags.map((tag) => (
              <div className="finding-section-resource-tag">
                <div className="finding-section-resource-tag-name">
                  {tag.tagName}:
                </div>
                <div className="finding-section-resource-tag-value">
                  {tag.tagValue}
                </div>
              </div>
            ))}

            <Button
              className="base-opus-text-button finding-section-resource-tag-show-more"
              onClick={() => {
                setShowMoreTags(false);
              }}
            >
              {translation(`common.showLess`)}
            </Button>
          </>
        );
      }

      const mainTags = tags.slice(0, 5);
      const additionalTags = tags.slice(5);

      if (additionalTags.length) {
        return (
          <>
            {mainTags.map((tag) => {
              return (
                <div className="finding-section-resource-tag">
                  <div className="finding-section-resource-tag-name">
                    {tag.tagName}:
                  </div>
                  <div className="finding-section-resource-tag-value">
                    {tag.tagValue}
                  </div>
                </div>
              );
            })}
            <Button
              className="base-opus-text-button finding-section-resource-tag-show-more"
              onClick={() => {
                setShowMoreTags(true);
              }}
            >
              {translation(`common.showMore`)}
            </Button>
          </>
        );
      }

      return mainTags.map((tag) => {
        return (
          <div className="finding-section-resource-tag">
            <div className="finding-section-resource-tag-name">
              {tag.tagName}:
            </div>
            <div className="finding-section-resource-tag-value">
              {tag.tagValue}
            </div>
          </div>
        );
      });
    }
  };

  const renderEnvironmentSubSection = () => {
    if (environmentDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }

    if (resourceData) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.SQUARE_CODE_ICON} />}
          title={translation(`findings.subSections.environment`)}
        >
          <div className="finding-content-environment-sub-section-body">
            <FindingItem title={translation(`findings.subSections.service`)}>
              {resourceData?.businessUnitName ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    const service = serviceOptions?.find(
                      (serviceOption: AutocompleteOption) =>
                        serviceOption.label === resourceData?.businessUnitName
                    );

                    navigationHandler.handleRiskNavigation(GridType.None, {
                      businessUnitId: [
                        {
                          id: service?.value,
                          name: service?.label,
                        },
                      ],
                    });
                  }}
                >
                  <InteractiveLabel
                    icon={<OpusSvgIcon type={SVG_ICON_TYPES.BRIEFCASE_ICON} />}
                    label={resourceData?.businessUnitName}
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.group`)}>
              {environmentData?.groupName ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    const groupNode = nodeData?.find(
                      (nodeDataItem: OrganizationNode) =>
                        nodeDataItem.name === environmentData?.groupName
                    );

                    navigationHandler.handleRiskNavigation(GridType.None, {
                      groupId: [
                        {
                          id: groupNode?.id,
                          name: groupNode?.name,
                        },
                      ] as Array<FilterOption>,
                    });
                  }}
                >
                  <InteractiveLabel
                    icon={
                      <OpusSvgIcon type={SVG_ICON_TYPES.PEOPLE_GROUP_ICON} />
                    }
                    label={environmentData?.groupName}
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.workspace`)}>
              {resourceData?.workspaceData?.alias ? (
                <div
                  className="finding-content-cursor-item"
                  onClick={() => {
                    navigationHandler.handleRiskNavigation(GridType.None, {
                      workspace: [
                        {
                          id: resourceData?.workspaceData?.id,
                          name: resourceData?.workspaceData?.alias,
                        },
                      ] as Array<FilterOption>,
                    });
                  }}
                >
                  <IconBox
                    icon={
                      <div className="finding-content-workspace-icon">
                        <img
                          src={resourceData?.workspaceData?.providerLogoUrl}
                        ></img>
                      </div>
                    }
                    helperText={resourceData?.workspaceData?.externalId}
                    label={resourceData?.workspaceData?.alias}
                  />
                </div>
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
            <FindingItem title={translation(`findings.subSections.type`)}>
              {resourceData?.workspaceData?.environmentType ? (
                <InteractiveLabel
                  icon={<OpusSvgIcon type={SVG_ICON_TYPES.CLOUD_ICON} />}
                  label={resourceData?.workspaceData?.environmentType}
                />
              ) : (
                <p>{translation(`common.missingValue`)}</p>
              )}
            </FindingItem>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderRiskAttributes = () => {
    if (resourceDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }
    if (resourceData && resourceData?.riskAttributes) {
      const areAllRiskAttributesInactive = !Object.values(
        resourceData?.riskAttributes
      ).some((value) => value);
      if (areAllRiskAttributesInactive) return <></>;
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.TRIANGLE_EXCLAMATION_ICON} />}
          title={translation(`findings.subSections.riskAttributes`)}
        >
          <div className="finding-content-riskAttributes-sub-section-body">
            <ResourceAttributes
              attributes={resourceData?.riskAttributes}
              displayLabel
            />
          </div>
        </ContentSection>
      );
    }
    return <></>;
  };

  const renderResourceWorkstationDetails = () => {
    if (resourceDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={150} width={'100%'} />
        </div>
      );
    }

    if ((!resourceDataLoading && !resourcePerSensorData) || resourceDataError) {
      return <></>;
    }

    if (resourcePerSensorData) {
      if (
        resourcePerSensorData[activeResourceTab]?.workStationDetails &&
        Object.keys(
          resourcePerSensorData[activeResourceTab]?.workStationDetails
        )?.length
      ) {
        const workstationDetails =
          resourcePerSensorData[activeResourceTab]?.workStationDetails;

        return (
          <div className="finding-content-resource-sub-section-additonal-properties-row">
            {Object.keys(workstationDetails)
              .sort()
              ?.map((workstationDetailsPropertyKey) => (
                <FindingItem title={workstationDetailsPropertyKey}>
                  <p
                    title={
                      workstationDetails[
                        workstationDetailsPropertyKey as keyof typeof workstationDetails
                      ]
                    }
                  >
                    {workstationDetails[
                      workstationDetailsPropertyKey as keyof typeof workstationDetails
                    ] || 'N/A'}
                  </p>
                </FindingItem>
              ))}
          </div>
        );
      }
    }

    return <></>;
  };
  const handleGoToRootCause = () => {
    const codeEventId = cloudToCodeData?.codeEventDetails?.id;
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    updatedReactiveSearchParams.set('openCodeEventId', codeEventId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const renderResourceSubSection = () => {
    if (resourceDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={150} width={'100%'} />
        </div>
      );
    }

    if ((!resourceDataLoading && !resourcePerSensorData) || resourceDataError) {
      return <></>;
    }

    if (resourcePerSensorData) {
      const selectedSensorData = resourcePerSensorData[activeResourceTab];

      return (
        <>
          {displayTabList ? (
            <TabList
              onSelect={(id: string) => {
                setActiveResourceTab(id);
              }}
              items={getSensorTabList}
              activeItemId={activeResourceTab}
            ></TabList>
          ) : (
            <></>
          )}
          <ContentSection
            iconClassName="finding-content-sub-section-icon"
            rootClassName="finding-content-sub-section finding-content-sub-section-no-title"
            icon={<></>}
            title=""
          >
            <div className="finding-content-resource-sub-section-body">
              <div className="finding-content-resource-sub-section-main-row">
                <FindingItem
                  title={translation(`findings.subSections.resourceName`)}
                >
                  <IconBox
                    icon={
                      <div className="finding-content-resource-logo">
                        <img
                          src={selectedSensorData?.typeLogoUrl}
                          alt={selectedSensorData?.type}
                        ></img>
                      </div>
                    }
                    helperText={selectedSensorData?.type}
                    label={selectedSensorData?.name}
                  />
                </FindingItem>
                <FindingItem
                  title={translation(`findings.subSections.resourceId`)}
                >
                  <div
                    className="finding-content-resource-item-body-collapsable-text"
                    title={selectedSensorData?.resourceIdLabel}
                  >
                    {selectedSensorData?.resourceIdLabel}
                  </div>
                </FindingItem>
              </div>
              {renderResourceWorkstationDetails()}
            </div>
          </ContentSection>
        </>
      );
    }

    return <></>;
  };

  const renderTags = () => {
    if (resourceDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={150} width={'100%'} />
        </div>
      );
    }
    return (
      <div className="finding-content-resource-sub-section-tags-row">
        <FindingItem title={translation(`findings.subSections.tags`)}>
          {renderResourceTags()}
        </FindingItem>
      </div>
    );
  };

  const renderCloudToCodeSection = () => {
    if (cloudToCodeDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={200} width={'100%'} />
        </div>
      );
    }

    if (cloudToCodeData?.codeEventDetails) {
      return (
        <ContentSection
          iconClassName="finding-content-sub-section-icon"
          rootClassName="finding-content-sub-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_NETWORK_ICON} />}
          title={translation(`findings.subSections.cloudToCode`)}
          sectionNavigateName={translation(
            `findings.subSections.goToRootCause`
          )}
          onSectionNavigate={handleGoToRootCause}
        >
          <div className="finding-content-cloud-to-code-sub-section-body">
            <div className="finding-content-cloud-to-code-sub-section-main-row finding-content-section-row">
              <FindingItem title={translation(`findings.subSections.fileName`)}>
                {' '}
                <p>{cloudToCodeData?.codeEventDetails?.fileName}</p>
              </FindingItem>
              <FindingItem
                title={translation(`findings.subSections.repository`)}
              >
                <InteractiveLabel
                  label={cloudToCodeData?.codeEventDetails?.repositoryName}
                  icon={
                    <div className="finding-content-application-icon">
                      <img
                        src={
                          cloudToCodeData?.codeEventDetails?.repositoryLogoUrl
                        }
                      ></img>
                    </div>
                  }
                  link={cloudToCodeData?.codeEventDetails?.repositoryLink}
                />
              </FindingItem>

              <FindingItem
                title={translation(`findings.subSections.associatedFindings`)}
              >
                <p>{cloudToCodeData?.codeEventDetails?.findingsCount}</p>
              </FindingItem>

              <FindingItem
                title={translation(
                  `findings.subSections.suggestedFixAvailable`
                )}
              >
                <p>
                  {translation(
                    `common.${
                      cloudToCodeData?.codeEventDetails?.codeFixSuggestion
                        ? 'yes'
                        : 'no'
                    }`
                  )}
                </p>
              </FindingItem>
            </div>
            <div className="finding-content-cloud-to-code-sub-section-workflow-row">
              <Cloud2CodeWorkflow
                flowData={cloudToCodeData?.codeEventDetails?.flow}
              />
            </div>
          </div>
        </ContentSection>
      );
    }

    return <></>;
  };

  const renderSectionBody = () => {
    return (
      <div className="finding-content-section-body finding-content-resource-section-body">
        {renderResourceSubSection()}
        {renderRiskAttributes()}
        {renderTags()}
        {renderCloudToCodeSection()}
        {renderEnvironmentSubSection()}
      </div>
    );
  };

  return (
    <div className="finding-content-item resource-data-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.BOX_ICON} />}
        title="Resource Info"
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

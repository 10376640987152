import { TreeItem, TreeView } from '@mui/x-tree-view';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useFetchFindingSummaryMutation } from 'FindingDetails/store/api';
import FormattedMessage from '../FormattedMessage';
import { CircularProgress } from '@mui/material';

interface TokenInputFindingContentProps extends BaseComponentProps {
  findingId: string;
  selectFindingContentTokenHandler: (id: string) => void;
}

export const TokenInputFindingContent: FunctionComponent<
  TokenInputFindingContentProps
> = ({ findingId, selectFindingContentTokenHandler }) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleToggle = (event: any, nodeIds: string[]) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      setExpanded(nodeIds);
    }
  };

  const [
    fetchFindingSummary,
    { isLoading: findingSummaryLoading, data: findingSummaryData },
  ] = useFetchFindingSummaryMutation();

  useEffect(() => {
    if (findingId) fetchFindingSummary(findingId);
  }, [findingId]);

  const renderApiContent = (
    isLoading: boolean,
    data: any,
    renderContent: () => ReactNode
  ) => {
    if (isLoading)
      return <CircularProgress size={25} sx={{ marginTop: '10px' }} />;

    if ((!isLoading && !data) || (!isLoading && data?.length === 0))
      return <FormattedMessage id="common.noData" defaultMessage="No data" />;

    return renderContent();
  };

  const renderResultTree = (result: any, parentKey = `finding.content`) => {
    const isResultArray = Array.isArray(result);

    if (
      isResultArray &&
      result.every((resultItem: any) => typeof resultItem === 'string')
    ) {
      return result.map((resultItem: string, index: number) => {
        const itemId =
          parentKey.length > 0 ? `${parentKey}.${index}` : `${index}`;

        return (
          <TreeItem
            className="token-input-panel-tree-item"
            nodeId={itemId}
            id={itemId}
            label={`${index}`}
            collapseIcon={<></>}
            expandIcon={<></>}
            ContentComponent={() => {
              const label = `${index}`;
              const subLabel = resultItem;
              return (
                <div className="token-input-panel-step-sub-branch-header">
                  <div
                    className="token-input-panel-step-sub-branch-header-label"
                    title={label}
                    onClick={() => {
                      selectFindingContentTokenHandler(itemId);
                    }}
                  >
                    {label}
                  </div>
                  <div
                    className="token-input-panel-step-sub-branch-header-sub-label"
                    title={subLabel}
                  >
                    {subLabel}
                  </div>
                </div>
              );
            }}
          >
            <></>
          </TreeItem>
        );
      });
    }

    const itemToBeProcessed = isResultArray ? result[0] || {} : result;

    return (
      <>
        {Object.keys(itemToBeProcessed).map((objectKey) => {
          const itemId =
            parentKey.length > 0 ? `${parentKey}.${objectKey}` : objectKey;

          const isObject =
            itemToBeProcessed[objectKey] &&
            typeof itemToBeProcessed[objectKey] === 'object';

          const hasKeys =
            isObject && Object.keys(itemToBeProcessed[objectKey])?.length > 0;

          const isItemArray = Array.isArray(itemToBeProcessed[objectKey]);

          return (
            <TreeItem
              className="token-input-panel-tree-item"
              nodeId={itemId}
              id={itemId}
              label={`${objectKey}${isItemArray ? ' [ ]' : ''}}`}
              collapseIcon={
                hasKeys ? (
                  <OpusSvgIcon type={SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON} />
                ) : (
                  <></>
                )
              }
              expandIcon={
                hasKeys ? (
                  <OpusSvgIcon type={SVG_ICON_TYPES.CARET_BOLD_RIGHT_ICON} />
                ) : (
                  <></>
                )
              }
              ContentComponent={() => {
                const label = `${objectKey}${isItemArray ? ' [ ]' : ''}`;
                const subLabel = isObject ? '' : itemToBeProcessed[objectKey];
                return (
                  <div className="token-input-panel-step-sub-branch-header">
                    {hasKeys ? (
                      <div
                        className="token-input-panel-step-sub-branch-header-expand-icon"
                        onClick={() => {
                          setExpanded((previousExpanded) => {
                            if (previousExpanded.includes(itemId)) {
                              return previousExpanded.filter(
                                (id) => id !== itemId
                              );
                            }

                            return [...previousExpanded, itemId];
                          });
                        }}
                      >
                        <OpusSvgIcon
                          type={
                            expanded.includes(itemId)
                              ? SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON
                              : SVG_ICON_TYPES.CARET_BOLD_RIGHT_ICON
                          }
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    <div
                      className="token-input-panel-step-sub-branch-header-label"
                      title={label}
                      onClick={() => {
                        selectFindingContentTokenHandler(itemId);
                      }}
                    >
                      {label}
                    </div>
                    <div
                      className="token-input-panel-step-sub-branch-header-sub-label"
                      title={subLabel}
                    >
                      {subLabel}
                    </div>
                  </div>
                );
              }}
            >
              {isObject ? (
                renderResultTree(itemToBeProcessed[objectKey], itemId)
              ) : (
                <></>
              )}
            </TreeItem>
          );
        })}
      </>
    );
  };

  return (
    <TreeView
      expanded={expanded}
      onNodeToggle={handleToggle}
      className="token-input-panel-tree-view"
    >
      {renderApiContent(
        findingSummaryLoading,
        findingSummaryData?.content,
        () => renderResultTree(findingSummaryData?.content)
      )}
    </TreeView>
  );
};

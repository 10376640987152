export enum FindingStatus {
  OPEN = 'Open',
  IN_PROGRESS = 'In Progress',
  RESOLVED = 'Resolved',
  IN_REVIEW = 'In Review',
  SUPPRESS = 'Suppress',
}

export enum FindingImmutableStatus {
  EXCEPTION = 'Exception',
  PENDING_EXCEPTION = 'Pending Exception',
}

import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CommonChipProps extends BaseComponentProps {
  label: string;
}

export const CommonChip: FunctionComponent<CommonChipProps> = ({ label }) => {
  return <div className="common-chip-container">{label}</div>;
};

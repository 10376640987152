import { SvgIconProps } from '@mui/material';

interface SeverityIconProps extends Omit<SvgIconProps, 'color'> {
  color?: string;
}
export default function SeverityIcon({ color }: SeverityIconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" fill={`${color}50`} />
      <circle cx="10" cy="10" r="4" fill={color} />
    </svg>
  );
}

import { Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { AutomationManagementRemediationListProps } from 'shared/models/props/automation-management-props.model';

export const AutomationManagementRemediationList: FunctionComponent<
  AutomationManagementRemediationListProps
> = ({ items }) => {
  return (
    <div className="automation-management-remediation-list-container">
      {items?.length ? (
        <div className="automation-management-remediation-list">
          {items.map((item: { value: string; icon?: ReactNode }) => {
            return (
              <div className="automation-management-remediation-list-item">
                {item.icon}
                <Typography className="automation-management-remediation-text">
                  {item.value}
                </Typography>
              </div>
            );
          })}
        </div>
      ) : (
        <Typography className="automation-management-remediation-text">
          No action can be taken for the selected workflow definition.
        </Typography>
      )}
    </div>
  );
};

import { FunctionComponent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FindingItem from 'shared/components/FindingItem';
import CopyButton from 'Common/components/CopyButton';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import {
  FindingWidget,
  FindingWidgetType,
} from 'shared/models/data/finding-widget.model';
import { useSearchParams } from 'react-router-dom';
import { AssetType } from 'Risk/interfaces/AssetType.enum';
import { useFetchAssetsDetailsByIdMutation } from 'Assets/store/api';

export interface ResourceAssetDetailsDrawerAdditionalDataPayload {
  widgets: Array<FindingWidget>;
}

interface ResourceAssetDetailsDrawerAdditionalDataProps
  extends BaseComponentProps {}

const WidgetHeader = ({ ...props }) => {
  const { t: translation } = useTranslation();
  return (
    <div className="widget-header">
      <Typography className="widget-header-text">
        {props.description}
      </Typography>
      {props.platformName && (
        <>
          <Typography className="widget-header-title-app-prefix">
            {translation(`common.poweredBy`)}
          </Typography>
          <Typography className="widget-header-text">
            {props.platformName}
          </Typography>
        </>
      )}
      {props.icon}
    </div>
  );
};

const CollapsibleSectionBasic = ({ ...props }) => {
  return (
    <Accordion
      elevation={0}
      defaultExpanded={true}
      className="collapsable-sub-section"
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        className="finding-content-additional-data-accordion-summary"
      >
        {props.header}
      </AccordionSummary>
      <AccordionDetails className="finding-content-additional-data-accordion-summary">
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
};

function formatJSON(jsonObj: any) {
  try {
    return JSON.stringify(jsonObj, null, 4);
  } catch (error) {
    return '{}';
  }
}

export const ResourceAssetDetailsDrawerAdditionalData: FunctionComponent<
  ResourceAssetDetailsDrawerAdditionalDataProps
> = ({}) => {
  const [searchParams] = useSearchParams();

  const [
    searchAdditionalData,
    {
      data: additionalDataPayload,
      isLoading: additionalDataLoading,
      error: additionalDataError,
    },
  ] = useFetchAssetsDetailsByIdMutation();
  const openResourceId = useMemo<string | null>(() => {
    return searchParams.get('openResourceId') || null;
  }, [searchParams.get('openResourceId')]);

  useEffect(() => {
    if (openResourceId) {
      searchAdditionalData({
        type: AssetType.AdditionalDataPerSensor,
        id: openResourceId,
      });
    }
  }, [openResourceId]);

  const { t: translation } = useTranslation();
  if (
    additionalDataPayload === undefined ||
    (additionalDataLoading === false &&
      Object.keys(additionalDataPayload).length === 0)
  ) {
    return <></>;
  }
  const renderJsonWidget = (widget: FindingWidget) => {
    return (
      <div className="finding-content-json-tree-text-area">
        <CopyButton
          textToCopy={JSON.stringify(widget.data)}
          buttonIcon={SVG_ICON_TYPES.COPY_ICON_GRAY}
        />
        <textarea disabled defaultValue={formatJSON(widget.data)} />
      </div>
    );
  };

  const renderKeyValWidget = (widget: FindingWidget) => {
    return (
      <div className="finding-content-section-row finding-content-key-val-widget">
        {widget.data.map((item: any) => (
          <FindingItem title={item.key}>
            <Typography>{item.value}</Typography>
          </FindingItem>
        ))}
      </div>
    );
  };

  const renderTableWidget = (widget: FindingWidget) => {
    return (
      <div className="finding-content-grid-wrapper">
        <CommonSimpleDataGrid
          defaultColDef={{
            sortable: true,
            resizable: true,
            lockPinned: true,
            lockPosition: true,
            lockVisible: true,
          }}
          columnDefs={widget.data.columns.map((column: any) => ({
            field: column?.value,
            headerName: column?.label,
            initialFlex: 12 / widget.data.columns.length,
          }))}
          rowData={widget.data.data}
          domLayout="autoHeight"
        />
      </div>
    );
  };

  const renderWidgetByType = (widget: FindingWidget) => {
    switch (widget.type) {
      case FindingWidgetType.JSON:
        return renderJsonWidget(widget);
      case FindingWidgetType.KEY_VAL_PAIRS:
        return renderKeyValWidget(widget);
      case FindingWidgetType.TABLE:
        return renderTableWidget(widget);
    }
  };

  const renderSectionBody = () => {
    if (additionalDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }

    return Object.keys(additionalDataPayload).map((key) =>
      additionalDataPayload[key]?.additionalData?.map(
        (widget: FindingWidget) => (
          <CollapsibleSectionBasic
            header={
              <WidgetHeader
                description={widget.title}
                platformName={widget.applicationId}
                icon={
                  widget.appLogo ? (
                    <OpusImageIcon
                      src={widget.appLogo}
                      className="finding-content-additional-data-icon"
                    />
                  ) : (
                    <></>
                  )
                }
              />
            }
          >
            {renderWidgetByType(widget)}
          </CollapsibleSectionBasic>
        )
      )
    );
  };

  return (
    <div className="finding-content-item finding-content-additional-data-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.GRADUATION_CAP_ICON} />}
        title={translation(`findings.sections.additionalData`)}
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

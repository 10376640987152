import React, { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { BaseIntegrationModal } from './components/BaseIntegrationModal/BaseIntegrationModal';
import { SecurityHubIntegrationModal } from './components/SecurityHubIntegrationModal/SecurityHubIntegrationModal';
import { GithubIntegrationModal } from './components/GithubIntegrationModal/GithubIntegrationModal';
import { JiraIntegrationModal } from './components/JiraIntegrationModal/JiraIntegrationModal';
import { OrcaIntegrationModal } from './components/OrcaIntegrationModal/OrcaIntegrationModal';
import { AwsIntegrationModal } from './components/AwsIntegrationModal';
import { AzureIntegrationModal } from './components/AzureIntegrationModal/AzureIntegrationModal';
import GoogleCloudIntegrationModal from './components/GoogleCloudIntegrationModal';

export enum IntegrationModalType {
  AWS_SECURITY_HUB = 'AWSSecurityHub',
  WIZ = 'Wiz',
  ORCA = 'Orca',
  GITHUB = 'Github',
  JIRA = 'JiraCloudPlatform',
  DEFAULT = 'Default',
  AWS = 'AWS',
  AZURE = 'Azure',
  GCP = 'GCP',
}

export interface CommonIntegrationModalButtonProps {
  connectHandler: () => void;
  verifyHandler?: () => void;
  authorizeHandler?: () => void;
  connecting: boolean;
  authorizing?: boolean;
  verifying: boolean;
}

export interface CommonIntegrationModalProps {
  integrationItem: IntegrationItem;
  open: boolean;
  connectionId?: string;
  onCancel: () => void;
  onCreateSuccess?: (id: string) => void;
  rootClassName?: string;
  renderButtonRow?: (
    buttonProps: CommonIntegrationModalButtonProps
  ) => ReactNode;
  connectionTemplateData?: any;
  connectionTemplateDataLoading?: boolean;
  businessUnitRequired?: boolean;
}

interface IntegrationModalProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

export const IntegrationModal: FunctionComponent<IntegrationModalProps> = ({
  integrationItem,
  onCreateSuccess,
  ...otherProps
}) => {
  const ModalComponents: Record<string, React.ElementType> = {
    [IntegrationModalType.AWS_SECURITY_HUB]: SecurityHubIntegrationModal,
    [IntegrationModalType.GITHUB]: GithubIntegrationModal,
    [IntegrationModalType.JIRA]: JiraIntegrationModal,
    [IntegrationModalType.ORCA]: OrcaIntegrationModal,
    [IntegrationModalType.AWS]: AwsIntegrationModal,
    [IntegrationModalType.AZURE]: AzureIntegrationModal,
    [IntegrationModalType.GCP]: GoogleCloudIntegrationModal,
    [IntegrationModalType.DEFAULT]: BaseIntegrationModal,
  };

  const ComponentToRender =
    ModalComponents[integrationItem.id] ||
    ModalComponents[IntegrationModalType.DEFAULT];

  return (
    <ComponentToRender
      {...{ ...otherProps, integrationItem, onCreateSuccess }}
    />
  );
};

import { AutocompleteOption } from 'FindingDetails/store/api';
import { NotificationRuleTrigger } from 'shared/models/data/notification-rule.model';
import * as yup from 'yup';
import { ExtendedFilterCategory } from 'shared/models/data/data-filter.model';

import { notificationRuleConditionOptions } from './notification-rule.data';

export const riskCustomizationSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string(),
  properties: yup.array().optional(),
  riskCustomization: yup.array().optional(),
});

export const notificationRuleSLATriggerOptions: Array<AutocompleteOption> = [
  {
    value: NotificationRuleTrigger.FINDING_SLA_WARNING,
    label: 'finding nearly breaches SLA',
  },
  {
    value: NotificationRuleTrigger.FINDING_SLA_BREACHED,
    label: 'finding breaches SLA',
  },
];

export const riskCustomizationConditionOptions: Array<ExtendedFilterCategory> =
  notificationRuleConditionOptions;

export const riskCustomizationFormRequiredFields = ['name'];

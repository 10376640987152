import { AvatarGroup, Avatar } from '@mui/material';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import AvatarBox from 'shared/components/AvatarBox';
import { BusinessUnitStakeholder } from 'shared/models/data/extended-business-unit.model';

const StakeholdersGroup = ({
  stakeholders,
  maxStakeholders = 2,
}: {
  stakeholders: BusinessUnitStakeholder[];
  maxStakeholders?: number;
}) => {
  const renderTooltipContent = (stakeholders: BusinessUnitStakeholder[]) => {
    return (
      <div className="more-avatar-tooltip-content">
        {stakeholders.map((stakeholder: BusinessUnitStakeholder) => (
          <AvatarBox
            key={stakeholder.id}
            name={stakeholder.fullName}
            profileUrl={stakeholder.avatarUrl}
          />
        ))}
      </div>
    );
  };
  return (
    <div className="stakeholders-group">
      {stakeholders.length === 1 ? (
        <AvatarBox
          name={stakeholders[0].fullName}
          profileUrl={stakeholders[0].avatarUrl}
          role={stakeholders[0].role}
        />
      ) : stakeholders.length > 1 ? (
        <AvatarGroup
          renderSurplus={(surplus) => (
            <CommonTooltip
              title={renderTooltipContent(
                stakeholders?.slice(maxStakeholders, stakeholders.length)
              )}
            >
              <p>+{surplus}</p>
            </CommonTooltip>
          )}
          max={maxStakeholders}
          total={stakeholders.length}
          classes={{
            root: 'common-avatar-group-container',
            avatar: 'common-avatar-container',
          }}
        >
          {stakeholders
            .slice(0, maxStakeholders)
            .map((stakeholder: BusinessUnitStakeholder, index: number) => (
              <CommonTooltip
                key={index}
                placement="top"
                title={
                  <AvatarBox
                    displayAvatar={false}
                    name={stakeholder.fullName}
                    role={stakeholder.role}
                  />
                }
              >
                <Avatar
                  className="avatar-container"
                  alt={stakeholder.fullName}
                  src={stakeholder.avatarUrl}
                />
              </CommonTooltip>
            ))}
        </AvatarGroup>
      ) : null}
    </div>
  );
};

export default StakeholdersGroup;

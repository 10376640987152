import { FunctionComponent, useEffect, useMemo } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CommonButton } from 'shared/components/CommonButton';
import { IntegrationModalAccordion } from '../IntegrationModalAccordion';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  awsIntegrationFormDefaultSchema,
  awsIntegrationFormRequiredFields,
  awsIntegrationInputKeysToInputLabels,
} from 'shared/fixtures/data/integrations.data';
import CopyButton from 'Common/components/CopyButton';
import {
  useUpdateCloudConnectionMutation,
  useVerifyCloudConnectionMutation,
} from 'Integrations/Connection/store/api';
import {
  CloudProviderType,
  IntegrationActionType,
} from 'shared/models/data/integrations.model';
import { AlertBox, AlertBoxType } from 'shared/components/AlertBox/AlertBox';
import { useFetchConnectionByIdMutation } from 'WorkflowBuilder/store/api';
import { CircularProgress } from '@mui/material';
import { AlertExpandBox } from 'shared/components/AlertExpandBox/AlertExpandBox';
import { IntegrationsDataHandler } from 'shared/handlers/integrations-data.handler';
import { useTranslation } from 'react-i18next';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import Autocomplete from 'Common/components/Autocomplete';
import { businessUnitEnvironmentTypesOptionList } from 'shared/fixtures/data/business-unit.data';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OrganizationNodeDataHandler as OrganizationNodeOptionDataHandler } from 'shared/handlers/organization-node-data.handler';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';

interface AwsIntegrationModalEditFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

const integrationsDataHandler = new IntegrationsDataHandler();
const organizationNodeOptionDataHandler =
  new OrganizationNodeOptionDataHandler();

export const AwsIntegrationModalEditForm: FunctionComponent<
  AwsIntegrationModalEditFormProps
> = ({
  onCancel,
  connectionId,
  connectionTemplateData,
  connectionTemplateDataLoading,
  rootClassName,
  renderButtonRow,
  onCreateSuccess,
}) => {
  const { t: translation } = useTranslation();

  const {
    setValue: setFormValue,
    clearErrors: clearInputErrors,
    getValues: getFormValues,
    setError: setFormError,
    formState: { errors: formErrors },
    watch: watchFormValue,
  } = useForm({
    resolver: yupResolver(yup.object().shape(awsIntegrationFormDefaultSchema)),
    defaultValues: {
      id: '',
      externalId: '',
      environmentType: '',
      name: '',
      readRoleArn: '',
      writeRoleArn: '',
      writeApplicationIds: [] as Array<string>,
      scopeId: '',
    },
  });

  const writeApplicationIds = watchFormValue('writeApplicationIds');

  const [
    fetchConnectionById,
    { isLoading: fetchConnectionByIdLoading, data: connectionData },
  ] = useFetchConnectionByIdMutation();

  const cloudProviderData = useMemo(() => {
    if (connectionData) {
      return {
        ...(connectionData?.metadata?.cloudProvider?.aws || {}),
        name: connectionData?.name,
        scopeId: connectionData?.scopeId,
        environmentType:
          connectionData?.metadata?.cloudProvider?.environmentType,
      };
    }

    return undefined;
  }, [connectionData]);

  const [
    updateCloudConnection,
    {
      isLoading: updateCloudConnectionLoading,
      error: updateCloudConnectionError,
      isSuccess: updateCloudConnectionSuccess,
      reset: updateCloudConnectionReset,
    },
  ] = useUpdateCloudConnectionMutation();

  const [
    verifyConnection,
    {
      isLoading: verifyConnectionLoading,
      error: verifyConnectionError,
      data: verifyConnectionPayload,
      isSuccess: verifyConnectionSuccess,
      reset: verifyConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  const [
    verifySubConnection,
    {
      isLoading: verifySubConnectionLoading,
      error: verifySubConnectionError,
      data: verifySubConnectionPayload,
      isSuccess: verifySubConnectionSuccess,
      reset: verifySubConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  useEffect(() => {
    if (connectionId) {
      fetchConnectionById(connectionId);
    }
  }, [connectionId]);

  useEffect(() => {
    if (cloudProviderData) populateDefaultFormValues(cloudProviderData);
  }, [cloudProviderData]);

  useEffect(() => {
    if (updateCloudConnectionSuccess) {
      onCreateSuccess && onCreateSuccess(connectionId as string);
      onCancel();
    }
  }, [updateCloudConnectionSuccess]);

  const inputChangeHandler = (
    inputName: keyof typeof awsIntegrationFormDefaultSchema,
    inputValue: string
  ) => {
    if (inputValue.length) {
      clearInputErrors(inputName);
    }

    setFormValue(inputName, inputValue?.trim());
  };

  const writeArnHandler = (writeArnValue: string) => {
    if (writeArnValue.length) {
      clearInputErrors('writeRoleArn');
    }

    setFormValue('writeRoleArn', writeArnValue?.trim());
  };

  const validateFormBeforeSubmit = () => {
    let valid = true;

    const formValues = getFormValues();

    for (const requiredInputKey of awsIntegrationFormRequiredFields) {
      const typedInputKey =
        requiredInputKey as keyof typeof awsIntegrationFormDefaultSchema;
      if (
        formValues[typedInputKey] === undefined ||
        formValues[typedInputKey]?.length === 0
      ) {
        valid = false;
        setFormError(typedInputKey, {
          type: 'required',
          message: `${awsIntegrationInputKeysToInputLabels[typedInputKey]} is required`,
        });
      }
    }

    return valid;
  };

  const validateWriteRoleARN = () => {
    let valid = true;
    const writeRoleArn = getFormValues().writeRoleArn;

    if (writeRoleArn.length === 0) {
      valid = false;
      setFormError('writeRoleArn', {
        type: 'required',
        message: 'Write role ARN is required',
      });
    }

    return valid;
  };

  const connectSubApplicationHandler = (applicationId: string) => {
    const isSubFormValid = validateWriteRoleARN();

    if (isSubFormValid) {
      const subApplicationIds: Array<string> =
        getFormValues().writeApplicationIds || [];

      if (subApplicationIds.includes(applicationId)) {
        setFormValue(
          'writeApplicationIds',
          subApplicationIds.filter((id) => id !== applicationId)
        );
      } else {
        setFormValue('writeApplicationIds', [
          ...subApplicationIds,
          applicationId,
        ]);
      }
    }
  };

  const connectHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      updateCloudConnection({
        ...formValues,
        id: connectionData?.id,
        scopeId:
          formValues.scopeId && formValues.scopeId !== 'None'
            ? formValues.scopeId
            : null,
        cloudProvider: CloudProviderType.AWS,
      });
    }
  };

  const verifyHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      verifyConnection({
        cloudProvider: CloudProviderType.AWS,
        body: {
          ...formValues,
          scopeId:
            formValues.scopeId && formValues.scopeId !== 'None'
              ? formValues.scopeId
              : null,
          awsAppId: CloudProviderType.AWS,
          accountType: connectionData.metadata.cloudProvider.aws.accountType,
        },
      });
    }
  };
  const [fetchScopeList, { data: scopeData, isLoading: scopeDataLoading }] =
    useGetUserAvailableNodesOfTypeMutation();
  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);

  const scopeAutocompleteList: Array<AutocompleteOption> = useMemo(() => {
    return scopeData
      ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeData,
          true
        )
      : [];
  }, [scopeData]);

  const verifySubApplicationHandler = (applicationId: string) => {
    resetApiState();
    const isSubFormValid = validateWriteRoleARN();

    if (isSubFormValid) {
      const formValues = getFormValues();

      verifySubConnection({
        cloudProvider: CloudProviderType.AWS,
        body: {
          writeRoleArn: formValues.writeRoleArn,
          externalId: formValues.externalId,
          awsAppId: applicationId,
        },
      });
    }
  };

  const populateDefaultFormValues = (
    data: Record<
      keyof typeof awsIntegrationFormDefaultSchema,
      string | Array<string>
    >
  ) => {
    for (const dataKey in data) {
      setFormValue(
        dataKey as keyof typeof awsIntegrationFormDefaultSchema,
        data[dataKey as keyof typeof awsIntegrationFormDefaultSchema]
      );
    }
  };

  const resetApiState = () => {
    updateCloudConnectionReset();
    verifyConnectionReset();
    verifySubConnectionReset();
  };

  const selectedScopeOption = useMemo<AutocompleteOption | undefined>(() => {
    return integrationsDataHandler.getSelectedScopeOption(
      scopeAutocompleteList,
      getFormValues().scopeId
    );
  }, [scopeAutocompleteList, getFormValues().scopeId]);

  const renderAlertBox = () => {
    if (updateCloudConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.UPDATING,
            'AWS'
          )}
          description={
            (updateCloudConnectionError as any)?.data?.message ||
            (updateCloudConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            (verifyConnectionError as any)?.data?.message ||
            (verifyConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifySubConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            (verifySubConnectionError as any)?.data?.message ||
            (verifySubConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload?.success === false) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            verifyConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (
      verifySubConnectionPayload &&
      verifySubConnectionPayload?.success === false
    ) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'AWS'
          )}
          description={
            verifySubConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    if (verifySubConnectionPayload && verifySubConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }
    return <></>;
  };

  const renderDeployStack = () => {
    if (connectionTemplateDataLoading) {
      return <CircularProgress size={24} />;
    }
    return (
      <div className="cloud-integration-modal-form-deploy-stack">
        <div className="cloud-integration-modal-form-deploy-stack-text">
          {connectionTemplateData?.read?.description}
        </div>
        <div className="cloud-integration-modal-form-deploy-stack-button">
          <CommonButton
            type={CommonButtonType.TEXT}
            onClick={() => {
              connectionTemplateData?.read?.definition?.props?.deployLink &&
                window.open(
                  connectionTemplateData?.read?.definition?.props?.deployLink
                );
            }}
          >
            <a
              href={connectionTemplateData?.read?.definition?.props?.deployLink}
              className="cloud-integration-deploy-button"
              target="_blank"
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.DEPLOY_ICON} />
              Deploy Stack
            </a>
          </CommonButton>
        </div>
      </div>
    );
  };

  const getScopeLabel = (scopeId: string) => {
    return organizationNodeOptionDataHandler.getScopeLabel(
      scopeId,
      scopeAutocompleteList
    );
  };

  const renderConnectButtonRow = () => {
    if (renderButtonRow) {
      return renderButtonRow({
        connectHandler,
        verifyHandler,
        connecting: updateCloudConnectionLoading,
        verifying: verifyConnectionLoading,
      });
    }
    return (
      <div className="integration-modal-button-row">
        <div className="integration-modal-cancel-buttons">
          <CommonButton type={CommonButtonType.SECONDARY} onClick={onCancel}>
            Cancel
          </CommonButton>
        </div>
        <div className="integration-modal-connection-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={verifyHandler}
          >
            {verifyConnectionLoading
              ? 'Verifying Connectivity'
              : 'Verify Connectivity'}
          </CommonButton>
          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={connectHandler}
          >
            {updateCloudConnectionLoading ? 'Updating' : 'Update'}
          </CommonButton>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    if (fetchConnectionByIdLoading) {
      return <CircularProgress size={24} />;
    }

    return (
      <div className="cloud-integration-modal-form-area">
        <form className="cloud-integration-modal-form">
          <FormInput
            label="Workspace Name"
            name="workspaceName"
            placeholder="Please type workspace name"
            value={getFormValues().name}
            onChange={(value: string) => {
              inputChangeHandler('name', value);
            }}
            errorMessage={formErrors?.name?.message}
          />
          <InputLabelWrapper label="Scope">
            {scopeAutocompleteList === null || scopeDataLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Autocomplete
                model="scopeId"
                optionList={scopeAutocompleteList}
                loading={scopeDataLoading}
                loadingText="Loading"
                values={selectedScopeOption}
                initialSelectedValues={selectedScopeOption}
                onChangeCallBack={(
                  model: string,
                  option: AutocompleteOption | Array<AutocompleteOption>
                ) => {
                  inputChangeHandler(
                    'scopeId',
                    (option as AutocompleteOption).value
                  );
                }}
                classes={{
                  root: 'multi-select-field-1',
                  paper: 'multi-select-field-paper-1',
                }}
                single
              />
            )}
          </InputLabelWrapper>
          <InputLabelWrapper
            label="Workspace Environment Type"
            errorMessage={formErrors?.environmentType?.message}
          >
            <Autocomplete
              model="workspaceEnvironmentType"
              optionList={businessUnitEnvironmentTypesOptionList}
              values={
                getFormValues().environmentType
                  ? {
                      value: getFormValues().environmentType,
                      label: getFormValues().environmentType,
                    }
                  : undefined
              }
              onChangeCallBack={(
                model: string,
                option: AutocompleteOption | Array<AutocompleteOption>
              ) => {
                inputChangeHandler(
                  'environmentType',
                  (option as AutocompleteOption).value
                );
              }}
              classes={{
                root: 'multi-select-field-1',
                paper: 'multi-select-field-paper-1',
                inputRoot: formErrors.environmentType
                  ? 'input-validation-error-1'
                  : '',
              }}
              single
            />
          </InputLabelWrapper>

          <FormInput
            label="External ID"
            name="externalId"
            placeholder="Please type workspace name"
            value={getFormValues().externalId}
            onChange={(value: string) => {
              inputChangeHandler('externalId', value);
            }}
            components={{
              EndIcon: (
                <CopyButton
                  textToCopy={getFormValues().externalId}
                ></CopyButton>
              ),
            }}
            disabled
          />

          {renderDeployStack()}

          <FormInput
            label="Read Role ARN"
            name="roleArn"
            placeholder="Please type role arn"
            value={getFormValues().readRoleArn}
            onChange={(value: string) => {
              inputChangeHandler('readRoleArn', value);
            }}
            errorMessage={formErrors?.readRoleArn?.message}
          />
        </form>

        <div className="cloud-integration-modal-accordion-area">
          <IntegrationModalAccordion
            parentApplicationId="AWS"
            title="Write Access"
            selectedApplicationIds={writeApplicationIds}
            arnProps={{
              enableArn: true,
              onArnValueChange: writeArnHandler,
              arnValidationError: formErrors.writeRoleArn?.message,
              arnValue: getFormValues().writeRoleArn,
            }}
            subApplicationProps={{
              onConnect: connectSubApplicationHandler,
              onVerify: verifySubApplicationHandler,
            }}
            template={connectionTemplateData?.write}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderAlertBox()}
      <div className={`cloud-integration-modal-form-wrapper ${rootClassName}`}>
        {renderForm()}

        <div className="cloud-integration-modal-button-area">
          {renderConnectButtonRow()}
        </div>
      </div>
    </>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import FlowItem, { FlowItemCreate } from 'Flow/interfaces/item';
import flowItemsFixture from '../fixtures/items';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import {
  Workflow,
  WorkflowDefinition,
} from 'shared/models/data/workflows.model';

export interface ExportContent {
  data: object;
}

const flowApi = createApi({
  reducerPath: 'flowApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  tagTypes: ['Flows'],
  endpoints: (builder) => ({
    fetchFlows: builder.query<FlowItem[], void>({
      query: () => 'workflows',
      transformResponse: async (response) => {
        return response as FlowItem[]; // prod; TODO: handle possibly empty or incorrect response data
      },
      providesTags: ['Flows'],
    }),
    searchFlows: builder.mutation<
      {
        data: Array<WorkflowDefinition>;
        totalItems: number;
      },
      any
    >({
      query: (body: any) => ({
        url: 'workflows/search',
        method: 'POST',
        body,
      }),
    }),
    searchFlowHistory: builder.mutation<
      {
        data: Array<Workflow>;
        totalItems: number;
      },
      any
    >({
      query: (body: any) => ({
        url: `workflows/family/history/${body.workflowDefFamilyId}/search`,
        method: 'POST',
        body: body.data,
      }),
    }),
    importFlow: builder.mutation<FlowItem, object>({
      query: (itemCreate) => ({
        url: 'workflows/actions/import',
        method: 'POST',
        body: itemCreate,
      }),
      invalidatesTags: ['Flows'],
      transformResponse: async (response) => {
        return response as FlowItem;
      },
    }),
    getExportFlowContent: builder.mutation<ExportContent, string>({
      query: (id) => ({
        url: `workflows/${id}/actions/export`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as ExportContent;
      },
    }),
    deleteFlow: builder.mutation<void, string>({
      query: (id) => ({
        url: `workflows/${id}`,
        method: 'DELETE',
      }),
    }),
    getWorkflowTokenGroupsByType: builder.mutation({
      query: (type: string) => ({
        url: `internal/marketplace/builder/tokens/${type}/groups`,
        method: 'GET',
      }),
    }),
    getWorkflowStepTokensByStepDefId: builder.mutation({
      query: (stepDefId: string) => ({
        url: `internal/marketplace/builder/tokens/search/step`,
        method: 'POST',
        body: {
          stepDefId,
        },
      }),
    }),
    getSensorTriggers: builder.mutation({
      query: () => ({
        url: `workflows/triggers/sensor`,
        method: 'POST',
      }),
    }),
    getSensorTriggerOptions: builder.mutation({
      query: (body: { applicationId: string }) => ({
        url: `workflows/triggers/finding-type`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export default flowApi;

export const {
  useFetchFlowsQuery,
  useSearchFlowsMutation,
  useSearchFlowHistoryMutation,
  useLazyFetchFlowsQuery,
  useImportFlowMutation,
  useGetExportFlowContentMutation,
  useDeleteFlowMutation,
  useGetWorkflowTokenGroupsByTypeMutation,
  useGetWorkflowStepTokensByStepDefIdMutation,
  useGetSensorTriggersMutation,
  useGetSensorTriggerOptionsMutation,
} = flowApi;

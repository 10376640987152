import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  SelectChipCategory,
  SelectChipGroupMutationProps,
} from '../SelectChipGroup';
import { CircularProgress, MenuItem, MenuList, TextField } from '@mui/material';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { CommonCheckbox } from 'shared/components/CommonCheckbox/CommonCheckbox';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

interface SelectChipOptionDropdownProps
  extends BaseComponentProps,
    SelectChipCategory {
  onChange: (categoryId: string, values: Array<AutocompleteOption>) => void;
  selectedValues?: Array<AutocompleteOption>;
  backClickHandle: () => void;
  enableHeader?: boolean;
  mutationProps?: SelectChipGroupMutationProps;
}

export const SelectChipOptionDropdown: FunctionComponent<
  SelectChipOptionDropdownProps
> = ({
  id,
  label,
  loaded,
  optionList,
  onChange,
  selectedValues,
  backClickHandle,
  enableHeader = false,
  mutationProps,
}) => {
  const [filteredOptionList, setFilteredOptionList] = useState<
    Array<AutocompleteOption>
  >([]);

  const { t: translation } = useTranslation();

  const fetchMutation =
    mutationProps?.fetchMutation || useFetchFilterInformationForFieldMutation;

  const [
    fetchOptionListForField,
    { isLoading: isOptionListLoading, data: fetchedOptionList },
  ] = fetchMutation();

  useEffect(() => {
    if (!loaded) {
      fetchOptionListForField({
        ...(mutationProps?.params || {}),
        field: id,
      });
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      setFilteredOptionList(optionList);
    } else if (fetchedOptionList) {
      setFilteredOptionList(fetchedOptionList);
    }
  }, [loaded, fetchedOptionList]);

  const itemClickHandle = (option: AutocompleteOption) => {
    const optionIsSelected = selectedValues?.find(
      (valueItem) => valueItem.value === option.value
    );

    if (optionIsSelected) {
      onChange(
        id,
        selectedValues?.filter(
          (valueItem) => valueItem.value !== option.value
        ) || []
      );
    } else {
      onChange(id, [...(selectedValues || []), option]);
    }
  };

  const isOptionChecked = (option: AutocompleteOption): boolean => {
    return Boolean(
      selectedValues?.find((valueItem) => valueItem.value == option.value)
    );
  };

  const renderHeader = () => {
    if (enableHeader) {
      return (
        <div className="select-chip-option-dropdown-header">
          <div
            className="select-chip-option-dropdown-back-button"
            onClick={() => {
              backClickHandle();
            }}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
          </div>
          <div className="select-chip-option-dropdown-label">{label}</div>
        </div>
      );
    }

    return <></>;
  };

  const searchOptionList = (searchKeyword: string) => {
    setFilteredOptionList(() => {
      const optionItems: Array<AutocompleteOption> = loaded
        ? optionList
        : fetchedOptionList;

      return optionItems?.filter((optionItem) => {
        const searchProperty = optionItem.label || optionItem.value;

        return searchProperty
          ?.toLowerCase()
          .trim()
          .includes(searchKeyword?.toLowerCase().trim());
      });
    });
  };

  const renderSearchInput = () => {
    return (
      <TextField
        fullWidth={true}
        className="filter-input body-1"
        placeholder={translation(`common.searchPlaceholder`)}
        onChange={(e) =>
          debounce(() => {
            searchOptionList(e.target.value);
          }, 100)()
        }
        InputProps={{
          className: 'search-filter-input',
          startAdornment: (
            <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
          ),
        }}
      />
    );
  };

  const renderBody = () => {
    if (loaded) {
      return (
        <div className="select-chip-option-dropdown-body">
          <MenuList>
            {filteredOptionList?.map((option: AutocompleteOption) => {
              return (
                <MenuItem
                  className="select-chip-option-dropdown-item"
                  onClick={() => itemClickHandle(option)}
                >
                  <CommonCheckbox checked={isOptionChecked(option)} />{' '}
                  <span className="select-chip-option-dropdown-item-text">
                    {option.label || option.value}
                  </span>
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
      );
    }

    if (isOptionListLoading) {
      return (
        <div className="select-chip-option-dropdown-loading">
          <CircularProgress size={24} />
        </div>
      );
    }

    if (filteredOptionList?.length === 0) {
      return (
        <div className="select-chip-option-dropdown-no-data">
          No options available
        </div>
      );
    }

    return (
      <div className="select-chip-option-dropdown-body">
        <MenuList>
          {filteredOptionList?.map((option: AutocompleteOption) => {
            return (
              <MenuItem
                className="select-chip-option-dropdown-item"
                onClick={() => itemClickHandle(option)}
                title={option.label || option.value}
              >
                <CommonCheckbox checked={isOptionChecked(option)} />{' '}
                <span className="select-chip-option-dropdown-item-text">
                  {option.label || option.value}
                </span>
              </MenuItem>
            );
          })}
        </MenuList>
      </div>
    );
  };

  return (
    <div className="select-chip-option-dropdown-container">
      {renderHeader()}
      {renderSearchInput()}
      {renderBody()}
    </div>
  );
};

import { FunctionComponent, ReactNode } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

interface InteractiveLabelProps extends BaseComponentProps {
  label: string;
  icon?: ReactNode;
  link?: string;
  linkIcon?: boolean;
}

export const InteractiveLabel: FunctionComponent<InteractiveLabelProps> = ({
  label,
  icon,
  link,
  linkIcon,
}) => {
  const renderIcon = (icon: ReactNode, isLink: boolean) => (
    <div className="interactive-label-icon">
      {isLink ? (
        <a target="_blank" href={link}>
          {icon}
        </a>
      ) : (
        icon
      )}
    </div>
  );

  const renderLabel = () =>
    link?.length ? (
      <a target="_blank" href={link}>
        {label}
      </a>
    ) : (
      <span>{label}</span>
    );

  return (
    <div className="interactive-label">
      {icon && renderIcon(icon, false)}
      <div className="interactive-label-text">{renderLabel()}</div>
      {linkIcon &&
        link?.length &&
        renderIcon(
          <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_UP_RIGHT_FROM_SQUARE_ICON} />,
          !!link?.length
        )}
    </div>
  );
};

import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import Cloud2CodeContentHeader from '../Cloud2CodeContentHeader';
import { useFetchIacDetailsByIdQuery } from 'IaCFindings/store/api';
import FindingContentDescription from 'FindingDetails/components/FindingContent/components/FindingContentDescription';
import { Cloud2CodeDetails } from 'Cloud2Code/components/Cloud2CodeDetails/Cloud2CodeDetails';
import Cloud2CodeOperationalStatus from '../Cloud2CodeOperationalStatus';
import FindingContentAffectedPackages from 'FindingDetails/components/FindingContent/components/FindingContentAffectedPackages';
import Cloud2CodeRelatedFindings from '../Cloud2CodeRelatedFindings';
import FindingContentEvidence from 'FindingDetails/components/FindingContent/components/FindingContentEvidence';
import FindingContentOwners from 'FindingDetails/components/FindingContent/components/FindingContentOwners';
import { RowClickedEvent } from 'ag-grid-community';
import { CodeEventItem } from 'Cloud2Code/interfaces/code-event-item.model';

interface Cloud2CodeContentProps extends BaseComponentProps {
  onFindingClick: (event: RowClickedEvent) => void;
}

export const Cloud2CodeContent: FunctionComponent<Cloud2CodeContentProps> = ({
  onFindingClick,
}) => {
  const [urlSearchParams] = useQueryParams();
  const {
    data: cloud2codeDetailsData,
    isLoading: cloud2CodeDetailsLoading,
    refetch,
  } = useFetchIacDetailsByIdQuery(urlSearchParams.openCodeEventId);
  const [relatedItemsData, setRelatedItemsData] = useState<CodeEventItem[]>([]);
  const [isLoadingRelatedItems, setIsLoadingRelatedItems] =
    useState<boolean>(true);

  useEffect(() => {
    refetch();
  }, [urlSearchParams.openCodeEventId, refetch]);

  return (
    <div className="finding-content-container ">
      <>
        <div className="finding-content-header-and-tabs">
          <Cloud2CodeContentHeader
            codeEventData={cloud2codeDetailsData}
            codeEventDataLoading={cloud2CodeDetailsLoading}
            setRelatedItemsData={setRelatedItemsData}
            setIsLoadingRelatedItems={setIsLoadingRelatedItems}
          />
        </div>

        <div className="finding-content-body">
          <div className="finding-content-details-container">
            <FindingContentDescription
              description={cloud2codeDetailsData?.findingDescription}
            />
            <Cloud2CodeDetails
              codeEventData={cloud2codeDetailsData}
              codeEventDataLoading={cloud2CodeDetailsLoading}
            />
            <Cloud2CodeOperationalStatus
              isLoading={isLoadingRelatedItems}
              codeEventRelatedItems={relatedItemsData}
            />
            <FindingContentEvidence
              findingId={cloud2codeDetailsData?.findingId}
              isLoadingEvidanceData={cloud2CodeDetailsLoading}
              showOnlyFlow
            />
            <FindingContentOwners
              codeEventId={urlSearchParams.openCodeEventId}
            />
            <FindingContentAffectedPackages
              findingId={cloud2codeDetailsData?.findingId}
              isLoading={cloud2CodeDetailsLoading}
            />
            <Cloud2CodeRelatedFindings onFindingClick={onFindingClick} />
          </div>
        </div>
      </>
    </div>
  );
};

import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import { FunctionComponent, useEffect } from 'react';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import VerticalSimpleBarChart from 'shared/components/VerticalSimpleBarChart';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import DashboardChartCard from 'Dashboard/components/DashboardChartCard';
import { ChartOptions } from 'chart.js';
import { useGetDashboardPostureAnalyticsMutation } from 'Dashboard/store/api';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';

interface DashboardReportsRiskFindingBySeverityWidgetProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {}

export const DashboardReportsRiskFindingBySeverityWidget: FunctionComponent<
  DashboardReportsRiskFindingBySeverityWidgetProps
> = ({ businessUnitIds, findingTypes }) => {
  const [
    getBySeverityWidgetData,
    { data: bySeverityWidgetData, isLoading: bySeverityWidgetDataLoading },
  ] = useGetDashboardPostureAnalyticsMutation();

  useEffect(() => {
    getBySeverityWidgetData({
      businessUnitIds: businessUnitIds,
      findingTypes: findingTypes,
      type: PostureWidgetAnalyticsType.ACCUMULATIVE_FINDINGS_BY_SEVERITY,
    });
  }, [businessUnitIds, findingTypes]);

  const renderChartWidget = (widgetData: any, isLoading: boolean) => {
    if ((!widgetData || widgetData?.datasets.length == 0) && !isLoading) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }

    return (
      <>
        <VerticalSimpleBarChart
          fetchWidgetData={() => {}}
          isWidgetDataLoading={isLoading}
          widgetData={widgetData}
          style={
            presentationalConfigurationForWidgets['posture_by_severity'].style
          }
          options={
            presentationalConfigurationForWidgets['posture_by_severity']
              .options as ChartOptions<'bar'>
          }
          customLegendContainerId="posture-dashboard-section-by-severity-chart-legend-container"
          enableTooltip
          applyColorList
        />
        {!isLoading && (
          <div id="posture-dashboard-section-by-severity-chart-legend-container"></div>
        )}
      </>
    );
  };
  return (
    <div className="dashboard-reports-risk-trends-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.CHART_SIMPLE_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Findings by Severity
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-trends-widget-body">
        <DashboardChartCard
          title=""
          className="posture-dashboard-section-by-severity-chart-container"
        >
          {renderChartWidget(bySeverityWidgetData, bySeverityWidgetDataLoading)}
        </DashboardChartCard>
      </div>
    </div>
  );
};

import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { CommonButton } from 'shared/components/CommonButton';
import { IntegrationModalAccordion } from '../IntegrationModalAccordion';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  googleCloudIntegrationFormDefaultSchema,
  googleCloudIntegrationFormRequiredFields,
  googleCloudIntegrationInputKeysToInputLabels,
  googleCloudIntegrationLinks,
} from 'shared/fixtures/data/integrations.data';
import {
  useCreateCloudConnectionMutation,
  useVerifyCloudConnectionMutation,
} from 'Integrations/Connection/store/api';
import {
  CloudProviderType,
  IntegrationActionType,
} from 'shared/models/data/integrations.model';
import { AlertBox, AlertBoxType } from 'shared/components/AlertBox/AlertBox';
import { AlertExpandBox } from 'shared/components/AlertExpandBox/AlertExpandBox';
import { IntegrationsDataHandler } from 'shared/handlers/integrations-data.handler';
import { useTranslation } from 'react-i18next';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import CopyButton from 'Common/components/CopyButton';
import { CircularProgress, TextField } from '@mui/material';
import Autocomplete from 'Common/components/Autocomplete';
import { businessUnitEnvironmentTypesOptionList } from 'shared/fixtures/data/business-unit.data';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OrganizationNodeDataHandler as OrganizationNodeOptionDataHandler } from 'shared/handlers/organization-node-data.handler';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';

interface GoogleCloudIntegrationModalCreateFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

const integrationsDataHandler = new IntegrationsDataHandler();
const organizationNodeOptionDataHandler =
  new OrganizationNodeOptionDataHandler();

export const GoogleCloudIntegrationModalCreateForm: FunctionComponent<
  GoogleCloudIntegrationModalCreateFormProps
> = ({ onCancel }) => {
  const { t: translation } = useTranslation();

  const {
    setValue: setFormValue,
    clearErrors: clearInputErrors,
    getValues: getFormValues,
    setError: setFormError,
    formState: { errors: formErrors },
    watch: watchFormValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape(googleCloudIntegrationFormDefaultSchema)
    ),
    defaultValues: {
      id: '',
      environmentType: '',
      name: '',
      credJson: '',
      projectId: '',
      writeApplicationIds: [] as Array<string>,
      scopeId: '',
    },
  });

  const writeApplicationIds = watchFormValue('writeApplicationIds');
  const scopeIdFormValue = watchFormValue('scopeId');

  const [
    createCloudConnection,
    {
      isLoading: createCloudConnectionLoading,
      error: createCloudConnectionError,
      reset: createCloudConnectionReset,
      isSuccess: createCloudConnectionSuccess,
    },
  ] = useCreateCloudConnectionMutation();

  const [
    verifyConnection,
    {
      isLoading: verifyConnectionLoading,
      error: verifyConnectionError,
      data: verifyConnectionPayload,
      isSuccess: verifyConnectionSuccess,
      reset: verifyConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  const [
    verifySubConnection,
    {
      isLoading: verifySubConnectionLoading,
      error: verifySubConnectionError,
      data: verifySubConnectionPayload,
      isSuccess: verifySubConnectionSuccess,
      reset: verifySubConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  useEffect(() => {
    if (createCloudConnectionSuccess) {
      onCancel();
    }
  }, [createCloudConnectionSuccess]);

  const [fetchScopeList, { data: scopeData, isLoading: scopeDataLoading }] =
    useGetUserAvailableNodesOfTypeMutation();
  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);
  const scopeAutocompleteList: Array<AutocompleteOption> = useMemo(() => {
    return scopeData
      ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeData,
          true
        )
      : [];
  }, [scopeData]);
  const inputChangeHandler = (
    inputName: keyof typeof googleCloudIntegrationFormDefaultSchema,
    inputValue: string
  ) => {
    if (inputValue.length) {
      clearInputErrors(inputName);
    }

    setFormValue(inputName, inputValue?.trim());
  };

  const validateFormBeforeSubmit = () => {
    let valid = true;

    const formValues = getFormValues();

    for (const requiredInputKey of googleCloudIntegrationFormRequiredFields) {
      const typedInputKey =
        requiredInputKey as keyof typeof googleCloudIntegrationFormDefaultSchema;
      if (formValues[typedInputKey]?.length === 0) {
        valid = false;
        setFormError(typedInputKey, {
          type: 'required',
          message: `${googleCloudIntegrationInputKeysToInputLabels[typedInputKey]} is required`,
        });
      }
    }

    return valid;
  };

  const selectedScopeOption = useMemo<AutocompleteOption | undefined>(() => {
    return integrationsDataHandler.getSelectedScopeOption(
      scopeAutocompleteList,
      scopeIdFormValue
    );
  }, [scopeAutocompleteList, scopeIdFormValue]);

  const connectSubApplicationHandler = (applicationId: string) => {
    const subApplicationIds: Array<string> =
      getFormValues().writeApplicationIds || [];

    if (subApplicationIds.includes(applicationId)) {
      setFormValue(
        'writeApplicationIds',
        subApplicationIds.filter((id) => id !== applicationId)
      );
    } else {
      setFormValue('writeApplicationIds', [
        ...subApplicationIds,
        applicationId,
      ]);
    }
  };

  const connectHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      createCloudConnection({
        ...formValues,
        scopeId:
          formValues.scopeId && formValues.scopeId !== 'None'
            ? formValues.scopeId
            : null,
        cloudProvider: CloudProviderType.GCP,
      });
    }
  };

  const verifyHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      verifyConnection({
        cloudProvider: CloudProviderType.GCP,
        body: {
          ...formValues,
          scopeId:
            formValues.scopeId && formValues.scopeId !== 'None'
              ? formValues.scopeId
              : null,
          gcpAppId: CloudProviderType.GCP,
        },
      });
    }
  };

  const verifySubApplicationHandler = (applicationId: string) => {
    resetApiState();

    const formValues = getFormValues();

    verifySubConnection({
      cloudProvider: CloudProviderType.GCP,
      body: {
        ...formValues,
        gcpAppId: applicationId,
      },
    });
  };

  const resetApiState = () => {
    createCloudConnectionReset();
    verifyConnectionReset();
    verifySubConnectionReset();
  };

  const renderAlertBox = () => {
    if (createCloudConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.CREATING,
            'GCP'
          )}
          description={
            (createCloudConnectionError as any)?.data?.message ||
            (createCloudConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'GCP'
          )}
          description={
            (verifyConnectionError as any)?.data?.message ||
            (verifyConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifySubConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'GCP'
          )}
          description={
            (verifySubConnectionError as any)?.data?.message ||
            (verifySubConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload?.success === false) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'GCP'
          )}
          description={
            verifyConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (
      verifySubConnectionPayload &&
      verifySubConnectionPayload?.success === false
    ) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'GCP'
          )}
          description={
            verifySubConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    if (verifySubConnectionPayload && verifySubConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    return <></>;
  };

  const renderConnectButtonRow = () => {
    return (
      <div className="integration-modal-button-row">
        <div className="integration-modal-cancel-buttons">
          <CommonButton type={CommonButtonType.SECONDARY} onClick={onCancel}>
            Cancel
          </CommonButton>
        </div>
        <div className="integration-modal-connection-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={verifyHandler}
          >
            {verifyConnectionLoading
              ? 'Verifying Connectivity'
              : 'Verify Connectivity'}
          </CommonButton>
          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={connectHandler}
          >
            {createCloudConnectionLoading ? 'Connecting' : 'Connect'}
          </CommonButton>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderAlertBox()}
      <div className="cloud-integration-modal-form-wrapper">
        <div className="cloud-integration-modal-form-area">
          <form className="cloud-integration-modal-form">
            <FormInput
              label="Workspace Name"
              name="name"
              placeholder="Please type workspace name"
              value={getFormValues().name}
              onChange={(value: string) => {
                inputChangeHandler('name', value);
              }}
              errorMessage={formErrors?.name?.message}
            />

            <InputLabelWrapper label="Scope">
              {scopeAutocompleteList === null || scopeDataLoading ? (
                <CircularProgress size={24} />
              ) : (
                <Autocomplete
                  model="scopeId"
                  areOptionsLoaded
                  optionList={scopeAutocompleteList}
                  loading={scopeDataLoading}
                  initialSelectedValues={selectedScopeOption}
                  loadingText="Loading"
                  onChangeCallBack={(
                    model: string,
                    option: AutocompleteOption | Array<AutocompleteOption>
                  ) => {
                    inputChangeHandler(
                      'scopeId',
                      (option as AutocompleteOption).value
                    );
                  }}
                  classes={{
                    root: 'multi-select-field-1',
                    paper: 'multi-select-field-paper-1',
                  }}
                  single
                />
              )}
            </InputLabelWrapper>

            <InputLabelWrapper
              label="Workspace Environment Type"
              errorMessage={formErrors?.environmentType?.message}
            >
              <Autocomplete
                model="workspaceEnvironmentType"
                optionList={businessUnitEnvironmentTypesOptionList}
                onChangeCallBack={(
                  model: string,
                  option: AutocompleteOption | Array<AutocompleteOption>
                ) => {
                  inputChangeHandler(
                    'environmentType',
                    (option as AutocompleteOption).value
                  );
                }}
                classes={{
                  root: 'multi-select-field-1',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: formErrors.environmentType
                    ? 'input-validation-error-1'
                    : '',
                }}
                single
              />
            </InputLabelWrapper>

            <div className="cloud-integration-input-title">
              <span>
                {translation(`integrations.details.openYourGcpConsole`)}
              </span>
              <a
                href={googleCloudIntegrationLinks.gcpConsoleLink}
                target="_blank"
              >
                {translation(`common.clickHere`)}
              </a>
            </div>

            <InputLabelWrapper
              label={translation(`integrations.details.enableTheRelevantApis`)}
              classes={{
                label: 'cloud-integration-bold-input-title',
              }}
            >
              <TextField
                className={`text-area-field-1 cloud-integration-text-area`}
                multiline
                disabled
                rows={4}
                value={googleCloudIntegrationLinks.shellCommand}
                InputProps={{
                  endAdornment: (
                    <CopyButton
                      textToCopy={googleCloudIntegrationLinks.shellCommand}
                    />
                  ),
                }}
              />
            </InputLabelWrapper>

            <div className="cloud-integration-input-title cloud-integration-input-title-with-helper-text">
              <span>
                <span>
                  {translation(`integrations.details.createOpusServiceAccount`)}
                </span>
                <a
                  href={googleCloudIntegrationLinks.serviceAccountLink}
                  target="_blank"
                >
                  {translation(`common.clickHere`)}
                </a>
              </span>
              <div className="cloud-integration-input-helper-text">
                {translation(`integrations.details.makeSureToGrantViewer`)}
              </div>
            </div>

            <InputLabelWrapper
              label="Your Service account JSON key"
              errorMessage={formErrors?.credJson?.message}
            >
              <textarea
                rows={4}
                className={`${
                  formErrors.credJson?.message ? 'input-validation-error-1' : ''
                } text-area-field-1`}
                onChange={(event) => {
                  inputChangeHandler('credJson', event.target.value);
                }}
              ></textarea>
            </InputLabelWrapper>

            <FormInput
              label="Project ID"
              name="projectId"
              value={getFormValues().projectId}
              onChange={(value: string) => {
                inputChangeHandler('projectId', value);
              }}
              errorMessage={formErrors?.projectId?.message}
            />
          </form>

          <div className="cloud-integration-modal-accordion-area">
            <IntegrationModalAccordion
              parentApplicationId={CloudProviderType.GCP}
              title="Write Access"
              selectedApplicationIds={writeApplicationIds}
              subApplicationProps={{
                onConnect: connectSubApplicationHandler,
                onVerify: verifySubApplicationHandler,
              }}
            />
          </div>
        </div>

        <div className="cloud-integration-modal-button-area">
          {renderConnectButtonRow()}
        </div>
      </div>
    </>
  );
};

import React, { useState } from 'react';
import { ButtonProps, Menu, MenuItem } from '@mui/material';

interface ActionButtonsProps {
  baseButton: React.ReactElement<ButtonProps>;
  options: React.ReactNode[];
  title?: React.ReactElement;
  closeOnClick?: boolean;
  classes?: {
    menu?: string;
  };
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  baseButton,
  options,
  title,
  closeOnClick = true,
  classes = {},
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOptionClick = (option: any) => {
    if (option.onClick) {
      option.onClick(option.value);
    }
    closeOnClick && handleClose();
  };

  return (
    <div className="action-buttons-container">
      {React.cloneElement(baseButton, { onClick: handleOpenMenu })}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={`actions-list ${classes?.menu || ''}`}
      >
        {title && React.cloneElement(title)}
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => handleOptionClick(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

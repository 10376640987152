export enum ApplicationRoute {
  WORKFLOWS = 'flows',
  INTEGRATIONS = 'integrations',
  SETTINGS = 'settings',
  WORKFLOW_CREATE = 'workflow_create',
  WORKFLOW_EDIT = 'workflow_edit',
  WORKFLOW_SINGLE = 'workflow_view',
  RISKS = 'risks',
  CAMPAIGNS = 'campaigns',
  CAMPAIGN_SINGLE = 'campaign_single',
  FINDING_SINGLE = 'finding_single',
  EVENTS = 'events',
  EVENT_SINGLE = 'event_single',
  DASHBOARDS = 'dashboards',
  AUTOMATION_MANAGEMENT = 'automation_management',
  COMPARATIVE = 'Comparative',
  ASSETS = 'Assets',
}

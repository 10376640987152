import { capitalize } from 'lodash';

export enum FindingSeverity {
  INFORMATIONAL = 5,
  LOW = 10,
  MEDIUM = 20,
  HIGH = 30,
  CRITICAL = 40,
}

export const FindingSeverityList: number[] = (
  Object.values(FindingSeverity) as FindingSeverity[]
).filter((_) => !isNaN(Number(_)));

export const SeverityColorMapper: { [key: number]: string } = {
  5: '#0C5FFD',
  10: '#22CC9A',
  20: '#ED923C',
  30: '#E02B4B',
  40: '#AB2039',
};

export const SeverityTextMapper: { [key: string]: string } = {
  5: 'info',
  10: 'low',
  20: 'medium',
  30: 'high',
  40: 'critical',
};

export const SeverityValueMapper: { [key: string]: number } = {
  Info: 5,
  Low: 10,
  Medium: 20,
  High: 30,
  Critical: 40,
};

export const findingSeverityListOptions = FindingSeverityList.slice()
  .sort((a, b) => b - a)
  .map((severityValue) => ({
    value: String(severityValue),
    label: capitalize(FindingSeverity[severityValue]),
  }));

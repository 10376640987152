import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  ThemeProvider,
  Tooltip,
} from '@mui/material';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import DiagramNavbar from '../Navbar';
import styles from './Layout.module.css';
import ListPanel from '../ListPanel';
import getDiagramStepsFixture, {
  diagramInitialSteps,
} from 'WorkflowBuilder/fixtures/steps';
import { NodeType } from 'WorkflowBuilder/interfaces/node';
import DiagramStep, {
  DIAGRAM_STEP_TYPE,
} from 'WorkflowBuilder/interfaces/step';
import CrossroadPanel from '../CrossroadPanel';
import diagramTheme from '../theme';
import Diagram from '../Builder';
import {
  useFetchFullWorkflowByFamilyIdQuery,
  useSaveWorkflowMutation,
} from '../../store/api';
import { useParams, useSearchParams } from 'react-router-dom';
import { setActiveTool, setAfterTool } from 'WorkflowBuilder/store';
import { useDispatch } from 'react-redux';
import { LAYOUT_MODE } from 'WorkflowBuilder/interfaces/layout';
import Draggable from 'react-draggable';
import { getWorkflowDataSteps } from 'WorkflowBuilder/utils/diagram-fns';
import TriggerPanel from '../WorkflowTriggerPanel';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { rootWorkflowTriggerSeqId } from 'WorkflowBuilder/interfaces/types';
import {
  getCrossroadStepsToRemove,
  getStepsToRemove,
} from 'WorkflowBuilder/utils/step-transform';
import CommonWarningModal from 'shared/components/CommonWarningModal';
import { useCallbackPrompt } from 'shared/hooks/useCallbackPrompt';
import HistoryTable from '../HistoryTable';
import {
  defaultCondition,
  defaultConditionRoute,
} from '../CrossroadPanel/components/CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SingleFindingTab } from 'shared/enums/findings.enum';
import FindingCodeEventDrawerComplex from 'shared/components/FindingCodeEventDrawerComplex/FindingCodeEventDrawerComplex';
import { useFindingSystemActionMutation } from 'FindingDetails/store/api';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface CrossroadPanelState {
  absolutePosition: {
    top: number;
    left: number;
    bottom: number;
    right: number;
  };

  display: boolean;
  activeStepId: number | null;
}

interface DiagramLayoutProps {
  mode: LAYOUT_MODE;
}

export interface WorkflowTriggerDto {
  type: string;
  operator: string;
  value: string;
}

export interface WorkflowFindingContextProps {
  activeFindingId: string | null;
  setActiveFindingId?: (value: string) => void;
}

export const WorkflowFindingContext =
  createContext<WorkflowFindingContextProps>({
    activeFindingId: null,
  });

const nonNegligibleCrossroadParentTypes: Array<DIAGRAM_STEP_TYPE> = [
  DIAGRAM_STEP_TYPE.AWS,
  DIAGRAM_STEP_TYPE.HTTP,
  DIAGRAM_STEP_TYPE.INTERNAL,
  DIAGRAM_STEP_TYPE.HTTP_DUMMY,
  DIAGRAM_STEP_TYPE.AZURE,
  DIAGRAM_STEP_TYPE.MICROSOFT_TEAMS,
  DIAGRAM_STEP_TYPE.WIZ,
  DIAGRAM_STEP_TYPE.LACEWORK,
  DIAGRAM_STEP_TYPE.GCP,
  DIAGRAM_STEP_TYPE.CHECKMARX,
  DIAGRAM_STEP_TYPE.CROWDSTRIKE,
  DIAGRAM_STEP_TYPE.SNOWFLAKE,
];

enum WorkflowBuilderTab {
  WORKFLOW = 'WORKFLOW',
  HISTORY = 'HISTORY',
}

/**
 * Component
 */
export default function DiagramLayout({ mode }: DiagramLayoutProps) {
  const { t: translation } = useTranslation();

  let [, , getUrlSearchParams] = useQueryParams();

  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const [performFindingSystemAction, {}] = useFindingSystemActionMutation();

  const [activeFindingId, setActiveFindingId] = useState<string | null>(null);

  const reactFlowRef = useRef<HTMLDivElement>(null);
  const diagramContainerRef = useRef<HTMLDivElement>(null);

  const { workflowFamilyId } = useParams();

  const [activeTabId, setActiveTabId] = useState<WorkflowBuilderTab>(
    WorkflowBuilderTab.WORKFLOW
  );

  const tabListItems = [
    {
      id: WorkflowBuilderTab.WORKFLOW,
      label: 'Workflow',
    },
    {
      id: WorkflowBuilderTab.HISTORY,
      label: 'History',
    },
  ];

  const onSelectActiveTab = (tabId: string) => {
    setActiveTabId(tabId as WorkflowBuilderTab);
  };

  const renderRunWorkflowButton = () => {
    if (mode === LAYOUT_MODE.VIEW) return <></>;

    return (
      <Tooltip
        title={
          hasUnsavedChanges
            ? translation(`common.youHaveUnsavedChanges`)
            : activeFindingId === null
            ? translation(`flows.details.selectFindingWarning`)
            : ''
        }
      >
        <Button
          className={`workflow-run-button ${
            hasUnsavedChanges || activeFindingId === null
              ? 'workflow-run-button-disabled'
              : ''
          }`}
          onClick={onHandleRunWorkflow}
        >
          <span className="workflow-run-button-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.PLAY_ICON} />
          </span>
          <span className="workflow-">
            {translation(`flows.details.runWorkflow`)}
          </span>
        </Button>
      </Tooltip>
    );
  };

  const renderTabContent = () => {
    switch (activeTabId) {
      case WorkflowBuilderTab.WORKFLOW:
        return (
          <>
            {renderRunWorkflowButton()}
            <ReactFlowProvider>
              <Box className={styles.canvas}>
                {isFetchingWorkflowData ? (
                  <Backdrop
                    open={isFetchingWorkflowData}
                    sx={{
                      color: '#fff',
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                      position: 'absolute',
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Backdrop>
                ) : (
                  <Diagram
                    steps={steps.map((step) => ({
                      ...step,
                      diagramData: {
                        ...step.diagramData,
                        onAddCrossroad: handleAddCrossroad,
                        displayCrossroadPanel,
                        displayTriggerPanel,
                        handleEditNode,
                      },
                    }))}
                    setSteps={setSteps}
                    stepDefs={workflowData?.stepDefs}
                    mode={LAYOUT_MODE.EDIT}
                  />
                )}

                <Box className={styles.bottomMenu}></Box>
                <Box className={styles.addToolPanel}>
                  <Draggable handle=".handle">
                    <ListPanel
                      closeCrossroadPanel={closeCrossroadPanel}
                      onToolAddOrEdit={handleAddOrEditNode}
                      onRemoveNode={handleRemoveNode}
                      onHandleSearch={() => {}}
                      title={translation(`flows.details.addTool`)}
                      steps={steps}
                      setSteps={setSteps}
                    />
                  </Draggable>
                </Box>

                <Box className={styles.addToolPanel}>
                  <CrossroadPanel
                    display={crossroadPanelState.display}
                    steps={steps}
                    setSteps={setSteps}
                    activeStepId={crossroadPanelState.activeStepId}
                    onClose={closeCrossroadPanel}
                    onRemoveNode={handleRemoveCrossroad}
                  />
                </Box>

                <Box className={styles.addToolPanel}>
                  <TriggerPanel
                    display={isTriggerPanelVisible}
                    onClose={closeTriggerPanel}
                    onSave={onWorkflowTriggerPanelSave}
                    triggerList={
                      workflowData?.triggers.map(
                        (trigger: WorkflowTriggerDto) => {
                          return trigger.value;
                        }
                      ) || []
                    }
                    triggerListByApp={workflowData?.triggersByApp}
                    selectedTriggers={workflowTriggers}
                  />
                </Box>
              </Box>
            </ReactFlowProvider>
          </>
        );

      case WorkflowBuilderTab.HISTORY:
        return <HistoryTable familyId={workflowFamilyId as string} />;
    }
  };

  const routeParams = useParams();

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [workflowTriggers, setWorkflowTriggers] = useState<
    WorkflowTriggerDto[]
  >([]);

  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const [, setLastAddedNode] = useState<DiagramStep | null>(null);
  const [isFirstNode, setIsFirstNode] = useState<boolean>(false);

  const {
    data: workflowData,
    isLoading: isFetchingWorkflowData,
    isError: isFetchingWorkflowError,
    error: fetchingWorkflowError,
  } = useFetchFullWorkflowByFamilyIdQuery(routeParams['workflowFamilyId'], {
    skip: !routeParams['workflowFamilyId'],
    refetchOnMountOrArgChange: true,
  });

  const [steps, setSteps] = useState<DiagramStep[]>(
    getDiagramStepsFixture(reactFlowRef)
  );

  const [
    saveWorkflow,
    {
      isLoading: isSaving,
      isSuccess: isWorkflowSaveSuccess,
      isError: isWorkflowSaveError,
      data: workflowResultData,
      error: workflowResultError,
    },
  ] = useSaveWorkflowMutation();

  const hasUnsavedChanges = useMemo<boolean>(() => {
    if (mode === LAYOUT_MODE.VIEW) return false;

    if (workflowResultData) {
      const stepsFromSavedWorkflow = getWorkflowDataSteps(
        workflowResultData,
        mode
      );

      return JSON.stringify(stepsFromSavedWorkflow) !== JSON.stringify(steps);
    }

    if (routeParams['workflowFamilyId']) {
      const stepsFromWorkflow = getWorkflowDataSteps(workflowData, mode);

      return JSON.stringify(stepsFromWorkflow) !== JSON.stringify(steps);
    } else {
      return JSON.stringify(diagramInitialSteps) !== JSON.stringify(steps);
    }
  }, [workflowData, workflowResultData, steps, mode]);

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(hasUnsavedChanges);

  const [, setSavedSteps] = useState<Array<any>>([]);

  const dispatch = useDispatch();

  const [isTriggerPanelVisible, setTriggerPanelVisibility] =
    useState<boolean>(false);

  useEffect(() => {
    if (workflowData && workflowData.stepDefs) {
      if (workflowData.triggers) {
        setWorkflowTriggers(workflowData.triggers);
      }
      const stepsFromWorkflow = getWorkflowDataSteps(workflowData, mode);

      setSteps(stepsFromWorkflow);
    }
  }, [workflowData]);

  useEffect(() => {
    if (workflowResultData && workflowResultData.stepDefs) {
      if (workflowResultData.triggers) {
        setWorkflowTriggers(workflowResultData.triggers);
      }
      const stepsFromSavedWorkflow = getWorkflowDataSteps(
        workflowResultData,
        mode
      );

      setSteps(stepsFromSavedWorkflow);
    }
  }, [workflowResultData]);

  useEffect(() => {
    isWorkflowSaveSuccess
      ? setShowSuccessMessage(true)
      : isWorkflowSaveError && setShowErrorMessage(true);

    isFetchingWorkflowError && setShowErrorMessage(true);

    setTimeout(() => {
      setShowSuccessMessage(false);
      setShowErrorMessage(false);
    }, 3000);
  }, [isWorkflowSaveSuccess, isWorkflowSaveError]);

  const handleSaveFullWorkflow = (workflowName: string) => {
    if (mode === LAYOUT_MODE.VIEW) {
      window.location.replace(`/builder/${workflowData?.familyId}/edit`);
    } else if (mode === LAYOUT_MODE.EDIT) {
      saveWorkflow({
        workflowDef: {
          id: workflowData?.id,
          familyId: workflowData?.familyId,
          name: workflowName,
          rootWorkflowSeqId:
            workflowData?.rootWorkflowSeqId || rootWorkflowTriggerSeqId,
          triggers: workflowTriggers,
        },
        stepDefs: steps
          .filter((step: DiagramStep) => {
            return (
              step.type !== DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY &&
              step.type !== DIAGRAM_STEP_TYPE.HTTP_DUMMY &&
              step.nodeType !== NodeType.PLUS
            );
          })
          .map((step: DiagramStep) => {
            return {
              ...step,
              type: step.type?.toLowerCase(),
              diagramData: {
                position: {
                  ...(step.diagramData?.position || {}),
                },
              },
              triggers: null,
            };
          })
          .sort((step) => {
            return step.type === DIAGRAM_STEP_TYPE.TRIGGER ? -1 : 1;
          }),
      });
    }
  };

  useEffect(() => {
    if (isWorkflowSaveSuccess && !workflowData) {
      setTimeout(() => {
        window.location.replace(
          `/builder/${workflowResultData?.familyId}/edit`
        );
      }, 3000);
    }
  }, [isWorkflowSaveSuccess]);

  const [crossroadPanelState, setCrossroadPanelState] =
    useState<CrossroadPanelState>({
      absolutePosition: { top: 0, left: 0, bottom: 0, right: 0 },
      display: false,
      activeStepId: null,
    });

  function getNewNodePosition(
    afterTool: number,
    beforeNode: number | null
  ): { x: number; y: number } {
    const prevStep = steps.find((s) => s.workflowSeqId === afterTool);
    const nextStep = steps.find((s) => s.workflowSeqId === beforeNode);

    if (!prevStep) {
      return { x: 0, y: 0 }; // fallback
    }
    const { x: x1, y: y1 } = prevStep.diagramData.position;
    if (nextStep) {
      const { x: x2, y: y2 } = nextStep.diagramData.position;
      const x = Math.round((x1 + x2) / 2);
      const y = Math.round((y1 + y2) / 2);
      return { x, y };
    }
    return { x: x1, y: y1 + 200 };
  }

  const newStepSeqId =
    steps.reduce(
      (seqId, step) =>
        step.workflowSeqId > seqId ? step.workflowSeqId : seqId,
      0
    ) + 1;

  function handleAddOrEditNode(
    payload: {
      label?: string;
      logo?: string;
      application?: any;
      actionTemplate?: any;
      connectionData?: any;
      activeTool?: number | null;
    },
    afterTool: number | null,
    beforeNode: number | null,
    mode: 'edit' | 'create'
  ) {
    const previousStep = steps.find((step) => step.workflowSeqId === afterTool);
    const { label, logo, application, actionTemplate, connectionData } =
      payload;
    const seqId = newStepSeqId;
    const routes =
      beforeNode === null ? [] : [{ nextWorkflowSeqId: beforeNode }];

    if (mode === 'create') {
      const step: DiagramStep = {
        name: connectionData['system::name'],
        type: actionTemplate?.type,
        nodeType: NodeType.HTTP,
        applicationId: application?.id,
        actionTemplateId: actionTemplate.id,
        timelineEvent: actionTemplate.timelineEvent,
        workFlowAffectOnFail: actionTemplate.workFlowAffectOnFail,
        parameters: actionTemplate.parameters?.map((parameter: any) => {
          return {
            ...parameter,
            value: connectionData[parameter.name],
          };
        }),
        diagramData: {
          position:
            previousStep?.type === DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY
              ? {
                  x: previousStep.diagramData.position?.x - 120,
                  y: previousStep.diagramData.position?.y,
                }
              : getNewNodePosition(afterTool as number, beforeNode),
        },
        workflowSeqId:
          previousStep?.type === DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY
            ? (afterTool as number)
            : seqId,
        routes,
        icon: logo,
      };

      setLastAddedNode(step);

      setSteps((prevSteps) => {
        return [
          ...prevSteps
            .filter((step) => {
              return !(
                step.nodeType === NodeType.PLUS &&
                step.workflowSeqId === afterTool
              );
            })
            .map((x) =>
              x.workflowSeqId !== afterTool
                ? x
                : {
                    ...x,
                    routes: [
                      ...x.routes.filter(
                        (y) => y.nextWorkflowSeqId !== beforeNode
                      ),
                      { nextWorkflowSeqId: seqId },
                    ],
                  }
            ),
          step,
        ];
      });
    } else {
      setSteps(
        steps.map((step: DiagramStep) => {
          if (step.workflowSeqId === payload.activeTool) {
            return {
              ...step,
              name: connectionData['system::name'],
              timelineEvent: actionTemplate.timelineEvent,
              workFlowAffectOnFail: actionTemplate.workFlowAffectOnFail,
              parameters: actionTemplate.parameters?.map((parameter: any) => {
                return {
                  ...parameter,
                  value: connectionData[parameter.name],
                };
              }),
            };
          }
          return step;
        })
      );
    }
  }

  function handleRemoveNode(seqId: number) {
    dispatch(setActiveTool(null));
    dispatch(setAfterTool(null));

    let stepToBeRemovedIndex = steps.findIndex((step: DiagramStep) => {
      return step.workflowSeqId === seqId;
    });

    const stepToBeRemoved = steps[stepToBeRemovedIndex];

    let updatedSteps = [...steps];

    if (stepToBeRemovedIndex >= 0) {
      const previousStepIndex = updatedSteps.findIndex((step: DiagramStep) => {
        return step.routes.find((route: any) => {
          return route.nextWorkflowSeqId === stepToBeRemoved.workflowSeqId;
        });
      });

      const nextStepIndex = updatedSteps.findIndex((step: DiagramStep) => {
        return stepToBeRemoved.routes.find((route: any) => {
          return route.nextWorkflowSeqId === step.workflowSeqId;
        });
      });

      const previousStep = updatedSteps[previousStepIndex];
      const nextStep = updatedSteps[nextStepIndex];

      if (previousStep && previousStep.type === DIAGRAM_STEP_TYPE.CROSSROAD) {
        if (nextStep && nextStep.type === DIAGRAM_STEP_TYPE.CROSSROAD) {
          updatedSteps[previousStepIndex] = {
            ...updatedSteps[previousStepIndex],
            routes: updatedSteps[previousStepIndex].routes.map((route) => {
              if (route.nextWorkflowSeqId === stepToBeRemoved.workflowSeqId) {
                return {
                  ...route,
                  nextWorkflowSeqId: nextStep.workflowSeqId,
                };
              } else {
                return route;
              }
            }),
          };
          const filteredUpdatedSteps = updatedSteps.filter(
            (step: DiagramStep) => {
              return step.workflowSeqId !== seqId;
            }
          );
          setSteps(filteredUpdatedSteps);
        } else {
          const filteredUpdatedSteps = updatedSteps.filter(
            (step: DiagramStep) => {
              return step.workflowSeqId !== seqId;
            }
          );
          filteredUpdatedSteps.push({
            name: `${NodeType.CROSSROAD}_${previousStepIndex}_route_${
              previousStepIndex + 1
            }`,
            type: DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY,
            workflowSeqId: stepToBeRemoved.workflowSeqId,
            diagramData: {
              position: {
                x:
                  stepToBeRemoved.diagramData.position.x > 0
                    ? stepToBeRemoved.diagramData.position.x - 120
                    : stepToBeRemoved.diagramData.position.x + 120,
                y: stepToBeRemoved.diagramData.position.y,
              },
            },
            nodeType: NodeType.PLUS,
            routes: stepToBeRemoved.routes,
          });

          setSteps(filteredUpdatedSteps);
        }
      } else {
        updatedSteps[previousStepIndex] = {
          ...updatedSteps[previousStepIndex],
          routes: stepToBeRemoved.routes,
        };

        const filteredUpdatedSteps = updatedSteps.filter(
          (step: DiagramStep) => {
            return step.workflowSeqId !== seqId;
          }
        );
        setSteps(filteredUpdatedSteps);
      }
    }
  }

  function handleRemoveCrossroad(seqId: number) {
    dispatch(setActiveTool(null));
    dispatch(setAfterTool(null));

    let stepToBeRemovedIndex = steps.findIndex((step: DiagramStep) => {
      return step.workflowSeqId === seqId;
    });

    const previousStep = steps.find((step: DiagramStep) =>
      step.routes.find((route) => route.nextWorkflowSeqId === seqId)
    );

    const stepToBeRemoved = steps[stepToBeRemovedIndex];

    closeCrossroadPanel();

    const stepsToRemove = getCrossroadStepsToRemove(stepToBeRemoved, steps);

    setSteps(
      steps
        .filter((step: DiagramStep) => {
          return !stepsToRemove.includes(step.workflowSeqId);
        })
        .map((step: DiagramStep) => {
          if (step.workflowSeqId === previousStep?.workflowSeqId) {
            return {
              ...step,
              diagramData: {
                ...step.diagramData,
                isLeaf: true,
              },
              routes: step.routes.filter(
                (route) => route.nextWorkflowSeqId !== seqId
              ),
            };
          }

          return step;
        })
    );
  }

  function getCrossroadInitialChildrenNodes(
    parentId: number,
    parentPosition: {
      x: number;
      y: number;
    },
    seqIdToConnect?: number
  ): Array<DiagramStep> {
    const { x: parentXPosition, y: parentYPosition } = parentPosition;

    const nodeIndexes = seqIdToConnect ? [1] : [1, 2];

    return nodeIndexes.map((item: any, index: number) => {
      return {
        name: `${NodeType.CROSSROAD}_${parentId}_route_${
          parentId + (index + 1)
        }`,
        type: DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY,
        workflowSeqId: parentId + (index + 1),
        diagramData: {
          position: {
            x:
              (index + 1) % 2 === 0
                ? parentXPosition + 300
                : parentXPosition - 300,
            y: parentYPosition + 150,
          },
        },
        nodeType: NodeType.PLUS,
        routes: [
          {
            nextWorkflowSeqId: parentId + (index + 1),
            name: 'Lorem Ipsum',
            isFallback: false,
            conditions: [[defaultCondition]],
          },
        ],
      };
    });
  }

  function handleAddCrossroad(node: any) {
    const previousStep = steps.find(
      (step) => String(step?.workflowSeqId) === node.id
    );

    let highestStepId = 0;

    for (let step of steps) {
      if (step.workflowSeqId > highestStepId) {
        highestStepId = step.workflowSeqId;
      }
    }

    const upcomingSteps = getStepsToRemove(previousStep as DiagramStep, steps, [
      +(previousStep?.workflowSeqId as number),
    ]);

    const stepsToMoveDown = upcomingSteps.slice(1);

    const seqId = highestStepId || Number(node.id);

    const crossRoadParentDimensions = {
      x: node.positionAbsolute?.x + node.width / 2 - 30,
      y: (previousStep?.diagramData.position.y as number) + 200,
    };

    const crossroadChildrenNodes = getCrossroadInitialChildrenNodes(
      seqId + 1,
      crossRoadParentDimensions,
      stepsToMoveDown[0]
    );

    const crossroadRoutes = [
      ...crossroadChildrenNodes.flatMap((childrenNode: DiagramStep) => {
        return childrenNode.routes;
      }),
    ];

    if (stepsToMoveDown[0]) {
      crossroadRoutes.push({
        ...defaultConditionRoute,
        nextWorkflowSeqId: stepsToMoveDown[0],
      });
    }

    const crossroadStep: DiagramStep = {
      name: `${NodeType.CROSSROAD}_${seqId + 1}`,
      type: DIAGRAM_STEP_TYPE.CROSSROAD,
      workflowSeqId: seqId + 1,
      diagramData: {
        position: crossRoadParentDimensions,
      },
      parameters: [{}],
      nodeType: NodeType.CROSSROAD,
      routes: crossroadRoutes,
    };

    const parentCrossroad = nonNegligibleCrossroadParentTypes.every(
      (type: DIAGRAM_STEP_TYPE) =>
        previousStep?.type?.toLowerCase() !== type.toLowerCase()
    )
      ? steps.find((step: DiagramStep) => {
          return (
            step?.type === DIAGRAM_STEP_TYPE.CROSSROAD &&
            step.routes.find((route) => {
              return route.nextWorkflowSeqId === previousStep?.workflowSeqId;
            })
          );
        })
      : null;

    setSteps((prevSteps) => {
      return [
        ...prevSteps
          .filter((step: DiagramStep) => {
            return !(
              step.workflowSeqId === previousStep?.workflowSeqId &&
              step.nodeType === NodeType.PLUS
            );
          })
          .map((step: DiagramStep) => {
            if (
              parentCrossroad &&
              step.workflowSeqId === parentCrossroad.workflowSeqId
            ) {
              return {
                ...step,
                routes: step.routes.map((route) => {
                  if (route.nextWorkflowSeqId === previousStep?.workflowSeqId) {
                    return {
                      ...route,
                      nextWorkflowSeqId: crossroadStep.workflowSeqId,
                    };
                  }
                  return route;
                }),
              };
            }

            if (step.workflowSeqId === Number(node.id)) {
              return {
                ...step,
                diagramData: {
                  ...step.diagramData,
                  isLeaf: false,
                },
                routes: [
                  {
                    nextWorkflowSeqId: seqId + 1,
                  },
                ],
              };
            }

            return step;
          })
          .map((step: DiagramStep) => {
            if (stepsToMoveDown.includes(step.workflowSeqId)) {
              return {
                ...step,
                diagramData: {
                  ...step.diagramData,
                  position: {
                    ...step.diagramData.position,
                    y: step.diagramData.position.y + 200,
                  },
                },
              };
            }
            return step;
          }),
        crossroadStep,
        ...crossroadChildrenNodes.map((childNode: DiagramStep) => {
          childNode.routes = [];
          return childNode;
        }),
      ];
    });
    // setIsFirstNode(!parentCrossroad);
    setSavedSteps(steps);
  }

  useEffect(() => {
    if (isFirstNode) {
      setSavedSteps(steps);
      setIsFirstNode(false);
    }
  }, [isFirstNode, steps]);
  const displayCrossroadPanel = (
    position: { top: number; left: number; bottom: number; right: number },
    stepId: number
  ) => {
    dispatch(setActiveTool(null));
    dispatch(setAfterTool(null));
    setCrossroadPanelState({
      absolutePosition: position,
      display: true,
      activeStepId: stepId,
    });
  };

  const closeCrossroadPanel = () => {
    setCrossroadPanelState({
      ...crossroadPanelState,
      display: false,
      activeStepId: null,
      absolutePosition: {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      },
    });
  };

  const handleEditNode = (seqId: number) => {
    dispatch(setActiveTool(seqId));
  };

  const displayTriggerPanel = () => {
    dispatch(setActiveTool(null));
    closeCrossroadPanel();
    setTriggerPanelVisibility(true);
  };

  const closeTriggerPanel = () => {
    setTriggerPanelVisibility(false);
  };

  const onWorkflowTriggerPanelSave = (
    values: Record<string, Array<string>>
  ) => {
    setSteps(
      steps.map((step: DiagramStep) => {
        if (step.nodeType === NodeType.TRIGGER) {
          const triggerItems = Object.keys(values).reduce(
            (accumulator: Array<any>, currentValue: string) => {
              return [
                ...accumulator,
                ...values[currentValue].map((value) => ({
                  type: 'finding-type',
                  operator: 'equal',
                  value,
                  applicationId: currentValue,
                })),
              ];
            },
            []
          );
          setWorkflowTriggers(triggerItems);
          return {
            ...step,
            type: DIAGRAM_STEP_TYPE.TRIGGER,
            name: triggerItems.length
              ? translation(`flows.details.workflowTrigger`)
              : translation(`flows.details.setWorkflowTrigger`),
            diagramData: {
              ...step.diagramData,
              value: triggerItems?.map((triggerItem: any) => triggerItem.value),
            },
            parameters: [],
            workflowSeqId:
              workflowData?.rootWorkflowSeqId || rootWorkflowTriggerSeqId,
          };
        }

        return step;
      })
    );
  };

  const onHandleWarningModalClose = () => {
    cancelNavigation();
  };

  const onHandleWarningModalAccept = () => {
    confirmNavigation();
  };

  const onHandleRunWorkflow = () => {
    if (activeFindingId === null || hasUnsavedChanges) return;

    performFindingSystemAction({
      findingId: activeFindingId,
      action: 'rerun',
    });

    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    updatedReactiveSearchParams.set('openFindingId', activeFindingId);
    updatedReactiveSearchParams.set(
      'findingActiveTabId',
      SingleFindingTab.WORKFLOW
    );

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  return (
    <WorkflowFindingContext.Provider
      value={{
        activeFindingId: activeFindingId,
        setActiveFindingId: (value: string) => {
          setActiveFindingId(value);
        },
      }}
    >
      <ThemeProvider theme={diagramTheme}>
        <CommonWarningModal
          isOpen={showPrompt}
          title={translation(`common.youHaveUnsavedChanges`)}
          message={translation(`common.areYouSureYouWantToExit`)}
          onAccept={onHandleWarningModalAccept}
          onCancel={onHandleWarningModalClose}
        />
        <Stack
          height="100vh"
          ref={diagramContainerRef}
          id="diagram-container"
          className="workflow-container"
        >
          <Box position="relative" zIndex={5}>
            <DiagramNavbar
              mode={mode}
              workflowData={workflowData}
              saveWorkflow={handleSaveFullWorkflow}
              isFetchingWorkflowData={isFetchingWorkflowData}
              activeTabId={activeTabId}
              tabListItems={tabListItems}
              onSelectActiveTab={onSelectActiveTab}
            />
          </Box>
          {renderTabContent()}
        </Stack>

        <Snackbar
          open={showSuccessMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={3000}
        >
          <Alert severity="success" sx={{ width: '100%' }}>
            <FormattedMessage
              id="flows.details.savedWorkflowSuccessfully"
              defaultMessage="Saved workflow successfully!"
            />
          </Alert>
        </Snackbar>
        <Snackbar
          open={showErrorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {Array.isArray((fetchingWorkflowError as any)?.data?.message)
              ? (workflowResultError as any)?.data?.message[0]
              : (workflowResultError as any)?.data?.message}
          </Alert>
        </Snackbar>
        <Snackbar
          open={isFetchingWorkflowData}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="info" sx={{ width: '100%' }}>
            <FormattedMessage
              id="flows.details.retrievingWorkflowConfiguration"
              defaultMessage="Retrieving workflow configuration"
            />
          </Alert>
        </Snackbar>
        <Snackbar
          open={isSaving}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity="info" sx={{ width: '100%' }}>
            <FormattedMessage
              id="flows.details.savingWorkflowConfiguration"
              defaultMessage="Saving workflow configuration"
            />
          </Alert>
        </Snackbar>
      </ThemeProvider>
      <FindingCodeEventDrawerComplex />
    </WorkflowFindingContext.Provider>
  );
}

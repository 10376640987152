import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  MenuItem,
  Box,
  CircularProgress,
  Button,
  Popover,
} from '@mui/material';
import { TextField } from '@mui/material';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useTranslation } from 'react-i18next';

interface DropdownPickerProps {
  title?: string;
  listItems?: any;
  fetchItemsMutation?: any;
  fetchItemsFunctionParams?: any;
  selectedListItem?: any;
  searchMode?: boolean;
  getSearchProperty?: (item: any) => string;
  readonly?: boolean;
  renderButtonContent: () => React.ReactElement;
  renderItem: (item: any) => React.ReactElement;
  onListItemClick?: (item: any) => void;
  displayFullWidthPopover?: boolean;
  inputId?: string;
}

export const DropdownPicker: FunctionComponent<DropdownPickerProps> = ({
  listItems,
  fetchItemsMutation,
  fetchItemsFunctionParams,
  selectedListItem,
  getSearchProperty,
  searchMode = false,
  readonly = false,
  renderButtonContent,
  renderItem,
  onListItemClick,
  displayFullWidthPopover,
  inputId,
}) => {
  const { t: translation } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  let fetchItems: (params?: any) => {},
    isLoadingItems = false,
    fetchedItems: undefined | any[] = undefined;
  if (fetchItemsMutation) {
    [fetchItems, { isLoading: isLoadingItems, data: fetchedItems }] =
      fetchItemsMutation();
  }
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (fetchedItems) setItems(fetchedItems);
  }, [fetchedItems]);

  useEffect(() => {
    let searchableItems = listItems ? listItems : fetchedItems;
    if (searchableItems && searchMode && getSearchProperty) {
      let returnedResults = searchableItems.filter((item: any) =>
        getSearchProperty(item)
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
      setItems(returnedResults);
    }
  }, [listItems, fetchedItems, searchTerm, searchMode]);

  const onSearch = (text: any) => setSearchTerm(text);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (readonly) return;
    if (listItems) {
      setItems(listItems);
    } else if (!fetchedItems) {
      fetchItemsFunctionParams
        ? fetchItems(fetchItemsFunctionParams)
        : fetchItems();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onSearch('');
  };

  const handleMenuItemClick = (item: any) => {
    setAnchorEl(null);
    onListItemClick && onListItemClick(item);
    onSearch('');
  };

  function renderContent() {
    if (isLoadingItems)
      return (
        <Box textAlign="center" p={2}>
          <CircularProgress size={24} />
        </Box>
      );
    return (
      <>
        {items.map((item) => (
          <MenuItem
            onClick={() => handleMenuItemClick(item)}
            className={
              selectedListItem?.id === item.id
                ? 'active dropdown-menu-item'
                : 'dropdown-menu-item'
            }
          >
            {renderItem(item)}
          </MenuItem>
        ))}
      </>
    );
  }

  function renderIconButtonContent() {
    return (
      <>
        {renderButtonContent()}
        {!readonly && (
          <OpusSvgIcon
            type={
              open
                ? SVG_ICON_TYPES.CHEVRON_UP_ICON
                : SVG_ICON_TYPES.CHEVRON_DOWN_ICON
            }
            style={{ marginRight: '6px' }}
          />
        )}
      </>
    );
  }

  return (
    <div className="dropdown-picker-container">
      <Button
        onClick={handleClick}
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={readonly}
        className="dropdown-button"
        id={inputId}
      >
        {renderIconButtonContent()}
      </Button>
      <Popover
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        className={`popover-filter`}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        PaperProps={{
          style: {
            width: displayFullWidthPopover ? anchorEl?.clientWidth : undefined,
          },
        }}
      >
        {searchMode ? (
          <TextField
            fullWidth={true}
            className="filter-input body-1"
            placeholder={translation(`common.searchPlaceholder`)}
            onChange={(e: any) => onSearch(e.target.value)}
            InputProps={{
              className: 'search-filter-input',
              startAdornment: (
                <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
              ),
            }}
          />
        ) : null}
        {renderContent()}
      </Popover>
    </div>
  );
};

import { Grid, Typography } from '@mui/material';
import React from 'react';

export interface MenuItemProps {
  title: string;
  icon: React.ReactElement;
  isActive: boolean;
  onClick: () => void;
}
export const MenuItem: React.FC<MenuItemProps> = ({
  title,
  icon,
  isActive,
  onClick,
}) => {
  return (
    <Grid
      item
      className={`side-menu-item  ${isActive && 'active'}`}
      component="div"
      onClick={onClick}
    >
      <div className="icon-container">{icon}</div>
      <div className="title">
        <p>{title}</p>
      </div>
    </Grid>
  );
};

import { FunctionComponent, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import AutomationManagementTableHeader from './components/AutomationManagementTableHeader';
import AutomationManagementTableRow from './components/AutomationManagementTableRow';
import useCommonSelector from 'Common/utils/use-selector';
import {
  selectRuleGroups,
  setGlobalOptions,
  setRuleGroups,
  selectRuleGroupsSearchParams,
  setRuleGroupsSearchParams,
  AutomationManagementRuleGroupSearchParams,
} from 'AutomationManagement/store';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { CommonDispatch } from 'Common/store';
import { AutomationManagementTableProps } from 'shared/models/props/automation-management-props.model';
import {
  useFetchRemediationRulesCountQuery,
  useSearchRemediationRulesMutation,
} from 'AutomationManagement/store/api';
import { AutomationRuleValue } from 'shared/models/data/automation-rule-group.model';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';
import { useFetchFilterByTypeMutation } from 'shared/store/api';
import CommonPagination from 'Common/components/Pagination';

export const AutomationManagementTable: FunctionComponent<
  AutomationManagementTableProps
> = () => {
  const ruleGroups = useCommonSelector(selectRuleGroups);

  const ruleGroupsSearchParams =
    useCommonSelector<AutomationManagementRuleGroupSearchParams>(
      selectRuleGroupsSearchParams
    );

  const dispatch: CommonDispatch = useCommonDispatch();

  const [
    searchRulesGroupedByTriggers,
    {
      data: rulesGroupedByTriggers,
      isLoading: isRulesGroupsLoading,
      status: ruleGroupsFetchingStatus,
    },
  ] = useSearchRemediationRulesMutation();

  const { data: ruleGroupedByTriggersCount } =
    useFetchRemediationRulesCountQuery('');

  useEffect(() => {
    searchRulesGroupedByTriggers(ruleGroupsSearchParams);

    return () => {
      dispatch(setRuleGroupsSearchParams({}));
    };
  }, []);

  useEffect(() => {
    if (ruleGroupsFetchingStatus === QueryStatus.fulfilled) {
      dispatch(setRuleGroups(rulesGroupedByTriggers.data));
    }
  }, [ruleGroupsFetchingStatus]);

  const [fetchBusinessUnitIds, { data: businessUnitIds }] =
    useFetchFilterByTypeMutation();

  const [fetchBusinessUnitEnvironmentIds, { data: businessEnvironmentIds }] =
    useFetchFilterByTypeMutation();

  const [
    fetchBusinessUnitEnvironmentTypes,
    { data: businessEnvironmentTypes },
  ] = useFetchFilterByTypeMutation();

  useEffect(() => {
    fetchBusinessUnitIds({
      type: 'config/filters/businessUnitId',
    });

    fetchBusinessUnitEnvironmentTypes({
      type: 'config/filters/businessUnitEnvironmentType',
    });
  }, []);

  useEffect(() => {
    if (businessUnitIds?.length) {
      dispatch(
        setGlobalOptions({
          options: {
            businessUnitIds: businessUnitIds as Array<AutomationRuleValue>,
          },
        })
      );
    }

    if (businessEnvironmentTypes?.length) {
      dispatch(
        setGlobalOptions({
          options: {
            businessEnvironmentTypes:
              businessEnvironmentTypes as Array<AutomationRuleValue>,
          },
        })
      );
    }
  }, [businessUnitIds, businessEnvironmentTypes]);

  const onPaginationPageChange = (pageNumber: number) => {
    const updatedRuleGroupsSearchParams: AutomationManagementRuleGroupSearchParams =
      {
        ...ruleGroupsSearchParams,
        pagination: {
          ...ruleGroupsSearchParams?.pagination,
          skip:
            (pageNumber - 1) *
            (ruleGroupsSearchParams?.pagination?.take as number),
        },
      };

    dispatch(setRuleGroupsSearchParams(updatedRuleGroupsSearchParams));

    searchRulesGroupedByTriggers(updatedRuleGroupsSearchParams);
  };

  const onPaginationPageSizeChange = (pageSize: number) => {
    const udpatedRuleGroupsSearchParams: AutomationManagementRuleGroupSearchParams =
      {
        ...ruleGroupsSearchParams,
        pagination: {
          skip: 0,
          take: pageSize,
        },
      };

    dispatch(setRuleGroupsSearchParams(udpatedRuleGroupsSearchParams));

    searchRulesGroupedByTriggers(udpatedRuleGroupsSearchParams);
  };

  return (
    <div className="automation-management-table-container">
      <DataGrid
        components={{
          Header: AutomationManagementTableHeader,
          Row: AutomationManagementTableRow,
          Pagination: CommonPagination,
        }}
        columns={[]}
        rows={ruleGroups || []}
        classes={{
          root: 'automation-management-table',
        }}
        componentsProps={{
          pagination: {
            pageSize: ruleGroupsSearchParams?.pagination?.take,
            currentPage:
              ((ruleGroupsSearchParams?.pagination?.take as number) +
                (ruleGroupsSearchParams?.pagination?.skip as number)) /
              (ruleGroupsSearchParams?.pagination?.take as number),
            totalItems:
              rulesGroupedByTriggers?.totalCount ||
              ruleGroupedByTriggersCount ||
              100,
            onPageChange: onPaginationPageChange,
            onPageSizeChange: onPaginationPageSizeChange,
          },
          header: {
            searchRuleGroups: searchRulesGroupedByTriggers,
          },
          row: {
            searchRuleGroups: searchRulesGroupedByTriggers,
          },
        }}
        pagination
        loading={isRulesGroupsLoading}
        disableVirtualization
      />
    </div>
  );
};

import {
  ChangeEvent,
  FunctionComponent,
  useRef,
  useState,
  useEffect,
} from 'react';
import { NumberRangeState } from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import Slider, { SliderRef } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Tooltip } from '@mui/material';

const SliderHandle = (props: any) => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      title={value}
      placement="top"
      classes={{
        tooltip: 'range-slider-handle-tooltip',
      }}
    >
      <div {...restProps} className="range-slider-handle" />
    </Tooltip>
  );
};

interface NumberRangeInputProps extends BaseComponentProps, NumberRangeState {
  label: string;
  onRangeChange: (state: NumberRangeState) => void;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
}

const numberRangeDefaultState: number = 0.1;

export const NumberRangeInput: FunctionComponent<NumberRangeInputProps> = ({
  startValue,
  endValue,
  label,
  onRangeChange,
  actionHandlers,
  minValue,
  maxValue,
  step,
}) => {
  const sliderRef = useRef<SliderRef | null>(null);

  const sliderStep = step || numberRangeDefaultState;

  const sliderDefaultValues =
    startValue !== undefined && endValue !== undefined
      ? [startValue, endValue]
      : [minValue, maxValue];

  const [sliderValues, setSliderValues] =
    useState<Array<number>>(sliderDefaultValues);

  const [startInputValue, setStartInputValue] = useState<string>(
    String(sliderDefaultValues[0])
  );
  const [endInputValue, setEndInputValue] = useState<string>(
    String(sliderDefaultValues[1])
  );

  useEffect(() => {
    setSliderValues(sliderDefaultValues);
  }, [startValue, endValue, minValue, maxValue]);

  const renderTitleArea = () => {
    return (
      <div className="filter-title-container">
        {actionHandlers?.goBackHandler ? (
          <div className="filter-go-back-button">
            <div
              onClick={() => {
                actionHandlers.goBackHandler && actionHandlers.goBackHandler();
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="filter-title">{label}</div>
      </div>
    );
  };

  const isValueInRange = (value: number) => {
    return value >= minValue && value <= maxValue;
  };

  const rangeStartChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const value = rawValue.length === 0 ? 0 : parseFloat(event.target.value);

    const sliderEndValue = sliderValues[1];

    if (
      isValueInRange(value) &&
      value <= sliderEndValue &&
      rawValue.length < 4
    ) {
      setStartInputValue(rawValue);
      setSliderValues([value, sliderValues[1]]);
      onRangeChange({
        startValue: value,
        endValue: sliderValues[1],
        minValue,
        maxValue,
      });
    }
  };

  const rangeEndChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const value = rawValue.length === 0 ? 0 : parseFloat(event.target.value);

    const sliderStartValue = sliderValues[0];

    if (
      isValueInRange(value) &&
      sliderStartValue <= value &&
      rawValue.length < 4
    ) {
      setEndInputValue(rawValue);
      setSliderValues([sliderValues[0], value]);
      onRangeChange({
        startValue: sliderValues[0],
        endValue: value,
        minValue,
        maxValue,
      });
    }
  };

  const handleChangeComplete = (values: number | Array<number>) => {
    if (Array.isArray(values)) {
      setSliderValues(values);

      onRangeChange({
        startValue: values[0],
        endValue: values[1],
        minValue,
        maxValue,
      });
    }
  };

  const handleChange = (values: number | Array<number>) => {
    const numberValues = values as Array<number>;
    setSliderValues(numberValues);
    setStartInputValue(String(numberValues[0]));
    setEndInputValue(String(numberValues[1]));
  };

  return (
    <div className="number-range-input">
      {renderTitleArea()}
      <div className="number-range-input-slider">
        <input
          className="number-range-input-value"
          value={startInputValue}
          type="text"
          min={minValue}
          max={maxValue}
          onChange={rangeStartChangeHandler}
          inputMode="decimal"
        />
        <Slider
          ref={sliderRef}
          className="range-slider"
          range
          min={minValue}
          max={maxValue}
          step={sliderStep}
          value={sliderValues}
          defaultValue={sliderDefaultValues}
          onChangeComplete={handleChangeComplete}
          onChange={handleChange}
          handleRender={(origin, props) => (
            <SliderHandle {...props} style={origin.props.style} />
          )}
        />
        <input
          className="number-range-input-value"
          value={endInputValue}
          type="text"
          min={minValue}
          max={maxValue}
          onChange={rangeEndChangeHandler}
          inputMode="decimal"
        />
      </div>
    </div>
  );
};

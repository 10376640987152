export enum TimeLineActorTypes {
  SYSTEM,
  USER,
  WORKFLOW,
}

export default interface FindingEvent {
  id: string;
  findingId: string;
  actorType: TimeLineActorTypes;
  createdAt: Date;
  updatedAt: Date;
  message: string;
  applicationId: string;
  appLogo: string;
  actorLogo: string;
  actorName?: string;
}

// utility
function getEnvVar(envVarName: string): string {
  const envVar = process.env[envVarName];
  if (!envVar) {
    throw new Error(`Environment variable ${envVarName} is missing.`);
  }
  return envVar;
}

/**
 * Global env var config.
 */
const commonConfig = {
  fronteggBaseUrl: getEnvVar('REACT_APP_FRONTEGG_BASE_URL'),
  fronteggClientId: getEnvVar('REACT_APP_FRONTEGG_CLIENT_ID'),
  cloudFormationReadRoleUrl: getEnvVar(
    'REACT_APP_CLOUD_FORMATION_READ_ROLE_URL',
  ),
  bffBaseUrl: getEnvVar('REACT_APP_BFF_BASE_URL'),
  globalGwBaseUrl: getEnvVar('REACT_APP_GW_BASE_URL'),
  contactUsEmail: getEnvVar('REACT_APP_CONTACT_US_EMAIL'),
  slackClientId: getEnvVar('REACT_APP_SLACK_CLIENT_ID'),
  bulkTicketingActionFindingLimit: Number(
    getEnvVar('REACT_APP_BULK_TICKETING_ACTION_FINDING_LIMIT'),
  ),
  bulkActionFindingLimit: Number(
    getEnvVar('REACT_APP_BULK_ACTION_FINDING_LIMIT'),
  ),
};

export default commonConfig;

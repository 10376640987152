import { GroupCellRendererParams } from 'ag-grid-community';
import { FunctionComponent, useMemo } from 'react';
import { CommonCheckbox } from '../CommonCheckbox/CommonCheckbox';
import {
  GridRowSelectionProps,
  GridSelectionProps,
  GridSelectionType,
} from 'Risk/store';
import useCommonSelector from 'Common/utils/use-selector';

interface CommonAdvancedDataGridRowCheckboxProps
  extends GroupCellRendererParams {}

export const CommonAdvancedDataGridRowCheckbox: FunctionComponent<
  CommonAdvancedDataGridRowCheckboxProps
> = (props) => {
  const gridSelectionProps: GridSelectionProps = useCommonSelector(
    props.context?.gridSelectionPropsSelector
  );

  const nodeId = useMemo<string>(() => {
    return props.node.id?.replace(':parent', '') || '';
  }, [props.node.id]);

  const subTableSelectionProps = useMemo<GridRowSelectionProps>(() => {
    return gridSelectionProps?.rows?.find(
      (rowItem: any) => rowItem.value === nodeId
    ) as GridRowSelectionProps;
  }, [gridSelectionProps]);

  const selectRow = () => {
    const isSelected = Boolean((props?.node as any)?.selected);
    props.node?.setSelected(!isSelected);
  };

  return (
    <CommonCheckbox
      onClick={selectRow}
      indeterminate={
        subTableSelectionProps?.selection === GridSelectionType.MANUAL ||
        subTableSelectionProps?.selection === GridSelectionType.PARTIAL
      }
      checked={gridSelectionProps.selectedChildren.includes(nodeId)}
    />
  );
};

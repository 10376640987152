import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseChartContainerProps } from 'shared/models/props/chart-props.model';

export const BaseChartContainer: FunctionComponent<BaseChartContainerProps> = ({
  legendPosition = 'top',
  children,
  height,
}) => {
  const getFlexDirectionBasedOnLegendPosition = () => {
    switch (legendPosition) {
      case 'bottom':
        return 'column';
      case 'left':
        return 'row';
      case 'right':
        return 'row-reverse';
      default:
        return 'column-reverse';
    }
  };

  return (
    <Stack
      flexDirection={getFlexDirectionBasedOnLegendPosition()}
      alignItems="center"
      className="base-chart-main-container"
      height={height}
    >
      {children}
    </Stack>
  );
};

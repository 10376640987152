import React, { ReactNode } from 'react';
import {
  Box,
  Pagination as MuiPagination,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './CommonPagination.module.css';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';

export interface PaginationProps {
  totalItems: number;
  pageSize?: number;
  pageSizeOptions?: number[];
  currentPage?: number;
  onPageChange?: (selectedPage: number) => void;
  onPageSizeChange?: (selectedPageSize: number) => void;
  pageCount?: number;
  disableChangePageSize?: boolean;
  classes?: {
    root?: string;
    results?: string;
    rowsPerPageText?: string;
    rowsPerPageSelect?: string;
    rowsPerPageSelectItem?: string;
    pages?: string;
  };
  icons?: {
    ExpandIcon?: JSX.Element;
  };
}

const Pagination = styled(MuiPagination)({
  '& .MuiPaginationItem-root': {
    borderRadius: '4px',
    color: '#748495',
    fontSize: 16,
    fontWeight: 700,
    padding: '10px',
    height: '40px',
    width: '40px',
  },
  '& .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#D2D4F7',
    color: '#0A0B50',
  },
  '& .MuiPaginationItem-previousNext': {
    backgroundColor: '#F2F7FC',
    color: '#000000',
  },
  '& .MuiPaginationItem-previousNext.Mui-disabled': {
    backgroundColor: 'initial',
    color: '#748495',
    opacity: 1,
  },
});

export default function CommonPagination({
  totalItems,
  pageSize = 10,
  pageSizeOptions = [10, 25, 50, 100, 200],
  currentPage = 1,
  onPageChange,
  onPageSizeChange,
  pageCount,
  classes,
  icons,
  disableChangePageSize = false,
}: PaginationProps) {
  const { t: translation } = useTranslation();

  if (totalItems === 0) return <Box />;

  const totalPages = pageCount || Math.ceil(totalItems / pageSize);
  const firstItemInPage = (currentPage - 1) * pageSize + 1;
  const lastItemInPageBySize = firstItemInPage + pageSize - 1;
  const lastItemInPage =
    lastItemInPageBySize > totalItems ? totalItems : lastItemInPageBySize;

  function handleChange(event: React.ChangeEvent<unknown>, value: number) {
    if (onPageChange) onPageChange(value);
  }

  const handlePageSizeChange = (event: SelectChangeEvent) => {
    if (onPageSizeChange)
      onPageSizeChange(event.target.value as unknown as number);
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes?.root || ''}
    >
      <Stack
        direction="row"
        alignItems="center"
        className={classes?.results || ''}
      >
        <Typography className={styles.text} fontWeight={700}>
          {translation('global.pagination.results', {
            param1: `${firstItemInPage}-${lastItemInPage}`,
            param2: totalItems,
          })}
        </Typography>
        <Box ml={4} />
        {!disableChangePageSize && (
          <>
            <Typography className={classes?.rowsPerPageText || styles.text}>
              <FormattedMessage
                id="global.pagination.rowsPerPage"
                defaultMessage="Rows per page"
              />
            </Typography>
            <Box ml={2} />
            <Select
              value={pageSize.toString()}
              label={`${translation(`global.pagination.pageSize`)}`}
              onChange={handlePageSizeChange}
              className={classes?.rowsPerPageSelect || styles.selectRows}
              IconComponent={ExpandMoreIcon}
            >
              {pageSizeOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  className={classes?.rowsPerPageSelectItem || ''}
                >
                  {option}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      </Stack>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        className={classes?.pages || ''}
      />
    </Stack>
  );
}

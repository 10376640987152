import { OpusAvatarIconProps } from 'shared/icons/interface';
import { IconFactory } from '../../../icons/utils';
import IconWrapper from '../IconWrapper';
import _ from 'lodash';

const iconFactory = new IconFactory();

interface OpusIconProps extends OpusAvatarIconProps {
  children?: any;
  title?: string;
}

export const OpusAvatarIcon = (props: OpusIconProps): JSX.Element => {
  const { title } = props;

  return (
    <IconWrapper title={title}>
      {iconFactory.getAvatarIcon(_.omit(props, ['title']))}
    </IconWrapper>
  );
};

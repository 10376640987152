import { Box, Typography } from '@mui/material';
import { useGetCampaignListMutation } from 'Campaigns/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import ActiveCampaignCarouselList from '../ActiveCampaignCarouselList';
import {
  CampaignModel,
  CampaignStatus,
} from 'shared/models/data/campaigns.model';

interface ActiveCampaignDashboardSectionProps extends BaseComponentProps {}

export const ActiveCampaignDashboardSection: FunctionComponent<
  ActiveCampaignDashboardSectionProps
> = () => {
  const [
    getCampaignList,
    { isLoading: campaignListLoading, data: campaignList },
  ] = useGetCampaignListMutation();

  const activeCampaigns = useMemo<Array<CampaignModel>>(() => {
    return (
      campaignList?.filter(
        (campaign) => campaign.status === CampaignStatus.ACTIVE
      ) || []
    );
  }, [campaignList]);

  useEffect(() => {
    getCampaignList('');
  }, []);

  return (
    <div className="active-campaign-dashboard-section-container">
      <div className="active-campaign-dashboard-section-header">
        <Box display={'flex'} gap={1}>
          <OpusSvgIcon type={SVG_ICON_TYPES.BULLHORN_ICON} />
          <Typography className="header-6" variant="inherit">
            Active Campaigns
            {activeCampaigns?.length ? ` (${activeCampaigns?.length})` : ''}
          </Typography>
        </Box>
      </div>
      <div className="active-campaign-dashboard-section-body">
        <ActiveCampaignCarouselList
          isLoading={campaignListLoading}
          activeCampaigns={activeCampaigns || []}
        />
      </div>
    </div>
  );
};

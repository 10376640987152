import { FunctionComponent } from 'react';
import { AuthorizedContent } from '@frontegg/react';
import PageContainer from 'shared/components/PageContainer';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import Bump from '../Common/components/Bump';
import RiskAdvancedDataGrid from './components/RiskAdvancedDataGrid';
import { ApplicationPermission } from '../shared/enums/permission.enum';

interface RiskPageProps extends BaseComponentProps {}

export const RiskPage: FunctionComponent<RiskPageProps> = () => {
  return (
    <PageContainer
      title="Risk Views"
      sideElement={
        <AuthorizedContent
          requiredPermissions={[ApplicationPermission.SETTINGS_SYSTEM_ACTIONS]}
        >
          <Bump />
        </AuthorizedContent>
      }
    >
      <RiskAdvancedDataGrid />
    </PageContainer>
  );
};

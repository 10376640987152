import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { FunctionComponent } from 'react';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface BaseIntegrationModalContainerProps extends BaseComponentProps {
  open: boolean;
  onClose: () => void;
  integrationItem: IntegrationItem;
  title?: string;
}

export const BaseIntegrationModalContainer: FunctionComponent<
  BaseIntegrationModalContainerProps
> = ({ open, onClose, integrationItem, children, title: customTitle }) => {
  const { id: imageAlt, name: title, logo: imageSource } = integrationItem;

  return (
    <CommonModalContainer
      isOpen={open}
      handleClose={onClose}
      title={customTitle || title}
      components={{
        TitleIcon: (
          <img
            className="integration-modal-icon"
            src={imageSource}
            alt={imageAlt}
          />
        ),
      }}
      rootClassName="integration-modal-container"
    >
      {children}
    </CommonModalContainer>
  );
};

import { useAuthUser } from '@frontegg/react';
import { useSearchApplicationsMutation } from 'Application/store/api';
import { useFetchConnectionByIdMutation } from 'WorkflowBuilder/store/api';
import { SideMenuNavigation } from 'Integrations/Common/SideMenuNavigation/SideMenuNavigation';
import { CommonIntegrationModalButtonProps } from 'Integrations/Connection/components/IntegrationModal/IntegrationModal';
import { OnboardingStatus } from 'Onboarding/models/onboarding-tenant-details.model';
import { useUpdateOnBoardingTenantDetailsMutation } from 'Onboarding/store/api';
import { CloudProviderType } from 'Settings/interfaces/CloudProviderType.enum';
import { EnvironmentType } from 'Settings/interfaces/EnvironmentType.enum';
import {
  useCreateBusinessUnitMutation,
  useFetchCloudWorkspacesMutation,
} from 'Settings/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CommonButton } from 'shared/components/CommonButton';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import {
  defaultOnboardingBusinessUnit,
  onboardingDefaultSteps,
  onboardingInitialState,
  onboardingMenuList,
} from 'shared/fixtures/data/onboarding.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OnboardingState,
  OnboardingStep,
  OnboardingStepAction,
  OnboardingStepId,
  OnboardingStepStatus,
} from 'shared/models/data/onboarding.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useSearchConnectionTemplatesMutation } from 'ConnectionTemplate/store/api';
import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import { CloudWorkspace } from 'Settings/interfaces/CloudWorkspace';
import { CloudWorkspaceType } from 'Settings/interfaces/CloudWorkspaceType.enum';
import { ReactComponent as OnboardingStartingScreen } from '../../../shared/images/onboarding-starting-screen.svg';
import { useConnectionCreateMutation } from 'Integrations/Connection/store/api';
import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';
import { ConnectionItem } from 'Integrations/interfaces/ConnectionItem.model';

interface OnboardingContentProps extends BaseComponentProps {
  closeModal: () => void;
}

export const OnboardingContent: FunctionComponent<OnboardingContentProps> = ({
  closeModal,
}) => {
  const navigate = useNavigate();
  const authUser = useAuthUser();

  const [shouldNavigateForward, setShouldNavigateForward] =
    useState<boolean>(false);

  const [onboardingState, setOnboardingState] = useState<OnboardingState>(
    onboardingInitialState
  );

  const [onboardingSteps, setOnboardingSteps] = useState<Array<OnboardingStep>>(
    onboardingDefaultSteps
  );

  const [
    searchConnectionTemplates,
    { data: connectionTemplateData, isLoading: connectionTemplateDataLoading },
  ] = useSearchConnectionTemplatesMutation();

  const [
    updateTenantDetails,
    { isLoading: tenantDetailsLoading, isSuccess: tenantDetailsSuccess },
  ] = useUpdateOnBoardingTenantDetailsMutation();

  const [
    createBusinessUnit,
    {
      isLoading: createBusinessUnitLoading,
      isSuccess: createBusinessUnitSuccess,
    },
  ] = useCreateBusinessUnitMutation();

  const [
    fetchCloudWorkspaces,
    { isLoading: fetchCloudWorkspacesLoading, data: cloudWorkspaces },
  ] = useFetchCloudWorkspacesMutation();

  const [
    createConnection,
    { isLoading: createConnectionLoading, data: createConnectionData },
  ] = useConnectionCreateMutation();

  const finalizingOnboarding = useMemo(() => {
    return (
      tenantDetailsLoading ||
      createBusinessUnitLoading ||
      fetchCloudWorkspacesLoading
    );
  }, [
    tenantDetailsLoading,
    createBusinessUnitLoading,
    fetchCloudWorkspacesLoading,
  ]);

  const awsCloudAccount = useMemo(() => {
    return cloudWorkspaces?.find(
      (cloudWorkspace) => cloudWorkspace.cloudProvider === CloudProviderType.AWS
    );
  }, [cloudWorkspaces]);

  const codeRepos = useMemo(() => {
    if (cloudWorkspaces) {
      const filteredCloudWorkspaces = cloudWorkspaces?.filter(
        (cloudWorkspace: CloudWorkspace) =>
          cloudWorkspace.type === CloudWorkspaceType.CODE_MANAGEMENT
      );

      return filteredCloudWorkspaces;
    }

    return [];
  }, [cloudWorkspaces]);

  const activeStep = useMemo(() => {
    if (onboardingState.activeStepId) {
      return onboardingSteps.find(
        (step: OnboardingStep) => step.id === onboardingState.activeStepId
      );
    }

    return null;
  }, [onboardingState.activeStepId]);

  const activeStepDescription = useMemo(() => {
    if (activeStep?.applicationId === ApplicationTypes.AWS) {
      return '';
    }

    return connectionTemplateData?.length
      ? connectionTemplateData?.find(
          (connectionTemplate: ConnectionTemplateItem) =>
            connectionTemplate.applicationId === activeStep?.applicationId
        )?.description
      : '';
  }, [activeStep, connectionTemplateData]);

  const securityHubConnectionTemplate = useMemo<ConnectionTemplateItem>(() => {
    return connectionTemplateData?.length
      ? connectionTemplateData?.find(
          (connectionTemplate: ConnectionTemplateItem) =>
            connectionTemplate.applicationId === ApplicationTypes.SECURITY_HUB
        )
      : undefined;
  }, [connectionTemplateData]);

  const { t: translation } = useTranslation();

  const [searchApplications, { data: applicationData }] =
    useSearchApplicationsMutation();

  useEffect(() => {
    searchConnectionTemplates({});
  }, []);

  useEffect(() => {
    searchApplications({
      id: 'Opus',
    });
  }, []);

  useEffect(() => {
    if (createConnectionData) {
      setOnboardingState((prevOnboardingState) => ({
        ...prevOnboardingState,
        connectionIds: {
          ...prevOnboardingState.connectionIds,
          [OnboardingStepId.SECURITY_HUB]: createConnectionData.id,
        },
      }));

      setShouldNavigateForward(true);
    }
  }, [createConnectionData]);

  useEffect(() => {
    if (shouldNavigateForward) {
      stepNavigationForwardHandler();
      setShouldNavigateForward(false);
    }
  }, [shouldNavigateForward]);

  useEffect(() => {
    if (awsCloudAccount) {
      createBusinessUnit({
        ...defaultOnboardingBusinessUnit,
        environments: [
          {
            workspaceId: awsCloudAccount?.id,
            type: EnvironmentType.PROD,
            alias: awsCloudAccount.alias,
          },
        ],
        associatedRepos: codeRepos?.map((codeRepo) => codeRepo.id) || [],
        unitOwner: authUser.email,
      });
    }
  }, [awsCloudAccount]);

  useEffect(() => {
    if (createBusinessUnitSuccess) {
      updateTenantDetails({
        metadata: {
          onboardingStatus: OnboardingStatus.Finished,
        },
      });
    }
  }, [createBusinessUnitSuccess]);

  useEffect(() => {
    if (tenantDetailsSuccess) {
      navigate('/settings?activeTabId=business-units');
      closeModal();
    }
  }, [tenantDetailsSuccess]);

  const opusApplicationLogoUrl = useMemo<string>(() => {
    return applicationData?.length ? applicationData[0]?.logo : '';
  }, [applicationData]);

  const defaultSecurityHubCreateHandler = () => {
    createConnection({
      name: securityHubConnectionTemplate?.name,
      businessUnitIds: [],
      cloudWorkspaceIds: [],
      applicationId: ApplicationTypes.SECURITY_HUB,
      connectionTemplateId: securityHubConnectionTemplate?.id,
      definition: securityHubConnectionTemplate?.definition,
      isFallback: true,
      parameters: [],
      metadata: {},
    });
  };

  const stepConnectionIdHandler = (
    connectionId: string,
    stepId: OnboardingStepId
  ) => {
    if (connectionId) {
      if (
        stepId === OnboardingStepId.AWS &&
        onboardingState.connectionIds[OnboardingStepId.SECURITY_HUB] ===
          undefined
      ) {
        setOnboardingState((prevOnboardingState) => ({
          ...prevOnboardingState,
          connectionIds: {
            ...prevOnboardingState.connectionIds,
            [stepId]: connectionId,
          },
        }));

        defaultSecurityHubCreateHandler();

        return;
      }

      if (onboardingState.connectionIds[stepId] === undefined) {
        if (activeStep?.shouldNavigateAfterCreate) {
          setShouldNavigateForward(true);
        }
      } else {
        setShouldNavigateForward(true);
      }

      setOnboardingState((prevOnboardingState) => ({
        ...prevOnboardingState,
        connectionIds: {
          ...prevOnboardingState.connectionIds,
          [stepId]: connectionId,
        },
      }));
    }
  };

  const stepNavigationForwardHandler = () => {
    if (activeStep) {
      if (activeStep?.nextStepId) {
        setOnboardingState((prevOnboardingState) => ({
          ...prevOnboardingState,
          activeStepId: activeStep.nextStepId,
        }));
        setOnboardingSteps((prevOnboardingSteps) => {
          return prevOnboardingSteps.map((step: OnboardingStep) => {
            if (step.id === activeStep.nextStepId) {
              return {
                ...step,
                status: OnboardingStepStatus.CREATING,
              };
            }

            if (step.id === activeStep.id) {
              return {
                ...step,
                status: OnboardingStepStatus.CONNECTED,
              };
            }

            return step;
          });
        });
      } else {
        setOnboardingState((prevOnboardingState) => ({
          ...prevOnboardingState,
          activeStepId: null,
          isComplete: true,
        }));

        setOnboardingSteps((prevOnboardingSteps) => {
          return prevOnboardingSteps.map((step: OnboardingStep) => {
            return {
              ...step,
              status: OnboardingStepStatus.CONNECTED,
            };
          });
        });
      }
    } else {
      setOnboardingState((prevOnboardingState) => ({
        ...prevOnboardingState,
        activeStepId: OnboardingStepId.AWS,
      }));

      setOnboardingSteps((prevOnboardingSteps) => {
        return prevOnboardingSteps.map((step: OnboardingStep) => {
          if (step.id === OnboardingStepId.AWS) {
            return {
              ...step,
              status: OnboardingStepStatus.CREATING,
            };
          }

          return step;
        });
      });
    }
  };

  const stepNavigationBackwarddHandler = () => {
    if (activeStep) {
      if (activeStep?.previousStepId) {
        setOnboardingState((prevOnboardingState) => ({
          ...prevOnboardingState,
          activeStepId: activeStep.previousStepId,
        }));
        setOnboardingSteps((prevOnboardingSteps) => {
          return prevOnboardingSteps.map((step: OnboardingStep) => {
            if (step.id === activeStep.previousStepId) {
              return {
                ...step,
                status: OnboardingStepStatus.UPDATING,
              };
            }

            if (step.id === activeStep.id) {
              return {
                ...step,
                status: OnboardingStepStatus.EMPTY,
              };
            }

            return step;
          });
        });
      } else {
        setOnboardingState((prevOnboardingState) => ({
          ...prevOnboardingState,
          activeStepId: null,
        }));

        setOnboardingSteps((prevOnboardingSteps) => {
          return prevOnboardingSteps.map((step: OnboardingStep) => {
            return {
              ...step,
              status: OnboardingStepStatus.EMPTY,
            };
          });
        });
      }
    }
  };

  const stepSkipHandler = () => {
    setOnboardingState((prevOnboardingState) => ({
      ...prevOnboardingState,
      activeStepId: null,
      isComplete: true,
    }));
  };

  const preFinalizeOnboardingHandler = () => {
    fetchCloudWorkspaces();
  };

  const renderOnboardingSideMenuItemStatusIndicator = (
    status: OnboardingStepStatus
  ) => {
    if (status === OnboardingStepStatus.CONNECTED) {
      return <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />;
    }

    return <></>;
  };

  const renderOnboardingContentButtons = (
    props: CommonIntegrationModalButtonProps
  ) => {
    if (activeStep?.id === OnboardingStepId.GITHUB) {
      return (
        <div className="onboarding-content-buttons">
          <div className="onboarding-content-back-button">
            <CommonButton
              type={CommonButtonType.SECONDARY}
              onClick={stepNavigationBackwarddHandler}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
              Back
            </CommonButton>
          </div>
          <div className="onboarding-content-action-buttons">
            <CommonButton
              type={CommonButtonType.SECONDARY}
              onClick={stepSkipHandler}
            >
              Skip
            </CommonButton>
            <CommonButton
              type={CommonButtonType.PRIMARY}
              onClick={
                props.authorizeHandler
                  ? props.authorizeHandler
                  : props.connectHandler
              }
            >
              {props.authorizing || props.connecting
                ? 'Authorizing'
                : 'Authorize'}
            </CommonButton>
          </div>
        </div>
      );
    }

    return (
      <div className="onboarding-content-buttons">
        <div className="onboarding-content-back-button">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={stepNavigationBackwarddHandler}
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
            Back
          </CommonButton>
        </div>
        <div className="onboarding-content-action-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={props.verifyHandler && props.verifyHandler}
          >
            {props.verifying ? 'Verifying' : 'Verify'} Connectivity
          </CommonButton>
          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={props.connectHandler}
          >
            {props.connecting || createConnectionLoading
              ? 'Connecting'
              : 'Connect'}
          </CommonButton>
        </div>
      </div>
    );
  };

  const renderOnboardingContentHeader = (step: OnboardingStep) => {
    return (
      <div className="onboarding-content-header-area">
        <div className="onboarding-content-header-area-title">
          <div className="onboarding-content-header-area-title-icon">
            <img alt={step.id} src={step.integrationItem.logo} />
          </div>
          <div className="onboarding-content-header-area-title-text">
            {step.name}
          </div>
        </div>
        <div className="onboarding-content-header-area-description">
          {activeStepDescription}
        </div>
      </div>
    );
  };

  const renderOnboardingContent = () => {
    if (onboardingState.isComplete) {
      return (
        <div className="onboarding-content-area onboarding-content-initial-screen">
          <div className="onboarding-content-body onboarding-content-initial-screen-body">
            <div className="onboarding-content-initial-screen-title">
              <div className="onboarding-content-initial-screen-title-text">
                {translation(`onboarding.beforeYouStart`)}
              </div>
            </div>
            <div className="onboarding-content-initial-screen-description">
              {translation(`onboarding.beforeYouStartDescription`)}
            </div>
          </div>

          <div className="onboarding-content-initial-screen-buttons">
            <CommonButton
              type={CommonButtonType.PRIMARY}
              onClick={preFinalizeOnboardingHandler}
            >
              {finalizingOnboarding ? 'Finishing' : 'Finish'}
            </CommonButton>
          </div>
        </div>
      );
    }

    if (activeStep) {
      return (
        <div className="onboarding-content-area">
          <div className="onboarding-content-body">
            {renderOnboardingContentHeader(activeStep)}

            {activeStep.renderContent({
              integrationItem: activeStep.integrationItem,
              onCancel: () => {},
              open: true,
              renderButtonRow: renderOnboardingContentButtons,
              onCreateSuccess: (connectionId: string) => {
                stepConnectionIdHandler(connectionId, activeStep.id);
              },
              connectionId: onboardingState.connectionIds[activeStep.id],
            })}
          </div>
        </div>
      );
    }

    return (
      <div className="onboarding-content-area onboarding-content-initial-screen">
        <div className="onboarding-content-body onboarding-content-initial-screen-body">
          <div className="onboarding-content-initial-screen-title">
            <div className="onboarding-content-initial-screen-title-icon">
              <img alt="opus-logo" src={opusApplicationLogoUrl} />
            </div>
            <div className="onboarding-content-initial-screen-title-text">
              {translation(`onboarding.title`)}
            </div>
          </div>
          <div className="onboarding-content-initial-screen-description">
            {translation(`onboarding.description`)}
          </div>

          <div className="onboarding-content-initial-screen-image">
            <OnboardingStartingScreen />
          </div>
        </div>

        <div className="onboarding-content-initial-screen-buttons">
          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={stepNavigationForwardHandler}
          >
            Start
          </CommonButton>
        </div>
      </div>
    );
  };

  const renderOnboardingSideMenu = () => {
    return onboardingSteps.map((onboardingStep: OnboardingStep) => (
      <div
        className={`onboarding-sidebar-menu-item ${
          onboardingStep.status === OnboardingStepStatus.EMPTY
            ? 'onboarding-sidebar-menu-item-disabled'
            : ''
        } ${
          onboardingStep.id === onboardingState.activeStepId
            ? 'onboarding-sidebar-menu-item-active'
            : ''
        } `}
      >
        <div className="onboarding-sidebar-menu-item-icon">
          <img
            alt={onboardingStep.id}
            src={onboardingStep.integrationItem.logo}
          />
        </div>
        <div className="onboarding-sidebar-menu-item-title">
          <span> {onboardingStep.name}</span>
          {onboardingStep.required === false ? (
            <span className="onboarding-sidebar-menu-item-optional-text">
              (Optional)
            </span>
          ) : (
            <></>
          )}
        </div>

        <div className="onboarding-sidebar-menu-item-status-indicator">
          {renderOnboardingSideMenuItemStatusIndicator(onboardingStep.status)}
        </div>
      </div>
    ));
  };

  return (
    <div className="onboarding-content-container">
      <div className="onboarding-sidebar">{renderOnboardingSideMenu()}</div>
      <div className="onboarding-main-area">{renderOnboardingContent()}</div>
    </div>
  );
};

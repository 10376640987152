import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { CommonButton } from 'shared/components/CommonButton';
import { IntegrationModalAccordion } from '../IntegrationModalAccordion';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  azureIntegrationFormDefaultSchema,
  azureIntegrationFormRequiredFields,
  azureIntegrationInputKeysToInputLabels,
  azureIntegrationLinks,
} from 'shared/fixtures/data/integrations.data';
import {
  useUpdateCloudConnectionMutation,
  useVerifyCloudConnectionMutation,
} from 'Integrations/Connection/store/api';
import {
  CloudProviderType,
  IntegrationActionType,
} from 'shared/models/data/integrations.model';
import { AlertBox, AlertBoxType } from 'shared/components/AlertBox/AlertBox';
import { CircularProgress } from '@mui/material';
import commonConfig from 'Config';
import { useFetchConnectionByIdMutation } from 'WorkflowBuilder/store/api';
import { IntegrationsDataHandler } from 'shared/handlers/integrations-data.handler';
import { AlertExpandBox } from 'shared/components/AlertExpandBox/AlertExpandBox';
import { useTranslation } from 'react-i18next';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import Autocomplete from 'Common/components/Autocomplete';
import { businessUnitEnvironmentTypesOptionList } from 'shared/fixtures/data/business-unit.data';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { OrganizationNodeDataHandler as OrganizationNodeOptionDataHandler } from 'shared/handlers/organization-node-data.handler';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';
import AzureIntegrationSubscriptionSection from '../AzureIntegrationSubscriptionSection';

interface AzureIntegrationModalEditFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {
  template?: ConnectionTemplateItem;
}

const integrationsDataHandler = new IntegrationsDataHandler();

const organizationNodeOptionDataHandler =
  new OrganizationNodeOptionDataHandler();

export const AzureIntegrationModalEditForm: FunctionComponent<
  AzureIntegrationModalEditFormProps
> = ({ onCancel, connectionId, template }) => {
  const { t: translation } = useTranslation();

  const {
    setValue: setFormValue,
    clearErrors: clearInputErrors,
    getValues: getFormValues,
    setError: setFormError,
    formState: { errors: formErrors },
    watch: watchFormValue,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape(azureIntegrationFormDefaultSchema)
    ),
    defaultValues: {
      id: '',
      name: '',
      environmentType: '',
      azureSubscriptionId: '',
      azureTenantId: '',
      opusAppObjectId: '',
      writeApplicationIds: [] as Array<string>,
      scopeId: '',
    },
  });

  const azureTenantId = watchFormValue('azureTenantId');

  const writeApplicationIds = watchFormValue('writeApplicationIds');

  const [
    updateCloudConnection,
    {
      isLoading: updateCloudConnectionLoading,
      error: updateCloudConnectionError,
      reset: updateCloudConnectionReset,
      isSuccess: updateCloudConnectionSuccess,
    },
  ] = useUpdateCloudConnectionMutation();

  const [
    verifyConnection,
    {
      isLoading: verifyConnectionLoading,
      error: verifyConnectionError,
      data: verifyConnectionPayload,
      isSuccess: verifyConnectionSuccess,
      reset: verifyConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  const [
    verifySubConnection,
    {
      isLoading: verifySubConnectionLoading,
      error: verifySubConnectionError,
      data: verifySubConnectionPayload,
      isSuccess: verifySubConnectionSuccess,
      reset: verifySubConnectionReset,
    },
  ] = useVerifyCloudConnectionMutation();

  const [
    fetchConnectionById,
    { isLoading: fetchConnectionByIdLoading, data: connectionData },
  ] = useFetchConnectionByIdMutation();

  const [fetchScopeList, { data: scopeData, isLoading: scopeDataLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);

  const scopeAutocompleteList: Array<AutocompleteOption> = useMemo(() => {
    return scopeData
      ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeData,
          true
        )
      : [];
  }, [scopeData]);

  const cloudProviderData = useMemo(() => {
    if (connectionData) {
      return {
        ...(connectionData?.metadata?.cloudProvider?.azure || {}),
        name: connectionData?.name,
        scopeId: connectionData?.scopeId,
        environmentType:
          connectionData?.metadata?.cloudProvider?.environmentType,
      };
    }

    return undefined;
  }, [connectionData]);

  useEffect(() => {
    if (updateCloudConnectionSuccess) {
      onCancel();
    }
  }, [updateCloudConnectionSuccess]);

  useEffect(() => {
    if (connectionId) {
      fetchConnectionById(connectionId);
    }
  }, [connectionId]);

  useEffect(() => {
    if (cloudProviderData) populateDefaultFormValues(cloudProviderData);
  }, [cloudProviderData]);

  const inputChangeHandler = (
    inputName: keyof typeof azureIntegrationFormDefaultSchema,
    inputValue: string
  ) => {
    if (inputValue.length) {
      clearInputErrors(inputName);
    }

    setFormValue(inputName, inputValue?.trim());
  };

  const validateFormBeforeSubmit = () => {
    let valid = true;

    const formValues = getFormValues();

    for (const requiredInputKey of azureIntegrationFormRequiredFields) {
      const typedInputKey =
        requiredInputKey as keyof typeof azureIntegrationFormDefaultSchema;
      if (formValues[typedInputKey]?.length === 0) {
        valid = false;
        setFormError(typedInputKey, {
          type: 'required',
          message: `${azureIntegrationInputKeysToInputLabels[typedInputKey]} is required`,
        });
      }
    }

    return valid;
  };

  const populateDefaultFormValues = (
    data: Record<
      keyof typeof azureIntegrationFormDefaultSchema,
      string | Array<string>
    >
  ) => {
    for (const dataKey in data) {
      setFormValue(
        dataKey as keyof typeof azureIntegrationFormDefaultSchema,
        data[dataKey as keyof typeof azureIntegrationFormDefaultSchema]
      );
    }
  };

  const connectSubApplicationHandler = (applicationId: string) => {
    const subApplicationIds: Array<string> =
      getFormValues().writeApplicationIds || [];

    if (subApplicationIds.includes(applicationId)) {
      setFormValue(
        'writeApplicationIds',
        subApplicationIds.filter((id) => id !== applicationId)
      );
    } else {
      setFormValue('writeApplicationIds', [
        ...subApplicationIds,
        applicationId,
      ]);
    }
  };

  const connectHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      updateCloudConnection({
        ...formValues,
        id: connectionId,
        scopeId:
          formValues.scopeId && formValues.scopeId !== 'None'
            ? formValues.scopeId
            : null,
        cloudProvider: CloudProviderType.AZURE,
      });
    }
  };

  const verifyHandler = () => {
    resetApiState();
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const formValues = getFormValues();

      verifyConnection({
        cloudProvider: CloudProviderType.AZURE,
        body: {
          ...formValues,
          scopeId:
            formValues.scopeId && formValues.scopeId !== 'None'
              ? formValues.scopeId
              : null,
          azureAppId: CloudProviderType.AZURE,
        },
      });
    }
  };

  const verifySubApplicationHandler = (applicationId: string) => {
    resetApiState();

    const formValues = getFormValues();

    verifySubConnection({
      cloudProvider: CloudProviderType.AZURE,
      body: {
        ...formValues,
        azureAppId: applicationId,
      },
    });
  };

  const resetApiState = () => {
    updateCloudConnectionReset();
    verifyConnectionReset();
    verifySubConnectionReset();
  };

  const renderAlertBox = () => {
    if (updateCloudConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.UPDATING,
            'Azure'
          )}
          description={
            (updateCloudConnectionError as any)?.data?.message ||
            (updateCloudConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'Azure'
          )}
          description={
            (verifyConnectionError as any)?.data?.message ||
            (verifyConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifySubConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'Azure'
          )}
          description={
            (verifySubConnectionError as any)?.data?.message ||
            (verifySubConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload?.success === false) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'Azure'
          )}
          description={
            verifyConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (
      verifySubConnectionPayload &&
      verifySubConnectionPayload?.success === false
    ) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            'Azure'
          )}
          description={
            verifySubConnectionPayload?.errorMsg ||
            'Connection failed to be verified'
          }
        />
      );
    }

    if (verifyConnectionPayload && verifyConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    if (verifySubConnectionPayload && verifySubConnectionPayload.success) {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    return <></>;
  };

  const renderConnectButtonRow = () => {
    return (
      <div className="integration-modal-button-row">
        <div className="integration-modal-cancel-buttons">
          <CommonButton type={CommonButtonType.SECONDARY} onClick={onCancel}>
            Cancel
          </CommonButton>
        </div>
        <div className="integration-modal-connection-buttons">
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={verifyHandler}
          >
            {verifyConnectionLoading
              ? 'Verifying Connectivity'
              : 'Verify Connectivity'}
          </CommonButton>
          <CommonButton
            type={CommonButtonType.PRIMARY}
            onClick={connectHandler}
          >
            {updateCloudConnectionLoading ? 'Updating' : 'Update'}
          </CommonButton>
        </div>
      </div>
    );
  };

  const getScopeLabel = (scopeId: string) => {
    return organizationNodeOptionDataHandler.getScopeLabel(
      scopeId,
      scopeAutocompleteList
    );
  };

  const selectedScopeOption = useMemo<AutocompleteOption | undefined>(() => {
    return integrationsDataHandler.getSelectedScopeOption(
      scopeAutocompleteList,
      getFormValues().scopeId
    );
  }, [scopeAutocompleteList, getFormValues().scopeId]);

  const renderForm = () => {
    if (fetchConnectionByIdLoading) {
      return <CircularProgress size={24} />;
    }

    return (
      <div className="cloud-integration-modal-form-area">
        <form className="cloud-integration-modal-form">
          <FormInput
            label="Workspace Name"
            name="name"
            placeholder="Please type workspace name"
            value={getFormValues().name}
            onChange={(value: string) => {
              inputChangeHandler('name', value);
            }}
            errorMessage={formErrors?.name?.message}
          />
          <InputLabelWrapper label="Scope">
            {scopeAutocompleteList === null || scopeDataLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Autocomplete
                model="scopeId"
                optionList={scopeAutocompleteList}
                values={selectedScopeOption}
                initialSelectedValues={selectedScopeOption}
                onChangeCallBack={(
                  model: string,
                  option: AutocompleteOption | Array<AutocompleteOption>
                ) => {
                  inputChangeHandler(
                    'scopeId',
                    (option as AutocompleteOption).value
                  );
                }}
                loading={scopeDataLoading}
                loadingText="Loading"
                classes={{
                  root: 'multi-select-field-1',
                  paper: 'multi-select-field-paper-1',
                }}
                single
                areOptionsLoaded
              />
            )}
          </InputLabelWrapper>
          <InputLabelWrapper
            label="Workspace Environment Type"
            errorMessage={formErrors?.environmentType?.message}
          >
            <Autocomplete
              model="workspaceEnvironmentType"
              optionList={businessUnitEnvironmentTypesOptionList}
              values={
                getFormValues().environmentType
                  ? {
                      value: getFormValues().environmentType,
                      label: getFormValues().environmentType,
                    }
                  : undefined
              }
              onChangeCallBack={(
                model: string,
                option: AutocompleteOption | Array<AutocompleteOption>
              ) => {
                inputChangeHandler(
                  'environmentType',
                  (option as AutocompleteOption).value
                );
              }}
              classes={{
                root: 'multi-select-field-1',
                paper: 'multi-select-field-paper-1',
                inputRoot: formErrors.environmentType
                  ? 'input-validation-error-1'
                  : '',
              }}
              single
            />
          </InputLabelWrapper>

          <FormInput
            label=""
            name="azureTenantId"
            placeholder="Tenant ID"
            value={getFormValues().azureTenantId}
            onChange={(value: string) => {
              inputChangeHandler('azureTenantId', value);
            }}
            errorMessage={formErrors?.azureTenantId?.message}
            components={{
              TitleRow: (
                <div className="cloud-integration-input-title">
                  <span>1.</span>
                  <a
                    href={azureIntegrationLinks.azureTenantId()}
                    target="_blank"
                  >
                    Click here
                  </a>
                  <span>to open Your Azure AD and Copy your Tenant ID</span>
                </div>
              ),
            }}
          />
          <div className="cloud-integration-input-title">
            <span>2.</span>
            <a
              href={azureIntegrationLinks.connectOpusApp(
                azureTenantId,
                commonConfig.globalGwBaseUrl
              )}
              target="_blank"
            >
              Click here
            </a>
            <span>to connect Opus application to your Azure environment</span>
          </div>
          <FormInput
            label=""
            name="opusAppObjectId"
            placeholder="Object ID"
            value={getFormValues().opusAppObjectId}
            onChange={(value: string) => {
              inputChangeHandler('opusAppObjectId', value);
            }}
            errorMessage={formErrors?.opusAppObjectId?.message}
            components={{
              TitleRow: (
                <div className="cloud-integration-input-title">
                  <span>3.</span>
                  <a
                    href={azureIntegrationLinks.opusAppObjectId()}
                    target="_blank"
                  >
                    Click here
                  </a>
                  <span>to get your Opus application object ID</span>
                </div>
              ),
            }}
          />

          <FormInput
            label="Fill in the object ID in the arm template and take out your subscription ID"
            name="azureSubscriptionId"
            placeholder="Subscription ID"
            value={getFormValues().azureSubscriptionId}
            onChange={(value: string) => {
              inputChangeHandler('azureSubscriptionId', value);
            }}
            errorMessage={formErrors?.azureSubscriptionId?.message}
            components={{
              TitleRow: (
                <div className="cloud-integration-input-title">
                  <span>4.</span>
                  <a
                    href={azureIntegrationLinks.azureSubscriptionId()}
                    target="_blank"
                  >
                    Click here
                  </a>
                  <span>to connect to your Azure subscription</span>
                </div>
              ),
            }}
          />
        </form>

        <div className="cloud-integration-modal-accordion-area">
          <IntegrationModalAccordion
            parentApplicationId={CloudProviderType.AZURE}
            title="Write Access"
            selectedApplicationIds={writeApplicationIds}
            subApplicationProps={{
              onConnect: connectSubApplicationHandler,
              onVerify: verifySubApplicationHandler,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {renderAlertBox()}
      <div className="cloud-integration-modal-form-wrapper">
        {renderForm()}

        <div className="cloud-integration-modal-button-area">
          {renderConnectButtonRow()}
        </div>
      </div>
    </>
  );
};

import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import FindingsTagWithIcon, {
  FindingsTagWithIconProps,
} from 'FindingDetails/components/FindingsTagWithIcon/FindingsTagWithIcon';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export interface FindingContentIntelligenceReferencesSectionProps
  extends BaseComponentProps {
  titleProps: FindingsTagWithIconProps;
  links: Array<string>;
}

export const FindingContentIntelligenceReferencesSection: FunctionComponent<
  FindingContentIntelligenceReferencesSectionProps
> = ({ titleProps, links }) => {
  const renderBody = () => {
    return links.length ? (
      links?.map((link) => {
        if (!link) return <></>;

        return (
          <a href={link} title={link} target="_blank">
            {link}
          </a>
        );
      })
    ) : (
      <NoDataToDisplayCard displayDescription={false} />
    );
  };
  return (
    <div className="finding-content-intelligence-references-section">
      <div className="finding-content-intelligence-references-section-title">
        <FindingsTagWithIcon {...titleProps} />
      </div>
      <div className="finding-content-intelligence-references-section-body">
        {renderBody()}
      </div>
    </div>
  );
};

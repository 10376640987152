import { FunctionComponent, useMemo } from 'react';
import {
  CommonSimpleDataGrid,
  CommonSimpleDataGridProps,
} from '../CommonSimpleDataGrid/CommonSimpleDataGrid';
import {
  RowClickedEvent,
  IsFullWidthRowParams,
  RowHeightParams,
  IRowNode,
} from 'ag-grid-community';
import { CommonAdvancedDataGridRow } from '../CommonAdvancedDataGridRow/CommonAdvancedDataGridRow';
import { GridSelectionProps } from 'Risk/store';

interface CommonAdvancedDataGridProps extends CommonSimpleDataGridProps {
  nestedTableProps: CommonSimpleDataGridProps & {
    dataMutation?: any;
    populateSelectionValues?: (
      parentId: string,
      selectionValues: Array<string>,
      items: Array<any>,
      selectionProps: GridSelectionProps
    ) => void;
    filterParams: any;
    additionalMutationParameters?: any;
    onRowClick?: (props: any, event: any) => void;
    onHandleChildSelectionChange?: (
      selection: string,
      parentId: string,
      selectionProps: GridSelectionProps
    ) => void;
    gridSelectionPropsSelector?: any;
    gridDuplicationState?: any;
  };
  gridRef?: any;
  rowIdProperty?: string;
}

export const CommonAdvancedDataGrid: FunctionComponent<
  CommonAdvancedDataGridProps
> = ({ nestedTableProps, rowIdProperty = 'id', ...otherProps }) => {
  const selections = useMemo<Array<string>>(() => {
    if (!otherProps.selectionModel?.length) {
      return [];
    }

    return otherProps.selectionModel.map((selection) => {
      if (selection.includes(':nested')) return selection;

      return `${selection}:parent`;
    });
  }, [otherProps.selectionModel]);

  const rowData = useMemo(() => {
    if (otherProps.rowData?.length) {
      return otherProps.rowData
        ?.map((rowItem) => [
          {
            ...rowItem,
            [rowIdProperty]: `${
              otherProps.getRowId
                ? otherProps.getRowId({
                    data: rowItem,
                  } as any)
                : ''
            }:parent`,
            originalId: `${
              otherProps.getRowId
                ? otherProps.getRowId({
                    data: rowItem,
                  } as any)
                : ''
            }`,
          },
          {
            ...rowItem,
            [rowIdProperty]: `${
              otherProps.getRowId
                ? otherProps.getRowId({
                    data: rowItem,
                  } as any)
                : ''
            }:nested`,
          },
        ])
        .flat();
    }

    return otherProps.rowData;
  }, [otherProps.rowData]);

  const selectHandler = (selectionIds: Array<string>) => {
    const rawSelectionIds = selectionIds.map((selectionId: string) =>
      selectionId.replace(':parent', '')
    );

    otherProps.onSelect && otherProps.onSelect(rawSelectionIds);
  };

  return (
    <CommonSimpleDataGrid
      {...otherProps}
      rowData={rowData}
      containerClassName="common-advanced-data-grid-container"
      fullWidthCellRenderer={CommonAdvancedDataGridRow}
      fullWidthCellRendererParams={{
        nestedTableProps,
      }}
      context={{
        gridSelectionPropsSelector: nestedTableProps.gridSelectionPropsSelector,
      }}
      isFullWidthRow={(params: IsFullWidthRowParams) => {
        return Boolean(params.rowNode.id?.includes('nested'));
      }}
      getRowHeight={(params: RowHeightParams) => {
        return Boolean(params.node.id?.includes('nested')) ? 0 : 50;
      }}
      onRowClicked={(event: RowClickedEvent) => {
        const shouldIgnoreElement = Boolean(
          event.eventPath?.find((value: EventTarget) =>
            (value as Element)?.classList?.contains('ignore-row-click')
          )
        );

        if (shouldIgnoreElement) return;

        const rowId = event.node.id?.replace(':parent', '');

        if (Boolean(rowId?.includes('nested'))) {
          return;
        }

        const targetType = (event.event?.target as HTMLInputElement)?.type;

        const hasExpandedArea = event.eventPath?.find((path) => {
          return (path as HTMLElement).classList?.contains(
            'common-advanced-data-grid-expanded-area'
          );
        });

        if (hasExpandedArea) return;

        if (targetType === 'checkbox') return;

        const nestedNode = event.api.getRowNode(`${rowId}:nested`);

        nestedNode?.setExpanded(!nestedNode?.expanded);
        if (nestedNode?.expanded) {
          nestedNode?.setRowHeight(200);
        } else {
          nestedNode?.setRowHeight(0);
        }

        event.api.redrawRows({ rowNodes: [nestedNode as IRowNode<any>] });
      }}
      onSelect={selectHandler}
      selectionModel={selections}
      suppressAnimationFrame
      suppressRowClickSelection
      suppressColumnVirtualisation
      suppressRowVirtualisation
      keepCurrentSelections
      rowBuffer={otherProps.paginationProps?.totalItems}
    />
  );
};

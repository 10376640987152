import { FunctionComponent, useMemo } from 'react';
import { ChartData } from 'shared/models/data/chart.model';
import { VerticalSimpleBarChartProps } from 'shared/models/props/chart-props.model';
import { v4 as uuid4 } from 'uuid';
import BaseChart from '../BaseChart';

export const VerticalSimpleBarChart: FunctionComponent<
  VerticalSimpleBarChartProps<ChartData>
> = ({
  fetchWidgetData,
  isWidgetDataLoading,
  widgetData,
  displayLegend = false,
  displayLabels = false,
  aspectRatio = 3,
  isResponsive = true,
  legendPosition = 'top',
  style = {},
  applyColorList = false,
  options: otherOptions,
  onElementClick,
}) => {
  const chartId = useMemo(() => {
    return uuid4();
  }, []);
  const chartOptions = useMemo(() => {
    return {
      ...otherOptions,
      aspectRatio,
      responsive: isResponsive,
      plugins: {
        ...otherOptions?.plugins,
        legend: {
          display: false,
        },

        datalabels: {
          display: displayLabels,
        },
        htmlLegend: {
          containerID: `chart-legend-container-${chartId}`,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: '#7f7f7f',
            callback: function (this: any, value: number) {
              const chartInstance = this.chart;
              const scale = chartInstance.scales['x'];
              const label = scale.getLabelForValue(value);
              if (label.length > 10) {
                return label.substring(0, 10) + '...';
              }
              return label;
            },
          },
        },
        y: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            color: '#7f7f7f',
            font: {
              size: 20,
            },
          },
        },
      },
    };
  }, []);

  return (
    <BaseChart
      options={chartOptions as any}
      type="bar"
      {...{
        fetchWidgetData,
        isWidgetDataLoading,
        widgetData,
        style,
        applyColorList,
        displayLegend,
        legendPosition,
        chartId,
      }}
      onElementClick={onElementClick}
    />
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';
import { subtractMonths } from 'Common/utils/utilsFunction';
import { comparativeDashboardFilter } from 'Dashboard/Comparative/interfaces/fixtures/data/comparative-dashboard-filter-option.data';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import { MenuItems, SelectionItems } from 'DashboardFilter/store';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { OperationalMetricType } from 'shared/models/data/operational-widget-data.model';
import {
  CategoryState,
  MultiSelectState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';

interface DashboardTypes {
  filter: DashboardFilterType;
  listItems: MenuItems;
  initialFilterLoad: boolean;
  filterState: Record<string, CategoryState>;
}

const dt = new Date().toISOString();

export const dashboardFilterInitialState: DashboardFilterType = {
  externalCreatedAt: [subtractMonths(2), dt],
  timeline: TimelineFilter['60_DAYS'],
  businessUnitId: [],
  businessUnitItems: [],
  presentationalBusinessUnitId: [],
  metricsTypes: [
    OperationalMetricType.NEW_FINDINGS,
    OperationalMetricType.NEW_RESOLVED_FINDINGS,
  ],
  selectedScope: ['All'],
  selectedGroup: ['All'],
  selectedServices: ['All'],
  findingTypes: [],
  selectedMetricTypes: [...Object.values(OperationalMetricType)],
};

const dashboardDefaultFilter: Record<string, CategoryState> = {
  scopeId: {
    selectedOptions: [{ value: 'All', label: 'All' }],
  },
  groupId: {
    selectedOptions: [{ value: 'All', label: 'All' }],
  },
  businessUnitId: {
    allSelected: true,
  },
  findingType: {
    allSelected: true,
  },
};

const initialState: DashboardTypes = {
  filter: dashboardFilterInitialState,
  filterState: dashboardDefaultFilter,
  listItems: comparativeDashboardFilter,
  initialFilterLoad: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setdashboardFilters: (
      state,
      action: PayloadAction<DashboardFilterType>
    ) => {
      state.filter = action.payload;
      state.initialFilterLoad = true;
    },
    setSelectedMetrics: (
      state,
      action: PayloadAction<OperationalMetricType[]>
    ) => {
      state.filter.metricsTypes = action.payload;
    },
    setListFilter: (
      state,
      action: PayloadAction<
        MenuItems | { list: MenuItems; isSelectingItem: boolean }
      >
    ) => {
      const updatedSelections: SelectionItems = [];

      const listMenu = Array.isArray(action.payload)
        ? [...action.payload]
        : action.payload.list;
      const modifiedArray = listMenu.map((object) => {
        if (
          object.dateRange ||
          object.numberInput ||
          object.items.some((item: any) => item.checked)
        ) {
          updatedSelections.push({
            id: object.id,
            type: object.type as TypeFilter,
            selectionValues:
              object.dateRange ||
              object.numberInput ||
              object.items?.filter((item: any) => item.checked),
          });
        }

        return {
          ...object,
          items: object.items.map((item) => {
            if (!item.hasOwnProperty('checked')) {
              return {
                ...item,
                checked: false,
              };
            }
            return { ...item };
          }),
        };
      });

      state.listItems = modifiedArray;
    },
    setListItems: (state, action: PayloadAction<MenuItems>) => {
      state.listItems = action.payload;
    },
    setSelectedScope: (state, action: PayloadAction<string[]>) => {
      state.filter.selectedScope = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<string[]>) => {
      state.filter.selectedGroup = action.payload;
    },
    setSelectedServices: (state, action: PayloadAction<string[]>) => {
      state.filter.selectedServices = action.payload;
    },
    setSelectedFindingTypes: (state, action: PayloadAction<string[]>) => {
      state.filter.findingTypes = action.payload;
    },
    setSelectedMetricTypes: (
      state,
      action: PayloadAction<Array<OperationalMetricType>>
    ) => {
      state.filter.selectedMetricTypes = action.payload;
    },
    setFilterState: (
      storeState,
      action: PayloadAction<{
        categoryId: string;
        state: CategoryState;
      }>
    ) => {
      const { categoryId, state } = action.payload;

      let updatedFilterState = { ...storeState.filterState };

      if (categoryId === 'scopeId') {
        updatedFilterState['groupId'] = {
          selectedOptions: [
            {
              value: 'All',
              label: 'All',
            },
          ],
        };
        updatedFilterState['businessUnitId'] = {
          allSelected: true,
        };
      } else if (categoryId === 'groupId') {
        updatedFilterState['businessUnitId'] = {
          allSelected: true,
        };
      }

      if (categoryId === 'scopeId' || categoryId === 'groupId') {
        updatedFilterState['riskAttributes'] = {};
        updatedFilterState['complianceRequirements'] = {};
        updatedFilterState['businessImpact'] = {};
      }

      updatedFilterState = {
        ...updatedFilterState,
        [categoryId]: state,
      };

      storeState.filterState = updatedFilterState;
    },
    removeFilterItem: (
      state,
      action: PayloadAction<{ categoryId: string }>
    ) => {
      const { categoryId } = action.payload;

      let updatedFilterState = { ...state.filterState };

      updatedFilterState[categoryId] = dashboardDefaultFilter[categoryId];

      if (categoryId === 'businessUnitId') {
        updatedFilterState['riskAttributes'] = {};
        updatedFilterState['businessImpact'] = {};
        updatedFilterState['complianceRequirements'] = {};
      }

      state.filterState = updatedFilterState;
    },
  },
});

export const {
  setdashboardFilters,
  setListFilter,
  setListItems,
  setSelectedScope,
  setSelectedGroup,
  setSelectedServices,
  setSelectedFindingTypes,
  setSelectedMetrics,
  setSelectedMetricTypes,
  setFilterState,
  removeFilterItem,
} = dashboardSlice.actions;

export const selectdashboardFilter = (state: RootState) =>
  state.dashboard.filter;

export const getFilterList = (state: RootState) => state.dashboard.listItems;

export const getFilterState = (state: RootState) => state.dashboard.filterState;

export const getSelectedScope = (state: RootState) =>
  state.dashboard.filter.selectedScope;
export const getSelectedGroup = (state: RootState) =>
  state.dashboard.filter.selectedGroup;
export const getSelectedServices = (state: RootState) =>
  state.dashboard.filter.selectedServices;

export const getInitialFilterLoad = (state: RootState) =>
  state.dashboard.initialFilterLoad;

export const getFindingTypes = (filterState: Record<string, CategoryState>) => {
  const findingTypeState = filterState['findingType'] as MultiSelectState;

  if (findingTypeState?.allSelected) return [];

  return (
    findingTypeState?.selectedOptions?.map(
      (selectedOption) => selectedOption.value
    ) || []
  );
};

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;

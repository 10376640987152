import { AutocompleteOption } from 'FindingDetails/store/api';
import { Team } from 'Settings/interfaces/Teams';

export class TeamDataHandler {
  transformTeamListToAutocompleteTeamList(
    teams: Array<Team>
  ): Array<AutocompleteOption> {
    return teams.map((team: Team) => ({
      value: team.id,
      label: team.name,
    }));
  }
}

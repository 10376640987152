import { CircularProgress, Stack } from '@mui/material';
import { BusinessUnitDashboardSection } from './components/BusinessUnitDashboardSection';
import SectionBreaker from './components/Dashboard/SectionBreaker';
import { useEffect, useMemo, useTransition } from 'react';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import TabList from 'shared/components/TabList';
import { DashboardTabPanel } from './interfaces/DashboardTabPanel.enum';
import { OperationalContent } from './components/OperationalContent/OperationalContent';
import CardWrapper from 'Common/components/CardWrapper';
import {
  getFilterList,
  selectdashboardFilter,
  setSelectedFindingTypes,
  setdashboardFilters,
  getFilterState,
  setFilterState,
  removeFilterItem,
} from './store';
import PostureContent from './components/PostureContent';
import {
  useGetBusinessUnitListWithMetadataQuery,
  useGetUserAvailableNodesOfTypeMutation,
} from './store/api';
import { DashboardFilterType } from './interfaces/DashboardFilterType.enum';
import { MenuItems } from '../DashboardFilter/store';
import useCommonSelector from '../Common/utils/use-selector';
import useCommonDispatch from '../Common/utils/use-dispatch';
import DashboardFilterHandler from 'shared/handlers/dashboard-filter.handler';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { TimelineFilter } from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import {
  getFilteredDashboardFilterCategories,
  serviceAttributes,
} from 'shared/fixtures/data/dashboard.data';
import {
  ApiStaticOptionSourceProperties,
  CategoryState,
  ExtendedFilterCategory,
  MultiSelectState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import _ from 'lodash';
import { useFetchDataBasedOnParametersMutation } from 'shared/store/api';
import { UniqueBusinessUnitId } from './components/BusinessUnitDashboardSection/BusinessUnitDashboardSection';
import { useFeatureEntitlements } from '@frontegg/react';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';

export interface OrganizationalDataProps {
  scopeData?: Array<OrganizationNode>;
  groupData?: Array<OrganizationNode>;
  serviceData?: Array<OrganizationNode>;
}

const filterServicesBasedOnAttributes = (services: any, attributes: any) => {
  const { riskAttributes, businessImpact, complianceRequirements } = attributes;

  return services.filter((service: any) => {
    const matchesBusinessImpact =
      businessImpact.length === 0 ||
      businessImpact.includes(service.criticality);

    const matchesRiskAttributes =
      riskAttributes.length === 0 ||
      riskAttributes.some((attr: string) =>
        service.riskAttributes?.includes(attr)
      );

    const matchesComplianceRequirements =
      complianceRequirements.length === 0 ||
      complianceRequirements.some((req: string) =>
        service.complianceRequirements?.includes(req)
      );

    return (
      matchesBusinessImpact &&
      matchesRiskAttributes &&
      matchesComplianceRequirements
    );
  });
};

export function DashboardPage() {
  const { isEntitled: isServiceAttributesEnabled } = useFeatureEntitlements(
    SystemFeatureFlag.DASHBOARD_SERVICE_ATTRIBUTES_FILTER
  );

  const { data: businessUnitListWithMetadata, isLoading: businessUnitLoading } =
    useGetBusinessUnitListWithMetadataQuery('');

  const [fetchOptions, { data: optionsData, isLoading: optionsDataLoading }] =
    useFetchDataBasedOnParametersMutation();

  const [, startTransition] = useTransition();

  const filter = useCommonSelector(selectdashboardFilter);
  const allDataMenu: MenuItems = useCommonSelector(getFilterList);

  const dispatch = useCommonDispatch();

  const [getUserAvailableGroups, { data: groupData }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [getUserAvailableScopes, { data: scopeData }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [
    getUserAvailableServices,
    { data: serviceData, isLoading: serviceDataLoading },
  ] = useGetUserAvailableNodesOfTypeMutation();

  const filterCategoryState = useCommonSelector(getFilterState);

  const hasServiceAttributesFilter = useMemo<boolean>(
    () => {
      for (const attribute of serviceAttributes) {
        if (
          (filterCategoryState[attribute] as MultiSelectState)?.selectedOptions
            ?.length
        ) {
          return true;
        }
      }
      return false;
    },
    serviceAttributes.map((attr) => filterCategoryState[attr])
  );

  const filteredServices = useMemo(() => {
    if (serviceData && businessUnitListWithMetadata) {
      const selectState = filterCategoryState as Record<
        string,
        SingleSelectState
      >;

      const filteredServicesWithMetadata = filterServicesBasedOnAttributes(
        businessUnitListWithMetadata,
        {
          riskAttributes:
            selectState['riskAttributes']?.selectedOptions?.map(
              (option) => option.value
            ) || [],
          businessImpact:
            selectState['businessImpact']?.selectedOptions?.map(
              (option) => option.value
            ) || [],
          complianceRequirements:
            selectState['complianceRequirements']?.selectedOptions?.map(
              (option) => option.value
            ) || [],
        }
      );

      return serviceData.filter((service: any) =>
        filteredServicesWithMetadata?.find(
          (filteredService: any) => filteredService.id === service.id
        )
      );
    }
    return [];
  }, [
    serviceData,
    businessUnitListWithMetadata,
    filterCategoryState['riskAttributes'],
    filterCategoryState['businessImpact'],
    filterCategoryState['complianceRequirements'],
  ]);

  const serviceStartNodeId = useMemo(() => {
    const selectedScopeOption = filterCategoryState[
      'scopeId'
    ] as SingleSelectState;

    const selectedScopeId = selectedScopeOption?.selectedOptions
      ? selectedScopeOption?.selectedOptions[0]?.value !== 'All'
        ? selectedScopeOption?.selectedOptions[0]?.value
        : undefined
      : undefined;

    const selectedGroupOption = filterCategoryState[
      'groupId'
    ] as SingleSelectState;

    const selectedGroupId = selectedGroupOption?.selectedOptions
      ? selectedGroupOption?.selectedOptions[0]?.value !== 'All'
        ? selectedGroupOption?.selectedOptions[0]?.value
        : undefined
      : undefined;

    const startNodeId = selectedGroupId || selectedScopeId;

    return startNodeId;
  }, [filterCategoryState['scopeId'], filterCategoryState['groupId']]);

  const onFilterChange = (categoryId: string, state: CategoryState) => {
    if (categoryId === 'scopeId' || categoryId === 'groupId') {
      const typedState = state as SingleSelectState;
      const startOrganizationNodeId = typedState?.selectedOptions
        ? typedState?.selectedOptions[0]?.value !== 'All'
          ? typedState?.selectedOptions[0]?.value
          : undefined
        : undefined;

      getUserAvailableServices({
        nodesType: OrganizationNodeType.DATA,
        startNodeId: startOrganizationNodeId,
      });
    }

    startTransition(() => {
      dispatch(setFilterState({ categoryId, state }));
    });
  };

  const onFilterRemove = (categoryId: string) => {
    if (categoryId === 'scopeId' || categoryId === 'groupId') {
      let startOrganizationNodeId;

      if (categoryId === 'scopeId') {
        startOrganizationNodeId = undefined;
      } else if (categoryId === 'groupId') {
        const scopeState = filterCategoryState['scopeId'] as SingleSelectState;

        const selectedScopeId = scopeState?.selectedOptions
          ? scopeState?.selectedOptions[0]?.value !== 'All'
            ? scopeState?.selectedOptions[0]?.value
            : undefined
          : undefined;

        startOrganizationNodeId = selectedScopeId;
      }

      getUserAvailableServices({
        nodesType: OrganizationNodeType.DATA,
        startNodeId: startOrganizationNodeId,
      });
    }

    dispatch(removeFilterItem({ categoryId }));
  };

  const { t: translation } = useTranslation();
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const activeTabId = useMemo<DashboardTabPanel>(() => {
    return urlSearchParams.activeTabId as DashboardTabPanel;
  }, [urlSearchParams]);
  const tabListItems = [
    {
      id: DashboardTabPanel.POSTURE,
      label: translation(`dashboards.tabs.posture`),
    },
    {
      id: DashboardTabPanel.OPERATIONAL,
      label: translation(`dashboards.tabs.operational`),
    },
  ];

  const renderContentForTab = () => {
    switch (activeTabId) {
      case DashboardTabPanel.OPERATIONAL:
        return (
          <OperationalContent scopeData={scopeData} groupData={groupData} />
        );
      case DashboardTabPanel.POSTURE:
        return <PostureContent scopeData={scopeData} groupData={groupData} />;

      default:
        if (!urlSearchParams.activeTabId) {
          setUrlSearchParams({
            ...urlSearchParams,
            activeTabId: DashboardTabPanel.POSTURE,
          });
        }
        return <PostureContent />;
    }
  };

  useEffect(() => {
    const findingTypeMenu = allDataMenu.find(
      (item) => item.id === DashboardFilterType.FINDING_TYPE
    );
    if (findingTypeMenu) {
      const selectedFindingTypes = DashboardFilterHandler.getFilterValues(
        findingTypeMenu.items || []
      );

      if (
        selectedFindingTypes.length !== 0 ||
        filter.findingTypes.length !== 0
      ) {
        dispatch(setSelectedFindingTypes(selectedFindingTypes));
      }
    }
  }, [allDataMenu]);

  useEffect(() => {
    getUserAvailableGroups({
      nodesType: OrganizationNodeType.LOGICAL,
    });
    getUserAvailableScopes({
      nodesType: OrganizationNodeType.SCOPE,
    });

    getUserAvailableServices({
      nodesType: OrganizationNodeType.DATA,
      startNodeId: serviceStartNodeId,
    });

    fetchOptions({
      url: 'config/filters/search/findingType',
      method: 'POST',
      data: {},
    });
  }, []);

  useEffect(() => {
    const filteredServicesOptions =
      filteredServices.map((filteredService: any) => ({
        value: filteredService.id,
        label: filteredService.name,
      })) || [];

    dispatch(
      setFilterState({
        categoryId: 'businessUnitId',
        state: {
          selectedOptions:
            hasServiceAttributesFilter || serviceStartNodeId
              ? filteredServicesOptions
              : [
                  ...filteredServicesOptions,
                  {
                    value: UniqueBusinessUnitId.NO_BUSINESS_UNIT,
                    label: 'No Service',
                  },
                ],
          allSelected: true,
        },
      })
    );
  }, [filteredServices]);

  useEffect(() => {
    if (optionsData?.data) {
      dispatch(
        setFilterState({
          categoryId: 'findingType',
          state: {
            selectedOptions: optionsData?.data,
            allSelected: true,
          },
        })
      );
    }
  }, [optionsData]);

  const selectedServiceIds = useMemo(() => {
    const typedState = filterCategoryState[
      'businessUnitId'
    ] as MultiSelectState;

    if (hasServiceAttributesFilter || serviceStartNodeId)
      return (
        typedState?.selectedOptions?.map(
          (selectedOption) => selectedOption.value
        ) || []
      );

    if (typedState?.allSelected)
      return [
        ...(typedState?.selectedOptions?.map(
          (selectedOption) => selectedOption.value
        ) || []),
        UniqueBusinessUnitId.ALL,
      ];

    return (
      typedState?.selectedOptions?.map(
        (selectedOption) => selectedOption.value
      ) || []
    );
  }, [
    filterCategoryState['businessUnitId'],
    hasServiceAttributesFilter,
    serviceStartNodeId,
  ]);

  const renderTabSection = () => {
    if (businessUnitLoading) return <CircularProgress size={25} />;

    return (
      <>
        <Stack className="dashboard-page-body-control-row">
          <TabList
            items={tabListItems}
            activeItemId={activeTabId}
            onSelect={(activeItemId: DashboardTabPanel) => {
              setUrlSearchParams({
                ...urlSearchParams,
                activeTabId: activeItemId,
              });

              dispatch(
                setdashboardFilters({
                  ...filter,
                  timeline: TimelineFilter['60_DAYS'],
                })
              );
            }}
            isUnderlined
          />
        </Stack>

        <div className="dashboard-page-content-body">
          {renderContentForTab()}
        </div>
      </>
    );
  };

  const getServiceChipText = () => {
    const riskAttributes =
      (
        filterCategoryState['riskAttributes'] as SingleSelectState
      )?.selectedOptions
        ?.map((selectedOption) => selectedOption.label)
        ?.join(', ') || '';
    const complianceRequirements =
      (
        filterCategoryState['complianceRequirements'] as SingleSelectState
      )?.selectedOptions
        ?.map((selectedOption) => selectedOption.label)
        ?.join(', ') || '';
    const businessImpact =
      (
        filterCategoryState['businessImpact'] as SingleSelectState
      )?.selectedOptions
        ?.map((selectedOption) => selectedOption.label)
        ?.join(', ') || '';

    return _.compact([
      businessImpact.length
        ? `<b>Business Impact</b> is <b>${businessImpact}</b>`
        : null,
      riskAttributes.length
        ? `<b>Risk Attributes</b> is <b>${riskAttributes}</b>`
        : null,
      complianceRequirements.length
        ? `<b>Compliance Requirements</b> is <b>${complianceRequirements}</b>`
        : null,
    ]).join(' AND ');
  };

  const enrichCategoryServiceItemWithFilters = (
    categories: Array<ExtendedFilterCategory>
  ) => {
    const allServicesSelected = (
      filterCategoryState['businessUnitId'] as MultiSelectState
    ).allSelected;

    const serviceChipText = getServiceChipText();

    return categories.map((category: ExtendedFilterCategory) => {
      if (category.id === 'businessUnitId') {
        return {
          ...category,
          state: {
            ...category.state,
            source: {
              ...(category.state as SingleSelectState)?.source,
              optionsTransformer: () => {
                const filteredServiceOptions = filteredServices.map(
                  (service: OrganizationNode) => ({
                    value: service.id,
                    label: service.name,
                  })
                );

                return hasServiceAttributesFilter || serviceStartNodeId
                  ? filteredServiceOptions
                  : [
                      ...filteredServiceOptions,
                      {
                        value: UniqueBusinessUnitId.NO_BUSINESS_UNIT,
                        label: 'No Service',
                      },
                    ];
              },
            },
          },
          getTagTextComponent: allServicesSelected
            ? serviceChipText.length
              ? () => (
                  <div
                    className="dashboard-filter-chip"
                    title={serviceChipText.replace(/<\/?[^>]+(>|$)/g, '')}
                    dangerouslySetInnerHTML={{
                      __html: '<b>All</b> where ' + serviceChipText,
                    }}
                  ></div>
                )
              : undefined
            : undefined,
        };
      }

      return category;
    });
  };

  const enrichCategoryServiceItem = (
    categories: Array<ExtendedFilterCategory>
  ) => {
    if (serviceStartNodeId) {
      return categories.map((category: ExtendedFilterCategory) => {
        if (category.id === 'businessUnitId') {
          return {
            ...category,
            state: {
              ...category.state,
              source: {
                ...(category.state as SingleSelectState).source,
                body: {
                  ...((
                    (category.state as SingleSelectState)
                      .source as ApiStaticOptionSourceProperties
                  ).body || {}),
                  startNodeId: serviceStartNodeId,
                },
              },
            },
          };
        }

        return category;
      });
    }

    return categories;
  };

  const enrichCategoryGroupItem = (
    categories: Array<ExtendedFilterCategory>
  ): Array<ExtendedFilterCategory> => {
    const selectedScopeOption = filterCategoryState[
      'scopeId'
    ] as SingleSelectState;

    const selectedScopeId = selectedScopeOption?.selectedOptions
      ? selectedScopeOption?.selectedOptions[0]?.value !== 'All'
        ? selectedScopeOption?.selectedOptions[0]?.value
        : undefined
      : undefined;

    if (selectedScopeId) {
      return categories.map((category: ExtendedFilterCategory) => {
        if (category.id === 'groupId') {
          return {
            ...category,
            state: {
              ...category.state,
              source: {
                ...(category.state as SingleSelectState).source,
                body: {
                  ...((
                    (category.state as SingleSelectState)
                      .source as ApiStaticOptionSourceProperties
                  ).body || {}),
                  startNodeId: selectedScopeId,
                },
              },
            },
          };
        }

        return category;
      });
    }

    return categories;
  };

  const enrichCategories = (categories: Array<ExtendedFilterCategory>) => {
    return enrichCategoryServiceItemWithFilters(
      enrichCategoryServiceItem(enrichCategoryGroupItem(categories))
    );
  };

  return (
    <div className="dashboard-page-container">
      <HeaderComponent text="Dashboard" />
      <div className="dashboard-page-body">
        <CardWrapper className="dashboard-page-filters">
          <FilterChipGroup
            categories={enrichCategories(
              getFilteredDashboardFilterCategories(isServiceAttributesEnabled)
            )}
            categoryState={filterCategoryState}
            onClearChipState={onFilterRemove}
            onChange={onFilterChange}
            staticCategoryIds={[
              'scopeId',
              'groupId',
              'businessUnitId',
              'findingType',
            ]}
            hiddenCategoryIds={[
              'riskAttributes',
              'businessImpact',
              'complianceRequirements',
            ]}
            disableAddButton
          />
          {/* <ScopeGroupFilter
            getters={{
              getFilterList: getFilterList,
              getSelectedGroup: getSelectedGroup,
              getSelectedScope: getSelectedScope,
            }}
            setters={{
              setListFilter: setListFilter,
              setListItems: setListItems,
              setSelectedGroup: setSelectedGroup,
              setSelectedScope: setSelectedScope,
              setSelectedServices: setSelectedServices,
            }}
            filterItems={comparativeDashboardFilter.filter((item) =>
              headerFilterIds.includes(item.id)
            )}
          /> */}
        </CardWrapper>
        <BusinessUnitDashboardSection
          businessUnitLoading={businessUnitLoading || serviceDataLoading}
          businessUnitListWithMetadata={businessUnitListWithMetadata}
          scopeData={scopeData}
          groupData={groupData}
          serviceData={serviceData}
          selectedServiceIds={selectedServiceIds}
        />
        <SectionBreaker />
        {renderTabSection()}
      </div>
    </div>
  );
}

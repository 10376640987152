import React, { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import { AgGridEvent } from 'ag-grid-community';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import CommonPagination from 'Common/components/Pagination';
import SearchField from 'Common/components/SearchField/SearchField';
import {
  useConnectionDeleteMutation,
  useFetchConnectionsForIntegrationTypeQuery,
} from 'Integrations/Connection/store/api';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import ConnectionsListGridColumns from 'Integrations/fixtures/connections-list-table-columns';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { ConnectionItem } from 'Integrations/interfaces/ConnectionItem.model';
import { CommonDialogProps } from 'Common/components/Dialogs/CommonDialog/CommonDialog';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { useTranslation } from 'react-i18next';
import { IntegrationModal } from 'Integrations/Connection/components/IntegrationModal/IntegrationModal';
import { IntegrationCategory } from '../../interfaces/IntegrationCategory.enum';
import { keepOpenIntegrationCategories } from 'shared/fixtures/data/integrations.data';

interface IntegrationsListModalProps extends CommonDialogProps {
  integration?: IntegrationItem;
}

export const IntegrationsListModal: React.FC<IntegrationsListModalProps> = ({
  integration,
  onClose,
  ...props
}: IntegrationsListModalProps) => {
  const { t: translation } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [paginatedRows, setPaginatedRows] = useState<ConnectionItem[]>([]);
  const [integrationModalOpen, setIntegrationModalOpen] =
    useState<boolean>(false);

  const [activeConnectionId, setActiveConnectionId] = useState<
    string | undefined
  >(undefined);

  const [connectionData, setConnectionData] = useState<ConnectionItem[]>([]);
  const [searchData, setSearchData] = useState<ConnectionItem[]>([]);
  const gridRef = useRef<AgGridEvent | null>(null);

  const {
    data: connectionList,
    isLoading,
    refetch,
    isFetching,
  } = useFetchConnectionsForIntegrationTypeQuery(integration?.id || '', {
    skip: !integration?.id,
  });

  const handleEditDialogOpen = (rowId: string) => {
    setActiveConnectionId(rowId);
    setIntegrationModalOpen(true);
  };

  useEffect(() => {
    if (connectionList?.connections && !isFetching) {
      const columns = ConnectionsListGridColumns.getConnectionListColumns({
        t: translation,
        useDeleteMutation: useConnectionDeleteMutation,
        onDelete: () => {},
        onUpdate: handleEditDialogOpen,
        setShouldIgnoreRowClick: true,
        requiredPermissions: [ApplicationPermission.INTEGRATIONS_WRITE],
        columns: connectionList?.columns,
      });
      setTableColumns(columns);
      setConnectionData(connectionList.connections);
      setSearchData(connectionList.connections);
      calculatePaginatedRows(pageSize, page, connectionList.connections);
    }
  }, [connectionList, pageSize, page, isFetching]);

  useEffect(() => {
    setPage(1);
    calculatePaginatedRows(pageSize, page, searchData);
  }, [searchData]);

  useEffect(() => {
    if (props.open) {
      refetch();
    }
  }, [props.open, refetch]);

  const calculatePaginatedRows = (
    size: number,
    pageNum: number,
    data: ConnectionItem[]
  ) => {
    const startRowIndex = (pageNum - 1) * size;
    const endRowIndex = startRowIndex + size;
    const paginatedRows = data.slice(startRowIndex, endRowIndex);
    const sortedPaginatedRows = [
      ...paginatedRows.filter((obj) => obj.isFallback),
      ...paginatedRows.filter((obj) => !obj.isFallback),
    ];

    setPaginatedRows(sortedPaginatedRows);
  };

  const onPageChange = (selectedPage: number) => {
    setPage(selectedPage);
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setPage(1);
  };

  const handleCreateDialogOpen = () => {
    setIntegrationModalOpen(true);
  };

  const handleDialogClose = () => {
    setActiveConnectionId(undefined);
    setIntegrationModalOpen(false);
  };

  const handleCreateSuccess = (id: string) => {
    if (
      keepOpenIntegrationCategories.includes(
        integration?.category as IntegrationCategory
      )
    ) {
      setActiveConnectionId(id);
      setIntegrationModalOpen(true);
    } else {
      handleDialogClose();
    }
  };

  const renderPagination = () => {
    return searchData?.length > 10 ? (
      <div>
        <Box mb={2} className="settings-pagination" mx={2}>
          <CommonPagination
            totalItems={connectionData?.length || 0}
            pageSize={pageSize}
            currentPage={page}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
          />
        </Box>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <CommonDialog
      {...props}
      onSave={() => {
        handleCreateDialogOpen();
      }}
      classes="integration-list-modal"
      onClose={() => {
        setPaginatedRows([]);
        setConnectionData([]);
        setSearchData([]);
        onClose();
      }}
    >
      <div className="integration-list-modal-body">
        <div className="integration-list-modal-search-input">
          <SearchField
            setTableItems={setSearchData}
            items={connectionData}
            filterPropertyName={'name'}
          />
        </div>
        <div className="integration-list-modal-table">
          <CommonSimpleDataGrid
            rowData={paginatedRows}
            columnDefs={tableColumns || []}
            defaultColDef={{
              resizable: true,
            }}
            gridRef={gridRef}
            containerClassName="integrations-list-data-grid"
            suppressRowClickSelection
            visibilityControlProps={{
              enableVisibilityControls: false,
              columns: [],
            }}
            isLoading={isLoading || isFetching}
            otherComponents={{
              NoDataBackdropComponent: <NoDataBackdrop />,
            }}
            loadingOverlayComponent={() => <CircularProgress />}
            domLayout="autoHeight"
          />
        </div>
        {renderPagination()}
        <IntegrationModal
          connectionId={activeConnectionId}
          open={integrationModalOpen}
          onCancel={handleDialogClose}
          onCreateSuccess={handleCreateSuccess}
          integrationItem={integration as IntegrationItem}
        />
      </div>
    </CommonDialog>
  );
};

import { MenuItemProps } from 'Integrations/Common/MenuItem/MenuItem';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

export const generateMenuList = (
  activeMenuItem: string,
  handleMenuItemClick: (arg0: string) => any
): MenuItemProps[] => {
  const menuItem: MenuItemProps[] = [
    {
      title: 'All',
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_VIRUS_ICON} />,
      isActive: activeMenuItem === 'All',
      onClick: () => handleMenuItemClick('All'),
    },
    {
      title: IntegrationCategory.CLOUD_PROVIDER,
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.CLOUD_ICON} />,
      isActive: activeMenuItem === IntegrationCategory.CLOUD_PROVIDER,
      onClick: () => handleMenuItemClick(IntegrationCategory.CLOUD_PROVIDER),
    },
    {
      title: IntegrationCategory.FINDINGS_SOURCE,
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_KEYHOLE_ICON} />,
      isActive: activeMenuItem === IntegrationCategory.FINDINGS_SOURCE,
      onClick: () => handleMenuItemClick(IntegrationCategory.FINDINGS_SOURCE),
    },
    {
      title: IntegrationCategory.SCM,
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.CODE_FORK_ICON} />,
      isActive: activeMenuItem === IntegrationCategory.SCM,
      onClick: () => handleMenuItemClick(IntegrationCategory.SCM),
    },
    {
      title: IntegrationCategory.TICKETING,
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />,
      isActive: activeMenuItem === IntegrationCategory.TICKETING,
      onClick: () => handleMenuItemClick(IntegrationCategory.TICKETING),
    },
    {
      title: IntegrationCategory.MESSAGING,
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.MESSAGE_ICON} />,
      isActive: activeMenuItem === IntegrationCategory.MESSAGING,
      onClick: () => handleMenuItemClick(IntegrationCategory.MESSAGING),
    },
  ];

  return menuItem;
};

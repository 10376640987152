import { FunctionComponent, useMemo } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CrossroadCondition } from '../CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';
import TokenInput from 'shared/components/TokenInput';
import Autocomplete from 'Common/components/Autocomplete';
import { Button } from '@mui/material';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { ConditionProps } from '../CrossroadPanelConditionRoute/CrossroadPanelConditionRoute';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CrossroadConditionOperator } from 'WorkflowBuilder/interfaces';

const conditionOperatorAutocompleteOptions: Array<AutocompleteOption> = [
  {
    label: 'Equal To',
    value: CrossroadConditionOperator.EQUAL,
  },
  {
    label: 'Not Equal To',
    value: CrossroadConditionOperator.NOTEQUAL,
  },
  {
    label: 'Contains',
    value: CrossroadConditionOperator.CONTAINS,
  },
  {
    label: 'Not Contains',
    value: CrossroadConditionOperator.NOTCONTAINS,
  },
];
interface CrossroadPanelConditionProps
  extends BaseComponentProps,
    CrossroadCondition,
    ConditionProps {
  routeIndex: number;
  rowIndex: number;
  columnIndex: number;
}

export const CrossroadPanelCondition: FunctionComponent<
  CrossroadPanelConditionProps
> = ({
  expressionA,
  expressionB,
  routeIndex,
  operator,
  rowIndex,
  columnIndex,
  deleteHandler,
  addAndConditionHandler,
  addOrConditionHandler,
  updateHandler,
}) => {
  const selectedOption: AutocompleteOption = useMemo(() => {
    return (
      conditionOperatorAutocompleteOptions.find(
        (conditionOption: AutocompleteOption) =>
          conditionOption.value === operator
      ) || conditionOperatorAutocompleteOptions[0]
    );
  }, [operator]);

  return (
    <div className="crossroad-panel-condition">
      <div className="crossroad-panel-condition-form">
        <div className="crossroad-panel-condition-form-input-group">
          <div className="crossroad-panel-condition-form-input-label">
            Set Condition
          </div>
          <div className="crossroad-panel-condition-form-input">
            <TokenInput
              content={expressionA}
              onChange={(value) => {
                updateHandler(
                  {
                    routeIndex,
                    rowIndex,
                    columnIndex,
                  },
                  'expressionA',
                  value
                );
              }}
            />
          </div>
        </div>
        <div className="crossroad-panel-condition-form-select-group">
          <div className="crossroad-panel-condition-form-select-label">
            Operator:
          </div>
          <div className="crossroad-panel-condition-form-select">
            <Autocomplete
              model="operator"
              onChangeCallBack={(
                model: string,
                option: AutocompleteOption | Array<AutocompleteOption>
              ) => {
                const singleOption: AutocompleteOption =
                  option as AutocompleteOption;

                updateHandler(
                  {
                    routeIndex,
                    rowIndex,
                    columnIndex,
                  },
                  'operator',
                  singleOption.value
                );
              }}
              areOptionsLoaded
              single
              optionList={conditionOperatorAutocompleteOptions}
              initialSelectedValues={selectedOption}
              values={selectedOption}
              icons={{
                DropdownIcon: (
                  <OpusSvgIcon type={SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON} />
                ),
              }}
            />
          </div>
        </div>

        <div className="crossroad-panel-condition-form-input-group">
          <div className="crossroad-panel-condition-form-input-label">
            Set Condition
          </div>
          <div className="crossroad-panel-condition-form-input">
            <TokenInput
              content={expressionB}
              onChange={(value) => {
                updateHandler(
                  {
                    routeIndex,
                    rowIndex,
                    columnIndex,
                  },
                  'expressionB',
                  value
                );
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="crossroad-panel-condition-delete-buttons"
        onClick={() => {
          deleteHandler(routeIndex, rowIndex, columnIndex);
        }}
      >
        <Button className="opus-secondary-button">Delete</Button>
      </div>
    </div>
  );
};

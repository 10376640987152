export enum CloudWorkspaceDialogType {
  AWS = 'aws',
  AZURE = 'azure',
  OPUS = 'opus',
}

export enum CloudWorkspaceType {
  CLOUD_ENVIRONMENT = 'Cloud Account',
  CODE_MANAGEMENT = 'Code Repo',
  ON_PREM = 'On-Premises',
  DOMAIN = 'Domain',
}

export enum CloudWorkspaceApiType {
  CLOUD_ENVIRONMENT = 'CLOUD_ENVIRONMENT',
  CODE_MANAGEMENT = 'CODE_MANAGEMENT',
  ON_PREM = 'ON_PREMISES',
  DOMAIN = 'DOMAIN',
}

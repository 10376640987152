import { IaCItem } from 'IaCFindings/interfaces/IaCFinding';
import { StringToStringMapper } from '../../Common/utils/types';

export enum ResourceType {
  S3 = 'AwsS3Bucket',
  AWS_EC2_SECURITY_GROUP = 'AwsEc2SecurityGroup',
  AWS_EC2_SECURITY_INSTANCE = 'AwsEc2Instance',
  AWS_RDS_DB_INSTANCE = 'AwsRdsDbInstance',
}

export const knownResources = [
  'AwsS3Bucket',
  'AwsEc2SecurityGroup',
  'AwsEc2Instance',
  'AwsRdsDbInstance',
  'AwsIamRole',
  'AwsEcrContainerImage',
];

export const ResourceTitleMapper: StringToStringMapper = {
  AwsS3Bucket: 'S3',
  AwsEc2SecurityGroup: 'Security Group',
  AwsEc2Instance: 'EC2 Instance',
  AwsRdsDbInstance: 'RDS',
  AwsIamRole: 'Role',
  AwsEcrContainerImage: 'ECR',
};

interface ResourceTag {
  tagName: string;
  tagValue: string;
}

export default interface Resource {
  externalCreatedAt?: Date;
  updatedAt?: Date;
  tenantId?: string;
  id?: string;
  resourceId?: string;
  findingId?: string;
  name?: string;
  type?: ResourceType;
  ownerUserId?: string;
  tags: ResourceTag[];
  iacSource?: IaCItem;
  resourceTypeLogo?: string;
}

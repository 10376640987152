import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';

export enum AdvanceFilterGetter {
  SCOPE = 'SCOPE',
  GROUP = 'GROUP',
}

interface AdvanceFilterGetterPayload {
  getter?: (body: any) => void;
  reset?: () => void;
  data?: any;
  isDataLoading?: any;
}

export function useGetAdvanceFilterGetter(
  getterType: AdvanceFilterGetter
): AdvanceFilterGetterPayload | undefined {
  const [
    getScopes,
    { data: scopeData, isLoading: scopeDataLoading, reset: resetScopes },
  ] = useGetUserAvailableNodesOfTypeMutation();

  const [
    getGroups,
    { data: groupData, isLoading: groupDataLoading, reset: resetGroups },
  ] = useGetUserAvailableNodesOfTypeMutation();

  switch (getterType) {
    case AdvanceFilterGetter.SCOPE:
      return {
        getter: getScopes,
        data: scopeData,
        isDataLoading: scopeDataLoading,
        reset: resetScopes,
      };
    case AdvanceFilterGetter.GROUP:
      return {
        getter: getGroups,
        data: groupData,
        isDataLoading: groupDataLoading,
        reset: resetGroups,
      };
  }
}

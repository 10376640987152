import { FC } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import DeleteWithConfirm from 'Common/components/Dialogs/DeleteWithConfirm';
import { useUnlinkFindingItemRelationMutation } from 'FindingDetails/store/api';
import { useTranslation } from 'react-i18next';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useDeleteCustomFilterMutation } from 'Risk/store/api';
interface FilterMainItemProps {
  item: any;
  styleClass?: string;
  clickItem: (value: any) => void;
  onDelete?: (e?: any) => void;
  displayIcon?: boolean;
  deleteCallback?: (decision: boolean, response?: any) => void;
}

const FilterMainItem: FC<FilterMainItemProps> = ({
  item,
  clickItem,
  styleClass,
  displayIcon = true,
  deleteCallback = () => {},
  onDelete,
}) => {
  const { t: translation } = useTranslation();

  return (
    <div className={classNames('filter-item', styleClass)} onClick={clickItem}>
      <span className="body-1 item-text">{item.name}</span>
      {displayIcon && <ChevronRightIcon />}
      {item.enableDelete && (
        <DeleteWithConfirm
          id={item.id}
          setShouldIgnoreRowClick={null}
          title={translation('common.areYouSure')}
          modalIcon={<OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED} />}
          message={translation('findings.filter.deleteQuickFilterWarning', {
            param1: item.name,
          })}
          customDelete={onDelete}
          callback={deleteCallback}
          confimButtonLabel={translation('common.delete')}
          loadingConfimButtonLabel={translation('common.deleting')}
          useMutation={useDeleteCustomFilterMutation}
        >
          <span className="filter-item-delete-btn">
            <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED} />
          </span>
        </DeleteWithConfirm>
      )}
    </div>
  );
};

export default FilterMainItem;

import { Box, Typography } from '@mui/material';
import React, { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';

interface OrganizationNodeLabelProps {
  type: OrganizationNodeType;
  label: ReactNode;
}

export const iconMap: Record<
  OrganizationNodeType,
  ReactElement<any, string | JSXElementConstructor<any>> | undefined
> = {
  [OrganizationNodeType.ROOT]: (
    <div className="node-icon-container">
      <OpusSvgIcon type={SVG_ICON_TYPES.SITEMAP_ICON}></OpusSvgIcon>
    </div>
  ),
  [OrganizationNodeType.SCOPE]: (
    <div className="node-icon-container">
      <OpusSvgIcon type={SVG_ICON_TYPES.CROSSHAIRS_ICON}></OpusSvgIcon>
    </div>
  ),
  [OrganizationNodeType.LOGICAL]: (
    <div className="node-icon-container">
      <OpusSvgIcon type={SVG_ICON_TYPES.PEOPLE_GROUP_ICON}></OpusSvgIcon>
    </div>
  ),
  [OrganizationNodeType.DATA]: (
    <div className="node-icon-container">
      <OpusSvgIcon type={SVG_ICON_TYPES.BRIEFCASE_ICON}></OpusSvgIcon>
    </div>
  ),
};

export const OrganizationNodeLabel = ({
  type,
  label,
}: OrganizationNodeLabelProps) => {
  const icon = iconMap[type] || null;

  return (
    <Box className="node-label-container">
      {icon}
      <div>
        <span className="label-1">{label}</span>
      </div>
    </Box>
  );
};

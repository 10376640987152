import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';

import { SVG_ICON_TYPES } from 'shared/icons/enums';

export enum AlertBoxType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export interface AlertBoxProps extends BaseComponentProps {
  type: AlertBoxType;
  onClose: () => void;
}

export const getAlertBoxIcon = (type: AlertBoxType) => {
  switch (type) {
    case AlertBoxType.ERROR:
      return <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />;
    case AlertBoxType.SUCCESS:
      return <></>;
  }
};

export const getAlertBoxClassname = (type: string) => {
  switch (type) {
    case AlertBoxType.ERROR:
      return 'alert-box-error';
    case AlertBoxType.SUCCESS:
      return 'alert-box-success';
    case AlertBoxType.WARNING:
      return 'alert-box-warning';
  }
};

export const AlertBox: FunctionComponent<AlertBoxProps> = ({
  type,
  onClose,
  children,
}) => {
  return (
    <div className={`alert-box-container ${getAlertBoxClassname(type)}`}>
      <div className="alert-box-icon">{getAlertBoxIcon(type)}</div>
      <div className="alert-box-text">{children}</div>
      <div className="alert-box-close-icon" onClick={onClose}>
        <OpusSvgIcon type={SVG_ICON_TYPES.TIMES_ICON} height={8} width={8} />
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'Common/store';
import { OrderState } from 'Common/utils/sort';
import { MenuItems, SelectionItems } from 'DashboardFilter/store';
import { CloudWorkspacesFilters } from 'Settings/interfaces/CloudWorkspaceFilterItems';
import { TypeFilter } from 'shared/enums/campaigns.enum';

export interface SettingsFilterItem {
  value: string;
  label?: string;
  iconUrl?: string;
}

export interface SettingsFilters {
  [key: string]: Array<SettingsFilterItem> | SettingsFilterItem;
}

export interface SettingsStoreSearchParams {
  filter: SettingsFilters;
  order?: OrderState;
  skip: number;
  take: number;
}

interface SettingsStore {
  searchParams: SettingsStoreSearchParams;
  listItems: MenuItems;
  filterSelections: SelectionItems;
}

const initialSettingsSearchParams: SettingsStoreSearchParams = {
  skip: 0,
  take: 25,
  filter: {},
};

const initialState: SettingsStore = {
  searchParams: initialSettingsSearchParams,
  listItems: CloudWorkspacesFilters,
  filterSelections: [],
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSearchParams: (
      state,
      action: PayloadAction<Partial<SettingsStoreSearchParams>>
    ) => {
      if (action.payload && Object.keys(action.payload).length) {
        state.searchParams = {
          ...state.searchParams,
          ...action.payload,
        };
      }
    },
    setListFilter: (
      state,
      action: PayloadAction<
        MenuItems | { list: MenuItems; isSelectingItem: boolean }
      >
    ) => {
      const updatedSelections: SelectionItems = [];

      const listMenu = Array.isArray(action.payload)
        ? [...action.payload]
        : action.payload.list;
      const modifiedArray = listMenu.map((object) => {
        if (
          object.dateRange ||
          object.numberInput ||
          object.items.some((item: any) => item.checked)
        ) {
          updatedSelections.push({
            id: object.id,
            type: object.type as TypeFilter,
            selectionValues:
              object.dateRange ||
              object.numberInput ||
              object.items?.filter((item: any) => item.checked),
          });
        }

        return {
          ...object,
          items: object.items.map((item) => {
            if (!item.hasOwnProperty('checked')) {
              return {
                ...item,
                checked: false,
              };
            }
            return { ...item };
          }),
        };
      });

      if (
        Array.isArray(action.payload) === false &&
        (action.payload as { list: MenuItems; isSelectingItem: boolean })
          .isSelectingItem
      ) {
        state.filterSelections = updatedSelections;
      }

      state.listItems = modifiedArray;
    },
    setListItems: (state, action: PayloadAction<MenuItems>) => {
      state.listItems = action.payload;
    },
    resetSearchParams: (state) => {
      state.searchParams = initialSettingsSearchParams;
    },
  },
});

export const {
  setSearchParams,
  setListFilter,
  setListItems,
  resetSearchParams,
} = settingsSlice.actions;

export const getSettingsSearchParams = (state: RootState) =>
  state.settings.searchParams;

export const getFilterList = (state: RootState) => state.settings.listItems;

export const getFilterSelections = (state: RootState) =>
  state.settings.filterSelections;

const settingsReducer = settingsSlice.reducer;
export default settingsReducer;

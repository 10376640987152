import React, {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  Select,
  MenuItem,
  Stack,
  Typography,
  TextField,
  FormHelperText,
  SxProps,
  Button,
  Grid,
  TextareaAutosize,
  Switch,
} from '@mui/material';
import './DynamicForm.css';
import { SwitchStyled } from 'Common/components/Switch/SwitchStyled';
import FormattedMessage from 'shared/components/FormattedMessage';
import TokenInput from 'shared/components/TokenInput';

export interface IDynamicFieldOption {
  value: string;
  label: string;
  active?: boolean;
}

export interface IDynamicField {
  type: 'text' | 'text_area' | 'select';
  name: string;
  label: string;
  options?: Array<IDynamicFieldOption>;
  onChange?: (args: any) => void;
  className?: string;
  hasButton?: boolean;
  buttonClassName?: string;
}

export interface DynamicFormProps {
  children?: any;
  fields: Array<IDynamicField>;
  onSubmit: (event: SyntheticEvent<HTMLFormElement>) => void;
  formState?: any;
  FormButtons?: any;
  initialValues?: any;
  otherData?: any;
  sx?: SxProps;
  mode?: 'watch' | 'submit';
  formClassName?: string;
  handleTimelineEventChange?: any;
  handleContinueWorkflowIfFailChange?: any;
  timelineEventConfig?: any;
  continueWorkflowIfFail?: boolean;
}

export const DynamicForm = ({
  fields,
  FormButtons,
  formState,
  onSubmit,
  otherData,
  initialValues,
  sx,
  formClassName,
  mode = 'watch',
  handleTimelineEventChange,
  handleContinueWorkflowIfFailChange,
  timelineEventConfig = {},
  continueWorkflowIfFail = true,
}: DynamicFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    getValues,
  } = formState;
  const { t: translation } = useTranslation();

  const formValues = getValues();

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      for (let key of Object.keys(initialValues)) {
        setValue(key, initialValues[key]);
      }
    }

    return () => {
      fields.forEach((field: IDynamicField) => {
        setValue(field.name, undefined);
      });
    };
  }, []);

  const getFieldByType = useCallback(
    (field: IDynamicField) => {
      switch (field.type) {
        case 'text':
          return (
            <React.Fragment>
              <Typography variant="subtitle1" fontWeight={700} component="div">
                {field.label}
              </Typography>
              <Grid container wrap="wrap" direction="row" spacing={2}>
                <Grid item xs={field.hasButton ? 9 : 12}>
                  <TokenInput
                    content={initialValues[field.name]}
                    onChange={(text: string) => {
                      setValue(field.name, text);
                      otherData?.outerFnParams?.fn(
                        ...otherData.outerFnParams.args,
                        { [field.name]: text }
                      );
                    }}
                  />
                </Grid>
                {field.hasButton && (
                  <Grid item xs={2}>
                    <Button
                      className={field.buttonClassName}
                      variant="contained"
                      type="button"
                    >
                      {'< >'}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {errors[field.name]?.type === 'required' && (
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {field.label} is required
                </FormHelperText>
              )}
            </React.Fragment>
          );
        case 'text_area':
          return (
            <React.Fragment>
              <Typography variant="subtitle1" fontWeight={700} component="div">
                {field.label}
              </Typography>
              <Grid container wrap="wrap" direction="row" spacing={2}>
                <Grid item xs={field.hasButton ? 9 : 12}>
                  <TextareaAutosize
                    maxRows={10}
                    minRows={10}
                    sx={{ ...sx, width: '100%' }}
                    className="list-panel-textarea"
                    name={field.name}
                    {...register(field.name, { required: true })}
                    error={errors[field.name]}
                    onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                      otherData?.outerFnParams?.fn(
                        ...otherData.outerFnParams.args,
                        { [field.name]: (event.target as any).value }
                      );
                    }}
                  />
                </Grid>
                {field.hasButton && (
                  <Grid item xs={2}>
                    <Button
                      className={field.buttonClassName}
                      variant="contained"
                      type="button"
                    >
                      {'< >'}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {errors[field.name]?.type === 'required' && (
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {field.label} is required
                </FormHelperText>
              )}
            </React.Fragment>
          );
        case 'select':
          return (
            <React.Fragment>
              <Typography variant="subtitle1" fontWeight={700} component="div">
                {field.label}
              </Typography>
              <Select
                sx={{ ...sx, width: '100%' }}
                className={field.className}
                id={field.name}
                value={initialValues && initialValues[field.name]}
                {...register(field.name, { required: true })}
                error={errors[field.name]}
                onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                  setValue(field.name, (event.target as any).value);
                  if (field.onChange) {
                    field.onChange(event);
                  } else
                    otherData?.outerFnParams?.fn(
                      ...otherData.outerFnParams.args,
                      { [field.name]: (event.target as any).value }
                    );
                }}
                defaultValue={initialValues && initialValues[field.name]}
              >
                {field.options?.map((option: IDynamicFieldOption) => {
                  return (
                    <MenuItem value={option.value}>{option.label}</MenuItem>
                  );
                })}
              </Select>
              {errors[field.name]?.type === 'required' && (
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  Please select a value
                </FormHelperText>
              )}
            </React.Fragment>
          );
        default:
          return <div></div>;
      }
    },
    [formValues]
  );

  return (
    <form
      className={`dynamic-form ${formClassName}`}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="dynamic-form-inputs-container">
        <Stack flexDirection="column" gap={4}>
          {fields.map((field: IDynamicField, index: number) => {
            return (
              <FormControl key={index}>{getFieldByType(field)}</FormControl>
            );
          })}
        </Stack>
        {timelineEventConfig && Object.keys(timelineEventConfig).length > 0 && (
          <div className="timeline-event">
            <div className="timeline-event-title-row">
              <Typography variant="subtitle1" fontWeight={700} component="div">
                <FormattedMessage
                  id="flows.details.timeLineEventLabel"
                  defaultMessage="Timeline Event"
                ></FormattedMessage>
              </Typography>
              <Switch
                checked={timelineEventConfig.status}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleTimelineEventChange(
                    event.target.checked,
                    timelineEventConfig.value
                  );
                }}
                classes={{
                  root: 'opus-switch-field-1',
                }}
              />
            </div>

            <div className="timeline-event-input-row">
              {timelineEventConfig.status && (
                <TokenInput
                  onChange={(value: string) => {
                    handleTimelineEventChange(
                      timelineEventConfig.status,
                      value
                    );
                  }}
                  content={timelineEventConfig.value}
                />
              )}
            </div>
          </div>
        )}
        <Grid container wrap="wrap" direction="row" spacing={2}>
          <Grid item xs={10}>
            <Typography variant="subtitle1" fontWeight={700} component="div">
              <FormattedMessage
                id="flows.details.workflowAffectOnFailLabel"
                defaultMessage="Stop Workflow If Step Fail"
              ></FormattedMessage>
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right" alignContent={'flex-end'}>
            <Switch
              checked={continueWorkflowIfFail}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleContinueWorkflowIfFailChange(event.target.checked);
              }}
              classes={{
                root: 'opus-switch-field-1',
              }}
            />
          </Grid>
        </Grid>
      </div>

      <div className="dynamic-form-buttons-container">
        {FormButtons ? <FormButtons /> : <></>}
      </div>
    </form>
  );
};

import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
interface HeaderComponentProps {
  text: string | ReactJSXElement;
  sideElement?: ReactNode | string;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  text,
  sideElement,
}) => {
  return (
    <div
      className={classNames([
        !sideElement
          ? 'dashboard-page-header'
          : 'dashboard-page-header-side-element',
      ])}
    >
      <span className="header-4">{text}</span>
      {sideElement && sideElement}
    </div>
  );
};

export default HeaderComponent;

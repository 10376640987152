import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TreeView } from '@mui/x-tree-view';
import { useGetOrganizationTreeQuery } from 'Organization/store/api';
import OrganizationNodeDialog from '../OrganizationNodeDialog';
import OrganizationTreeItemNode from '../OrganizationTreeItem';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationModalMode } from 'Organization/interfaces/OrganizationModalMode.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { OrganizationNodeContentProps } from '../OrganizationNodeContent/OrganizationNodeContent';

export const OrganizationTree = () => {
  const {
    data: organizationData,
    isLoading,
    refetch: refetchOrgData,
    isFetching,
  } = useGetOrganizationTreeQuery();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<OrganizationModalMode>(
    OrganizationModalMode.CREATE
  );
  const [selectedType, setSelectedType] = useState<
    OrganizationNodeType | undefined
  >(undefined);
  const [selectedNode, setSelectedNode] = useState<
    OrganizationNode | undefined
  >(undefined);

  useEffect(() => {
    refetchOrgData();
  }, []);

  const handleButtonClick = (
    data: OrganizationNodeContentProps,
    type: OrganizationModalMode
  ) => {
    setSelectedType(data.type);
    setSelectedNode(data);
    setOpenModal(true);
    setModalMode(type);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedType(undefined);
    setSelectedNode(undefined);
  };

  function renderTree(data: any) {
    return (
      <OrganizationTreeItemNode
        key={data.id}
        nodeId={data.id}
        label={data.name}
        type={data.type}
        {...data}
        onButtonClick={() =>
          handleButtonClick(data, OrganizationModalMode.CREATE)
        }
        handleEditButton={() =>
          handleButtonClick(data, OrganizationModalMode.EDIT)
        }
      >
        {Array.isArray(data.children)
          ? data.children.map((child: any) => renderTree(child))
          : null}
      </OrganizationTreeItemNode>
    );
  }

  return (
    <>
      {isLoading || isFetching ? (
        <Box display={'flex'} justifyContent="space-around">
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Box className="organization-tree-container">
          <TreeView
            classes={{ root: 'organization-tree-root' }}
            aria-label="dynamic tree"
            defaultExpanded={organizationData ? [organizationData.id] : []}
            defaultCollapseIcon={
              <RemoveCircleOutlineIcon className="collpase-icon" />
            }
            defaultExpandIcon={<AddCircleOutlineIcon className="expand-icon" />}
          >
            {organizationData && renderTree(organizationData)}
          </TreeView>
        </Box>
      )}

      <OrganizationNodeDialog
        open={openModal}
        onClose={handleCloseModal}
        type={selectedType}
        nodeData={selectedNode}
        mode={modalMode}
      ></OrganizationNodeDialog>
    </>
  );
};

import { Node } from 'react-flow-renderer';

export interface DiagramModalPayload {
  nodeType?: string;
  entity?: any;
  action?: ActionTemplate;
  actionTemplate?: any;
  connectionId?: any;
}

interface DiagramNodeActions {
  onNewNode?: (parentNodeId: string, payload: DiagramModalPayload) => void;
  onNewCrossroad?: (parentNodeId: string, payload: DiagramModalPayload) => void;
  onRemove?: (nodeId: string) => void;
  onSetParameters?: (parameters: Route[], id: string) => void;
}

export interface DiagramNodeData {
  uuid?: string;
  id: string;
  type: string;
  label: string | undefined;
  actionId?: string;
  actionLabel?: string;
  actions?: DiagramNodeActions;
  actionTemplate?: any;
  parameters?: any;
  routesData?: any;
  onNewNode?: (parentNodeId: string, payload: DiagramModalPayload) => void;
  onNewCrossroad?: (parentNodeId: string, payload: DiagramModalPayload) => void;
  onRemove?: (nodeId: string) => void;
  onSetParameters?: (
    parameters: Route[],
    id: string,
    connectionId?: string
  ) => void;
  editable?: boolean;
  isLeaf?: boolean;
  applicationId?: string;
  workflowSeqId?: string | number;
  wordflowId?: string;
  workflowId?: string;
  newDiagram?: boolean;
  connectionId?: string;
  dragging?: boolean;
  logo: string;
}

export interface Route {
  expA: string;
  expB: string;
  operator: string;
  nextWorkflowSeqId: string;
}

export interface DiagramNode extends Node {
  data: DiagramNodeData;
  dragging?: boolean;
}

export interface Application {
  id: string;
  name: string;
  description: string;
  logo: string;
  baseParameters: string | null;
}

export interface ActionTemplate {
  applicationId: string;
  definition: {
    urlPath: string;
    method: string;
    body: {};
  };
  id: string;
  name: string;
  parameters: [
    {
      name: string;
      displayName: string;
      type: string;
    }
  ];
  resultsExample: [
    {
      records: [
        {
          id: string;
          createdTime: string;
        }
      ];
    }
  ];
  offset: string;
  records: string;
  type: string;
  version: string;
}

export enum CrossroadConditionOperator {
  EQUAL = 'equal',
  NOTEQUAL = 'notequal',
  CONTAINS = 'contains',
  NOTCONTAINS = 'notcontains',
}

import { FunctionComponent, useMemo, useState } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

interface SlaChipProps extends BaseComponentProps {
  slaDate: string | Date;
  isUtc: boolean;
}

export const SlaChip: FunctionComponent<SlaChipProps> = ({
  slaDate,
  isUtc,
}) => {
  const [isOverDue, setIsOverDue] = useState<boolean>(false);

  const timeSinceFindingStr = useMemo(() => {
    if (slaDate) {
      return isUtc
        ? getRemainingTimeFromUtc(slaDate as Date)
        : getRemainingTimeFromSeconds(slaDate as string);
    }
    return '';
  }, [slaDate]);

  function getRemainingTimeFromUtc(date: Date) {
    const currentDate = new Date() as unknown as number;
    const slaTime = new Date(date) as unknown as number;
    const remainingTime = (currentDate - slaTime) / 1000;
    const { days, hours } = extractDaysAndHours(remainingTime);

    return formatDateToLabel(remainingTime, days, hours);
  }

  function extractDaysAndHours(time: number) {
    const hours = Math.floor((time % (3600 * 24)) / 3600);
    const days = Math.floor(time / (3600 * 24));
    return { days, hours };
  }

  function formatDateToLabel(difference: number, days: number, hours: number) {
    let formattedDifference = days ? `${days}d` : ` ${hours}h`;
    if (difference > 0) {
      formattedDifference = `+${formattedDifference}`;
      setIsOverDue(true);
    }
    return formattedDifference;
  }

  function getRemainingTimeFromSeconds(time: string) {
    const remainingTime = parseInt(time) * -1;
    const { days, hours } = extractDaysAndHours(remainingTime);

    return formatDateToLabel(remainingTime, days, hours);
  }

  return (
    <div
      className={`sla-chip-container ${
        isOverDue ? 'sla-chip-overdue' : 'sla-chip-on-time'
      }`}
    >
      <div className="sla-chip-icon">
        <OpusSvgIcon type={SVG_ICON_TYPES.STOP_WATCH_ICON} />
      </div>
      <div className="sla-chip-label">{timeSinceFindingStr}</div>
    </div>
  );
};

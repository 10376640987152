import { TicketCreateFormErrors } from 'shared/models/data/risk-table.model';

export const getInitialTicketFormValidationErrors = (
  translation: any
): TicketCreateFormErrors => ({
  projectKey: 'Project key is required',
  assigneeEmail: 'Assignee email is required',
  notifyTicketAssignee: '',
  ticketType: '',
  ticketApplication: '',
});

import { Box, CssBaseline } from '@mui/material';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthMain from 'Auth/components/Main';
import { useEffect } from 'react';
import authConstants from 'Auth/constants';
import CommonSidebar from 'Common/components/Sidebar';
import './shared/styles/index.scss';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { RouteContainer } from 'shared/components/RouteContainer/RouteContainer';
import { OnboardingModal } from 'Onboarding/components/OnboardingModal/OnboardingModal';
import { machineRoutePrefix } from 'shared/fixtures/data/route-list.data';
import MachingRouteContainer from 'shared/components/MachineRouteContainer';
import { useGetTenantDetailsQuery } from './Auth/store/api';
import { setTenantDetails } from './Auth/store';
import useCommonDispatch from './Common/utils/use-dispatch';

ChartJS.register(...registerables);
ChartJS.register(ChartDataLabels);

ChartJS.defaults.font.family = 'HEX Franklin v0.2';

export default function App() {
  const { data: tenantDetails } = useGetTenantDetailsQuery();

  const dispatch = useCommonDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(setTenantDetails(tenantDetails || null));
  }, [tenantDetails]);

  useEffect(() => {
    const originalRoute = localStorage.getItem(
      authConstants.authOriginalRouteKey
    );
    if (originalRoute) {
      localStorage.removeItem(authConstants.authOriginalRouteKey);
      navigate(originalRoute);
    }
  }, [navigate]);

  const renderRouteContainer = () => {
    if (location.pathname.includes(machineRoutePrefix)) {
      return <MachingRouteContainer />;
    }

    return (
      <>
        <CssBaseline />
        <AuthMain>
          <Box display="flex" minHeight="100vh" minWidth="75rem">
            <CommonSidebar />
            <Box component="main" flex="1">
              <RouteContainer />
            </Box>
            <OnboardingModal />
          </Box>
        </AuthMain>
      </>
    );
  };

  return renderRouteContainer();
}

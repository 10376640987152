import React from 'react';
import { Box, Stack } from '@mui/material';
import { Handle, Position, useStoreApi } from 'react-flow-renderer';
import { NodeProps } from 'WorkflowBuilder/interfaces/node';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { setAfterTool } from 'WorkflowBuilder/store';
import DiagramPlusButton from '../PlusButton';
import { MouseEvent, useState } from 'react';
import { DIAGRAM_STEP_STATUS } from 'WorkflowBuilder/interfaces/step';
import { ReactComponent as StepFailedIcon } from '../../icons/stepFailedIcon.svg';
import { ReactComponent as StepSuccessIcon } from '../../icons/stepSuccessIcon.svg';
import { ReactComponent as StepPendingIcon } from '../../icons/stepPendingIcon.svg';
import { ReactComponent as StepInProgressIcon } from '../../icons/stepInProgressIcon.svg';
import StepControlPopover from '../StepControlPopover';

interface Props {
  data: NodeProps;
}

export function getStatusIcon(status: DIAGRAM_STEP_STATUS) {
  switch (status) {
    case DIAGRAM_STEP_STATUS.DONE:
      return <StepSuccessIcon />;
    case DIAGRAM_STEP_STATUS.FAILED:
      return <StepFailedIcon />;
    case DIAGRAM_STEP_STATUS.PENDING:
      return <StepPendingIcon />;
    case DIAGRAM_STEP_STATUS.IN_PROGRESS:
      return <StepInProgressIcon />;
    default:
      return <StepSuccessIcon />;
  }
}

export default function DiagramToolNode({ data }: Props) {
  const store = useStoreApi();

  const dispatch = useCommonDispatch();
  const { label, icon, isLeaf, seq, readonly } = data;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handlePlusClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleAddToolClick() {
    handleClose();
    dispatch(setAfterTool(seq));
  }

  function handleAddCrossoard() {
    handleClose();

    const nodes: Array<any> = Array.from(store.getState().nodeInternals).map(
      ([, node]) => node
    );

    const previousNode = nodes.find((node) => {
      return node.id === String(seq);
    });
    data.onAddCrossroad && data.onAddCrossroad(previousNode);
  }

  return (
    <Box position="relative">
      <React.Fragment>
        <Handle
          type="target"
          position={Position.Top}
          className="workflow-builder-handle"
          style={{
            top: '-0.5rem',
          }}
        />
        <div
          className={`workflow-builder-tool-node-container ${
            data.status ? 'workflow-builder-tool-node-active' : ''
          }`}
          onClick={() => {
            !data.readonly && data.handleEditNode
              ? data.handleEditNode(seq)
              : data.displayReadonlyStepDetails &&
                data.displayReadonlyStepDetails({
                  applicationId: data.applicationId,
                  actionTemplateId: data.actionTemplateId,
                  results: data.results,
                  parameters: data.params,
                  status: data.status,
                  workflowSeqId: data.seq,
                  stepDefinitionId: data.id,
                });
          }}
        >
          <Stack className="workflow-builder-tool-node-status-icon">
            {data.status && getStatusIcon(data.status)}
          </Stack>
          <div>
            <div className="workflow-builder-tool-node-logo-container">
              <img
                alt=""
                src={icon}
                className="workflow-builder-tool-node-logo"
              />
            </div>
          </div>
          <h5 title={label} className="workflow-builder-tool-node-label">
            {label}
          </h5>
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          className="workflow-builder-handle"
          style={{
            bottom: isLeaf ? '-0.5rem' : '-0.5rem',
          }}
        />
      </React.Fragment>

      {isLeaf && !readonly && (
        <Box className="workflow-builder-tool-node-plus-button">
          <DiagramPlusButton onClick={handlePlusClick} />
          <StepControlPopover
            open={open}
            handleClose={handleClose}
            anchorEl={anchorEl}
            handleCrossroadClick={handleAddCrossoard}
            handleToolClick={handleAddToolClick}
          />
        </Box>
      )}
    </Box>
  );
}

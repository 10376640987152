import { Box } from '@mui/system';
import { useRef } from 'react';
import { Handle, Position, useStore } from 'react-flow-renderer';
import { ReactComponent as CrossroadButtonIcon } from '../../icons/crossroadButtonIcon.svg';
import styles from './CrossroadNode.module.css';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface CrossroadNodeProps {
  data: any;
}

export function CrossroadNode({ data }: CrossroadNodeProps) {
  const crossroadNodeRef = useRef<HTMLDivElement>(null);

  const diagramState = useStore();

  const onDisplayCrossroadPanel = () => {
    const boundingClientDimensions =
      crossroadNodeRef.current?.getBoundingClientRect();

    if (boundingClientDimensions) {
      data.displayCrossroadPanel &&
        data.displayCrossroadPanel(
          getDynamicCrossroadPanelPosition(),
          data.seq,
          data
        );
    }
  };

  const getDynamicCrossroadPanelPosition = () => {
    const crossroadNodeBoundingDimensions =
      crossroadNodeRef.current?.getBoundingClientRect();

    const crossroadPanelPosition = {
      top:
        (crossroadNodeBoundingDimensions?.top as number) -
        (window.innerHeight - diagramState.height),
      left:
        (crossroadNodeBoundingDimensions?.left as number) -
        (window.innerWidth - diagramState.width) +
        (crossroadNodeBoundingDimensions?.width as number) +
        10,
      right: 0,
      bottom: 0,
    };

    if (
      crossroadPanelPosition.top >=
      diagramState.height - window.innerHeight / 2
    ) {
      crossroadPanelPosition.top = 0;
      crossroadPanelPosition.bottom = 20;
    }

    if (crossroadPanelPosition.top < 0) {
      crossroadPanelPosition.top = 10;
      crossroadPanelPosition.bottom = 0;
    }

    if (crossroadPanelPosition.left >= diagramState.width - 480) {
      crossroadPanelPosition.right = 10;
      crossroadPanelPosition.left = 0;
    }

    return crossroadPanelPosition;
  };
  return (
    <Box position="relative" id={data.label}>
      <Handle
        type="target"
        position={Position.Top}
        className="workflow-builder-handle"
        style={{ top: '-6px' }}
      ></Handle>

      <div
        className="workflow-builder-crossroad-node-icon-container"
        onClick={() => {
          onDisplayCrossroadPanel();
        }}
        ref={crossroadNodeRef}
      >
        <OpusSvgIcon type={SVG_ICON_TYPES.SITEMAP_ICON} />
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        className="workflow-builder-handle"
        style={{ bottom: '-6px' }}
      ></Handle>
    </Box>
  );
}

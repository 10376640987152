import { CircularProgress, Stack } from '@mui/material';
import { ChartEvent, ChartOptions, ChartType } from 'chart.js';
import useCommonSelector from 'Common/utils/use-selector';
import { selectdashboardFilter } from 'Dashboard/store';
import { Fragment, FunctionComponent, useEffect, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { ChartsHandler } from 'shared/handlers/charts.handler';
import DashboardFilterHandler from 'shared/handlers/dashboard-filter.handler';
import { ChartData } from 'shared/models/data/chart.model';
import { BaseChartProps } from 'shared/models/props/chart-props.model';
import BaseChartContainer from '../BaseChartContainer';
import BaseChartLegendContainer from '../BaseChartLegendContainer';
import FormattedMessage from '../FormattedMessage';

export const BaseChart: FunctionComponent<
  BaseChartProps<ChartData, ChartType, ChartOptions>
> = ({
  isWidgetDataLoading,
  widgetData,
  fetchWidgetData,
  type = 'bar',
  options = {},
  style = {},
  applyColorList = false,
  legendPosition = 'top',
  displayLegend = false,
  chartId = '',
  customLegendContainerId,
  dynamicHeight,
  chartRef,
  plugins = [],
  onElementClick,
}) => {
  const dashboardFilter = useCommonSelector(selectdashboardFilter);

  const parsedDashboardFilter = useMemo(() => {
    return DashboardFilterHandler.transformFilterOptionsToListOfStrings(
      dashboardFilter
    );
  }, [dashboardFilter]);

  useEffect(() => {
    fetchWidgetData && fetchWidgetData(parsedDashboardFilter);
  }, [parsedDashboardFilter]);

  const renderChartContent = () => {
    if (isWidgetDataLoading) {
      return (
        <Stack
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Stack>
      );
    }

    if (
      (!isWidgetDataLoading && !widgetData) ||
      (widgetData && !widgetData.datasets.length)
    ) {
      return (
        <Stack
          height="100%"
          width="100%"
          justifyContent="center"
          alignItems="center"
          className="no-data-container"
        >
          <FormattedMessage id="common.noData" defaultMessage="No data" />
        </Stack>
      );
    }

    if (widgetData) {
      const transformedWidgetData = ChartsHandler.getWidgetDataAndApplyStyle(
        widgetData,
        style,
        applyColorList
      );

      return (
        <BaseChartContainer
          height={
            dynamicHeight
              ? `${50 + widgetData.labels?.length * 30}px`
              : undefined
          }
          legendPosition={legendPosition}
        >
          <Chart
            ref={chartRef}
            className="base-chart"
            type={type}
            options={{
              ...options,
              onClick: (event, element) => {
                if (element.length > 0 && onElementClick) {
                  const firstPoint = element[0];
                  const label = widgetData.labels[firstPoint.index];
                  const value =
                    widgetData.datasets[firstPoint.datasetIndex].data[
                      firstPoint.index
                    ];

                  onElementClick(String(value), label);
                }
              },
              onHover: (event: any, chartElement) => {
                event.native.target.style.cursor =
                  chartElement[0] && onElementClick ? 'pointer' : 'default';
              },
            }}
            data={transformedWidgetData}
            plugins={[ChartsHandler.getHtmlLegendPlugin(), ...plugins]}
          />
          {displayLegend && !isWidgetDataLoading ? (
            <BaseChartLegendContainer
              id={
                customLegendContainerId || `chart-legend-container-${chartId}`
              }
              display={displayLegend}
              legendPosition={legendPosition}
            />
          ) : (
            <></>
          )}
        </BaseChartContainer>
      );
    }
  };

  return <Fragment>{renderChartContent()}</Fragment>;
};

export enum TenantPlans {
  Freemium = 'freemium',
  Enterprise = 'enterprise',
}

export enum OnboardingStatus {
  NotStarted = 'NotStarted',
  Finished = 'Finished',
}

export interface TenantDetails {
  plan: TenantPlans;
  status?: OnboardingStatus;
}

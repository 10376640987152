import { createContext } from 'react';
import { ApplicationPermission } from '../../enums/permission.enum';

interface PermissionContextProps {
  permissions?: Array<ApplicationPermission>;
}

export const PermissionContext = createContext<PermissionContextProps>({
  permissions: [],
});

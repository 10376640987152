import Diagram from '../../../WorkflowBuilder/components/Builder';
import { ReactFlowProvider } from 'react-flow-renderer';
import {
  Paper,
  Stack,
  Box,
  Typography,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import styles from './FindingWorkflow.module.css';
import { ReactComponent as CloseIcon } from '../icons/closeIcon.svg';
import { useEffect, useRef, useState } from 'react';
import JsonView from 'Common/components/JsonView';
import DiagramStep, {
  DIAGRAM_STEP_STATUS,
} from 'WorkflowBuilder/interfaces/step';
import { NodeProps } from '../../../WorkflowBuilder/interfaces/node';
import FindingCrossroadPanel from './FindingCrossroadPanel';
import { isPathActive } from '../../../WorkflowBuilder/utils/diagram-fns';
import {
  useFetchFindingWorkflowDetailsMutation,
  useGetFindingWorkflowStepParametersMutation,
} from 'FindingDetails/store/api';
import { getFindingSelectedWorkflowState } from 'FindingDetails/store';
import useCommonSelector from 'Common/utils/use-selector';
import transformData from '../../hooks/useFetchWorkflowWithSteps';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useTranslation } from 'react-i18next';
import { LAYOUT_MODE } from 'WorkflowBuilder/interfaces/layout';

interface FindingWorkflowProps {
  children?: any;
  findingId: number | string;
}

export interface DisplayStepDetaislArgs {
  applicationId?: string;
  actionTemplateId?: string;
  results?: any;
  parameters?: any;
  status?: DIAGRAM_STEP_STATUS;
  workflowSeqId?: number;
  stepDefinitionId?: string;
}

export const FindingWorkflow = ({ findingId }: FindingWorkflowProps) => {
  const reactFlowRef = useRef();

  const { t: translation } = useTranslation();

  const selectedWorkflowId = useCommonSelector(getFindingSelectedWorkflowState);
  const [findingWorkflowDetailsLoading, setFindingWorkflowDetailsLoading] =
    useState<boolean>(false);
  const [workflowDefinitionSteps, setWorkflowDefinitionSteps] = useState<any>(
    []
  );
  const [workflowSteps, setWorkflowSteps] = useState<any>([]);
  const [displayPanel, setDisplayPanel] = useState<{
    open: boolean;
    action: boolean;
    crossroad: boolean;
  }>({
    open: false,
    action: false,
    crossroad: false,
  });

  const [panelConfiguration, setPanelConfiguration] =
    useState<DisplayStepDetaislArgs>({});
  const [crossroadConfig, setCrossroadConfig] = useState<NodeProps>({
    label: '',
    icon: '',
    seq: 0,
    isLeaf: false,
  });

  const [
    fetchFindingWorkflowStepParameters,
    {
      data: stepParameterData,
      isLoading: stepParameterLoading,
      reset: resetFindingWorkflowStepParameters,
    },
  ] = useGetFindingWorkflowStepParametersMutation();

  const displayStepDetails = (args: any) => {
    setDisplayPanel({
      open: true,
      action: true,
      crossroad: false,
    });
    setPanelConfiguration(args);
  };

  const displayCrossroadPanel = (
    position: { top: number; left: number; bottom: number; right: number },
    stepId: number,
    readOnlyData: NodeProps
  ) => {
    const isRunByWorkflow = !!(readOnlyData?.results?.length > 0);
    setDisplayPanel({
      open: isRunByWorkflow,
      action: false,
      crossroad: isRunByWorkflow,
    });

    setCrossroadConfig(readOnlyData);
  };

  const [
    getWorkflowDetails,
    { data: findingWorkflowDetails, isLoading: isWorkflowLoading },
  ] = useFetchFindingWorkflowDetailsMutation();

  const transformDataToDiagramSteps = (data: any) => {
    const {
      workflowSteps,
      workflowDefinitionSteps,
      findingWorkflowDetailsLoading,
    } = transformData({
      findingWorkflowDetails: data.data,
      findingWorkflowDetailsLoading: isWorkflowLoading,
      displayStepDetails: displayStepDetails,
    });

    setWorkflowDefinitionSteps(workflowDefinitionSteps);
    setWorkflowSteps(workflowSteps);
    setFindingWorkflowDetailsLoading(findingWorkflowDetailsLoading);
  };

  useEffect(() => {
    if (selectedWorkflowId) {
      (async () => {
        const response = await getWorkflowDetails({
          findingId: findingId,
          workflowId: selectedWorkflowId,
        });
        transformDataToDiagramSteps(response);
      })();
    }
  }, [selectedWorkflowId]);

  useEffect(() => {
    if (panelConfiguration.stepDefinitionId) {
      fetchFindingWorkflowStepParameters({
        findingId,
        stepDefinitionId: panelConfiguration.stepDefinitionId as string,
      });
    } else {
      resetFindingWorkflowStepParameters();
    }
  }, [panelConfiguration.stepDefinitionId]);

  const renderPanelHeader = () => {
    return displayPanel.open && displayPanel.action ? (
      <>
        <OpusSvgIcon type={SVG_ICON_TYPES.SQUARE_INFO_ICON} />
        <h2>{translation(`findings.details.actionDetails`)}</h2>
      </>
    ) : (
      <>
        <OpusSvgIcon
          type={SVG_ICON_TYPES.SITEMAP_ICON}
          className="finding-workflow-crossroad-icon"
        />
        <h2>{translation(`findings.details.crossroadDetails`)}</h2>
      </>
    );
  };

  const renderPanelDetails = () => {
    let item =
      displayPanel.open && displayPanel.action
        ? {
            title: stepParameterData?.actionTemplate?.name,
            content: stepParameterData?.application?.name,
            logo: stepParameterData?.application?.logo,
            id: panelConfiguration?.workflowSeqId,
          }
        : {
            title: crossroadConfig?.label,
            id: crossroadConfig?.seq,
          };
    return !stepParameterLoading ? (
      <>
        <div className="finding-workflow-action-details-parameters">
          {item.logo ? (
            <div className="finding-workflow-action-details-parameters-logo">
              <img src={item.logo} alt={item.title} />
            </div>
          ) : (
            <></>
          )}

          <div className="finding-workflow-action-details-parameters-text">
            <p className="active-tool-title">{item.title}</p>
            {item.content ? (
              <p className="active-tool-content">{item.content}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="finding-workflow-action-details-indicator">
          #{item.id}
        </div>
      </>
    ) : (
      <Stack>
        <CircularProgress />
      </Stack>
    );
  };

  return findingWorkflowDetailsLoading ? (
    <Stack className={styles.findingWorkflowContainer}>
      <Backdrop
        open={findingWorkflowDetailsLoading}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'absolute',
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Stack>
  ) : (
    <Stack className={styles.findingWorkflowContainer}>
      {displayPanel.open && (
        <Box
          component={Paper}
          className="finding-workflow-panel-container"
          flexDirection="column"
          padding={'2rem'}
          gap={2}
          alignItems="end"
          justifyContent="space-around"
        >
          <Stack
            className="finding-workflow-panel-header"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              flexDirection="row"
              alignItems="center"
              alignContent={'center'}
              gap={1}
            >
              {renderPanelHeader()}
            </Stack>
            <Stack
              className="finding-workflow-panel-header-close-icon"
              onClick={() => {
                setDisplayPanel({
                  open: false,
                  action: false,
                  crossroad: false,
                });
                setPanelConfiguration({});
              }}
            >
              <CloseIcon />
            </Stack>
          </Stack>
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            className="finding-workflow-panel-action-details"
          >
            {renderPanelDetails()}
          </Stack>
          {displayPanel.action && (
            <>
              <Stack className="finding-workflow-panel-body">
                <Stack className="finding-workflow-panel-body-parameters">
                  {panelConfiguration?.parameters?.map((parameter: any) => {
                    return (
                      <Box className="finding-workflow-panel-body-parameter">
                        <Box className="finding-workflow-panel-body-parameter-title">
                          <p>
                            {typeof parameter?.displayName !== 'string'
                              ? JSON.stringify(parameter?.displayName)
                              : parameter?.displayName}
                          </p>
                        </Box>
                        <Box className="finding-workflow-panel-body-parameter-value">
                          {typeof parameter?.value !== 'string'
                            ? JSON.stringify(parameter?.value)
                            : parameter?.value}
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
                <Stack className="finding-workflow-panel-results">
                  <Box>
                    <Typography>Results</Typography>
                    <Box className={styles.findingWorkflowPanelResultsJson}>
                      <JsonView data={panelConfiguration.results || {}} />
                    </Box>
                  </Box>
                </Stack>
              </Stack>
            </>
          )}
          {displayPanel.crossroad && (
            <Stack className="finding-workflow-panel-body">
              <FindingCrossroadPanel
                isPathActive={(nextWorkflowId) => {
                  return isPathActive(nextWorkflowId, workflowSteps);
                }}
                data={crossroadConfig}
              ></FindingCrossroadPanel>
            </Stack>
          )}
        </Box>
      )}

      <ReactFlowProvider>
        <Backdrop
          open={isWorkflowLoading}
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: 'absolute',
          }}
        >
          <CircularProgress color="primary" />
        </Backdrop>

        <Diagram
          steps={
            workflowSteps?.length
              ? workflowSteps?.map((step: DiagramStep) => ({
                  ...step,
                  diagramData: {
                    ...step.diagramData,
                    displayCrossroadPanel: displayCrossroadPanel,
                  },
                }))
              : []
          }
          stepDefs={workflowDefinitionSteps || []}
          flowRef={reactFlowRef}
          mode={LAYOUT_MODE.VIEW}
        />
      </ReactFlowProvider>
    </Stack>
  );
};

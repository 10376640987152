import { Skeleton } from '@mui/material';
import CopyButton from 'Common/components/CopyButton';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { useGetFindingSourceContentQuery } from 'FindingDetails/store/api';
import RiskLifecycleCell from 'Risk/components/RiskLifecycleCell';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import ContentSection from 'shared/components/ContentSection';
import FindingItem from 'shared/components/FindingItem';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import { FindingLayer } from 'shared/enums/findings.enum';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseFindingComponentProps } from 'shared/models/props/base-finding-component-props.model';

interface FindingContentSourceInformationProps
  extends BaseFindingComponentProps {}

export const FindingContentSourceInformation: FunctionComponent<
  FindingContentSourceInformationProps
> = ({ findingId }) => {
  const { t: translation } = useTranslation();
  const [searchParams] = useQueryParams();

  const {
    data: findingContentSourceInformationData,
    isLoading: findingContentSourceInformationDataLoading,
    error: findingContentSourceInformationDataError,
  } = useGetFindingSourceContentQuery(findingId || searchParams.openFindingId);

  const renderSectionBody = () => {
    if (findingContentSourceInformationDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={60} width={'100%'} />
        </div>
      );
    }

    if (
      findingContentSourceInformationData === undefined ||
      findingContentSourceInformationDataError
    ) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-source-information-section-body finding-content-section-row">
        <FindingItem title={translation(`findings.subSections.sensorName`)}>
          <InteractiveLabel
            icon={
              <div className="finding-content-application-icon">
                <img src={findingContentSourceInformationData?.appLogo}></img>
              </div>
            }
            label={
              findingContentSourceInformationData?.findingSource ||
              findingContentSourceInformationData?.appName
            }
          />
        </FindingItem>
        <FindingItem title={translation(`findings.subSections.severity`)}>
          <CommonSeverityChip
            severityNumber={findingContentSourceInformationData.severity}
          />
        </FindingItem>
        <FindingItem
          title={translation(`findings.subSections.sensorFindingId`)}
        >
          {findingContentSourceInformationData?.sensorLink ? (
            <div className="finding-content-source-information-sensor-finding-id-container">
              <a
                href={findingContentSourceInformationData?.sensorLink}
                target="_blank"
              >
                {findingContentSourceInformationData?.externalId}
              </a>
              <CopyButton
                textToCopy={
                  findingContentSourceInformationData?.sensorLink ||
                  findingContentSourceInformationData?.externalId
                }
              />
            </div>
          ) : (
            <p>
              {findingContentSourceInformationData?.externalId ||
                translation(`common.missingValue`)}
            </p>
          )}
        </FindingItem>
        <FindingItem title={translation(`findings.subSections.sdlc`)}>
          <RiskLifecycleCell
            artifactCount={
              findingContentSourceInformationData?.layer ===
              FindingLayer.ARTIFACT
                ? 1
                : 0
            }
            codeCount={
              findingContentSourceInformationData?.layer === FindingLayer.CODE
                ? 1
                : 0
            }
            runtimeCount={
              findingContentSourceInformationData?.layer ===
              FindingLayer.RUNTIME
                ? 1
                : 0
            }
          />
        </FindingItem>
      </div>
    );
  };

  return (
    <div
      className="finding-content-item finding-content-source-information-container"
      id="affected-packages"
    >
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.SENSOR_ICON} />}
        title={translation(`findings.sections.sourceInformation`)}
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

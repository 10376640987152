import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import { OpusSvgIconProps } from 'shared/icons/interface';
import { IconFactory } from '../../../icons/utils';
import IconWrapper from '../IconWrapper';
import _ from 'lodash';

const iconFactory = new IconFactory();

interface OpusIconProps extends OpusSvgIconProps {
  children?: JSX.Element;
  title?: string;
  type: ApplicationTypes;
}

export const OpusSvgAppIcon = (props: OpusIconProps): JSX.Element => {
  const { title, type } = props;

  return (
    <IconWrapper title={title}>
      {iconFactory.getSvgIconByAppType(
        type,
        _.omit(props, ['title', 'children', 'type'])
      )}
    </IconWrapper>
  );
};

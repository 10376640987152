export interface VulnerabilityMetadata {
  id: string;
  description: string;
  scores: VulnerabilityMetadataScores;
  timeline: any[];
  mostAffectedVendors: string[];
  threats: VulnerabilityMetadataThreat[];
  threatActors: VulnerabilityMetadataThreatActor[];
  weaknesses: VulnerabilityMetadataWeakness[];
  discussions: VulnerabilityMetadataDiscussion[];
  references: VulnerabilityMetadataReferences;
}

export interface VulnerabilityCvssV3 {
  exploitabilityMetrics: {
    score: number;
    vector: string;
    attackVector: string;
    attackComplexity: string;
    privilegesRequired: string;
    userInteraction: string;
    scope: string;
  };
  impactMetrics: {
    confidentialityImpact: string;
    integrityImpact: string;
    availabilityImpact: string;
  };
  temporalMetrics: {
    vector: string;
    exploitCodeMaturity: string;
    remediationLevel: string;
    reportConfidence: string;
  };
}

export interface VulnerabilityMetadataScores {
  riskIndex: number;
  lastModifiedDate: Date;
  indicators: {
    isTrending: boolean;
    lastTrendingDate: Date;
    cisaKev: boolean;
    isPrioritized: boolean;
  };
  predictive: {
    discussionsCount: number;
    pocCount: number;
    pentesterFrameworkCount: number;
    inTheWildCount: number;
  };
  threats: {
    malwareCount: number;
    exploitCount: number;
    threatsActorCount: number;
    ransomwareCount: number;
  };
  patches: {
    workaroundsCount: number;
    patchesCount: number;
    patchUpgradeCount: number;
  };
  cvssv3: VulnerabilityCvssV3;
  epss: {
    probability: number;
    lastModifiedDate: Date;
    percentile: string;
    last30DaysTrend?: string; // TODO: next phase
  };
}
export interface VulnerabilityMetadataThreat {
  title: string;
  aliases: string[];
  tags: string[];
  publishedDate: Date;
  sources: {
    label: string;
    url: string;
  }[];
}
export interface VulnerabilityMetadataThreatActor {
  name: string;
  associatedGroups: string[];
}
export interface VulnerabilityMetadataWeakness {
  id: string;
  type: string;
  title: string;
}
export interface VulnerabilityMetadataDiscussion {
  publishedDate: Date;
  type: string;
  source: {
    label: string;
    url: string;
  };
  content: string;
}

export enum VulnerabilityMetadataReferenceType {
  CVE = 'cve',
  EXPLOITS = 'exploits',
  MALWARES = 'malwares',
  PATCHES = 'patches',
}
export type VulnerabilityMetadataReferences = Record<
  VulnerabilityMetadataReferenceType,
  Array<string>
>;

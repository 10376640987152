import { Box, Grid, Typography } from '@mui/material';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import SideMenuNavigation from 'Integrations/Common/SideMenuNavigation';
import IntegrationItemCard from 'Integrations/Common/IntegrationItemCard';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { useEffect, useMemo, useState } from 'react';
import CardWrapper from 'Common/components/CardWrapper';
import { generateMenuList } from 'Integrations/fixtures/menu-list-items';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import IntegrationsListModal from '../IntegrationsListModal';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import { IntegrationModal } from 'Integrations/Connection/components/IntegrationModal/IntegrationModal';
import { keepOpenIntegrationCategories } from 'shared/fixtures/data/integrations.data';

interface IntegrationListProps {
  integrationList: Array<IntegrationItem>;
}

export default function IntegrationList({
  integrationList,
}: IntegrationListProps) {
  const userPermissions = usePermissions();
  const [activeMenuItem, setActiveMenuItem] = useState('All');
  const [queryParams, updateQueryParams] = useQueryParams();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [activeConnectionId, setActiveConnectionId] = useState<string>();
  const [selectedIntegration, setSelectedIntegration] = useState<
    IntegrationItem | undefined
  >();

  const hasIntegrationsReadPermission = userPermissions.includes(
    ApplicationPermission.INTEGRATIONS_READ
  );

  const orderedCategories = [
    IntegrationCategory.CLOUD_PROVIDER,
    IntegrationCategory.FINDINGS_SOURCE,
    IntegrationCategory.SCM,
    IntegrationCategory.TICKETING,
    IntegrationCategory.MESSAGING,
  ];

  const groupedIntegrationsList = useMemo(() => {
    const grouped: Record<IntegrationCategory, IntegrationItem[]> = {
      [IntegrationCategory.CLOUD_PROVIDER]: [],
      [IntegrationCategory.FINDINGS_SOURCE]: [],
      [IntegrationCategory.SCM]: [],
      [IntegrationCategory.TICKETING]: [],
      [IntegrationCategory.MESSAGING]: [],
    };
    const otherCategory: Record<string, IntegrationItem[]> = {};

    integrationList.forEach((item) => {
      const category = item.category || 'Other';
      if (orderedCategories.includes(category as any)) {
        grouped[category as IntegrationCategory].push(item);
      } else {
        otherCategory[category] = otherCategory[category] || [];
        otherCategory[category].push(item);
      }
    });

    const sortedGrouped: Record<IntegrationCategory, IntegrationItem[]> = {
      ...grouped,
    };

    Object.keys(otherCategory).forEach((category) => {
      sortedGrouped[category as IntegrationCategory] = otherCategory[category];
    });

    return sortedGrouped as any;
  }, [integrationList]);

  useEffect(() => {
    if (groupedIntegrationsList)
      setIntegrationsItemList(groupedIntegrationsList);
  }, [groupedIntegrationsList]);

  const [integrationsItemList, setIntegrationsItemList] = useState(
    groupedIntegrationsList
  );

  const [integrationModalOpen, setIntegrationModalOpen] =
    useState<boolean>(false);

  const filterIntegrationBySelectedMenuItem = (selected: any) => {
    if (selected === 'All') {
      setIntegrationsItemList(groupedIntegrationsList);
    } else {
      setIntegrationsItemList({
        [selected]: groupedIntegrationsList[selected],
      });
    }
  };

  useEffect(() => {
    filterIntegrationBySelectedMenuItem(activeMenuItem);
  }, [activeMenuItem]);

  const handleMenuItemClick = (selectedMenuItem: string) => {
    setActiveMenuItem(selectedMenuItem);
    updateQueryParams({ menu: selectedMenuItem });
  };

  useEffect(() => {
    const activeMenu = queryParams['menu'];
    if (activeMenu) {
      setActiveMenuItem(activeMenu);
      filterIntegrationBySelectedMenuItem(activeMenu);
    }
  }, []);

  if (!hasIntegrationsReadPermission) return <ForbiddenPage />;

  const menuList = generateMenuList(activeMenuItem, handleMenuItemClick);

  function handleTableDialogOpen() {
    setDialogOpen(true);
  }

  function handleTableDialogClose() {
    setDialogOpen(false);
  }

  const handleCreateModalOpen = () => {
    setIntegrationModalOpen(true);
  };

  const handleCreateModalClose = () => {
    setActiveConnectionId(undefined);
    setIntegrationModalOpen(false);
  };

  const onIntegrationClick = (integration: IntegrationItem) => {
    setSelectedIntegration(integration);

    if (integration.count === 0) {
      handleCreateModalOpen();
    } else {
      handleTableDialogOpen();
    }
  };

  const handleCreateSuccess = (id: string) => {
    if (
      keepOpenIntegrationCategories.includes(
        selectedIntegration?.category as IntegrationCategory
      )
    ) {
      setActiveConnectionId(id);
      setIntegrationModalOpen(true);
    } else {
      handleCreateModalClose();
    }
  };

  return (
    <>
      <Box className="integrations-page-box">
        <Grid container className="integrations-page-container">
          <Grid item xs={3} className="integrations-page-side-menu-container">
            <SideMenuNavigation menuList={menuList} />
          </Grid>
          <Grid item xs={9} className="integrations-page-items-container">
            <CardWrapper className="integrations-container">
              {Object.entries(integrationsItemList).map(
                ([category, applications]) => (
                  <div key={category} className="category-section">
                    <p className="category-section-header">{category}</p>
                    <div className="category-section-applications">
                      {(applications as IntegrationItem[]).map(
                        (application) => (
                          <div
                            className="category-section-application-card"
                            key={application.id}
                          >
                            <IntegrationItemCard
                              integration={application}
                              onItemClick={() =>
                                onIntegrationClick(application)
                              }
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )
              )}
            </CardWrapper>
          </Grid>
        </Grid>
      </Box>

      <IntegrationsListModal
        open={dialogOpen}
        onClose={handleTableDialogClose}
        closeButtonText={'Cancel'}
        saveButtonText={'Add Connection'}
        integration={selectedIntegration}
        title={selectedIntegration?.name || ''}
        onSave={() => {}}
        icon={
          <OpusImageIcon
            src={selectedIntegration?.logo || ''}
            className="integration-logo"
          ></OpusImageIcon>
        }
        buttonRequiredPermissions={[ApplicationPermission.INTEGRATIONS_WRITE]}
        children={undefined}
      />

      {selectedIntegration ? (
        <IntegrationModal
          connectionId={activeConnectionId}
          integrationItem={selectedIntegration}
          open={integrationModalOpen}
          onCancel={handleCreateModalClose}
          onCreateSuccess={handleCreateSuccess}
        />
      ) : (
        <></>
      )}
    </>
  );
}

import { TreeItem, TreeView } from '@mui/x-tree-view';
import { useGetWorkflowStepTokensByStepDefIdMutation } from 'Flow/store/api';
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import FormattedMessage from '../FormattedMessage';
import { CircularProgress } from '@mui/material';
import { useOnScreen } from 'shared/hooks/useOnScreen';

interface TokenInputPanelStepTreeProps extends BaseComponentProps {
  id: string;
  seqId: number;
  icon: string;
  title: string;
  selectStepTokenHandler: (id: string) => void;
}

export const TokenInputPanelStepTree: FunctionComponent<
  TokenInputPanelStepTreeProps
> = ({ id: stepDefId, selectStepTokenHandler, title, icon, seqId }) => {
  const [expanded, setExpanded] = useState<string[]>([]);

  const [parentExpanded, setParentExpanded] = useState<string[]>([]);

  const treeRef = useRef<HTMLUListElement>(null);

  const isVisible = useOnScreen(treeRef);

  const handleToggle = (event: any, nodeIds: string[]) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      setExpanded(nodeIds);
    }
  };

  const handleParentToggle = (event: any, nodeIds: string[]) => {
    if (event.target.closest('.MuiTreeItem-iconContainer')) {
      setParentExpanded(nodeIds);
    }
  };

  const [getStepTokens, { data: stepTokens, isLoading: stepTokensLoading }] =
    useGetWorkflowStepTokensByStepDefIdMutation();

  useEffect(() => {
    if (
      stepDefId &&
      isVisible &&
      parentExpanded.includes(stepDefId) &&
      !stepTokens
    ) {
      getStepTokens(stepDefId);
    }
  }, [stepDefId, isVisible, parentExpanded]);

  const renderApiContent = (
    isLoading: boolean,
    data: any,
    renderContent: () => ReactNode
  ) => {
    if (isLoading)
      return <CircularProgress size={25} sx={{ marginTop: '10px' }} />;

    if ((!isLoading && !data) || (!isLoading && data?.length === 0))
      return <FormattedMessage id="common.noData" defaultMessage="No data" />;

    return renderContent();
  };

  const renderResultTree = (
    result: any,
    parentKey = `step.${seqId}.results`
  ) => {
    const isResultArray = Array.isArray(result);

    if (
      isResultArray &&
      result.every((resultItem: any) => typeof resultItem === 'string')
    ) {
      return result.map((resultItem: string, index: number) => {
        const itemId =
          parentKey.length > 0 ? `${parentKey}.${index}` : `${index}`;

        return (
          <TreeItem
            className="token-input-panel-tree-item"
            nodeId={itemId}
            id={itemId}
            label={`${index}`}
            collapseIcon={<></>}
            expandIcon={<></>}
            ContentComponent={() => {
              const label = `${index}`;
              const subLabel = resultItem;
              return (
                <div className="token-input-panel-step-sub-branch-header">
                  <div
                    className="token-input-panel-step-sub-branch-header-label"
                    title={label}
                    onClick={() => {
                      selectStepTokenHandler(itemId);
                    }}
                  >
                    {label}
                  </div>
                  <div
                    className="token-input-panel-step-sub-branch-header-sub-label"
                    title={subLabel}
                  >
                    {subLabel}
                  </div>
                </div>
              );
            }}
          >
            <></>
          </TreeItem>
        );
      });
    }

    const itemToBeProcessed = isResultArray ? result[0] || {} : result;

    return (
      <>
        {Object.keys(itemToBeProcessed).map((objectKey) => {
          const itemId =
            parentKey.length > 0 ? `${parentKey}.${objectKey}` : objectKey;

          const isObject =
            itemToBeProcessed[objectKey] &&
            typeof itemToBeProcessed[objectKey] === 'object';

          const hasKeys =
            isObject && Object.keys(itemToBeProcessed[objectKey])?.length > 0;

          const isItemArray = Array.isArray(itemToBeProcessed[objectKey]);

          return (
            <TreeItem
              className="token-input-panel-tree-item"
              nodeId={itemId}
              id={itemId}
              label={`${objectKey}${isItemArray ? ' [ ]' : ''}}`}
              collapseIcon={
                hasKeys ? (
                  <OpusSvgIcon type={SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON} />
                ) : (
                  <></>
                )
              }
              expandIcon={
                hasKeys ? (
                  <OpusSvgIcon type={SVG_ICON_TYPES.CARET_BOLD_RIGHT_ICON} />
                ) : (
                  <></>
                )
              }
              ContentComponent={() => {
                const label = `${objectKey}${isItemArray ? ' [ ]' : ''}`;
                const subLabel = isObject ? '' : itemToBeProcessed[objectKey];
                return (
                  <div className="token-input-panel-step-sub-branch-header">
                    {hasKeys ? (
                      <div
                        className="token-input-panel-step-sub-branch-header-expand-icon"
                        onClick={() => {
                          setExpanded((previousExpanded) => {
                            if (previousExpanded.includes(itemId)) {
                              return previousExpanded.filter(
                                (id) => id !== itemId
                              );
                            }

                            return [...previousExpanded, itemId];
                          });
                        }}
                      >
                        <OpusSvgIcon
                          type={
                            expanded.includes(itemId)
                              ? SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON
                              : SVG_ICON_TYPES.CARET_BOLD_RIGHT_ICON
                          }
                        />
                      </div>
                    ) : (
                      <></>
                    )}

                    <div
                      className="token-input-panel-step-sub-branch-header-label"
                      title={label}
                      onClick={() => {
                        selectStepTokenHandler(itemId);
                      }}
                    >
                      {label}
                    </div>
                    <div
                      className="token-input-panel-step-sub-branch-header-sub-label"
                      title={subLabel}
                    >
                      {subLabel}
                    </div>
                  </div>
                );
              }}
            >
              {isObject ? (
                renderResultTree(itemToBeProcessed[objectKey], itemId)
              ) : (
                <></>
              )}
            </TreeItem>
          );
        })}
      </>
    );
  };

  const renderStepTokens = () => {
    if (Object.keys(stepTokens)?.length === 0) {
      return <FormattedMessage id="common.noData" defaultMessage="No data" />;
    }

    return (
      <TreeView
        expanded={expanded}
        onNodeToggle={handleToggle}
        className="token-input-panel-tree-view"
      >
        {renderResultTree(stepTokens)}
      </TreeView>
    );
  };

  return (
    <TreeView
      expanded={parentExpanded}
      onNodeToggle={handleParentToggle}
      ref={treeRef}
    >
      <TreeItem
        nodeId={stepDefId}
        expandIcon={<OpusSvgIcon type={SVG_ICON_TYPES.CARET_BOLD_RIGHT_ICON} />}
        collapseIcon={
          <OpusSvgIcon type={SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON} />
        }
        icon={<img className="token-input-panel-step-icon" src={icon}></img>}
        label={title}
        className="token-input-panel-step-main-branch"
        ContentComponent={() => {
          return (
            <div className="token-input-panel-step-main-branch-header">
              <div
                className="token-input-panel-step-main-branch-header-expand-icon"
                onClick={() => {
                  setParentExpanded((previousParentExpanded) => {
                    if (previousParentExpanded.includes(stepDefId)) {
                      return previousParentExpanded.filter(
                        (id) => id !== stepDefId
                      );
                    }

                    return [...previousParentExpanded, stepDefId];
                  });
                }}
              >
                <OpusSvgIcon
                  type={
                    parentExpanded.includes(stepDefId)
                      ? SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON
                      : SVG_ICON_TYPES.CARET_BOLD_RIGHT_ICON
                  }
                />
              </div>
              <div className="token-input-panel-step-main-branch-header-icon">
                <img src={icon}></img>
              </div>
              <div
                className="token-input-panel-step-main-branch-header-title"
                title={title}
              >
                {title}
              </div>
              <div className="token-input-panel-step-main-branch-header-id">
                ID {seqId}
              </div>
            </div>
          );
        }}
      >
        {renderApiContent(stepTokensLoading, stepTokens, renderStepTokens)}
      </TreeItem>
    </TreeView>
  );
};

import { Box, Skeleton, Tooltip } from '@mui/material';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { useGetFindingRiskScoreBreakdownQuery } from 'FindingDetails/store/api';
import RiskChip from 'Risk/components/RiskChip';
import { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import BusinessImpactChip from 'shared/components/BusinessImpactChip';
import CommonSeverityChip from 'shared/components/CommonSeverityChip';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseFindingComponentProps } from 'shared/models/props/base-finding-component-props.model';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import { SimpleTag } from 'shared/components/SimpleTag/SimpleTag';
import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import { useFeatureEntitlements } from '@frontegg/react';
import {
  riskTagsList,
  RiskTagsType,
} from '../FindingContentScoreTags/FindingContentScoreTags';

interface FindingContentRiskScoreBreakdownProps
  extends BaseFindingComponentProps {}

export const FindingContentRiskScoreBreakdown: FunctionComponent<
  FindingContentRiskScoreBreakdownProps
> = ({ findingId }) => {
  const { t: translation } = useTranslation();
  const [searchParams] = useQueryParams();

  const { isEntitled: isEntitledToIntelligenceFeature } =
    useFeatureEntitlements(SystemFeatureFlag.FINDING_VIEW_INTELLIGENCE_TAB);

  const {
    data: findingContentRiskScoreBreakdownData,
    isLoading: findingContentRiskScoreBreakdownDataLoading,
    error: findingContentRiskScoreBreakdownDataError,
  } = useGetFindingRiskScoreBreakdownQuery(
    findingId || searchParams.openFindingId
  );

  const renderItem = (title: string, titleIcon: ReactNode, body: ReactNode) => {
    return (
      <div className="finding-content-risk-score-breakdown-item">
        <div className="finding-content-risk-score-breakdown-item-category-container">
          <div className="finding-content-risk-score-breakdown-item-category">
            <div className="finding-content-risk-score-breakdown-item-category-icon">
              {titleIcon}
            </div>
            <div className="finding-content-risk-score-breakdown-item-category-text">
              {title}
            </div>
          </div>
          <div className="finding-content-risk-score-breakdown-item-category-blank-space"></div>
        </div>

        <div className="finding-content-risk-score-breakdown-item-body">
          {body}
        </div>
      </div>
    );
  };

  const renderRow = (
    body: ReactNode,
    applicationName?: string,
    applicationLogo?: string
  ) => {
    return (
      <div className="finding-content-risk-score-breakdown-item-category-row">
        {applicationName ? (
          <div className="finding-content-risk-score-breakdown-item-category-application">
            <div className="finding-content-risk-score-breakdown-item-category-application-logo">
              <img src={applicationLogo}></img>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="finding-content-risk-score-breakdown-item-category-row-title">
          {body}
        </div>
      </div>
    );
  };

  const renderScoreIcon = (score: number) => {
    if (score === 0) return <></>;

    return score > 0 ? (
      <div className="warning">
        <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_UP_ICON} fill="#E02B4B" />
      </div>
    ) : (
      <div className="info">
        <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_DOWN_ICON} fill="#22CC9A" />
      </div>
    );
  };

  const IntelligenceTag = ({
    label,
    iconType,
  }: {
    label: string;
    iconType: SVG_ICON_TYPES;
  }) => (
    <Box display="flex" alignItems="center" gap="5px">
      <OpusSvgIcon type={iconType} className="icon" />
      {label}
    </Box>
  );

  const IntelligenceCategory = ({
    category,
    type,
  }: {
    category: string;
    type: RiskTagsType.PREDICTIVE | RiskTagsType.THREATS | RiskTagsType.PATCHES;
  }) => {
    const categoryData = riskTagsList[type];
    const categoryTags =
      findingContentRiskScoreBreakdownData.factors.vulnerability.cause[type] ||
      {};

    if (!categoryData) {
      return null;
    }
    const renderPredictiveTag = (tags: { [key: string]: number }) => {
      const pentesterFrameworkCount = tags['pentesterFrameworkCount'];
      const inTheWildCount = tags['inTheWildCount'];
      const pocCount = tags['pocCount'];
      const discussionsCount = tags['discussionsCount'];

      if (pentesterFrameworkCount > 0 || inTheWildCount > 0) {
        return (
          <>
            {pentesterFrameworkCount > 0 && (
              <IntelligenceTag
                key="pentesterFrameworkCount"
                label="Pentester Framework"
                iconType={SVG_ICON_TYPES.LAPTOP_CODE_ICON}
              />
            )}
            {inTheWildCount > 0 && (
              <IntelligenceTag
                key="inTheWildCount"
                label="In The Wild"
                iconType={SVG_ICON_TYPES.UNLOCK_ICON}
              />
            )}
          </>
        );
      }

      if (pocCount > 0) {
        return (
          <IntelligenceTag
            key="pocCount"
            label="Proof of Concept"
            iconType={SVG_ICON_TYPES.LIST_CHECK_ICON}
          />
        );
      }

      if (discussionsCount > 0) {
        return (
          <IntelligenceTag
            key="discussionsCount"
            label="Discussed"
            iconType={SVG_ICON_TYPES.MESSAGES_ICON}
          />
        );
      }

      return null;
    };

    const renderTags = (tags: { [key: string]: number }) => {
      return categoryData.list.map((tag: any) => {
        const tagValue = tags[tag.key];
        if (tagValue > 0) {
          return (
            <IntelligenceTag
              key={tag.key}
              label={tag.label}
              iconType={tag.icon}
            />
          );
        }
        return null;
      });
    };

    const hasTagsWithValues = Object.keys(categoryTags).some(
      (key: string) => categoryTags[key] > 0
    );

    if (!hasTagsWithValues) return null;

    return (
      <div className="row">
        <span className="opus-body-text-1">{category}</span>
        <div className="list">
          {type === RiskTagsType.PREDICTIVE &&
            renderPredictiveTag(categoryTags)}
          {type === RiskTagsType.THREATS && renderTags(categoryTags)}
          {type === RiskTagsType.PATCHES && (
            <IntelligenceTag
              label="Patch Available"
              iconType={SVG_ICON_TYPES.BADGE_CHECK_ICON}
            />
          )}
        </div>
      </div>
    );
  };

  const renderRiskItemRow = () => {
    return renderRow(
      <Tooltip
        title={`Score ${findingContentRiskScoreBreakdownData?.factors?.risk?.severity?.score}`}
        placement="right"
      >
        <div className="finding-content-risk-score-breakdown-item-category-severity">
          <div className="finding-content-risk-score-breakdown-item-category-severity-title">
            {translation(`findings.subSections.sensorSeverity`)}
          </div>
          <div className="finding-content-risk-score-breakdown-item-category-severity-chip">
            <CommonSeverityChip
              severityNumber={
                findingContentRiskScoreBreakdownData?.factors?.risk?.severity
                  ?.cause
              }
            />
          </div>
          {findingContentRiskScoreBreakdownData?.factors?.risk?.severity
            ?.source ? (
            <div className="finding-content-risk-score-breakdown-item-category-application">
              <div className="finding-content-risk-score-breakdown-item-category-application-logo">
                <img
                  src={
                    findingContentRiskScoreBreakdownData?.factors?.risk
                      ?.severity?.sourceLogoUrl
                  }
                ></img>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Tooltip>
    );
  };

  const renderRiskItem = () => {
    return renderItem(
      translation(`findings.subSections.base`),
      <OpusSvgIcon type={SVG_ICON_TYPES.TRIANGLE_EXCLAMATION_ICON} />,
      renderRiskItemRow()
    );
  };

  const renderIntelligenceItemRow = () => {
    return renderRow(
      <div className="finding-content-risk-score-breakdown-item-category-intelligence-row">
        <div className="finding-content-risk-score-breakdown-item-rating-title">
          {translation(`findings.subSections.rating`)}
        </div>
        <div className="finding-content-risk-score-breakdown-item-rating-value">
          <SimpleTag
            value={
              findingContentRiskScoreBreakdownData.factors.vulnerability.cause
                .riskIndex
            }
            classes="rating"
          />
        </div>
        {findingContentRiskScoreBreakdownData.factors.vulnerability.cause
          .indicators.isTrending && (
          <>
            <div className="finding-content-dot"></div>
            <div className="finding-content-risk-score-breakdown-item-rating-trend">
              <SimpleTag
                classes="trend-up"
                icon={<OpusSvgIcon type={SVG_ICON_TYPES.ARROW_TREND_UP} />}
              />
            </div>
          </>
        )}
        {findingContentRiskScoreBreakdownData.factors.vulnerability.cause
          .indicators.cisaKev && (
          <>
            <div className="finding-content-dot"></div>
            <div className="finding-content-risk-score-breakdown-item-rating-logo">
              <OpusSvgIcon type={SVG_ICON_TYPES.CISA_ICON} />
            </div>
          </>
        )}
        <div className="finding-content-dot"></div>
        <div className="finding-content-risk-score-breakdown-item-rating-trend">
          {findingContentRiskScoreBreakdownData.factors.vulnerability.score ===
          undefined ? (
            <></>
          ) : (
            <div className="finding-content-score-icon">
              {renderScoreIcon(
                findingContentRiskScoreBreakdownData.factors.vulnerability.score
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderIntelligenceTagsRow = () => {
    const hasNonZeroValue = (obj: { [key: string]: number }) => {
      return Object.values(obj).some((value) => value > 0);
    };

    const { predictive, threats, patches } =
      findingContentRiskScoreBreakdownData?.factors?.vulnerability.cause || {};

    if (
      !hasNonZeroValue(predictive) &&
      !hasNonZeroValue(threats) &&
      !hasNonZeroValue(patches)
    ) {
      return <></>;
    }

    return (
      <div className="environmental-tag-section">
        {renderRow(
          <div className="finding-content-risk-score-breakdown-item-category-intelligence-tag-container">
            <IntelligenceCategory
              category="Exploit Status"
              type={RiskTagsType.PREDICTIVE}
            />
            <IntelligenceCategory
              category="Threats"
              type={RiskTagsType.THREATS}
            />
            <IntelligenceCategory
              category="Patch"
              type={RiskTagsType.PATCHES}
            />
          </div>
        )}
      </div>
    );
  };

  const renderIntelligenceItem = () => {
    const vulnerabilityFactor =
      findingContentRiskScoreBreakdownData?.factors?.vulnerability;
    if (!vulnerabilityFactor) {
      return <></>;
    }
    return renderItem(
      translation(`findings.subSections.intelligence`),
      <OpusSvgIcon type={SVG_ICON_TYPES.INTELLIGENCE_ICON} />,
      <div className="finding-content-risk-score-breakdown-item-category-intelligence">
        {renderIntelligenceItemRow()}
        {renderIntelligenceTagsRow()}
      </div>
    );
  };

  const renderServiceItem = () => {
    const criticalityScore =
      findingContentRiskScoreBreakdownData?.factors?.service?.buCriticality;
    const envTypeScore =
      findingContentRiskScoreBreakdownData?.factors?.service?.envType;
    if (criticalityScore === undefined && envTypeScore === undefined) {
      return <></>;
    }

    const externalFacingScore =
      findingContentRiskScoreBreakdownData?.factors?.service?.buExternalFacing;
    const sensitivityScore =
      findingContentRiskScoreBreakdownData?.factors?.service?.buSensitiveData;
    const complianceScore =
      findingContentRiskScoreBreakdownData?.factors?.service?.buCompliance;

    return renderItem(
      translation(`findings.subSections.environmental`),
      <OpusSvgIcon type={SVG_ICON_TYPES.BUILDINGS_ICON} />,
      <div className="finding-content-risk-score-breakdown-item-row-container">
        {findingContentRiskScoreBreakdownData?.factors?.service
          ?.buCriticality ? (
          renderRow(
            <div className="finding-content-risk-score-breakdown-criticality-container ">
              <div
                className={`risk-score-breakdown-attribute-icon-wrapper ${
                  criticalityScore &&
                  'risk-score-breakdown-icon-wrapper-with-criticality'
                }`}
              >
                <div className="finding-content-risk-score-breakdown-criticality-chip">
                  <BusinessImpactChip
                    impact={
                      findingContentRiskScoreBreakdownData?.factors?.service
                        ?.buCriticality?.cause
                    }
                  />
                </div>
              </div>
              {findingContentRiskScoreBreakdownData?.factors?.service
                ?.buCriticality?.score === undefined ? (
                <></>
              ) : (
                <div className="finding-content-score-icon">
                  {renderScoreIcon(
                    findingContentRiskScoreBreakdownData?.factors?.service
                      ?.buCriticality?.score
                  )}
                </div>
              )}
            </div>
          )
        ) : (
          <></>
        )}
        {findingContentRiskScoreBreakdownData?.factors?.service?.envType ? (
          renderRow(
            <div className="finding-content-risk-score-breakdown-attribute-container">
              <div className="factor-item">
                <div
                  className={`risk-score-breakdown-attribute-icon-wrapper ${
                    criticalityScore &&
                    'risk-score-breakdown-icon-wrapper-with-criticality'
                  }`}
                >
                  <div className="finding-content-risk-score-breakdown-attribute-icon">
                    <OpusSvgIcon type={SVG_ICON_TYPES.CLOUD_ICON} />
                  </div>
                </div>
                <div className="finding-content-risk-score-breakdown-attribute-text">
                  {
                    findingContentRiskScoreBreakdownData?.factors?.service
                      ?.envType?.cause
                  }
                </div>
              </div>
              {findingContentRiskScoreBreakdownData?.factors?.service?.envType
                ?.score === undefined ? (
                <></>
              ) : (
                <div className="finding-content-score-icon">
                  {renderScoreIcon(
                    findingContentRiskScoreBreakdownData?.factors?.service
                      ?.envType?.score
                  )}
                </div>
              )}
            </div>
          )
        ) : (
          <></>
        )}
        {externalFacingScore ? (
          renderRow(
            <Tooltip
              title={
                Array.isArray(externalFacingScore?.cause)
                  ? externalFacingScore?.cause.join(', ')
                  : externalFacingScore?.cause.toString()
              }
              placement="right"
              key={'tooltip-external-facing'}
            >
              <div className="finding-content-risk-score-breakdown-attribute-container">
                <div className="factor-item">
                  <div
                    className={`risk-score-breakdown-attribute-icon-wrapper ${
                      criticalityScore &&
                      'risk-score-breakdown-icon-wrapper-with-criticality'
                    }`}
                  >
                    <div className="finding-content-risk-score-breakdown-attribute-icon">
                      <OpusSvgIcon
                        type={SVG_ICON_TYPES.ARROW_UP_RIGHT_FROM_SQUARE_ICON}
                      />
                    </div>
                  </div>

                  <div className="finding-content-risk-score-breakdown-attribute-text">
                    {translation(`findings.subSections.externalFacing`)}
                  </div>
                </div>
                {externalFacingScore?.score === undefined ? (
                  <></>
                ) : (
                  <div className="finding-content-score-icon">
                    {renderScoreIcon(externalFacingScore?.score)}
                  </div>
                )}
              </div>
            </Tooltip>
          )
        ) : (
          <></>
        )}
        {sensitivityScore ? (
          renderRow(
            <Tooltip
              title={
                Array.isArray(sensitivityScore?.cause)
                  ? sensitivityScore?.cause.join(', ')
                  : sensitivityScore?.cause.toString()
              }
              placement="right"
              key={'tooltip-sensitive-data'}
            >
              <div className="finding-content-risk-score-breakdown-attribute-container">
                <div className="factor-item">
                  <div
                    className={`risk-score-breakdown-attribute-icon-wrapper ${
                      criticalityScore &&
                      'risk-score-breakdown-icon-wrapper-with-criticality'
                    }`}
                  >
                    <div className="finding-content-risk-score-breakdown-attribute-icon">
                      <OpusSvgIcon type={SVG_ICON_TYPES.EYES_ICON} />
                    </div>
                  </div>
                  <div className="finding-content-risk-score-breakdown-attribute-text">
                    {translation(`findings.subSections.sensitiveData`)}
                  </div>
                </div>
                {sensitivityScore?.score === undefined ? (
                  <></>
                ) : (
                  <div className="finding-content-score-icon">
                    {renderScoreIcon(sensitivityScore?.score)}
                  </div>
                )}
              </div>
            </Tooltip>
          )
        ) : (
          <></>
        )}
        {complianceScore ? (
          renderRow(
            <Tooltip
              title={
                Array.isArray(complianceScore?.cause)
                  ? complianceScore?.cause.join(', ')
                  : complianceScore?.cause.toString()
              }
              placement="right"
              key={'tooltip-compliance-requirements'}
            >
              <div className="finding-content-risk-score-breakdown-attribute-container">
                <div className="factor-item">
                  <div
                    className={`risk-score-breakdown-attribute-icon-wrapper ${
                      criticalityScore &&
                      'risk-score-breakdown-icon-wrapper-with-criticality'
                    }`}
                  >
                    <div className="finding-content-risk-score-breakdown-attribute-icon">
                      <OpusSvgIcon
                        type={SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON}
                      />
                    </div>
                  </div>
                  <div className="finding-content-risk-score-breakdown-attribute-text">
                    {translation(`findings.subSections.complianceRequirements`)}
                  </div>
                </div>
                {complianceScore?.score === undefined ? (
                  <></>
                ) : (
                  <div className="finding-content-score-icon">
                    {renderScoreIcon(complianceScore?.score)}
                  </div>
                )}
              </div>
            </Tooltip>
          )
        ) : (
          <></>
        )}
      </div>
    );
  };

  const renderExternalItem = () => {
    if (findingContentRiskScoreBreakdownData?.factors?.external?.length === 0) {
      return <></>;
    }

    return (
      <div className="external-section">
        {renderItem(
          translation(`findings.subSections.external`),
          <OpusSvgIcon type={SVG_ICON_TYPES.CUBES_ICON} />,
          <div className="finding-content-risk-score-breakdown-item-row-container">
            {findingContentRiskScoreBreakdownData?.factors?.external?.map(
              (externalFactor: any) => {
                return (
                  <div className="finding-content-risk-score-breakdown-item-row-item-container">
                    <div className="external-item">
                      {renderRow(
                        <span>{externalFactor?.cause}</span>,
                        externalFactor?.source,
                        externalFactor?.sourceLogoUrl
                      )}
                    </div>
                    {externalFactor?.score === undefined ? (
                      <></>
                    ) : (
                      <div className="finding-content-score-icon">
                        {renderScoreIcon(externalFactor?.score)}
                      </div>
                    )}
                  </div>
                );
              }
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSectionBody = () => {
    if (findingContentRiskScoreBreakdownDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={140} width={'100%'} />
        </div>
      );
    }

    if (
      findingContentRiskScoreBreakdownData === undefined ||
      findingContentRiskScoreBreakdownDataError ||
      findingContentRiskScoreBreakdownData?.score === 0
    ) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-risk-score-breakdown-body">
        {renderRiskItem()}
        {isEntitledToIntelligenceFeature ? renderIntelligenceItem() : <></>}
        {renderServiceItem()}
        {renderExternalItem()}
      </div>
    );
  };

  return (
    <div className="finding-content-item finding-content-risk-score-breakdown-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.GAUGE_CIRCLE_BOLT_ICON} />}
        title={translation(`findings.sections.riskScoreBreakdown`)}
        collapsable
        titleAdornment={
          findingContentRiskScoreBreakdownData?.score ? (
            <RiskChip riskScore={findingContentRiskScoreBreakdownData?.score} />
          ) : (
            <></>
          )
        }
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { TenantDetails } from '../../Onboarding/models/onboarding-tenant-details.model';

export interface UserInfo {
  email: string;
  id: string;
  name: string;
  profilePictureUrl: string;
}

export enum Role {
  SYSTEM_ADMIN = 'SysAdmin',
  ADMIN = 'Admin',
}

export interface UserInfoExtended extends UserInfo {
  roles: UserRole[];
  lastSeen: string;
  createdAt: string;
  orgStructureInfo: {
    scopesInfo: string[];
    groupsInfo: string[];
    dataInfo: string[];
  };
}
export interface UserRole {
  idpId: string;
  id: string;
  key: string;
  name: string;
}

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: ['Users'],
  endpoints: (builder) => ({
    getTenantDetails: builder.query<TenantDetails, void>({
      query: () => ({
        url: 'tenants/me',
        method: 'GET',
      }),
    }),
    fetchUser: builder.query<UserInfo[], string | undefined>({
      query: (userId: string) => ({
        url: `users/search`,
        method: 'POST',
        body: {
          ids: userId,
        },
      }),
      transformResponse: async (response) => {
        return response as UserInfo[];
      },
    }),
    fetchUsers: builder.mutation<Array<UserInfo>, void>({
      query: () => ({
        url: `users/search`,
        method: 'POST',
      }),
      transformResponse: async (response) => {
        return response as Array<UserInfo>;
      },
    }),
    fetchUserInfoExtended: builder.mutation<Array<UserInfoExtended>, any>({
      query: (payload: string[]) => ({
        url: `users/extended/search`,
        method: 'POST',
        body: payload,
      }),
      transformResponse: async (response) => {
        return response as Array<UserInfoExtended>;
      },
    }),
    fetchUsersExtended: builder.mutation<Array<UserInfoExtended>, void>({
      query: () => ({
        url: `users/extended/search`,
        method: 'POST',
      }),
      transformResponse: async (response) => {
        return response as Array<UserInfoExtended>;
      },
      invalidatesTags: ['Users'],
    }),

    deleteUser: builder.mutation<void, string>({
      query: (id) => ({
        url: `users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Users'],
      transformResponse: async (response) => {},
    }),
    fetchUserRoleList: builder.query<UserRole[], void>({
      query: () => 'users/roles',
      transformResponse: async (response) => {
        return response as UserRole[];
      },
    }),
    inviteUser: builder.mutation<void, string>({
      query: (body) => ({
        url: `users/invite`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Users'],
      transformResponse: async (response) => {},
    }),
    updateUser: builder.mutation<void, string>({
      query: (body) => ({
        url: `users/update`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: ['Users'],
      transformResponse: async (response) => {},
    }),
    validateJwtToken: builder.mutation({
      query: () => ({
        method: 'POST',
        url: 'auth/jwt/validate',
      }),
    }),
  }),
});

export default authApi;

export const {
  useGetTenantDetailsQuery,
  useFetchUserQuery,
  useFetchUsersMutation,
  useFetchUsersExtendedMutation,
  useDeleteUserMutation,
  useFetchUserRoleListQuery,
  useFetchUserInfoExtendedMutation,
  useInviteUserMutation,
  useUpdateUserMutation,
  useValidateJwtTokenMutation,
} = authApi;

import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';

import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { CommonDialogProps } from 'Common/components/Dialogs/CommonDialog/CommonDialog';
import FormInput from 'shared/components/FormInput';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import Autocomplete from 'Common/components/Autocomplete';
import OrganizationSelectDropdown from 'Settings/components/OrganizationSelectDropdown/OrganizationSelectDropdown';
import { useGetOrganizationTreeQuery } from 'Organization/store/api';
import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  useFetchUserInfoExtendedMutation,
  useFetchUserRoleListQuery,
  useInviteUserMutation,
  UserInfoExtended,
  useUpdateUserMutation,
} from 'Auth/store/api';
import { UserDataHandler } from 'shared/handlers/user-data.handler';
import FormattedMessage from 'shared/components/FormattedMessage';

const userDataHandler = new UserDataHandler();

interface Props extends CommonDialogProps {
  onClose: (shouldReload?: boolean) => void;
  userId?: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserDialog: React.FC<Props> = ({
  onClose,
  userId,
  setIsLoading,
  isLoading: isDialogLoading,
  open,
  title,
  saveButtonText,
}: Props) => {
  const [onSave, setOnSave] = useState<boolean>(false);
  const [fetchUsers, { data: userData, isLoading: isLoadingUser }] =
    useFetchUserInfoExtendedMutation();
  const { t: translation } = useTranslation();
  const formElement = useRef<HTMLFormElement>(null);
  const { data: organizationData, isLoading: isLoadingOrgData } =
    useGetOrganizationTreeQuery();
  const { data: userRoleData, isLoading: isLoadingUserRoles } =
    useFetchUserRoleListQuery();

  const [
    createUser,
    {
      isLoading: isLoadingCreate,
      isSuccess: isCreated,
      isError: isCreatedError,
      reset: resetCreate,
      error: createError,
    },
  ] = useInviteUserMutation();
  const [
    updateUser,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isUpdated,
      isError: isUpdateError,
      reset: resetUpdate,
      error: updateError,
    },
  ] = useUpdateUserMutation();
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [user, setUser] = useState<UserInfoExtended | undefined>(undefined);
  const [selectedRolesId, setSelectedRolesIds] = useState<string[]>([]);
  const [assignedNodes, setAssignedNodes] = useState<string[]>([]);

  const isLoading = isLoadingCreate || isLoadingUpdate;

  useEffect(() => {
    if (isCreated || isUpdated) onClose(true);
  }, [isCreated, isUpdated]);

  useEffect(() => {
    if (isCreated || isUpdated) onClose(true);
  }, [isCreated, isUpdated]);

  useEffect(() => {
    async function fetchUserData() {
      if (userId) {
        const response = (await fetchUsers({ ids: userId })) as {
          data: UserInfoExtended[];
        };
        if (response && response.data.length > 0) {
          setUser(response.data[0]);
          setSelectedRolesIds(response.data[0].roles.map((role) => role.id));
        }
      } else {
        setUser(undefined);
      }
    }

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    if (onSave) handleSubmit();
  }, [onSave]);

  useEffect(() => {
    setFormErrors({});
  }, [onClose]);

  async function handleSubmit() {
    const form = formElement.current;
    if (!form) return;

    const formData = new FormData(form);
    const item: any = {
      name: formData.get('name') as string,
      email: formData.get('email') as string,
      roleIds: selectedRolesId,
      assignedNodes: assignedNodes,
    };
    const errors = validateFormFields(item);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setOnSave(false);
    } else {
      setFormErrors({});
      user ? updateUser({ ...item, userIdpId: userId }) : createUser(item);
      setOnSave(false);
    }
  }

  function validateFormFields(item: any) {
    const errors: { [key: string]: string } = {};
    if (!item.name) {
      errors['name'] = translation('settings.details.requiredField', {
        param1: 'Name',
      });
    }
    if (!item.email && !user) {
      errors['email'] = translation('settings.details.requiredField', {
        param1: 'Email',
      });
    }
    if (!item.roleIds.length) {
      errors['role'] = translation('settings.details.requiredField', {
        param1: 'Role',
      });
    }
    if (!item.assignedNodes.length) {
      errors['nodes'] = translation('settings.details.requiredField', {
        param1: 'Scope',
      });
    }

    return errors;
  }

  const userRoleList = useMemo(() => {
    if (userRoleData) {
      return userDataHandler.transformUserRoleListToAutocompleteRoleList(
        userRoleData,
      );
    }

    return [];
  }, [userRoleData]);

  const userRole: Array<AutocompleteOption> = useMemo(() => {
    if (user) {
      const preSelectedRoles =
        userDataHandler.transformUserRoleListToAutocompleteRoleList(
          user?.roles,
        );
      return preSelectedRoles;
    }

    return [];
  }, [user]);

  return (
    <>
      <CommonDialog
        open={open}
        classes={'settings-user-dialog'}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.USER_ICON} />}
        closeButtonText={translation('common.cancel')}
        loadingButtonText={translation('common.connecting')}
        saveButtonText={saveButtonText}
        onClose={onClose}
        title={title}
        onSave={() => {
          setOnSave(true);
        }}
        buttonRequiredPermissions={[ApplicationPermission.SETTINGS_TEAMS_WRITE]}
        isLoading={isLoading}
      >
        {isLoadingUser ? (
          <Box textAlign="center" py={4}>
            <CircularProgress size={30} />
          </Box>
        ) : (
          <form ref={formElement} onSubmit={handleSubmit}>
            <Grid
              container
              minWidth={'500px'}
              my={2}
              columnGap={'20px'}
              rowGap={'40px'}
            >
              <Grid item xs={6.5}>
                <FormInput
                  label="Email"
                  name="email"
                  value={user?.email}
                  disabled={!!user}
                  placeholder="Please type user email"
                />
                {formErrors['email'] && (
                  <Typography variant="caption" color="error">
                    {formErrors['email']}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5}>
                <InputLabelWrapper label="Roles">
                  <Autocomplete
                    classes={{
                      root: 'multi-select-field-1',
                      paper: 'multi-select-field-paper-1',
                      inputRoot: 'mutlti-select-user-roles',
                    }}
                    model="role"
                    enableCheckbox
                    initialSelectedValues={userDataHandler.transformUserRoleListToAutocompleteRoleList(
                      user?.roles || [],
                    )}
                    onChangeCallBack={(model, values) => {
                      const typedValues = values as Array<AutocompleteOption>;
                      const rulesIds = typedValues.map((item) => item.value);
                      setSelectedRolesIds(rulesIds);
                    }}
                    limitTags={1}
                    optionList={userRoleList}
                  />
                  {formErrors['role'] && (
                    <Typography variant="caption" color="error">
                      {formErrors['role']}
                    </Typography>
                  )}
                </InputLabelWrapper>
              </Grid>
              <Grid item xs={12}>
                <FormInput
                  label="Full Name"
                  name="name"
                  value={user?.name}
                  placeholder="Please type user name"
                />
                {formErrors['name'] && (
                  <Typography variant="caption" color="error">
                    {formErrors['name']}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabelWrapper label="Scopes"></InputLabelWrapper>
                <OrganizationSelectDropdown
                  userData={user}
                  orgData={organizationData}
                  onSelectedNodesChangeCallback={(updatedNodes) => {
                    const nodeIds: string[] = ([] as string[]).concat(
                      ...Object.values<string[]>(updatedNodes),
                    );
                    setAssignedNodes(nodeIds);
                  }}
                  isLoading={isLoadingOrgData}
                ></OrganizationSelectDropdown>
                {formErrors['nodes'] && (
                  <Typography variant="caption" color="error">
                    {formErrors['nodes']}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </CommonDialog>
      <Snackbar
        open={isCreatedError || isUpdateError}
        onClose={() => {
          resetCreate();
          resetUpdate();
        }}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          {((createError || updateError) as any)?.data?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

import { Box, Grid, IconButton } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface BaseOrganizationNodeModalContainerProps extends BaseComponentProps {
  open: boolean;
  onClose: () => void;
  title: string;
  icon?: React.ReactElement;
}

export const BaseOrganizationNodeModalContainer: FunctionComponent<
  BaseOrganizationNodeModalContainerProps
> = ({ open, onClose, children, title: customTitle, icon }) => {
  return (
    <PopoverWrapper
      showPopover={open}
      handleClose={onClose}
      classes="organization-drawer"
    >
      <Grid
        container
        className="organization-modal-container"
        p={3}
        spacing={4}
      >
        <Grid
          item
          className="common-modal-header-title-container"
          display={'flex'}
          gap={2}
        >
          <div className="common-modal-header-title-icon">{icon}</div>
          <p className="common-modal-header-title header-4">{customTitle}</p>
        </Grid>
        <Grid item>{children}</Grid>
      </Grid>
    </PopoverWrapper>
  );
};

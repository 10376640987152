import { FunctionComponent, useMemo } from 'react';
import { BusinessUnitMetadata } from 'shared/models/data/business-unit.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { BusinessUnitCarouselList } from '../BusinessUnitCarouselList';
import { OrganizationalDataProps } from 'Dashboard/DashboardPage';
import _ from 'lodash';

interface BusinessUnitDashboardSectionProp
  extends BaseComponentProps,
    OrganizationalDataProps {
  businessUnitListWithMetadata?: any;
  businessUnitLoading: boolean;
  selectedServiceIds: Array<string>;
}

export enum UniqueBusinessUnitId {
  ALL = 'all',
  NO_BUSINESS_UNIT = '00000000-0000-0000-0000-000000000000',
}

export const BusinessUnitDashboardSection: FunctionComponent<
  BusinessUnitDashboardSectionProp
> = ({
  businessUnitLoading,
  businessUnitListWithMetadata,
  scopeData,
  groupData,
  serviceData,
  selectedServiceIds,
}) => {
  const filteredBusinessUnits = useMemo(() => {
    const allItem = businessUnitListWithMetadata?.find(
      (bu: BusinessUnitMetadata) => bu.id === UniqueBusinessUnitId.ALL
    );

    if (serviceData && businessUnitListWithMetadata) {
      const businessUnits = _.compact(
        selectedServiceIds.map((serviceId) => {
          return businessUnitListWithMetadata?.find(
            (bu: BusinessUnitMetadata) =>
              serviceId === bu.id && bu.id !== UniqueBusinessUnitId.ALL
          );
        })
      ) as BusinessUnitMetadata[];

      const allIssuesCount = businessUnits?.reduce(
        (allIssuesSum, businessUnit) => {
          return allIssuesSum + businessUnit.openIssues;
        },
        0
      );

      return [
        {
          ...allItem,
          openIssues: allIssuesCount,
          childrenCount: businessUnits?.length || 0,
        },
        ...(businessUnits || []),
      ];
    }

    return [];
  }, [serviceData, businessUnitListWithMetadata, selectedServiceIds]);

  return (
    <div className="business-unit-dashboard-section-container">
      <div className="business-unit-dashboard-section-body">
        <BusinessUnitCarouselList
          isLoading={businessUnitLoading}
          businessUnits={filteredBusinessUnits}
          scopeData={scopeData}
          groupData={groupData}
          isDataReady={true}
        />
      </div>
    </div>
  );
};

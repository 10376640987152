import { createApi } from '@reduxjs/toolkit/query/react';
import { CodeEventItem } from 'Cloud2Code/interfaces/code-event-item.model';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import {
  IacEventRequestBody,
  IacEventResponse,
  IaCItemDetails,
  IacRelatedFinding,
  PullRequest,
} from 'IaCFindings/interfaces/IaCFinding';

const iacFindingsApi = createApi({
  reducerPath: 'iacFindingsApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: ['IaC'],
  endpoints: (builder) => ({
    fetchIacDetailsById: builder.query<IaCItemDetails, string>({
      query: (iacId) => ({ url: `iacs/events/${iacId}`, method: 'GET' }),
      transformResponse: async (response) => {
        return response as IaCItemDetails;
      },
    }),
    fetchIaCWithPagination: builder.mutation({
      query: (params: IacEventRequestBody) => {
        return {
          method: 'POST',
          url: `iacs/events/search`,
          body: params,
        };
      },
      transformResponse: async (response) => {
        return response as IacEventResponse;
      },
    }),
    updateIacStatus: builder.mutation<IaCItemDetails, any>({
      query: (iacParams) => ({
        url: `iacs/events/${iacParams?.id}/statuses/${iacParams?.status}`,
        method: 'PATCH',
      }),
      transformResponse: async (response) => {
        return response as IaCItemDetails;
      },
    }),
    getFindingRelatedIaCEvent: builder.query<IaCItemDetails, string>({
      query: (findingId: string) => ({
        url: `iacs/events/${findingId}/content/related-event`,
        method: 'GET',
      }),
      transformResponse: async (response) => {
        return response as IaCItemDetails;
      },
    }),
    createPullRequest: builder.mutation<PullRequest, any>({
      query: (eventId) => ({
        url: `iacs/events/${eventId}/actions/pullrequest`,
        method: 'POST',
      }),
      transformResponse: async (response) => {
        return response as PullRequest;
      },
    }),
    getIacRelatedFindings: builder.query<IacRelatedFinding[], string>({
      query: (eventId: string) => ({
        url: `iacs/events/${eventId}/content/related-findings`,
        method: 'GET',
      }),
    }),
    getIacRelatedItems: builder.query<CodeEventItem[], string>({
      query: (eventId: string) => ({
        url: `iacs/events/${eventId}/content/related-items`,
        method: 'GET',
      }),
    }),
    updateCodeEvent: builder.mutation({
      query: ({ body, codeEventId }) => ({
        url: `iacs/events/${codeEventId}`,
        method: 'PATCH',
        body: body,
      }),
    }),
  }),
});

export default iacFindingsApi;

export const {
  useFetchIacDetailsByIdQuery,
  useFetchIaCWithPaginationMutation,
  useUpdateIacStatusMutation,
  useGetFindingRelatedIaCEventQuery,
  useLazyGetFindingRelatedIaCEventQuery,
  useCreatePullRequestMutation,
  useGetIacRelatedFindingsQuery,
  useGetIacRelatedItemsQuery,
  useUpdateCodeEventMutation,
} = iacFindingsApi;

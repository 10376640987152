import React, { useEffect, useRef, useState } from 'react';
import WebhookItem from 'Integrations/Webhook/interfaces/item';
import {
  useWebhookCreateMutation,
  useWebhookDeleteMutation,
} from 'Integrations/Webhook/store/api';
import config from 'Config';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { VisibilityControlContent } from 'shared/components/VisibilityControlContent/VisibilityControlContent';
import CopyButton from 'Common/components/CopyButton';
import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';
import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import { Grid, Typography } from '@mui/material';

interface FindingSourceContentProps {
  connectionId?: string;
  id: string;
  submited: boolean;
  setSubmited: any;
  webhook?: WebhookItem;
  connectionTemplate?: ConnectionTemplateItem;
}

export const FindingSourceContent: React.FC<FindingSourceContentProps> = ({
  connectionId,
  id,
  webhook,
  connectionTemplate,
}) => {
  const [webhookSubscription, setWebhookSubscription] = useState<
    WebhookItem | undefined
  >(undefined);
  const [findingSourceVisible, setFindingSourceVisible] =
    useState<boolean>(false);
  const prevFindingSourceVisible = useRef(findingSourceVisible);
  const [webhookValue, setWebhookValue] = useState<string>();
  const [generateWebhook, { data: webhookData, isLoading }] =
    useWebhookCreateMutation();

  const generateWebhookUrl = (webhook: WebhookItem) => {
    setWebhookValue(
      `${config.globalGwBaseUrl}/webhook/incoming/${webhook.tenantId}/${webhook.key}`
    );
  };
  const [deleteWebhook] = useWebhookDeleteMutation();

  useEffect(() => {
    if (findingSourceVisible && connectionId && !webhookSubscription) {
      generateWebhook({ applicationId: id, connectionId: connectionId });
      setWebhookSubscription(webhookData);
    }
  }, [findingSourceVisible, connectionId]);

  useEffect(() => {
    if (webhookSubscription) {
      generateWebhookUrl(webhookSubscription);
    }
  }, [webhookSubscription]);

  const handleFindingSourceVisibilityChange = () => {
    setFindingSourceVisible(
      (prevFindingSourceVisible) => !prevFindingSourceVisible
    );
  };

  useEffect(() => {
    if (webhookData) {
      generateWebhookUrl(webhookData);
    }
  }, [webhookData]);

  useEffect(() => {
    prevFindingSourceVisible.current = findingSourceVisible;
  }, [findingSourceVisible]);

  useEffect(() => {
    const id = webhookData?.id || webhookSubscription?.id;
    if (!findingSourceVisible && id && !isLoading) {
      deleteWebhook(id);
      setWebhookSubscription(undefined);
    }
  }, [findingSourceVisible]);

  useEffect(() => {
    setWebhookSubscription(webhook);
    setFindingSourceVisible(!!webhook);
  }, [webhook]);

  return (
    <VisibilityControlContent
      label="Enable Finding Source"
      visible={findingSourceVisible}
      onVisibilityChange={handleFindingSourceVisibilityChange}
      disabled={!connectionId}
    >
      <div className="finding-source-content">
        <FormInput
          label="Copy and paste at your Webhook endpoint configuration"
          name="webhook"
          value={webhookValue}
          readOnly={true}
          onChange={() => {}}
          components={{
            EndIcon: <CopyButton textToCopy={webhookValue}></CopyButton>,
          }}
        ></FormInput>
      </div>
      {connectionTemplate?.definition?.props?.deployLink &&
        connectionTemplate.applicationId === ApplicationTypes.SECURITY_HUB && (
          <Grid container className="deploy-container">
            <Grid item xs={12}>
              <Typography variant="inherit" className="body-1">
                Using CloudFormation stack you can easily deploy SNS topic and
                event bridge for registering Opus Webhook.
              </Typography>
            </Grid>
            <Grid item xs={12} className="deploy-link">
              <a
                href={connectionTemplate?.definition?.props?.deployLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Deploy Stack
              </a>
            </Grid>
          </Grid>
        )}
    </VisibilityControlContent>
  );
};

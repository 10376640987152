import { Box, Stack, Typography } from '@mui/material';
import CommonIconMui from '../../IconMui';
import * as React from 'react';
import GenericDialog from '../GenericDialog';
import { useState } from 'react';

interface IconConfiguration {
  backgroundColor: string;
  element: JSX.Element;
}

interface WarningDialogProps {
  isDialogOpen: boolean;
  onClose?: () => void;
  dialogTitle?: string;
  bodyTitle?: string;
  message?: string;
  iconConfiguration?: IconConfiguration;
}

export default function InfoDialog({
  dialogTitle,
  bodyTitle,
  message,
  onClose,
  isDialogOpen,
  iconConfiguration,
}: WarningDialogProps) {
  function handleDialogClose() {
    isDialogOpen = false;
    if (onClose) onClose();
  }

  return (
    <GenericDialog
      open={isDialogOpen}
      title={dialogTitle}
      onClose={() => handleDialogClose()}
      sx={{ minWidth: 400 }}
    >
      <Stack direction="column" alignItems="center">
        {iconConfiguration && (
          <Box
            p={2}
            borderRadius="50%"
            display="inline-flex"
            sx={{ backgroundColor: iconConfiguration.backgroundColor }}
            mt={4}
            mb={4}
          >
            {iconConfiguration.element}
          </Box>
        )}
        {bodyTitle && (
          <Typography variant="h4" mb={1}>
            {bodyTitle}
          </Typography>
        )}
        {message && (
          <Typography variant="h6" fontWeight={400} textAlign="center">
            {message}
          </Typography>
        )}
        <Box mb={4} />
      </Stack>
    </GenericDialog>
  );
}

import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useAuthUser } from '@frontegg/react';
import CommonIconLinkMui from '../IconLinkMui';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  getMenuConfig,
  getBottomMenuConfig,
  getAvailableMenuItems,
  MenuItemsIds,
} from './MenuConfig';
import classes from './Menu.module.css';
import { Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { UserPermissionHandler } from 'shared/handlers/user-permission.handler';
import { useTranslation } from 'react-i18next';
import { MenuConfigType } from './types';
import { ContextHolder } from '@frontegg/rest-api';
import authConstants from 'Auth/constants';
import EntitledContent from 'shared/components/EntitledContent';

const userPermissionHandler = new UserPermissionHandler();

export default function CommonMenu() {
  const { t: translation } = useTranslation();
  const { profilePictureUrl, email, name } = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const [displaySubMenu, setDisplaySubMenu] = useState<boolean>(false);

  const [menuId, setMenuId] = useState<number>(1);

  useEffect(() => {
    const result = menuFilter(currentPath);
    if (result) {
      setMenuId(result.id);
    } else {
      if (currentPath.includes('/findings') || currentPath.includes('/iac')) {
        setMenuId(MenuItemsIds.Risks);
      }
    }
  }, [currentPath]);

  const menuFilter = (arg: string): any | undefined => {
    const foundItem = getMenuConfig(translation).find((item) => {
      if (item.to === arg) {
        return true;
      } else if (item.submenu) {
        const matchingSubmenu = item.submenu.find(
          (subItem) => subItem.to === arg
        );
        return matchingSubmenu !== undefined;
      }
      return false;
    });

    return foundItem;
  };

  const handleMenuItemClick = (id: number) => {
    setMenuId(id);
  };

  const user = useAuthUser();

  const userPermissions = React.useMemo<Array<ApplicationPermission>>(() => {
    return user
      ? userPermissionHandler.extractPermissionListFromUserModel(user)
      : [];
  }, [user]);

  const menuItems = React.useMemo<Array<MenuConfigType>>(() => {
    if (userPermissions.length) {
      return getAvailableMenuItems(getMenuConfig(translation), userPermissions);
    }

    return [];
  }, [translation, userPermissions]);

  const logout = () => {
    if (localStorage.getItem(authConstants.authAccessToken)) {
      localStorage.removeItem(authConstants.authAccessToken);
    }
    const baseUrl = ContextHolder.getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };

  const getMenuIcon = (
    menuItem: MenuConfigType,
    i: number,
    submenu: boolean
  ) => {
    if (menuItem.icon) {
      return (
        <CommonIconLinkMui
          tooltipTitle={menuItem.title}
          tooltipIndex={i}
          isOutlined={true}
          icon={menuItem.icon}
          to={menuItem.to || ''}
          styleIcon="iconStyle"
          textStyle="textStyle"
        />
      );
    }

    if (menuItem.iconSvg) {
      const Icon = menuItem.iconSvg;
      return (
        <div className={classes.iconLink}>
          <div
            onClick={() => {
              if (menuItem.callBackFunction) {
                menuItem.callBackFunction();
              }
              setDisplaySubMenu(true);
            }}
          >
            <IconButton
              className={classes.svgIconLink}
              to={!submenu ? menuItem.to : location.pathname}
              onClick={() => {
                if (menuItem.callBackFunction) {
                  menuItem.callBackFunction();
                }
                setDisplaySubMenu(true);
              }}
              component={Link}
            >
              <Icon />
              <span className={classes.menuIconText}>{menuItem.title}</span>
            </IconButton>
          </div>
        </div>
      );
    }

    if (menuItem.iconAvatar) {
      return (
        <div
          onClick={() => {
            setDisplaySubMenu(true);
          }}
        >
          {menuItem.iconAvatar}
        </div>
      );
    }
  };

  const renderBottomsMenu = (item: any, index: number, submenu: boolean) => {
    return (
      <div className={classes.helpContainer}>
        {getMenuIcon(item, index, submenu)}
      </div>
    );
  };

  const renderMenuItemNestedSubMenuItems = (
    menuItem: MenuConfigType,
    index: number
  ) => {
    const renderSubMenuItems = () => (
      <>
        {menuItem.submenu?.map((subMenuItem, i) => (
          <MenuItem
            key={i}
            className="menuItem"
            onClick={() => {
              handleMenuItemClick(menuItem.id);
              setDisplaySubMenu(false);
              if (subMenuItem.callBackFunction) {
                subMenuItem.callBackFunction();
              } else {
                navigate(subMenuItem.to);
              }
            }}
          >
            {subMenuItem.title}
          </MenuItem>
        ))}
      </>
    );

    const renderMenuContent = () => (
      <div className="menu-item">
        <Menu>
          {!menuItem.submenu ? (
            <MenuItem className={menuId === menuItem.id ? 'active' : ''}>
              {renderBottomsMenu(menuItem, index, false)}
            </MenuItem>
          ) : (
            <SubMenu
              component="div"
              className={menuId === menuItem.id ? 'active' : ''}
              label={renderBottomsMenu(menuItem, index, true)}
            >
              {displaySubMenu && renderSubMenuItems()}
            </SubMenu>
          )}
        </Menu>
      </div>
    );

    return menuItem.featureFlag ? (
      <EntitledContent flag={menuItem.featureFlag}>
        {renderMenuContent()}
      </EntitledContent>
    ) : (
      renderMenuContent()
    );
  };

  return (
    <Box className={classes.boxMenu}>
      {menuItems.map((menuItem, i) => {
        return renderMenuItemNestedSubMenuItems(menuItem, i);
      })}
      <div className="bottomMenu">
        {getBottomMenuConfig(
          translation,
          {
            name,
            email,
            profilePictureUrl: profilePictureUrl as string,
            className: classes.userMenuButton,
          },
          logout
        ).map((menuItem, i) => {
          return renderMenuItemNestedSubMenuItems(menuItem, i);
        })}
      </div>
    </Box>
  );
}

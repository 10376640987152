import {
  Chip,
  InputAdornment,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import { KeyboardArrowDown, Search, Check } from '@mui/icons-material';
import {
  OpusAutocompleteOption,
  OpusAutocompleteProps,
} from 'shared/models/props/opus-autocomplete-props.model';

export const OpusAutocomplete: FunctionComponent<OpusAutocompleteProps> = ({
  label,
  onChange,
  options,
  mode = 'single',
  values = [],
  limitTags = 1,
}) => {
  const [selectedOptions, setSelectedOptions] =
    useState<Array<OpusAutocompleteOption>>(values);
  const [searchValue, setSearchValue] = useState<string>('');

  const onSelect = (option: OpusAutocompleteOption) => {
    if (mode === 'multiple') {
      const existingValue = selectedOptions.find(
        (selectedOption: OpusAutocompleteOption) =>
          option.value === selectedOption.value
      );

      if (existingValue) {
        const updatedOptions = selectedOptions.filter(
          (selectedOption) => selectedOption.value !== option.value
        );
        setSelectedOptions(updatedOptions);
        onChange(updatedOptions);
      } else {
        setSelectedOptions([...selectedOptions, option]);
        onChange([...selectedOptions, option]);
      }
    } else {
      setSelectedOptions([option]);
      onChange([option]);
    }
  };

  const renderValues = (values: Array<OpusAutocompleteOption>) => {
    return mode === 'multiple' ? (
      <div className="opus-autocomplete-input-value">
        {values.slice(0, limitTags).map((value: OpusAutocompleteOption) => (
          <Chip
            className="opus-autocomplete-chip"
            label={value.label || value.value}
            key={value.value}
          />
        ))}
        {values.slice(limitTags, values.length).length ? (
          <Chip
            className="opus-autocomplete-chip"
            label={`${values.slice(limitTags, values.length).length}+`}
          />
        ) : (
          <></>
        )}
      </div>
    ) : (
      <div className="opus-autocomplete-single-value-container">
        {values[0]?.label || values[0]?.value}
      </div>
    );
  };

  return (
    <div className="opus-autocomplete-container">
      <Select
        IconComponent={KeyboardArrowDown}
        multiple={mode === 'multiple'}
        value={selectedOptions}
        renderValue={renderValues}
        className="opus-autocomplete-select"
        startAdornment={
          label && (
            <Typography className="opus-autocomplete-input-label">
              {label}:
            </Typography>
          )
        }
      >
        <div className="opus-autocomplete-body">
          <TextField
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            className="opus-autocomplete-search-input"
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setSearchValue(event.target.value);
            }}
          />
          <div className="opus-autocomplete-option-list">
            {options
              .filter((option: OpusAutocompleteOption) =>
                (option.value || option.label)
                  ?.toLowerCase()
                  .includes(searchValue?.toLowerCase())
              )
              .map((option: OpusAutocompleteOption) => {
                return (
                  <div
                    onClick={() => {
                      onSelect(option);
                    }}
                    className="opus-autocomplete-option-list-item"
                    key={option.value}
                  >
                    <Typography>{option.label || option.value}</Typography>
                    {selectedOptions.find(
                      (selectedOption: OpusAutocompleteOption) =>
                        selectedOption.value === option.value
                    ) && <Check htmlColor="#6664ED" />}
                  </div>
                );
              })}
          </div>
        </div>
      </Select>
    </div>
  );
};

import { FunctionComponent } from 'react';
import { VulnerabilityMetadataWeakness } from 'shared/models/data/vulnerability.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export interface IntelligenceWeaknessesItemProps
  extends BaseComponentProps,
    VulnerabilityMetadataWeakness {}

export const IntelligenceWeaknessesItem: FunctionComponent<
  IntelligenceWeaknessesItemProps
> = ({ id, title, type }) => {
  return (
    <div className="intelligence-weakness-item">
      <div className="comment-indicator"></div>
      <div className="intelligence-weakness-item-title">{id}</div>
      <div className="intelligence-weakness-item-properties">
        <div className="intelligence-weakness-item-properties-row">
          <div className="intelligence-weakness-item-properties-row-key">
            Title
          </div>
          <div className="intelligence-weakness-item-properties-row-value">
            {title}
          </div>
        </div>
        <div className="intelligence-weakness-item-properties-row">
          <div className="intelligence-weakness-item-properties-row-key">
            Type
          </div>
          <div className="intelligence-weakness-item-properties-row-value">
            {type}
          </div>
        </div>
      </div>
    </div>
  );
};

import FindingWorkflow from 'FindingDetails/components/FindingWorkflow';
import { FunctionComponent } from 'react';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import WorkflowHeader from '../../../WorkflowHeader';

interface FindingContentWorkflowProps extends BaseComponentProps {}

export const FindingContentWorkflow: FunctionComponent<
  FindingContentWorkflowProps
> = () => {
  const [searchParams] = useQueryParams();
  const findingId = searchParams.openFindingId;

  return (
    <div className="finding-content-workflow-container">
      <WorkflowHeader findingId={findingId} />
      <FindingWorkflow findingId={findingId} />
    </div>
  );
};

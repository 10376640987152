import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Autocomplete from 'Common/components/Autocomplete';
import { AutocompleteOption } from 'FindingDetails/store/api';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface NotificationRuleRoleAutocompleteProps {
  model: string;
  mainOptionList: AutocompleteOption[];
  roleOptionList: AutocompleteOption[];
  limitTags: number;
  mainValues: AutocompleteOption[];
  roleValues: AutocompleteOption[];
  initialMainSelectedValues?: AutocompleteOption[];
  initialRoleSelectedValues?: AutocompleteOption[];
  enableCheckbox: boolean;
  areOptionsLoaded: boolean;
  disabled: boolean;
  placeholder?: string;
  errors: any;
  checked: boolean;
  onEmailTeamChange: (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => void;
  onRoleChange: (
    model: string,
    valueList: AutocompleteOption[] | AutocompleteOption
  ) => void;
}

export const NotificationRuleRoleAutocomplete: React.FC<
  NotificationRuleRoleAutocompleteProps
> = ({
  model,
  mainOptionList,
  roleOptionList,
  limitTags,
  mainValues,
  roleValues,
  initialMainSelectedValues,
  initialRoleSelectedValues,
  enableCheckbox,
  areOptionsLoaded,
  disabled,
  placeholder,
  errors,
  checked,
  onEmailTeamChange,
  onRoleChange,
}) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 3 }}>
      <Grid item xs={8}>
        <Autocomplete
          model={model}
          onChangeCallBack={(
            model: string,
            valueList: Array<AutocompleteOption> | AutocompleteOption
          ) => {
            onEmailTeamChange(model, valueList);
          }}
          freeSolo
          optionList={mainOptionList}
          limitTags={limitTags}
          classes={{
            root: 'multi-select-field-1',
            paper: 'multi-select-field-paper-1',
            inputRoot: errors.emails ? 'input-validation-error-1' : '',
          }}
          icons={{
            ChipRemoveIcon: <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />,
          }}
          values={mainValues}
          initialSelectedValues={initialMainSelectedValues}
          enableCheckbox={enableCheckbox}
          areOptionsLoaded={areOptionsLoaded}
          disabled={disabled}
          placeholder={placeholder}
        />
      </Grid>
      <Grid item xs={4}>
        <Autocomplete
          placeholder="Role"
          model={`${model}-role`}
          onChangeCallBack={(
            model: string,
            valueList: Array<AutocompleteOption> | AutocompleteOption
          ) => {
            onRoleChange(model, valueList);
          }}
          freeSolo
          optionList={roleOptionList}
          limitTags={15}
          classes={{
            root: 'multi-select-field-1',
            paper: 'multi-select-field-paper-1',
            inputRoot: errors.emails ? 'input-validation-error-1' : '',
          }}
          icons={{
            ChipRemoveIcon: <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />,
          }}
          values={roleValues}
          initialSelectedValues={initialRoleSelectedValues}
          enableCheckbox
          areOptionsLoaded
          disabled={!checked}
        />
      </Grid>
    </Grid>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../Common/store';
import { TenantDetails } from '../../Onboarding/models/onboarding-tenant-details.model';

// state definition
export interface AuthState {
  accessToken: string | null;
  tenantDetails: TenantDetails | null;
}

// initial state
const initialState: AuthState = {
  accessToken: null,
  tenantDetails: null,
};

// slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setTenantDetails: (state, action: PayloadAction<TenantDetails | null>) => {
      state.tenantDetails = action.payload;
    },
  },
});

// actions
export const { setAccessToken, setTenantDetails } = authSlice.actions;

// selectors
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const getTenantDetails = (state: RootState) => state.auth.tenantDetails;

// exporting reducer
const authReducer = authSlice.reducer;
export default authReducer;

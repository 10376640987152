import { Box, Grid, Typography } from '@mui/material';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { TicketTypes } from 'shared/models/data/risk-table.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { TicketCreation } from '../FilterActions/models/TicketCreation.model';
import TicketActionListDetails from '../TicketActionListDetails';

export interface ActionTicketResponseModalProps extends BaseComponentProps {
  isOpen: boolean;
  handleClose: () => void;
  responsePayload: Array<TicketCreation>;
  type?: TicketTypes;
}

export const ActionTicketResponseModal: FunctionComponent<
  ActionTicketResponseModalProps
> = ({ responsePayload, isOpen, handleClose, type }) => {
  const { t: translation } = useTranslation();
  const [successItems, setSuccessItems] = useState<TicketCreation[]>([]);
  const [failedItems, setFailedItems] = useState<TicketCreation[]>([]);
  useEffect(() => {
    const success = responsePayload.filter((item) => item.isSuccess);
    const failed = responsePayload.filter((item) => !item.isSuccess);
    setSuccessItems(success);
    setFailedItems(failed);
  }, [responsePayload]);

  const getTitle = useMemo(() => {
    if (successItems.length > 0 && failedItems.length === 0) {
      return type === TicketTypes.UNLINK_TICKET
        ? translation(`findings.ticket.unlinkTicketSuccess`)
        : translation(`findings.ticket.createTicketSuccess`);
    }
    if (successItems.length === 0 && failedItems.length > 0) {
      return type === TicketTypes.UNLINK_TICKET
        ? translation(`findings.ticket.unlinkTicketFailed`)
        : translation(`findings.ticket.createTicketFailed`);
    }
    if (successItems.length > 0 && failedItems.length > 0) {
      return translation(`findings.ticket.createTicketPartially`);
    }
  }, [successItems, failedItems, type]);

  const getSuccessMessage = useMemo(() => {
    switch (type) {
      case TicketTypes.SINGLE_FINDING_TICKET:
        return 'findings.ticket.createSuccessMessage';
      case TicketTypes.MULTI_FINDINGS_SINGLE_TICKET:
        return `findings.ticket.createSuccessMessageMultipleFindings`;
      case TicketTypes.UNLINK_TICKET:
        return `findings.ticket.unlinkSuccessMessage`;
      default:
        return '';
    }
  }, [type, successItems]);

  const getFailedMessage = useMemo(() => {
    switch (type) {
      case TicketTypes.SINGLE_FINDING_TICKET:
        return 'findings.ticket.createFailMessage';
      case TicketTypes.MULTI_FINDINGS_SINGLE_TICKET:
        return `findings.ticket.createFailMessageMultipleFindings`;
      case TicketTypes.UNLINK_TICKET:
        return `findings.ticket.unlinkFailMessage`;
      default:
        return '';
    }
  }, [type, failedItems]);
  return (
    <CommonDialog
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      displayCloseButton={false}
      onSave={handleClose}
      title={getTitle}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />}
      saveButtonText={' Got it'}
      classes="ticket-action-response-modal"
    >
      <Box className="ticket-action-response-container">
        <Grid container display="flex" gap="10px">
          {successItems.length > 0 && (
            <Grid item xs={12} className="ticket-action-success">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_CHECK_ICON} />
              <Typography
                variant="inherit"
                className="body-1"
                dangerouslySetInnerHTML={{
                  __html: translation(getSuccessMessage, {
                    param1:
                      type === TicketTypes.MULTI_FINDINGS_SINGLE_TICKET
                        ? successItems[0].title.length
                        : successItems.length,
                  }),
                }}
              ></Typography>
            </Grid>
          )}
          {successItems?.map((item: any) => (
            <Grid item xs={12}>
              <TicketActionListDetails {...item} isSuccess={true} />
            </Grid>
          ))}
        </Grid>
        {successItems.length > 0 && failedItems.length > 0 && (
          <Box className="container-divider"></Box>
        )}
        <Grid container display="flex" gap="10px">
          {failedItems.length > 0 && (
            <Grid item xs={12} className="ticket-action-failed">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON} />
              <Typography
                variant="inherit"
                className="body-1"
                dangerouslySetInnerHTML={{
                  __html: translation(getFailedMessage, {
                    param1:
                      type === TicketTypes.MULTI_FINDINGS_SINGLE_TICKET
                        ? failedItems[0].title.length
                        : failedItems.length,
                  }),
                }}
              ></Typography>
            </Grid>
          )}
          {failedItems?.map((item: any) => (
            <Grid item xs={12}>
              <TicketActionListDetails {...item} isSuccess={false} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </CommonDialog>
  );
};
export default ActionTicketResponseModal;

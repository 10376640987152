import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface LabelCellProps extends BaseComponentProps {
  labels: Array<string>;
}

export const LabelCell: FunctionComponent<LabelCellProps> = ({ labels }) => {
  const renderLabelItems = () => {
    if (labels.length > 2) {
      return (
        <>
          {labels.slice(0, 2).map((label) => (
            <div className="label-cell-item">{label}</div>
          ))}
          <div className="label-cell-item">+{labels.length - 2}</div>
        </>
      );
    }

    return labels.map((label) => (
      <div className="label-cell-item" title={label}>
        {label}
      </div>
    ));
  };

  return <div className="label-cell">{renderLabelItems()}</div>;
};

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual } from 'lodash';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { CloudWorkspace } from '../../interfaces/CloudWorkspace';
import AddIcon from '@mui/icons-material/Add';

import {
  useDeleteCloudWorkspaceMutation,
  useFetchWorkspaceFilterInformationForFieldMutation,
  useSearchCloudWorkspacesMutation,
} from '../../store/api';
import { useTranslation } from 'react-i18next';
import ConnectAWSAccoutDialogContent from './Dialog/ConnectAWSAccoutDialogContent';
import { CloudWorkspaceDialogType } from '../../interfaces/CloudWorkspaceType.enum';
import ConnectCloudAzureDialogContent from './Dialog/ConnectAzureAccoutDialogContent';
import { FormattedMessage } from 'shared/components/FormattedMessage/FormattedMessage';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import CodeRepositoryDialogContent from './Dialog/CodeRepositoryDialogContent';
import SettingsGridColumns from '../SettingsLayout/settings-data-grid';
import { ActionResponseModal } from 'Risk/components/ActionResponseModal';
import { Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import {
  RowClickedEvent,
  GetRowIdParams,
  AgGridEvent,
} from 'ag-grid-community';
import CardWrapper from 'Common/components/CardWrapper';
import CommonPagination from 'Common/components/Pagination';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import {
  getFilterList,
  getFilterSelections,
  getSettingsSearchParams,
  setListFilter,
  setListItems,
  setSearchParams,
  SettingsFilterItem,
  SettingsFilters,
} from 'Settings/store';
import { OrderState } from 'Common/utils/sort';
import { AuthorizedContent } from '@frontegg/react';
import { CloudWorkspacesFilters } from 'Settings/interfaces/CloudWorkspaceFilterItems';
import { MenuItems, SelectionItems } from 'DashboardFilter/store';
import ChipDropdown from 'Common/components/ChipDropdown/ChipDropdown';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';

export default function CloudWorkspacesList() {
  const { t: translation } = useTranslation();
  const gridRef = useRef<AgGridEvent | null>(null);
  const dispatch = useCommonDispatch();
  const searchParams = useCommonSelector(getSettingsSearchParams);

  const [shouldIgnoreRowClick, setShouldIgnoreRowClick] =
    useState<boolean>(false);
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<CloudWorkspace | null>(null);
  const [onSave, setOnSave] = useState<boolean>(false);
  const [isDialogLoading, setIsLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [
    selectedConnectCloudWorkspaceDialogType,
    setSelectedConnectCloudWorkspaceDialogType,
  ] = useState<CloudWorkspaceDialogType>(CloudWorkspaceDialogType.OPUS);
  const allDataMenu: MenuItems = useCommonSelector(getFilterList);
  const filterSelections: SelectionItems =
    useCommonSelector(getFilterSelections);

  const [
    cloudWorkspaceDeleteErrorPayload,
    setCloudWorkspaceDeleteErrorPayload,
  ] = useState<Array<string>>([]);

  const [cloudWorkspaceErrorPayload, setCloudWorkspaceErrorPayload] =
    useState<any>({});

  const [searchWorkspaces, { isLoading, data: workspacesList }] =
    useSearchCloudWorkspacesMutation();
  const userPermissions = usePermissions();

  useEffect(() => {
    searchWorkspaces({
      ...searchParams,
    });
  }, [searchParams]);

  const hasWorkspacesReadPermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_WORKSPACES_READ
  );
  const hasWorkspacesWritePermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_WORKSPACES_WRITE
  );

  function handleDialogOpen(connectType: CloudWorkspaceDialogType) {
    setDialogOpen(true);
    setSelectedConnectCloudWorkspaceDialogType(connectType);
  }

  function handleDialogClose(shouldReload?: boolean) {
    setDialogOpen(false);
    setSelectedWorkspace(null);
    if (shouldReload === true) {
      searchWorkspaces({
        ...searchParams,
      });
    }
  }

  const isErrorModalOpen = Boolean(cloudWorkspaceErrorPayload.error);

  const actionTitle = useMemo(() => {
    const actionType = cloudWorkspaceErrorPayload?.isCreate
      ? 'create'
      : 'update';
    return translation(`settings.details.failedWorkspace`, {
      param1: translation(`common.${actionType}`),
    });
  }, [cloudWorkspaceErrorPayload]);

  const warningMessage = useMemo(() => {
    const actionType = cloudWorkspaceErrorPayload?.isCreate
      ? 'create'
      : 'update';
    return translation(`settings.details.failedWorkspaceMessage`, {
      param1: translation(`common.${actionType}`),
    });
  }, [cloudWorkspaceErrorPayload]);

  const handleCloseModal = () => {
    setCloudWorkspaceErrorPayload([]);
  };

  function getDialogTitle(connectType: CloudWorkspaceDialogType) {
    if (selectedWorkspace) {
      return translation(`settings.details.editWorkspace`);
    }
    switch (connectType) {
      case CloudWorkspaceDialogType.AWS: {
        return translation(`settings.details.connectCloudAccount`);
      }
      case CloudWorkspaceDialogType.AZURE: {
        return translation(`settings.details.connectAzureSubscription`);
      }
      case CloudWorkspaceDialogType.OPUS: {
        return translation(`settings.details.connectCodeRepository`);
      }
    }
  }

  function getDialogIcon(connectType: CloudWorkspaceDialogType) {
    switch (connectType) {
      case CloudWorkspaceDialogType.AWS: {
        return <OpusSvgIcon type={SVG_ICON_TYPES.AWS_MAIN_ICON} />;
      }
      case CloudWorkspaceDialogType.AZURE: {
        return <OpusSvgIcon type={SVG_ICON_TYPES.AZURE_MAIN_ICON} />;
      }
      case CloudWorkspaceDialogType.OPUS: {
        return <OpusSvgIcon type={SVG_ICON_TYPES.CLOUD_SOURCE_ICON} />;
      }
    }
  }

  function editItem(workspace: CloudWorkspace) {
    if (!hasWorkspacesWritePermission) return;
    setSelectedWorkspace(workspace);
    handleDialogOpen(CloudWorkspaceDialogType.OPUS);
  }

  function onDelete(decision: boolean, deleteResponse: any) {
    if (deleteResponse?.success) {
      searchWorkspaces({
        ...searchParams,
      });
    } else {
      setCloudWorkspaceDeleteErrorPayload(deleteResponse?.payload || []);
    }
  }
  const renderDialogContent = () => {
    switch (selectedConnectCloudWorkspaceDialogType) {
      case CloudWorkspaceDialogType.AWS: {
        return (
          <ConnectAWSAccoutDialogContent
            setIsLoading={setIsLoading}
            setOnSave={setOnSave}
            onSave={onSave}
            onClose={handleDialogClose}
          />
        );
      }
      case CloudWorkspaceDialogType.AZURE: {
        return (
          <ConnectCloudAzureDialogContent
            setIsLoading={setIsLoading}
            setOnSave={setOnSave}
            onSave={onSave}
            onClose={handleDialogClose}
          />
        );
      }
      case CloudWorkspaceDialogType.OPUS: {
        return (
          <CodeRepositoryDialogContent
            onClose={handleDialogClose}
            setIsLoading={setIsLoading}
            setOnSave={setOnSave}
            onSave={onSave}
            workspace={selectedWorkspace}
            setSubmitResponse={setCloudWorkspaceErrorPayload}
          />
        );
      }
    }
  };

  const onPageChange = (pageNumber: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        skip: searchParams.take * (pageNumber - 1),
      })
    );
  };

  const onPageSizeChange = (pageSize: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        take: pageSize,
        skip: 0,
      })
    );
  };

  const workspaceColums = useMemo(() => {
    return SettingsGridColumns.getCloudWorkspacesColumns({
      t: translation,
      useDeleteMutation: useDeleteCloudWorkspaceMutation,
      onDelete,
      setShouldIgnoreRowClick,
      requiredPermissions: [ApplicationPermission.SETTINGS_WORKSPACES_WRITE],
    });
  }, []);

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    if (columnOrderPayload.length) {
      dispatch(
        setSearchParams({
          ...searchParams,
          order: {
            field: columnOrderPayload[0].field,
            type: columnOrderPayload[0].type,
          },
        })
      );
    }
  };

  const handleSelect = (selectedItem: any, data: any) => {
    const selectedItems = data.items.filter((item: any) => item.checked);
    const isAllSelected = selectedItems.some((item: any) => item.id === 'All');

    if (isAllSelected && selectedItems.length > 1) {
      handleDeselectAllOption(data);
    } else if (selectedItem.id === 'All') {
      handleAllSelect(data);
    } else {
      handleSelectItem(selectedItem, data);
    }
  };

  const handleDeselectAllOption = (data: any) => {
    const updatedArray = allDataMenu.map((submenu: any) => {
      if (submenu.id === data.id) {
        const updatedItems = submenu.items.map((item: any) => ({
          ...item,
          checked: item.id !== 'All' ? true : false,
        }));
        return { ...submenu, items: updatedItems };
      }
      return submenu;
    });

    dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
  };

  const handleAllSelect = (data: any) => {
    let updatedArray = allDataMenu.map((submenu: any) => {
      if (submenu.id === data.id) {
        const updatedItems = submenu.items.map((item: any) => {
          return item.id === 'All'
            ? { ...item, checked: true }
            : { ...item, checked: false };
        });
        return { ...submenu, items: updatedItems };
      }
      return submenu;
    });

    dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    dispatch(
      setSearchParams({
        ...searchParams,
      })
    );
  };

  useEffect(() => {
    const filterObj: SettingsFilters = {};
    const aliasKey = 'alias';
    const aliasValue = searchParams.filter?.[aliasKey];

    if (aliasValue) {
      filterObj[aliasKey] = aliasValue;
    }

    filterSelections.forEach((filterSelection) => {
      const selectedItems: Array<FilterOption> =
        filterSelection.selectionValues;
      const selectedItemIds = selectedItems.map(
        (selectedItem: FilterOption) => selectedItem.id
      );

      if (selectedItems.length === 0) handleAllSelect(filterSelection);
      if (selectedItemIds.length > 0 && !selectedItemIds.includes('All')) {
        filterObj[filterSelection.id] = selectedItemIds.map((itemId) => ({
          value: itemId,
        }));
      }
    });
    if (!isEqual(filterObj, searchParams.filter)) {
      dispatch(
        setSearchParams({
          ...searchParams,
          filter: filterObj,
        })
      );
    }
  }, [filterSelections]);

  const handleSelectItem = (id: any, data: any) => {
    let updatedArray = allDataMenu.map((submenu: any) => {
      if (submenu.id === data.id) {
        const updatedItems = submenu.items.map((item: any) => {
          if (item.id === 'All') {
            return { ...item, checked: false };
          } else if (item.id === id.id) {
            return { ...item, checked: !item.checked };
          }
          return item;
        });
        return { ...submenu, items: updatedItems };
      }
      return submenu;
    });

    dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
  };

  const getChipData = (id: string) => {
    const inputObj = allDataMenu.find((item) => item.id === id);
    const filteredItems = inputObj?.items.filter((item) => item.checked) || [];
    return { ...inputObj, items: filteredItems };
  };
  const onSearch = useCallback(
    (searchInput: string) => {
      let filterObj = { ...searchParams.filter };
      filterObj.alias = { value: searchInput };
      dispatch(
        setSearchParams({
          ...searchParams,
          filter: filterObj,
        })
      );
    },
    [searchParams, dispatch]
  );

  const searchIntputValue = useMemo(() => {
    if (searchParams.filter && searchParams.filter.alias) {
      return (searchParams.filter.alias as SettingsFilterItem).value || '';
    }
    return '';
  }, [searchParams]);

  if (!hasWorkspacesReadPermission) return <ForbiddenPage />;

  return (
    <>
      <Box m={4} gap={2} display="grid">
        <CardWrapper padding={2} className={`settings-header`} height={'64px'}>
          <Grid className="settings-header-container" container>
            <Grid item className="settings-header-filters">
              <>
                <TextField
                  value={searchIntputValue}
                  className="filter-input filter-main-input"
                  placeholder={translation(`common.searchPlaceholder`)}
                  onChange={(e: any) => {
                    onSearch(e.target.value);
                  }}
                  InputProps={{
                    className: 'search-filter-input',
                    startAdornment: (
                      <OpusSvgIcon
                        type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON}
                      />
                    ),
                  }}
                />

                <Grid direction="row" container alignItems="center">
                  {CloudWorkspacesFilters.map((item) => (
                    <Grid item display="flex" alignItems="center" gap={'12px'}>
                      <ChipDropdown
                        setListItems={setListItems}
                        key={item.id}
                        item={getChipData(item.id)}
                        allOption
                        data={allDataMenu}
                        mutationProps={{
                          params: {},
                          fetchMutation:
                            useFetchWorkspaceFilterInformationForFieldMutation,
                        }}
                        setListFilter={setListFilter}
                        handleItemSelect={handleSelect}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            </Grid>
            <Grid item className="settings-header-action-button">
              <AuthorizedContent
                requiredPermissions={[
                  ApplicationPermission.SETTINGS_WORKSPACES_WRITE,
                ]}
                render={(isAuthorized) => (
                  <Button
                    startIcon={<AddIcon fontSize="small" />}
                    onClick={() =>
                      handleDialogOpen(CloudWorkspaceDialogType.OPUS)
                    }
                    className="opus-primary-button settings-header-button"
                    disabled={!isAuthorized}
                  >
                    <FormattedMessage
                      id="settings.details.addWorkspace"
                      defaultMessage="Add Workspace"
                    />
                  </Button>
                )}
              />
            </Grid>
          </Grid>
        </CardWrapper>
        <Box height={'58vh'}>
          <CommonSimpleDataGrid
            rowData={workspacesList?.data}
            columnDefs={workspaceColums}
            rowSelection="multiple"
            defaultColDef={{
              resizable: true,
              sortable: true,
            }}
            gridRef={gridRef}
            containerClassName="settings-data-grid"
            suppressRowClickSelection
            onRowClicked={(event: RowClickedEvent) => {
              const shouldIgnoreElement = Boolean(
                event.eventPath?.find((value: EventTarget) =>
                  (value as Element)?.classList?.contains('ignore-row-click')
                )
              );

              if (shouldIgnoreElement || shouldIgnoreRowClick) {
                return;
              }

              editItem(event.data);
            }}
            visibilityControlProps={{
              enableVisibilityControls: false,
              columns: [],
            }}
            onSort={onHandleColumnOrderChange}
            isLoading={isLoading}
            keepCurrentSelections
            otherComponents={{
              NoDataBackdropComponent: <NoDataBackdrop />,
            }}
            loadingOverlayComponent={() => <CircularProgress />}
            getRowId={(row: GetRowIdParams) => {
              return row.data.id;
            }}
          />
        </Box>
        <Box mt={2} className="settings-pagination" mx={2}>
          <CommonPagination
            totalItems={workspacesList?.totalItems || 0}
            pageSize={searchParams.take}
            currentPage={
              searchParams.skip ? searchParams.skip / searchParams.take + 1 : 1
            }
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
          />
        </Box>
      </Box>

      <ActionResponseModal
        isOpen={Boolean(cloudWorkspaceDeleteErrorPayload?.length)}
        handleClose={() => {
          setCloudWorkspaceDeleteErrorPayload([]);
        }}
        title={translation(`settings.details.failedToDeleteWorkspace`)}
        listsDetails={[
          {
            warningMessage: translation(`settings.tabs.services`),
            displayItems: cloudWorkspaceDeleteErrorPayload,
            listTitle: translation(`settings.tabs.services`),
          },
        ]}
      />
      <CommonDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        icon={getDialogIcon(selectedConnectCloudWorkspaceDialogType)}
        title={getDialogTitle(selectedConnectCloudWorkspaceDialogType)}
        closeButtonText={translation('common.cancel')}
        isLoading={isDialogLoading}
        loadingButtonText={translation('common.connecting')}
        saveButtonText={
          selectedWorkspace
            ? translation('common.save')
            : translation('common.connect')
        }
        onSave={() => {
          setOnSave(true);
        }}
        buttonRequiredPermissions={[
          ApplicationPermission.SETTINGS_WORKSPACES_WRITE,
        ]}
      >
        {renderDialogContent()}
      </CommonDialog>
      <ActionResponseModal
        isOpen={isErrorModalOpen}
        handleClose={handleCloseModal}
        title={actionTitle}
        listsDetails={[
          {
            warningMessage: warningMessage,
            displayItems: [cloudWorkspaceErrorPayload.error],
            listTitle: 'Error message',
          },
        ]}
      />
    </>
  );
}

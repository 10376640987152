import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DashboardReportsRiskWidgetProps } from '../DashboardReportsRisk/DashboardReportsRisk';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { useGetCampaignListMutation } from 'Campaigns/store/api';
import ActiveCampaignCarouselCard from 'Dashboard/components/ActiveCampaignCarouselCard';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  CampaignModel,
  CampaignStatus,
} from 'shared/models/data/campaigns.model';

interface DashboardReportsRiskActiveCampaignsProps
  extends BaseComponentProps,
    DashboardReportsRiskWidgetProps {}

export const DashboardReportsRiskActiveCampaigns: FunctionComponent<
  DashboardReportsRiskActiveCampaignsProps
> = () => {
  const [
    getCampaignList,
    { isLoading: campaignListLoading, data: campaignList },
  ] = useGetCampaignListMutation();

  const activeCampaigns = useMemo<Array<CampaignModel>>(() => {
    return (
      campaignList?.filter(
        (campaign) => campaign.status === CampaignStatus.ACTIVE
      ) || []
    );
  }, [campaignList]);

  useEffect(() => {
    getCampaignList('');
  }, []);

  return (
    <div className="dashboard-reports-risk-active-campaigns-widget-container dashboard-reports-risk-widget-container">
      <div className="dashboard-reports-risk-widget-title">
        <div className="dashboard-reports-risk-widget-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.BULLHORN_ICON} />
        </div>
        <div className="dashboard-reports-risk-widget-title-text">
          Active Campaigns ({activeCampaigns.length || 0})
        </div>
      </div>
      <div className="dashboard-reports-risk-widget-body dashboard-reports-risk-active-campaigns-widget-body">
        {activeCampaigns.map((activeCampaign) => (
          <ActiveCampaignCarouselCard {...activeCampaign} />
        )) || <></>}
      </div>
    </div>
  );
};

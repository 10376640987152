import React from 'react';
import { Box, CircularProgress, List, Stack, Typography } from '@mui/material';
import FormattedMessage from 'shared/components/FormattedMessage';

interface TimelineComponentProps<T> {
  data: T[] | undefined;
  isLoading: boolean;
  noEventsMessageId: string;
  noEventsDefaultMessage: string;
  renderEvent: (event: T, index: number) => React.ReactNode;
  renderDefaultTimelineEvent?: () => React.ReactNode;
}

const TimelineComponent = <T,>({
  data,
  isLoading,
  noEventsMessageId,
  noEventsDefaultMessage,
  renderEvent,
  renderDefaultTimelineEvent,
}: TimelineComponentProps<T>) => {
  return (
    <Stack className="timeline-container">
      <Box className="timeline-events-list-container">
        {renderDefaultTimelineEvent && (
          <div className="children-item default-item">
            {renderDefaultTimelineEvent()}
          </div>
        )}

        {isLoading ? (
          <Box textAlign="center" p="2rem">
            <CircularProgress />
          </Box>
        ) : !data || data.length === 0 ? (
          <Typography>
            <FormattedMessage
              id={noEventsMessageId}
              defaultMessage={noEventsDefaultMessage}
            />
          </Typography>
        ) : (
          <List disablePadding>
            {data.map((event, index) => {
              return (
                <div className="children-item">{renderEvent(event, index)}</div>
              );
            })}
          </List>
        )}
      </Box>
    </Stack>
  );
};

export default TimelineComponent;

import { FunctionComponent, MouseEvent, useMemo, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  ExtendedFilterCategory,
  MultiSelectState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { FilterChipGroupChangeHandlerProps } from '../FilterChipGroup/FilterChipGroup';
import FilterContentDropdown from '../FilterContentDropdown';
import { useFeatureEntitlements } from '@frontegg/react';
import { NotEntitledJustification } from 'shared/components/EntitledContent/EntitledContent';
import CommonCheckbox from 'shared/components/CommonCheckbox';

interface CategoryDropdownItemProps
  extends BaseComponentProps,
    ExtendedFilterCategory,
    FilterChipGroupChangeHandlerProps {}

export const CategoryDropdownItem: FunctionComponent<
  CategoryDropdownItemProps
> = ({
  id,
  label,
  type,
  state,
  categories,
  categoryState,
  onChange,
  entitlement,
  displaySelectionCounter,
  displayNestedSelectionIndicator,
  disableSearch,
}) => {
  const [itemAnchorEl, setItemAnchorEl] = useState<HTMLElement | null>(null);

  const { isEntitled, justification } = useFeatureEntitlements(
    entitlement || ''
  );

  const updatedState = useMemo(() => {
    const categoryStateItem = categoryState[id];

    return {
      ...state,
      ...(categoryStateItem || {}),
    };
  }, [state, categoryState, id]);

  const handleItemClick = (event: MouseEvent<HTMLDivElement>) => {
    setItemAnchorEl(event.currentTarget.parentElement);
  };

  const handleItemPopoverClose = () => {
    setItemAnchorEl(null);
  };

  if (entitlement && !isEntitled) {
    return <></>;
  }

  const renderNestedSelectionIndicator = () => {
    if (displayNestedSelectionIndicator) {
      const allNestedCategoriesSelected = categories?.every(
        (category) =>
          (categoryState[category.id] as MultiSelectState)?.allSelected
      );

      const someNestedCategoriesSelected = categories?.some(
        (category) =>
          (categoryState[category.id] as MultiSelectState)?.selectedOptions
            ?.length
      );

      return (
        <CommonCheckbox
          readOnly
          indeterminate={
            allNestedCategoriesSelected ? false : someNestedCategoriesSelected
          }
          checked={allNestedCategoriesSelected}
        />
      );
    }

    return <></>;
  };

  const renderSelectionCounter = () => {
    const selectionCount = (updatedState as SingleSelectState)?.selectedOptions
      ?.length;

    if (selectionCount && displaySelectionCounter) {
      return (
        <div className="category-dropdown-item-selection-counter">
          {selectionCount}
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <div
        className={`category-dropdown-item ${
          itemAnchorEl ? 'category-dropdown-item-active' : ''
        }`}
        onClick={handleItemClick}
      >
        <div className="category-dropdown-item-start-area">
          {renderNestedSelectionIndicator()}
          <span className="body-1 item-text">{label}</span>
        </div>
        <div className="category-dropdown-item-end-area">
          {renderSelectionCounter()}
          <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_RIGHT_ICON} />
        </div>
      </div>

      <FilterContentDropdown
        {...{
          id,
          label,
          type,
          state: updatedState,
          anchorEl: itemAnchorEl,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          categoryState,
          handleClose: handleItemPopoverClose,
          onChange,
          categories,
          open: Boolean(itemAnchorEl),
          disableSearch,
        }}
      />
    </>
  );
};

import { FunctionComponent, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  SelectChipCategory,
  SelectChipChangePayload,
  SelectChipGroupMutationProps,
} from '../SelectChipGroup';
import { MenuItem, MenuList } from '@mui/material';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { SelectChipOptionDropdown } from '../SelectChipOptionDropdown/SelectChipOptionDropdown';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface SelectChipCategoryDropdownProps extends BaseComponentProps {
  categories: Array<SelectChipCategory>;
  onChange: (categoryId: string, values: Array<AutocompleteOption>) => void;
  selectedValues: SelectChipChangePayload;
  mutationProps?: SelectChipGroupMutationProps;
}

export const SelectChipCategoryDropdown: FunctionComponent<
  SelectChipCategoryDropdownProps
> = ({ categories, onChange, selectedValues, mutationProps }) => {
  const [activeCategory, setActiveCategory] =
    useState<SelectChipCategory | null>(null);

  const categoryClickHandle = (category: SelectChipCategory) => {
    setActiveCategory(category);
  };

  const categoryBackClickHandle = () => {
    setActiveCategory(null);
  };

  return (
    <div className="select-chip-category-dropdown-container">
      {activeCategory ? (
        <SelectChipOptionDropdown
          {...activeCategory}
          onChange={onChange}
          backClickHandle={categoryBackClickHandle}
          selectedValues={selectedValues[activeCategory.id]}
          enableHeader
          mutationProps={activeCategory?.mutationProps || mutationProps}
        />
      ) : (
        <MenuList>
          {categories.map((category) => (
            <MenuItem
              className="select-chip-category-dropdown-item"
              onClick={() => categoryClickHandle(category)}
            >
              <span>{category.label}</span>
              <OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_RIGHT_ICON} />
            </MenuItem>
          ))}
        </MenuList>
      )}
    </div>
  );
};

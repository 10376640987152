import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import ResourceAssetDetailsDrawerContent from './components/ResourceAssetDetailsDrawerContent';

export interface ResourceAssetDetailsDrawerProps extends BaseComponentProps {}

interface ResourceAssetDetailsDrawerWrapperProps extends BaseComponentProps {
  openModal: boolean;
}

export const ResourceAssetDetailsDrawerWrapper: FunctionComponent<
  ResourceAssetDetailsDrawerWrapperProps
> = ({ openModal, children }) => {
  const { t: translation } = useTranslation();
  const [pageNotFound, setPageNotFound] = useState(false);
  const [urlSearchParams] = useQueryParams();

  const Page404 = () => {
    return (
      <div className="finding-details-page-not-found-page-container">
        <div className="finding-details-page-not-found">
          <OpusSvgIcon type={SVG_ICON_TYPES.RADAR_GRAY_ICON} />
          <h1> {translation(`findings.details.findingMissing`)}</h1>
          <h2>{translation(`findings.details.findingMissingInfoMessage`)}</h2>
        </div>
      </div>
    );
  };

  return <>{pageNotFound ? <Page404 /> : children}</>;
};

export const ResourceAssetDetailsDrawer: FunctionComponent<
  ResourceAssetDetailsDrawerProps
> = () => {
  const { search } = useLocation();
  const reactiveSearchParams = new URLSearchParams(search);
  const [, setReactiveSearchParams] = useSearchParams();

  const open = useMemo<boolean>(() => {
    return Boolean(reactiveSearchParams.get('openResourceId'));
  }, [reactiveSearchParams.get('openResourceId')]);

  const handleClose = () => {
    reactiveSearchParams.delete('openResourceId');

    setReactiveSearchParams(reactiveSearchParams);
  };

  return (
    <>
      <PopoverWrapper
        classes="resource-details-popover"
        showPopover={open}
        handleClose={handleClose}
      >
        <ResourceAssetDetailsDrawerWrapper openModal={open}>
          <ResourceAssetDetailsDrawerContent />
        </ResourceAssetDetailsDrawerWrapper>
      </PopoverWrapper>
    </>
  );
};

import { Button } from '@mui/material';
import React, { ReactElement, useCallback, useState } from 'react';
import {
  useDropzone,
  FileRejection,
  DropEvent,
  FileWithPath,
  Accept,
} from 'react-dropzone';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import FormattedMessage from '../FormattedMessage';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

interface DropzoneProps {
  onDrop: (
    acceptedFiles: FileWithPath[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  acceptedFormat?: Accept;
  maxFileSize?: number;
  maxFileUpload?: number;
}

export const DropzoneFileUploader: React.FC<DropzoneProps> = ({
  onDrop,
  acceptedFormat,
  maxFileSize,
  maxFileUpload = 1,
}) => {
  const [isDragActive, setIsDragActive] = useState(false);

  const handleDragEnter = () => {
    setIsDragActive(true);
  };

  const handleDragLeave = () => {
    setIsDragActive(false);
  };

  const handleDrop = useCallback(
    (
      acceptedFiles: FileWithPath[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      setIsDragActive(false);
      onDrop(acceptedFiles, fileRejections, event);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleDrop,
    onDragEnter: handleDragEnter,
    onDragLeave: handleDragLeave,
    maxSize: maxFileSize,
    maxFiles: maxFileUpload,
    accept: acceptedFormat,
  });

  const files = acceptedFiles.map((file: any) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const maxFileSizeMB = maxFileSize
    ? parseFloat((maxFileSize / (1024 * 1024)).toFixed(2)).toString()
    : 'N/A';

  return (
    <div className={'dropzone-uploader-container'}>
      <div
        {...getRootProps({
          className: `dropzone ${isDragActive ? 'drag-active ' : ''}`,
        })}
      >
        <input {...getInputProps()} />
        <div className="dropzone-content">
          <OpusSvgIcon type={SVG_ICON_TYPES.UPLOAD_ICON} />
          <p className="body-5">
            <b>
              <FormattedMessage
                defaultMessage="Click to upload"
                id="risk.import.clickToUpload"
              />
            </b>{' '}
            <FormattedMessage
              defaultMessage="or drag and drop"
              id="risk.import.dragAndDrop"
            />
          </p>
          <p className="body-5">
            <b>
              <FormattedMessage
                defaultMessage="Max. File Size"
                id="risk.import.maxFileSize"
              />
              : {maxFileSizeMB} MB
            </b>
          </p>
          {files.length > 0 && (
            <p className="body-5">
              <b>
                <FormattedMessage defaultMessage="File" id="risk.import.file" />
                :
              </b>
              {files}
            </p>
          )}
          <Button
            onClick={open}
            className="base-opus-button common-button-secondary"
          >
            <FormattedMessage
              defaultMessage="Browse File"
              id="risk.import.browseFile"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

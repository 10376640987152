import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FilterItem,
  FilterOption,
} from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';

export interface SelectionItem {
  id: string;
  type: TypeFilter;
  selectionValues: Array<FilterOption> | Array<any>;
}

export interface MenuItems extends Array<FilterItem> {}

export interface SelectionItems extends Array<SelectionItem> {}

const initialState: {
  listItems: MenuItems;
  selections: SelectionItems;
} = {
  listItems: [],
  selections: [],
};
export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setListFilter: (
      state,
      action: PayloadAction<
        MenuItems | { list: MenuItems; isSelectingItem: boolean }
      >
    ) => {
      const updatedSelections: SelectionItems = [];

      const listMenu = Array.isArray(action.payload)
        ? [...action.payload]
        : action.payload.list;
      const modifiedArray = listMenu.map((object) => {
        if (
          object.dateRange ||
          object.items.some((item: any) => item.checked)
        ) {
          updatedSelections.push({
            id: object.id,
            type: object.type as TypeFilter,
            selectionValues:
              object.dateRange ||
              object.items?.filter((item: any) => item.checked),
          });
        }

        return {
          ...object,
          items: object.items.map((item) => {
            if (!item.hasOwnProperty('checked')) {
              return {
                ...item,
                checked: false,
              };
            }
            return { ...item };
          }),
        };
      });

      if (
        Array.isArray(action.payload) === false &&
        (action.payload as { list: MenuItems; isSelectingItem: boolean })
          .isSelectingItem
      ) {
        state.selections = updatedSelections;
      }

      state.listItems = modifiedArray;
    },
    setListItems: (state, action: PayloadAction<MenuItems>) => {
      state.listItems = action.payload;
    },
  },
});

const filterReducer = filterSlice.reducer;
export default filterReducer;

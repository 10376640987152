import moment from 'moment';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { dateTimeFormat } from 'shared/services/date.service';

interface DashboardReportsHeaderProps extends BaseComponentProps {}

export const DashboardReportsHeader: FunctionComponent<
  DashboardReportsHeaderProps
> = () => {
  return (
    <div className="dashboard-reports-header">
      <div className="dashboard-reports-header-icon-container">
        <div className="dashboard-reports-header-icon">
          <img
            src="https://res.cloudinary.com/opus-security/image/upload/v1694180958/application-logos-svg/Opus.svg"
            alt="opus-logo"
          />
        </div>
        <div className="dashboard-reports-header-icon-text">opus</div>
      </div>

      <div className="dashboard-reports-header-date-container">
        {moment(new Date()).format(dateTimeFormat)}
      </div>
    </div>
  );
};

import { FunctionComponent, useMemo } from 'react';
import { ChartData } from 'shared/models/data/chart.model';
import { VerticalStackedBarChartProps } from 'shared/models/props/chart-props.model';
import { ExternalToolTip } from 'shared/services/widgets/ExternalTooltip';
import { v4 as uuid4 } from 'uuid';
import BaseChart from '../BaseChart';

export const VerticalStackedBarChart: FunctionComponent<
  VerticalStackedBarChartProps<ChartData>
> = ({
  fetchWidgetData,
  isWidgetDataLoading,
  widgetData,
  displayLegend = false,
  displayLabels = false,
  enableTooltip = false,
  aspectRatio = 3,
  isResponsive = true,
  legendPosition = 'top',
  style = {},
  applyColorList = false,
  customLegendContainerId,
  displayPercentageDataLabels = false,
  options: otherOptiones,
  displayExternalTooltip = false,
}) => {
  const chartId = useMemo(() => {
    return uuid4();
  }, []);

  const chartOptions = useMemo(() => {
    return {
      ...otherOptiones,
      aspectRatio,
      responsive: isResponsive,
      plugins: {
        beforeInit: function (chart: { data: { labels: any[] } }) {
          chart.data.labels.forEach(function (
            label: string,
            index: string | number,
            labelsArr: { [x: string]: any }
          ) {
            if (/\n/.test(label)) {
              labelsArr[index] = label.split(/\n/);
            }
          });
        },
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'index' as const,
          intersect: false,
          position: 'nearest' as const,
          enabled: enableTooltip,
          external: (context: any) => {
            return displayExternalTooltip
              ? ExternalToolTip.externalTooltipHandler(context)
              : null;
          },
        },
        datalabels: {
          display: displayLabels,
          formatter: (value: any, context: any) => {
            if (!value) return '';
            if (displayPercentageDataLabels) {
              const { chart, datasetIndex, dataIndex } = context;
              if (chart.data.datasets[datasetIndex] != null) {
                const barTotal = chart.data.datasets
                  .map((x: { data: { [x: string]: any } }) => x.data[dataIndex])
                  .reduce((a: any, b: any) => a + b, 0);
                return `${((value * 100) / barTotal).toFixed(2)}%`;
              }
            } else {
              return value;
            }
          },

          color: 'white',
          font: {
            weight: 700,
            size: 12,
          },
        },
        htmlLegend: {
          containerID:
            customLegendContainerId || `chart-legend-container-${chartId}`,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: '#7f7f7f',
          },
        },
        y: {
          grid: {
            drawBorder: false,
          },
          stacked: true,
          ticks: {
            color: '#7f7f7f',
            font: {
              size: 20,
            },
          },
        },
      },
    };
  }, []);

  return (
    <BaseChart
      options={chartOptions}
      type="bar"
      customLegendContainerId={customLegendContainerId}
      {...{
        fetchWidgetData,
        isWidgetDataLoading,
        widgetData,
        style,
        applyColorList,
        displayLegend,
        legendPosition,
        chartId,
      }}
    />
  );
};

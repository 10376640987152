import jwtDecode from 'jwt-decode';
import { ClaimsPayload } from './claims-payload.model';

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'ACCESS_TOKEN';

export class JWTService {
  private jwtPayload: ClaimsPayload | null;

  constructor() {
    const token = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);

    if (!token) {
      console.error(
        `can't fetch ${ACCESS_TOKEN_LOCAL_STORAGE_KEY} from local storage`
      );
      this.jwtPayload = null;
      return;
    }

    try {
      this.jwtPayload = jwtDecode<ClaimsPayload>(token);
    } catch (err) {
      this.jwtPayload = null;
    }
  }

  getPayload(): ClaimsPayload | null {
    return this.jwtPayload;
  }
}

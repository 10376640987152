import { useTranslation } from 'react-i18next';
import { useIntegrationsFetchQuery } from './Connection/store/api';
import IntegrationEmpty from './Common/Empty';
import IntegrationErrorView from './Common/ErrorView';
import IntegrationLoading from './Common/Loading';
import IntegrationList from './Common/IntegrationList';
import HeaderComponent from 'shared/components/Header/HeaderComponent';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { VisibilityControlContent } from 'shared/components/VisibilityControlContent/VisibilityControlContent';
import {
  IntegrationModal,
  IntegrationModalType,
} from './Connection/components/IntegrationModal/IntegrationModal';

export enum IntegrationsSelectionType {
  Integrations,
  FindingSources,
}

export enum IntegrationsTabQueryString {
  Integrations = 'Integrations',
  FindingSources = 'FindingSources',
}

export default function IntegrationsMain() {
  const { t: translation } = useTranslation();

  const {
    isLoading,
    error,
    data: connectionList,
  } = useIntegrationsFetchQuery();

  if (isLoading) {
    return <IntegrationLoading />;
  }

  if (error) {
    return <IntegrationErrorView />;
  }

  if (!connectionList) {
    return <IntegrationEmpty modelName={translation(`connections.title`)} />;
  }

  return (
    <>
      <HeaderComponent
        text={translation(`global.sidebar.titles.integrations`)}
      />
      <IntegrationList integrationList={connectionList} />
    </>
  );
}

import { useState, useEffect } from 'react';
import {
  SystemLabel,
  SystemLabelType,
} from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';
import {
  useGetLabelsMutation,
  useSearchLabelsMutation,
} from 'shared/store/api';
import { useLabelSelection } from './useLabelSelection';

interface UseLabelDataProps {
  itemType: SystemLabelType;
  itemId?: string;
  shouldFetch?: boolean;
}

interface UseLabelDataReturn {
  recentLabels: SystemLabel[];
  allLabels: SystemLabel[];
  activeLabels: SystemLabel[];
  filteredRecentLabels: SystemLabel[];
  filteredAllLabels: SystemLabel[];
  loading: boolean;
  error: any;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  addLabel: (labelName: string) => void;
  refetch: () => void;
  selectedLabels: Array<SystemLabel>;
  selectLabel: (label: SystemLabel) => void;
  setSelectedLabels: (labels: Array<SystemLabel>) => void; // Add external control
}

export const useLabelData = ({
  itemType,
  itemId,
  shouldFetch,
}: UseLabelDataProps): UseLabelDataReturn => {
  const [recentLabels, setRecentLabels] = useState<SystemLabel[]>([]);
  const [allLabels, setAllLabels] = useState<SystemLabel[]>([]);
  const [activeLabels, setActiveLabels] = useState<SystemLabel[]>([]);
  const [filteredRecentLabels, setFilteredRecentLabels] = useState<
    SystemLabel[]
  >([]);
  const [filteredAllLabels, setFilteredAllLabels] = useState<SystemLabel[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const [getLabelItems] = useGetLabelsMutation();
  const [getRecentLabelItems] = useGetLabelsMutation();
  const [searchActiveLabelItems] = useSearchLabelsMutation();

  // Use label selection logic (with initial preselected labels)
  const { selectedLabels, selectLabel, resetSelection, setSelectedLabels } =
    useLabelSelection(activeLabels);

  const resetState = () => {
    setRecentLabels([]);
    setAllLabels([]);
    setActiveLabels([]);
    setFilteredAllLabels([]);
    setFilteredAllLabels([]);
    setSearchTerm('');
    resetSelection();
  };

  const fetchLabels = async () => {
    resetState();
    setLoading(true);
    try {
      const labelItemsResponse = await getLabelItems({
        queryString: 'orderBy=label&orderType=ASC&skip=0&take=10000',
      }).unwrap();

      const recentLabelItemsResponse = await getRecentLabelItems({
        queryString: `orderBy=updatedAt&orderType=DESC&skip=0&take=5`,
      }).unwrap();

      let activeLabelItemsResponse;

      if (itemId) {
        activeLabelItemsResponse = await searchActiveLabelItems({
          itemType,
          itemId,
        }).unwrap();
      }

      setAllLabels(labelItemsResponse || []);
      setRecentLabels(recentLabelItemsResponse.slice(0, 5) || []);
      setFilteredAllLabels(labelItemsResponse || []);
      setFilteredRecentLabels(recentLabelItemsResponse.slice(0, 5) || []);
      setActiveLabels(activeLabelItemsResponse || []);
      setSelectedLabels(activeLabelItemsResponse || []);
      setError(null);
    } catch (err) {
      setError(err);
      setAllLabels([]);
      setRecentLabels([]);
      setFilteredAllLabels([]);
      setFilteredRecentLabels([]);
      setActiveLabels([]);
      setSelectedLabels([]);
    } finally {
      setLoading(false);
    }
  };

  const filterLabels = () => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();

    const filteredRecent = recentLabels.filter((label) =>
      label.label.toLowerCase().includes(lowercasedSearchTerm)
    );

    const filteredAll = allLabels.filter((label) =>
      label.label.toLowerCase().includes(lowercasedSearchTerm)
    );

    setFilteredRecentLabels(filteredRecent);
    setFilteredAllLabels(filteredAll);
  };

  const addLabel = (labelName: string) => {
    const lowercasedLabelName = labelName.toLowerCase();

    const existingLabelItem = allLabels.find(
      (labelItem) => labelItem.label.toLowerCase() === lowercasedLabelName
    );

    const existingCurrentLabelItem = recentLabels.find(
      (labelItem) => labelItem.label.toLowerCase() === lowercasedLabelName
    );

    if (!existingLabelItem && !existingCurrentLabelItem) {
      const newLabelItem: SystemLabel = {
        label: lowercasedLabelName,
        itemId,
        itemType,
      };

      setRecentLabels([newLabelItem, ...recentLabels]);
      selectLabel(newLabelItem);

      if (
        searchTerm === '' ||
        labelName.toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        setFilteredRecentLabels([newLabelItem, ...filteredRecentLabels]);
      }
    } else {
      if (existingLabelItem) {
        const labelAlreadySelected = selectedLabels.find(
          (labelItem) => labelItem.label.toLowerCase() === lowercasedLabelName
        );

        const labelExistsInRecent = recentLabels.find(
          (labelItem) => labelItem.label.toLowerCase() === lowercasedLabelName
        );

        if (!labelAlreadySelected) {
          selectLabel(existingLabelItem);
        }

        if (!labelExistsInRecent) {
          setRecentLabels([existingLabelItem, ...recentLabels]);
          setFilteredRecentLabels([existingLabelItem, ...recentLabels]);
        }

        if (!labelAlreadySelected && labelExistsInRecent) {
          selectLabel(existingLabelItem);
        }
      }
    }
  };

  useEffect(() => {
    if (shouldFetch) fetchLabels();
  }, [itemType, itemId, shouldFetch]);

  useEffect(() => {
    filterLabels();
  }, [searchTerm]);

  return {
    recentLabels,
    allLabels,
    activeLabels,
    filteredRecentLabels,
    filteredAllLabels,
    loading,
    error,
    setSearchTerm,
    addLabel,
    refetch: fetchLabels,
    searchTerm,
    selectedLabels,
    selectLabel,
    setSelectedLabels, // Expose for external control
  };
};

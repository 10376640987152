import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface FindingItemProps extends BaseComponentProps {
  title: string;
  tooltipText?: string;
  id?: string;
}

export const FindingItem: FunctionComponent<FindingItemProps> = ({
  title,
  tooltipText,
  children,
  id,
}) => {
  return (
    <div className="finding-item">
      <div className="finding-item-title" title={tooltipText}>
        {title}
      </div>
      <div className="finding-item-body" id={id || undefined}>
        {children}
      </div>
    </div>
  );
};

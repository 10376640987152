import { Box } from '@mui/material';
import { NodeProps } from 'WorkflowBuilder/interfaces/node';
import React from 'react';
import { Handle, Position } from 'react-flow-renderer';

interface Props {
  data: NodeProps;
}

export function DotNode({ data }: Props) {
  return (
    <Box position="relative">
      <React.Fragment>
        <Handle
          type="target"
          position={Position.Top}
          className="workflow-builder-handle"
        />
      </React.Fragment>
    </Box>
  );
}

import useCommonDispatch from 'Common/utils/use-dispatch';
import { setAfterTool, setBeforeNode } from 'WorkflowBuilder/store';
import {
  EdgeProps,
  getBezierPath,
  getEdgeCenter,
  useStoreApi,
} from 'react-flow-renderer';
import DiagramPlusButton from '../PlusButton';
import { IconButton } from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { MouseEvent, useState } from 'react';
import StepControlPopover from '../StepControlPopover';

const foreignObjectWidth = 60;
const foreignObjectMaxWidth = 80;
const foreignObjectHeight = 40;

export default function DiagramEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps) {
  const dispatch = useCommonDispatch();

  const store = useStoreApi();

  const [containerWidth, setContainerWidth] = useState(foreignObjectWidth);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const [shouldDisplayDeleteButton, setShouldDisplayDeleteButton] =
    useState<boolean>(false);

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  function onAddTool() {
    dispatch(setAfterTool(+source));
    dispatch(setBeforeNode(+target));
  }

  function onAddCrossroad() {
    const nodes: Array<any> = Array.from(store.getState().nodeInternals).map(
      ([, node]) => node
    );

    const previousNode = nodes.find((node) => {
      return node.id === String(source);
    });

    data.onAddCrossroad && data.onAddCrossroad(previousNode);
  }

  function handlePlusClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  const onRemoveEdge = () => {
    data.removeEdgeCallback && data.removeEdgeCallback(source, target);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  const renderDeleteButton = () => {
    if (shouldDisplayDeleteButton) {
      return (
        <IconButton
          onClick={onRemoveEdge}
          className="workflow-builder-plus-edge-delete-button"
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON} />
        </IconButton>
      );
    }
    return <></>;
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={containerWidth}
        height={foreignObjectHeight}
        x={edgeCenterX - containerWidth / 2}
        y={edgeCenterY - foreignObjectHeight / 2}
        className="workflow-builder-plus-edge-container"
        requiredExtensions="http://www.w3.org/1999/xhtml"
        onMouseEnter={() => {
          setShouldDisplayDeleteButton(true);
          setContainerWidth(foreignObjectMaxWidth);
        }}
        onMouseLeave={() => {
          setShouldDisplayDeleteButton(false);
          setContainerWidth(foreignObjectWidth);
        }}
      >
        <div className="workflow-builder-plus-edge-buttons">
          <DiagramPlusButton onClick={handlePlusClick} />
          {renderDeleteButton()}
        </div>
      </foreignObject>

      <StepControlPopover
        open={open}
        handleClose={handleClose}
        anchorEl={anchorEl}
        handleCrossroadClick={onAddCrossroad}
        handleToolClick={onAddTool}
      />
    </>
  );
}

import { useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import { ReactFlowProvider } from 'react-flow-renderer';
import ReactFlow, {
  addEdge,
  ConnectionLineType,
  useNodesState,
  useEdgesState,
  Connection,
  Edge,
} from 'reactflow';
import 'reactflow/dist/style.css';
import { IaCWorkflowProps } from 'shared/models/props/iac-props.model';
import IaCWorkflowNode from '../../../IaCFindings/components/IaCNode/IaCNode';
import IaCWorkflowService from 'IaCFindings/utils/IaCWorkflow.service';

const Cloud2CodeWorkflow = ({ flowData }: IaCWorkflowProps) => {
  const formattedSteps = IaCWorkflowService.flattenStepData(flowData);
  const { nodes: layoutedNodes, edges: layoutedEdges } =
    IaCWorkflowService.getLayoutedElements(
      IaCWorkflowService.convertToNodes(formattedSteps),
      IaCWorkflowService.convertToEdges(formattedSteps)
    );
  const [nodes, setNodes] = useNodesState(layoutedNodes);
  const [edges, setEdges] = useEdgesState(layoutedEdges);
  const nodeTypes = useMemo(
    () => ({
      ['iacNode']: IaCWorkflowNode,
    }),
    []
  );
  const onConnect = useCallback(
    (params: Edge<any> | Connection) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.SmoothStep }, eds)
      ),
    []
  );

  const calculateDepth = (node: any, parent = null, depth = 0) => {
    node.depth = depth;
    node.children?.forEach((child: any) => {
      calculateDepth(child, node, depth + 1);
    });
  };

  layoutedNodes.forEach((node) => {
    calculateDepth(node);
  });

  const renderedNodes = layoutedNodes.map((node, index) => (
    <IaCWorkflowNode key={node.id} data={node} depth={node.depth} />
  ));

  return (
    <Stack className="code-event-workflow">
      <ReactFlowProvider>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onConnect={onConnect}
          connectionLineType={ConnectionLineType.SmoothStep}
          fitView
        />
      </ReactFlowProvider>
    </Stack>
  );
};

export default Cloud2CodeWorkflow;

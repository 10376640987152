import { CommonIntegrationModalProps } from 'Integrations/Connection/components/IntegrationModal/IntegrationModal';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { ReactNode } from 'react';
import { ApplicationTypes } from 'Application/interfaces/applications.enum';

export interface OnboardingStepContentRenderPayload {
  submitStepData: (stepData: any) => void;
  updateStepData: (stepData: any) => void;
  verifyStepData: (stepData: any) => void;
  navigateToPreviousStep: (stepOrder: number) => void;
}

export enum OnboardingStepStatus {
  EMPTY = 'EMPTY',
  CREATING = 'CREATING',
  UPDATING = 'UPDATING',
  CONNECTED = 'CONNECTED',
}

export enum OnboardingStepAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export enum OnboardingStepId {
  AWS = 'AWS',
  SECURITY_HUB = 'SECURITY_HUB',
  GITHUB = 'GITHUB',
}

export interface OnboardingStep {
  id: OnboardingStepId;
  applicationId: ApplicationTypes;
  nextStepId: string | null;
  previousStepId: string | null;
  name: string;
  integrationItem: IntegrationItem;
  description: string;
  status: OnboardingStepStatus;
  required: boolean;
  renderContent: (payload: CommonIntegrationModalProps) => ReactNode;
  shouldNavigateAfterCreate: boolean;
  submitPaylod?: any;
}

export interface OnboardingState {
  activeStepId: string | null;
  isComplete: boolean;
  connectionIds: Partial<Record<OnboardingStepId, string>>;
}

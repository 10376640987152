import Autocomplete from 'Common/components/Autocomplete';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import {
  getFilterState,
  getFindingTypes,
  getInitialFilterLoad,
  selectdashboardFilter,
  setdashboardFilters,
  setSelectedMetrics,
  setSelectedMetricTypes,
} from 'Dashboard/store';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useState, useMemo } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import SimpleLineChart from 'shared/components/SimpleLineChart';
import {
  operationalDashboardFilterOptions,
  TimelineFilter,
} from 'shared/fixtures/data/operational-dashboard-filter-option.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  OperationalMetricType,
  OperationalWidgetAnalyticsType,
  OperationalWidgetData,
  OperationalWidgetDataTrend,
  OperationalWidgetDataTrendImpact,
} from 'shared/models/data/operational-widget-data.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OperationalWidgetCard from '../OperationalWidgetCard';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import NoDataToDisplayCard from '../NoDataCard';
import { ChartOptions } from 'chart.js';
import { Button, Grid } from '@mui/material';
import DashboardChartCard from '../DashboardChartCard';
import { useGetDashboardAnalyticTrendsMutation } from 'Dashboard/store/api';
import { useTranslation } from 'react-i18next';
import { ChartDataItem } from 'shared/models/data/chart.model';
import { DashboardSection } from '../DashboardSection';
import OperationalMetricsModal from '../OperationalMetricsModal';
import _ from 'lodash';

const mockSlaData: OperationalWidgetData = {
  trend: OperationalWidgetDataTrend.INCREASING,
  trendPercentage: 2,
  trendImpact: OperationalWidgetDataTrendImpact.POSITIVE,
  value: '57.80%',
};

interface OperationalDashboardSectionProps extends BaseComponentProps {}

export const OperationalDashboardSection: FunctionComponent<
  OperationalDashboardSectionProps
> = () => {
  const dispatch = useCommonDispatch();
  const { t: translation } = useTranslation();

  const [metricsModalOpen, setMetricsModalOpen] = useState<boolean>(false);

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const filterState = useCommonSelector(getFilterState);

  const findingTypes = useMemo(() => {
    return getFindingTypes(filterState);
  }, [filterState['findingType']]);

  const getOperationalWidgetTitle = (type: OperationalMetricType) => {
    return translation(`dashboards.widgets.${type}`);
  };

  const operationalWidgets = [
    {
      title: getOperationalWidgetTitle(OperationalMetricType.NEW_FINDINGS),
      type: OperationalMetricType.NEW_FINDINGS,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.NEW_RESOLVED_FINDINGS
      ),
      type: OperationalMetricType.NEW_RESOLVED_FINDINGS,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.NEW_RESOLVED_WITHIN_SLA
      ),
      type: OperationalMetricType.NEW_RESOLVED_WITHIN_SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.NEW_RESOLVED_OUTSIDE_SLA
      ),
      type: OperationalMetricType.NEW_RESOLVED_OUTSIDE_SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.NEW_EXCEPTION_APPROVED
      ),
      type: OperationalMetricType.NEW_EXCEPTION_APPROVED,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.SLA_ATTAINMENT_RATE
      ),
      type: OperationalMetricType.SLA_ATTAINMENT_RATE,
    },
    {
      title: getOperationalWidgetTitle(OperationalMetricType.TRENDING_MTTR),
      type: OperationalMetricType.TRENDING_MTTR,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.NEW_FINDINGS_BREACHED_SLA
      ),
      type: OperationalMetricType.NEW_FINDINGS_BREACHED_SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.TOTAL_ACTIVE_FINDINGS
      ),
      type: OperationalMetricType.TOTAL_ACTIVE_FINDINGS,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.TOTAL_CRITICAL_FINDINGS
      ),
      type: OperationalMetricType.TOTAL_CRITICAL_FINDINGS,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.TOTAL_EXPLOITABLE_FINDINGS
      ),
      type: OperationalMetricType.TOTAL_EXPLOITABLE_FINDINGS,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.TOTAL_ACTIVE_WITHIN_SLA
      ),
      type: OperationalMetricType.TOTAL_ACTIVE_WITHIN_SLA,
    },
    {
      title: getOperationalWidgetTitle(
        OperationalMetricType.TOTAL_ACTIVE_OUTSIDE_SLA
      ),
      type: OperationalMetricType.TOTAL_ACTIVE_OUTSIDE_SLA,
    },
    {
      title: getOperationalWidgetTitle(OperationalMetricType.TOTAL_MTTR),
      type: OperationalMetricType.TOTAL_MTTR,
    },
  ];

  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const selectTimeline = (timeline: TimelineFilter) => {
    dispatch(
      setdashboardFilters({
        ...dashboardFilter,
        timeline,
      })
    );
  };

  const [
    getAnalitycTrendsWidgetData,
    {
      data: analyticTrendsWidgetData,
      isLoading: analyticTrendsDataWidgetLoading,
    },
  ] = useGetDashboardAnalyticTrendsMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad)
      getAnalitycTrendsWidgetData({
        businessUnitIds: dashboardFilter.businessUnitId,
        findingTypes: findingTypes,
        timeline: dashboardFilter.timeline,
        types: dashboardFilter.metricsTypes,
      });
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter.businessUnitId,
    dashboardFilter.timeline,
    dashboardFilter.metricsTypes,
    findingTypes,
  ]);

  const saveSelectedMetricsHandler = (
    metricTypes: Array<OperationalMetricType>
  ) => {
    dispatch(setSelectedMetricTypes(metricTypes));

    const isMetricExcluded = dashboardFilter.metricsTypes.some(
      (metricType) => !metricTypes.includes(metricType)
    );

    if (isMetricExcluded) {
      dispatch(
        setSelectedMetrics(
          _.intersection(dashboardFilter.metricsTypes, metricTypes)
        )
      );
    }
  };

  const renderChartWidget = (widgetData: any, isLoading: boolean) => {
    const hasNoData =
      (!widgetData || widgetData?.datasets.length === 0) && !isLoading;

    if (hasNoData) {
      return <NoDataToDisplayCard displayIcon={true} />;
    }

    const widgetIndexMap = new Map(
      operationalWidgets.map((widget, index) => [widget.title, index])
    );

    widgetData?.datasets.sort((a: any, b: any) => {
      const indexA = widgetIndexMap.get(a.label) ?? Infinity;
      const indexB = widgetIndexMap.get(b.label) ?? Infinity;

      return indexA - indexB;
    });

    const chartOptions = presentationalConfigurationForWidgets[
      'analytic_trends'
    ].options as ChartOptions<'line'>;

    return (
      <>
        <SimpleLineChart
          fetchWidgetData={() => {}}
          isWidgetDataLoading={isLoading}
          widgetData={widgetData}
          style={presentationalConfigurationForWidgets['analytic_trends'].style}
          options={chartOptions}
          customLegendContainerId="operational-dashboard-section-metrics-chart-legend-container"
          displayLegend
          enableTooltip
        />
        {!isLoading && (
          <div id="operational-dashboard-section-metrics-chart-legend-container"></div>
        )}
      </>
    );
  };

  const onMetricClick = (type: OperationalMetricType) => {
    const selectedMetrics = dashboardFilter.metricsTypes.slice();

    if (selectedMetrics.includes(type)) {
      const index = selectedMetrics.indexOf(type);
      selectedMetrics.splice(index, 1);
    } else {
      selectedMetrics.push(type);
    }

    dispatch(setSelectedMetrics(selectedMetrics));
  };

  const addAnalyticsTrendDataLabels = () => {
    if (analyticTrendsWidgetData) {
      return {
        ...analyticTrendsWidgetData,
        datasets: analyticTrendsWidgetData.datasets.map(
          (dataset: ChartDataItem) => ({
            ...dataset,
            label: getOperationalWidgetTitle(
              dataset.label as OperationalMetricType
            ),
          })
        ),
      };
    }

    return analyticTrendsWidgetData;
  };

  const renderFilterArea = () => {
    return (
      <div className="operational-dashboard-section-filters">
        <Button
          className="operational-dashboard-section-metrics-button"
          onClick={() => {
            setMetricsModalOpen(true);
          }}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.PLUS_ICON} />{' '}
          {translation(`dashboards.widgets.addOrRemoveMetrics`)}
        </Button>
        <Autocomplete
          model=""
          onChangeCallBack={(model, option) => {
            selectTimeline(
              (option as AutocompleteOption).value as TimelineFilter
            );
          }}
          classes={{
            root: 'operational-dashboard-section-timeline-filter-root',
            inputRoot:
              'operational-dashboard-section-timeline-filter-input-root',
            input: 'operational-dashboard-section-timeline-filter-input',
            listbox: 'operational-dashboard-section-timeline-filter-listbox',
          }}
          optionList={operationalDashboardFilterOptions}
          single
          placeholder="Select Timeline"
          initialSelectedValues={{
            value: dashboardFilter.timeline,
            label: dashboardFilter.timeline,
          }}
        />
      </div>
    );
  };

  return (
    <DashboardSection
      title={translation(`dashboards.widgets.metrics`)}
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.CHART_PIE_ICON} />}
      filter={renderFilterArea()}
    >
      <div className="operational-dashboard-section-body">
        <Grid
          container
          spacing={1.5}
          className="operational-dashboard-section-body-card-list"
        >
          {operationalWidgets.map((widget, index) => {
            if (dashboardFilter.selectedMetricTypes.includes(widget.type)) {
              return (
                <Grid key={index} item xs={2} sm={2} md={2} lg={1.7} xl={1.7}>
                  <OperationalWidgetCard
                    {...widget}
                    onItemClick={() => onMetricClick(widget.type)}
                    isSelected={dashboardFilter.metricsTypes.includes(
                      widget.type
                    )}
                  />
                </Grid>
              );
            }

            return <></>;
          })}
        </Grid>
        <div className="operational-dashboard-section-body-chart-list">
          <DashboardChartCard
            title="Trends"
            className="operational-dashboard-section-by-severity-chart-container-analytic-trends"
          >
            {renderChartWidget(
              addAnalyticsTrendDataLabels(),
              analyticTrendsDataWidgetLoading
            )}
          </DashboardChartCard>
        </div>
      </div>
      <OperationalMetricsModal
        modalOpen={metricsModalOpen}
        handleModalClose={() => {
          setMetricsModalOpen(false);
        }}
        saveSelectedMetrics={saveSelectedMetricsHandler}
        selectedMetrics={dashboardFilter.selectedMetricTypes}
      />
    </DashboardSection>
  );
};

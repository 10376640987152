import { FunctionComponent } from 'react';
import PageContainer from 'shared/components/PageContainer';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import AssetsAdvancedDataGrid from './components/AssetsAdvancedDataGrid';

interface AssetsPageProps extends BaseComponentProps {}

export const AssetsPage: FunctionComponent<AssetsPageProps> = () => {
  return (
    <PageContainer title="Resource Management">
      <AssetsAdvancedDataGrid />
    </PageContainer>
  );
};

import ApplicationItem from './interfaces/item';

export function filterFindingsSources(
  apps: ApplicationItem[]
): ApplicationItem[] {
  return apps.filter((x) => !!x.isFindingsSource);
}

export function groupByCategory(apps: ApplicationItem[]): ApplicationItem[][] {
  const categorySet = new Set<string>();
  for (const app of apps) {
    categorySet.add(app.category);
  }

  const grouped: ApplicationItem[][] = [];
  new Set(Array.from(categorySet).sort()).forEach((category) => {
    grouped.push(apps.filter((app) => app.category === category));
  });

  return grouped;
}

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import CommonCheckbox from 'shared/components/CommonCheckbox';

interface MultiChoiceInteractionProps {
  options: string[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
}

export default function MultiChoiceInteraction({
  options,
  selectedOptions,
  onChange,
}: MultiChoiceInteractionProps) {
  const handleCheckboxChange = (option: string, checked: boolean) => {
    const tempOptions = [...selectedOptions];
    const index = tempOptions.indexOf(option);
    // User asked to remove, and it is in the array
    if (!checked && index > -1) {
      tempOptions.splice(index, 1);
    }
    // User asked to push, and it isn't in the array
    else if (checked && index === -1) {
      tempOptions.push(option);
    }
    onChange(tempOptions);
  };

  return (
    <FormGroup>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <CommonCheckbox
              onChange={(event) => {
                handleCheckboxChange(option, event.target.checked);
              }}
            />
          }
          label={option}
        />
      ))}
    </FormGroup>
  );
}

import { URLSearchParamsInit } from 'react-router-dom';
import {
  FilterItem,
  FilterOption,
} from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import { SearchParams, UpdateQueryParams } from 'shared/hooks/useQueryParams';

export type SearchParamsCallback = (
  nextInit: URLSearchParamsInit,
  navigateOptions?:
    | {
        replace?: boolean | undefined;
        state?: any;
      }
    | undefined
) => void;

export class FilterSearchParamsHandler {
  handleAdvanceFilterAllOptionsSelection(
    item: FilterItem,
    allSelected: boolean,
    searchParams: SearchParams,
    searchParamsCallback: UpdateQueryParams
  ) {
    const filterParams = searchParams.filter
      ? JSON.parse(searchParams.filter as string)
      : null;

    const existingSearchParams = this.setSearchParamsForKeys(searchParams, [
      'activeTabId',
      'order',
      'searchKeyword',
    ]);

    if (filterParams) {
      let updatedFilterObject = { ...filterParams };

      if (allSelected) {
        delete updatedFilterObject[item.id];
      } else {
        updatedFilterObject[item.id] = item.items.map((element) => ({
          id: element.id,
          name: element.name,
        }));
      }

      let updatedSearchParamsObject = { ...existingSearchParams };

      if (Object.keys(updatedFilterObject).length) {
        updatedSearchParamsObject = {
          ...updatedSearchParamsObject,
          filter: JSON.stringify(updatedFilterObject),
        };
      } else {
        delete updatedSearchParamsObject.filter;
      }

      searchParamsCallback(updatedSearchParamsObject);
    } else {
      const existingSearchParams = this.setSearchParamsForKeys(searchParams, [
        'activeTabId',
        'order',
        'searchKeyword',
      ]);

      searchParamsCallback({
        ...existingSearchParams,
        filter: JSON.stringify({
          [item.id]: item.items.map((element) => ({
            id: element.id,
            name: element.name,
          })),
        }),
      });
    }
  }

  handleAdvanceFilterOptionSelection(
    item: FilterItem,
    option: FilterOption,
    searchParams: SearchParams,
    searchParamsCallback: UpdateQueryParams
  ) {
    const filterParams = searchParams.filter
      ? JSON.parse(searchParams.filter as string)
      : null;

    if (filterParams) {
      let filterSelections = [];

      if (filterParams[item.id]) {
        switch (item.type) {
          case TypeFilter.MULTI_SELECT:
            filterSelections = filterParams[item.id]?.find(
              (filterParam: any) => filterParam?.id === option?.id
            )
              ? filterParams[item.id]?.filter(
                  (item: FilterOption) => item?.id !== option?.id
                )
              : [
                  ...filterParams[item?.id],
                  { id: option?.id, name: option?.name },
                ];
            break;
          case TypeFilter.SINGLE_SELECT:
            filterSelections =
              filterParams[item.id][0]?.id === option?.id
                ? []
                : [{ id: option?.id, name: option?.name }];

            break;
          case TypeFilter.DATE_PICKER_RANGE:
            filterSelections = option as any;
            break;
          case TypeFilter.NUMBER:
            filterSelections = option as any;
            break;
        }
      } else {
        filterSelections =
          option.id !== undefined
            ? [{ id: option?.id, name: option?.name }]
            : (option as any);
      }

      const existingSearchParams = this.setSearchParamsForKeys(searchParams, [
        'activeTabId',
        'order',
        'searchKeyword',
      ]);

      let updatedFilterObject = { ...filterParams };

      if (filterSelections?.length) {
        updatedFilterObject = {
          ...updatedFilterObject,
          [item.id]: filterSelections,
        };
      } else {
        delete updatedFilterObject[item.id];
      }

      let updatedSearchParamsObject = { ...existingSearchParams };

      if (Object.keys(updatedFilterObject).length) {
        updatedSearchParamsObject = {
          ...updatedSearchParamsObject,
          filter: JSON.stringify(updatedFilterObject),
        };
      } else {
        delete updatedSearchParamsObject.filter;
      }

      searchParamsCallback(updatedSearchParamsObject);
    } else {
      const existingSearchParams = this.setSearchParamsForKeys(searchParams, [
        'activeTabId',
        'order',
        'searchKeyword',
      ]);

      searchParamsCallback({
        ...existingSearchParams,
        filter: JSON.stringify({
          [item.id]: option.id
            ? [{ id: option?.id, name: option?.name }]
            : (option as any),
        }),
      });
    }
  }

  populateAdvanceFilterOptionsWithPreselectedInformation(
    urlSearchParams: SearchParams,
    filterList: Array<FilterItem>
  ) {
    try {
      if (urlSearchParams.filter) {
        const parsedFilterParams = JSON.parse(urlSearchParams.filter as string);

        if (parsedFilterParams && Object.keys(parsedFilterParams).length) {
          for (let filterKey in parsedFilterParams) {
            filterList = filterList.map((filterItem: FilterItem) => {
              // Check for nested filters
              if (filterItem.nested) {
                let updatedNestedItems = filterItem.filterItems?.map(
                  (item: FilterItem) => {
                    if (item.id === filterKey) {
                      return {
                        ...item,
                        items:
                          item.items?.length > 0
                            ? [
                                ...item.items.map((nestedItem) => {
                                  const selectedItem = parsedFilterParams[
                                    filterKey
                                  ].find(
                                    (element: FilterOption) =>
                                      element.id === nestedItem.id
                                  );

                                  return {
                                    ...nestedItem,
                                    checked: Boolean(selectedItem),
                                  };
                                }),
                                ...(item.loaded
                                  ? []
                                  : parsedFilterParams[filterKey]
                                      ?.filter(
                                        (item: any) =>
                                          !item.items?.find(
                                            (dataItem: FilterItem) =>
                                              dataItem.id === item.id
                                          )
                                      )
                                      ?.map((filterItem: any) => ({
                                        ...filterItem,
                                        checked: true,
                                      })) || []),
                              ]
                            : parsedFilterParams[filterKey].map(
                                (item: any) => ({
                                  ...item,
                                  checked: true,
                                })
                              ),
                      };
                    }

                    return item;
                  }
                );

                // Handle dependencies
                updatedNestedItems = updatedNestedItems?.map(
                  (nestedItem, index, nestedItemsArray) => {
                    const dependsOn = nestedItemsArray.find((item) =>
                      item.resetFields?.includes(nestedItem.id)
                    );

                    if (dependsOn) {
                      const selectedItem = dependsOn.items.find(
                        (item: any) => item.checked
                      );
                      return {
                        ...nestedItem,
                        loaded: nestedItem.loaded,
                        additionalBodyParameters:
                          selectedItem &&
                          nestedItem?.getAdditionalBodyParameters
                            ? nestedItem.getAdditionalBodyParameters(
                                selectedItem || {}
                              )
                            : {},
                      };
                    }

                    return nestedItem;
                  }
                );

                return {
                  ...filterItem,
                  filterItems: updatedNestedItems,
                };
              }

              // Handle top-level filters
              if (filterItem.id === filterKey) {
                return filterItem.type === TypeFilter.DATE_PICKER_RANGE
                  ? { ...filterItem, dateRange: parsedFilterParams[filterKey] }
                  : filterItem.type === TypeFilter.NUMBER
                  ? {
                      ...filterItem,
                      numberInput: parsedFilterParams[filterKey],
                    }
                  : {
                      ...filterItem,
                      items:
                        filterItem.items?.length > 0
                          ? [
                              ...filterItem.items.map((item) => {
                                const selectedItem = parsedFilterParams[
                                  filterKey
                                ].find(
                                  (element: FilterOption) =>
                                    element.id === item.id
                                );

                                return {
                                  ...item,
                                  checked: Boolean(selectedItem),
                                };
                              }),
                              ...(parsedFilterParams[filterKey]
                                ?.filter(
                                  (item: any) =>
                                    !filterItem.items.find(
                                      (dataItem) => dataItem.id === item.id
                                    )
                                )
                                ?.map((filterItem: any) => ({
                                  ...filterItem,
                                  checked: true,
                                })) || []),
                            ]
                          : parsedFilterParams[filterKey].map((item: any) => ({
                              ...item,
                              checked: true,
                            })),
                    };
              }

              return filterItem;
            });
          }
        }
      }

      return filterList;
    } catch (err) {
      return filterList;
    }
  }

  handleFilterCategoryRemovalFromSearchParams(
    categoryId: string,
    searchParams: SearchParams,
    searchParamsCallback: UpdateQueryParams
  ) {
    if (searchParams.filter) {
      const parsedFilterParams = JSON.parse(searchParams.filter as string);

      if (parsedFilterParams[categoryId]) delete parsedFilterParams[categoryId];

      if (Object.keys(parsedFilterParams).length) {
        searchParamsCallback({
          ...searchParams,
          filter: JSON.stringify(parsedFilterParams),
        });
      } else {
        delete searchParams.filter;

        searchParamsCallback(searchParams);
      }
    }
  }

  handleClearParamFromSearchParams(
    param: string,
    searchParams: SearchParams,
    searchParamsCallback: UpdateQueryParams
  ) {
    if (searchParams[param]) {
      const existingSearchParams = this.setSearchParamsForKeys(searchParams, [
        'order',
        'filter',
        'activeTabId',
        'searchKeyword',
      ]);

      if (existingSearchParams[param]) delete existingSearchParams[param];

      searchParamsCallback({
        ...existingSearchParams,
      });
    }
  }

  setSearchParamsForKeys(searchParams: SearchParams, keys: Array<string>): any {
    const urlSearchParams = new URLSearchParams(window.location.search);

    let transformedSearchParams: any = {};

    urlSearchParams.forEach((value: string, key: string) => {
      transformedSearchParams[key] = value;
    });

    return transformedSearchParams;
  }
}

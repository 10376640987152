import { AutocompleteOption } from 'FindingDetails/store/api';

export class OptionDataHandler {
  sortOptionList(
    list: Array<AutocompleteOption>,
    order: 'asc' | 'desc',
    optionKey: keyof AutocompleteOption,
  ): Array<AutocompleteOption> {
    return list.sort((itemA, itemB) => {
      if (itemA[optionKey] === 'All' || itemB[optionKey] === 'All') return 1;

      return order === 'asc'
        ? -1 *
            itemA[optionKey]
              ?.toLowerCase()
              .localeCompare(itemB[optionKey]?.toLocaleLowerCase())
        : itemA[optionKey]
            ?.toLowerCase()
            .localeCompare(itemB[optionKey]?.toLocaleLowerCase());
    });
  }
}

import { FunctionComponent } from 'react';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

interface NoDataBackdropProps extends BaseComponentProps {
  descriptionText?: string;
  components?: {
    ControlButton?: JSX.Element;
  };
}

export const NoDataBackdrop: FunctionComponent<NoDataBackdropProps> = ({
  descriptionText,
  components,
}) => {
  return (
    <div className="no-data-backdrop-container">
      <div className="no-data-backdrop-body">
        <div className="no-data-backdrop-icon">
          <OpusSvgIcon
            width={125}
            height={125}
            type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON}
          />
        </div>
        <div className="no-data-backdrop-title header-3">No Results Found</div>
        {descriptionText && (
          <div className="no-data-backdrop-description">{descriptionText}</div>
        )}
      </div>
      {components && (
        <div className="no-data-backdrop-footer">
          {components.ControlButton}
        </div>
      )}
    </div>
  );
};

import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  reportRuleFrequencyOptions,
  reportRuleRecipientsOptions,
} from 'shared/fixtures/data/report-rule.data';
import {
  ReportRule,
  ReportRuleActionType,
  ReportRuleContentGroupType,
  ReportRuleFormData,
  ReportRuleFrequencyType,
  ReportRulePropertyOperator,
  ReportRulePropertyType,
  ReportRuleStatus,
  ReportRuleType,
} from 'shared/models/data/report-rule.model';

export class ReportRuleDataHandler {
  transformReportRuleFormDataToPostData(
    formData: ReportRuleFormData
  ): ReportRule {
    let groups = [];

    const formGroups = formData.groups;

    if (Array.isArray(formGroups)) {
      groups = [...formGroups];
    } else {
      groups.push(formGroups);
    }

    return {
      name: formData.name,
      description: formData.description,
      actions: [
        {
          type: ReportRuleActionType.EMAIL,
          data: {
            recipients: formData.emails?.map(
              (emailOption) => emailOption.value
            ),
          },
        },
      ],
      contentGroups: [
        {
          type: formData.contentType.value as ReportRuleContentGroupType,
          label: formData.contentType.label as string,
          elements: [
            ...formData.widgets.map((widgetOption) => ({
              id: widgetOption.value,
              label: widgetOption.label,
            })),
          ],

          configuration:
            formData.contentType.value === ReportRuleContentGroupType.RISK &&
            formData.timeFrame &&
            formData.trendMetricsTypes
              ? {
                  timeFrame: formData.timeFrame,
                  trendMetricsTypes: formData.trendMetricsTypes,
                }
              : undefined,
        },
      ],
      frequencyType: formData.frequencyType?.value as ReportRuleFrequencyType,
      properties: [
        {
          type: ReportRulePropertyType.SCOPE,
          values: [formData.scopes],
          operator: ReportRulePropertyOperator.IN,
        },
        {
          type: ReportRulePropertyType.GROUP,
          values: groups,
          operator: ReportRulePropertyOperator.IN,
        },
        {
          type: ReportRulePropertyType.SERVICE,
          values: formData.services as Array<AutocompleteOption>,
          operator: ReportRulePropertyOperator.IN,
        },
        {
          type: ReportRulePropertyType.DOMAIN,
          values: formData.domains as Array<AutocompleteOption>,
          operator: ReportRulePropertyOperator.IN,
        },
      ],
      status: ReportRuleStatus.ENABLED,
      type: formData.recipients.value as ReportRuleType,
    };
  }

  transformReportRuleToFormData(reportRule: ReportRule): ReportRuleFormData {
    const contentGroup = reportRule.contentGroups[0];
    const recipients =
      reportRuleRecipientsOptions.find(
        (option) => option.value === reportRule.type
      ) || reportRuleRecipientsOptions[0];

    const emailAction = reportRule.actions?.find(
      (action) => action.type === ReportRuleActionType.EMAIL
    );

    const groupFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.GROUP
    );

    const domainFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.DOMAIN
    );

    const multipleGroups =
      reportRule.type === ReportRuleType.GROUP_OWNERS ||
      (contentGroup.type ===
        ReportRuleContentGroupType.COMPARATIVE_PER_SERVICE &&
        reportRule.type === ReportRuleType.SERVICE_OWNERS);

    const groups: AutocompleteOption | Array<AutocompleteOption> =
      multipleGroups ? groupFilter?.values : groupFilter?.values[0];

    const serviceFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.SERVICE
    )?.values;

    const scopeFilter = reportRule.properties?.find(
      (property) => property.type === ReportRulePropertyType.SCOPE
    );

    const selectedFrequencyOption = reportRuleFrequencyOptions.find(
      (frequencyOption) => frequencyOption.value === reportRule.frequencyType
    );

    const riskTimeFrame = contentGroup.configuration?.timeFrame;

    const riskTrendMetricsTypes = contentGroup.configuration?.trendMetricsTypes;

    return {
      name: reportRule.name,
      description: reportRule.description || '',
      recipients: recipients,
      contentType: {
        value: contentGroup?.type,
        label: contentGroup?.label,
      },
      emails:
        emailAction?.data?.recipients?.map((recipient) => ({
          value: recipient,
          label: recipient,
        })) || [],
      frequencyType: selectedFrequencyOption as AutocompleteOption,
      groups: groups,
      scopes: scopeFilter?.values[0] as AutocompleteOption,
      services: serviceFilter as AutocompleteOption[],
      widgets: contentGroup.elements?.map((element) => ({
        value: element.id,
        label: element.label,
      })),
      timeFrame: riskTimeFrame,
      trendMetricsTypes: riskTrendMetricsTypes,
      domains: domainFilter?.values || [],
    };
  }
}

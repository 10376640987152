import { Chip, ChipProps } from '@mui/material';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';

interface CommonSimpleChipProps extends ChipProps {}
export const CommonSimpleChip: React.FC<CommonSimpleChipProps> = ({
  ...props
}) => {
  return (
    <Chip
      {...props}
      classes={{
        root: 'common-simple-chip',
        icon: 'common-simple-chip-icon',
        label: 'common-simple-chip-label',
        deleteIcon: 'common-simple-chip-delete-icon',
      }}
      deleteIcon={<OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} />}
    ></Chip>
  );
};
export default CommonSimpleChip;

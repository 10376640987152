import { Tooltip } from '@mui/material';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import { ColDef } from 'ag-grid-community';
import { CommonCheckbox } from 'shared/components/CommonCheckbox/CommonCheckbox';
import { CommonDataGridUtilityCell } from 'shared/components/CommonSimpleDataGrid/enums/common-data-grid.enum';
import {
  AwsIntegration,
  AzureIntegration,
  GCPIntegration,
} from 'shared/models/data/integrations.model';
import * as yup from 'yup';

export type IntegrationsFormDefaultKeys =
  | 'name'
  | 'enviroments'
  | 'isFallback'
  | 'scopeId';

export const keepOpenIntegrationCategories = [
  IntegrationCategory.FINDINGS_SOURCE,
  IntegrationCategory.SCM,
];

export const integrationsFormDefaultSchema: Record<
  IntegrationsFormDefaultKeys,
  any
> = {
  name: yup.string().required(),
  enviroments: yup.array().required(),
  scopeId: yup.string().optional(),
  isFallback: yup.bool(),
};

export const awsIntegrationFormRequiredFields = [
  'name',
  'environmentType',
  'externalId',
  'readRoleArn',
];

export const azureIntegrationFormRequiredFields = [
  'name',
  'environmentType',
  'azureTenantId',
  'opusAppObjectId',
];

export const googleCloudIntegrationFormRequiredFields = [
  'name',
  'environmentType',
  'credJson',
  'projectId',
];

export const awsIntegrationInputKeysToInputLabels: Partial<
  Record<keyof typeof awsIntegrationFormDefaultSchema, string>
> = {
  name: 'Name',
  externalId: 'External ID',
  readRoleArn: 'Read Role ARN',
  writeRoleArn: 'Write Role ARN',
  environmentType: 'Environment Type',
  scopeId: 'Scope',
};

export const awsIntegrationFormDefaultSchema: Partial<
  Record<keyof AwsIntegration, any>
> = {
  name: yup.string().required(),
  scopeId: yup.string().optional(),
  environmentType: yup.string().required(),
  externalId: yup.string().required(),
  readRoleArn: yup.string().required(),
  writeRoleArn: yup.string(),
  writeApplicationIds: yup.array().of(yup.string()),
};

export const azureIntegrationInputKeysToInputLabels: Partial<
  Record<keyof typeof azureIntegrationFormDefaultSchema, string>
> = {
  name: 'Name',
  azureSubscriptionId: 'Subscription ID',
  azureTenantId: 'Tenant ID',
  opusAppObjectId: 'Opus Application Object ID',
  environmentType: 'Environment Type',
  scopeId: 'Scope',
};

export const googleCloudIntegrationInputKeysToInputLabels: Partial<
  Record<keyof typeof googleCloudIntegrationFormDefaultSchema, string>
> = {
  name: 'Workspace Name',
  environmentType: 'Environment Type',
  credJson: 'Your Service account JSON key',
  projectId: 'Project ID',
};

export const azureIntegrationFormDefaultSchema: Partial<
  Record<keyof AzureIntegration, any>
> = {
  name: yup.string().required(),
  environmentType: yup.string().required(),
  scopeId: yup.string().optional(),
  azureSubscriptionId: yup.string().required(),
  azureTenantId: yup.string().required(),
  opusAppObjectId: yup.string().required(),
  writeApplicationIds: yup.array().of(yup.string()),
};

export const googleCloudIntegrationFormDefaultSchema: Partial<
  Record<keyof GCPIntegration, any>
> = {
  name: yup.string().required(),
  scopeId: yup.string().optional(),
  environmentType: yup.string().required(),
  credJson: yup.string().required(),
  writeApplicationIds: yup.array().of(yup.string()),
  projectId: yup.string().required(),
};

export const azureIntegrationScripts: Partial<Record<string, string>> = {
  downloadDeploymentScriptCommand:
    'Insert here the download bash script for Azure cloud shell',
  deploymentScript:
    'Insert here the script needed to run in Azure cloud shell for deploying accross a management group',
};

export const azureIntegrationLinks: Partial<Record<string, any>> = {
  azureTenantId: () =>
    'https://portal.azure.com/#view/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/~/Properties',
  connectOpusApp: (tenantId: string, globalGwBaseUrl: string) =>
    `https://login.microsoftonline.com/${tenantId}/oauth2/authorize?client_id=017961fd-f05f-4564-b0e2-c83722743baa&response_type=code&redirect_uri=${globalGwBaseUrl}/internal/connections/callback`,
  opusAppObjectId: () =>
    'https://portal.azure.com/#view/Microsoft_AAD_IAM/StartboardApplicationsMenuBlade/~/AppAppsPreview',
  azureSubscriptionId: () =>
    'https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fopus-cf-dev-us-east-1.s3.amazonaws.com%2FOpus%2Fopus_azure_rols_arm.json',
};

export const googleCloudIntegrationLinks: Partial<Record<string, any>> = {
  gcpConsoleLink: 'https://console.cloud.google.com/?cloudshell=true',
  shellCommand:
    'gcloud services enable cloudresourcemanager.googleapis.com dns.googleapis.com iam.googleapis.com apikeys.googleapis.com cloudasset.googleapis.com cloudkms.googleapis.com compute.googleapis.com container.googleapis.com sqladmin.googleapis.com',
  serviceAccountLink:
    'https://console.cloud.google.com/projectselector2/iam-admin/serviceaccounts/create',
};

export const integrationRepositoryTableColumns: Array<ColDef> = [
  {
    field: CommonDataGridUtilityCell.DATA_GRID_CHECKBOX_CELL,
    headerName: '',
    sortable: false,
    resizable: false,
    headerCheckboxSelection: true,
    lockPinned: true,
    lockPosition: true,
    lockVisible: true,
    headerClass: 'data-grid-checkbox-header',
    width: 10,
    cellRenderer: (props: any) => (
      <CommonCheckbox
        onClick={() => {
          props.node?.setSelected(!props.node?.selected);
        }}
        checked={props.node?.selected}
      />
    ),
  },
  {
    headerName: 'Name',
    field: 'displayName',
    flex: 1,
    cellRenderer: (props: any) => {
      return (
        <Tooltip
          title={
            <>
              <p>{props.data.displayName}</p>
              <p>{props.data.id}</p>
            </>
          }
        >
          <p className="integration-repository-table-row-text">
            {props.data.displayName}
          </p>
        </Tooltip>
      );
    },
  },
];

import { v4 as uuid } from 'uuid';

export interface InitConnectionResponse {
  redirectUrl: string;
  state: string;
}

export enum ConnectionTemplateType {
  PARAMS = 'parameters',
  OAUTH = 'oauth',
  AWS_ASSUME_ROLE = 'aws-assume-role',
}

export interface Parameter {
  name: string;
  displayName: string;
  hidden?: boolean;
  required?: boolean;
  defaultValue?: string;
}

export default interface ConnectionTemplateItem {
  id: string;
  name: string;
  type: ConnectionTemplateType;
  applicationId: string;
  description?: string;
  definition: any;
  parameters: Parameter[];
  allowsRemediation?: boolean;
  docsLink?: string;
}

export function init(raw: any): ConnectionTemplateItem {
  return {
    id: raw.id ?? uuid(),
    name: raw.name ?? '',
    parameters: raw.parameters ?? [],
    definition: raw.definition ?? {},
    description: raw.description ?? '',
    type: raw.type,
    applicationId: raw.applicationId,
    docsLink: raw.docsLink ?? '',
  };
}

export function initBulk(raw: any[]): ConnectionTemplateItem[] {
  return raw.map(init);
}

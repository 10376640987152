import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CrossroadConditionRoute } from '../CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import CrossroadPanelConditionList from '../CrossroadPanelConditionList';

export interface ConditionProps {
  deleteHandler: (
    routeIndex: number,
    rowIndex: number,
    columnIndex: number
  ) => void;
  addAndConditionHandler: (routeIndex: number) => void;
  addOrConditionHandler: (routeIndex: number) => void;
  updateHandler: (
    location: {
      routeIndex: number;
      rowIndex: number;
      columnIndex: number;
    },
    property: 'expressionA' | 'expressionB' | 'operator',
    value: string
  ) => void;
}

interface CrossroadPanelConditionRouteProps
  extends BaseComponentProps,
    CrossroadConditionRoute {
  routeIndex: number;
  deleteRouteHandler: (workflowSeqId: number) => void;
  setDefaultRouteHandler: (index: number) => void;
  updateRouteNameHandler: (index: number, value: string) => void;
  conditionProps: ConditionProps;
}

export const CrossroadPanelConditionRoute: FunctionComponent<
  CrossroadPanelConditionRouteProps
> = ({
  conditions,
  name,
  routeIndex,
  nextWorkflowSeqId,
  updateRouteNameHandler,
  setDefaultRouteHandler,
  deleteRouteHandler,
  conditionProps,
  isFallback,
}) => {
  const [nameState, setNameState] = useState({
    value: name,
    editable: false,
  });

  useEffect(() => {
    setNameState((prevNameState) => ({ ...prevNameState, value: name }));
  }, [name]);

  const renderNameInput = () => {
    if (nameState.editable) {
      return (
        <input
          className="text-field-1 crossroad-condition-route-accordion-summary-text-input"
          onChange={(event) => {
            const inputValue = event.target.value;

            setNameState((prevNameState) => ({
              ...prevNameState,
              value: inputValue,
            }));

            updateRouteNameHandler(routeIndex, inputValue);
          }}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
          }}
          value={nameState.value}
        ></input>
      );
    }

    return (
      <div
        className="crossroad-condition-route-accordion-summary-text-title"
        title={nameState.value}
      >
        {nameState.value}
      </div>
    );
  };

  const renderDefaultIcon = () => {
    if (isFallback) {
      return (
        <div className="crossroad-condition-route-accordion-summary-text-default">
          Default
        </div>
      );
    }

    return <></>;
  };

  return (
    <div className="crossroad-condition-route">
      <Accordion
        className="crossroad-condition-route-accordion"
        onChange={(event, expanded) => {
          setNameState((prevNameState) => ({
            ...prevNameState,
            editable: expanded,
          }));
        }}
      >
        <AccordionSummary
          className="crossroad-condition-route-accordion-summary"
          expandIcon={<OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />}
          onClick={(event) => event.preventDefault()}
        >
          <div className="crossroad-condition-route-accordion-summary-text">
            {renderNameInput()}
            {renderDefaultIcon()}
          </div>
          <IconButton
            className="crossroad-condition-route-accordion-summary-button"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              deleteRouteHandler(nextWorkflowSeqId || routeIndex);
            }}
          >
            <OpusSvgIcon
              type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED}
              height={13}
            />
          </IconButton>
        </AccordionSummary>
        <AccordionDetails className="crossroad-condition-route-accordion-details">
          <CrossroadPanelConditionList
            conditions={conditions || []}
            conditionProps={conditionProps}
            routeIndex={routeIndex}
            isRouteFallback={isFallback}
            setDefaultRouteHandler={setDefaultRouteHandler}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

import { FunctionComponent } from 'react';
import { BusinessUnitImpact } from 'shared/enums/business-unit.enum';
import { CampaignCriticality } from 'shared/models/data/campaigns.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
type ImpactType = BusinessUnitImpact | CampaignCriticality;

interface BusinessImpactChipProps extends BaseComponentProps {
  impact: ImpactType;
}

export const BusinessImpactChip: FunctionComponent<BusinessImpactChipProps> = ({
  impact,
}) => {
  const getChipClassname = (impact: ImpactType) => {
    switch (impact) {
      case (BusinessUnitImpact.CBI, CampaignCriticality.CBI):
        return 'business-impact-critical';
      case (BusinessUnitImpact.HBI, CampaignCriticality.HBI):
        return 'business-impact-high';
      case (BusinessUnitImpact.MBI, CampaignCriticality.MBI):
        return 'business-impact-medium';
      case (BusinessUnitImpact.LBI, CampaignCriticality.LBI):
        return 'business-impact-low';

      default:
        return '';
    }
  };
  return (
    <div className={`business-impact-chip ${getChipClassname(impact)}`}>
      {impact}
    </div>
  );
};

import { GridType } from 'Risk/store';
import {
  FilterItem,
  FilterOption,
} from 'shared/components/SearchFilter/LegacyAdvanceFilter';

export class NavigationHandler {
  getRiskNavigationUrlWithParameters(
    tabId: GridType,
    filterList: Partial<
      Record<string, Array<FilterOption> | FilterOption | undefined>
    >,
    disableDefaultFilter = false
  ) {
    return `/risks?activeTabId=${tabId}${
      Object.keys(filterList).length
        ? `&filter=${encodeURIComponent(JSON.stringify(filterList))}`
        : ''
    }${disableDefaultFilter ? `&disableDefaultFilter=true` : ''}`;
  }

  handleRiskNavigation(
    tabId: GridType,
    filterList: Partial<
      Record<string, Array<FilterOption> | FilterOption | undefined>
    >,
    disableDefaultFilter = false
  ) {
    const riskNavigationUrl = this.getRiskNavigationUrlWithParameters(
      tabId,
      filterList,
      disableDefaultFilter
    );

    window.open(riskNavigationUrl, '_blank');
  }
}

import { yupResolver } from '@hookform/resolvers/yup';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import { CommonDialogProps } from 'Common/components/Dialogs/CommonDialog/CommonDialog';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import * as yup from 'yup';
import {
  Alert,
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import DropzoneFileUploader from '../DropzoneFileUploader';
import { useFetchFindingFileImportApplicationsQuery } from 'Application/store/api';
import OpusImageIcon from '../IconComponents/OpusImageIcon';
import { useUploadImportFindingsFileMutation } from 'Risk/store/api';
import FormattedMessage from '../FormattedMessage';
import {
  FindingFileImportApplicationItem,
  FindingFileImportMapperItem,
} from 'Application/interfaces/item';

interface ImportFindingsModalProps extends CommonDialogProps {}

interface ImportFindingFormProps {
  applicationId: string;
  FindingMapperId: string;
  file: File;
}

const importFindingSchema = yup.object().shape({
  applicationId: yup.string().required(),
  FindingMapperId: yup.string().required(),
  file: yup.mixed().required(),
});

export const ImportFindingsModal: React.FC<ImportFindingsModalProps> = ({
  onClose,
  open,
}) => {
  const { t: translation } = useTranslation();
  const formElement = useRef<HTMLFormElement>(null);

  const { data: applicationsData, isLoading: isLoadingApplicationList } =
    useFetchFindingFileImportApplicationsQuery();
  const [
    handleUploadFile,
    {
      data: fileData,
      isLoading: isLoadingUploadFile,
      isError: uploadFileFailed,
      isSuccess: uploadFileSuccess,
      reset: resetUploadingFindingsFile,
    },
  ] = useUploadImportFindingsFileMutation();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    getValues,
    reset: resetForm,
    formState: { errors },
    watch,
  } = useForm<ImportFindingFormProps>({
    resolver: yupResolver(importFindingSchema),
    defaultValues: {
      applicationId: '',
      FindingMapperId: '',
      file: undefined,
    },
  });

  const applicationSelectedValue = watch('applicationId');

  const formats = useMemo(() => {
    if (!applicationSelectedValue || !applicationsData) return [];

    const selectedApp: FindingFileImportApplicationItem | undefined =
      applicationsData.find(
        (app: FindingFileImportApplicationItem) =>
          app.id === applicationSelectedValue
      );

    return selectedApp
      ? (selectedApp.fileMappers as FindingFileImportMapperItem[])
      : [];
  }, [applicationSelectedValue, applicationsData]);

  const inputChangeHandler = (
    inputName: keyof ImportFindingFormProps,
    inputValue: string
  ) => {
    if (inputValue.length) {
      clearErrors(inputName);
    }
    setValue(inputName, inputValue.trim());
  };

  useEffect(() => {
    if (!open) resetForm();
  }, [open]);

  const onSubmit = async (data: ImportFindingFormProps) => {
    try {
      const formData = new FormData();
      formData.append('applicationId', data.applicationId);
      formData.append('FindingMapperId', data.FindingMapperId);
      formData.append('file', data.file);
      handleUploadFile(formData);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <CommonDialog
        open={open}
        onClose={onClose}
        saveButtonText={translation('common.import')}
        title={translation('risk.importFindings')}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.FILE_IMPORT_ICON} />}
        classes="import-findings-modal"
        onSave={() => {
          formElement.current?.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          );
        }}
        isOnSubmitDisabled={isLoadingUploadFile}
      >
        <form ref={formElement} onSubmit={handleSubmit(onSubmit)}>
          <div className="import-findings-modal-container">
            <div>
              <p className="body-1 description">
                <FormattedMessage
                  defaultMessage=" Choose your file application and format for importing findings
                into the Opus platform"
                  id="risk.import.description"
                />
              </p>
            </div>
            <div>
              <p className="body-1">
                <FormattedMessage
                  defaultMessage="Integration"
                  id="risk.import.integration"
                />
              </p>
              <Select
                fullWidth
                displayEmpty
                value={getValues().applicationId}
                className="input-dialog select-input"
                {...register('applicationId')}
                onChange={(event) => {
                  inputChangeHandler('applicationId', event.target.value);
                }}
                renderValue={(selected: string) => {
                  if (!selected) {
                    return (
                      <p className="body-1">
                        <FormattedMessage
                          defaultMessage="Select an integration"
                          id="risk.import.integrationPlaceholder"
                        />
                      </p>
                    );
                  } else {
                    const selectedApp = applicationsData?.find(
                      (app: FindingFileImportApplicationItem) =>
                        app.id === selected
                    );
                    return (
                      <Grid className="select-form-container">
                        <OpusImageIcon
                          src={selectedApp?.logo || ''}
                          className="organization-form-image-container"
                        />
                        <p className="body-1">{selectedApp?.name}</p>
                      </Grid>
                    );
                  }
                }}
                name="applicationId"
                inputProps={{ placeholder: 'Select an application' }}
              >
                {!isLoadingApplicationList ? (
                  applicationsData?.map(
                    (app: FindingFileImportApplicationItem) => (
                      <MenuItem key={app.id} value={app.id}>
                        <Grid className="select-form-container">
                          <OpusImageIcon
                            src={app.logo}
                            className="organization-form-image-container"
                          />
                          <p className="body-1">{app.name}</p>
                        </Grid>
                      </MenuItem>
                    )
                  )
                ) : (
                  <MenuItem disabled>
                    <Grid className="select-form-container">
                      <CircularProgress size={14} />
                      <FormattedMessage
                        defaultMessage="Loading"
                        id="common.loading"
                      />
                    </Grid>
                  </MenuItem>
                )}
              </Select>
              <div className="validation-error-text">
                {errors.applicationId ? 'Integration is required' : ''}
              </div>
            </div>
            <div>
              <p className="body-1">
                <FormattedMessage
                  defaultMessage="Format"
                  id="risk.import.format"
                />
              </p>
              <Select
                fullWidth
                value={getValues().FindingMapperId}
                className="input-dialog select-input"
                {...register('FindingMapperId')}
                onChange={(event) => {
                  inputChangeHandler('FindingMapperId', event.target.value);
                }}
                name="FindingMapperId"
                displayEmpty
              >
                {formats?.map((option: FindingFileImportMapperItem) => (
                  <MenuItem key={option.mapperId} value={option.mapperId}>
                    <div className="select-form-container">
                      <p className="body-1">{option.mapperName}</p>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              <div className="validation-error-text">
                {errors.FindingMapperId ? 'Format is required' : ''}
              </div>
            </div>
            <DropzoneFileUploader
              maxFileSize={20971520}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length > 0) {
                  setValue('file', acceptedFiles[0]);
                }
              }}
            />
            <div className="validation-error-text">
              {errors.file ? 'File is required' : ''}
            </div>
          </div>
        </form>
      </CommonDialog>
      {isLoadingUploadFile && (
        <Snackbar
          open={isLoadingUploadFile}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="info" sx={{ width: '1000%' }}>
            <FormattedMessage
              defaultMessage="File is getting imported"
              id="risk.import.fileImportInProgress"
            />
          </Alert>
        </Snackbar>
      )}
      <Snackbar
        open={uploadFileSuccess}
        onClose={() => resetUploadingFindingsFile()}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          <FormattedMessage
            defaultMessage="File was imported successfully"
            id="risk.import.fileImportedSuccessfully"
          />
        </Alert>
      </Snackbar>
      <Snackbar
        open={uploadFileFailed}
        onClose={() => resetUploadingFindingsFile()}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ width: '100%' }}>
          <FormattedMessage
            defaultMessage="File import failed"
            id="risk.import.fileImportFailed"
          />
        </Alert>
      </Snackbar>
    </>
  );
};

import React from 'react';
import { Button, Drawer } from '@mui/material';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
interface PopoverWrapperProps {
  children?: React.ReactNode;
  showPopover: boolean;
  handleClose: () => void;
  setUpArrow?: (vl: boolean) => void;
  setDowArrow?: (vl: boolean) => void;
  classes?: string;
  hideBackdrop?: boolean;
}

const PopoverWrapper: React.FC<PopoverWrapperProps> = ({
  children,
  showPopover,
  handleClose,
  setUpArrow,
  setDowArrow,
  classes,
  hideBackdrop = false,
}) => {
  return (
    <>
      <Drawer
        anchor="right"
        open={showPopover}
        onClose={() => handleClose()}
        className={`drawer-container ${classes}`}
        classes={{
          paper: 'drawer-paper',
        }}
        hideBackdrop={hideBackdrop}
      >
        {showPopover ? (
          <div className="drawer-paper-buttons">
            <Button onClick={() => handleClose()} className="btn-popover">
              <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE} />
            </Button>
          </div>
        ) : (
          <></>
        )}

        <div className="drawer-paper-body">{children}</div>
      </Drawer>
    </>
  );
};

export default PopoverWrapper;

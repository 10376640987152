import { Box } from '@mui/material';
import React, { useState, MouseEvent } from 'react';
import { Handle, Position, useStoreApi } from 'react-flow-renderer';
import DiagramPlusButton from '../PlusButton';
import StepControlPopover from '../StepControlPopover';
import { NodeProps } from '../../interfaces/node';
import { setAfterTool } from '../../store';
import useCommonDispatch from '../../../Common/utils/use-dispatch';
import OpusSvgIcon from '../../../shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface Props {
  data: NodeProps;
}

export function WorkflowTriggerNode({ data }: Props) {
  const store = useStoreApi();
  const dispatch = useCommonDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { label, icon, isLeaf, seq, readonly } = data;

  const open = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handlePlusClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleAddToolClick() {
    handleClose();
    dispatch(setAfterTool(seq));
  }

  function handleAddCrossoard() {
    handleClose();

    const nodes: Array<any> = Array.from(store.getState().nodeInternals).map(
      ([, node]) => node
    );

    const previousNode = nodes.find((node) => {
      return node.id === String(seq);
    });
    data.onAddCrossroad && data.onAddCrossroad(previousNode);
  }

  return (
    <Box position="relative">
      <React.Fragment>
        <Handle
          type="target"
          position={Position.Top}
          style={{
            visibility: 'hidden',
            top: '0.5rem',
          }}
        />
        <button
          className={`workflow-builder-trigger-node-container ${
            data.value?.length
              ? 'workflow-builder-trigger-node-selected'
              : 'workflow-builder-trigger-node-empty'
          }`}
          onClick={() => {
            if (!readonly && data.displayTriggerPanel) {
              data.displayTriggerPanel();
            }
          }}
        >
          <div className="workflow-builder-trigger-node-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.BOLT_LIGHTNING_ICON} />
          </div>

          <h5 className="workflow-builder-trigger-node-title">{label}</h5>
          {data.value?.length ? (
            <div className="workflow-builder-trigger-node-items-counter">
              <b>{data.value.length}</b>
            </div>
          ) : (
            <></>
          )}
        </button>
        <Handle
          type="source"
          position={Position.Bottom}
          className="workflow-builder-handle"
          style={{
            bottom: '-6px',
          }}
        />
        {isLeaf && !readonly && (
          <Box className="workflow-builder-trigger-node-plus-button">
            <DiagramPlusButton onClick={handlePlusClick} />
            <StepControlPopover
              open={open}
              handleClose={handleClose}
              anchorEl={anchorEl}
              handleCrossroadClick={handleAddCrossoard}
              handleToolClick={handleAddToolClick}
            />
          </Box>
        )}
      </React.Fragment>
    </Box>
  );
}

import { useState, FunctionComponent, useEffect, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Popover,
  PopoverOrigin,
  TextField,
} from '@mui/material';
import SearchInput from 'shared/components/SearchInput/SearchInput';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterMainItem from 'shared/components/FilterItems/FilterMainItem';
import FilterCheckboxItem from 'shared/components/FilterItems/FilterCheckboxItem';
import useCommonDispatch from 'Common/utils/use-dispatch';
import Flatpickr from 'react-flatpickr';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import { FilterItem, FilterOption } from '../SearchFilter/LegacyAdvanceFilter';
import styles from './filter-drop-down.module.scss';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import classNames from 'classnames';
import { useGetAdvanceFilterGetter } from 'shared/hooks/useGetAdvanceFilterGetter';

export type mutationProps = {
  params: { [key: string]: any };
  fetchMutation: any;
};

export enum StaticFilterOptions {
  SELECT_ALL = 'select_all',
  NONE = 'None',
}

export const getSelectAllFilterOption = (translation: any): FilterOption => {
  return {
    id: StaticFilterOptions.SELECT_ALL,
    name: translation('common.selectAll'),
    checked: false,
  };
};

const menuItemsComparator = (a: any, b: any, order: 'ASC' | 'DESC') => {
  const nameA = a?.name?.toUpperCase();
  const nameB = b?.name?.toUpperCase();
  if (nameA < nameB) {
    return order === 'ASC' ? -1 : 1;
  }
  if (nameA > nameB) {
    return order === 'ASC' ? 1 : -1;
  }
  return 0;
};

export type FilterDropdownProps = {
  id: any;
  open: boolean;
  anchorEl: any;
  handleClose: () => void;
  listItems: any;
  mainMenuItemsSort?: 'ASC' | 'DESC';
  data: any;
  isSubMenu?: boolean;
  handleGoBack?: () => void;
  isEdit?: boolean;
  setListItems: (id: any) => any;
  setListFilter?: any;
  handleItemSelect: (element: FilterOption, data: []) => any;
  filteredData: boolean;
  setFilteredData: (v: boolean) => void;
  className?: string;
  mutationProps: mutationProps;
  storeSynchronization?: boolean;
  handleSelectAll?: (filterItem: FilterItem) => any;
  allOption?: boolean;
  isSelectAllCheckboxEnabled?: boolean;
  listMapper?: (items: Array<any>) => Array<any>;
  anchorOrigin?: PopoverOrigin;
};

const FilterDropdown: FunctionComponent<FilterDropdownProps> = ({
  id,
  open,
  anchorEl,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  handleClose,
  isSubMenu,
  data,
  isEdit,
  listItems,
  mainMenuItemsSort,
  handleGoBack,
  setListItems,
  handleItemSelect,
  filteredData,
  setFilteredData,
  className = '',
  mutationProps: { fetchMutation, params },
  setListFilter,
  storeSynchronization,
  handleSelectAll,
  allOption = false,
  isSelectAllCheckboxEnabled = true,
  listMapper,
}) => {
  const { t: translation } = useTranslation();

  const selectAllFilterOption = getSelectAllFilterOption(translation);

  const dispatch = useCommonDispatch();
  const [dateRange, setDateRange] = useState<any>(null);
  const [numberInput, setNumberInput] = useState<number | null>(null);
  const [
    fetchFilterInformationForField,
    { isLoading: isFilterInformationLoading, data: filterInformation },
  ] = fetchMutation();

  const getterProps = useGetAdvanceFilterGetter(listItems?.optionGetterId);

  const [sortedMenuOptions, setSortedMenuOptions] = useState<any>([]);

  const addOptionListToFilterItem = (
    filterItems: Array<FilterItem>,
    filterOptions: Array<FilterOption>
  ) => {
    const updatedListData = filterItems?.map((filterItem: FilterItem) => {
      if (filterItem.id === listItems.id) {
        let items: Array<FilterOption> = filterOptions.map(
          (filterOption: FilterOption) => {
            const existingItem = filterItem.items.find(
              (item: { id: any }) => item?.id === filterOption?.id
            );

            if (existingItem) {
              return existingItem;
            }

            return { ...filterOption, checked: false };
          }
        );

        items.sort((itemA: FilterOption, itemB: FilterOption) =>
          itemA?.name?.toLowerCase() > itemB?.name?.toLowerCase() ? 1 : -1
        );

        items.sort((a: FilterOption, b: FilterOption) =>
          a.checked ? -1 : b.checked ? 1 : 0
        );

        if (filterItem.keepExistingOptions) {
          const existingOptions = filterItem.items;

          items = [...existingOptions, ...items]?.filter(
            (
              item: FilterOption,
              index: number,
              itemList: Array<FilterOption>
            ) =>
              index ===
              itemList.findIndex((itemElement) => itemElement.id === item.id)
          );
        }

        if (allOption) {
          const allOptionItem = filterItem.items.find(
            (item) => item?.id === 'All'
          );
          if (!allOptionItem) {
            items.unshift({ id: 'All', name: 'All' });
          }
        }

        return {
          ...filterItem,
          items: items,
          loaded: true,
        };
      }

      return filterItem;
    });

    return updatedListData;
  };

  useEffect(() => {
    if (!filteredData && !isSubMenu) {
      if (mainMenuItemsSort) {
        let listItemsSorted = [...listItems].sort((a, b) =>
          menuItemsComparator(a, b, mainMenuItemsSort)
        );
        setSortedMenuOptions(listItemsSorted);
      } else {
        setSortedMenuOptions(listItems);
      }
    }
  }, [listItems, mainMenuItemsSort]);

  const allChecked = useMemo(() => {
    if (
      isSubMenu &&
      listItems &&
      listItems.type === TypeFilter.MULTI_SELECT &&
      listItems.loaded
    ) {
      return listItems.items.every((item: FilterOption) => item.checked);
    }

    return false;
  }, [listItems]);

  useEffect(() => {
    if (
      listItems?.id &&
      !listItems?.loaded &&
      listItems?.type !== TypeFilter.DATE_PICKER_RANGE &&
      open &&
      !getterProps
    ) {
      fetchFilterInformationForField({
        ...params,
        field: listItems.id,
      });
    }
    setDateRange(listItems?.dateRange);
    setNumberInput(listItems?.numberInput);
  }, [listItems?.id, open]);

  useEffect(() => {
    if (getterProps?.getter && !listItems?.loaded) {
      getterProps?.reset && getterProps?.reset();
      getterProps.getter({
        ...(listItems?.bodyParameters || {}),
        ...(listItems?.additionalBodyParameters || {}),
      });
    }
  }, [getterProps?.getter, !listItems?.loaded]);

  useEffect(() => {
    if (filterInformation && listItems?.id && !listItems.loaded) {
      const filterInformationOptions = listMapper
        ? listMapper(filterInformation)
        : filterInformation;

      const filterOptions = filterInformationOptions.map((option: any) => ({
        id: option.value,
        name: option.label || option.value,
        businessIds: option.businessIds,
      }));

      const updatedListData = addOptionListToFilterItem(data, filterOptions);

      if (storeSynchronization) {
        dispatch(setListFilter(updatedListData));
      } else {
        setListFilter(updatedListData);
      }
    }
  }, [filterInformation]);

  useEffect(() => {
    if (getterProps?.data) {
      const filterOptions = getterProps?.data;

      const updatedListData = data?.map(
        (filterItem: {
          id: any;
          items: any[];
          nested: boolean;
          filterItems: Array<any>;
        }) => {
          const isNestedItem = Boolean(
            filterItem.nested &&
              filterItem.filterItems?.find(
                (dataItem) => dataItem.id === listItems.id
              )
          );

          if (isNestedItem) {
            return {
              ...filterItem,
              filterItems: addOptionListToFilterItem(
                filterItem.filterItems,
                filterOptions
              ),
            };
          }

          if (filterItem.id === listItems.id) {
            let items = filterOptions.map((filterOption: { id: any }) => {
              const existingItem = filterItem.items.find(
                (item: { id: any }) => item?.id === filterOption?.id
              );

              if (existingItem) {
                return existingItem;
              }

              return filterOption;
            });

            if (filterItem.items?.length) {
              const additionalItems = filterItem.items.filter(
                (selectedItem) =>
                  !Boolean(
                    filterOptions?.find(
                      (filterOption: FilterOption) =>
                        filterOption.id === selectedItem.id
                    )
                  )
              );

              if (additionalItems.length) {
                items = [...additionalItems, ...items];
              }
            }

            items.sort((itemA: FilterOption, itemB: FilterOption) =>
              itemA?.name?.toLowerCase() > itemB?.name?.toLowerCase() ? 1 : -1
            );

            items.sort((a: { checked: any }, b: { checked: any }) =>
              a.checked ? -1 : b.checked ? 1 : 0
            );

            if (allOption) {
              const allOptionItem = filterItem.items.find(
                (item) => item?.id === 'All'
              );
              if (!allOptionItem) {
                items.unshift({ id: 'All', name: 'All' });
              }
            }

            return {
              ...filterItem,
              items: items,
              loaded: true,
            };
          }

          return filterItem;
        }
      );

      if (storeSynchronization) {
        dispatch(setListFilter(updatedListData));
      } else {
        setListFilter(updatedListData);
      }
    }
  }, [getterProps?.data]);

  const handleDateChange = (selectedDates: any) => {
    setDateRange(selectedDates);
    if (selectedDates?.length === 2) {
      handleItemSelect(selectedDates, listItems);
    }
  };

  const handleNumberSelection = (event: any) => {
    setNumberInput(event.target.value);
    handleItemSelect(event.target.value, listItems);
  };

  if (!listItems) return <></>;

  const renderSelectAllItem = () => {
    if (
      isSubMenu &&
      listItems.type === TypeFilter.MULTI_SELECT &&
      listItems.loaded &&
      isSelectAllCheckboxEnabled
    ) {
      return (
        <div className="popover-list" key={selectAllFilterOption.id}>
          <FilterCheckboxItem
            item={selectAllFilterOption}
            checked={allChecked}
            key={selectAllFilterOption.id}
            clickItem={() => {
              handleSelectAll && handleSelectAll(listItems);
            }}
          />
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        className={`popover-filter ${className}`}
      >
        {isSubMenu && (
          <div className="popover-nav-bar">
            {!isEdit && (
              <ArrowBackIcon
                className="popover-back-icon"
                onClick={handleGoBack}
              />
            )}
            <span className="popover-nav-name">{listItems?.name}</span>
          </div>
        )}

        {(listItems.type === TypeFilter.MULTI_SELECT ||
          listItems.type === TypeFilter.SINGLE_SELECT) && (
          <SearchInput
            fullWidth={true}
            setListItems={setListItems}
            menuList={listItems}
            isSubMenu={isSubMenu}
            setNoFilter={setFilteredData}
            data={data}
          />
        )}

        {isFilterInformationLoading || getterProps?.isDataLoading ? (
          <div className={styles.circularContainer}>
            <CircularProgress size={24} />
          </div>
        ) : (
          <>
            {(filteredData || listItems.items?.length === 0) &&
            isSubMenu &&
            !isFilterInformationLoading &&
            !getterProps?.isDataLoading &&
            listItems?.type !== TypeFilter.DATE_PICKER_RANGE &&
            listItems?.type !== TypeFilter.NUMBER ? (
              <p className="empty-result-text">No result found</p>
            ) : (
              <></>
            )}

            {!isSubMenu &&
              sortedMenuOptions &&
              sortedMenuOptions?.length > 0 &&
              sortedMenuOptions.map((e: any) => {
                return (
                  <div className="popover-list" key={e.id}>
                    <FilterMainItem
                      item={e}
                      key={e.id}
                      clickItem={() => handleItemSelect(e.id, listItems)}
                    />
                  </div>
                );
              })}

            {!filteredData &&
              isSubMenu &&
              listItems &&
              listItems.items?.length > 0 &&
              listItems.type === TypeFilter.MULTI_SELECT && (
                <>
                  {renderSelectAllItem()}
                  {listItems.items.map((e: any) => {
                    return (
                      <div className="popover-list" key={e.id}>
                        <FilterCheckboxItem
                          item={e}
                          key={e.id}
                          clickItem={() => handleItemSelect(e, listItems)}
                        />
                      </div>
                    );
                  })}
                </>
              )}

            {!filteredData &&
              isSubMenu &&
              listItems &&
              listItems.items?.length > 0 &&
              listItems.type === TypeFilter.SINGLE_SELECT &&
              listItems.items.map((e: any) => {
                return (
                  <div className="popover-list" key={e.id}>
                    {/* <FilterCheckboxItem
                  item={e}
                  key={e.id}
                  clickItem={() => handleItemSelect(e, listItems)}
                /> */}
                    <Box
                      className={classNames('filter-item')}
                      key={e.id}
                      onClick={() => handleItemSelect(e, listItems)}
                      title={e.name}
                    >
                      <span className="body-1 item-text">{e.name}</span>
                      {e.checked && <CheckIcon />}
                    </Box>
                  </div>
                );
              })}

            {listItems.type === TypeFilter.DATE_PICKER_RANGE &&
              !filteredData &&
              isSubMenu &&
              listItems && (
                <div className="date-picker-container" key={listItems.id}>
                  <Flatpickr
                    options={{
                      mode: 'range',
                      dateFormat: 'Y-m-d',
                      inline: true,
                    }}
                    placeholder="Choose date range"
                    value={dateRange as any}
                    onChange={handleDateChange}
                  />
                </div>
              )}
            {listItems.type === TypeFilter.NUMBER &&
              !filteredData &&
              isSubMenu &&
              listItems && (
                <div className="number-input-container" key={listItems.id}>
                  <TextField
                    type="number"
                    InputProps={{
                      inputProps: {
                        max: 100,
                        min: 10,
                      },
                    }}
                    value={numberInput as number}
                    onChange={handleNumberSelection}
                  />
                </div>
              )}
          </>
        )}
      </Popover>
    </>
  );
};

export default FilterDropdown;

import React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

interface CommonTooltipProps extends TooltipProps {}

const CommonTooltip: React.FC<CommonTooltipProps> = ({
  title,
  children,

  ...otherProps
}) => {
  return (
    <Tooltip
      {...otherProps}
      className="common-tooltip"
      arrow={true}
      classes={{
        popper: 'common-tooltip-popper',
        arrow: 'common-tooltip-arrow',
        tooltip: ' common-tooltip-container',
      }}
      title={<React.Fragment>{title}</React.Fragment>}
    >
      {children}
    </Tooltip>
  );
};

export default CommonTooltip;

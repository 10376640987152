import React, { FunctionComponent } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowDirection,
  OverflowTextDisplay,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface ItemWithIconProps extends BaseComponentProps {
  title?: string | number;
  missingTitleKey: string;
  icon?: React.ReactElement;
  description?: string;
  maxWidth?: number;
  displayIconOutline?: boolean;
}

export const ItemWithIcon: FunctionComponent<ItemWithIconProps> = ({
  icon,
  title,
  missingTitleKey,
  description,
  maxWidth,
  displayIconOutline = true,
}) => {
  const { t: translation } = useTranslation();

  const renderContent = () => {
    const titleItem = (
      <p className="body-1">
        <TextOverflow
          direction={OverflowDirection.WIDTH}
          type={OverflowTextDisplay.ELLIPSIS}
        >
          {title || translation(missingTitleKey)}
        </TextOverflow>
      </p>
    );

    if (description) {
      return (
        <>
          <div className="title">{titleItem}</div>
          <div className="description">
            <p className="body-3">
              <TextOverflow
                direction={OverflowDirection.WIDTH}
                type={OverflowTextDisplay.ELLIPSIS}
                maxWidth={maxWidth}
              >
                {description}
              </TextOverflow>
            </p>
          </div>
        </>
      );
    }
    return titleItem;
  };
  return (
    <div className="item-with-icon-component">
      {icon && (
        <div className={`avatar-container ${displayIconOutline && 'bordered'}`}>
          {icon}
        </div>
      )}
      <div className="text-container">{renderContent()}</div>
    </div>
  );
};

import { ChartData, ChartDataItem } from 'shared/models/data/chart.model';

export class ChartsHandler {
  static getOrCreateLegendList(chart: any, id: string) {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';
      listContainer.style.justifyContent = 'space-around';
      listContainer.style.paddingBottom = '2rem';
      listContainer.style.paddingTop = '0.5rem';

      legendContainer?.appendChild(listContainer);
    }

    return listContainer;
  }

  static getHtmlLegendPlugin() {
    return {
      id: 'htmlLegend',
      afterUpdate(chart: any, args: any, options: any) {
        const ul = ChartsHandler.getOrCreateLegendList(
          chart,
          options.containerID
        );

        // Remove old legend items
        while (ul.firstChild) {
          ul.firstChild.remove();
        }

        // Reuse the built-in legendItems generator
        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach((item: any) => {
          const li = document.createElement('li');
          li.style.alignItems = 'center';
          li.style.cursor = 'pointer';
          li.style.display = 'flex';
          li.style.flexDirection = 'row';
          li.style.marginLeft = '10px';

          li.onclick = () => {
            const { type } = chart.config;
            if (type === 'pie' || type === 'doughnut') {
              // Pie and doughnut charts only have a single dataset and visibility is per item
              chart.toggleDataVisibility(item.index);
            } else {
              chart.setDatasetVisibility(
                item.datasetIndex,
                !chart.isDatasetVisible(item.datasetIndex)
              );
            }
            chart.update();
          };

          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '30px';
          boxSpan.style.marginRight = '10px';
          boxSpan.style.width = '70px';

          // Text
          const textContainer = document.createElement('b');
          textContainer.style.color = item.fontColor;
          textContainer.style.margin = '0';
          textContainer.style.padding = '0';
          textContainer.style.textDecoration = item.hidden
            ? 'line-through'
            : '';
          textContainer.style.fontSize = '12px';

          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      },
    };
  }

  static getWidgetDataAndApplyStyle(
    widgetData: ChartData,
    style: any,
    applyColorlist?: boolean
  ): ChartData {
    return {
      ...widgetData,
      datasets: widgetData?.datasets?.map(
        (dataset: ChartDataItem, index: number) => {
          if (applyColorlist) {
            return {
              ...dataset,
              ...style,
            };
          }

          const backgroundColorList = style?.backgroundColor || [];
          const borderColorList = style?.borderColor || [];

          return {
            ...dataset,
            ...style,
            backgroundColor:
              backgroundColorList[index] ||
              backgroundColorList[
                Math.round(index % backgroundColorList.length)
              ],
            borderColor:
              typeof borderColorList === 'string'
                ? borderColorList
                : borderColorList[index] ||
                  borderColorList[Math.round(index % borderColorList.length)],
          };
        }
      ),
    };
  }
}

import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Button, Popover, CircularProgress } from '@mui/material';
import Autocomplete from 'Common/components/Autocomplete';
import { AutocompleteOption } from 'FindingDetails/store/api';
import OrganizationSelectableTree from 'Organization/components/OrganizationSelectableTree';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import CommonSimpleChip from 'shared/components/CommonSimpleChip/CommonSimpleChip';
import { OrganizationNodeDataHandler } from 'shared/handlers/organization-data-node.handler';
import { OrganizationNodeDataHandler as OrganizationNodeOptionDataHandler } from 'shared/handlers/organization-node-data.handler';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { iconMap } from 'Organization/components/OrganizationNodeLabel/OrganizationNodeLabel';
import { UserInfoExtended } from 'Auth/store/api';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';

export interface OrganizationSelectDropdownProps {
  orgData?: OrganizationNode;
  isLoading?: boolean;
  userData?: UserInfoExtended;
  onSelectedNodesChangeCallback: (nodes: any) => void;
}

const organizationNodeOptionDataHandler =
  new OrganizationNodeOptionDataHandler();

const OrganizationSelectDropdown = ({
  orgData,
  isLoading,
  userData,
  onSelectedNodesChangeCallback,
}: OrganizationSelectDropdownProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(['']);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedNodesByScope, setSelectedNodesByScope] = useState<{
    [key: string]: string[];
  }>({});

  const [selectRefs, setSelectRefs] = useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<Array<null | HTMLElement>>(
    new Array(selectedValues.length).fill(null)
  );

  const [fetchScopeList, { data: scopeData, isLoading: scopeDataLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [open, setOpen] = React.useState(false);

  const organizationNodeDataHandler = useMemo(() => {
    if (orgData) {
      return new OrganizationNodeDataHandler(orgData);
    }
    return null;
  }, [orgData]);

  const scopeAutocompleteList: Array<AutocompleteOption> = useMemo(() => {
    return scopeData
      ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeData
        )
      : [];
  }, [scopeData, selectedValues]);

  const scopeList: Array<AutocompleteOption> = useMemo(() => {
    return scopeAutocompleteList.filter(
      (scope: AutocompleteOption) => !selectedValues.includes(scope.value)
    );
  }, [scopeAutocompleteList, selectedValues]);

  function groupByAllowedScopes(data?: any[]) {
    return data?.reduce((result, item) => {
      const allowedScope = item.allowedScopes[0];

      if (!result[allowedScope]) {
        result[allowedScope] = [];
      }

      result[allowedScope].push(item.id);

      return result;
    }, {});
  }

  const getGroupedByAllowedScopes = () => {
    if (userData && scopeData) {
      const { scopesInfo, groupsInfo, dataInfo } = userData?.orgStructureInfo;
      const idsToFindSet = new Set(
        [...scopesInfo, ...groupsInfo, ...dataInfo].map((item: any) => item.id)
      );

      const idsToFind = Array.from(idsToFindSet);
      const foundNodes = organizationNodeDataHandler?.findNodesByIds(idsToFind);

      const egligbleNodes = foundNodes?.filter((node) =>
        scopeData?.find((scopeItem: any) =>
          node?.allowedScopes?.includes(scopeItem?.id)
        )
      );

      const groupedByAllowedScopes = groupByAllowedScopes(egligbleNodes);

      const groupedByAllScopes = groupByAllowedScopes(foundNodes);

      return { groupedByAllowedScopes, groupedByAllScopes };
    }

    return { groupedByAllowedScopes: {}, groupedByAllScopes: {} };
  };

  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);

  useEffect(() => {
    setSelectRefs(selectedValues.map(() => React.createRef()));
    setAnchorEl(new Array(selectedValues.length).fill(null));
  }, [selectedValues]);

  useEffect(() => {
    if (!userData) return;

    const { groupedByAllowedScopes, groupedByAllScopes } =
      getGroupedByAllowedScopes();

    const keysArray = Object.keys(groupedByAllowedScopes);

    if (keysArray.length > 0) setSelectedValues(keysArray);
    else setSelectedValues(['']);

    setSelectedNodesByScope(groupedByAllScopes);
  }, [userData, scopeData]);

  const handleSelectedNodesChange = (
    scopeId: string,
    updatedSelectedNodes: string[],
    selectedValues?: string[]
  ) => {
    setSelectedNodesByScope((prevSelectedNodesByScope) => {
      let filteredNodesByScope = { ...prevSelectedNodesByScope };
      filteredNodesByScope[scopeId] = updatedSelectedNodes;
      return filteredNodesByScope;
    });
  };

  useEffect(() => {
    onSelectedNodesChangeCallback(selectedNodesByScope);
  }, [selectedNodesByScope]);

  const handleClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    if (selectedValues[index]) {
      const newAnchorEl = anchorEl.slice();
      newAnchorEl[index] = event.currentTarget;
      setAnchorEl(newAnchorEl);
      setOpen(true);
    }
  };

  const handleClose = (index: number) => {
    const newAnchorEl = anchorEl.slice();
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
    setOpen(false);
  };

  useEffect(() => {
    if (scopeList?.length > 0 && selectedIndex === null) {
      setSelectedIndex(0);
    }
  }, [scopeList, selectedIndex]);

  const handleSelect = (scope: any, index: number) => {
    let updatedSelected: string[] = [];
    setSelectedValues((prevSelected) => {
      updatedSelected = prevSelected.map((item, i) =>
        i === index ? scope.value : item
      );
      return updatedSelected;
    });
    setSelectedIndex(index);
    handleSelectedNodesChange(scope.value, [scope.value], updatedSelected);
  };

  const handleAddRow = () => {
    setSelectedValues([...selectedValues, '']);
    setSelectedIndex(selectedValues.length);
  };

  const handleDeleteRow = (indexToDelete: number) => {
    const updatedSelectedValues = [...selectedValues];

    updatedSelectedValues.splice(indexToDelete, 1);

    setSelectedValues(updatedSelectedValues);

    if (selectedIndex === indexToDelete) {
      setSelectedIndex(selectedIndex === 0 ? null : selectedIndex - 1);
    }

    const updatedSelectedNodesByScope = { ...selectedNodesByScope };
    const deletedScopeId = selectedValues[indexToDelete];
    delete updatedSelectedNodesByScope[deletedScopeId];

    setSelectedNodesByScope(updatedSelectedNodesByScope);
  };

  const getSelectedScopeData = (scopeId: string): OrganizationNode[] => {
    return (orgData?.children || []).filter(
      (node: OrganizationNode) => node.id === scopeId
    );
  };
  const renderChipData = (scopeId: string) => {
    const chipRawData =
      organizationNodeDataHandler?.transformOrganizationNodeIdToChipData(
        selectedNodesByScope[scopeId]
      );
    const data =
      organizationNodeDataHandler?.tranformChipRawDataToAutocomplete(
        chipRawData
      );

    if (!data) {
      return null;
    }

    return data.map((element, index) => (
      <CommonSimpleChip
        key={index}
        label={element.label}
        icon={
          element.type
            ? iconMap[element.type as OrganizationNodeType]
            : undefined
        }
      />
    ));
  };

  const getSelectedNodes = (scopeId: string) => {
    return selectedNodesByScope[scopeId];
  };

  const getValue = (id: string) => {
    if (!id) return { value: '', label: '' };

    const { groupedByAllowedScopes } = getGroupedByAllowedScopes();

    const keysArray = Object.keys(groupedByAllowedScopes);

    const selectedScopes = scopeList.filter((item) =>
      keysArray.includes(item.value)
    );

    if (selectedScopes.length) {
      return selectedScopes.find(
        (item: AutocompleteOption) => item.value === id
      );
    } else {
      return scopeAutocompleteList.find((item) => item.value === id);
    }
  };

  const canAddRow = useMemo(() => {
    const allOptionsSelected = scopeList.every((option) =>
      selectedValues.includes(option.value)
    );

    const atLeastOneNonEmptyValue = selectedValues.some(
      (value) => value.trim() !== ''
    );
    return !allOptionsSelected && atLeastOneNonEmptyValue;
  }, [selectedValues, scopeList]);

  const renderBody = () => {
    if (scopeDataLoading) return <CircularProgress size={20} />;

    return (
      <Grid container className="user-organization-selection">
        {selectedValues[0]
          ? selectedValues.map((selectedValue, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                columnGap={'22px'}
                alignItems={'flex-end'}
              >
                <Grid item xs={3.1} key={index}>
                  <Autocomplete
                    key={index}
                    onChangeCallBack={(model: string, item) => {
                      handleSelect(item, index);
                    }}
                    placeholder="scope"
                    optionList={scopeList}
                    single
                    classes={{
                      root: 'multi-select-field-1',
                      paper: 'multi-select-field-paper-1',
                    }}
                    model={'nodes-scope'}
                    areOptionsLoaded
                    values={getValue(selectedValue)}
                    initialSelectedValues={getValue(selectedValue)}
                  />
                </Grid>
                <Grid item xs={7} alignItems="center">
                  <Grid
                    className={`chip-group-container`}
                    container
                    display={'flex'}
                  >
                    <Grid item xs={10} display="flex">
                      {renderChipData(selectedValue)}
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        className="expand-button"
                        onClick={(event) => handleClick(event, index)}
                        ref={selectRefs[index]}
                      >
                        <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Popover
                  id={`simple-popover-${index}`} // Use a unique identifier for each popover
                  open={open && anchorEl[index] !== null} // Open the popover if it has an anchor element
                  anchorEl={anchorEl[index]}
                  onClose={() => handleClose(index)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  classes={{
                    root: 'user-org-tree-selectable-root',
                    paper: 'user-org-tree-selectable-paper',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <OrganizationSelectableTree
                    onSelectedNodesChange={handleSelectedNodesChange}
                    data={getSelectedScopeData(selectedValues[index])}
                    preSelectedNodes={getSelectedNodes(selectedValues[index])}
                  />
                </Popover>
                <Grid item xs={1}>
                  {index === 0 ? (
                    <Button
                      onClick={handleAddRow}
                      className="action-button"
                      disabled={!canAddRow}
                    >
                      <OpusSvgIcon type={SVG_ICON_TYPES.PLUS_ICON} />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => handleDeleteRow(index)}
                      className="action-button"
                      disabled={selectedValues.length === 1}
                    >
                      <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED} />
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))
          : selectedValues.map((selectedValue, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                columnGap={'22px'}
                alignItems={'flex-end'}
              >
                <Grid
                  container
                  item
                  xs={12}
                  key={index}
                  columnGap={'22px'}
                  alignItems={'flex-end'}
                >
                  <Grid item xs={3.1} key={index}>
                    <Autocomplete
                      key={index}
                      freeSolo={true}
                      onChangeCallBack={(model: string, item) => {
                        handleSelect(item, index);
                      }}
                      placeholder="scope"
                      optionList={scopeList}
                      single
                      classes={{
                        root: 'multi-select-field-1',
                        paper: 'multi-select-field-paper-1',
                      }}
                      model={'nodes-scope'}
                      areOptionsLoaded
                    />
                  </Grid>
                  <Grid item xs={7} alignItems="center">
                    <Grid
                      className={`chip-group-container`}
                      container
                      display={'flex'}
                    >
                      <Grid item xs={10} display="flex">
                        {renderChipData(selectedValue)}
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          className="expand-button"
                          onClick={(event) => handleClick(event, index)}
                          ref={selectRefs[index]}
                        >
                          <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Popover
                    id={`simple-popover-${index}`}
                    open={open && anchorEl[index] !== null}
                    anchorEl={anchorEl[index]}
                    onClose={() => handleClose(index)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    classes={{
                      root: 'user-org-tree-selectable-root',
                      paper: 'user-org-tree-selectable-paper',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <OrganizationSelectableTree
                      onSelectedNodesChange={handleSelectedNodesChange}
                      data={getSelectedScopeData(selectedValues[index])}
                      preSelectedNodes={getSelectedNodes(selectedValues[index])}
                    />
                  </Popover>
                  <Grid item xs={1}>
                    {index === 0 ? (
                      <Button
                        onClick={handleAddRow}
                        className="action-button"
                        disabled={!canAddRow}
                      >
                        <OpusSvgIcon type={SVG_ICON_TYPES.PLUS_ICON} />
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleDeleteRow(index)}
                        className="action-button"
                        disabled={selectedValues.length === 1}
                      >
                        <OpusSvgIcon
                          type={SVG_ICON_TYPES.TRASH_ICON_OUTLINED}
                        />
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}
      </Grid>
    );
  };

  return renderBody();
};

export default OrganizationSelectDropdown;

import { CircularProgress } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import CommonCarouselList from 'shared/components/CommonCarouselList';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { Navigation, SwiperOptions } from 'swiper';
import ActiveCampaignCarouselCard from '../ActiveCampaignCarouselCard';
import { CampaignModel } from 'shared/models/data/campaigns.model';

const activeCampaignCarouselConfiguration: SwiperOptions = {
  slidesPerView: 5.5,
  navigation: {
    prevEl: '.dashboard-previous-control-button',
    nextEl: '.dashboard-next-control-button',
    disabledClass: 'dashboard-control-button-hidden',
    hideOnClick: true,
  },
  modules: [Navigation],
  autoHeight: true,
  wrapperClass: 'dashboard-carousel-wrapper',
  spaceBetween: 16,
};

const getDashboardCarouselControlButtons = () => {
  return (
    <>
      <div className="dashboard-previous-control-button">
        <OpusSvgIcon
          type={SVG_ICON_TYPES.CARET_LEFT_ICON}
          className="dashboard-carousel-caret-icon"
        />
      </div>

      <div className="dashboard-next-control-button">
        <OpusSvgIcon
          type={SVG_ICON_TYPES.CARET_RIGHT_ICON}
          className="dashboard-carousel-caret-icon"
        />
      </div>
    </>
  );
};

interface ActiveCampaignCarouselListProps extends BaseComponentProps {
  isLoading: boolean;
  activeCampaigns: Array<CampaignModel>;
}

export const ActiveCampaignCarouselList: FunctionComponent<
  ActiveCampaignCarouselListProps
> = ({ activeCampaigns, isLoading }) => {
  const { t: translation } = useTranslation();

  return (
    <div className="active-campaign-carousel-list-container">
      {isLoading ? (
        <CircularProgress size={24} />
      ) : activeCampaigns?.length ? (
        <CommonCarouselList
          configuration={activeCampaignCarouselConfiguration}
          items={activeCampaigns}
          renderItem={(item) => <ActiveCampaignCarouselCard {...item} />}
          getAdditionalChildren={() => getDashboardCarouselControlButtons()}
        />
      ) : (
        <div className="no-data-container">{translation(`common.noData`)}</div>
      )}
    </div>
  );
};

import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DropdownProps } from '../CategoryDropdown/CategoryDropdown';
import { Popover } from '@mui/material';

interface DropdownWrapperProps extends BaseComponentProps, DropdownProps {
  id: string;
  className?: string;
}

export const DropdownWrapper: FunctionComponent<DropdownWrapperProps> = ({
  id,
  anchorEl,
  anchorOrigin,
  handleClose,
  open,
  className,
  children,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      className={className}
    >
      {children}
    </Popover>
  );
};

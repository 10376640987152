import { useEffect, useState } from 'react';
import { SystemLabel } from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';

export const useLabelSelection = (initialLabels: SystemLabel[] = []) => {
  const [selectedLabels, setSelectedLabels] =
    useState<SystemLabel[]>(initialLabels);

  useEffect(() => {
    setSelectedLabels(initialLabels);
  }, [initialLabels]);

  const selectLabel = (label: SystemLabel) => {
    const isLabelSelected = selectedLabels.find(
      (selectedLabel) => selectedLabel.label === label.label
    );

    if (isLabelSelected) {
      setSelectedLabels((prevSelected) =>
        prevSelected.filter((item) => item.label !== label.label)
      );
    } else {
      setSelectedLabels((prevSelected) => [...prevSelected, label]);
    }
  };

  const addAndSelectLabel = (label: SystemLabel) => {
    selectLabel(label);
  };

  const resetSelection = () => {
    setSelectedLabels([]);
  };

  return {
    selectedLabels,
    selectLabel,
    addAndSelectLabel,
    resetSelection,
    setSelectedLabels,
  };
};

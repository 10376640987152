import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import FindingContentMetrics from '../FindingContentMetrics';
import FindingContentScoreTags from '../FindingContentScoreTags';
import { VulnerabilityMetadataScores } from 'shared/models/data/vulnerability.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { FunctionComponent } from 'react';
import moment from 'moment';
import {
  findingIntelligenceDateMonthFormat,
  findingIntelligenceDateNumberFormat,
} from '../FindingContentIntelligence/FindingContentIntelligence';

interface FindingContentIntelligenceScoresProps
  extends VulnerabilityMetadataScores,
    BaseComponentProps {}

export const FindingContentIntelligenceScores: FunctionComponent<
  FindingContentIntelligenceScoresProps
> = ({
  riskIndex,
  lastModifiedDate,
  indicators: { cisaKev, isPrioritized, isTrending, lastTrendingDate },
  patches,
  predictive,
  threats,
  cvssv3,
  epss,
}) => {
  const { t: translation } = useTranslation();

  const renderIsTrendingIndicator = () => {
    if (!isTrending) return <></>;

    return (
      <>
        <div className="warning-tag">
          <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_TREND_UP} />
        </div>
        <p className="body-1 black-text">
          {moment(lastTrendingDate).format(findingIntelligenceDateNumberFormat)}
        </p>
      </>
    );
  };

  const renderCisaKev = () => {
    if (!cisaKev) return <></>;

    return (
      <>
        <div className="finding-content-dot"></div>
        <OpusSvgIcon type={SVG_ICON_TYPES.CISA_ICON} />
      </>
    );
  };

  const renderIsPrioritized = () => {
    if (!isPrioritized) return <></>;

    return (
      <>
        <div className="finding-content-dot"></div>
        <div className="indicators-container">
          <div className="primary-tag">
            <OpusSvgIcon type={SVG_ICON_TYPES.SHIELD_EXLAMATION_ICON} />
          </div>
          <p className="body-1 black-text">Prioritized Vulnerability</p>
        </div>
      </>
    );
  };

  const renderIndicators = () => {
    if (!isTrending && !isPrioritized && !cisaKev) return <></>;

    return (
      <div className="metadata-row">
        <div className="indicators-container">
          <p className="body-1 bold">
            <FormattedMessage
              defaultMessage="Indicators"
              id="findings.intelligence.indicators"
            />
          </p>
          {renderIsTrendingIndicator()}
        </div>
        {renderCisaKev()}
        {renderIsPrioritized()}
      </div>
    );
  };

  return (
    <div className="finding-content-item finding-intelligence-risks">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-section-risks"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.RIBBON_ICON} />}
        title={translation(`findings.sections.risks`)}
      >
        <div className="risk-metadata-container">
          <div className="metadata-row">
            <p className="body-1 bold">
              <FormattedMessage
                id="findings.intelligence.riskIndex"
                defaultMessage="Risk Index"
              />
            </p>
            <div className="warning-tag">
              <p className="header-7 ">
                {riskIndex === null ? 'N/A' : Number(riskIndex).toFixed(2)}
              </p>
            </div>
            <div className="finding-content-dot"></div>

            <p className="body-1 secondary-text">
              <FormattedMessage
                id="findings.intelligence.dateUpdated"
                defaultMessage="Date Updated"
              />
            </p>
            <p className="body-1 bold">
              {moment(lastModifiedDate).format(
                findingIntelligenceDateMonthFormat
              )}
            </p>
          </div>
          {renderIndicators()}
        </div>
        <FindingContentScoreTags
          patches={patches}
          threats={threats}
          predictive={predictive}
        />
        <Divider className="widget-divider" />
        <FindingContentMetrics cvssv3={cvssv3} epss={epss} />
      </ContentSection>
    </div>
  );
};

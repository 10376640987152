import { useLazyFetchConnectionByFiltersQuery } from 'WorkflowBuilder/store/api';
import ConnectionItem from 'Integrations/Connection/interfaces/item';
import { useEffect } from 'react';

export function useFetchDefaultConnectionForApplication(
  applicationId: string
): {
  isLoading: boolean;
  connection: ConnectionItem | undefined;
} {
  const [
    fetchConnectionByFilters,
    { data: connectionData, isLoading: connectionDataLoading },
  ] = useLazyFetchConnectionByFiltersQuery();

  useEffect(() => {
    fetchConnectionByFilters({
      applicationId,
      isFallback: true,
    });
  }, []);

  return {
    isLoading: connectionDataLoading,
    connection: connectionData?.length ? connectionData[0] : undefined,
  };
}

import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonCheckbox } from '../CommonCheckbox/CommonCheckbox';

interface InputLabelWrapperProps extends BaseComponentProps {
  label: string;
  errorMessage?: string;
  isOptional?: boolean;
  helperText?: string;
  showCheckbox?: boolean;
  renderBody?: (checked: boolean) => JSX.Element;
  onToggle?: (checked: boolean) => void;
  defaultChecked?: boolean;
  disabled?: boolean;
  classes?: {
    label?: string;
  };
  components?: {
    EndIcon?: ReactNode;
  };
  endIconTitle?: string;
}

export const InputLabelWrapper: FunctionComponent<InputLabelWrapperProps> = ({
  children,
  label,
  isOptional,
  helperText,
  showCheckbox = false,
  renderBody,
  defaultChecked = true,
  onToggle,
  disabled = false,
  classes = {},
  errorMessage,
  components = {},
  endIconTitle = '',
}) => {
  const [checked, setChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div className="input-label-wrapper">
      {label.length ? (
        <div className="input-label-text">
          {showCheckbox ? (
            <CommonCheckbox
              disabled={disabled}
              checked={checked}
              onClick={() => {
                setChecked((prevChecked) => {
                  onToggle && onToggle(!prevChecked);
                  return !prevChecked;
                });
              }}
            />
          ) : (
            <></>
          )}
          <div className={`input-label ${classes.label || ''}`}>{label}</div>
          {components.EndIcon ? (
            <div className="input-label-end-icon" title={endIconTitle}>
              {components.EndIcon}
            </div>
          ) : (
            <></>
          )}
          {isOptional ? (
            <div className="input-label-optional">{`(Optional)`}</div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}

      {children || renderBody ? (
        <div className="input-label-body">
          {renderBody ? renderBody(checked) : children}
        </div>
      ) : (
        <></>
      )}

      {helperText?.length ? (
        <div className="input-label-helper-text">{helperText}</div>
      ) : (
        <></>
      )}

      {errorMessage ? (
        <div className="form-input-error">{errorMessage}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

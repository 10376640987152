import React, { FC } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import moment from 'moment';
export interface ChipProps {
  className?: string;
  item: any;
  removeChip: (id: string | number) => void;
  editChip: (vl: any, item: any) => void;
  closeEnabled?: boolean;
  label?: string;
}
const Chip: FC<ChipProps> = ({
  className,
  item,
  removeChip,
  editChip,
  closeEnabled = true,
  label = 'Selected',
  ...props
}) => {
  return (
    <div className="chip-wrapper">
      <span className="chip-type label-1">{item.name}: </span>&nbsp;
      {(item.type === TypeFilter.MULTI_SELECT ||
        item.type === TypeFilter.SINGLE_SELECT) && (
        <>
          {item.items.length > 1 ? (
            <span className="chip-selected label-1">{`${item.items.length} ${label}`}</span>
          ) : item.items.length === 1 ? (
            <span className="chip-selected label-1">{item.items[0].name}</span>
          ) : null}
        </>
      )}
      {item.type === TypeFilter.DATE_PICKER_RANGE && (
        <>
          {item.dateRange.length === 2 && (
            <span className="chip-selected label-1">{`${
              moment(item.dateRange[0]).format('DD/MM/YYYY') +
              ' - ' +
              moment(item.dateRange[1]).format('DD/MM/YYYY')
            } `}</span>
          )}
        </>
      )}
      {item.type === TypeFilter.NUMBER && (
        <span className="chip-selected label-1">{`${item.numberInput}`}</span>
      )}
      <ArrowDropDownIcon
        className="chip-popover-icon"
        onClick={(e) => editChip(e, item)}
      />
      {closeEnabled && (
        <CloseIcon
          className="chip-close-icon"
          onClick={() => removeChip(item.id)}
        />
      )}
    </div>
  );
};

export default Chip;

import { RowClickedEvent } from 'ag-grid-community';
import Cloud2CodeDrawer from 'Cloud2Code/components/Cloud2CodeDrawer';
import FindingDetailsModal from 'FindingDetails/components/FindingDetailsModal';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParams } from 'shared/hooks/useQueryParams';

const FindingCodeEventDrawerComplex = () => {
  const [, , getUrlSearchParams] = useQueryParams();
  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const selectedFindingId = useMemo<string | null>(() => {
    return reactiveSearchParams.get('openFindingId');
  }, [reactiveSearchParams.get('openFindingId')]);

  const findingDetailsModelOpen = useMemo<boolean>(() => {
    return Boolean(reactiveSearchParams.get('openFindingId'));
  }, [reactiveSearchParams.get('openFindingId')]);

  const c2cDetailsModelOpen = useMemo<boolean>(() => {
    return Boolean(reactiveSearchParams.get('openCodeEventId'));
  }, [reactiveSearchParams.get('openCodeEventId')]);

  const handleFindingDetailsModalClose = () => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (updatedReactiveSearchParams.has('openFindingId'))
      updatedReactiveSearchParams.delete('openFindingId');

    if (updatedReactiveSearchParams.has('findingActiveTabId'))
      updatedReactiveSearchParams.delete('findingActiveTabId');

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const handleC2CDetailsModalClose = () => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (updatedReactiveSearchParams.has('openCodeEventId'))
      updatedReactiveSearchParams.delete('openCodeEventId');

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const onFindingClick = (event: RowClickedEvent<any, any>) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    if (updatedReactiveSearchParams.has('openCodeEventId'))
      updatedReactiveSearchParams.delete('openCodeEventId');

    updatedReactiveSearchParams.set('openFindingId', event.data.findingId);

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  return (
    <>
      <FindingDetailsModal
        open={findingDetailsModelOpen}
        handleClose={handleFindingDetailsModalClose}
        handleCloud2CodeModalOpen={() => {}}
        findingId={selectedFindingId as string}
        setFindingId={(id: string) => {}}
      />
      <Cloud2CodeDrawer
        open={c2cDetailsModelOpen}
        handleClose={handleC2CDetailsModalClose}
        onFindingClick={onFindingClick}
      />
    </>
  );
};

export default FindingCodeEventDrawerComplex;

import { Search } from '@mui/icons-material';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { AutomationManagementTableHeaderProps } from 'shared/models/props/automation-management-props.model';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import {
  AutomationManagementRuleGroupSearchParams,
  selectRuleGroupsSearchParams,
  setRuleGroupsSearchParams,
} from 'AutomationManagement/store';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { SearchOperator } from 'shared/models/data/common-search.model';
import FormattedMessage from '../../../../../shared/components/FormattedMessage';

export const AutomationManagementTableHeader: FunctionComponent<
  AutomationManagementTableHeaderProps
> = ({ searchRuleGroups }) => {
  const dispatch = useCommonDispatch();

  const ruleGroupsSearchParams =
    useCommonSelector<AutomationManagementRuleGroupSearchParams>(
      selectRuleGroupsSearchParams
    );

  const [showOnlyRuleDefined, setShowOnlyRuleDefined] = useState(false);

  const onClickShowOnlyRuleDefined = () => {
    const cur_val = showOnlyRuleDefined;
    setShowOnlyRuleDefined(!cur_val);

    const updatedRuleGroupsSearchParams: AutomationManagementRuleGroupSearchParams =
      {
        ...ruleGroupsSearchParams,
        showOnlyRuleDefined: !cur_val,
        pagination: {
          ...ruleGroupsSearchParams?.pagination,
          skip: 0,
        },
      };
    dispatch(setRuleGroupsSearchParams(updatedRuleGroupsSearchParams));

    searchRuleGroups && searchRuleGroups(updatedRuleGroupsSearchParams);
  };

  const { t: translation } = useTranslation();

  const onSearchAutomationRemediationGroup = (filterKeyword: string): void => {
    const updatedRuleGroupsSearchParams: AutomationManagementRuleGroupSearchParams =
      {
        ...ruleGroupsSearchParams,
        showOnlyRuleDefined: showOnlyRuleDefined,
        pagination: {
          ...ruleGroupsSearchParams?.pagination,
          skip: 0,
        },
        filters: [
          {
            name: 'title',
            operator: SearchOperator.LIKE,
            values: [filterKeyword],
          },
        ],
      };

    dispatch(setRuleGroupsSearchParams(updatedRuleGroupsSearchParams));

    searchRuleGroups && searchRuleGroups(updatedRuleGroupsSearchParams);
  };

  const debouncedOnSearchAutomationRemediationGroup = debounce(
    onSearchAutomationRemediationGroup,
    200
  );

  return (
    <div className="automation-management-table-header">
      <div className="automation-management-table-header-select"></div>
      <Button
        style={{ marginRight: 'auto' }}
        className={`base-opus-button main-filters-button ${
          showOnlyRuleDefined && 'selected'
        }`}
        onClick={() => onClickShowOnlyRuleDefined()}
      >
        <Typography variant="body1">
          <FormattedMessage
            defaultMessage="Show active guardrails"
            id={`automationManagement.filters.onlyRuleDefined`}
            capitalize
          ></FormattedMessage>
        </Typography>
      </Button>
      <div className="automation-management-table-header-button-group">
        <TextField
          placeholder={translation(`common.search`)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className="automation-management-table-header-button-group-search-input-icon" />
              </InputAdornment>
            ),
          }}
          className="automation-management-table-header-button-group-search-input"
          onChange={(
            event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            debouncedOnSearchAutomationRemediationGroup(event.target?.value);
          }}
        />
      </div>
    </div>
  );
};

import React from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowTextDisplay,
  OverflowDirection,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { Grid } from '@mui/material';
import moment from 'moment';
import { VulnerabilityMetadataDiscussion } from 'shared/models/data/vulnerability.model';

interface FindingContentIntelligenceDiscussionsItemProps
  extends BaseComponentProps,
    VulnerabilityMetadataDiscussion {}

export const FindingContentIntelligenceDiscussionsItem: React.FC<
  FindingContentIntelligenceDiscussionsItemProps
> = ({ content, publishedDate, source, type }) => {
  const { t: translation } = useTranslation();

  return (
    <div className="finding-discussions-item-container">
      <div className="comment-indicator"></div>
      <div className="discussions-content">
        <div className="title">
          <p className="label-4 month">
            {moment(publishedDate).format('MMM-DD')}
          </p>
          <p className="label-4 hours">
            {moment(publishedDate).format('HH:mm:ss')}
          </p>
        </div>
        <div className="discussions-details">
          <div className="row">
            <p className="opus-body-text-1">
              {translation(`findings.details.type`)}
            </p>
            <TextOverflow
              type={OverflowTextDisplay.ELLIPSIS}
              direction={OverflowDirection.WIDTH}
            >
              <p className="body-2">{type}</p>
            </TextOverflow>
          </div>
          <div className="row">
            <p className="opus-body-text-1">Website Name</p>
            <div className="tag-list">
              <InteractiveLabel
                label={source?.label}
                link={source?.url}
                linkIcon
              />
            </div>
          </div>
          <div className="row">
            <p className="opus-body-text-1">Content</p>
            <p className="body-2" title={content}>
              {content}
            </p>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
export default FindingContentIntelligenceDiscussionsItem;

export const settingFiltersLabelMapper = [
  { id: 'risk', label: 'Risk' },
  { id: 'title', label: 'Risk Name' },
  { id: 'findingType', label: 'Domain' },
  { id: 'category', label: 'Risk Category' },
  { id: 'riskScore', label: 'Risk Score' },
  { id: 'severity', label: 'Severity' },
  { id: 'findingSource', label: 'Event Source' },
  { id: 'organization', label: 'Organization' },
  { id: 'scopeId', label: 'Scope' },
  { id: 'groupId', label: 'Group' },
  { id: 'businessUnitId', label: 'Service' },
  { id: 'workspace', label: 'Workspace' },
  { id: 'resource', label: 'Resource' },
  { id: 'resourceType', label: 'Resource Type' },
  { id: 'operatingSystem', label: 'Operating System' },
  { id: 'ownerUserId', label: 'Resource Owner' },
  { id: 'isExternalFacing', label: 'External Facing Resource' },
  { id: 'hasSensitiveData', label: 'Resource with Sensitive Data' },
  { id: 'intelligence', label: 'Intelligence' },
  { id: 'isDiscussed', label: 'Is Discussed' },
  { id: 'hasPOC', label: 'POC' },
  { id: 'hasExploit', label: 'Has Exploit' },
  { id: 'isExploitedInTheWild', label: 'In the Wild' },
  { id: 'hasPenTesterFramework', label: 'Pentest Framework' },
  { id: 'cisaKev', label: 'Cisa Kev' },
  { id: 'hasRansomware', label: 'Ransomware' },
  { id: 'hasThreatActor', label: 'Threat Actors' },
  { id: 'isTrending', label: 'Trending' },
  { id: 'hasMalware', label: 'Malware' },
  { id: 'hasPatch', label: 'Has Patch' },
  { id: 'isPrioritized', label: 'Prioritized' },
  { id: 'intelligenceScore', label: 'Intelligence Score' },
  { id: 'epssScore', label: 'EPSS Score' },
  { id: 'epssPercentile', label: 'EPSS Percentile' },
  { id: 'attackComplexity', label: 'Attack Complexity' },
  { id: 'operational', label: 'Operational' },
  { id: 'status', label: 'Status' },
  { id: 'externalCreatedAt', label: 'First Seen' },
  { id: 'itemRelationMetadata', label: 'Ticket' },
  { id: 'reopened', label: 'Reopened' },
  { id: 'assigneeUserId', label: 'Assignee' },
  { id: 'slaStatus', label: 'SLA Status' },
  { id: 'state', label: 'State' },
  { id: 'duplicationState', label: 'Duplication State' },
  { id: 'scope', label: 'Scope' },
  { id: 'group', label: 'Group' },
  { id: 'cloudWorkspaceId', label: 'Workspace' },
  { id: 'environmentType', label: 'Environment' },
];

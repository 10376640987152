import { ItemType } from 'FindingDetails/components/FindingContent/components/AddOwnerModal/AddOwnerModal';

export interface CreateResourceOwner {
  email: string;
  creationReason: string;
  createdBy: ResourceOwnerCreator;
  setAsDefault?: boolean;
  itemType: ItemType;
}
export enum ResourceOwnerCreator {
  SYSTEM = 'system',
  USER = 'user',
  WORKFLOW = 'workflow',
}

export type ResourceOwnerCreateFormErrors = Record<
  keyof CreateResourceOwner,
  string | null
>;

import { AutocompleteOption } from 'FindingDetails/store/api';
import { useEffect, useMemo } from 'react';
import {
  ApiStaticOptionSourceProperties,
  KeyValueSource,
  OptionSourceType,
  StaticOptionSourceProperties,
} from 'shared/models/data/data-filter.model';
import { useFetchDataBasedOnParametersMutation } from 'shared/store/api';

export function useKeyValueOptions(
  source: KeyValueSource,
  skip?: boolean
): [Array<AutocompleteOption>, boolean] {
  const [fetchOptions, { data: optionsData, isLoading: optionsDataLoading }] =
    useFetchDataBasedOnParametersMutation();

  const options = useMemo<Array<AutocompleteOption>>(() => {
    if (source.type === OptionSourceType.STATIC) {
      const staticSource = source.payload as StaticOptionSourceProperties;

      return staticSource.options;
    }

    if (optionsData) {
      const apiStaticSource = source.payload as ApiStaticOptionSourceProperties;

      return apiStaticSource.optionsTransformer
        ? apiStaticSource.optionsTransformer(optionsData)
        : optionsData;
    }
    return [];
  }, [optionsData, source.type]);

  const isLoading = useMemo<boolean>(() => {
    if (source.type === OptionSourceType.STATIC) {
      return false;
    }

    return optionsDataLoading;
  }, [optionsDataLoading, source.type]);

  useEffect(() => {
    if (!skip && source.type === OptionSourceType.API_STATIC) {
      const apiStaticSource = source.payload as ApiStaticOptionSourceProperties;

      fetchOptions({
        url: apiStaticSource.url,
        method: apiStaticSource.method || 'POST',
        data: apiStaticSource.body,
      });
    }
  }, [source, skip]);

  return [options, isLoading];
}

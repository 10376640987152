import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import TimelineComponent from 'Common/components/TimelineComponent/TimelineComponent';
import IntelligenceThreatItem from './IntelligenceThreatItem';
import { FunctionComponent } from 'react';
import { VulnerabilityMetadataThreat } from 'shared/models/data/vulnerability.model';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';

interface FindingContentIntelligenceThreatsProps extends BaseComponentProps {
  threats: Array<VulnerabilityMetadataThreat>;
}

export const FindingContentIntelligenceThreats: FunctionComponent<
  FindingContentIntelligenceThreatsProps
> = ({ threats }) => {
  const { t: translation } = useTranslation();

  const renderBody = () => {
    if (threats.length === 0)
      return <NoDataToDisplayCard displayDescription={false} />;

    return (
      <TimelineComponent
        data={threats}
        // isLoading={isLoading}
        noEventsMessageId="findings.details.noEvents"
        noEventsDefaultMessage="No events"
        renderEvent={renderEvent}
        isLoading={false}
      />
    );
  };

  const renderEvent = (event: VulnerabilityMetadataThreat, index: number) => (
    <IntelligenceThreatItem key={index} {...event} />
  );

  return (
    <div className=" finding-content-item finding-threats-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-section-threats"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.BOMB_ICON} />}
        title={translation(`findings.sections.threats`)}
      >
        {renderBody()}
      </ContentSection>
    </div>
  );
};

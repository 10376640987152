import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import {
  OrganizationNewNodeMetadata,
  OrganizationNewNode,
  OrganizationUpdateNode,
} from 'Organization/interfaces/OrganizationNewNode.interface';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import {
  useAddOrganizationNodeMutation,
  useGetOrgApplicationsQuery,
  useUpdateOrganizationNodeMutation,
} from 'Organization/store/api';
import { CommonButton } from 'shared/components/CommonButton';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import FormInput from 'shared/components/FormInput';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { CommunicationApp } from 'Settings/components/TeamsList/enums/communication-app.enum';
import { useTranslation } from 'react-i18next';
import OpusImageIcon from 'shared/components/IconComponents/OpusImageIcon';
import { OrganizationConnectionItem } from 'Organization/interfaces/OrganizationConnectionItem.interface';
import { OrganizationModalMode } from 'Organization/interfaces/OrganizationModalMode.enum';
import { PersonPicker } from 'shared/components/DropdownPicker';
import { AvailableUser } from 'FindingDetails/interfaces/Users';

interface OrganizationNodeFormProps {
  type: OrganizationNodeType;
  nodeData?: OrganizationNode;
  onClose: () => void;
  mode: OrganizationModalMode;
}

export const OrganizationNodeForm: FunctionComponent<
  OrganizationNodeFormProps
> = ({ type, nodeData, onClose, mode }) => {
  const { t: translation } = useTranslation();
  const formElement = useRef<HTMLFormElement>(null);
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});
  const [selectedUser, setSelectedUser] = useState<string>();

  const { data: connectionList } = useGetOrgApplicationsQuery();
  const [
    addNode,
    { isSuccess: successfullyAddedNode, isLoading: isAddingNode },
  ] = useAddOrganizationNodeMutation();

  const defaultFormValues = useMemo(() => {
    if (mode === OrganizationModalMode.EDIT) {
      return {
        name: nodeData?.name,
        ownerId: nodeData?.metadata?.ownerId,
        communicationTargetId: nodeData?.metadata?.communicationTargetId,
        communicationAppId: nodeData?.metadata?.communicationAppId,
      } as OrganizationNewNodeMetadata;
    } else {
      return {
        name: '',
        ownerId: '',
        communicationTargetId: '',
        communicationAppId: '',
      };
    }
  }, [mode]);

  const [
    updateNode,
    { isSuccess: successfullyUpdatedNode, isLoading: isUpdating },
  ] = useUpdateOrganizationNodeMutation();

  const handleSubmit = () => {
    const form = formElement.current;
    if (!form) return;
    const formData = new FormData(form);
    const formValues: OrganizationNewNodeMetadata =
      Object.fromEntries(formData);
    const payload: OrganizationNewNode = {
      name: formValues.name || '',
      isDefault: !!nodeData?.isDefault,
      parentId: '',
      metadata: {
        ownerId: selectedUser,
        communicationAppId: formValues.communicationAppId,
        communicationTargetId: formValues.communicationTargetId,
      },
    };
    const errors = validateFormFields({ name: payload.name });
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    } else {
      setFormErrors({});
      if (mode === OrganizationModalMode.CREATE) {
        payload.parentId = nodeData?.id || '';
        payload.type =
          nodeData?.type === OrganizationNodeType.ROOT
            ? OrganizationNodeType.SCOPE
            : OrganizationNodeType.LOGICAL;
        addNode(payload);
      } else {
        payload.parentId = nodeData?.parentId || '';
        updateNode({
          nodeId: nodeData?.id,
          body: payload,
        } as OrganizationUpdateNode);
      }
    }
  };

  useEffect(() => {
    if (successfullyAddedNode || successfullyUpdatedNode) onClose();
  }, [successfullyAddedNode, successfullyUpdatedNode]);

  function validateFormFields(item: OrganizationNewNodeMetadata) {
    const errors: { [key: string]: string } = {};
    if (!item.name) {
      errors['name'] = 'Name is required';
    }

    return errors;
  }
  const handleSelectUser = (user: AvailableUser) => {
    setSelectedUser(user.id);
  };
  return (
    <Box>
      <form ref={formElement} onSubmit={handleSubmit}>
        <Grid container rowGap={'39px'} columnGap={2} alignItems="flex-end">
          <Grid item xs={12}>
            <FormInput
              label="Name"
              name="name"
              value={defaultFormValues?.name}
              id="organization-name-field"
            />
            {formErrors['name'] && (
              <Typography variant="caption" color="error">
                {formErrors['name']}
              </Typography>
            )}
          </Grid>
          <Grid item xs={5.7}>
            <InputLabelWrapper label="Communication Channel">
              <Select
                fullWidth
                className="input-dialog select-input"
                name="communicationAppId"
                defaultValue={defaultFormValues?.communicationAppId}
                inputProps={{
                  id: 'organization-communication-channel-field',
                }}
              >
                {connectionList?.map(
                  (connection: OrganizationConnectionItem) => [
                    <MenuItem key={connection.id} value={connection.id}>
                      <Grid display={'flex'} alignItems="center">
                        <OpusImageIcon
                          src={connection.logo}
                          className="organization-form-image-container"
                        />
                        <Typography variant="inherit" className="body-1" ml={2}>
                          {
                            CommunicationApp[
                              connection.id as keyof typeof CommunicationApp
                            ]
                          }
                        </Typography>
                      </Grid>
                    </MenuItem>,
                  ]
                )}
              </Select>
            </InputLabelWrapper>
          </Grid>
          <Grid item xs={5.7}>
            <FormInput
              label=""
              placeholder="Channel ID"
              name="communicationTargetId"
              value={defaultFormValues?.communicationTargetId}
              id="organization-channel-id-field"
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabelWrapper label="Owner">
              <PersonPicker
                selectedUserId={defaultFormValues?.ownerId}
                property="ownerId"
                onSelect={handleSelectUser}
                displayFullWidthPopover
                inputId="organization-owner-field"
              />
            </InputLabelWrapper>
          </Grid>
        </Grid>
      </form>

      <Box className="organization-modal-button-row" height={'47vh'}>
        <div className="organization-modal-cancel-buttons"></div>
        <Box
          className="organization-modal-connection-buttons"
          display={'flex'}
          alignItems="flex-end"
        >
          <CommonButton
            type={CommonButtonType.SECONDARY}
            onClick={() => onClose()}
          >
            Cancel
          </CommonButton>
          <CommonButton type={CommonButtonType.PRIMARY} onClick={handleSubmit}>
            {isAddingNode || isUpdating ? 'Saving' : 'Save'}
          </CommonButton>
        </Box>
      </Box>
    </Box>
  );
};

import { CircularProgress, TextField } from '@mui/material';
import { useGetIntegrationReposMutation } from 'Integrations/Connection/store/api';
import { GridReadyEvent } from 'ag-grid-community';
import classNames from 'classnames';
import {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CommonSimpleDataGrid } from 'shared/components/CommonSimpleDataGrid/CommonSimpleDataGrid';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { integrationRepositoryTableColumns } from 'shared/fixtures/data/integrations.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { IntegrationRepo } from 'shared/models/data/integrations.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface IntegrationRepositoryTableProps extends BaseComponentProps {
  integrationId: string;
  selectionIds: Array<string>;
  selectedAll: boolean;
  onSelect: (repositoryIds: Array<string>, selectAll?: boolean) => void;
}

export const IntegrationRepositoryTable: FunctionComponent<
  IntegrationRepositoryTableProps
> = ({ integrationId, onSelect, selectionIds, selectedAll }) => {
  const { t: translation } = useTranslation();

  const [isPending, startTransition] = useTransition();

  const gridRef = useRef();

  const [filteredRepos, setFilteredRepos] = useState<Array<IntegrationRepo>>(
    []
  );

  const [searchValue, setSearchValue] = useState<string>('');

  const [getRepos, { isLoading: reposLoading, data: repoReponse }] =
    useGetIntegrationReposMutation();

  useEffect(() => {
    if (repoReponse) {
      setFilteredRepos(
        repoReponse.repos?.filter((repo: IntegrationRepo) =>
          repo.displayName.includes(searchValue)
        )
      );
    }
  }, [repoReponse]);

  useEffect(() => {
    if (repoReponse) {
      const lowerCaseSearchValue = searchValue.toLowerCase();

      setFilteredRepos(
        repoReponse.repos?.filter((repo: IntegrationRepo) => {
          return (
            repo.displayName?.toLowerCase().includes(lowerCaseSearchValue) ||
            repo.id?.toLowerCase().includes(lowerCaseSearchValue)
          );
        })
      );
    }
  }, [searchValue]);

  useEffect(() => {
    if (integrationId) getRepos(integrationId);
  }, [integrationId]);

  const selectionHandler = (selections: Array<string>) => {
    if (selections.length === repoReponse?.repos?.length) {
      onSelect(selections, true);
    } else {
      onSelect(selections, false);
    }
  };

  const searchHandler = (searchInput: string) => {
    startTransition(() => {
      setSearchValue(searchInput);
    });
  };

  const renderSelectedText = () => {
    const selectedIdsCount = repoReponse ? selectionIds.length : 0;

    const totalIdsCount = repoReponse ? repoReponse?.repos?.length : 0;

    return `${selectedIdsCount}/${totalIdsCount}`;
  };

  return (
    <div className="integration-repository-table-container">
      <div className="integration-repository-table-title">
        <div className="integration-repository-table-title-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.DATABASE_ICON} />
        </div>
        <div className="integration-repository-table-title-text">
          Select Repositories
        </div>
      </div>

      <div className="integration-repository-table-description">
        Build a resilient risk management program leveraging data-driven and
        risk-driven approach by adding contextual information to each security
        finding and help .
      </div>

      <div className="integration-repository-table-header">
        <div className="integration-repository-table-search-input">
          <TextField
            className={classNames(['filter-input filter-main-input'])}
            placeholder={translation(`common.searchPlaceholder`)}
            onChange={(e) => {
              searchHandler(e.target.value);
            }}
            InputProps={{
              className: 'search-filter-input',
              startAdornment: (
                <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
              ),
            }}
            value={searchValue}
          />
        </div>
        <div className="integration-repository-table-selection-count">
          {renderSelectedText()} Selected
        </div>
      </div>

      <div className="integration-repository-table-body">
        <CommonSimpleDataGrid
          gridRef={gridRef}
          onGridReady={(params: GridReadyEvent) => {
            if (selectedAll) {
              params.api.selectAll();
            }
          }}
          rowData={filteredRepos}
          columnDefs={integrationRepositoryTableColumns}
          onSelect={selectionHandler}
          selectionModel={selectionIds}
          isLoading={reposLoading}
          loadingOverlayComponent={() => <CircularProgress />}
          otherComponents={{
            NoDataBackdropComponent: <div>No Data...</div>,
          }}
          getRowId={(row) => row.data.id}
          rowSelection="multiple"
          defaultColDef={{
            sortable: true,
          }}
          suppressRowClickSelection
          keepCurrentSelections
        />
      </div>
    </div>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { isEqual, omit } from 'lodash';
import { Button, CircularProgress } from '@mui/material';
import { findingSeverityListOptions } from 'FindingDetails/interfaces/severity';
import {
  useCreateSlaConfigRuleMutation,
  useGetSlaConfigRuleByIdMutation,
  useUpdateSlaConfigRuleByIdMutation,
} from 'Settings/store/api';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CommonModalContainer } from 'shared/components/CommonModalContainer';
import ContentSection from 'shared/components/ContentSection';
import FilterChipGroup from 'shared/components/DataFilters/FilterChipGroup';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InputLabelWrapper from 'shared/components/InputLabelWrapper';
import { MultiInputConfigurationField } from 'shared/components/MultiInputConfigurationField';
import {
  SlaRuleAdditionalFormData,
  SlaRuleFormData,
  slaRuleConditionOptions,
  slaRuleSchema,
  requiredDefaultFieldOptions,
  SlaRuleBaseFormData,
} from 'shared/fixtures/data/sla-rules.data';
import { SlaRuleDataHandler } from 'shared/handlers/sla-rule-data.handler';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  CategoryState,
  MultiSelectState,
} from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import FormattedMessage from 'shared/components/FormattedMessage';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { SlaStartAtOptions, SlaType } from 'Settings/interfaces/Sla';
import Autocomplete from 'Common/components/Autocomplete';

interface SlaRulesFormPanelProps extends BaseComponentProps {
  onCancel: () => void;
  detectFormChanged?: (param: boolean) => void;
  cancelationModalProps?: {
    open: boolean;
    onClose: () => void;
    onOpen: () => void;
  };
}

const slaRuleDataHandler = new SlaRuleDataHandler();

export const SlaRulesFormPanel: FunctionComponent<SlaRulesFormPanelProps> = ({
  onCancel,
  cancelationModalProps,
  detectFormChanged,
}) => {
  const [searchParams, setSearchParams] = useQueryParams();

  const [getSlaRuleById, { data: slaData, isLoading: slaDataLoading }] =
    useGetSlaConfigRuleByIdMutation();

  const [formCancelationModalOpen, setFormCancelationModalOpen] =
    useState<boolean>(false);

  const [createSlaRule, { isLoading: createSlaLoading }] =
    useCreateSlaConfigRuleMutation();
  const [editSlaRule, { isLoading: updateSlaLoading }] =
    useUpdateSlaConfigRuleByIdMutation();

  const defaultFormValues = useMemo<SlaRuleFormData | undefined>(() => {
    if (slaData)
      return slaRuleDataHandler.transformSlaRuleDataToFormData(slaData);

    return undefined;
  }, [slaData]);

  const {
    register,
    formState: { errors },
    setValue: setFormValue,
    setError,
    getValues,
    watch,
    clearErrors,
  } = useForm({
    resolver: yupResolver(slaRuleSchema),
  });

  useEffect(() => {
    if (searchParams.slaRuleId) {
      getSlaRuleById(searchParams.slaRuleId || '');
    }
  }, [searchParams]);

  const [additionalFormData, setAdditionalFormData] =
    useState<SlaRuleAdditionalFormData>({
      properties: {},
      values: [],
    });

  useEffect(() => {
    if (defaultFormValues) {
      setFormValue('name', defaultFormValues.name);
      setFormValue('description', defaultFormValues.description);
      setFormValue('settings', defaultFormValues.settings);

      setAdditionalFormData({
        properties: defaultFormValues.properties,
        values: defaultFormValues.values,
      });
    } else {
      setFormValue('settings', SlaType.FINDING_EXTERNAL_CREATE);
    }
  }, [defaultFormValues]);

  const validateFormBeforeSubmit = () => {
    const formValues = getValues();

    let isValid = true;

    const arePropertiesChecked =
      Object.keys(additionalFormData.properties).length > 0;

    if (!arePropertiesChecked && !slaData?.isDefault) {
      setError('properties', { message: 'Properties are required' });
      isValid = false;
    } else {
      clearErrors('properties');
    }

    const areValuesChecked =
      additionalFormData.values.length > 0 &&
      additionalFormData.values[0]?.values.length > 1;

    if (!areValuesChecked) {
      setError('values', { message: 'At least one severity is required' });
      isValid = false;
    } else {
      clearErrors('values');
    }

    for (const requiredField of ['name']) {
      if (formValues[requiredField]?.length === 0) {
        setError(requiredField as any, {
          message: `${requiredField} is required`,
        });
        isValid = false;
      } else {
        clearErrors(requiredField as any);
      }
    }

    return isValid;
  };

  const hasFormChanged = () => {
    const currentValues = watch();

    const cleanDefaultFormValues = omit(defaultFormValues, [
      'values[0].values[0].disabled',
      'values[0].values[1].disabled',
    ]);
    const cleanMergedValues = omit(
      { ...currentValues, ...additionalFormData },
      ['values[0].values[0].disabled', 'values[0].values[1].disabled']
    );

    if (slaData) {
      return !isEqual(cleanMergedValues, cleanDefaultFormValues);
    } else {
      const areAdditionalFormDataKeysEmpty =
        !currentValues.name &&
        !currentValues.description &&
        Object.keys(additionalFormData.properties).length === 0 &&
        additionalFormData.values.length === 0 &&
        currentValues.settings === SlaType.FINDING_EXTERNAL_CREATE;

      return !areAdditionalFormDataKeysEmpty;
    }
  };

  useEffect(() => {
    const formChanged = hasFormChanged();
    detectFormChanged && detectFormChanged(formChanged);
  }, [watch(), defaultFormValues, additionalFormData]);

  return slaDataLoading ? (
    <div className="sla-rule-view-panel-loading">
      <CircularProgress size={35} />
    </div>
  ) : (
    <div className="sla-rule-view-panel">
      <CommonModalContainer
        handleClose={() => {
          cancelationModalProps?.onClose && cancelationModalProps?.onClose();
        }}
        isOpen={hasFormChanged() ? Boolean(cancelationModalProps?.open) : false}
        title={`Cancel Sla ${searchParams.slaRuleId ? 'Update' : 'Creation'}`}
      >
        <div className="business-unit-form-modal-body">
          <div className="business-unit-form-modal-description">
            <span>Are you sure you want to cancel this action?</span>
            <span>All data will be lost</span>
          </div>
          <div className="business-unit-form-modal-buttons">
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
              }}
            >
              Stay
            </Button>
            <Button
              className="base-opus-text-button"
              onClick={() => {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
                onCancel();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </CommonModalContainer>
      <div className="sla-rule-view-panel-header">
        <div className="sla-rule-view-panel-header-icon">
          <OpusSvgIcon type={SVG_ICON_TYPES.LIST_CHECK_ICON} />
        </div>
        <div className="sla-rule-view-panel-header-text">
          <div className="sla-rule-view-panel-header-title">
            SLA {searchParams.slaRuleId ? 'Edit' : 'Creation'}
          </div>
        </div>
      </div>

      <div className="sla-rule-view-panel-body">
        <form className="sla-rule-view-panel-form">
          <ContentSection
            title=""
            rootClassName="sla-rule-content-section sla-rule-base-content-section"
          >
            <InputLabelWrapper label="Name">
              <input
                type="text"
                className={`text-field-1 ${
                  errors.name ? 'input-validation-error-1' : ''
                }`}
                {...register('name')}
              ></input>
            </InputLabelWrapper>
            <InputLabelWrapper label="Description">
              <input
                type="text"
                className={`text-field-1`}
                {...register('description')}
              ></input>
            </InputLabelWrapper>
            {!slaData?.isDefault && (
              <InputLabelWrapper label="Match Filter">
                <div
                  className={`select-chip-group-container sla-rule-filter-chip-group ${
                    errors.properties ? 'input-validation-error-1' : ''
                  }`}
                >
                  <FilterChipGroup
                    separator="AND"
                    categories={slaRuleConditionOptions}
                    categoryState={additionalFormData.properties}
                    onChange={(id: string, state: CategoryState) => {
                      setAdditionalFormData((prevAdditionalFormData) => {
                        const updatedProperties = {
                          ...prevAdditionalFormData.properties,
                        };

                        const typedState = state as MultiSelectState;

                        if (
                          typedState.selectedOptions &&
                          typedState.selectedOptions?.length === 0
                        ) {
                          if (updatedProperties[id])
                            delete updatedProperties[id];
                        } else {
                          updatedProperties[id] = state;
                        }

                        return {
                          ...prevAdditionalFormData,
                          properties: updatedProperties,
                        };
                      });
                    }}
                    Components={{
                      AddButtonContent: (
                        <>
                          <span>Add</span>
                          <OpusSvgIcon
                            type={SVG_ICON_TYPES.SORT_DESCENDING_ICON}
                          />
                        </>
                      ),
                    }}
                    addButtonAlignment="right"
                    onRemoveChip={(categoryId: string) => {
                      setAdditionalFormData((prevAdditionalFormData) => {
                        const updatedProperties = {
                          ...prevAdditionalFormData.properties,
                        };

                        if (updatedProperties[categoryId])
                          delete updatedProperties[categoryId];

                        return {
                          ...prevAdditionalFormData,
                          properties: updatedProperties,
                        };
                      });
                    }}
                  />
                </div>
              </InputLabelWrapper>
            )}
          </ContentSection>
          <div className="sla-rule-form-panel-form-divider"></div>

          <ContentSection
            title="Configuration"
            icon={<OpusSvgIcon type={SVG_ICON_TYPES.GEAR_ICON} />}
          >
            <div className="additional-data-section">
              <InputLabelWrapper label={'SLA start at'}>
                <Autocomplete
                  model={'settings'}
                  optionList={SlaStartAtOptions}
                  {...register('settings')}
                  areOptionsLoaded
                  onChangeCallBack={(
                    model: string,
                    option: AutocompleteOption | Array<AutocompleteOption>
                  ) => {
                    const singleOption = option as AutocompleteOption;
                    setFormValue('settings', singleOption.value);
                  }}
                  classes={{
                    root: 'multi-select-field-1',
                    paper: 'multi-select-field-paper-1',
                  }}
                  single
                  initialSelectedValues={
                    SlaStartAtOptions.find(
                      (opt: AutocompleteOption) =>
                        opt.value === defaultFormValues?.settings
                    ) || SlaStartAtOptions[0]
                  }
                />
              </InputLabelWrapper>
              <MultiInputConfigurationField
                defaultDisabledOptions={
                  slaData?.isDefault
                    ? (requiredDefaultFieldOptions as unknown as string[]).map(
                        String
                      )
                    : []
                }
                fieldLabels={['Severity', 'SLA Hours']}
                fieldOptions={[findingSeverityListOptions, []]}
                values={defaultFormValues?.values}
                onChange={(configurationValues) => {
                  setAdditionalFormData((prevAdditionalFormData) => ({
                    ...prevAdditionalFormData,
                    values: configurationValues,
                  }));
                }}
                classes={{
                  inputRoot: `multi-select-field-input-root ${
                    errors.values ? 'input-validation-error-1' : ''
                  }`,
                }}
                isInputDisabled={slaData?.isDefault}
              />
            </div>
          </ContentSection>
        </form>
      </div>

      <div className="sla-rule-view-panel-footer">
        <div></div>
        <div className="sla-rule-view-panel-footer-buttons">
          <Button
            className="opus-secondary-button"
            onClick={() => {
              if (hasFormChanged()) {
                cancelationModalProps?.onOpen &&
                  cancelationModalProps?.onOpen();
              } else {
                cancelationModalProps?.onClose &&
                  cancelationModalProps?.onClose();
                onCancel();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            className="opus-primary-button"
            onClick={() => {
              const isFormValid = validateFormBeforeSubmit();

              if (isFormValid) {
                const values = getValues();
                const postData =
                  slaRuleDataHandler.transformSlaRuleFormDataToPostData(
                    values as SlaRuleBaseFormData,
                    additionalFormData,
                    slaRuleConditionOptions
                  );

                if (searchParams.slaRuleId) {
                  editSlaRule({
                    id: searchParams.slaRuleId,
                    body: postData,
                  });
                } else {
                  createSlaRule({ ...postData });
                }

                onCancel && onCancel();
              }
            }}
          >
            {updateSlaLoading || createSlaLoading ? (
              <FormattedMessage
                id="common.saving"
                defaultMessage="Saving"
                capitalize
              />
            ) : (
              <FormattedMessage
                id="common.save"
                defaultMessage="Save"
                capitalize
              />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

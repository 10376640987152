import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useFetchAffectedPackagesQuery } from '../../../../store/api';
import { useQueryParams } from '../../../../../shared/hooks/useQueryParams';
import CommonSimpleDataGrid from '../../../../../shared/components/CommonSimpleDataGrid';
import {
  AffectedPackagesAdditionalColumns,
  AffectedPackagesColumns,
} from '../../../../../shared/fixtures/data/findings.data';
import { Box, Skeleton } from '@mui/material';
import CommonPagination from '../../../../../Common/components/Pagination';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';

interface FindingContentAffectedPackagesProps extends BaseComponentProps {
  findingId?: string;
  isLoading?: boolean;
}

export const FindingContentAffectedPackages: FunctionComponent<
  FindingContentAffectedPackagesProps
> = ({ findingId, isLoading: isCodeEventLoading }) => {
  const { t: translation } = useTranslation();
  const { data, isLoading } = useFetchAffectedPackagesQuery(findingId || '', {
    skip: findingId ? false : true,
  });
  const [pageSize, _] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [paginatedRows, setPaginatedRows] = useState<any[]>([]);

  const calculatePaginatedRows = (size: number, numb: number) => {
    const startRowIndex = (numb - 1) * size;
    const endRowIndex = startRowIndex + size;
    const paginatedRows =
      data && data.length ? data.slice(startRowIndex, endRowIndex) : [];
    setPaginatedRows(paginatedRows);
  };

  useEffect(() => {
    if (data) {
      calculatePaginatedRows(pageSize, page);
    }
  }, [pageSize, page, data]);

  const getAffectedPackagesColumns = useMemo(() => {
    if (data && data.length > 0) {
      const hasOSLevelKey = data.some((row) => row.hasOwnProperty('isOsLevel'));
      if (hasOSLevelKey) {
        return [
          ...AffectedPackagesColumns,
          ...AffectedPackagesAdditionalColumns,
        ];
      }
    }
    return AffectedPackagesColumns;
  }, [data]);

  const renderSectionBody = () => {
    if (isLoading || isCodeEventLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }

    if (paginatedRows?.length === 0) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-table-body">
        <CommonSimpleDataGrid
          rowData={paginatedRows}
          columnDefs={getAffectedPackagesColumns}
          domLayout="autoHeight"
          defaultColDef={{
            resizable: true,
            sortable: false,
            lockPinned: true,
            lockPosition: true,
            lockVisible: true,
          }}
        />
        <Box mt={2} className="settings-pagination" mx={2}>
          <CommonPagination
            disableChangePageSize
            totalItems={data?.length || 0}
            pageSize={pageSize}
            currentPage={page}
            onPageChange={(selected) => setPage(selected)}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
          />
        </Box>
      </div>
    );
  };

  const renderSection = () => {
    if (!isLoading && paginatedRows?.length === 0) return <></>;

    return (
      <div className="finding-content-item finding-content-affected-packages-container">
        <ContentSection
          iconClassName="finding-content-section-icon"
          rootClassName="finding-content-section"
          icon={<OpusSvgIcon type={SVG_ICON_TYPES.CUBES_ICON} />}
          title={translation(`findings.sections.affectedPackages`)}
          collapsable={true}
        >
          {renderSectionBody()}
        </ContentSection>
      </div>
    );
  };

  return renderSection();
};

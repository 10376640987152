import {
  RuleCategory,
  RuleOperatorType,
  RuleProperty,
  RulePropertyInRange,
  RulePropertyType,
} from 'Settings/interfaces/RiskCustomizationConfig';
import {
  hasAccessPropertyNames,
  inRangePropertyNames,
} from 'shared/fixtures/data/notification-rule.data';
import {
  CategoryState,
  ExtendedFilterCategory,
  MultiSelectState,
  NumberRangeState,
} from 'shared/models/data/data-filter.model';
import { AdvanceFilterHandler } from './advance-filter-data.handler';

const advancedFilterHandler = new AdvanceFilterHandler();

export class SettingsDataHandler {
  private getOperatorBasedOnPropertyKey(propertyKey: string): RuleOperatorType {
    if (hasAccessPropertyNames.includes(propertyKey)) {
      return RuleOperatorType.HAS_ACCESS;
    }

    if (propertyKey === 'labels') {
      return RuleOperatorType.OVERLAPS;
    }

    return RuleOperatorType.IN;
  }

  transformRulePropertiesToFormProperties(
    ruleProperties: Array<RuleProperty>
  ): Record<string, CategoryState> {
    let properties: Record<string, CategoryState> = {};
    for (const property of ruleProperties) {
      if (inRangePropertyNames.includes(property.type)) {
        if (property.values)
          properties[property.type] = {
            startValue: (property.values as RulePropertyInRange).start,
            endValue: (property.values as RulePropertyInRange).end,
          } as NumberRangeState;
      } else {
        if ((property.values as Array<any>).length) {
          properties[property.type] = {
            selectedOptions: property.values as Array<any>,
          };
        }
      }
    }

    return properties;
  }

  transformFormPropertiesToRuleProperties(
    formProperties: Record<string, CategoryState>,
    categories?: Array<ExtendedFilterCategory>
  ): Array<RuleProperty> {
    let properties: Array<RuleProperty> = [];

    const allCategories = advancedFilterHandler.extractAllCategories(
      categories || []
    );

    if (Object.keys(formProperties).length) {
      for (const propertyKey in formProperties) {
        const category = allCategories.find(
          (category: ExtendedFilterCategory) => category.id === propertyKey
        );

        const parentCategory = categories?.find(
          (category: ExtendedFilterCategory) =>
            Boolean(
              category.categories?.find(
                (cat: ExtendedFilterCategory) => cat.id === propertyKey
              )
            )
        );

        if (inRangePropertyNames.includes(propertyKey)) {
          const typedState = formProperties[propertyKey] as NumberRangeState;

          properties.push({
            operator: RuleOperatorType.IN_RANGE,
            type: propertyKey as unknown as RulePropertyType,
            values: { start: typedState.startValue, end: typedState.endValue },
            category: parentCategory?.id as RuleCategory,
          });
        } else {
          const typedState = formProperties[propertyKey] as MultiSelectState;
          properties.push({
            operator: this.getOperatorBasedOnPropertyKey(propertyKey),
            type: propertyKey as unknown as RulePropertyType,
            values: typedState?.selectedOptions || [],
            category: parentCategory?.id as RuleCategory,
          });
        }
      }
    }

    return properties;
  }
}

import { Typography } from '@mui/material';

import FormattedMessage from 'shared/components/FormattedMessage';

export default function IntegrationErrorView() {
  return (
    <div data-testid="ConnectionErrorView">
      <Typography color="error">
        <FormattedMessage
          id="common.anErrorOccured"
          defaultMessage="An error occured"
          capitalize
        />
        .
      </Typography>
    </div>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProjectInfoData } from 'Campaigns/interfaces/ProjectInfoWidget.interface';
import { RootState } from 'Common/store';
import { MenuItems, SelectionItems } from 'DashboardFilter/store';
import { GridSelectionProps } from 'Risk/store';
import { TypeFilter } from 'shared/enums/campaigns.enum';

export enum CampaignsOrderType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface CampaignsStore {
  filter: any;
  activeCampaign: ProjectInfoData | undefined;
  page: number;
  pageSize: number;
  iacFindingsTableSelectionProps: GridSelectionProps;
  listItems: MenuItems;
  selections: SelectionItems;
}

export const iacFindingsTableSelectionDefaultProps = {
  selectedChildren: [],
  availableChildren: [],
  rows: [],
  totalAvailableChildren: 0,
  totalSelectedChildren: 0,
};

const initialState: CampaignsStore = {
  filter: {},
  activeCampaign: undefined,
  page: 1,
  pageSize: 25,
  iacFindingsTableSelectionProps: iacFindingsTableSelectionDefaultProps,
  listItems: [],
  selections: [],
};

export const CampaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaignFindingsSearchParams: (
      state,
      action: PayloadAction<CampaignsStore>
    ) => {
      state.filter = action.payload.filter;

      state.activeCampaign = action.payload.activeCampaign
        ? action.payload.activeCampaign
        : undefined;
      state.page = action.payload.page ? action.payload.page : 1;
      state.pageSize = action.payload.pageSize ? action.payload.pageSize : 25;
    },
    setCampaignFindingsPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setListFilter: (
      state,
      action: PayloadAction<
        MenuItems | { list: MenuItems; isSelectingItem: boolean }
      >
    ) => {
      const updatedSelections: SelectionItems = [];

      const listMenu = Array.isArray(action.payload)
        ? [...action.payload]
        : action.payload.list;
      const modifiedArray = listMenu.map((object) => {
        if (
          object.dateRange ||
          object.numberInput ||
          object.items.some((item: any) => item.checked)
        ) {
          updatedSelections.push({
            id: object.id,
            type: object.type as TypeFilter,
            selectionValues:
              object.dateRange ||
              object.numberInput ||
              object.items?.filter((item: any) => item.checked),
          });
        }

        return {
          ...object,
          items: object.items.map((item) => {
            if (!item.hasOwnProperty('checked')) {
              return {
                ...item,
                checked: false,
              };
            }
            return { ...item };
          }),
        };
      });

      if (
        Array.isArray(action.payload) === false &&
        (action.payload as { list: MenuItems; isSelectingItem: boolean })
          .isSelectingItem
      ) {
        state.selections = updatedSelections;
      }

      state.listItems = modifiedArray;
    },
    setListItems: (state, action: PayloadAction<MenuItems>) => {
      state.listItems = action.payload;
    },
    setCampaignFindingsPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
      state.page = 1;
    },
    setActiveCampaign: (
      state,
      action: PayloadAction<ProjectInfoData | undefined>
    ) => {
      state.activeCampaign = action.payload;
    },
    setIacFindingsTableSelection: (
      state,
      action: PayloadAction<GridSelectionProps>
    ) => {
      state.iacFindingsTableSelectionProps = action.payload;
    },
    setSearchParams: (state, action) => {
      if (action.payload && Object.keys(action.payload).length) {
        state.filter = {
          ...state.filter,
          ...action.payload,
        };
      }
    },
  },
});

export const {
  setCampaignFindingsSearchParams,
  setActiveCampaign,
  setCampaignFindingsPage,
  setCampaignFindingsPageSize,
  setIacFindingsTableSelection,
  setListItems,
  setListFilter,
  setSearchParams,
} = CampaignsSlice.actions;

export const getCampaignFindingsState = (state: RootState) => state.campaigns;

export const getActiveCampaign = (state: RootState) =>
  state.campaigns.activeCampaign;

export const getIacFindingsTableSelectionProps = (
  state: RootState
): GridSelectionProps => {
  return state.campaigns.iacFindingsTableSelectionProps;
};
export const getFilterList = (state: RootState) => state.campaigns.listItems;
export const getSelectionList = (state: RootState) =>
  state.campaigns.selections;

export const getSearchParams = (state: RootState) => state.campaigns.filter;
const CampaignsReducer = CampaignsSlice.reducer;
export default CampaignsReducer;

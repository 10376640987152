import { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardSectionProps extends BaseComponentProps {
  title: string;
  icon?: ReactNode;
  filter?: ReactNode;
}

export const DashboardSection: FunctionComponent<DashboardSectionProps> = ({
  title,
  icon,
  children,
  filter,
}) => {
  const renderHeaderIcon = () => {
    if (icon) {
      return <div className="dashboard-section-header-icon">{icon}</div>;
    }
    return <></>;
  };

  return (
    <div className="dashboard-section-container">
      <div className="dashboard-section-header">
        <div className="dashboard-section-header-title">
          {renderHeaderIcon()}
          {title}
        </div>
        <div className="dashboard-section-header-filter">{filter}</div>
      </div>

      <div className="dashboard-section-body">{children}</div>
    </div>
  );
};

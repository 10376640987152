import { FunctionComponent } from 'react';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { BaseIntegrationModal } from '../BaseIntegrationModal/BaseIntegrationModal';

interface OrcaIntegrationModalProps extends CommonIntegrationModalProps {}

export const OrcaIntegrationModal: FunctionComponent<
  OrcaIntegrationModalProps
> = (props) => {
  return <BaseIntegrationModal {...props} />;
};

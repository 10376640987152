import { SelectionItem, SelectionItems } from 'DashboardFilter/store';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { RiskFilters } from 'Risk/store';
import { capitalize } from 'lodash';
import { StaticFilterOptions } from 'shared/components/FilterDropdown/FilterDropdown';
import { TypeFilter } from 'shared/enums/campaigns.enum';
import {
  CategoryState,
  DateRangeState,
  ExtendedFilterCategory,
  FilterCategory,
  FilterOperator,
  FilterOption,
  FilterType,
  KeyValueState,
  MultiSelectState,
  NumberRangeState,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';

export class AdvanceFilterHandler {
  translateAdvanceFilterComponentValuesToUrlSearchParams(
    advanceFilterData: SelectionItems
  ) {
    const riskFilters: any = {};

    advanceFilterData.forEach((menuItem: SelectionItem) => {
      switch (menuItem.type) {
        case TypeFilter.MULTI_SELECT: {
          if (menuItem.selectionValues.length) {
            const hasSomeChecked = menuItem.selectionValues?.some(
              (item: any) => item.checked
            );
            if (hasSomeChecked) {
              riskFilters[menuItem.id] = menuItem.selectionValues
                ?.filter((item: any) => item.checked)
                .map((item) => ({
                  id: item.id,
                  name: item.name,
                }));
            }
          }

          break;
        }
        case TypeFilter.SINGLE_SELECT: {
          if (menuItem.selectionValues.length) {
            const checkedItem = menuItem.selectionValues.find(
              (item: any) => item.checked
            );

            if (checkedItem) {
              riskFilters[menuItem.id] = {
                id: checkedItem.id,
                name: checkedItem.name,
              };
            }
          }
          break;
        }
        case TypeFilter.DATE_PICKER_RANGE: {
          if (menuItem.selectionValues) {
            riskFilters[menuItem.id] = menuItem.selectionValues as Array<any>;
          }
          break;
        }
        case TypeFilter.NUMBER: {
          if (menuItem.selectionValues) {
            riskFilters[menuItem.id] = menuItem.selectionValues as Array<any>;
          }
          break;
        }
      }
    });

    return riskFilters;
  }

  translateAdvanceFilterComponentValuesToSearchParams(
    advanceFilterData: SelectionItems
  ): RiskFilters {
    const riskFilters: RiskFilters = {};

    advanceFilterData.forEach((menuItem: SelectionItem) => {
      switch (menuItem.type) {
        case TypeFilter.MULTI_SELECT: {
          if (menuItem.selectionValues.length) {
            const hasSomeChecked = menuItem.selectionValues?.some(
              (item: any) => item.checked
            );
            if (hasSomeChecked) {
              riskFilters[menuItem.id] = menuItem.selectionValues
                ?.filter((item: any) => item.checked)
                .map((item) => ({
                  value: item.id === StaticFilterOptions.NONE ? null : item.id,
                  label: item.name,
                }));
            }
          }

          break;
        }
        case TypeFilter.SINGLE_SELECT: {
          if (menuItem.selectionValues.length) {
            const checkedItem = menuItem.selectionValues.find(
              (item: any) => item.checked
            );

            if (checkedItem) {
              riskFilters[menuItem.id] = {
                value:
                  checkedItem.id === StaticFilterOptions.NONE
                    ? null
                    : checkedItem.id,
                label: checkedItem.name,
              };
            }
          }
          break;
        }
        case TypeFilter.DATE_PICKER_RANGE: {
          if (menuItem.selectionValues) {
            riskFilters[menuItem.id] = menuItem.selectionValues as Array<any>;
          }
          break;
        }
        case TypeFilter.NUMBER: {
          if (menuItem.selectionValues) {
            riskFilters[menuItem.id] = menuItem.selectionValues as Array<any>;
          }
          break;
        }
      }
    });

    return riskFilters;
  }

  extractAllCategories(
    categories: Array<ExtendedFilterCategory>
  ): Array<ExtendedFilterCategory> {
    let allCategories: Array<ExtendedFilterCategory> = [];

    function extract(categories: Array<ExtendedFilterCategory>) {
      categories.forEach((category) => {
        allCategories.push(category);
        if (category.categories && category.categories.length > 0) {
          extract(category.categories);
        }
      });
    }

    extract(categories);
    return allCategories;
  }

  translateQueryFiltersToFilterState(
    queryFilters: any,
    categories: Array<ExtendedFilterCategory>,
    optionTransformer?: (option: any) => { id: string; name: string }
  ) {
    const allCategories = this.extractAllCategories(categories);

    return Object.keys(queryFilters).reduce(
      (accumulator, currentKey: string) => {
        const category = allCategories.find(
          (cat: FilterCategory) => cat.id === currentKey
        );

        switch (category?.type) {
          case FilterType.SINGLE_SELECT: {
            const transformedOption = optionTransformer
              ? optionTransformer(queryFilters[currentKey])
              : queryFilters[currentKey];

            accumulator[currentKey] = {
              selectedOptions: [
                {
                  value: transformedOption.id,
                  label: transformedOption.name,
                },
              ],
            };

            break;
          }
          case FilterType.MULTI_SELECT: {
            accumulator[currentKey] = {
              selectedOptions: queryFilters[currentKey]?.map(
                (filterOption: any) => {
                  const transformedOption = optionTransformer
                    ? optionTransformer(filterOption)
                    : filterOption;

                  return {
                    value: transformedOption.id,
                    label: transformedOption.name,
                  };
                }
              ),
            };

            break;
          }
          case FilterType.DATE_RANGE: {
            accumulator[currentKey] = {
              startDate: queryFilters[currentKey][0],
              endDate: queryFilters[currentKey][1],
            };
            break;
          }

          case FilterType.NUMBER_RANGE: {
            const typedCategoryState = category.state as NumberRangeState;
            accumulator[currentKey] = {
              startValue: queryFilters[currentKey].startValue,
              endValue: queryFilters[currentKey].endValue,
              minValue: typedCategoryState.minValue,
              maxValue: typedCategoryState.maxValue,
            };
            break;
          }

          case FilterType.KEY_VALUE: {
            const typedCategoryState = category.state as KeyValueState;

            accumulator[currentKey] = {
              ...typedCategoryState,
              selections: queryFilters[currentKey],
            };

            break;
          }
        }

        return accumulator;
      },
      {} as Record<string, CategoryState>
    );
  }

  translateFilterStateSelectionsToQueryFilters(
    filterState: Record<string, CategoryState>,
    categories: Array<ExtendedFilterCategory>
  ) {
    const allCategories = this.extractAllCategories(categories);

    return Object.keys(filterState).reduce(
      (accumulator, currentKey: string) => {
        const category = allCategories.find(
          (cat: FilterCategory) => cat.id === currentKey
        );
        const categoryState = filterState[currentKey];

        switch (category?.type) {
          case FilterType.SINGLE_SELECT: {
            if (Object.keys(categoryState).length) {
              const typedState = categoryState as SingleSelectState;

              if (typedState.selectedOptions?.length) {
                accumulator[currentKey] = {
                  id: typedState.selectedOptions[0].value,
                  name:
                    typedState.selectedOptions[0].label ||
                    typedState.selectedOptions[0].value,
                };
              }
            }

            break;
          }
          case FilterType.MULTI_SELECT: {
            if (Object.keys(categoryState).length) {
              const typedState = categoryState as MultiSelectState;

              if (typedState.selectedOptions?.length) {
                accumulator[currentKey] = typedState.selectedOptions?.map(
                  (selectedOption: FilterOption) => ({
                    id: selectedOption.value,
                    name: selectedOption.label || selectedOption.value,
                  })
                );
              }
            }

            break;
          }
          case FilterType.DATE_RANGE: {
            if (Object.keys(categoryState).length) {
              const typedState = categoryState as DateRangeState;

              if (typedState.startDate && typedState.endDate) {
                accumulator[currentKey] = [
                  typedState.startDate,
                  typedState.endDate,
                ];
              }
            }
            break;
          }
          case FilterType.NUMBER_RANGE: {
            if (Object.keys(categoryState).length) {
              const typedState = categoryState as NumberRangeState;

              if (
                typedState.startValue !== undefined &&
                typedState.endValue !== undefined
              ) {
                accumulator[currentKey] = {
                  startValue: typedState.startValue,
                  endValue: typedState.endValue,
                };
              }
            }

            break;
          }

          case FilterType.KEY_VALUE: {
            const typedState = categoryState as KeyValueState;

            const validSelections = typedState.selections?.filter(
              (selection) => selection.key.value.length > 0
            );

            if (
              validSelections?.length === 1 &&
              validSelections[0].key.value.length === 0
            ) {
              break;
            } else {
              if (validSelections?.length)
                accumulator[currentKey] = validSelections;
            }

            break;
          }
        }

        return accumulator;
      },
      {} as Record<string, any>
    );
  }

  translateFilterStateSelectionsToApiFilters(
    filterState: Record<string, CategoryState>,
    categories: Array<ExtendedFilterCategory>
  ) {
    const allCategories = this.extractAllCategories(categories);

    return Object.keys(filterState).reduce(
      (accumulator, currentKey: string) => {
        const category = allCategories.find(
          (cat: FilterCategory) => cat.id === currentKey
        );
        const categoryState = filterState[currentKey];

        if (category?.operator) {
          switch (category.operator) {
            case FilterOperator.OVERLAPS: {
              if (Object.keys(categoryState).length) {
                const typedState = categoryState as MultiSelectState;

                if (typedState.selectedOptions?.length)
                  accumulator[currentKey] = {
                    operator: category.operator,
                    value: typedState.selectedOptions?.map(
                      (selectedOption) => selectedOption.value
                    ),
                  };
              }
            }
          }
        } else {
          switch (category?.type) {
            case FilterType.SINGLE_SELECT: {
              if (Object.keys(categoryState).length) {
                const typedState = categoryState as SingleSelectState;

                if (typedState.selectedOptions?.length) {
                  if (currentKey === 'scopeId' || currentKey === 'groupId') {
                    accumulator[currentKey] = [typedState.selectedOptions[0]];
                  } else {
                    accumulator[currentKey] = typedState.selectedOptions[0];
                  }
                }
              }

              break;
            }
            case FilterType.MULTI_SELECT: {
              if (Object.keys(categoryState).length) {
                const typedState = categoryState as MultiSelectState;

                if (typedState.selectedOptions?.length) {
                  accumulator[currentKey] = typedState.selectedOptions;
                }
              }

              break;
            }
            case FilterType.DATE_RANGE: {
              if (Object.keys(categoryState).length) {
                const typedState = categoryState as DateRangeState;

                if (typedState.startDate && typedState.endDate) {
                  accumulator[currentKey] = [
                    typedState.startDate,
                    typedState.endDate,
                  ];
                }
              }
              break;
            }
            case FilterType.NUMBER_RANGE: {
              if (Object.keys(categoryState).length) {
                const typedState = categoryState as NumberRangeState;

                if (
                  typedState.startValue !== undefined &&
                  typedState.endValue !== undefined
                ) {
                  accumulator[currentKey] = {
                    operator: FilterOperator.RANGE,
                    value: {
                      start: typedState.startValue,
                      end: typedState.endValue,
                    },
                  };
                }
              }

              break;
            }

            case FilterType.KEY_VALUE: {
              const typedState = categoryState as KeyValueState;

              const validSelections = typedState.selections?.filter(
                (selection) => selection.key.value.length > 0
              );

              if (validSelections?.length) {
                accumulator[currentKey] = {
                  operator: FilterOperator.ANY_TAG,
                  value: validSelections.map((selection) => ({
                    key: selection.key.value,
                    values: selection.values?.map(
                      (valueOption) => valueOption.value
                    ),
                  })),
                };
              }

              break;
            }
          }
        }

        return accumulator;
      },
      {} as Record<string, any>
    );
  }

  translateApiFiltersToToFilterStateSelections(
    filters: Record<string, any>,
    categories: Array<ExtendedFilterCategory>
  ): Record<string, CategoryState> {
    return Object.keys(filters).reduce((accumulator, currentKey: string) => {
      const category = categories.find(
        (cat: FilterCategory) => cat.id === currentKey
      );
      const filterState = filters[currentKey];

      switch (category?.type) {
        case FilterType.SINGLE_SELECT: {
          if (typeof filterState.value === 'boolean') {
            accumulator[currentKey] = {
              selectedOptions: [
                {
                  value: filterState.value,
                  label: filterState.value ? 'Yes' : 'No',
                },
              ],
            };
          } else if (currentKey === 'duplicationState') {
            accumulator[currentKey] = {
              selectedOptions: [
                {
                  value: filterState.value,
                  label:
                    filterState.value === 'primary' ? 'Duplicated' : 'Single',
                },
              ],
            };
          } else if (currentKey === 'scopeId' || currentKey === 'groupId') {
            accumulator[currentKey] = {
              selectedOptions: [filterState[0]],
            };
          } else {
            accumulator[currentKey] = {
              selectedOptions: [filterState],
            };
          }

          break;
        }
        case FilterType.MULTI_SELECT: {
          if (currentKey === 'severity') {
            accumulator[currentKey] = {
              selectedOptions: filterState?.map(
                (filterOption: AutocompleteOption) => ({
                  value: +filterOption.value,
                  label: capitalize(FindingSeverity[filterOption.value as any]),
                })
              ),
            };
          } else {
            accumulator[currentKey] = {
              selectedOptions: filterState,
            };
          }

          break;
        }
        case FilterType.DATE_RANGE: {
          accumulator[currentKey] = {
            startDate: filterState[0],
            endDate: filterState[1],
          };
          break;
        }

        case FilterType.NUMBER_RANGE: {
          const typedCategoryState = category.state as NumberRangeState;
          accumulator[currentKey] = {
            startValue: filterState.value?.start,
            endValue: filterState.value?.end,
            minValue: typedCategoryState.minValue,
            maxValue: typedCategoryState.maxValue,
          };
          break;
        }

        case FilterType.KEY_VALUE: {
          const typedCategoryState = category.state as KeyValueState;

          accumulator[currentKey] = {
            ...typedCategoryState,
            selections: filterState.value,
          };

          break;
        }
      }

      return accumulator;
    }, {} as Record<string, any>);
  }
}

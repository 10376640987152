import DiagramStep from 'WorkflowBuilder/interfaces/step';
import { DisplayStepDetaislArgs } from '../components/FindingWorkflow/FindingWorkflow';
import {
  addButtonNodesToCrossroads,
  sealDiagramDataOnCondition,
} from 'WorkflowBuilder/utils/diagram-fns';
import { LAYOUT_MODE } from 'WorkflowBuilder/interfaces/layout';

interface FetchWorkflowWithStepsParams {
  findingId: number | string;
  selectedWorkflowId?: number | string;
  displayStepDetails: (args: DisplayStepDetaislArgs) => void;
}

interface WorkflowStep {
  createdAt: string;
  id: string;
  nextStepId: string;
  parameters: Array<any>;
  results: Array<any>;
  status: string;
  stepDefId: string;
  tenantId: string;
  updatedAt: string;
  workflowId: string;
  workflowSeqId: string;
}

export default function transformData({
  findingWorkflowDetails,
  findingWorkflowDetailsLoading,
  displayStepDetails,
}: any) {
  return {
    ...findingWorkflowDetails,
    findingWorkflowDetailsLoading,
    workflowSteps: sealDiagramDataOnCondition(
      addButtonNodesToCrossroads(
        findingWorkflowDetails?.workflowSteps.map(
          (workflowStep: DiagramStep) => {
            return {
              ...workflowStep,
              diagramData: {
                ...workflowStep?.diagramData,
                displayReadonlyStepDetails: displayStepDetails,
              },
            };
          }
        )
      ),
      LAYOUT_MODE.VIEW
    ),
  };
}

import { Grid } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface NoDataToDisplayCardProps extends BaseComponentProps {
  displayIcon?: boolean;
  displayDescription?: boolean;
}

export const NoDataToDisplayCard: FunctionComponent<
  NoDataToDisplayCardProps
> = ({ displayIcon, displayDescription = true }) => {
  const renderDescription = () => {
    if (displayDescription) {
      return (
        <div className="description">
          <FormattedMessage
            id="common.noDataFoundDescription"
            defaultMessage="Change from the last period"
            capitalize
          />
        </div>
      );
    }

    return <></>;
  };
  return (
    <Grid className="no-data-container" container>
      <Grid item xs={12}>
        {displayIcon && (
          <div className="icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_ZOOM} />
          </div>
        )}
        <div className="title">
          <FormattedMessage
            id="common.noDataFound"
            defaultMessage="No data found"
            capitalize
          />
        </div>
        {renderDescription()}
      </Grid>
    </Grid>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import ConnectionTemplateItem, {
  ConnectionTemplateType,
  Parameter,
} from 'ConnectionTemplate/interfaces/item';
import { SectionBreaker } from 'Dashboard/components/Dashboard/SectionBreaker/SectionBreaker';
import ConnectionItem from 'Integrations/Connection/interfaces/item';
import {
  useConnectionCreateMutation,
  useConnectionTestMutation,
  useConnectionUpdateWithRepoMutation,
} from 'Integrations/Connection/store/api';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AlertBox, AlertBoxType } from 'shared/components/AlertBox/AlertBox';
import { CommonButton } from 'shared/components/CommonButton';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { integrationsFormDefaultSchema } from 'shared/fixtures/data/integrations.data';
import { IntegrationsDataHandler } from 'shared/handlers/integrations-data.handler';
import { useOauthIntegration } from 'shared/hooks/useOauthIntegration';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import * as yup from 'yup';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { CircularProgress } from '@mui/material';
import { InputLabelWrapper } from 'shared/components/InputLabelWrapper/InputLabelWrapper';
import { capitalize } from 'lodash';
import { FormInput } from 'shared/components/FormInput/FormInput';
import Autocomplete from 'Common/components/Autocomplete';
import { useFetchIntegrationMetadata } from 'shared/hooks/useFetchIntegrationMetadata';
import { IntegrationRepositoryTable } from '../IntegrationRepositoryTable/IntegrationRepositoryTable';
import { PollingSubscription } from 'Integrations/Webhook/interfaces/polling-subscription';
import { IntegrationCategory } from 'Integrations/interfaces/IntegrationCategory.enum';
import WebhookItem from 'Integrations/Webhook/interfaces/item';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { useFetchDefaultConnectionForApplication } from 'shared/hooks/useFetchDefaultConnectionForApplication';
import { useTranslation } from 'react-i18next';
import { IntegrationActionType } from 'shared/models/data/integrations.model';
import { AlertExpandBox } from 'shared/components/AlertExpandBox/AlertExpandBox';
import { OrganizationNodeDataHandler as OrganizationNodeOptionDataHandler } from 'shared/handlers/organization-node-data.handler';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { useAuth } from '@frontegg/react';
import { ApplicationTypes } from 'Application/interfaces/applications.enum';

interface BaseIntegrationModalCreateFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {
  renderAdditionalContent?: (
    isVisible: boolean,
    toggleVisibility: () => void,
    data?: PollingSubscription | WebhookItem
  ) => JSX.Element;
  onConnectionTemplateChange?: (template?: ConnectionTemplateItem) => void;
  handleOnCreate?: (connection: IntegrationItem) => void;
}

const integrationsDataHandler = new IntegrationsDataHandler();

const organizationNodeOptionDataHandler =
  new OrganizationNodeOptionDataHandler();

export const BaseIntegrationModalCreateForm: FunctionComponent<
  BaseIntegrationModalCreateFormProps
> = ({
  renderAdditionalContent,
  onCancel,
  integrationItem,
  open,
  onConnectionTemplateChange,
  handleOnCreate,
  rootClassName = '',
  renderButtonRow: propsRenderButtonRow,
  businessUnitRequired = true,
}) => {
  const { t: translation } = useTranslation();
  const { user, isAuthenticated } = useAuth();

  const { connectionTemplate, loadingConnectionTemplate } =
    useFetchIntegrationMetadata(open, integrationItem);

  const [
    getUserAvailableNodesOfType,
    { data: nodeData, isLoading: nodeDataLoading },
  ] = useGetUserAvailableNodesOfTypeMutation();

  const defaultConnectionPayload = useFetchDefaultConnectionForApplication(
    integrationItem?.id
  );

  const [selectedRepoIds, setSelectedRepoIds] = useState<Array<string>>([]);

  const [additionalContentVisible, setAdditionalContentVisible] =
    useState<boolean>(false);

  const [selectedAllRepos, setSelectedAllRepos] = useState<boolean>(false);
  const [configurationError, setConfigurationError] = useState<string | null>(
    null
  );

  const formDataInitialized = useRef<boolean>(false);

  const formDefaultValues = useMemo(() => {
    if (connectionTemplate?.parameters?.length && open) {
      return connectionTemplate?.parameters?.reduce(
        (accumulator: any, currentValue: Parameter) => {
          if (currentValue.defaultValue) {
            accumulator[currentValue.name] = currentValue.defaultValue;
          }

          return accumulator;
        },
        {}
      );
    }

    return undefined;
  }, [connectionTemplate?.parameters]);

  const formSchema = useMemo(() => {
    if (connectionTemplate) {
      return yup.object().shape({
        ...integrationsFormDefaultSchema,
        ...connectionTemplate.parameters.reduce(
          (previousValue: any, currentValue: any) => {
            if (currentValue.hidden) return previousValue;

            if (
              currentValue.required === undefined ||
              currentValue.required === true
            )
              return {
                ...previousValue,
                [currentValue.name]: yup
                  .string()
                  .required()
                  .default(currentValue.defaultValue || ''),
              };

            return {
              ...previousValue,
              [currentValue.name]: yup
                .string()
                .default(currentValue.defaultValue || ''),
            };
          },
          {}
        ),
      });
    }

    return yup.object().shape(integrationsFormDefaultSchema);
  }, [connectionTemplate]);

  const {
    formState: { errors },
    getValues,
    setValue,
    setError,
    clearErrors,
    reset: resetFormState,
    watch: watchFormValue,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      isFallback: false,
      name: '',
      enviroments: [],
      scopeId: '',
    } as any,
    mode: 'all',
  });

  const [
    createConnection,
    {
      isLoading: creatingConnection,
      isSuccess: createConnectionSuccess,
      error: createConnectionError,
      reset: resetCreateConnection,
      data: createConnectionData,
    },
  ] = useConnectionCreateMutation();

  const [
    testConnection,
    {
      data: testConnectionPayload,
      isLoading: testingConnection,
      isSuccess: testConnectionSuccess,
      error: testConnectionError,
      reset: resetTestConnection,
    },
  ] = useConnectionTestMutation();

  const [
    updateWithRepos,
    {
      isLoading: settingRepos,
      isSuccess: settingReposSuccess,
      error: settingReposError,
      reset: resetRepos,
      data: settingReposData,
    },
  ] = useConnectionUpdateWithRepoMutation();

  useEffect(() => {
    return () => {
      formDataInitialized.current = false;
    };
  }, []);

  useEffect(() => {
    if (formDefaultValues && !formDataInitialized.current) {
      for (const formKey in formDefaultValues) {
        setValue(formKey, formDefaultValues[formKey]);
        clearErrors(formKey);
      }
      formDataInitialized.current = true;
    }
  }, [formDefaultValues, formDataInitialized]);

  useEffect(() => {
    if (
      settingReposData?.metadata?.scm?.repos?.selectedCount > 0 &&
      isAuthenticated &&
      user
    ) {
      (window as any).userGuiding.identify(user.email, {
        firstConnectedRepos: true,
      });
    }
  }, [settingReposData, isAuthenticated]);

  const {
    handleAuthorize,
    isLoading: isAuthorizing,
    isSuccess: isAuthorized,
    error: authorizationError,
    handleReset: resetAuthorizationState,
    authorizedConnection,
  } = useOauthIntegration();

  useEffect(() => {
    if (
      createConnectionSuccess &&
      integrationItem.category !== IntegrationCategory.SCM
    ) {
      closeHandler();
      handleOnCreate && handleOnCreate(createConnectionData);
    }
  }, [createConnectionSuccess]);

  useEffect(() => {
    if (integrationItem.category !== IntegrationCategory.SCM) {
      const scopeId = getValues().scopeId;

      if (!scopeId || scopeId === 'None') {
        getUserAvailableNodesOfType({
          nodesType: OrganizationNodeType.DATA,
        });
      } else {
        getUserAvailableNodesOfType({
          nodesType: OrganizationNodeType.DATA,
          startNodeId: scopeId,
        });
      }
    }
  }, [getValues().scopeId]);

  const nodeDataAutocompleteList: Array<AutocompleteOption> = useMemo(() => {
    return nodeData
      ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
          nodeData
        )
      : [];
  }, [nodeData]);

  useEffect(() => {
    if (isAuthorized) {
      closeHandler();
      handleOnCreate && handleOnCreate(authorizedConnection);
    }
  }, [isAuthorized]);

  useEffect(() => {
    if (settingReposSuccess) {
      closeHandler();
    }
  }, [settingReposSuccess]);

  const shouldConnectRepositories = useMemo<boolean>(() => {
    return (
      integrationItem.category === IntegrationCategory.SCM &&
      Boolean(createConnectionData || authorizedConnection)
    );
  }, [createConnectionData, authorizedConnection]);

  useEffect(() => {
    if (shouldConnectRepositories) {
      const repositoryTableElement = document.querySelector(
        '.integration-repository-table-container'
      );
      repositoryTableElement?.scrollIntoView();
      setConfigurationError(
        'Please select the repositories you wish to connect to this integration'
      );
    }
  }, [shouldConnectRepositories]);

  const resetApiState = () => {
    resetCreateConnection();
    resetTestConnection();
    resetAuthorizationState();
  };

  const [fetchScopeList, { data: scopeData, isLoading: scopeDataLoading }] =
    useGetUserAvailableNodesOfTypeMutation();
  useEffect(() => {
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
  }, []);

  const scopeAutocompleteList: Array<AutocompleteOption> = useMemo(() => {
    return scopeData
      ? organizationNodeOptionDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeData,
          true
        )
      : [];
  }, [scopeData]);
  const fieldValueChangeHandler = (fieldName: string, changeValue: any) => {
    if (changeValue.length) {
      clearErrors(fieldName);
    }
    setValue(fieldName, changeValue);
  };

  const validateReposTable = () => {
    let isValid = true;

    if (shouldConnectRepositories && selectedRepoIds.length === 0) {
      isValid = false;
      setConfigurationError(
        'Please select the repositories you wish to connect to this integration'
      );
    }

    return isValid;
  };

  const selectedScopeOption = useMemo<AutocompleteOption | undefined>(() => {
    return integrationsDataHandler.getSelectedScopeOption(
      scopeAutocompleteList,
      getValues().scopeId
    );
  }, [scopeAutocompleteList, getValues().scopeId]);

  const validateFormBeforeSubmit = () => {
    let isValid = true;
    const formValues = getValues();
    let schemaFields = formSchema.fields;

    if (
      integrationItem?.category === IntegrationCategory.SCM ||
      businessUnitRequired === false
    ) {
      delete schemaFields['enviroments'];
    }

    for (const schemaFieldKey in schemaFields) {
      const fieldOptions = schemaFields[schemaFieldKey];

      if (
        schemaFieldKey === 'enviroments' &&
        (formValues.isFallback ||
          (formValues.scopeId && formValues.scopeId !== 'None'))
      ) {
        clearErrors('enviroments');
      } else if (
        (fieldOptions.type === 'string' || fieldOptions.type === 'array') &&
        fieldOptions.spec.presence === 'required' &&
        (formValues[schemaFieldKey] === undefined ||
          formValues[schemaFieldKey]?.length === 0) &&
        schemaFieldKey !== 'scopeId'
      ) {
        isValid = false;
        setError(schemaFieldKey, {
          message: `${
            schemaFieldKey === 'enviroments'
              ? `Service or Scope`
              : schemaFieldKey
          } is required`,
        });
      }
    }

    return isValid;
  };

  useEffect(() => {
    onConnectionTemplateChange &&
      onConnectionTemplateChange(connectionTemplate);
  }, [connectionTemplate]);

  const closeHandler = () => {
    resetFormState();
    resetApiState();
    onCancel();
  };

  const setReposHandler = () => {
    const isValid = validateReposTable();

    if (isValid) {
      setConfigurationError(null);
      updateWithRepos({
        id: createConnectionData?.id || authorizedConnection?.id,
        repos: selectedAllRepos ? [] : selectedRepoIds,
        selectAll: selectedAllRepos,
        connection: createConnectionData,
      });
    }
  };

  const createHandler = () => {
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      const postValues =
        integrationsDataHandler.transformIntegrationsFormDataToPostData(
          getValues(),
          integrationItem,
          connectionTemplate
        ) as ConnectionItem;

      createConnection(postValues);
    }
  };

  useEffect(() => {
    setValue('isFallback', !Boolean(defaultConnectionPayload?.connection));
  }, [defaultConnectionPayload?.connection]);

  const connectHandler = () => {
    if (shouldConnectRepositories) {
      setReposHandler();
    } else {
      createHandler();
    }
  };

  const testHandler = () => {
    setConfigurationError(null);

    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      testConnection(
        integrationsDataHandler.transformIntegrationsFormDataToPostData(
          getValues(),
          integrationItem,
          connectionTemplate
        ) as ConnectionItem
      );
    }
  };

  const authorizeHandler = () => {
    if (shouldConnectRepositories) {
      setReposHandler();
    } else {
      oauthHandler();
    }
  };

  const oauthHandler = () => {
    const isFormValid = validateFormBeforeSubmit();

    if (isFormValid) {
      handleAuthorize(
        integrationsDataHandler.transformIntegrationsFormDataToPostData(
          getValues(),
          integrationItem,
          connectionTemplate
        ) as ConnectionItem
      );
    }
  };

  const renderAlertBox = () => {
    if (createConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.CREATING,
            integrationItem.name
          )}
          description={
            (createConnectionError as any)?.data?.message ||
            (createConnectionError as any)?.data?.error
          }
        />
      );
    }

    if (testConnectionError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            integrationItem.name
          )}
          description={(testConnectionError as any)?.data?.error}
        />
      );
    }

    if (testConnectionPayload && testConnectionPayload?.status === 'failed') {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.VERIFYING,
            integrationItem.name
          )}
          description={testConnectionPayload?.results?.error}
        />
      );
    }

    if (configurationError) {
      return (
        <AlertBox
          type={AlertBoxType.WARNING}
          onClose={() => {
            setConfigurationError(null);
          }}
        >
          {configurationError}
        </AlertBox>
      );
    }

    if (authorizationError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.CREATING,
            integrationItem.name
          )}
          description={authorizationError}
        />
      );
    }

    if (settingReposError) {
      return (
        <AlertExpandBox
          type={AlertBoxType.ERROR}
          onClose={resetApiState}
          title={integrationsDataHandler.getIntegrationErrorMessage(
            translation,
            IntegrationActionType.CREATING,
            integrationItem.name
          )}
          description={
            (settingReposError as any)?.data?.message ||
            (settingReposError as any)?.data?.error
          }
        />
      );
    }

    if (testConnectionPayload && testConnectionPayload.status === 'done') {
      return (
        <AlertBox type={AlertBoxType.SUCCESS} onClose={resetApiState}>
          Connection Verified Successfully
        </AlertBox>
      );
    }

    return <></>;
  };

  const renderButton = (
    type: CommonButtonType,
    onClick: any,
    label: string
  ) => (
    <CommonButton type={type} onClick={onClick}>
      {label}
    </CommonButton>
  );

  const getAuthorizeButtonLabel = () => {
    if (shouldConnectRepositories) {
      return settingRepos ? 'Connecting' : 'Connect';
    }
    return isAuthorizing ? 'Authorizing' : 'Authorize';
  };

  const connectButtonLabel =
    creatingConnection || settingRepos ? 'Connecting' : 'Connect';
  const verifyButtonLabel = testingConnection
    ? 'Verifying Connectivity'
    : 'Verify Connectivity';

  const renderAuthorizationAndConnectButtonRow = () => (
    <div className="integration-modal-button-row">
      {renderButton(CommonButtonType.SECONDARY, closeHandler, 'Cancel')}
      <div className="integration-modal-connection-buttons">
        {renderButton(
          CommonButtonType.SECONDARY,
          testHandler,
          verifyButtonLabel
        )}
        {renderButton(
          CommonButtonType.PRIMARY,
          connectHandler,
          connectButtonLabel
        )}
        {renderButton(
          CommonButtonType.PRIMARY,
          authorizeHandler,
          getAuthorizeButtonLabel()
        )}
      </div>
    </div>
  );

  const renderAuthorizationButtonRow = () => (
    <div className="integration-modal-button-row">
      <div className="integration-modal-cancel-buttons">
        {renderButton(CommonButtonType.SECONDARY, closeHandler, 'Cancel')}
      </div>
      <div className="integration-modal-connection-buttons">
        {renderButton(
          CommonButtonType.SECONDARY,
          testHandler,
          verifyButtonLabel
        )}
        {renderButton(
          CommonButtonType.PRIMARY,
          authorizeHandler,
          getAuthorizeButtonLabel()
        )}
      </div>
    </div>
  );

  const renderConnectButtonRow = () => (
    <div className="integration-modal-button-row">
      <div className="integration-modal-cancel-buttons">
        {renderButton(CommonButtonType.SECONDARY, closeHandler, 'Cancel')}
      </div>
      <div className="integration-modal-connection-buttons">
        {renderButton(
          CommonButtonType.SECONDARY,
          testHandler,
          verifyButtonLabel
        )}
        {renderButton(
          CommonButtonType.PRIMARY,
          connectHandler,
          connectButtonLabel
        )}
      </div>
    </div>
  );

  const renderButtonRow = () => {
    if (loadingConnectionTemplate) {
      return <></>;
    }
    if (
      connectionTemplate?.type === ConnectionTemplateType.OAUTH &&
      (connectionTemplate.applicationId === ApplicationTypes.GITHUB ||
        connectionTemplate.applicationId ===
          ApplicationTypes.GITHUB_ADVANCED_SECURITY) &&
      !shouldConnectRepositories
    ) {
      if (propsRenderButtonRow)
        return propsRenderButtonRow({
          verifyHandler: testHandler,
          authorizeHandler,
          authorizing: isAuthorizing,
          connectHandler: connectHandler,
          connecting: creatingConnection,
          verifying: testingConnection,
        });
      return renderAuthorizationAndConnectButtonRow();
    }

    if (connectionTemplate?.type === ConnectionTemplateType.OAUTH) {
      if (propsRenderButtonRow)
        return propsRenderButtonRow({
          verifyHandler: testHandler,
          authorizeHandler,
          connectHandler: () => {},
          connecting: false,
          authorizing: isAuthorizing,
          verifying: false,
        });
      return renderAuthorizationButtonRow();
    }

    if (propsRenderButtonRow)
      return propsRenderButtonRow({
        verifyHandler: testHandler,
        connectHandler: connectHandler,
        connecting: creatingConnection,
        verifying: testingConnection,
      });

    return renderConnectButtonRow();
  };

  const renderEnvironmentsSelect = () => {
    if (
      loadingConnectionTemplate ||
      integrationItem?.category === IntegrationCategory.SCM
    ) {
      return <></>;
    }

    return (
      <>
        <InputLabelWrapper label="Services">
          {nodeDataLoading ? (
            <CircularProgress size={24} />
          ) : (
            <Autocomplete
              enableCheckbox
              classes={{
                root: 'multi-select-field-1',
                paper: 'multi-select-field-paper-1',
                inputRoot: errors.enviroments ? 'input-validation-error-1' : '',
              }}
              model="environments"
              label="Services"
              areOptionsLoaded
              initialSelectedValues={[]}
              onChangeCallBack={(model, values) => {
                const typedValues = values as Array<AutocompleteOption>;

                fieldValueChangeHandler('enviroments', typedValues);
              }}
              loadingText={translation(`common.loading`)}
              loading={nodeDataLoading}
              optionList={nodeDataAutocompleteList}
              disabled={shouldConnectRepositories}
            />
          )}
        </InputLabelWrapper>
      </>
    );
  };

  const renderIsDefaultCheckbox = () => {
    if (integrationItem.category === IntegrationCategory.SCM) {
      return <></>;
    }

    return (
      <InputLabelWrapper
        defaultChecked={
          defaultConnectionPayload?.isLoading
            ? false
            : !Boolean(defaultConnectionPayload?.connection)
        }
        onToggle={(checked) => setValue('isFallback', checked)}
        showCheckbox
        label="Default Connection"
        disabled={
          defaultConnectionPayload?.isLoading
            ? true
            : shouldConnectRepositories ||
              Boolean(defaultConnectionPayload?.connection)
        }
      />
    );
  };

  const renderFormSectionBreaker = () => {
    if (integrationItem?.category === IntegrationCategory.SCM) {
      return <></>;
    }

    if (renderAdditionalContent || connectionTemplate) {
      return <SectionBreaker />;
    }

    return <></>;
  };

  const renderDynamicInputs = () => {
    if (loadingConnectionTemplate) {
      return <CircularProgress size={20} />;
    }

    if (!connectionTemplate) {
      return <></>;
    }

    return connectionTemplate.parameters?.length ? (
      connectionTemplate.parameters?.map((parameter: any) => {
        if (parameter?.hidden) return <></>;

        return (
          <FormInput
            label={parameter.displayName}
            name={parameter.name}
            value={watchFormValue(parameter.name) || ''}
            onChange={(changeValue: string) => {
              fieldValueChangeHandler(parameter.name, changeValue);
            }}
            errorMessage={capitalize(
              errors[parameter.name]?.message?.toString()
            )}
            disabled={shouldConnectRepositories}
          />
        );
      })
    ) : (
      <></>
    );
  };

  const renderForm = () => {
    return (
      <form className="integration-modal-form">
        <div className="integration-modal-form-main-inputs">
          <FormInput
            label="Name"
            name="name"
            onChange={(changeValue) => {
              fieldValueChangeHandler('name', changeValue);
            }}
            value=""
            placeholder=""
            errorMessage={capitalize(errors.name?.message?.toString())}
            disabled={shouldConnectRepositories}
          />
          <InputLabelWrapper
            label="Scope"
            errorMessage={errors.enviroments?.message?.toString()}
          >
            {scopeAutocompleteList === null || scopeDataLoading ? (
              <CircularProgress size={24} />
            ) : (
              <Autocomplete
                model="scopeId"
                areOptionsLoaded
                optionList={scopeAutocompleteList}
                initialSelectedValues={selectedScopeOption}
                onChangeCallBack={(
                  model: string,
                  option: AutocompleteOption | Array<AutocompleteOption>
                ) => {
                  fieldValueChangeHandler('enviroments', []);

                  fieldValueChangeHandler(
                    'scopeId',
                    (option as AutocompleteOption).value
                  );
                }}
                classes={{
                  root: 'multi-select-field-1',
                  paper: 'multi-select-field-paper-1',
                  inputRoot: errors.enviroments
                    ? 'input-validation-error-1'
                    : '',
                }}
                single
              />
            )}
          </InputLabelWrapper>
          {renderEnvironmentsSelect()}
          {renderIsDefaultCheckbox()}

          {renderFormSectionBreaker()}
        </div>

        <div className="integration-modal-form-additional-inputs">
          {renderDynamicInputs()}
          {renderAdditionalContent ? (
            renderAdditionalContent(additionalContentVisible, () => {
              setAdditionalContentVisible(
                (prevContentVisible) => !prevContentVisible
              );
            })
          ) : (
            <></>
          )}
        </div>
      </form>
    );
  };

  const renderRepositoryTable = () => {
    if (shouldConnectRepositories) {
      return (
        <IntegrationRepositoryTable
          integrationId={authorizedConnection?.id || createConnectionData?.id}
          onSelect={(selectedIds: Array<string>, selectAll?: boolean) => {
            setSelectedRepoIds(selectedIds);
            setSelectedAllRepos(Boolean(selectAll));
          }}
          selectionIds={selectedRepoIds}
          selectedAll={false}
        />
      );
    }

    return <></>;
  };

  return (
    <div className={`integration-modal-form-container ${rootClassName}`}>
      {renderAlertBox()}
      <div className="integration-modal-content">
        <div className="integration-modal-content-body">
          {renderForm()}
          {renderRepositoryTable()}
        </div>
        <div className="integration-modal-content-footer">
          {renderButtonRow()}
        </div>
      </div>
    </div>
  );
};

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import CardWrapper from 'Common/components/CardWrapper';
import { FunctionComponent } from 'react';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { ActionListDetailsProps } from '../ActionListDetails/ActionListDetails';

export interface TicketActionListDetailsProps extends ActionListDetailsProps {}

export const TicketActionListDetails: FunctionComponent<any> = (item: any) => {
  if (item === undefined || item?.length === 0) {
    return <></>;
  }

  const renderTitles = (titles: string[]) => {
    return titles.map((title: string, index: number) => (
      <Typography key={index} variant="inherit" className="body-1 item-title">
        {title}
      </Typography>
    ));
  };

  return (
    <CardWrapper
      className={`ticket-item-details ${
        item.isSuccess ? 'success-item' : 'failed-item'
      }`}
    >
      {item.isSuccess && (
        <Grid container display={'flex'} justifyContent="space-between">
          <Grid item xs={10}>
            {renderTitles(item.title)}
          </Grid>

          {item?.link && (
            <Grid item xs={2} className="view-ticket-link">
              <Link
                target={'_blank'}
                display="flex"
                alignItems={'center'}
                component="a"
                href={item?.link}
                variant="body1"
                underline="none"
              >
                <OpusSvgIcon type={SVG_ICON_TYPES.TICKET_ICON} />
                <Typography className="body-1" variant="inherit">
                  <FormattedMessage
                    id="findings.ticket.viewTicket"
                    defaultMessage="View Ticket"
                  />
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      )}
      {!item.isSuccess && (
        <Accordion className="failed-items-accordion">
          <AccordionSummary
            expandIcon={<OpusSvgIcon type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid item>{renderTitles(item.title)}</Grid>
          </AccordionSummary>
          <AccordionDetails>
            <CardWrapper className="failed-item-message">
              <Typography variant="inherit" className="body-1">
                {item.error?.error || item.error}
              </Typography>
            </CardWrapper>
          </AccordionDetails>
        </Accordion>
      )}
    </CardWrapper>
  );
};

import { Box, Stack } from '@mui/material';
import { ActiveCampaignDashboardSection } from '../ActiveCampaignDashboardSection';
import SectionBreaker from '../Dashboard/SectionBreaker';
import OperationalDashboardSection from '../OperationalDashboardSection';
import OperationalOverview from '../OperationalOverview/OperationalOverview';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { FunctionComponent } from 'react';

interface OperationalContentProps extends BaseComponentProps {
  scopeData?: Array<OrganizationNode>;
  groupData?: Array<OrganizationNode>;
}

export const OperationalContent: FunctionComponent<OperationalContentProps> = ({
  scopeData,
  groupData,
}) => {
  return (
    <div className="operational-content-container">
      <OperationalDashboardSection />
      <SectionBreaker />
      <OperationalOverview scopeData={scopeData} groupData={groupData} />
      <SectionBreaker />
      <ActiveCampaignDashboardSection />
    </div>
  );
};

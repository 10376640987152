import { Box, ButtonBase } from '@mui/material';
import { NodeProps } from 'WorkflowBuilder/interfaces/node';
import useCommonDispatch from 'Common/utils/use-dispatch';
import { setAfterTool } from 'WorkflowBuilder/store';
import React, { MouseEvent, useState } from 'react';
import { Handle, Position, useStoreApi } from 'react-flow-renderer';
import { ReactComponent as PalePlusButtonIcon } from '../../icons/palePlusButtonIcon.svg';
import StepControlPopover from '../StepControlPopover';
import DiagramPlusButton from '../PlusButton/PlusButton';
import FormattedMessage from 'shared/components/FormattedMessage';

interface Props {
  data: NodeProps;
}

export function PlusButtonNode({ data }: Props) {
  const store = useStoreApi();

  const dispatch = useCommonDispatch();
  const { seq } = data;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  function handleClose() {
    setAnchorEl(null);
  }

  function handlePlusClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleAddToolClick() {
    handleClose();
    dispatch(setAfterTool(seq));
  }

  function handleAddCrossoard() {
    handleClose();

    const nodes: Array<any> = Array.from(store.getState().nodeInternals).map(
      ([, node]) => node
    );

    const previousNode = nodes.find((node) => {
      return node.id === String(seq);
    });
    data.onAddCrossroad && data.onAddCrossroad(previousNode);
  }

  return (
    <Box position="relative">
      <React.Fragment>
        <Handle
          type="target"
          position={Position.Top}
          style={{
            visibility: 'hidden',
            top: '0.5rem',
          }}
        />
        {data?.seq > 1 ? (
          <DiagramPlusButton
            readonly={data.readonly}
            onClick={handlePlusClick}
          />
        ) : (
          <ButtonBase onClick={handlePlusClick}>
            <PalePlusButtonIcon width={45} height={45} />
            <b>
              <FormattedMessage
                id="flows.details.startANewFlow"
                defaultMessage="Start a new flow"
              />
            </b>
          </ButtonBase>
        )}
        <StepControlPopover
          open={open}
          handleClose={handleClose}
          anchorEl={anchorEl}
          handleCrossroadClick={handleAddCrossoard}
          handleToolClick={handleAddToolClick}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            visibility: 'hidden',
          }}
        />
      </React.Fragment>
    </Box>
  );
}

import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CriticalityDataHandler,
  CriticalityStyleProps,
} from 'shared/handlers/criticality-data.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface CriticalityChipProps extends BaseComponentProps {
  criticality: string;
}

export const CriticalityChip: FunctionComponent<CriticalityChipProps> = ({
  criticality,
}) => {
  const { t: translation } = useTranslation();

  const criticalityDataHandler = useMemo(() => {
    return new CriticalityDataHandler(translation);
  }, []);

  const criticalityStyleProps = useMemo<
    CriticalityStyleProps | undefined
  >(() => {
    if (criticalityDataHandler) {
      return criticalityDataHandler.getCriticalityStyleProps(criticality);
    }
  }, [criticalityDataHandler, criticality]);

  return (
    <div
      className={`criticality-chip-container ${criticalityStyleProps?.className}`}
    >
      {criticalityStyleProps?.label}
    </div>
  );
};

import { Tooltip, Typography } from '@mui/material';
import CardWrapper from 'Common/components/CardWrapper';
import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { FunctionComponent, useMemo } from 'react';
import { NumberFormatHandler } from 'shared/handlers/number-format.handler';
import { OrderByOperator } from 'shared/models/data/common-search.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CommonSeverityChip from '../CommonSeverityChip';

interface SeverityBreakdowns extends BaseComponentProps {
  severityData: Partial<Record<FindingSeverity, number>>;
  severityToDisplay?: FindingSeverity[];
  sortOrder?: OrderByOperator;
  onChipClick?: (event: any, severity: FindingSeverity) => void;
}

const numberFormatHandler = new NumberFormatHandler();

export const SeverityBreakdown: FunctionComponent<SeverityBreakdowns> = ({
  severityData,
  severityToDisplay,
  sortOrder = OrderByOperator.DESC,
  onChipClick,
}) => {
  const severityList = useMemo(() => {
    if (severityData) {
      return Object.entries(severityData)
        .filter(([severity, totalNumber]) => {
          const severityNumber = Number(severity) as FindingSeverity;
          return (
            totalNumber !== undefined &&
            (severityToDisplay === undefined ||
              severityToDisplay.includes(severityNumber))
          );
        })
        .map(([severity, totalNumber]) => ({
          severity: Number(severity),
          totalNumber: totalNumber as number,
        }))
        .sort((a, b) =>
          sortOrder === OrderByOperator.ASC
            ? a.severity - b.severity
            : b.severity - a.severity
        );
    } else {
      return [];
    }
  }, [severityData, severityToDisplay, sortOrder]);

  return (
    <div className="severity-breakdown-container">
      {severityList.map((item, index) => (
        <CardWrapper
          key={index}
          className={`severity-container`}
          onClick={(event) => {
            onChipClick && onChipClick(event, item.severity);
          }}
        >
          <CommonSeverityChip
            displayShortLabel
            severityNumber={item.severity}
          />
          <Tooltip title={item.totalNumber}>
            <Typography variant="inherit" className="label-4">
              {numberFormatHandler.formatNumberToString(item.totalNumber)}
            </Typography>
          </Tooltip>
        </CardWrapper>
      ))}
    </div>
  );
};

export default SeverityBreakdown;

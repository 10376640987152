import { useEffect, useMemo, useState } from 'react';
import { Team } from 'Settings/interfaces/Teams';
import {
  useDeleteTeamMutation,
  useFetchTeamsMutation,
} from 'Settings/store/api';
import TeamDialogContent from './Dialog/TeamDialogContent';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { useApplicationLogosFetchQuery } from '../../../Application/store/api';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import SettingsGridColumns from '../SettingsLayout/settings-data-grid';

export default function TeamsList() {
  const { t: translation } = useTranslation();
  const [fetchTeams, { isLoading, data: teams }] = useFetchTeamsMutation();
  const [shouldIgnoreRowClick, setShouldIgnoreRowClick] =
    useState<boolean>(false);
  const { data: logos } = useApplicationLogosFetchQuery();
  const [isDialogLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTeam, setselectedTeam] = useState<Team | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [tableItems, setTableItems] = useState<Team[]>([]);
  const userPermissions = usePermissions();
  const hasTeamsReadPermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_TEAMS_READ
  );
  const hasTeamsWritePermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_TEAMS_WRITE
  );
  const [onSave, setOnSave] = useState<boolean>(false);

  useEffect(() => {
    if (teams) {
      setTableItems(teams);
    }
  }, [teams]);

  const teamColumns = useMemo(() => {
    return SettingsGridColumns.getTeamsColumns({
      t: translation,
      logos,
      useDeleteMutation: useDeleteTeamMutation,
      onDelete,
      setShouldIgnoreRowClick,
      requiredPermissions: [ApplicationPermission.SETTINGS_TEAMS_WRITE],
    });
  }, [logos]);

  function handleDialogOpen() {
    setDialogOpen(true);
  }

  function handleDialogClose(shouldReload?: boolean) {
    setDialogOpen(false);
    setselectedTeam(null);
    setOnSave(false);
    if (shouldReload === true) {
      fetchTeams();
    }
  }

  function editItem(team: Team) {
    if (!hasTeamsWritePermission) return;
    setselectedTeam(team);
    handleDialogOpen();
  }

  function onDelete(decision: boolean) {
    if (decision) fetchTeams();
  }

  if (!hasTeamsReadPermission) return <ForbiddenPage />;

  return (
    <>
      <SettingsLayout<Team>
        headerProps={{
          setTableItems: setTableItems,
          items: teams || [],
          filterPropertyName: 'name',
          onButtonClick: handleDialogOpen,
          buttonLabel: (
            <FormattedMessage
              id="settings.details.addTeam"
              defaultMessage="Add team"
            />
          ),
          buttonType: 'button',
          buttonRequiredPermissions: [
            ApplicationPermission.SETTINGS_TEAMS_WRITE,
          ],
        }}
        tableProps={{
          rowData: tableItems,
          columnDefs: teamColumns,
          isLoading: isLoading,
          onItemClick: (item) => editItem(item),
          deleteMutation: useDeleteTeamMutation,
          ignoreRowClick: shouldIgnoreRowClick,
        }}
        fetchData={fetchTeams}
      />
      <CommonDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onSave={() => {
          setOnSave(true);
        }}
        isLoading={isDialogLoading}
        saveButtonText={
          selectedTeam
            ? translation('common.save')
            : translation('common.create')
        }
        buttonRequiredPermissions={[ApplicationPermission.SETTINGS_TEAMS_WRITE]}
        loadingButtonText={selectedTeam ? 'Editing' : 'Creating'}
        closeButtonText={translation('common.cancel')}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.ADD_TEAM_ICON} />}
        title={
          selectedTeam
            ? translation(`settings.details.editTeam`)
            : translation(`settings.details.addTeam`)
        }
      >
        <TeamDialogContent
          setIsLoading={setIsLoading}
          setOnSave={setOnSave}
          onSave={onSave}
          onClose={handleDialogClose}
          team={selectedTeam}
        />
      </CommonDialog>
    </>
  );
}

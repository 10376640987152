import { CircularProgress, Skeleton } from '@mui/material';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import {
  useFetchResourceOwnersQuery,
  useFetchResourcesQuery,
  useSetPrimaryResourceOwnerMutation,
} from 'FindingDetails/store/api';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { findingOwnersTableColumns } from 'shared/fixtures/data/findings.data';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface FindingContentOwnersProps {
  codeEventId?: string;
  findingId?: string;
}

export const FindingContentOwners: FunctionComponent<
  FindingContentOwnersProps
> = ({ findingId = '', codeEventId }) => {
  const { t: translation } = useTranslation();

  const {
    isLoading: findingResourcesLoading,
    data: findingResources,
    isSuccess: findingResourcesSuccess,
  } = useFetchResourcesQuery(findingId, {
    skip: !findingId,
  });

  const [
    setPrimaryOwner,
    {
      isLoading: primaryOwnerLoading,
      isSuccess: primaryOwnerSuccess,
      reset: resetPrimaryOwner,
    },
  ] = useSetPrimaryResourceOwnerMutation();

  const {
    isFetching: ownersLoading,
    data: ownersData,
    error: ownersDataError,
    isSuccess: ownersDataSuccess,
  } = useFetchResourceOwnersQuery(
    {
      resourceId:
        codeEventId ||
        (findingResources?.length ? findingResources[0]?.id : null),
    },
    { skip: codeEventId ? false : !findingResourcesSuccess }
  );

  useEffect(() => {
    if (ownersDataSuccess) resetPrimaryOwner();
  }, [ownersDataSuccess]);

  const renderSectionBody = () => {
    if (findingResourcesLoading || ownersLoading || primaryOwnerLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={80} width={'100%'} />
        </div>
      );
    }

    if (
      ownersData === undefined ||
      ownersData?.length === 0 ||
      ownersDataError
    ) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    return (
      <div className="finding-content-grid-wrapper">
        <CommonSimpleDataGrid
          columnDefs={findingOwnersTableColumns}
          rowData={ownersData?.map((data) => ({
            ...data,
            setPrimaryOwner,
          }))}
          domLayout="autoHeight"
          isLoading={false}
          loadingOverlayComponent={() => <CircularProgress />}
          otherComponents={{
            NoDataBackdropComponent: <NoDataBackdrop />,
          }}
        />
      </div>
    );
  };

  return (
    <div className="finding-content-item finding-content-owners-container">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.USER_ICON} />}
        title={translation(`findings.sections.owners`)}
      >
        {renderSectionBody()}
      </ContentSection>
    </div>
  );
};

import { FunctionComponent } from 'react';
import { DateRangeState } from 'shared/models/data/data-filter.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import DatePicker from 'react-flatpickr';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface DateRangeInputProps extends BaseComponentProps, DateRangeState {
  label: string;
  onDateChange: (startDate: Date, endDate: Date) => void;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
}

export const DateRangeInput: FunctionComponent<DateRangeInputProps> = ({
  label,
  actionHandlers,
  onDateChange,
  endDate,
  startDate,
}) => {
  const renderTitleArea = () => {
    return (
      <div className="filter-title-container">
        {actionHandlers?.goBackHandler ? (
          <div className="filter-go-back-button">
            <div
              onClick={() => {
                actionHandlers.goBackHandler && actionHandlers.goBackHandler();
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="filter-title">{label}</div>
      </div>
    );
  };

  return (
    <div className="date-picker-container">
      {renderTitleArea()}
      <div className="date-picker-body">
        <DatePicker
          options={{
            mode: 'range',
            dateFormat: 'Y-m-d',
            inline: true,
          }}
          placeholder="Choose date range"
          value={[startDate, endDate]}
          onChange={(selectedDates: Array<Date>) => {
            if (selectedDates.length === 2)
              onDateChange(selectedDates[0], selectedDates[1]);
          }}
        />
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import {
  List as MaterialList,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Avatar,
  Typography,
  CircularProgress,
  Stack,
} from '@mui/material';

import './List.css';

export interface IListItem {
  id: string | number;
  label: string;
  subLabel?: string;
  imageMetadata?: {
    alt: string;
    src: string;
  };
  rawItem?: any;
  onClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
}

interface ListProps {
  items: Array<IListItem>;
  onSelect?: (item: IListItem) => void;
  children?: any;
  itemsLoading?: boolean;
}

export const List: React.FC<ListProps> = ({
  items,
  onSelect,
  itemsLoading,
}: ListProps) => {
  return (
    <MaterialList className="material-list-container">
      {itemsLoading ? (
        <Stack flexDirection="row" justifyContent="center">
          <CircularProgress size={25} />
        </Stack>
      ) : items?.length > 0 ? (
        items?.map((item: IListItem) => {
          return (
            <ListItemButton
              className="material-list-item-button"
              onClick={() => {
                onSelect && onSelect(item);
              }}
              key={item.id}
            >
              <ListItem key={item.id}>
                {item.imageMetadata && (
                  <ListItemIcon>
                    <img
                      height={20}
                      width="auto"
                      alt={item.imageMetadata.alt}
                      src={item.imageMetadata.src}
                    />
                  </ListItemIcon>
                )}
                <ListItemText>{item.label}</ListItemText>
              </ListItem>
            </ListItemButton>
          );
        })
      ) : (
        <Stack flexDirection="row" justifyContent="center">
          {' '}
          <h3>No data found</h3>
        </Stack>
      )}
    </MaterialList>
  );
};

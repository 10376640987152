import CommonDialog from 'Common/components/Dialogs/CommonDialog';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Typography } from '@mui/material';

interface PRCreateModalProps {
  handleModalClose: () => void;
  isOpen: boolean;
  response: any;
  error: any;
  icon?: JSX.Element;
}

export const PullRequestModal: FunctionComponent<PRCreateModalProps> = ({
  handleModalClose,
  isOpen,
  response,
  error,
  icon,
}) => {
  const { t: translation } = useTranslation();
  const title = error
    ? translation('findings.pullrequest.createFailed')
    : translation('findings.pullrequest.createSuccess');

  return (
    <CommonDialog
      classes="common-dialog-pull-request-create"
      open={isOpen}
      onClose={handleModalClose}
      title={title}
      closeButtonText={translation('common.cancel')}
      icon={icon}
    >
      {error?.data?.message && (
        <Typography className="body-1">{error.data.message}</Typography>
      )}
      {response && (
        <Box>
          <span className="body-1">
            {translation('findings.pullrequest.linkPrefix')}:{' '}
          </span>
          <Link
            target={'_blank'}
            className="item-link"
            component="a"
            href={response.url}
            variant="body1"
            underline="hover"
          >
            <span>{response.title}</span>
          </Link>
        </Box>
      )}
    </CommonDialog>
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';

const diagramApi = createApi({
  reducerPath: 'diagramApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  endpoints: (builder) => ({
    fetchApplicationById: builder.query({
      query: (applicationId: string | null) => `applications/${applicationId}`,
    }),
    fetchActionTemplateById: builder.query({
      query: (actionTemplateId: string | null) =>
        `actions/templates/${actionTemplateId}`,
    }),
    fetchConnectionByFilters: builder.query({
      query: (filters) => ({
        method: 'POST',
        url: `connections/search`,
        body: filters,
      }),
    }),
    fetchConnectionById: builder.mutation({
      query: (connectionId: string) => ({
        method: 'GET',
        url: `connections/${connectionId}`,
      }),
    }),
    saveWorkflow: builder.mutation({
      query: (params) => ({
        url: `workflows`,
        method: 'POST',
        body: params,
      }),
    }),
    updateWorkflow: builder.mutation({
      query: (params) => {
        return {
          url: `workflows/${params.id}`,
          method: 'PATCH',
          body: params.data,
        };
      },
    }),
    fetchFullWorkflowByFamilyId: builder.query({
      query: (workflowFamilyId) => `workflows/${workflowFamilyId}`,
    }),
  }),
});

export default diagramApi;

export const {
  useFetchApplicationByIdQuery,
  useFetchActionTemplateByIdQuery,
  useSaveWorkflowMutation,
  useFetchConnectionByFiltersQuery,
  useLazyFetchConnectionByFiltersQuery,
  useFetchFullWorkflowByFamilyIdQuery,
  useFetchConnectionByIdMutation,
  useUpdateWorkflowMutation,
} = diagramApi;

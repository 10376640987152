import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import InputLabelWrapper from '../InputLabelWrapper';
import { Button } from '@mui/material';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

type MultiTextColumn = {
  name: string;
};

type MultiTextRow = [MultiTextColumn, MultiTextColumn];

export type MultiTextRowValue = [string, string];

interface MultiTextConfigurationFieldProps extends BaseComponentProps {
  columns: MultiTextRow;
  onChange: (values: Array<MultiTextRowValue>) => void;
  defaultValues?: Array<MultiTextRowValue>;
  classes?: {
    inputRoot?: string;
  };
  ids?: Array<string>;
}

const multiTextConfigurationFieldDefaultValues: Array<MultiTextRowValue> = [
  ['', ''],
];

export const MultiTextConfigurationField: FunctionComponent<
  MultiTextConfigurationFieldProps
> = ({ columns, defaultValues, onChange, ids = [] }) => {
  const [rowValues, setRowValues] = useState<Array<MultiTextRowValue>>(
    defaultValues?.length
      ? defaultValues
      : multiTextConfigurationFieldDefaultValues
  );

  const onAddRow = () => {
    setRowValues((oldRowValues) => {
      return [...oldRowValues, multiTextConfigurationFieldDefaultValues[0]];
    });
  };

  const onDeleteRow = (index: number) => {
    setRowValues((oldRowValues) => {
      if (oldRowValues.length === 1) {
        return multiTextConfigurationFieldDefaultValues;
      }

      return oldRowValues.filter((rowValue, idx: number) => idx !== index);
    });
  };

  const onChangeCallback = (
    rowIndex: number,
    itemIndex: number,
    value: string
  ) => {
    setRowValues((oldRowValues) => {
      const updatedRowValues = [...oldRowValues];

      return updatedRowValues.map((rowValue, index: number) => {
        if (index === rowIndex) {
          const rowValues = rowValue.map((valueItem, index: number) => {
            if (index === itemIndex) {
              return value;
            }

            return valueItem;
          });

          return rowValues;
        }

        return rowValue;
      }) as Array<MultiTextRowValue>;
    });
  };

  useEffect(() => {
    if (onChange) {
      const filteredRowValues = rowValues.filter(
        (rowValue) => rowValue[0]?.length
      );

      onChange(filteredRowValues);
    }
  }, [rowValues]);

  return (
    <div className="multi-select-configuration">
      <div className="multi-select-configuration-row">
        <InputLabelWrapper label={columns[0].name}>
          <input
            type="text"
            className="text-field-1"
            value={rowValues[0][0]}
            onChange={(event) => {
              onChangeCallback(0, 0, event.target.value);
            }}
            id={ids[0]}
          />
        </InputLabelWrapper>

        <InputLabelWrapper label={columns[1].name}>
          <input
            type="text"
            className="text-field-1"
            value={rowValues[0][1]}
            onChange={(event) => {
              onChangeCallback(0, 1, event.target.value);
            }}
            id={ids[1]}
          />
        </InputLabelWrapper>

        <Button
          onClick={() => {
            onDeleteRow(0);
          }}
          className="base-opus-text-button multi-select-configuration-button"
          disabled={
            rowValues[0][0].length === 0 && rowValues[0][1].length === 0
          }
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
          <span>Delete</span>
        </Button>
      </div>

      {rowValues.slice(1).map((rowValue, index: number) => (
        <div className="multi-select-configuration-row">
          <input
            type="text"
            className="text-field-1"
            value={rowValues[index + 1][0]}
            onChange={(event) => {
              onChangeCallback(index + 1, 0, event.target.value);
            }}
            id={ids[0] ? `${ids[0]}-${index}` : undefined}
          />

          <input
            type="text"
            className="text-field-1"
            value={rowValues[index + 1][1]}
            onChange={(event) => {
              onChangeCallback(index + 1, 1, event.target.value);
            }}
            id={ids[1] ? `${ids[1]}-${index}` : undefined}
          />
          <Button
            onClick={() => {
              onDeleteRow(index + 1);
            }}
            className="base-opus-text-button multi-select-configuration-button"
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON} />
            <span>Delete</span>
          </Button>
        </div>
      ))}

      <div className="multi-select-configuration-button">
        <Button
          className="base-opus-text-button multi-select-configuration-button multi-select-configuration-add-button"
          onClick={onAddRow}
        >
          <OpusSvgIcon type={SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON} />
          <p>Add New</p>
        </Button>
      </div>
    </div>
  );
};

import { Button, CircularProgress } from '@mui/material';
import {
  ColumnMovedEvent,
  ColumnVisibleEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import { OrderState, OrderType } from 'Common/utils/sort';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import { FunctionComponent, useEffect, useMemo } from 'react';
import {
  getAdditionalSearchParams,
  getGridColumnsByGridType,
  getGridDuplicationState,
  getResourceGroupTableSelectionProps,
  getRiskSearchParams,
  getTagsSearchParams,
  getVulnerabilitySearchParams,
  GridSelectionProps,
  GridType,
  RiskFilterItem,
  setGridColumns,
  setResourceGroupTableSelection,
  setSearchParams,
} from 'Risk/store';
import { useSearchResouceIdGroupsMutation } from 'Risk/store/api';
import { CommonAdvancedDataGrid } from 'shared/components/CommonAdvancedDataGrid/CommonAdvancedDataGrid';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import {
  defaultHiddenResourceGroupNestedColumns,
  getAllColumnDefs,
  getRiskSearchParamsRequestDependencies,
} from 'shared/fixtures/data/risk-grid.data';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { GridSelectionHandler } from 'shared/handlers/grid-selection.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface ResourceGroupGridProps extends BaseComponentProps {
  clearFilters: () => void;
  onRowClicked?: (event: RowClickedEvent) => void;
  gridRef: any;
}
export const resourceGroupGridDefaultSort: OrderState = {
  field: 'maxRiskScore',
  type: OrderType.DESC,
};
const riskSearchParamsHandler = new FilterSearchParamsHandler();

const resourceGroupGridSelectionHandler = new GridSelectionHandler();

export const ResourceGroupGrid: FunctionComponent<ResourceGroupGridProps> = ({
  onRowClicked,
  gridRef,
}) => {
  let [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const isDuplicatedStateEnabled = useCommonSelector(getGridDuplicationState);

  const orderParams = useMemo<OrderState | null>(() => {
    try {
      if (urlSearchParams.order) {
        const parsedOrderParams = JSON.parse(
          urlSearchParams.order as string
        ) as OrderState;

        return parsedOrderParams;
      }

      return null;
    } catch (err) {
      return null;
    }
  }, [urlSearchParams.order]);

  const searchParams = useCommonSelector(getRiskSearchParams);

  const resourceGroupGridColumns = useCommonSelector(
    getGridColumnsByGridType(GridType.ResourceName)
  );

  const resourceGroupNestedColumns = useCommonSelector(
    getGridColumnsByGridType(
      `${GridType.ResourceName}_nested` as `${GridType.ResourceName}_nested`
    )
  );

  const resourceGroupGridSelectionProps = useCommonSelector(
    getResourceGroupTableSelectionProps
  );

  const additionalSearchParams = useCommonSelector(getAdditionalSearchParams);
  const vulnerabilitySearchParams = useCommonSelector(
    getVulnerabilitySearchParams
  );
  const tagsSearchParams = useCommonSelector(getTagsSearchParams);

  const dispatch = useCommonDispatch();

  const [
    searchRiskGroups,
    { data: riskResourcePayload, isLoading: isLoading },
  ] = useSearchResouceIdGroupsMutation();

  const titleValue = useMemo<string>(() => {
    const titleRiskFilterItem = searchParams?.filter
      ?.resourceName as RiskFilterItem;
    return titleRiskFilterItem?.value;
  }, [searchParams]);

  useEffect(() => {
    searchRiskGroups({
      ...searchParams,
      filter: {
        ...additionalSearchParams,
        ...searchParams?.filter,
      },
      order: orderParams,
      removeDuplications: isDuplicatedStateEnabled,
      vulnerabilityFilter: vulnerabilitySearchParams,
      tagFilter: tagsSearchParams,
    });
  }, [
    ...getRiskSearchParamsRequestDependencies(additionalSearchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    searchParams?.skip,
    searchParams?.take,
    titleValue,
    orderParams,
    isDuplicatedStateEnabled,
  ]);

  useEffect(() => {
    if (riskResourcePayload) {
      const updatedResourceGroupGridSelectionProps =
        resourceGroupGridSelectionHandler.handleGridSelectionStateOnSearchParamsChange(
          {
            responsePayload: riskResourcePayload,
            currentGridSelectionProps: resourceGroupGridSelectionProps,
          }
        );

      dispatch(
        setResourceGroupTableSelection(updatedResourceGroupGridSelectionProps)
      );
    }
  }, [riskResourcePayload]);

  const resetPageNumber = () => {
    if (searchParams.skip !== 0)
      dispatch(
        setSearchParams({
          ...searchParams,
          skip: 0,
        })
      );
  };

  useEffect(() => {
    resetPageNumber();
  }, [
    ...getRiskSearchParamsRequestDependencies(additionalSearchParams),
    ...getRiskSearchParamsRequestDependencies(vulnerabilitySearchParams),
    ...getRiskSearchParamsRequestDependencies({
      tags: tagsSearchParams,
    }),
    orderParams,
    isDuplicatedStateEnabled,
    searchParams?.filter?.title,
  ]);

  const onPageChange = (pageNumber: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        skip: searchParams.take * (pageNumber - 1),
      })
    );
  };

  const onPageSizeChange = (pageSize: number) => {
    dispatch(
      setSearchParams({
        ...searchParams,
        take: pageSize,
        skip: 0,
      })
    );
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    const existingParams = riskSearchParamsHandler.setSearchParamsForKeys(
      urlSearchParams,
      ['activeTabId', 'filter', 'searchKeyword']
    );
    if (columnOrderPayload.length) {
      setUrlSearchParams({
        ...existingParams,
        order: JSON.stringify({
          field: columnOrderPayload[0].field,
          type: columnOrderPayload[0].type,
        }),
      });
    } else {
      delete existingParams.order;

      setUrlSearchParams({
        ...existingParams,
      });
    }
  };

  const onHandleChildSelectionChange = (
    selection: string,
    parentId: string,
    selectionProps: GridSelectionProps
  ) => {
    const updatedResourceGroupGridSelectionProps =
      resourceGroupGridSelectionHandler.handleNestedGridRowSelection({
        currentGridSelectionProps: selectionProps,
        selectionId: parentId,
        nestedSelectionId: selection,
      });

    dispatch(
      setResourceGroupTableSelection(updatedResourceGroupGridSelectionProps)
    );
  };

  const populateSelectionValues = (
    parentId: string,
    selectionValues: Array<string>,
    payload: any,
    selectionProps: GridSelectionProps
  ) => {
    const updatedResourceGroupGridSelectionProps =
      resourceGroupGridSelectionHandler.handleNestedGridSelectionStateOnSearchParamsChange(
        {
          currentGridSelectionProps: selectionProps,
          selectionId: parentId,
          responsePayload: payload,
        }
      );

    dispatch(
      setResourceGroupTableSelection(updatedResourceGroupGridSelectionProps)
    );
  };

  return (
    <CommonAdvancedDataGrid
      columnDefs={resourceGroupGridColumns}
      isLoading={isLoading}
      rowData={riskResourcePayload?.data}
      rowSelection="multiple"
      defaultColDef={{
        resizable: true,
        sortable: true,
      }}
      rowIdProperty="aggregatedValue"
      gridRef={gridRef}
      getRowId={(params) => params.data.aggregatedValue}
      suppressRowClickSelection
      otherComponents={{
        NoDataBackdropComponent: (
          <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
        ),
      }}
      loadingOverlayComponent={() => <CircularProgress />}
      onSelect={(selectionIds: Array<string>) => {
        const updatedResourceGroupSelectionProps =
          resourceGroupGridSelectionHandler.handleGridSelectionChanges({
            currentGridSelectionProps: resourceGroupGridSelectionProps,
            responsePayload: riskResourcePayload,
            selectionIds: selectionIds as Array<string>,
          });

        dispatch(
          setResourceGroupTableSelection(updatedResourceGroupSelectionProps)
        );
      }}
      onColumnMoved={(event: ColumnMovedEvent) => {
        if (event.finished) {
          const updatedColumnDefs = event.api.getColumnDefs();

          dispatch(
            setGridColumns({
              gridType: GridType.ResourceName,
              columns: getAllColumnDefs(
                resourceGroupGridColumns,
                updatedColumnDefs || []
              ),
            })
          );
        }
      }}
      paginationProps={{
        pageSize: searchParams.take,
        currentPage: searchParams.skip
          ? searchParams.skip / searchParams.take + 1
          : 1,
        onPageChange,
        onPageSizeChange,
        totalItems: riskResourcePayload?.totalItems || 0,
      }}
      sortModel={orderParams as OrderState}
      selectionModel={
        resourceGroupGridSelectionProps.selectedChildren as Array<string>
      }
      onSort={onHandleColumnOrderChange}
      nestedTableProps={{
        columnDefs: resourceGroupNestedColumns,
        visibilityControlProps: {
          enableVisibilityControls: true,
          columns: defaultHiddenResourceGroupNestedColumns,
        },
        dataMutation: useFetchFindingsWithResourceMutation,
        getRowId: (params) => params.data.findingId,
        populateSelectionValues,
        gridSelectionPropsSelector: getResourceGroupTableSelectionProps,
        onHandleChildSelectionChange,
        filterParams: {
          ...searchParams.filter,
          ...additionalSearchParams,
        },
        additionalMutationParameters: {
          vulnerabilityFilter: vulnerabilitySearchParams,
        },
        loadingOverlayComponent: () => <CircularProgress />,
        onRowClicked: (event: RowClickedEvent) => {
          onRowClicked && onRowClicked(event);
        },
        onColumnMoved: (event: ColumnMovedEvent) => {
          if (event.finished) {
            const updatedColumnDefs = event.api.getColumnDefs();

            dispatch(
              setGridColumns({
                gridType:
                  `${GridType.ResourceName}_nested` as `${GridType.ResourceName}_nested`,
                columns: getAllColumnDefs(
                  resourceGroupNestedColumns,
                  updatedColumnDefs || []
                ),
              })
            );
          }
        },
        gridDuplicationState: getGridDuplicationState,
        onColumnVisible: (event: ColumnVisibleEvent) => {
          const updatedColumnDefs = event.api.getColumnDefs();

          dispatch(
            setGridColumns({
              gridType:
                `${GridType.ResourceName}_nested` as `${GridType.ResourceName}_nested`,
              columns: getAllColumnDefs(
                resourceGroupNestedColumns,
                updatedColumnDefs || []
              ),
            })
          );
        },
      }}
    />
  );
};

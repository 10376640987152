import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import styles from './GenericDialog.module.css';
import CommonIconMui from '../../IconMui';
import * as React from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';

interface GenericDialogProps {
  title?: string;
  symbol?: JSX.Element;
  open?: boolean;
  onClose?: () => void;
  children: ReactJSXElement;
  sx?: object;
  description?: ReactJSXElement;
}

export default function GenericDialog({
  title,
  symbol,
  open = false,
  onClose = () => {},
  children,
  sx: extraStyle,
  description,
}: GenericDialogProps) {
  const { palette } = useTheme();

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: 2,
          minWidth: 644,
          ...extraStyle,
        },
      }}
    >
      <Box p={3}>
        <Stack
          direction="row"
          justifyContent={title ? 'space-between' : 'flex-end'}
          alignItems="center"
        >
          {(title || symbol) && (
            <Stack direction="row" alignItems="center" spacing={1}>
              {symbol}
              {title && (
                <Typography className={styles.dialogTitle}>{title}</Typography>
              )}
            </Stack>
          )}
          <IconButton
            style={{ width: '1.5rem', height: '1.5rem' }}
            onClick={onClose}
          >
            <CommonIconMui
              icon="close"
              color={palette.text.disabled}
              size={24}
            />
          </IconButton>
        </Stack>
        {description && (
          <Stack direction="row" alignItems="center" m={1}>
            {description}
          </Stack>
        )}
        <Box component={DialogContent} maxHeight="75vh" mt={3} p={0}>
          {children}
        </Box>
      </Box>
    </Dialog>
  );
}

import { CircularProgress } from '@mui/material';
import CommonPagination from 'Common/components/Pagination';
import { OrderState, OrderType } from 'Common/utils/sort';
import { useSearchFlowHistoryMutation } from 'Flow/store/api';
import {
  AgGridEvent,
  GetRowIdParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import FindingCodeEventDrawerComplex from 'shared/components/FindingCodeEventDrawerComplex/FindingCodeEventDrawerComplex';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { SingleFindingTab } from 'shared/enums/findings.enum';
import { workflowHistoryGridColumns } from 'shared/fixtures/data/workflows.data';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface HistoryTableProps extends BaseComponentProps {
  familyId: string;
}

export const HistoryTable: FunctionComponent<HistoryTableProps> = ({
  familyId,
}) => {
  const { t: translation } = useTranslation();

  let [, , getUrlSearchParams] = useQueryParams();

  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const gridRef = useRef<AgGridEvent | null>(null);

  const [searchFlowHistory, { data: flowHistoryPayload, isLoading }] =
    useSearchFlowHistoryMutation();

  const [searchKeyword, setSearchKeyword] = useState<string>('');

  const [orderParams, setOrderParams] = useState<OrderState>({
    type: OrderType.DESC,
    field: 'createdAt',
  });

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);

  const searchFlowHistoryHandler = () => {
    searchFlowHistory({
      data: {
        order: orderParams,
        take: pageSize,
        skip: currentPage * pageSize,
      },
      workflowDefFamilyId: familyId,
    });
  };

  useEffect(() => {
    searchFlowHistoryHandler();
  }, [orderParams, currentPage, pageSize, searchKeyword, familyId]);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber - 1);
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  const onSearchKeywordChange = (searchKeyword: string) => {
    setSearchKeyword(searchKeyword);
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    setOrderParams(columnOrderPayload[0]);
  };

  const rowClickHandler = (event: RowClickedEvent) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );

    updatedReactiveSearchParams.set('openFindingId', event.data.findingId);
    updatedReactiveSearchParams.set(
      'findingActiveTabId',
      SingleFindingTab.WORKFLOW
    );

    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  return (
    <div className="history-flows-page-container">
      <div className="history-flows-page-body">
        <div className="history-flows-page-body-table">
          <CommonSimpleDataGrid
            gridRef={gridRef}
            columnDefs={workflowHistoryGridColumns}
            rowData={flowHistoryPayload?.data}
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            otherComponents={{
              NoDataBackdropComponent: (
                <NoDataBackdrop descriptionText="Try relaxing your search criteria" />
              ),
            }}
            loadingOverlayComponent={() => <CircularProgress />}
            isLoading={isLoading}
            getRowId={(row: GetRowIdParams) => {
              return row.data.id;
            }}
            onSort={onHandleColumnOrderChange}
            onRowClicked={rowClickHandler}
          />
          <CommonPagination
            totalItems={flowHistoryPayload?.totalItems || 0}
            currentPage={currentPage + 1}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
          />

          <FindingCodeEventDrawerComplex />
        </div>
      </div>
    </div>
  );
};

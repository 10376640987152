import { useCallback, useEffect, useMemo, useState } from 'react';

export interface SearchParams {
  [key: string]: string;
}

export type UpdateQueryParams = (params: SearchParams) => void;
export type CallbackFunction = (params: any) => void;

export function useQueryParams(
  callback?: CallbackFunction
): [SearchParams, UpdateQueryParams, () => SearchParams] {
  const getSearchParams = useMemo(() => {
    return () => {
      const searchParams = new URLSearchParams(window.location.search);
      const searchParamsObj: SearchParams = {};
      searchParams.forEach((value, key) => {
        searchParamsObj[key] = value;
      });
      return searchParamsObj;
    };
  }, []);

  const [searchParams, setSearchParams] = useState<SearchParams>(
    getSearchParams()
  );

  useEffect(() => {
    if (callback) {
      callback(searchParams);
    }
  }, [searchParams, callback]);

  const updateQueryParams = useCallback((params: SearchParams) => {
    const currentSearchParams = new URLSearchParams();
    for (const key in params) {
      if (params[key] !== undefined) {
        currentSearchParams.set(key, params[key]);
      } else {
        currentSearchParams.delete(key);
      }
    }
    const newSearch = currentSearchParams.toString();
    const newPathname = window.location.pathname;
    window.history.pushState(null, '', `${newPathname}?${newSearch}`);

    setSearchParams(params);
  }, []);

  return [searchParams, updateQueryParams, getSearchParams];
}

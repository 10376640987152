import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';

const automationManagementApi = createApi({
  reducerPath: 'automationManagementApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  endpoints: (builder) => ({
    fetchRemediationRules: builder.query({
      query: (refresh) => 'automations/remediation/rules',
    }),
    fetchRemediationRulesCount: builder.query({
      query: () => 'automations/remediation/rules/count',
    }),
    searchRemediationRules: builder.mutation({
      query: (params) => ({
        url: 'automations/remediation/rules/search',
        method: 'POST',
        body: params,
      }),
    }),
    bulkPostRules: builder.mutation({
      query: (params) => ({
        url: 'automations/remediation/rules',
        method: 'POST',
        body: params,
      }),
    }),
    fetchTriggersForRemediationRules: builder.mutation({
      query: ({ body }) => ({
        method: 'POST',
        url: `automations/remediation/rules/workflows/search`,
        body,
      }),
    }),
  }),
});

export default automationManagementApi;

export const {
  useFetchRemediationRulesQuery,
  useBulkPostRulesMutation,
  useFetchTriggersForRemediationRulesMutation,
  useSearchRemediationRulesMutation,
  useFetchRemediationRulesCountQuery,
} = automationManagementApi;

import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import { AutocompleteOption } from 'FindingDetails/store/api';
import {
  FilterItem,
  FilterOption,
} from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { TypeFilter } from 'shared/enums/campaigns.enum';

export enum ComparativeFindingFilter {
  'accumulative_open_findings_by_sla_status' = 'By Sla',
  'accumulative_open_findings_by_severity' = 'By Severity',
  'accumulative_open_findings_by_sla_breach_age' = 'SLA Breach Age',
}
export const findingFilterToWidgetKey: Record<
  ComparativeFindingFilter,
  string
> = {
  [ComparativeFindingFilter.accumulative_open_findings_by_sla_status]:
    'comparative_finding_widget_by_sla',
  [ComparativeFindingFilter.accumulative_open_findings_by_severity]:
    'comparative_finding_widget_by_severity',
  [ComparativeFindingFilter.accumulative_open_findings_by_sla_breach_age]:
    'comparative_finding_widget_by_sla_breach_age',
} as const;

export enum ComparativeFilterKey {
  SERVICE = 'service',
  GROUP = 'group',
  SCOPE = 'scope',
}

export enum ComparativeFilter {
  'service' = 'Per Service',
  'group' = 'Per Group',
  'scope' = 'Per Scope',
}

export const comparativeFindingsFilterOptions: Array<AutocompleteOption> =
  Object.keys(ComparativeFindingFilter).map((key) => ({
    value: key,
    label:
      ComparativeFindingFilter[key as keyof typeof ComparativeFindingFilter],
  }));

export const comparativeFilterOptions: Array<AutocompleteOption> = Object.keys(
  ComparativeFilter
).map((key) => ({
  value: key as ComparativeFilter,
  label: ComparativeFilter[key as keyof typeof ComparativeFilter],
}));

export interface DashboardFilterExpandedBusinessUnits {
  businessIds?: string[];
}
export interface ExtendedFilterOption
  extends FilterOption,
    DashboardFilterExpandedBusinessUnits {}
export const comparativeDashboardFilter: Array<
  FilterItem<ExtendedFilterOption>
> = [
  {
    id: DashboardFilterType.SCOPE,
    name: 'Scope',
    field: 'scope',
    type: TypeFilter.SINGLE_SELECT,
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    keepExistingOptions: true,
    loaded: false,
  },
  {
    id: DashboardFilterType.GROUP,
    name: 'Group',
    field: 'logical',
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    keepExistingOptions: true,
    type: TypeFilter.SINGLE_SELECT,
    loaded: false,
  },
  {
    id: DashboardFilterType.SERVICES,
    name: 'Services',
    field: 'data',
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    keepExistingOptions: true,
    type: TypeFilter.MULTI_SELECT,
    loaded: false,
  },
  {
    id: DashboardFilterType.FINDING_TYPE,
    name: 'Domain',
    field: 'findingType',
    items: [
      {
        id: DashboardFilterType.ALL,
        name: 'All',
        checked: true,
      },
    ],
    keepExistingOptions: true,
    type: TypeFilter.MULTI_SELECT,
    loaded: false,
  },
];

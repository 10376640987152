import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface IconData {
  count: number;
  iconType: SVG_ICON_TYPES;
  id?: string;
}

const WorkspacesCountIcon = ({ count, iconType, id }: IconData) => (
  <div className="icon" id={id || undefined}>
    <OpusSvgIcon type={iconType} />
    <p className="label-4">{count}</p>
  </div>
);

export default WorkspacesCountIcon;

import { GridSelectionProps } from 'Risk/store';
import { CampaignCreateFormBusinessPriority } from 'shared/enums/campaigns.enum';
import * as yup from 'yup';

export interface CampaignCreateFormValues {
  name: string;
  startTime: Array<string> | string;
  endDate: Array<string> | string;
  assignees: Array<string>;
  businessPriority: CampaignCreateFormBusinessPriority | string;
  dateRange: string;
}

export type CampaignCreateFormErrors = Record<
  keyof CampaignCreateFormValues,
  string | null
>;

export enum CampaignType {
  LIVE = 'live',
  STATIC = 'static',
}

export enum CampaignStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  COMPLETED = 'Completed',
  CREATING = 'Creating',
}

export enum CampaignCriticality {
  LBI = 'LBI',
  MBI = 'MBI',
  HBI = 'HBI',
  CBI = 'CBI',
}

export interface CampaignOwner {
  id: string;
  name: string;
  email: string;
  profilePictureUrl: string;
}

export interface CampaignModel {
  id: string;
  name: string;
  remainingDays: number;
  ownerUser: CampaignOwner;
  priority: CampaignCriticality;
  status: CampaignStatus;
  type: CampaignType;
  appliedFilter: any;
  activeFindings: number;
  totalFindings: number;
  completionRate: number;
}

export interface SingleCampaignModel extends CampaignModel {
  startTime: Date;
  endDate: Date;
  stakeholders: { [key: string]: any }[];
}
export interface CampaignCreateModel {
  name: string;
  duration: string;
  startTime: Date;
  endDate: Date;
  ownerUserId: string;
  stakeholders: string[];
  priority: CampaignCriticality;
  type: CampaignType;
  status?: CampaignStatus;
  appliedFilter?: any;
  gridSelectionProps?: GridSelectionProps;
  findingIds?: Array<string>;
}

export const campaignFormSchema = yup.object().shape({
  name: yup.string().required(),
  duration: yup.object().required(),
  startTime: yup.string(),
  endDate: yup.string(),
  ownerUserId: yup.object().required(),
  stakeholders: yup.array().min(1),
  priority: yup.object().required(),
  type: yup.object().required(),
});

import { IaCNode, IaCGeneralDetails } from 'IaCFindings/interfaces/IaCFinding';
import { ReactElement } from 'react';

export interface IaCWorkflowProps {
  flowData: IaCNode[];
}

export interface IaCGeneralDetailsProps {
  data: IaCGeneralDetails;
}

export interface CodePreviewProps {
  eventId: string;
  label: ReactElement;
  fileName?: string;
  code: string;
  copyButton?: boolean;
  theme?: CodeThemeTypes;
}

export enum CodeExtensionsTypes {
  YAML,
  JSON,
}

export enum CodeThemeTypes {
  LIGHT = 'light',
  DARK = 'dark',
  LIGHT_GRAY = 'lightGray',
  BBEDIT = 'bbedit',
  CUSTOM = 'custom',
}

import { RowClickedEvent } from 'ag-grid-community';
import { FunctionComponent } from 'react';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import Cloud2CodeContent from '../Cloud2CodeContent';

export interface Cloud2CodeDrawerProps {
  open: boolean;
  handleClose: () => void;
  onFindingClick: (event: RowClickedEvent) => void;
}

export const Cloud2CodeDrawer: FunctionComponent<Cloud2CodeDrawerProps> = ({
  open,
  handleClose,
  onFindingClick,
}) => {
  return (
    <>
      <PopoverWrapper
        classes="cloud2code-popover"
        showPopover={open}
        handleClose={handleClose}
      >
        <Cloud2CodeContent onFindingClick={onFindingClick} />
      </PopoverWrapper>
    </>
  );
};

import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardChartCardProps extends BaseComponentProps {
  title: string;
  className?: string;
}

export const DashboardChartCard: FunctionComponent<DashboardChartCardProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <div className={`dashboard-chart-card-container ${className || ''}`}>
      <div className="dashboard-chart-card-title">
        <Typography variant="inherit" className="label-2">
          {title}
        </Typography>
      </div>
      <div className="dashboard-chart-card-body">{children}</div>
    </div>
  );
};

import { FindingSeverity } from 'FindingDetails/interfaces/severity';
import { AutocompleteOption } from 'FindingDetails/store/api';

export interface Sla {
  createdAt: string;
  updatedAt: string;
  tenantId: string;
  id: string;
  severity: FindingSeverity;
  severityLabel: string;
  value: number;
}

export interface SlaCreate {
  severity: FindingSeverity;
  value: number;
}

export interface SlaUpdate {
  id: string;
  item: SlaCreate;
}
export enum SlaType {
  FINDING_EXTERNAL_CREATE = 'finding-external-create',
  TICKET_CREATE = 'ticket-create',
}

export interface SlaSettings {
  type: SlaType;
}
export const SlaStartAtOptions: AutocompleteOption[] = [
  {
    value: SlaType.FINDING_EXTERNAL_CREATE,
    label: 'Finding Detection',
  },
  {
    value: SlaType.TICKET_CREATE,
    label: 'Ticket Creation',
  },
];

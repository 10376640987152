import { AvailableUser } from 'FindingDetails/interfaces/Users';
import { BusinessPriority } from 'shared/components/BusinessPriorityChip/businessPriority';

export interface ProjectInfoData {
  id: string;
  name: string;
  startTime: Date;
  endDate: Date;
  businessPriority: BusinessPriority;
  assignees: AvailableUser[];
  status: string;
}

export interface ProjectInfoWidgetProps {
  projectData?: ProjectInfoData;
}

export enum CampaignStatus {
  NEW = 'New',
  ACTIVE = 'ACTIVE',
  FINISHED = 'Finished',
  ARCHIVED = 'Archived',
}

export enum CampaignState {
  ACTIVE = 'Active',
  NON_ACTIVE = 'Non Active',
}

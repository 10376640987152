export interface CriticalityStyleProps {
  label: string;
  className: string;
}

export class CriticalityDataHandler {
  private criticalityLabelToClassName: { [key: string]: string } = {};

  constructor(private translation: any) {
    this.criticalityLabelToClassName = {
      [translation(`global.severity.lbi`)]: 'criticality-chip-low',
      [translation(`global.severity.mbi`)]: 'criticality-chip-medium',
      [translation(`global.severity.hbi`)]: 'criticality-chip-high',
    };
  }

  getCriticalityStyleProps(criticality: string): CriticalityStyleProps {
    let label = this.translation(
      `global.severity.${criticality.toLocaleLowerCase()}`
    );
    return {
      label,
      className: this.criticalityLabelToClassName[label],
    };
  }
}

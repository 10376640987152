import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { OrganizationConnectionItem } from 'Organization/interfaces/OrganizationConnectionItem.interface';
import {
  OrganizationNewNode,
  OrganizationUpdateNode,
} from 'Organization/interfaces/OrganizationNewNode.interface';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';

const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: ['organization-structures'],
  endpoints: (builder) => ({
    getOrganizationTree: builder.query<OrganizationNode, void>({
      query: () => ({
        url: `organization-structures/getOrgTree`,
        method: 'GET',
      }),
      providesTags: ['organization-structures'],
    }),
    getOrgApplications: builder.query<OrganizationConnectionItem[], void>({
      query: () => ({
        url: `organization-structures/applications`,
        method: 'GET',
      }),
    }),

    addOrganizationNode: builder.mutation<any, OrganizationNewNode>({
      query: (body) => ({
        url: `organization-structures/addNode`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['organization-structures'],
    }),
    updateOrganizationNode: builder.mutation<any, OrganizationUpdateNode>({
      query: (payload) => ({
        url: `organization-structures/node/${payload.nodeId}`,
        method: 'PATCH',
        body: payload.body,
      }),
      invalidatesTags: ['organization-structures'],
    }),
    deleteOrganizationNode: builder.mutation<any, void>({
      query: (id) => ({
        url: `organization-structures/deleteNode/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['organization-structures'],
    }),
    getOrganizationNodeByType: builder.mutation<any, any>({
      query: (type) => ({
        url: `organization-structures/nodes/${type}/getAll`,
        method: 'POST',
      }),
    }),
  }),
});

export default organizationApi;

export const {
  useGetOrganizationTreeQuery,
  useGetOrgApplicationsQuery,
  useAddOrganizationNodeMutation,
  useDeleteOrganizationNodeMutation,
  useGetOrganizationNodeByTypeMutation,
  useUpdateOrganizationNodeMutation,
  useLazyGetOrganizationTreeQuery,
} = organizationApi;

import { Chart, Tooltip } from 'chart.js';
import { WidgetService } from './widget-service';

export class ExternalToolTip {
  static getOrCreateTooltip = (chart: {
    canvas: {
      parentNode: {
        querySelector: (arg0: string) => any;
        appendChild: (arg0: any) => void;
      };
    };
  }) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('chartjs-tooltip');
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);

    return tooltipEl;
  };

  static externalTooltipHandler(context: any) {
    const { chart, tooltip } = context;
    const tooltipEl = ExternalToolTip.getOrCreateTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    if (tooltip.body) {
      const mouseX = tooltip.caretX;
      const mouseY = tooltip.caretY;

      const chartCanvas = chart.canvas;
      const chartBounds = chartCanvas.getBoundingClientRect();

      const tooltipWidth = tooltipEl.offsetWidth;
      const tooltipHeight = tooltipEl.offsetHeight;

      const spaceLeft = mouseX - tooltipWidth / 2;
      const spaceRight = chartBounds.width - mouseX - tooltipWidth / 2;
      const spaceAbove = mouseY - tooltipHeight - 10;

      let tooltipX, tooltipY;

      if (spaceRight > tooltipWidth && spaceLeft > tooltipWidth) {
        tooltipX =
          spaceLeft > spaceRight
            ? mouseX - tooltipWidth / 2
            : mouseX + tooltipWidth / 2;
        tooltipEl.style.left = tooltipX + 'px';
        tooltipEl.style.top = mouseY + 'px';
      } else if (spaceRight < tooltipWidth && spaceLeft < tooltipWidth) {
        tooltipY = spaceAbove > 0 ? mouseY - tooltipHeight - 10 : mouseY + 10;
        tooltipEl.style.left = mouseX - tooltipWidth / 2 + 'px';
        tooltipEl.style.top = tooltipY + 'px';
      } else if (spaceRight < spaceLeft) {
        tooltipX = mouseX + tooltipWidth / 2;
        tooltipEl.style.left = tooltipX + 'px';
        tooltipEl.style.top = mouseY + 'px';
      } else {
        tooltipX = mouseX - tooltipWidth - 10;
        tooltipEl.style.left = tooltipX + 'px';
        tooltipEl.style.top = mouseY + 'px';
      }

      const caret = document.createElement('div');
      caret.className = 'tooltip-caret';

      caret.style.position = 'absolute';
      caret.style.top = '-5px';
      caret.style.left = 'calc(50% - 5px)';

      tooltipEl.appendChild(caret);
      const bodyLines = tooltip.body.map((b: { lines: any }) => b.lines);

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body: string, i: string | number) => {
        const colors = tooltip.labelColors[i];
        const value = tooltip.dataPoints[i].parsed.y;
        const label = tooltip.dataPoints[i].dataset.label;

        const span = document.createElement('span');
        span.className = 'tooltip-label-color';
        span.style.background = colors.backgroundColor;
        span.style.boxShadow = `0 0px 0px 2px rgba(${WidgetService.hexToRgb(
          colors.backgroundColor
        )}, 0.3)`;
        span.style.borderRadius = '50%';
        span.style.marginRight = '10px';
        span.style.height = '8px';
        span.style.width = '8px';
        span.style.display = 'inline-block';

        const labelEl = document.createElement('span');
        labelEl.textContent = label;
        labelEl.className = 'tooltip-label';
        labelEl.style.fontFamily = 'HEX Franklin v0.2';
        labelEl.style.fontSize = '13px';
        labelEl.style.fontWeight = '400';
        labelEl.style.marginLeft = '3px';

        const valueEl = document.createElement('span');
        valueEl.textContent = value;
        valueEl.className = 'tooltip-label-value';
        valueEl.style.fontFamily = 'HEX Franklin v0.2';
        valueEl.style.fontSize = '13px';
        valueEl.style.fontWeight = '700';

        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = '0';

        const td = document.createElement('td');
        td.style.borderWidth = '0';

        td.appendChild(span);
        td.appendChild(valueEl);
        td.appendChild(labelEl);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      while (tableRoot?.firstChild) {
        tableRoot.firstChild.remove();
      }

      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  }
  static getOrCreateStaticTooltip = (chart: {
    canvas: {
      parentNode: {
        querySelector: (arg0: string) => any;
        appendChild: (arg0: any) => void;
      };
    };
  }) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('chartjs-tooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('chartjs-tooltip');
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.backdropColor = '#3d3d3d';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);

    return tooltipEl;
  };

  static externalFixedTooltipHandler(this: any, context: any) {
    const { chart, tooltip } = context;

    const tooltipEl = ExternalToolTip.getOrCreateStaticTooltip(chart);

    if (tooltip.opacity === 0) {
      tooltipEl.style.display = 'none';
      return;
    }

    if (tooltip.body) {
      const caret = document.createElement('div');
      caret.className = 'tooltip-caret';

      caret.style.position = 'absolute';
      caret.style.bottom = '-5px';
      caret.style.left = 'calc(50% - 5px)';
      caret.style.transform = 'rotate(180deg)';

      tooltipEl.appendChild(caret);
      const bodyLines = tooltip.body.map((b: { lines: any }) => b.lines);

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body: string, i: string | number) => {
        const colors = tooltip.labelColors[i];
        const value =
          tooltip.dataPoints[i].parsed.y || tooltip.dataPoints[i].parsed.x;
        const label = tooltip.dataPoints[i].dataset.label;

        const valueEl = document.createElement('span');
        valueEl.textContent = value;
        valueEl.className = 'tooltip-label-value';
        valueEl.style.fontFamily = 'HEX Franklin v0.2';
        valueEl.style.fontSize = '13px';
        valueEl.style.fontWeight = '700';
        valueEl.style.color = '#fff';

        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = '0';

        const td = document.createElement('td');
        td.style.borderWidth = '0';

        td.appendChild(valueEl);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector('table');

      while (tableRoot?.firstChild) {
        tableRoot.firstChild.remove();
      }

      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + -45 + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding =
      tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';

    tooltipEl.style.display = 'block';
  }

  static onChartReady = (e: any) => {
    if (e.initial && e.numSteps === e.currentStep) {
      e.chart.config['_config'].data.datasets.map(
        ({ data }: any, datasetIndex: any) =>
          data.map(({ showTooltip }: any, index: any) => {
            return e.chart.tooltip.setActiveElements([
              {
                datasetIndex,
                index,
              },
            ]);
          })
      );
    }
  };
}

import { FC, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FilterItem } from '../SearchFilter/LegacyAdvanceFilter';
interface SearchInputProps {
  fullWidth?: boolean;
  data: any;
  menuList: any;
  isSubMenu?: boolean;
  setListItems: (str: any) => void;
  setNoFilter: (str: boolean) => void;
}

const SearchInput: FC<SearchInputProps> = ({
  fullWidth = false,
  data,
  isSubMenu,
  menuList,
  setListItems,
  setNoFilter,
}) => {
  const { t: translation } = useTranslation();
  const [value, setValue] = useState<string>('');
  const [itemsSelected, setItemsSelected] = useState<any>();

  useEffect(() => {
    setItemsSelected(menuList);
  }, []);

  const filterItems = (searchText: string) => {
    setValue(searchText);
    const arrayItems = filterData(data, searchText);
    if (_.isArray(arrayItems)) {
      //is array we have first menu
      if (!!searchText) {
        if (arrayItems.length > 0) {
          setNoFilter(false);
          setListItems(arrayItems);
          return;
        }
        setNoFilter(true);
        return;
      }
      setNoFilter(false);
      setListItems(arrayItems);
      return;
    } else {
      //is object we have first menu
      if (!!searchText) {
        if (arrayItems.items.length > 0) {
          setNoFilter(false);
          setListItems(arrayItems);
          return;
        }
        setNoFilter(true);
        return;
      }
      setNoFilter(false);
      const resetObject =
        data.find((item: any) => {
          return item.id === arrayItems.id;
        }) ||
        data
          .find(
            (item: any) =>
              item.nested &&
              item.filterItems?.find(
                (filterItem: FilterItem) => filterItem.id === arrayItems.id
              )
          )
          ?.filterItems?.find(
            (filterItem: FilterItem) => filterItem.id === arrayItems.id
          );

      setListItems(resetObject);
      return;
    }
  };

  const filterData = (menuData: any, searchText: string) => {
    if (!isSubMenu) {
      const newFilteredItems = menuData.filter((item: any) => {
        return item.name?.toLowerCase()?.includes(searchText.toLowerCase());
      });
      return newFilteredItems;
    } else {
      let newFilteredItems = {} as any;

      const nestedItem = menuData.find((item: FilterItem) => {
        return (
          item.nested &&
          item.filterItems?.find(
            (filterItem: FilterItem) => filterItem.id === menuList.id
          )
        );
      });

      if (nestedItem) {
        const childItem = nestedItem?.filterItems?.find(
          (filterItem: FilterItem) => filterItem.id === menuList.id
        );

        return {
          ...childItem,
          items: childItem.items.filter((item: any) => {
            return item.name?.toLowerCase()?.includes(searchText.toLowerCase());
          }),
        };
      } else {
        const allItems = menuData.find((item: any) => {
          return item.id === menuList.id;
        });

        newFilteredItems = {
          ...allItems,
          items: allItems.items.filter((item: any) => {
            return item.name?.toLowerCase()?.includes(searchText.toLowerCase());
          }),
        };
      }

      return newFilteredItems;
    }
  };

  return (
    <TextField
      fullWidth={fullWidth}
      className={classNames(['filter-input body-1'])}
      placeholder={translation(`common.searchPlaceholder`)}
      onChange={(e) => filterItems(e.target.value)}
      value={value}
      InputProps={{
        className: 'search-filter-input',
        startAdornment: (
          <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
        ),
      }}
    />
  );
};

export default SearchInput;

import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import {
  GroupCellRendererParams,
  RowSelectedEvent,
  GetRowIdParams,
  AgGridEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import {
  CommonSimpleDataGrid,
  CommonSimpleDataGridProps,
} from '../CommonSimpleDataGrid/CommonSimpleDataGrid';
import { GridRowSelectionProps, GridSelectionProps } from 'Risk/store';
import { OrderState } from 'Common/utils/sort';
import useCommonSelector from 'Common/utils/use-selector';

interface CommonAdvancedDataGridRowProps extends GroupCellRendererParams {
  nestedTableProps?: CommonSimpleDataGridProps & {
    dataMutation?: any;
    populateSelectionValues?: (
      parentId: string,
      selectionValues: Array<string>,
      items: Array<any>,
      selectionProps: GridSelectionProps
    ) => void;
    filterParams: any;
    additionalMutationParameters?: any;
    onRowClick?: (props: any, event: any) => void;
    onHandleChildSelectionChange?: (
      selection: string,
      parentId: string,
      selectionProps: GridSelectionProps
    ) => void;
    gridSelectionPropsSelector?: any;
    gridDuplicationState?: any;
  };
}

export const CommonAdvancedDataGridRow: FunctionComponent<
  CommonAdvancedDataGridRowProps
> = (props) => {
  const { nestedTableProps } = props;

  const gridRef = useRef<AgGridEvent | null>(null);
  const gridDuplicationState =
    nestedTableProps?.gridDuplicationState ?? (() => undefined);

  const isDuplicatedStateEnabled = useCommonSelector(gridDuplicationState);

  const gridSelectionProps: GridSelectionProps = useCommonSelector(
    nestedTableProps?.gridSelectionPropsSelector
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(25);
  const [orderBy, setOrderBy] = useState(nestedTableProps?.sortModel);

  const parentRowId = useMemo<string>(() => {
    return (props.node?.id as string).replace(':nested', '');
  }, [props.node?.id]);

  const subTableSelectionProps = useMemo<GridRowSelectionProps>(() => {
    return gridSelectionProps?.rows?.find(
      (rowItem: any) => rowItem.value === props.node.id?.replace(':nested', '')
    ) as GridRowSelectionProps;
  }, [gridSelectionProps]);

  useEffect(() => {
    if (
      subTableSelectionProps?.selection === 'unselected' &&
      gridRef?.current
    ) {
      gridRef?.current?.api?.deselectAll();
    }
  }, [subTableSelectionProps]);

  const dataMutationPayload =
    nestedTableProps?.dataMutation && nestedTableProps?.dataMutation();

  const onPageChange = (value: number) => {
    setCurrentPage(value);
  };

  const onPageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    if (columnOrderPayload.length) {
      setOrderBy({
        field: columnOrderPayload[0].field,
        type: columnOrderPayload[0].type,
      });
    } else {
      setOrderBy(nestedTableProps?.sortModel);
    }
  };

  useEffect(() => {
    if (dataMutationPayload?.length && props.node.expanded) {
      let payload: any = {
        filter: {
          ...(nestedTableProps?.filterParams || {}),
          [props.node.data?.aggregatedBy]: [
            { value: props.node.data?.aggregatedValue.replace(':nested', '') },
          ],
        },
        order: orderBy,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
        ...(nestedTableProps?.additionalMutationParameters || {}),
      };
      if (isDuplicatedStateEnabled !== undefined)
        payload.removeDuplications = isDuplicatedStateEnabled;
      dataMutationPayload[0](payload);
    }
  }, [
    currentPage,
    pageSize,
    orderBy,
    props.node.expanded,
    isDuplicatedStateEnabled,
  ]);

  const getRowHeightBasedOnDataLength = (dataLength: number) => {
    switch (dataLength) {
      case 1:
        return 180;
      case 2:
        return 250;
      case 3:
        return 300;
      case 4:
        return 350;
    }

    return 400;
  };

  useEffect(() => {
    if (
      dataMutationPayload &&
      dataMutationPayload[1]?.data?.data &&
      props.node.expanded
    ) {
      const payload = dataMutationPayload[1]?.data || [];
      nestedTableProps?.populateSelectionValues &&
        nestedTableProps.populateSelectionValues(
          (props.node.id as string).replace(':nested', ''),
          payload?.data?.map(
            (item: any) =>
              nestedTableProps?.getRowId &&
              nestedTableProps.getRowId({ data: item } as any)
          ),
          payload,
          gridSelectionProps
        );

      // Sets a dynamic height for the row based on the data length when the nested table has less than 5 rows in order to avoid unnecessary white space
      // If the data length is greater than 5, the row height will be fixed and verticall scroll will be activated

      if (payload.data?.length < 6) {
        const dataLength = payload.data?.length as number;
        props.node.setRowHeight(getRowHeightBasedOnDataLength(dataLength));
      } else {
        props.node.setRowHeight(450);
      }
    }
  }, [dataMutationPayload]);

  return (
    <div className={props.node.expanded ? 'common-advanced-data-grid-row' : ''}>
      {props.node.expanded && (
        <div className="common-advanced-data-grid-expanded-area">
          <CommonSimpleDataGrid
            columnDefs={nestedTableProps?.columnDefs || []}
            rowData={
              (dataMutationPayload && dataMutationPayload[1]?.data?.data) ||
              null
            }
            defaultColDef={{
              sortable: true,
              resizable: true,
            }}
            rowSelection="multiple"
            suppressRowClickSelection
            onSort={onHandleColumnOrderChange}
            onRowSelected={(event: RowSelectedEvent) => {
              if (event.source === 'checkboxSelected') {
                const rowId =
                  nestedTableProps?.getRowId &&
                  nestedTableProps.getRowId({
                    data: event.data,
                  } as GetRowIdParams);

                nestedTableProps?.onHandleChildSelectionChange &&
                  nestedTableProps.onHandleChildSelectionChange(
                    rowId as string,
                    (props.node.id as string).replace(':nested', ''),
                    gridSelectionProps
                  );
              }
            }}
            isLoading={dataMutationPayload && dataMutationPayload[1]?.isLoading}
            getRowId={nestedTableProps?.getRowId}
            paginationProps={{
              pageSize,
              currentPage,
              totalItems:
                (dataMutationPayload &&
                  dataMutationPayload[1]?.data?.totalItems) ||
                0,
              onPageChange,
              onPageSizeChange,
            }}
            visibilityControlProps={nestedTableProps?.visibilityControlProps}
            loadingOverlayComponent={nestedTableProps?.loadingOverlayComponent}
            otherComponents={nestedTableProps?.otherComponents}
            selectionModel={
              subTableSelectionProps?.availableChildren.filter(
                (child) =>
                  !subTableSelectionProps.unselectedChildren.includes(child)
              ) as Array<string>
            }
            keepCurrentSelections
            getRowNavigationUrl={nestedTableProps?.getRowNavigationUrl}
            gridRef={gridRef}
            onRowClicked={(event: RowClickedEvent) => {
              nestedTableProps?.onRowClicked &&
                nestedTableProps.onRowClicked(event);
            }}
            onColumnMoved={nestedTableProps?.onColumnMoved}
            onColumnVisible={nestedTableProps?.onColumnVisible}
          />
        </div>
      )}
    </div>
  );
};

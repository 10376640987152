import { FunctionComponent, useEffect } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { useSearchApplicationsMutation } from 'Application/store/api';
import { CommonButton } from 'shared/components/CommonButton';
import { CommonButtonType } from 'shared/components/CommonButton/CommonButton';
import { FormInput } from 'shared/components/FormInput/FormInput';
import { useSearchConnectionTemplatesMutation } from 'ConnectionTemplate/store/api';

interface IntegrationModalAccordionProps extends BaseComponentProps {
  parentApplicationId: string;
  title: string;
  selectedApplicationIds?: Array<string>;
  arnProps?: {
    enableArn: boolean;
    arnValue?: string;
    arnValidationError?: string;
    onArnValueChange?: (arnValue: string) => void;
  };
  subApplicationProps?: {
    onConnect: (applicationId: string) => void;
    onVerify: (applicationId: string) => void;
  };
  template?: any;
}

interface ApplicationAccordionProps {
  id: string;
  icon: string;
  title: string;
  description: string;
  formationLink: string;
  isConnected: boolean;
  onVerify?: (applicationId: string) => void;
  onConnect?: (applicationId: string) => void;
}

const ApplicationAccordion: FunctionComponent<ApplicationAccordionProps> = ({
  id,
  icon,
  title,
  description,
  formationLink,
  isConnected,
  onVerify,
  onConnect,
}) => {
  const renderConfiguredBlock = () => {
    if (isConnected) {
      return (
        <div className="integration-modal-accordion-configure-block">
          <div className="integration-modal-accordion-configure-block-text">
            Configured
          </div>
          <div className="integration-modal-accordion-configure-block-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />
          </div>
        </div>
      );
    }

    return <></>;
  };

  return (
    <Accordion className="integration-modal-accordion integration-modal-application-accordion">
      <AccordionSummary
        expandIcon={
          <OpusSvgIcon
            type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON}
            height={12}
            width={12}
          />
        }
        className="integration-modal-application-accordion-summary"
      >
        <div className="integration-modal-appliation-accordion-summary-body">
          <div className="integration-modal-accordion-title-block">
            <div className="integration-modal-accordion-title-icon">
              <img src={icon} alt={title} />
            </div>
            <div className="integration-modal-accordion-title-text">
              {title}
            </div>
          </div>
          {renderConfiguredBlock()}
        </div>
      </AccordionSummary>
      <AccordionDetails className="integration-modal-application-accordion-details">
        <div className="integration-modal-accordion-details">
          <div className="integration-modal-accordion-description">
            {description}
          </div>
          <div className="integration-modal-accordion-button-row">
            <div className="integration-modal-accordion-deploy-stack-button">
              <CommonButton type={CommonButtonType.TEXT}>
                <a
                  href={formationLink}
                  className="cloud-integration-deploy-button"
                  target="_blank"
                >
                  <OpusSvgIcon type={SVG_ICON_TYPES.DEPLOY_ICON} />
                  Deploy Stack
                </a>
              </CommonButton>
            </div>
            <div className="integration-modal-accordion-connection-button">
              <CommonButton
                type={CommonButtonType.SECONDARY}
                onClick={() => {
                  onVerify && onVerify(id);
                }}
              >
                Verify
              </CommonButton>
              <CommonButton
                type={CommonButtonType.OUTLINE_PRIMARY}
                onClick={() => {
                  onConnect && onConnect(id);
                }}
              >
                {isConnected ? 'Disconnect' : 'Connect'}
              </CommonButton>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export const IntegrationModalAccordion: FunctionComponent<
  IntegrationModalAccordionProps
> = ({
  parentApplicationId,
  title,
  selectedApplicationIds = [],
  arnProps,
  subApplicationProps,
  template,
  children,
}) => {
  const [
    searchApplications,
    { data: applicationsData, isLoading: applicationsDataLoading },
  ] = useSearchApplicationsMutation();

  const [
    searchConnectionTemplates,
    { data: connectionTemplateData, isLoading: connectionTemplatesLoading },
  ] = useSearchConnectionTemplatesMutation();

  useEffect(() => {
    searchApplications({});
  }, []);

  useEffect(() => {
    if (parentApplicationId?.length) {
      searchConnectionTemplates({
        parentApplicationId,
      });
    }
  }, [parentApplicationId]);

  const arnValueHandler = (arnValue: string) => {
    arnProps?.onArnValueChange && arnProps.onArnValueChange(arnValue);
  };

  const renderArnInput = () => {
    if (arnProps?.enableArn) {
      return (
        <div className="integration-modal-arn-content">
          <div className="integration-modal-arn-information">
            <div className="integration-modal-arn-information-text">
              {template?.description}
            </div>
            <div className="integration-modal-arn-information-button">
              <CommonButton type={CommonButtonType.TEXT}>
                <a
                  href={template?.definition?.props?.deployLink}
                  className="cloud-integration-deploy-button"
                  target="_blank"
                >
                  <OpusSvgIcon type={SVG_ICON_TYPES.DEPLOY_ICON} />
                  Deploy Stack
                </a>
              </CommonButton>
            </div>
          </div>

          <div className="integration-modal-arn-input">
            <FormInput
              label="Write Role ARN"
              placeholder="Write role ARN"
              value={arnProps?.arnValue || ''}
              name="roleArn"
              onChange={(value: string) => {
                arnValueHandler(value);
              }}
              errorMessage={arnProps?.arnValidationError}
            />
          </div>
        </div>
      );
    }

    return <></>;
  };

  const renderApplicationList = () => {
    if (applicationsData && connectionTemplateData) {
      return connectionTemplateData?.map((connectionTemplate: any) => {
        const application = applicationsData?.find(
          (app: any) => app.id === connectionTemplate?.applicationId
        );

        return (
          <ApplicationAccordion
            description={connectionTemplate?.description}
            formationLink={connectionTemplate?.definition?.props?.deployLink}
            icon={application?.logo}
            id={application?.id}
            title={application?.name}
            isConnected={selectedApplicationIds?.includes(application?.id)}
            onConnect={subApplicationProps?.onConnect}
            onVerify={subApplicationProps?.onVerify}
          />
        );
      });
    }

    return <CircularProgress size={24} />;
  };

  return (
    <div className="integration-modal-accordion-container">
      <Accordion className="integration-modal-accordion">
        <AccordionSummary
          title={title}
          expandIcon={
            <OpusSvgIcon
              type={SVG_ICON_TYPES.CHEVRON_DOWN_ICON}
              height={12}
              width={12}
            />
          }
          className="integration-modal-accordion-summary"
        >
          <div className="integration-modal-accordion-title">{title}</div>
        </AccordionSummary>
        <AccordionDetails className="integration-modal-accordion-details-container">
          <div className="integration-modal-accordion-details">
            {renderArnInput()}
            {renderApplicationList()}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

import React, { useCallback, useEffect } from 'react';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { Box } from '@mui/material';
import { MenuItems } from 'DashboardFilter/store';
import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import ChipDropdown from 'Common/components/ChipDropdown/ChipDropdown';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { FilterItem } from 'shared/components/SearchFilter/AdvanceFilter';
import { RootState } from 'Common/store';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';
import { TypeFilter } from 'shared/enums/campaigns.enum';

interface FilterGetters {
  getFilterList: (state: RootState) => MenuItems;
  getSelectedGroup: any;
  getSelectedScope: any;
}

interface FilterSetters {
  setListFilter: any;
  setSelectedGroup: any;
  setSelectedScope: any;
  setSelectedServices: any;
  setListItems: any;
}

interface ScopeGroupFilterProps {
  filterItems: Array<FilterItem>;
  getters: FilterGetters;
  setters: FilterSetters;
}

const ScopeGroupFilter: React.FC<ScopeGroupFilterProps> = ({
  filterItems,
  getters: { getFilterList, getSelectedGroup, getSelectedScope },
  setters: {
    setListFilter,
    setSelectedGroup,
    setSelectedScope,
    setSelectedServices,
    setListItems,
  },
}) => {
  const dispatch = useCommonDispatch();
  const allDataMenu: MenuItems = useCommonSelector(getFilterList);
  const selectedGroupIds: string[] = useCommonSelector(getSelectedGroup);
  const selectedScopeIds: string[] = useCommonSelector(getSelectedScope);

  const findSelectedIds = (menuId: string, data: MenuItems) => {
    const menuObject = data.find((obj) => obj.id === menuId);
    return (
      menuObject?.items.filter((item) => item.checked).map((item) => item.id) ||
      []
    );
  };

  const handleAllMultiSelect = (data: any) => {
    const updatedArray = allDataMenu.map((submenu: any) => {
      if (submenu.id === data.id) {
        const updatedItems = submenu.items.map((item: any) => {
          return item.id === 'All'
            ? { ...item, checked: true }
            : { ...item, checked: false };
        });
        return { ...submenu, items: updatedItems };
      }
      return submenu;
    });
    return updatedArray;
  };

  const handleMultiSelect = (selectedItem: any, data: any) => {
    const activeItemId = data.id;
    let updatedArray;

    if (selectedItem.id === 'All') {
      updatedArray = handleAllMultiSelect(data);
    } else {
      updatedArray = allDataMenu.map((submenu: FilterItem) => {
        if (submenu.id === data.id) {
          const updatedItems = submenu.items.map((item: any) => {
            if (item.id === 'All') {
              return { ...item, checked: false };
            } else if (item.id === selectedItem.id) {
              return { ...item, checked: !item.checked };
            }
            return item;
          });
          // This was a deselecting of the last item, force select "all"
          if (updatedItems.every((item) => !item.checked)) {
            updatedItems.find((item) => item.id === 'All').checked = true;
          }
          return { ...submenu, items: updatedItems };
        }
        return submenu;
      });
    }

    dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    if (activeItemId === DashboardFilterType.SERVICES) {
      const selectedServices = findSelectedIds(
        DashboardFilterType.SERVICES,
        updatedArray
      );
      dispatch(setSelectedServices(selectedServices));
    }
  };

  const handleSingleSelect = (id: any, data: any) => {
    const activeItemId = data.id;
    const updatedSubmenu = allDataMenu.find((item) => item.id === data.id);
    const isAlreadySelected = Boolean(
      updatedSubmenu?.items?.find((item) => item.id === id.id && item.checked)
    );
    if (isAlreadySelected) return;

    const updatedObject = {
      ...updatedSubmenu,
      items: updatedSubmenu?.items.map((item: any) => ({
        ...item,
        checked: item.id === id.id,
      })),
    };

    const updatedArray = allDataMenu.map((obj: any) =>
      obj.id === updatedObject.id ? { ...updatedObject } : obj
    );

    dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
    const selectedValues = findSelectedIds(activeItemId, updatedArray);
    switch (activeItemId) {
      case DashboardFilterType.SCOPE:
        dispatch(setSelectedScope(selectedValues));
        break;
      case DashboardFilterType.GROUP:
        dispatch(setSelectedGroup(selectedValues));
        break;
    }
  };

  const handleSelect = (id: any, data: any) => {
    if (data.type === TypeFilter.SINGLE_SELECT) {
      handleSingleSelect(id, data);
    } else {
      handleMultiSelect(id, data);
    }
  };

  const getMutationParams = useCallback(
    (item: FilterItem) => {
      if (item.id === DashboardFilterType.FINDING_TYPE) {
        return {};
      }
      if (item.id === DashboardFilterType.SERVICES) {
        let startNodeId;
        if (selectedGroupIds[0] !== DashboardFilterType.ALL) {
          startNodeId = selectedGroupIds[0];
        } else if (selectedScopeIds[0] !== DashboardFilterType.ALL) {
          startNodeId = selectedScopeIds[0];
        }
        return {
          nodesType: item.field,
          startNodeId: startNodeId,
        };
      }
      return {
        nodesType: item.field,
        startNodeId:
          selectedScopeIds[0] === DashboardFilterType.ALL
            ? undefined
            : selectedScopeIds[0],
      };
    },
    [selectedGroupIds[0], selectedScopeIds[0]]
  );

  const updateReduxData = (updatedMenuItems: MenuItems) => {
    const updatedArray = allDataMenu.map((obj: FilterItem) => {
      const updatedItem = updatedMenuItems.find((item) => item.id === obj.id);
      return updatedItem ? { ...updatedItem } : obj;
    });

    dispatch(setListFilter({ list: updatedArray, isSelectingItem: true }));
  };

  const getChipData = (id: string) => {
    const inputObj = allDataMenu.find((item) => item.id === id);
    const filteredItems = inputObj?.items.filter((item) => item.checked) || [];
    return { ...inputObj, items: filteredItems };
  };

  const mapRequestDataToOptionData = (requestData: Array<any>) => {
    return requestData.map((dataItem) => {
      if (dataItem.value) {
        return {
          value: dataItem.value,
          label: dataItem.label,
        };
      }
      return {
        value: dataItem.id,
        label: dataItem.name,
      };
    });
  };

  const propagateFilters = (
    selectedParentFilterItems: any[],
    filterTypes: DashboardFilterType[]
  ) => {
    const updatedObjects: MenuItems = [];
    for (const filterType of filterTypes) {
      const filterData = allDataMenu.find(
        (item: FilterItem) => item.id === filterType
      );
      if (!filterData) continue;

      let updatedItems = [...filterData.items];
      if (selectedParentFilterItems[0] !== DashboardFilterType.ALL) {
        switch (filterType) {
          case DashboardFilterType.GROUP:
            dispatch(setSelectedGroup(['All']));
            break;
          case DashboardFilterType.SERVICES:
            dispatch(setSelectedServices(['All']));
            break;
        }

        updatedItems = [
          {
            id: DashboardFilterType.ALL,
            name: DashboardFilterType.ALL,
            checked: true,
          },
        ];
      }
      const updatedObject: FilterItem = {
        ...filterData,
        items: updatedItems,
        loaded: false,
      };
      updatedObjects.push(updatedObject);
    }
    updateReduxData(updatedObjects);
  };

  useEffect(() => {
    if (selectedScopeIds[0]) {
      propagateFilters(selectedScopeIds, [
        DashboardFilterType.GROUP,
        DashboardFilterType.SERVICES,
      ]);
    }
  }, [selectedScopeIds[0]]);

  useEffect(() => {
    if (selectedGroupIds[0]) {
      propagateFilters(selectedGroupIds, [DashboardFilterType.SERVICES]);
    }
  }, [selectedGroupIds[0]]);

  return (
    <Box display={'flex'} gap="15px">
      {filterItems.map((item) => (
        <ChipDropdown
          setListItems={setListItems}
          key={item.id}
          item={getChipData(item.id)}
          allOption
          data={allDataMenu}
          mutationProps={{
            params: getMutationParams(item),
            fetchMutation:
              item.id === DashboardFilterType.FINDING_TYPE
                ? useFetchFilterInformationForFieldMutation
                : useGetUserAvailableNodesOfTypeMutation,
          }}
          setListFilter={setListFilter}
          handleItemSelect={handleSelect}
          listMapper={mapRequestDataToOptionData}
        />
      ))}
    </Box>
  );
};

export default ScopeGroupFilter;

import { Box } from '@mui/material';
import AppLogo from '../Logo/Logo';
import CommonMenu from '../Menu';
import classes from './Sidebar.module.css';
import { Sidebar } from 'react-pro-sidebar';
export default function CommonSidebar() {
  return (
    <Sidebar defaultCollapsed={true} className={classes.sidebarContainer}>
      <Box className={classes.sidebar}>
        <Box className={classes.sidebarMenu}>
          <AppLogo />
          <CommonMenu />
        </Box>
      </Box>
    </Sidebar>
  );
}

import { ApplicationPermission } from 'shared/enums/permission.enum';
import { ApplicationRoute } from 'shared/enums/route.enum';

type RouteKeyWithPermissions = Partial<{
  [key in ApplicationRoute]: Array<ApplicationPermission>;
}>;

export const routePermissions: RouteKeyWithPermissions = {
  [ApplicationRoute.DASHBOARDS]: [ApplicationPermission.ANALYTICS_READ],
  [ApplicationRoute.RISKS]: [ApplicationPermission.RISK_READ],
  [ApplicationRoute.ASSETS]: [],
  [ApplicationRoute.FINDING_SINGLE]: [ApplicationPermission.FINDINGS_READ],
  [ApplicationRoute.WORKFLOWS]: [ApplicationPermission.WORKFLOWS_READ],
  [ApplicationRoute.WORKFLOW_SINGLE]: [ApplicationPermission.WORKFLOWS_READ],
  [ApplicationRoute.WORKFLOW_CREATE]: [ApplicationPermission.WORKFLOWS_WRITE],
  [ApplicationRoute.WORKFLOW_EDIT]: [ApplicationPermission.WORKFLOWS_WRITE],
  [ApplicationRoute.CAMPAIGNS]: [ApplicationPermission.CAMPAIGNS_READ],
  [ApplicationRoute.CAMPAIGN_SINGLE]: [ApplicationPermission.CAMPAIGNS_READ],
  [ApplicationRoute.INTEGRATIONS]: [ApplicationPermission.INTEGRATIONS_TAB],
  [ApplicationRoute.AUTOMATION_MANAGEMENT]: [
    ApplicationPermission.AUTOMATIONS_READ,
  ],
  [ApplicationRoute.EVENTS]: [ApplicationPermission.EVENTS_READ],
  [ApplicationRoute.EVENT_SINGLE]: [ApplicationPermission.EVENTS_READ],
  [ApplicationRoute.SETTINGS]: [ApplicationPermission.SETTINGS_GLOBAL],
};

export enum FindingWidgetType {
  KEY_VAL_PAIRS = 'KEY_VAL_PAIRS',
  JSON = 'JSON',
  TABLE = 'TABLE',
}

export interface FindingWidgetData {
  key: string;
  label?: string;
  value: string;
  icon?: string;
  color?: string;
  isTableRow?: boolean;
}

export interface FindingWidget {
  tenantId?: string;
  findingId?: string;
  data: any;
  id?: string;
  title?: string;
  type: FindingWidgetType;
  applicationId?: string;
  applicationLogo?: string;
  appLogo?: string;
}

export interface TableWidgetDataColumn {
  label: string;
  value: string;
}

export interface TableWidgetData {
  title: string;
  columns: Array<TableWidgetDataColumn>;
  data: Array<any>;
}

export enum ThreatIntelTrendType {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export interface ThreatIntelWidgetData {
  title: string;
  description: string;
  score: number;
  range: number;
  trendType: ThreatIntelTrendType;
}

export type DynamicFindingWidgetData = ThreatIntelWidgetData | TableWidgetData;

export interface JsonWidget {
  title: string;
  value: string;
}

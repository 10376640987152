import { FunctionComponent } from 'react';
import { Route, Routes } from 'react-router-dom';
import { machineAccessibleRouteList } from 'shared/fixtures/data/route-list.data';
import { RouteModel } from 'shared/models/data/route.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface MachineRouteContainerProps extends BaseComponentProps {}

export const MachingRouteContainer: FunctionComponent<
  MachineRouteContainerProps
> = () => {
  return (
    <Routes>
      {machineAccessibleRouteList.map((route: RouteModel) => (
        <Route path={route.path} element={route.component} />
      ))}
    </Routes>
  );
};

import { AutocompleteOption } from 'FindingDetails/store/api';
import { ConfigurationFieldValue } from 'shared/components/MultiSelectConfigurationField/MultiSelectConfigurationField';
import { MultiTextRowValue } from 'shared/components/MultiTextConfigurationField/MultiTextConfigurationField';
import {
  BusinessUnitComplianceRequirements,
  BusinessUnitImpact,
  BusinessUnitRiskAttribute,
  BusinessUnitRole,
} from 'shared/enums/business-unit.enum';

export interface BusinessUnitCodeRepository {
  id: string;
  logoUrl?: string;
  name?: string;
  link?: string;
}

export interface BusinessUnitCloudAccount {
  id?: string;
  alias: string;
  type: string;
  workspaceId?: string;
  cloudProviderLogo?: string;
}

export interface BusinessUnitStakeholder {
  id: string;
  fullName?: string;
  avatarUrl?: string;
  role?: BusinessUnitRole;
}

export interface BusinessUnitTicketingSystem {
  id: string;
  logoUrl?: string;
  name?: string;
  link?: string;
  projectName?: string;
}

export interface BusinssUnitRelatedTeam {
  id: string;
  name?: string;
  owner?: string;
}

export interface ExtendedBusinessUnitCreateUser {
  id: string;
  role: BusinessUnitRole;
}

export interface ExtendedBusinessUnitCreateTicketingSystem {
  connectionId: string;
  projectName: string;
}

export interface ExtendedBusinessUnitCreateAccount
  extends BusinessUnitCloudAccount {}

export interface ExtendedBusinessUnitListItemModel {
  id: string;
  name: string;
  unitOwner: string;
  description: string;
  businessUnitImpact: BusinessUnitImpact;
  stakeholders: Array<BusinessUnitStakeholder>;
  riskAttributes: Array<BusinessUnitRiskAttribute>;
  environmentsCount: number;
  associatedReposCount: number;
}

export enum BusinessUnitRuleTypeEnum {
  RESOURCE_TAGS = 'resource-tags',
}

export interface BusinessUnitAssociationSingleRule {
  key: string;
  value: string;
}

export interface BusinessUnitAssociationRule {
  type: BusinessUnitRuleTypeEnum;
  rules: BusinessUnitAssociationSingleRule[];
}

export interface ExtendedBusinessUnitModel {
  id?: string;
  orgStructureInfo: any;
  name: string;
  description?: string;
  unitOwner?: string;
  businessUnitImpact: BusinessUnitImpact;
  associatedRepos?: Array<BusinessUnitCodeRepository>;
  associatedAccounts: Array<BusinessUnitCloudAccount>;
  associatedDomains?: Array<string>;
  stakeholders?: Array<BusinessUnitStakeholder>;
  ticketingSystems?: Array<BusinessUnitTicketingSystem>;
  team?: BusinssUnitRelatedTeam;
  riskAttributes?: Array<BusinessUnitRiskAttribute>;
  complianceRequirements?: Array<BusinessUnitComplianceRequirements>;
  communicationAppId?: string;
  tags?: string;
  parentId?: string;
  assetGroups?: Array<string>;
  associationRules?: Array<BusinessUnitAssociationRule>;
}

export interface ExtendedBusinessUnitCreateModel {
  id?: string;
  name: string;
  businessUnitCriticality: any;
  description?: string;
  associatedRepos?: Array<string>;
  associatedCloudAccounts?: Array<string>;
  associatedDomains?: Array<string>;
  users?: Array<ExtendedBusinessUnitCreateUser>;
  ticketingSystems?: Array<ExtendedBusinessUnitCreateTicketingSystem>;
  riskAttributes?: Array<BusinessUnitRiskAttribute>;
  complianceRequirements?: Array<BusinessUnitComplianceRequirements>;
  associatedAccounts: Array<string>;
  teamId?: string;
  unitOwner?: string;
  communicationAppId?: string;
  tags?: string;
  parentId?: string;
  assetGroups?: Array<string>;
  associationRules?: Array<BusinessUnitAssociationRule>;
}

export interface ExtendedBusinessUnitFormModel {
  groupNodeId?: AutocompleteOption;
  orgScopeId?: AutocompleteOption;
  name: string;
  description?: string;
  businessUnitImpact?: AutocompleteOption;
  associatedRepos?: Array<AutocompleteOption>;
  associatedAccounts: Array<AutocompleteOption>;
  associatedDomains?: string;
  stakeholders?: Array<ConfigurationFieldValue>;
  ticketingSystem?: AutocompleteOption;
  projectName?: string;
  team?: AutocompleteOption;
  riskAttributes?: Array<BusinessUnitRiskAttribute>;
  complianceRequirements?: Array<BusinessUnitComplianceRequirements>;
  communicationAppId?: string;
  tags?: string;
  assetGroups?: string;
  associationRules?: Array<MultiTextRowValue>;
}

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Popover,
  Snackbar,
} from '@mui/material';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import FilterMainItem from '../FilterItems/FilterMainItem';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { FilterOption } from '../SearchFilter/LegacyAdvanceFilter';
import { useTranslation } from 'react-i18next';
import { CustomFilterType } from 'Risk/interfaces/CustomFilterType.enum';
import {
  useDeleteCustomFilterMutation,
  useFetchCustomFilterMutation,
} from 'Risk/store/api';
import FormattedMessage from '../FormattedMessage';

export interface FavouritesFilterItemOption {
  type: string;
  values: Array<FilterOption>;
}

export interface FavouritesFilterItem {
  name: string;
  id: string;
  options: FavouritesFilterItemOption[];
  enableDelete?: boolean;
}

export interface CustomFilterItem {
  customFilterEntityType: QuickFilterTypes;
  filter: any;
  name: string;
  id: string;
}
export enum QuickFilterTypes {
  SAVED_FILTERS = 'savedFilters',
  SYSTEM_FILTERS = 'systemFilters',
}
type QuickFiltersMap = {
  [key in QuickFilterTypes]: FavouritesFilterItem[];
};

export interface QuickFiltersList extends QuickFiltersMap {}

export interface CommonFavoriteComponentProps {
  items: QuickFiltersList;
  onSelect: (item: FavouritesFilterItem) => void;
  filterType?: CustomFilterType;
}

export const CommonFavoriteComponent = ({
  items,
  onSelect,
  filterType,
}: CommonFavoriteComponentProps) => {
  const [filterList, setFilterList] = useState<QuickFiltersList>(items);
  const { t: translation } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [fetchCustomFilters, { data: customFiltersList, isLoading }] =
    useFetchCustomFilterMutation();
  const [
    deleteCustomFilter,
    { isSuccess: deleteSuccess, error: deleteFailed, reset: resetDelete },
  ] = useDeleteCustomFilterMutation();
  let open = Boolean(anchorEl);
  let id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (filterType && open)
      fetchCustomFilters({
        customFilterEntityType: filterType,
      });
  }, [open]);

  const createFilterObject = (item: CustomFilterItem) => {
    if (!item || !item.filter) {
      return null;
    }

    const transformedData = Object.entries(item.filter).map(
      ([type, values]) => ({ type, values })
    );

    return {
      enableDelete: true,
      id: item.id,
      options: transformedData,
      name: item.name,
    };
  };

  const updateFilterList = (
    filterList: QuickFiltersList,
    customFiltersList: any[]
  ) => {
    const updatedSavedFilters = customFiltersList.map(createFilterObject);

    return {
      ...filterList,
      [QuickFilterTypes.SAVED_FILTERS]: updatedSavedFilters,
    };
  };

  useEffect(() => {
    if (deleteSuccess) {
      fetchCustomFilters({
        customFilterEntityType: filterType,
      });
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (customFiltersList?.length === 0) setFilterList(items);
    if (customFiltersList && customFiltersList?.length > 0) {
      const updatedFilterList = updateFilterList(filterList, customFiltersList);
      setFilterList(updatedFilterList as any);
    }
  }, [customFiltersList]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (item: FavouritesFilterItem) => {
    handleCloseMenu();
    onSelect(item);
  };

  const handleDeleteCustomFilter = (
    item: FavouritesFilterItem,
    event?: React.MouseEvent<HTMLElement>
  ) => {
    event?.preventDefault();
    event?.stopPropagation();
    if (item.enableDelete) {
      deleteCustomFilter(item.id);
    }
  };
  const renderFilterGroup = (
    enumKey: QuickFilterTypes,
    translation: any,
    isLoading: boolean,
    filterList: QuickFiltersList,
    handleItemClick: (item: FavouritesFilterItem) => void,
    handleDeleteCustomFilter: (
      item: FavouritesFilterItem,
      event?: React.MouseEvent<HTMLElement>
    ) => void
  ) => {
    if (
      enumKey === QuickFilterTypes.SYSTEM_FILTERS &&
      filterList[enumKey].length === 0
    ) {
      return <></>;
    }

    return (
      <React.Fragment key={enumKey}>
        <p className="quick-filter-group-label body-5">
          {translation(`findings.filter.${enumKey}`)}
        </p>
        {enumKey === QuickFilterTypes.SAVED_FILTERS && isLoading && (
          <div className="quick-filter-loading">
            <CircularProgress size={12}></CircularProgress>
          </div>
        )}
        {!filterList[enumKey]?.length && !isLoading && (
          <p className="body-4 quick-filter-group-label ">
            <FormattedMessage
              id="findings.filter.noFilters"
              defaultMessage="There is no filter saved yet"
            ></FormattedMessage>
          </p>
        )}

        {filterList[enumKey]?.map((item: FavouritesFilterItem) => (
          <FilterMainItem
            key={item.id}
            displayIcon={false}
            item={item}
            clickItem={() => handleItemClick(item)}
            onDelete={(event: any) => handleDeleteCustomFilter(item, event)}
          />
        ))}
      </React.Fragment>
    );
  };

  const renderFilterGroups = (
    filterList: QuickFiltersList,
    translation: any,
    isLoading: boolean,
    handleItemClick: (item: FavouritesFilterItem) => void,
    handleDeleteCustomFilter: (
      item: FavouritesFilterItem,
      event?: React.MouseEvent<HTMLElement>
    ) => void
  ) => {
    return Object.keys(filterList).map((key: string) => {
      const enumKey = key as QuickFilterTypes;
      return renderFilterGroup(
        enumKey,
        translation,
        isLoading,
        filterList,
        handleItemClick,
        handleDeleteCustomFilter
      );
    });
  };

  const renderFilterContent = (
    filterList: QuickFiltersList,
    isLoading: boolean,
    translation: any,
    handleItemClick: (item: FavouritesFilterItem) => void,
    handleDeleteCustomFilter: (
      item: FavouritesFilterItem,
      event?: React.MouseEvent<HTMLElement>
    ) => void
  ) => {
    return (
      <div className="popover-list">
        {renderFilterGroups(
          filterList,
          translation,
          isLoading,
          handleItemClick,
          handleDeleteCustomFilter
        )}
      </div>
    );
  };

  return (
    <>
      <Button
        className={classNames([
          'opus-filter-button add-filter-btn filter-favourites',
          { active: open },
        ])}
        aria-describedby="favorite-popover"
        onClick={handleOpenMenu}
      >
        <StarOutlineIcon />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className="popover-filter main-category-filter-dropdown-container"
      >
        {renderFilterContent(
          filterList,
          isLoading,
          translation,
          handleItemClick,
          handleDeleteCustomFilter
        )}
      </Popover>
      <Snackbar
        open={deleteSuccess}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => {
          resetDelete();
        }}
      >
        <Alert severity="success" sx={{ minWidth: '100px !important' }}>
          <FormattedMessage
            id="findings.filter.filterDelete"
            defaultMessage="Filter is successfully deleted"
          />
        </Alert>
      </Snackbar>
    </>
  );
};

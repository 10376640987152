import { FunctionComponent } from 'react';

export interface ActionListDetailsProps {
  warningMessage?: string;
  displayItems?: Array<string>;
  listTitle: string;
  listTitleSuffix?: string;
}

export const ActionListDetails: FunctionComponent<ActionListDetailsProps> = ({
  warningMessage,
  displayItems,
  listTitle,
  listTitleSuffix,
}) => {
  if (displayItems === undefined || displayItems?.length === 0) {
    return <></>;
  }

  return (
    <div>
      <div className="risk-action-response-title-container">
        <span className="risk-action-response-title-note">Please note:</span>
        <span className="risk-action-response-title-space"></span>
        <span className="risk-action-response-title">{warningMessage}</span>
      </div>
      <div className="risk-action-response-list-container">
        <div className="risk-action-response-list-title">
          {listTitle}{' '}
          {listTitleSuffix === undefined || listTitleSuffix === null
            ? `(${displayItems?.length})`
            : `${listTitleSuffix}`}
        </div>
        <div className="risk-action-response-list-items">
          {displayItems?.map((displayItem: any) => (
            <div className="risk-action-response-list-item" title={displayItem}>
              {displayItem}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  Controller,
  Navigation,
  Pagination,
  SwiperOptions,
  Virtual,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

interface CommonCarouselListProps extends BaseComponentProps {
  configuration?: SwiperOptions;
  items: Array<any>;
  renderItem: (data: any) => ReactNode;
  getAdditionalChildren?: () => ReactNode | Array<ReactNode>;
}

export const CommonCarouselList: FunctionComponent<CommonCarouselListProps> = ({
  configuration,
  items,
  renderItem,
  getAdditionalChildren,
}) => {
  return (
    <Swiper
      {...configuration}
      virtual
      modules={[Virtual, Navigation, Pagination, Controller]}
    >
      {items.map((item: any, index: number) => (
        <SwiperSlide virtualIndex={index}>{renderItem(item)}</SwiperSlide>
      ))}

      {getAdditionalChildren && getAdditionalChildren()}
    </Swiper>
  );
};

import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  IntegrationItem,
  IntegrationItemSubCategory,
} from 'Integrations/interfaces/IntegrationItem.model';
import { PollingSubscription } from 'Integrations/Webhook/interfaces/polling-subscription';
import WebhookItem from 'Integrations/Webhook/interfaces/item';
import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';
import { CommonIntegrationModalProps } from 'Integrations/Connection/components/IntegrationModal/IntegrationModal';
import { FindingSourceContent } from 'Integrations/Connection/components/IntegrationModal/components/FindingSourceContent/FindingSourceContent';
import { BaseIntegrationModalEditForm } from 'Integrations/Connection/components/IntegrationModal/components/BaseIntegrationModalEditForm/BaseIntegrationModalEditForm';
import { BaseIntegrationModalCreateForm } from 'Integrations/Connection/components/IntegrationModal/components/BaseIntegrationModalCreateForm/BaseIntegrationModalCreateForm';

interface OnboardingSecurityHubFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

export const OnboardingSecurityHubForm: FunctionComponent<
  OnboardingSecurityHubFormProps
> = ({
  open,
  onCancel,
  integrationItem,
  connectionId,
  onCreateSuccess,
  ...otherProps
}) => {
  const [submited, setSubmited] = useState<boolean>(false);
  const [parentConnectionTemplate, setParentConnectionTemplate] = useState<
    ConnectionTemplateItem | undefined
  >(undefined);

  const renderAdditionalContent = (
    visible: boolean,
    toggleVisibility: (visible: boolean) => void,
    data?: PollingSubscription | WebhookItem
  ) => {
    return (
      <FindingSourceContent
        webhook={data as WebhookItem}
        submited={submited}
        connectionId={connectionId}
        setSubmited={setSubmited}
        id={integrationItem.id}
        connectionTemplate={parentConnectionTemplate}
      />
    );
  };

  const handleConnectionTemplateChange = (
    template?: ConnectionTemplateItem
  ) => {
    setParentConnectionTemplate(template);
  };

  const handleAfterCreateConnection = (connection: IntegrationItem): void => {
    if (onCreateSuccess) onCreateSuccess(connection.id);
  };

  const renderModalForm = () => {
    if (connectionId) {
      return (
        <BaseIntegrationModalEditForm
          handleOnCreate={handleAfterCreateConnection}
          {...{
            onConnectHandler: setSubmited,
            integrationItem,
            onCancel,
            open,
            connectionId,
            renderAdditionalContent,
            onConnectionTemplateChange: handleConnectionTemplateChange,
            ...otherProps,
          }}
          rootClassName="onboarding-integration-form"
        />
      );
    }

    return (
      <BaseIntegrationModalCreateForm
        handleOnCreate={handleAfterCreateConnection}
        {...{
          integrationItem,
          onCancel,
          open,
          renderAdditionalContent,
          onConnectionTemplateChange: handleConnectionTemplateChange,
          ...otherProps,
        }}
        rootClassName="onboarding-integration-form"
      />
    );
  };

  return renderModalForm();
};

import { v4 as uuid } from 'uuid';
import { string } from 'yup/lib/locale';

//
// entity
//
export default interface ApplicationItem {
  id: string;
  name: string;
  logo: string;
  isFindingsSource: boolean;
  isEnabled: boolean;
  category: string;
  isWebhookEnabled?: boolean;
  applicationLogo?: string;
  applicationId: string;
}

export interface ApplicationLogo {
  id: string;
  logo: string;
  name?: string;
}

// initializer, data transformer
export function applicationInit(
  raw: Partial<ApplicationItem>
): ApplicationItem {
  return {
    id: raw.id ?? uuid(),
    name: raw.name ?? 'Application',
    logo: raw.logo ?? raw.applicationLogo ?? '',
    applicationId: raw.applicationId ?? '',
    isFindingsSource: Boolean(raw.isFindingsSource),
    isEnabled: raw.isEnabled ?? true,
    category: raw.category ?? 'Miscellaneous',
    isWebhookEnabled: Boolean(raw.isWebhookEnabled),
  };
}

// bulk initializer
export function applicationInitBulk(
  raws: Partial<ApplicationItem>[]
): ApplicationItem[] {
  return raws.map(applicationInit);
}

export interface FindingFileImportApplicationItem extends ApplicationItem {
  fileMappers?: FindingFileImportMapperItem[];
}

export interface FindingFileImportMapperItem {
  mapperName: string;
  mapperId: string;
}

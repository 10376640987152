import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useAuthUser } from '@frontegg/react';
import { AgGridEvent, RowClickedEvent } from 'ag-grid-community';
import { useFetchFilterInformationForFieldMutation } from 'Risk/store/api';
import { riskViewFilterCategories } from 'shared/fixtures/data/risk-grid.data';
import { AdvanceFilterHandler } from 'shared/handlers/advance-filter-data.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  getGridFilterSelections,
  getGridFilterState,
  setActiveGridTab,
  setGridFilterState,
  getFilterState as getStoreFilterState,
  setSearchParams,
  clearFilterState,
  removeFilterItem,
  replaceFilterState,
  setFilterState,
  getAssetsSearchParams,
  AssetsDefaultOrder,
} from 'Assets/store';
import { AssetsTabList } from '../AssetsTabList/AssetsTabList';
import { AssetsCodeGrid } from '../AssetsCodeGrid/AssetsCodeGrid';
import { AssetsTabType } from 'Assets/interfaces/AssetsTabType';
import { AssetsArtifactGrid } from '../AssetsArtifactGrid/AssetsArtifactGrid';
import { CustomFilterType } from 'Risk/interfaces/CustomFilterType.enum';
import {
  ApiStaticOptionSourceProperties,
  CategoryState,
  ExtendedFilterCategory,
  SingleSelectState,
} from 'shared/models/data/data-filter.model';
import { useSearchParams } from 'react-router-dom';
import { QuickFiltersList } from 'shared/components/CommonFavouritesFilter/CommonFavouritesFilter';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import AdvanceFilter from 'shared/components/SearchFilter/AdvanceFilter';
import {
  assetsFilterItems,
  assetsViewFilterCategories,
} from 'shared/fixtures/data/assets-grid.data';
import { AssetsCloudGrid } from '../AssetsCloudGrid/AssetsCloudGrid';
import { AssetsDomainGrid } from '../AssetsDomainGrid/AssetsDomainGrid';
import { AssetsOnPremGrid } from '../AssetsOnPremGrid/AssetsOnPremGrid';
import { Snackbar, Alert } from '@mui/material';
import CustomFilterModal from 'shared/components/CustomFilterModal';
import FormattedMessage from 'shared/components/FormattedMessage';
import { useCreateCustomFilterMutation } from 'Assets/store/api';
import ResourceAssetDetailsDrawer from '../ResourceAssetDetailsDrawer';
import AssetsAllGrid from '../AssetsAllGrid';
import FindingCodeEventDrawerComplex from 'shared/components/FindingCodeEventDrawerComplex/FindingCodeEventDrawerComplex';

const tabListItems = [
  {
    id: AssetsTabType.ALL,
    label: 'All',
    icon: SVG_ICON_TYPES.DATABASE_ICON,
  },
  {
    id: AssetsTabType.CODE,
    label: 'Code',
    icon: SVG_ICON_TYPES.CODE_ICON,
  },
  {
    id: AssetsTabType.ARTIFACT,
    label: 'Artifact',
    icon: SVG_ICON_TYPES.CUBE_ICON,
  },
  {
    id: AssetsTabType.CLOUD,
    label: 'Cloud',
    icon: SVG_ICON_TYPES.CLOUD_ICON,
  },
  {
    id: AssetsTabType.ONPREM,
    label: 'On-Prem',
    icon: SVG_ICON_TYPES.SERVER_ICON,
  },
  {
    id: AssetsTabType.DOMAIN,
    label: 'Domain',
    icon: SVG_ICON_TYPES.GLOBE_ICON,
  },
];

const filterSearchParamsHandler = new FilterSearchParamsHandler();
const advancedFilterDataHandler = new AdvanceFilterHandler();

interface AssetsAdvancedDataGridProps extends BaseComponentProps {}

export const AssetsAdvancedDataGrid: FunctionComponent<
  AssetsAdvancedDataGridProps
> = () => {
  const categoryState = useCommonSelector(getStoreFilterState);
  const user = useAuthUser();
  const [openCustomFilterModal, setOpenCustomFilterModal] =
    useState<boolean>(false);
  const permissionActions = usePermissions();

  const dispatch = useCommonDispatch();
  const { t: translation } = useTranslation();
  let [urlSearchParams, setUrlSearchParams, getUrlSearchParams] =
    useQueryParams();
  const [reactiveSearchParams, setReactiveSearchParams] = useSearchParams();

  const activeLayer = useMemo<AssetsTabType>(() => {
    const searchParams = getUrlSearchParams();

    if (!searchParams.activeTabId) {
      return AssetsTabType.ALL;
    }

    return searchParams.activeTabId as AssetsTabType;
  }, [getUrlSearchParams().activeTabId]);

  const gridRef = useRef<AgGridEvent | null>(null);

  const hasExceptionsReadPermission = permissionActions.includes(
    ApplicationPermission.EXCEPTIONS_READ
  );

  const assetsSearchParams = useCommonSelector(getAssetsSearchParams);

  const [
    createCustomFilter,
    {
      isSuccess: customFilterCreatedSuccessfully,
      isError: customFilterFailed,
      reset: resetCreateCustomFilter,
    },
  ] = useCreateCustomFilterMutation();

  const activeTabId = useMemo<AssetsTabType>(() => {
    const activeGridTab: AssetsTabType =
      urlSearchParams.activeTabId as AssetsTabType;
    dispatch(
      setSearchParams({
        ...assetsSearchParams,
        skip: 0,
      })
    );
    dispatch(setActiveGridTab(activeGridTab));
    return activeGridTab;
  }, [urlSearchParams]);

  const setFilterItems = useMemo(() => {
    return setGridFilterState;
  }, [activeTabId]);

  const filterState = useCommonSelector(getGridFilterState);

  const filterSelections = useCommonSelector(getGridFilterSelections);

  const getFilterState = useMemo(() => {
    return getGridFilterState;
  }, [activeTabId]);

  const handleOnSave = (filterName: string) => {
    const filters = JSON.parse(getUrlSearchParams().filter as string);
    createCustomFilter({
      name: filterName,
      customFilterEntityType: CustomFilterType.ASSET,
      filter: filters,
    });
    setOpenCustomFilterModal(false);
  };
  const quickFilterItems = useMemo<QuickFiltersList>(() => {
    if (hasExceptionsReadPermission)
      return {
        savedFilters: [],
        systemFilters: [],
      };

    return {
      savedFilters: [],
      systemFilters: [],
    };
  }, [user, hasExceptionsReadPermission]);

  useEffect(() => {
    const queryFilters =
      advancedFilterDataHandler.translateFilterStateSelectionsToQueryFilters(
        categoryState,
        assetsViewFilterCategories
      );

    if (Object.keys(queryFilters)) {
      const currentSearchParams = getUrlSearchParams();
      const updatedSearchParams = {
        ...currentSearchParams,
        filter: JSON.stringify(queryFilters),
      };

      setReactiveSearchParams(updatedSearchParams);
    }
  }, [categoryState]);

  useEffect(() => {
    if (reactiveSearchParams.has('searchKeyword')) {
      let updatedFilterParams = {
        ...assetsSearchParams.filter,
      };

      updatedFilterParams = {
        ...updatedFilterParams,
        nameKeyword: reactiveSearchParams.get('searchKeyword') || '',
      };

      dispatch(
        setSearchParams({
          ...assetsSearchParams,
          filter: updatedFilterParams,
        })
      );
    }
  }, [reactiveSearchParams.get('searchKeyword')]);

  useEffect(() => {
    if (filterSelections.length) {
      const searchParams = getUrlSearchParams();

      if (!searchParams.filter) {
        const filterQuery = JSON.stringify(
          advancedFilterDataHandler.translateAdvanceFilterComponentValuesToUrlSearchParams(
            filterSelections
          )
        );

        setUrlSearchParams({
          ...searchParams,
          filter: filterQuery,
        });
      }
    }
  }, [filterSelections]);

  useEffect(() => {
    if (!urlSearchParams.activeTabId) {
      setUrlSearchParams({
        activeTabId: AssetsTabType.ALL,
      });
    } else {
      const existingParams = filterSearchParamsHandler.setSearchParamsForKeys(
        urlSearchParams,
        ['filter', 'activeTabId', 'searchKeyword']
      );

      const order = AssetsDefaultOrder;

      setUrlSearchParams({
        ...existingParams,
        order: JSON.stringify(order),
      });
    }
  }, []);

  const clearFiltersFromStore = () => {
    const updatedArray = filterState.map((obj: any) => {
      if (!obj?.dateRange) {
        return {
          ...obj,
          dateRange: null,
          items: obj.items.map((item: any) => {
            return { ...item, checked: false };
          }),
        };
      }
      return {
        ...obj,
        dateRange: null,
      };
    });
    dispatch(setFilterItems({ list: updatedArray, isSelectingItem: true }));
    dispatch(setSearchParams({ ...assetsSearchParams, filter: {} }));
  };

  const clearFiltersFromUrl = () => {
    const existingUrlSearchParams =
      filterSearchParamsHandler.setSearchParamsForKeys({}, []);

    setUrlSearchParams({
      activeTabId: existingUrlSearchParams.activeTabId,
      order: existingUrlSearchParams.order,
    });
  };

  const clearFilters = () => {
    clearFiltersFromStore();
    clearFiltersFromUrl();
  };

  useEffect(() => {
    return () => {
      clearFiltersFromStore();
    };
  }, []);

  const handleRowClicked = (event: RowClickedEvent) => {
    const updatedReactiveSearchParams = new URLSearchParams(
      getUrlSearchParams()
    );
    updatedReactiveSearchParams.set(
      'openResourceId',
      event?.data?.assetUniqueId
    );
    setReactiveSearchParams(updatedReactiveSearchParams);
  };

  const renderTableForTab = () => {
    switch (activeTabId) {
      case AssetsTabType.CODE:
        return (
          <AssetsCodeGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case AssetsTabType.ARTIFACT:
        return (
          <AssetsArtifactGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case AssetsTabType.CLOUD:
        return (
          <AssetsCloudGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case AssetsTabType.ONPREM:
        return (
          <AssetsOnPremGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      case AssetsTabType.DOMAIN:
        return (
          <AssetsDomainGrid
            clearFilters={clearFilters}
            gridRef={gridRef}
            onRowClicked={handleRowClicked}
          />
        );
      default:
        if (!urlSearchParams.activeTabId) {
          setUrlSearchParams({
            activeTabId: AssetsTabType.ALL,
          });
        }
        return (
          <AssetsAllGrid
            gridRef={gridRef}
            clearFilters={clearFilters}
            onRowClicked={handleRowClicked}
          />
        );
    }
  };

  const selectGridTab = (activeItemId: AssetsTabType) => {
    const urlSearchParams = getUrlSearchParams();

    setUrlSearchParams({
      ...urlSearchParams,
      activeTabId: activeItemId,
    });
  };

  const saveFilters = () => {
    setOpenCustomFilterModal(true);
  };

  const enrichCategoriesByLayerPayload = (
    categories: Array<ExtendedFilterCategory>
  ): Array<ExtendedFilterCategory> => {
    return categories.map((category) => {
      if (category.id === 'risk') return category;

      if (category.categories) {
        return {
          ...category,
          categories: enrichCategoriesByLayerPayload(category.categories),
        };
      }

      if (category.id === 'scopeId' || category.id === 'groupId')
        return category;

      const selectionState = category.state as SingleSelectState;

      if (selectionState?.source) {
        return {
          ...category,
          state: {
            ...selectionState,
            source: {
              ...selectionState.source,
              body: {
                ...((selectionState.source as any)?.body || {}),
                filter: {
                  layer:
                    activeLayer === AssetsTabType.ALL
                      ? [
                          AssetsTabType.CODE,
                          AssetsTabType.ARTIFACT,
                          AssetsTabType.CLOUD,
                          AssetsTabType.ONPREM,
                          AssetsTabType.DOMAIN,
                        ]
                      : [activeLayer],
                },
              },
            },
          },
        };
      }

      return category;
    });
  };

  const enrichCategoryGroupItem = (
    categories: Array<ExtendedFilterCategory>
  ): Array<ExtendedFilterCategory> => {
    const selectedScopeOption = categoryState['scopeId'] as SingleSelectState;

    const selectedScopeId = selectedScopeOption?.selectedOptions
      ? selectedScopeOption?.selectedOptions[0]?.value
      : undefined;

    if (selectedScopeId) {
      return categories.map((category: ExtendedFilterCategory) => {
        if (category.id === 'organization') {
          return {
            ...category,
            categories: category.categories?.map(
              (subCategory: ExtendedFilterCategory) => {
                if (subCategory.id === 'groupId') {
                  return {
                    ...subCategory,
                    state: {
                      ...subCategory.state,
                      source: {
                        ...(subCategory.state as SingleSelectState).source,
                        body: {
                          ...((
                            (subCategory.state as SingleSelectState)
                              .source as ApiStaticOptionSourceProperties
                          ).body || {}),
                          startNodeId: selectedScopeId,
                        },
                      },
                    },
                  };
                }
                return subCategory;
              }
            ),
          };
        }

        return category;
      });
    }

    return categories;
  };

  const transformedCategories = useMemo<Array<ExtendedFilterCategory>>(() => {
    return enrichCategoriesByLayerPayload(
      enrichCategoryGroupItem(assetsViewFilterCategories)
    );
  }, [activeLayer, categoryState['scopeId']]);

  return (
    <div className="risk-page-body-container assets-page-body-container">
      <div className="risk-page-body-filter-row">
        <AdvanceFilter
          extendedTabComponent={
            <AssetsTabList
              items={tabListItems}
              activeItemId={activeTabId}
              onSelect={selectGridTab}
            />
          }
          customFilterType={CustomFilterType.ASSET}
          saveFilters={saveFilters}
          onSearch={(searchInput) => {
            const urlSearchParams = getUrlSearchParams();

            setReactiveSearchParams({
              ...urlSearchParams,
              searchKeyword: searchInput,
            });
          }}
          searchValue={reactiveSearchParams.get('searchKeyword') || ''}
          mutationProps={{
            params: {},
            fetchMutation: useFetchFilterInformationForFieldMutation,
          }}
          quickFilterItems={quickFilterItems}
          initialFilterItems={assetsFilterItems}
          setListFilter={setFilterItems}
          getFilterList={getFilterState}
          categories={transformedCategories}
          categoryState={categoryState}
          onChange={(categoryId: string, state: CategoryState) => {
            dispatch(
              setFilterState({
                categoryId,
                state,
              })
            );
          }}
          onRemoveChip={(categoryId: string) => {
            dispatch(
              removeFilterItem({
                categoryId,
              })
            );
          }}
          onClearChipState={(categoryId) => {
            dispatch(
              setFilterState({
                categoryId,
                state: {},
              })
            );
          }}
          clearFilters={() => {
            dispatch(clearFilterState());
          }}
          replaceFilterState={(state: Record<string, CategoryState>) => {
            dispatch(replaceFilterState({ updatedState: state }));
          }}
          rootClassName="asset-view-advance-filter"
          staticCategoryIds={[
            'type',
            'businessUnitId',
            'workspace',
            'operatingSystem',
          ]}
        />
      </div>

      <div className="risk-page-body-table">{renderTableForTab()}</div>
      <FindingCodeEventDrawerComplex />
      <ResourceAssetDetailsDrawer />
      <CustomFilterModal
        handleModalClose={() => {
          setOpenCustomFilterModal(false);
        }}
        onSave={handleOnSave}
        open={openCustomFilterModal}
      />
      {(customFilterCreatedSuccessfully || customFilterFailed) && (
        <Snackbar
          open={customFilterCreatedSuccessfully || customFilterFailed}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={3000}
          onClose={() => resetCreateCustomFilter()}
        >
          <Alert
            severity={customFilterCreatedSuccessfully ? 'success' : 'error'}
            sx={{ minWidth: '100px !important' }}
          >
            <FormattedMessage
              id={
                customFilterCreatedSuccessfully
                  ? 'findings.filter.filterCreated'
                  : 'findings.filter.filterCreatedFailed'
              }
              defaultMessage={
                customFilterCreatedSuccessfully
                  ? 'Filter is successfully saved'
                  : 'Filter saving failed'
              }
            />
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CircularProgress, TextField } from '@mui/material';
import OptionListItem from '../OptionListItem';
import { FilterOption } from 'shared/models/data/data-filter.model';
import { useFetchDataBasedOnParametersMutation } from 'shared/store/api';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { debounce } from 'lodash';

export enum OptionListType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

interface OptionListProps extends BaseComponentProps {
  title: string;
  mode: OptionListType;
  open?: boolean;
  dynamicOptionsUrl?: string;
  dynamicOptionsMethod?: string;
  dynamicSearchProperty?: string;
  appendSearchValueToUrl?: boolean;
  options?: Array<FilterOption>;
  extraOptions?: Array<FilterOption>;
  optionsTransformer?: (rawOptions: Array<any>) => Array<FilterOption>;
  onOptionsSelect?: (
    options: Array<FilterOption>,
    isAllSelected?: boolean
  ) => void;
  onAllSelect?: (options: Array<FilterOption>) => void;
  selectedOptions?: Array<FilterOption>;
  dynamicOptionsBodyParameters?: any;
  actionHandlers?: {
    goBackHandler?: () => void;
  };
  allSelected?: boolean;
  priorityOptionValues?: Array<string>;
}

export const OptionList: FunctionComponent<OptionListProps> = ({
  title,
  mode,
  dynamicOptionsUrl,
  dynamicOptionsMethod,
  options,
  extraOptions,
  optionsTransformer,
  onOptionsSelect,
  selectedOptions,
  dynamicOptionsBodyParameters,
  open,
  actionHandlers,
  dynamicSearchProperty,
  onAllSelect,
  allSelected,
  appendSearchValueToUrl,
  priorityOptionValues = [],
}) => {
  const { t: translation } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');

  const [fetchOptions, { data: optionsData, isLoading: optionsDataLoading }] =
    useFetchDataBasedOnParametersMutation();

  useEffect(() => {
    if (dynamicOptionsUrl && open) {
      let searchBody = {
        ...(dynamicOptionsBodyParameters || {}),
      };

      fetchOptions({
        url: dynamicOptionsUrl,
        method: dynamicOptionsMethod || 'POST',
        data: searchBody,
      });
    }
  }, [dynamicOptionsUrl, open, dynamicOptionsMethod]);

  const searchApiOptions = (value: string) => {
    let searchBody = {
      ...(dynamicOptionsBodyParameters || {}),
      ...(dynamicSearchProperty
        ? {
            [dynamicSearchProperty]: value,
          }
        : {}),
    };

    fetchOptions({
      url: dynamicOptionsUrl,
      method: dynamicOptionsMethod || 'POST',
      data: searchBody,
    });
  };

  const searchOptions = (
    options: Array<FilterOption>,
    searchValue: string
  ): Array<FilterOption> => {
    if (searchValue.length === 0) return options;

    return options.filter((option: FilterOption) =>
      (option.label || option.value)
        ?.toLowerCase()
        .includes(searchValue.toLowerCase().trim())
    );
  };

  const isOptionSelected = (option: FilterOption) => {
    return Boolean(
      selectedOptions?.find(
        (selectedOption: FilterOption) => selectedOption.value === option.value
      )
    );
  };

  const renderTitleArea = () => {
    return (
      <div className="filter-title-container">
        {actionHandlers?.goBackHandler ? (
          <div className="filter-go-back-button">
            <div
              onClick={() => {
                actionHandlers.goBackHandler && actionHandlers.goBackHandler();
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.ARROW_LEFT_ICON} />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="filter-title">{title}</div>
      </div>
    );
  };

  const searchInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);

    if (dynamicOptionsUrl && open && dynamicSearchProperty) {
      searchApiOptions(value);
    }
  };

  const renderSearchInput = () => {
    return (
      <TextField
        className="filter-input filter-main-input"
        placeholder={translation(`common.searchPlaceholder`)}
        onChange={debounce(searchInputHandler, dynamicSearchProperty ? 250 : 0)}
        InputProps={{
          className: 'search-filter-input',
          startAdornment: (
            <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
          ),
        }}
      />
    );
  };

  const renderExtraOptions = () => {
    if (extraOptions?.length) {
      return extraOptions.map((option: FilterOption) => (
        <OptionListItem
          {...option}
          enableCheckbox={mode === OptionListType.MULTIPLE}
          checked={isOptionSelected(option)}
          onClick={onOptionSelect}
        />
      ));
    }

    return <></>;
  };

  const renderAllOption = (optionData: Array<FilterOption>) => {
    if (mode === OptionListType.MULTIPLE)
      return (
        <OptionListItem
          value="All"
          label="All"
          checked={Boolean(allSelected)}
          enableCheckbox
          onClick={() => {
            onAllSelect && onAllSelect(optionData);
          }}
        />
      );

    return <></>;
  };

  const getOptionData = (): Array<FilterOption> => {
    if (optionsData) {
      if (optionsTransformer) {
        const allOptionData = [...optionsTransformer(optionsData)];

        return dynamicSearchProperty
          ? allOptionData
          : (searchOptions(allOptionData, searchValue) as Array<FilterOption>);
      }

      return dynamicSearchProperty
        ? optionsData
        : searchOptions(optionsData, searchValue);
    }

    if (options) return searchOptions(options, searchValue);

    return [];
  };

  const onOptionSelect = (value: string, label?: string) => {
    let currentSelectedOptions: Array<FilterOption> = [
      ...(selectedOptions || []),
    ];

    if (
      currentSelectedOptions.find(
        (selectedOption) => selectedOption.value === value
      )
    ) {
      currentSelectedOptions = currentSelectedOptions.filter(
        (selectedOption) => selectedOption.value !== value
      );
    } else {
      if (mode === OptionListType.MULTIPLE) {
        currentSelectedOptions = [
          ...currentSelectedOptions,
          {
            value,
            label,
          },
        ];
      } else {
        currentSelectedOptions = [
          {
            value,
            label,
          },
        ];
      }
    }

    const optionData = getOptionData();

    const allOptionData = [...optionData, ...(extraOptions || [])];

    onOptionsSelect &&
      onOptionsSelect(
        currentSelectedOptions,
        allOptionData.length === currentSelectedOptions.length
      );
  };

  const renderOptions = () => {
    if (optionsDataLoading)
      return (
        <div className="option-list-loading">
          <CircularProgress size={24} />
        </div>
      );

    const optionData = getOptionData();

    const sortedOptionData = optionData.sort((optionA, optionB) => {
      if (
        priorityOptionValues.length &&
        priorityOptionValues.includes(optionA.value)
      ) {
        return -1;
      }

      const optionAProperty: string = optionA.label || optionA.value;

      const optionBProperty: string = optionB.label || optionB.value;

      return optionAProperty?.localeCompare(optionBProperty);
    });

    if (optionData.length)
      return (
        <>
          {renderAllOption([...optionData, ...(extraOptions || [])])}
          {renderExtraOptions()}

          {sortedOptionData.map((option: FilterOption) => (
            <OptionListItem
              {...option}
              enableCheckbox={mode === OptionListType.MULTIPLE}
              checked={isOptionSelected(option)}
              onClick={onOptionSelect}
            />
          ))}
        </>
      );

    return (
      <div className="option-list-no-data option-list-text">
        {translation(`common.noData`)}
      </div>
    );
  };

  return (
    <div className="option-list">
      {renderTitleArea()}
      {renderSearchInput()}
      {renderOptions()}
    </div>
  );
};

import { useInitiateNewConnectionMutation } from 'ConnectionTemplate/store/api';
import ConnectionItem, {
  ConnectionItemCreate,
} from 'Integrations/Connection/interfaces/item';
import { useCheckConnectionExistsMutation } from 'Integrations/Connection/store/api';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';
import { useEffect, useRef, useState } from 'react';

interface OauthIntegrationPayload {
  connectionId: string;
  isLoading: boolean;
  isSuccess: boolean;
  error: string | null;
  handleAuthorize: (connectionItem: ConnectionItem) => void;
  handleReset: () => void;
  authorizedConnection: any;
}

export function useOauthIntegration(): OauthIntegrationPayload {
  const authLoaded = useRef<boolean>(false);
  const [initiateNewConnection, { data: newConnectionData }] =
    useInitiateNewConnectionMutation();
  const [testConnectionCreated, { isSuccess, isError, data: connectionData }] =
    useCheckConnectionExistsMutation();
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string | null>(null);

  function openPopup(state: string) {
    const width = 500;
    const height = 800;
    let url = newConnectionData?.redirectUrl;

    return window.open(
      url,
      '',
      'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
        width +
        ', height=' +
        height
    );
  }

  const reset = () => {
    setAuthError(null);
    setIsConnecting(false);
    authLoaded.current = false;
  };

  useEffect(() => {
    if (isSuccess) {
      setIsConnecting(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setIsConnecting(false);
      setAuthError('Authentication Failed');
    }
  }, [isError]);

  useEffect(() => {
    if (newConnectionData?.state) {
      polling(openPopup(newConnectionData?.state));
    }
  }, [newConnectionData?.state]);

  function handleClick(connectionData: ConnectionItem) {
    initiateNewConnection(connectionData as any);
    setAuthError(null);
    setIsConnecting(true);
  }

  function polling(popup: any) {
    const polling = setInterval(() => {
      // Auth popup fully loaded
      if (!authLoaded.current && popup.length === 2) {
        authLoaded.current = true;
      }

      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(polling);
        if (newConnectionData?.state)
          testConnectionCreated(newConnectionData?.state);
      }
    }, 1000);
  }

  return {
    isLoading: isConnecting,
    isSuccess: isSuccess,
    error: authError,
    handleAuthorize: handleClick,
    connectionId: newConnectionData?.state || '',
    handleReset: reset,
    authorizedConnection: connectionData,
  };
}

import { CircularProgress, Tooltip, Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardWidgetData {
  value: number | string;
}

interface DashboardWidgetCardProps extends BaseComponentProps {
  title: string;
  icon: ReactNode;
  isLoading: boolean;
  data: DashboardWidgetData;
  renderAddornment?: () => ReactNode;
  classes?: {
    container?: string;
    body?: string;
    header?: string;
  };
  onClick?: any;
  tooltip?: string;
}

export const DashboardWidgetCard: FunctionComponent<
  DashboardWidgetCardProps
> = ({
  title,
  icon,
  isLoading,
  data,
  renderAddornment,
  onClick,
  classes = {},
  tooltip = '',
}) => {
  const handleCardClick = () => {
    onClick && onClick();
  };

  const renderWidgetAddornment = () => {
    if (renderAddornment) {
      return renderAddornment();
    }

    return <></>;
  };

  const renderWidgetBody = () => {
    if (isLoading) {
      return <CircularProgress size={18} />;
    }

    return (
      <div className={`dashboard-widget-card-body ${classes.body || ''}`}>
        <div className="dashboard-widget-card-body-data">
          <Tooltip title={tooltip}>
            <Typography variant="inherit" className="header-5">
              {data?.value}
            </Typography>
          </Tooltip>
        </div>
        {renderWidgetAddornment()}
      </div>
    );
  };

  const renderIcon = () => {
    if (icon)
      return <div className="dashboard-widget-card-header-icon">{icon}</div>;

    return <></>;
  };

  return (
    <div
      className={`dashboard-widget-card-container ${classes.container || ''}`}
      onClick={handleCardClick}
    >
      <div className={`dashboard-widget-card-header ${classes.header || ''}`}>
        {renderIcon()}
        <div className="dashboard-widget-card-header-text">
          <h3 className="dashboard-widget-card-header-title">{title}</h3>
        </div>
      </div>
      {renderWidgetBody()}
    </div>
  );
};

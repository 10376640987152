import { Button, ButtonProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface ActionButtonProps extends BaseComponentProps, ButtonProps {
  children?: any;
  onClick?: () => void;
  icon?: SVG_ICON_TYPES;
}

export const ActionButton: FunctionComponent<ActionButtonProps> = ({
  icon,
  children,
  ...otherProps
}) => {
  return (
    <Button id="button-root" {...otherProps}>
      {icon ? (
        <OpusSvgIcon
          type={icon}
          style={{ height: '16px', marginRight: '6px' }}
        />
      ) : null}
      {children}
    </Button>
  );
};

import { ButtonBase, Popover } from '@mui/material';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface StepControlPopoverProps {
  children?: any;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  handleToolClick: () => void;
  handleCrossroadClick: () => void;
}

export function StepControlPopover({
  open,
  anchorEl,
  handleClose,
  handleToolClick,
  handleCrossroadClick,
}: StepControlPopoverProps) {
  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{
        paper: 'workflow-builder-tool-control-popover',
        root: 'workflow-builder-tool-control-popover-root',
      }}
    >
      <div className="workflow-builder-tool-control-popover-button-row">
        <ButtonBase
          onClick={handleToolClick}
          className="workflow-builder-tool-control-popover-button"
        >
          <div className="workflow-builder-tool-control-popover-button-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.SCREWDRIVER_WRENCH_ICON} />
          </div>
          <p className="workflow-builder-tool-control-popover-button-text">
            <FormattedMessage
              id="flows.details.addTool"
              defaultMessage="Add Tool"
            />
          </p>
        </ButtonBase>
        <ButtonBase
          onClick={handleCrossroadClick}
          className="workflow-builder-tool-control-popover-button"
        >
          <div className="workflow-builder-tool-control-popover-button-icon">
            <OpusSvgIcon type={SVG_ICON_TYPES.SITEMAP_ICON} />
          </div>
          <p className="workflow-builder-tool-control-popover-button-text">
            <FormattedMessage
              id="flows.details.addCrossroad"
              defaultMessage="Add Crossroad"
            />
          </p>
        </ButtonBase>
      </div>
    </Popover>
  );
}

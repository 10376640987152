import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonModalContainer } from '../CommonModalContainer';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface CommonWarningModalProps extends BaseComponentProps {
  isOpen: boolean;
  title: string;
  message: string;
  onCancel: () => void;
  onAccept: () => void;
}

export const CommonWarningModal: FunctionComponent<CommonWarningModalProps> = ({
  isOpen,
  title,
  message,
  onCancel,
  onAccept,
}) => {
  const { t: translation } = useTranslation();

  return (
    <CommonModalContainer handleClose={onCancel} isOpen={isOpen} title={title}>
      <div className="common-warning-modal-body">
        <div className="common-warning-modal-body-description">
          <span>{message}</span>
        </div>
        <div className="common-warning-modal-body-buttons">
          <Button className="base-opus-text-button" onClick={onCancel}>
            {translation(`common.stay`)}
          </Button>
          <Button className="base-opus-text-button" onClick={onAccept}>
            {translation(`common.yes`)}
          </Button>
        </div>
      </div>
    </CommonModalContainer>
  );
};

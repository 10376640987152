import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import HeaderComponent from '../Header/HeaderComponent';
interface PageHeaderProps extends BaseComponentProps {
  title: string | ReactJSXElement;
  sideElement?: ReactJSXElement;
}

export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  title,
  sideElement,
}) => {
  return <HeaderComponent text={title} sideElement={sideElement} />;
};

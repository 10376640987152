import { AutocompleteOption } from 'FindingDetails/store/api';
import { MinimalOrganizationNode } from 'shared/models/data/organization-node.model';

export class OrganizationNodeDataHandler {
  transformOrganizationNodesToAutocompleteOptions(
    nodes: Array<MinimalOrganizationNode>,
    addNoneOption?: boolean
  ): Array<AutocompleteOption> {
    const options = nodes.map((node: MinimalOrganizationNode) => ({
      value: node.id,
      label: node.name,
    }));

    if (addNoneOption) {
      options.unshift({
        value: 'None',
        label: 'None',
      });
    }

    return options;
  }
  getScopeLabel = (scopeId: string, scopeDataList: AutocompleteOption[]) => {
    const scopeData = scopeDataList?.find((scope) => scope.value === scopeId);
    return scopeData?.label as string;
  };
}

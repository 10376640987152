import { FileType } from 'shared/enums/supported-file-types-import.enum';
import { TenantPlans } from '../../Onboarding/models/onboarding-tenant-details.model';

export interface IntegrationItem {
  id: string;
  name: string;
  description?: string;
  logo: string;
  category: string;
  count: number;
  plan?: TenantPlans;
  subCategory?: IntegrationItemSubCategory;
  supportedImportFileTypes?: FileType[];
  isFileImportAvailable?: boolean;
}

export enum IntegrationItemSubCategory {
  POLLING = 'polling',
  WEBHOOK = 'webhook',
}

import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonCheckbox } from '../CommonCheckbox/CommonCheckbox';

interface CheckboxLabelProps extends BaseComponentProps {
  label: string;
  checked: boolean;
  onCheck?: (checked: boolean) => void;
  disabled?: boolean;
}

export const CheckboxLabel: FunctionComponent<CheckboxLabelProps> = ({
  label,
  checked,
  disabled,
  onCheck,
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  useEffect(() => {
    if (disabled) {
      setIsChecked(checked);
    }
  }, [checked]);

  const handleLabelClick = () => {
    if (disabled) return;

    setIsChecked((previousCheckedValue) => {
      onCheck && onCheck(!previousCheckedValue);
      return !previousCheckedValue;
    });
  };

  return (
    <div className="checkbox-label" onClick={handleLabelClick}>
      <div className="checkbox-label-text">{label}</div>
      <div className="checkbox-label-control">
        <CommonCheckbox disabled={disabled} checked={isChecked} />
      </div>
    </div>
  );
};

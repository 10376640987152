import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import CommonTooltip from 'Common/components/CommonTooltip/CommonTooltip';
import CommonSlider from 'Common/Slider';
import { useTranslation } from 'react-i18next';
import { RiskCustomizationConfigItem } from 'Settings/interfaces/RiskCustomizationConfig';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface RiskConfigItemProps extends RiskCustomizationConfigItem {
  weight?: number;
  onChange: (value: number) => void;
}

export const RiskConfigItem: React.FC<RiskConfigItemProps> = ({
  icon,
  title,
  description,
  type,
  weight = 0,
  onChange,
}) => {
  const { t: translation } = useTranslation();
  const [onChangeTriggered, setOnChangeTriggered] = useState(false);
  const permissionActions = usePermissions();
  const [value, setValue] = useState<number>(weight);
  const hasUpdateRiskScorePermission = permissionActions.includes(
    ApplicationPermission.SETTINGS_RISK_SCORE_WRITE
  );

  useEffect(() => {
    setValue(weight);
  }, [weight]);

  const handleChangeCommitted = () => {
    setOnChangeTriggered(true);
    onChange(value);
  };

  const handleChange = (event: any, newValue: number | number[]) => {
    setValue(newValue as number);
  };

  useEffect(() => {
    if (onChangeTriggered) {
      setOnChangeTriggered(false);
    }
  }, [onChangeTriggered]);

  return (
    <Grid container className="risk-config-item">
      <Grid item xs={7} className="risk-config-item-header">
        <div className="icon-container">
          <OpusSvgIcon type={icon}></OpusSvgIcon>
        </div>
        <div className="title-container">
          <p className="label-4">{translation(title)}</p>
          <CommonTooltip
            title={
              <p className="opus-body-text-1 tooltip-content">
                {translation(description)}
              </p>
            }
          >
            <div className="information-badge">
              <OpusSvgIcon type={SVG_ICON_TYPES.CIRCLE_INFO_ICON} />
            </div>
          </CommonTooltip>
        </div>
      </Grid>
      <Grid item xs={5}>
        <div className="slider">
          <CommonSlider
            value={value}
            onChange={handleChange}
            min={0}
            step={1}
            max={10}
            disabled={!hasUpdateRiskScorePermission}
            onChangeCommitted={handleChangeCommitted}
            valueLabelDisplay="off"
            aria-labelledby="non-linear-slider"
          />
          <div className="value-container">
            <Typography className="header-4" variant="inherit">
              {value}
            </Typography>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  useDeleteNotificationRuleMutation,
  useDeleteReportRuleMutation,
  useFetchReportRulesQuery,
  useSendReportRuleMutation,
  useUpdateNotificationRuleMutation,
} from 'Settings/store/api';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { SettingsLayout } from '../SettingsLayout/SettingsLayout';
import { usePermissions } from '../../../shared/hooks/usePermissionActions';
import { ApplicationPermission } from '../../../shared/enums/permission.enum';
import ForbiddenPage from '../../../Common/components/ForbiddenPage';
import SettingsGridColumns from '../SettingsLayout/settings-data-grid';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SettingsTabPanel } from 'Settings/interfaces/SettingTabPanel.enum';
import { DrawerMode } from 'shared/enums/business-unit.enum';
import { NotificationRule } from 'shared/models/data/notification-rule.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { ReportRule } from 'shared/models/data/report-rule.model';
import ReportRuleDrawerPanel from '../ReportRuleDrawerPanel';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import CommonSnackbarContainer from 'shared/components/CommonSnackbarContainer';
import { Alert } from '@mui/material';

interface ReportRuleListProps extends BaseComponentProps {}

export const ReportRuleList: FunctionComponent<ReportRuleListProps> = () => {
  const { t: translation } = useTranslation();
  const [shouldIgnoreRowClick, setShouldIgnoreRowClick] =
    useState<boolean>(false);
  const [tableItems, setTableItems] = useState<ReportRule[] | null>(null);
  const userPermissions = usePermissions();
  const hasReportRuleReadPermission = true;

  const [searchParams, setSearchParams] = useQueryParams();

  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [formChanged, setFormChanged] = useState<boolean>(false);

  const [formCancelationModalOpen, setFormCancelationModalOpen] =
    useState<boolean>(false);

  const { data: reportRules, isLoading: reportRulesLoading } =
    useFetchReportRulesQuery();

  const [updateNotificationRule, { isLoading: notificationRuleUpdating }] =
    useUpdateNotificationRuleMutation();

  const [
    sendReportRule,
    {
      isLoading: sendReportRuleLoading,
      error: sendReportError,
      isSuccess: sendReportSuccess,
      reset: resetSendReportPayload,
    },
  ] = useSendReportRuleMutation();

  useEffect(() => {
    if (searchParams.mode) {
      setPopoverOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    if (reportRules) {
      setTableItems(reportRules);
    }
  }, [reportRules]);

  useEffect(() => {
    setTimeout(() => {
      resetSendReportPayload();
    }, 3000);
  }, [sendReportError, sendReportSuccess]);

  const sendReportRuleHandler = (reportRule: ReportRule) => {
    sendReportRule(reportRule.id as string);
  };

  const notificationRulesColumns = useMemo(() => {
    return SettingsGridColumns.getReportRulesColumns({
      t: translation,
      useDeleteMutation: useDeleteReportRuleMutation,
      onDelete,
      onUpdate: updateNotificationRule,
      setShouldIgnoreRowClick,
      requiredPermissions: [
        ApplicationPermission.SETTINGS_NOTIFICATION_RULES_WRITE,
      ],
      isUpdating: notificationRuleUpdating,
      onSendReport: sendReportRuleHandler,
    });
  }, []);

  const drawerOpenHandler = () => {
    setSearchParams({
      activeTabId: SettingsTabPanel.REPORT_RULES,
      mode: DrawerMode.CREATE,
    });
  };

  const rowClickHandler = (rule: NotificationRule) => {
    if (
      userPermissions.includes(
        ApplicationPermission.SETTINGS_NOTIFICATION_RULES_WRITE
      )
    ) {
      setSearchParams({
        ...searchParams,
        mode: DrawerMode.EDIT,
        ruleId: rule.id as string,
      });
    }
  };

  const drawerCloseHandler = () => {
    setPopoverOpen(false);
    setSearchParams({
      activeTabId: SettingsTabPanel.REPORT_RULES,
    });
  };

  function onDelete(decision: boolean) {}

  const renderSendReportAlerts = () => {
    if (sendReportError) {
      return (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Alert severity="error">
            {`${translation(`settings.details.sendingReportError`)}: ${
              (sendReportError as any)?.data?.message
            }`}
          </Alert>
        </CommonSnackbarContainer>
      );
    }

    if (sendReportRuleLoading || sendReportSuccess) {
      return (
        <CommonSnackbarContainer
          open={true}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <Alert severity="info">
            {`${translation(`settings.details.sendingReportSuccess`)}`}
          </Alert>
        </CommonSnackbarContainer>
      );
    }

    return <></>;
  };
  const handlePopoverClose = () => {
    formChanged ? setFormCancelationModalOpen(true) : drawerCloseHandler();
  };

  if (!hasReportRuleReadPermission) return <ForbiddenPage />;

  return (
    <>
      <SettingsLayout<ReportRule>
        headerProps={{
          setTableItems: setTableItems,
          items: reportRules || [],
          filterPropertyName: 'name',
          onButtonClick: drawerOpenHandler,
          buttonRequiredPermissions: [
            ApplicationPermission.SETTINGS_REPORTS_RULES_WRITE,
          ],
          buttonLabel: (
            <FormattedMessage
              id="settings.details.addNewReportRule"
              defaultMessage="Add New Report Rule"
            />
          ),
          buttonType: 'button',
        }}
        tableProps={{
          rowData: tableItems || reportRules,
          columnDefs: notificationRulesColumns,
          isLoading: reportRulesLoading,
          onItemClick: (item) => rowClickHandler(item),
          deleteMutation: useDeleteNotificationRuleMutation,
          ignoreRowClick: shouldIgnoreRowClick,
        }}
        fetchData={() => {}}
      />
      <PopoverWrapper
        showPopover={popoverOpen}
        handleClose={handlePopoverClose}
      >
        <ReportRuleDrawerPanel
          onCancel={drawerCloseHandler}
          detectFormChanged={(isChanged: boolean) => setFormChanged(isChanged)}
          cancelationModalProps={{
            onClose: () => {
              setFormCancelationModalOpen(false);
            },
            onOpen: () => {
              setFormCancelationModalOpen(true);
            },
            open: formCancelationModalOpen,
          }}
        />
      </PopoverWrapper>
      {renderSendReportAlerts()}
    </>
  );
};

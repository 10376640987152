import { FunctionComponent, useEffect, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { IntegrationModalContentWrapper } from '../IntegrationModalContentWrapper/IntegrationModalContentWrapper';
import { BaseIntegrationModalContainer } from '../BaseIntegrationModalContainer/BaseIntegrationModalContainer';
import { BaseIntegrationModalCreateForm } from '../BaseIntegrationModalCreateForm/BaseIntegrationModalCreateForm';
import {
  BaseIntegrationModalEditForm,
  IntegrationAdditionalContentFormProps,
} from '../BaseIntegrationModalEditForm/BaseIntegrationModalEditForm';
import {
  IntegrationItem,
  IntegrationItemSubCategory,
} from 'Integrations/interfaces/IntegrationItem.model';
import { FindingSourceContent } from '../FindingSourceContent/FindingSourceContent';
import { PollingContent } from '../PollingContent/PollingContent';
import { PollingSubscription } from 'Integrations/Webhook/interfaces/polling-subscription';
import WebhookItem from 'Integrations/Webhook/interfaces/item';
import ConnectionTemplateItem from 'ConnectionTemplate/interfaces/item';

interface BaseIntegrationModalProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

export const BaseIntegrationModal: FunctionComponent<
  BaseIntegrationModalProps
> = ({
  open,
  onCancel,
  integrationItem,
  connectionId,
  onCreateSuccess,
  businessUnitRequired = true,
}) => {
  const [submited, setSubmited] = useState<boolean>(false);
  const [parentConnectionTemplate, setParentConnectionTemplate] = useState<
    ConnectionTemplateItem | undefined
  >(undefined);

  const renderAdditionalContent = (
    isVisible: boolean,
    toggleVisibility: (visible: boolean) => void,
    data?: PollingSubscription | WebhookItem,
    formProps?: IntegrationAdditionalContentFormProps
  ) => {
    switch (integrationItem.subCategory) {
      case IntegrationItemSubCategory.POLLING:
        return (
          <PollingContent
            submited={submited}
            setSubmited={setSubmited}
            connectionId={connectionId}
            id={integrationItem.id}
            pollingSubscription={data as PollingSubscription}
            formProps={formProps}
            isVisible={isVisible}
            toggleVisibility={toggleVisibility}
          />
        );
      case IntegrationItemSubCategory.WEBHOOK:
        return (
          <FindingSourceContent
            webhook={data as WebhookItem}
            submited={submited}
            connectionId={connectionId}
            setSubmited={setSubmited}
            id={integrationItem.id}
            connectionTemplate={parentConnectionTemplate}
          />
        );
      default:
        return <></>;
    }
  };

  const handleConnectionTemplateChange = (
    template?: ConnectionTemplateItem
  ) => {
    setParentConnectionTemplate(template);
  };

  const handleAfterCreateConnection = (connection: IntegrationItem): void => {
    if (onCreateSuccess) onCreateSuccess(connection.id);
  };
  const renderModalForm = () => {
    if (connectionId) {
      return (
        <BaseIntegrationModalEditForm
          {...{
            onConnectHandler: setSubmited,
            integrationItem,
            onCancel,
            open,
            connectionId,
            renderAdditionalContent,
            onConnectionTemplateChange: handleConnectionTemplateChange,
            businessUnitRequired,
          }}
        />
      );
    }

    return (
      <BaseIntegrationModalCreateForm
        handleOnCreate={handleAfterCreateConnection}
        {...{
          integrationItem,
          onCancel,
          open,
          renderAdditionalContent,
          onConnectionTemplateChange: handleConnectionTemplateChange,
          businessUnitRequired,
        }}
      />
    );
  };

  return (
    <BaseIntegrationModalContainer
      open={open}
      onClose={onCancel}
      integrationItem={integrationItem}
    >
      <IntegrationModalContentWrapper
        tutorialLink={parentConnectionTemplate?.docsLink}
        tutorialName={`How to integrate with ${integrationItem.name}?`}
        description={parentConnectionTemplate?.description}
      >
        {renderModalForm()}
      </IntegrationModalContentWrapper>
    </BaseIntegrationModalContainer>
  );
};

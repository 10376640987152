import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { OrganizationNodesSelected } from 'Organization/interfaces/OrganizationNodesSelected.interface';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';

export class OrganizationNodeDataHandler {
  constructor(private nodeData: OrganizationNode) {}

  transformOrganizationNodeIdToChipData(nodeIds: string[]) {
    const groupedNodes: Record<string, OrganizationNode[]> = {};
    const includedTypes: Record<string, boolean> = {};
    nodeIds?.forEach((id) => {
      const node = this.findObjectById(id);
      if (node) {
        groupedNodes[node.type] = groupedNodes[node.type] || [];
        groupedNodes[node.type].push(node);
        const allItemsIncluded =
          groupedNodes[node.type].length === nodeIds.length;
        includedTypes[node.type] = allItemsIncluded;
      }
    });

    return { groupedNodes, includedTypes } as OrganizationNodesSelected;
  }

  tranformChipRawDataToAutocomplete(data?: OrganizationNodesSelected): any[] {
    if (!data || !data.groupedNodes) return [];

    const getTypeLabel = (type: OrganizationNodeType) => {
      if (type === OrganizationNodeType.LOGICAL) return 'Groups';
      if (type === OrganizationNodeType.DATA) return 'Services';
      return type;
    };

    const result = (
      Object.keys(data.groupedNodes) as OrganizationNodeType[]
    ).flatMap((key: OrganizationNodeType) => {
      if (key === OrganizationNodeType.SCOPE && data.includedTypes) {
        return [
          {
            type: OrganizationNodeType.LOGICAL,
            label: 'All Groups',
          },
          {
            type: OrganizationNodeType.DATA,
            label: 'All Services',
          },
        ];
      }

      return {
        type: key,
        label: `${data.groupedNodes[key].length} ${getTypeLabel(key)}`,
      };
    });

    return result;
  }

  findObjectById(id: string): OrganizationNode | null {
    if (this.nodeData) return this.findNodeRecursively([this.nodeData], id);
    return null;
  }

  private findNodeRecursively(
    nodes: OrganizationNode[],
    id: string
  ): OrganizationNode | null {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      }
      if (node.children && node.children.length > 0) {
        const childResult = this.findNodeRecursively(node.children, id);
        if (childResult) {
          return childResult;
        }
      }
    }
    return null;
  }
  findNodesByIds(ids: string[]) {
    const nodes = [];
    for (const id of ids) {
      const node = this.findObjectById(id);
      if (node) {
        nodes.push(node);
      }
    }
    return nodes;
  }
}

import { Avatar } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { OpusSvgIcon } from '../IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { IconBox } from '../IconBox/IconBox';

interface AvatarBoxProps extends BaseComponentProps {
  name?: string;
  profileUrl?: string;
  role?: string;
  displayAvatar?: boolean;
}

export const AvatarBox: FunctionComponent<AvatarBoxProps> = ({
  name,
  profileUrl,
  role,
  displayAvatar = true,
}) => {
  return (
    <IconBox
      icon={
        displayAvatar ? (
          profileUrl?.length ? (
            <Avatar
              className="avatar-box-profile-icon"
              alt={name}
              src={profileUrl}
            />
          ) : (
            <Avatar
              className="avatar-box-profile-icon"
              sx={{
                background: '#F5F5F5',
              }}
              alt={name}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.USER_ICON} />
            </Avatar>
          )
        ) : null
      }
      label={name || ''}
      helperText={role}
    />
  );
};

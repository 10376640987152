import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { DropdownPicker } from './DropdownPicker';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { FindingStatus } from 'FindingDetails/interfaces/status';
import { Typography } from '@mui/material';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { StatusItemsList } from 'shared/fixtures/data/status-items-list';

interface StatusPickerProps {
  selectedStatusValue?: string;
  readonly?: boolean;
  itemId?: string;
  property?: string;
  updateItemMutation?: any;
}

interface StatusItem {
  icon: SVG_ICON_TYPES;
  id: string;
  hidden?: boolean;
}

export const StatusPicker: FunctionComponent<StatusPickerProps> = ({
  selectedStatusValue = FindingStatus.OPEN,
  readonly,
  itemId,
  property,
  updateItemMutation,
}) => {
  const statusList: StatusItem[] = useMemo(() => {
    return StatusItemsList;
  }, []);
  const selectedStatus = statusList.find(
    (status) => status.id === selectedStatusValue
  ) || { id: selectedStatusValue };
  const [currentStatus, setCurrentStatus] = useState<any>(selectedStatus);
  const updateItemMutationPayload =
    !readonly && updateItemMutation && updateItemMutation();

  useEffect(() => {
    setCurrentStatus(selectedStatus);
  }, [selectedStatus]);

  const renderButtonContent = () => (
    <>
      <OpusSvgIcon
        title={currentStatus.id}
        className={'chip-status-icon'}
        type={currentStatus?.icon}
      />
      <Typography className={'menu-chip-text'}>{currentStatus?.id}</Typography>
    </>
  );

  const renderItem = (item: any) => (
    <>
      <OpusSvgIcon title={item.id} className={'status-icon'} type={item.icon} />
      <Typography className="menu-item-text">{item.id}</Typography>
    </>
  );

  const onSelectItem = (item: StatusItem) => {
    if (readonly || !property || !itemId) return;
    setCurrentStatus(item);
    updateItemMutationPayload[0] &&
      updateItemMutationPayload[0]({
        [property]: itemId,
        body: {
          status: item.id,
        },
      });
  };

  return (
    <>
      <DropdownPicker
        listItems={statusList.filter((statusItem) => !statusItem.hidden)}
        selectedListItem={currentStatus}
        readonly={readonly || Boolean(currentStatus?.hidden)}
        renderButtonContent={renderButtonContent}
        renderItem={renderItem}
        onListItemClick={onSelectItem}
      />
    </>
  );
};

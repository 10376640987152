import { FunctionComponent, useEffect } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { BaseIntegrationModalContainer } from '../BaseIntegrationModalContainer/BaseIntegrationModalContainer';
import { IntegrationModalContentWrapper } from '../IntegrationModalContentWrapper/IntegrationModalContentWrapper';
import { AwsIntegrationModalCreateForm } from '../AwsIntegrationModalCreateForm/AwsIntegrationModalCreateForm';
import { AwsIntegrationModalEditForm } from '../AwsIntegrationModalEditForm/AwsIntegrationModalEditForm';
import { useSearchConnectionTemplatesMutation } from 'ConnectionTemplate/store/api';
import { CloudProviderType } from 'shared/models/data/integrations.model';
import { useGetCloudConnectionTemplatesMutation } from 'Integrations/Connection/store/api';

interface AwsIntegrationModalProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

export const AwsIntegrationModal: FunctionComponent<
  AwsIntegrationModalProps
> = ({ integrationItem, open, onCancel, connectionId }) => {
  const [
    searchConnectionTemplates,
    { data: connectionTemplateData, isLoading: connectionTemplateDataLoading },
  ] = useGetCloudConnectionTemplatesMutation();

  useEffect(() => {
    if (open) searchConnectionTemplates(CloudProviderType.AWS);
  }, [open]);

  const renderModalForm = () => {
    if (connectionId) {
      return (
        <AwsIntegrationModalEditForm
          {...{
            integrationItem,
            open,
            onCancel,
            connectionId,
            connectionTemplateData,
            connectionTemplateDataLoading,
          }}
        />
      );
    }

    return (
      <AwsIntegrationModalCreateForm
        {...{
          integrationItem,
          open,
          onCancel,
          connectionTemplateData,
          connectionTemplateDataLoading,
        }}
      />
    );
  };

  return (
    <BaseIntegrationModalContainer
      integrationItem={integrationItem}
      open={open}
      onClose={onCancel}
      title={`${integrationItem.name} Subscription`}
    >
      <IntegrationModalContentWrapper
        tutorialLink={connectionTemplateData?.read?.docsLink}
        tutorialName={`How to integrate with ${integrationItem.name}?`}
        description=""
      >
        <div className="cloud-integration-modal-content">
          {renderModalForm()}
        </div>
      </IntegrationModalContentWrapper>
    </BaseIntegrationModalContainer>
  );
};

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentSection from 'shared/components/ContentSection';
import FormattedMessage from 'shared/components/FormattedMessage';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import FindingContentMetrics from '../FindingContentMetrics';
import FindingContentScoreTags from '../FindingContentScoreTags';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { FunctionComponent } from 'react';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';

interface FindingContentIntelligenceVendorsProps extends BaseComponentProps {
  vendors: Array<string>;
}

export const FindingContentIntelligenceVendors: FunctionComponent<
  FindingContentIntelligenceVendorsProps
> = ({ vendors }) => {
  const renderBody = () => {
    if (vendors.length === 0)
      return <NoDataToDisplayCard displayDescription={false} />;

    return (
      <div className="finding-vendors-container">
        {vendors.map((item) => {
          return (
            <div className="threat-tag">
              <p className="body-1 bold">{item}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const { t: translation } = useTranslation();

  return (
    <div className="finding-content-item finding-intelligence-vendors">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-section-vendors"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.BUG_ICON} />}
        title={translation(`findings.sections.mostAffectedVendors`)}
      >
        {renderBody()}
      </ContentSection>
    </div>
  );
};

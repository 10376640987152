import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { OpusSvgIconProps } from 'shared/icons/interface';
import { IconFactory } from '../../../icons/utils';
import IconWrapper from '../IconWrapper';
import _ from 'lodash';

const iconFactory = new IconFactory();

interface OpusIconProps extends OpusSvgIconProps {
  children?: JSX.Element;
  title?: string;
  type: SVG_ICON_TYPES;
}

export const OpusSvgIcon = (props: OpusIconProps): JSX.Element => {
  const { title, type } = props;

  return (
    <IconWrapper title={title}>
      {iconFactory.getSvgIconByType(
        type,
        _.omit(props, ['title', 'children', 'type'])
      )}
    </IconWrapper>
  );
};

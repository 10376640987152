import React, { FunctionComponent } from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import BaseOrganizationNodeModalContainer from '../BaseOrganizationNodeModalContainer';
import OrganizationNodeForm from '../OrganizationNodeForm';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { OrganizationModalMode } from 'Organization/interfaces/OrganizationModalMode.enum';

interface OrganizationNodeDialogProps {
  type?: OrganizationNodeType;
  open: boolean;
  onClose: () => void;
  nodeData?: OrganizationNode;
  mode: OrganizationModalMode;
}

const getTitle = (
  type: OrganizationNodeType | undefined,
  mode: OrganizationModalMode
): string => {
  const titleMode = mode === OrganizationModalMode.CREATE ? 'Add' : 'Edit';
  switch (type) {
    case OrganizationNodeType.ROOT:
      return `${titleMode} ${
        mode === OrganizationModalMode.CREATE ? 'Scope' : 'Organization'
      }`;
    case OrganizationNodeType.SCOPE:
    case OrganizationNodeType.LOGICAL:
      return `${titleMode} Group`;
    default:
      return '';
  }
};

const getIcon = (
  type: OrganizationNodeType | undefined
): React.ReactElement => {
  const iconType =
    type === OrganizationNodeType.ROOT
      ? SVG_ICON_TYPES.SLIDERS_ICON
      : SVG_ICON_TYPES.GROUP_WITH_PEOPLE_ICON;

  return (
    <OpusSvgIcon type={iconType} className="common-modal-header-close-icon" />
  );
};

export const OrganizationNodeDialog: FunctionComponent<
  OrganizationNodeDialogProps
> = ({ type, open, onClose, nodeData, mode }) => (
  <BaseOrganizationNodeModalContainer
    open={open}
    onClose={onClose}
    title={getTitle(type, mode)}
    icon={getIcon(type)}
  >
    {type && (
      <OrganizationNodeForm
        type={type}
        mode={mode}
        nodeData={nodeData}
        onClose={onClose}
      />
    )}
  </BaseOrganizationNodeModalContainer>
);

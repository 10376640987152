import numeral from 'numeral';

export class NumberFormatHandler {
  formatNumberToString(number: number) {
    if (number >= 999) {
      return numeral(number).format('0.0a').toUpperCase();
    } else {
      return number;
    }
  }
}

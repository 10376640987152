import { FunctionComponent, useEffect } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { CommonIntegrationModalProps } from '../../IntegrationModal';
import { BaseIntegrationModalContainer } from '../BaseIntegrationModalContainer/BaseIntegrationModalContainer';
import { AzureIntegrationModalCreateForm } from '../AzureIntegrationModalCreateForm';
import { IntegrationModalContentWrapper } from '../IntegrationModalContentWrapper/IntegrationModalContentWrapper';
import { AzureIntegrationModalEditForm } from '../AzureIntegrationModalEditForm/AzureIntegrationModalEditForm';
import { useGetCloudConnectionTemplatesMutation } from 'Integrations/Connection/store/api';
import { CloudProviderType } from 'shared/models/data/integrations.model';

interface AzureIntegrationModalProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

export const AzureIntegrationModal: FunctionComponent<
  AzureIntegrationModalProps
> = ({ integrationItem, open, connectionId, onCancel }) => {
  const [
    searchConnectionTemplates,
    { data: connectionTemplateData, isLoading: connectionTemplateDataLoading },
  ] = useGetCloudConnectionTemplatesMutation();

  useEffect(() => {
    if (open) searchConnectionTemplates(CloudProviderType.AZURE);
  }, [open]);

  const renderModalForm = () => {
    if (connectionId) {
      return (
        <AzureIntegrationModalEditForm
          {...{
            integrationItem,
            open,
            onCancel,
            connectionId,
            template: connectionTemplateData?.read,
          }}
        />
      );
    }

    return (
      <AzureIntegrationModalCreateForm
        {...{
          integrationItem,
          open,
          onCancel,
          template: connectionTemplateData?.read,
        }}
      />
    );
  };

  return (
    <BaseIntegrationModalContainer
      integrationItem={integrationItem}
      open={open}
      onClose={onCancel}
      title={`${integrationItem.name} Subscription`}
    >
      <IntegrationModalContentWrapper
        tutorialLink={connectionTemplateData?.read?.docsLink}
        tutorialName={`How to integrate with ${integrationItem.name}?`}
        description=""
      >
        <div className="cloud-integration-modal-content">
          {renderModalForm()}
        </div>
      </IntegrationModalContentWrapper>
    </BaseIntegrationModalContainer>
  );
};

import { SyntheticEvent } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import FormattedMessage from 'shared/components/FormattedMessage';

interface AlertDialogProps {
  onHandleConfirm: (event: SyntheticEvent<HTMLButtonElement>) => void;
  onHandleClose: (event: SyntheticEvent<HTMLButtonElement>) => void;
  text: string;
  open: boolean;
}

export function AlertDialog({
  text,
  onHandleConfirm,
  onHandleClose,
  open,
}: AlertDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onHandleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
      <DialogActions>
        <Button onClick={onHandleClose}>
          <FormattedMessage
            capitalize
            id="common.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          onClick={onHandleConfirm}
          autoFocus
          variant="outlined"
          color="error"
        >
          <FormattedMessage
            capitalize
            id="common.confirm"
            defaultMessage="Confirm"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

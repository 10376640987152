import { Snackbar, SnackbarProps } from '@mui/material';
import { FunctionComponent } from 'react';

interface CommonSnackbarContainerProps extends SnackbarProps {}

export const CommonSnackbarContainer: FunctionComponent<
  CommonSnackbarContainerProps
> = (props) => {
  return (
    <Snackbar
      {...props}
      className={`common-snackbar-container ${props.className || ''}`}
    >
      {props.children}
    </Snackbar>
  );
};

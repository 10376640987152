import { Slider, SliderProps } from '@mui/material';

interface CommonSliderProps extends SliderProps {}
export const CommonSlider: React.FC<CommonSliderProps> = (props) => {
  return (
    <Slider
      {...props}
      classes={{
        root: 'common-slider-container',
        thumb: 'common-slider-thumb',
        rail: 'common-slider-rail',
        track: 'common-slider-track',
      }}
    ></Slider>
  );
};

import { FunctionComponent, useState } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  SelectChipCategory,
  SelectChipChangePayload,
  SelectChipGroupMutationProps,
} from '../SelectChipGroup';
import { AutocompleteOption } from 'FindingDetails/store/api';
import { SelectChipOptionDropdown } from '../SelectChipOptionDropdown/SelectChipOptionDropdown';
import { SelectChipCategoryDropdown } from '../SelectChipCategoryDropdown/SelectChipCategoryDropdown';
import { Popover } from '@mui/material';
import { OpusSvgIcon } from 'shared/components/IconComponents/OpusSvgIcon/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface SelectChipProps extends BaseComponentProps {
  label: string;
  categories: Array<SelectChipCategory>;
  onChange: (categoryId: string, values: Array<AutocompleteOption>) => void;
  displayMode: 'single' | 'multiple';
  selectedValues: SelectChipChangePayload;
  enableClearIcon?: boolean;
  mutationProps?: SelectChipGroupMutationProps;
  hasDropdown?: boolean;
}

export const SelectChip: FunctionComponent<SelectChipProps> = ({
  label,
  categories,
  onChange,
  displayMode,
  selectedValues,
  enableClearIcon = false,
  mutationProps,
  hasDropdown = true,
}) => {
  const [dropdownAnchorEl, setDropdownAnchorEl] =
    useState<HTMLDivElement | null>(null);

  const handleChipClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (hasDropdown) setDropdownAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setDropdownAnchorEl(null);
  };

  const handleChipRemove = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    onChange(categories[0].id, []);
  };

  const dropdownOpen = Boolean(dropdownAnchorEl);

  const renderSelectChipPopover = () => {
    if (categories.length === 1) {
      return (
        <SelectChipOptionDropdown
          {...categories[0]}
          onChange={onChange}
          selectedValues={selectedValues[categories[0].id]}
          backClickHandle={handleDropdownClose}
          enableHeader={displayMode === 'multiple'}
          mutationProps={categories[0]?.mutationProps || mutationProps}
        />
      );
    }

    return (
      <SelectChipCategoryDropdown
        categories={categories}
        onChange={onChange}
        selectedValues={selectedValues}
        mutationProps={mutationProps}
      />
    );
  };

  const renderSelectedValuesText = () => {
    if (displayMode === 'single' && Object.keys(selectedValues).length) {
      const activeCategory = categories[0];
      const selectedValuesForSingleProperty = selectedValues[activeCategory.id];

      return (
        <div className="select-chip-values">
          {selectedValuesForSingleProperty
            .map((valueItem) => valueItem.label || valueItem.value)
            .join(activeCategory?.delimiter || ' OR ')}
        </div>
      );
    }

    return <></>;
  };

  const renderClearIcon = () => {
    if (enableClearIcon) {
      return (
        <div className="select-chip-close-icon" onClick={handleChipRemove}>
          <OpusSvgIcon type={SVG_ICON_TYPES.CLOSE_ICON} height={15} />
        </div>
      );
    }

    return <></>;
  };

  return (
    <>
      <div className="select-chip-container" onClick={handleChipClick}>
        <div className="select-chip-inner-container">
          <div
            className={`select-chip-label ${
              displayMode === 'multiple' ? 'select-chip-label-bold' : ''
            }`}
          >
            {label}
            {enableClearIcon ? ':' : ''}
          </div>
          {renderSelectedValuesText()}
          {hasDropdown && (
            <div className="select-chip-dropdown-icon select-chip-icon">
              <OpusSvgIcon type={SVG_ICON_TYPES.SORT_DESCENDING_ICON} />
            </div>
          )}
          {renderClearIcon()}
        </div>
      </div>
      {hasDropdown && (
        <Popover
          open={dropdownOpen}
          anchorEl={dropdownAnchorEl}
          onClose={handleDropdownClose}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          classes={{
            root: 'select-chip-group-popover',
          }}
        >
          {renderSelectChipPopover()}
        </Popover>
      )}
    </>
  );
};

import { useFeatureEntitlements } from '@frontegg/react';
import { ForbiddenPage } from 'Common/components/ForbiddenPage/ForbiddenPage';
import { FunctionComponent, useMemo } from 'react';
import { RoutePermissionModel } from 'shared/models/data/route.model';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { SystemFeatureFlag } from '../EntitledContent/EntitledContent';
import { PermissionContext } from '../PermissionContext/PermissionContext';

interface PermissionGuardProps
  extends BaseComponentProps,
    RoutePermissionModel {
  featureFlag?: SystemFeatureFlag;
}

export const PermissionGuard: FunctionComponent<PermissionGuardProps> = ({
  name,
  permissions,
  userPermissions,
  children,
  featureFlag,
}) => {
  const { isEntitled, justification } = useFeatureEntitlements(
    featureFlag || ''
  );

  const userHasPermission = useMemo<boolean>(() => {
    if (permissions?.length === 0) {
      return true;
    }

    return Boolean(
      userPermissions?.some((userPermission) =>
        permissions?.includes(userPermission)
      )
    );
  }, [permissions, userPermissions]);

  const content = userHasPermission ? (
    <PermissionContext.Provider value={{ permissions: userPermissions }}>
      {children}
    </PermissionContext.Provider>
  ) : (
    <ForbiddenPage />
  );

  return featureFlag ? isEntitled ? content : <ForbiddenPage /> : content;
};

import DiagramEdgeType from 'WorkflowBuilder/interfaces/edge-type';
import { NodeType } from 'WorkflowBuilder/interfaces/node';
import DiagramStep, {
  DIAGRAM_STEP_TYPE,
  Operator,
} from 'WorkflowBuilder/interfaces/step';
import { steps2edges, steps2nodes } from 'WorkflowBuilder/utils/step-transform';
import { createContext, useCallback, useState } from 'react';
import ReactFlow, {
  Background,
  Connection,
  useReactFlow,
} from 'react-flow-renderer';
import DiagramConditionEdge from '../ConditionEdge';
import CrossroadNode from '../CrossroadNode';
import DiagramEdge from '../Edge';
import PlusButtonNode from '../PlusButtonNode';
import DiagramToolNode from '../ToolNode';
import DotNode from '../DotNode';
import DiagramTriggerNode from '../WorkflowTriggerNode';
import { defaultConditionRoute } from '../CrossroadPanel/components/CrossroadPanelConditionRouteList/CrossroadPanelConditionRouteList';
import { LAYOUT_MODE } from 'WorkflowBuilder/interfaces/layout';

const nodeTypes = {
  [NodeType.TRIGGER]: DiagramTriggerNode,
  [NodeType.HTTP]: DiagramToolNode,
  [NodeType.INTERNAL]: DiagramToolNode,
  [NodeType.AWS]: DiagramToolNode,
  [NodeType.PLUS]: PlusButtonNode,
  [NodeType.CROSSROAD]: CrossroadNode,
  [NodeType.DOT]: DotNode,
  [NodeType.AZURE]: DiagramToolNode,
  [NodeType.MICROSOFT_TEAMS]: DiagramToolNode,
  [NodeType.WIZ]: DiagramToolNode,
  [NodeType.LACEWORK]: DiagramToolNode,
  [NodeType.GCP]: DiagramToolNode,
  [NodeType.CHECKMARX]: DiagramToolNode,
  [NodeType.CROWDSTRIKE]: DiagramToolNode,
  [NodeType.SNOWFLAKE]: DiagramToolNode,
};

const edgeTypes = {
  [DiagramEdgeType.PLUS_BUTTON]: DiagramEdge,
  [DiagramEdgeType.CONDITION]: DiagramConditionEdge,
};

interface DiagramProps {
  steps: DiagramStep[];
  setSteps?: any;
  stepDefs: Array<DiagramStep>;
  flowRef?: any;
  mode?: LAYOUT_MODE;
}

export const DiagramStepContext = createContext<{ steps: Array<DiagramStep> }>({
  steps: [],
});

export function Builder({
  steps,
  setSteps,
  stepDefs,
  flowRef,
  mode,
}: DiagramProps) {
  const { fitView } = useReactFlow();

  function handleNodesChange(nodeUpdateEvents: any[]) {
    // only handles position change
    setSteps &&
      setSteps((prevSteps: DiagramStep[]) => {
        const updatedStepSeqIds = nodeUpdateEvents.map((x) => +x.id);
        return prevSteps.map((step: DiagramStep) => {
          if (!updatedStepSeqIds.includes(step.workflowSeqId)) {
            return step;
          }
          const targetEvent = nodeUpdateEvents.find(
            (y) => +y.id === step.workflowSeqId
          );
          const { position } = targetEvent;
          return {
            ...step,
            diagramData: {
              ...step.diagramData,
              position: position ? position : step.diagramData.position,
              icon: step.diagramData.icon,
            },
          };
        });
      });
  }

  const onConnect = useCallback((params: Connection) => {
    setSteps((prevSteps: Array<DiagramStep>) => {
      return prevSteps.map((step: DiagramStep) => {
        if (step.workflowSeqId === Number(params.source)) {
          const existingRoute = step.routes.find(
            (route) => route.nextWorkflowSeqId === Number(params.target)
          );

          if (existingRoute) {
            return step;
          }

          if (step.type === DIAGRAM_STEP_TYPE.CROSSROAD) {
            return {
              ...step,
              routes: [
                ...step.routes,
                {
                  ...defaultConditionRoute,
                  nextWorkflowSeqId: Number(params.target),
                },
              ],
            };
          }

          return {
            ...step,
            routes: [
              ...step.routes,
              { nextWorkflowSeqId: Number(params.target) },
            ],
          };
        }

        return step;
      });
    });
  }, []);

  const onRemoveEdge = (source: string, target: string) => {
    setSteps((prevSteps: Array<DiagramStep>) => {
      return prevSteps
        .map((step: DiagramStep) => {
          if (step.workflowSeqId === Number(source)) {
            return {
              ...step,
              routes: step.routes.filter(
                (route) => route.nextWorkflowSeqId !== Number(target)
              ),
            };
          }
          return step;
        })
        .filter((step: DiagramStep) => {
          return !(
            step.workflowSeqId === Number(target) &&
            step.type === DIAGRAM_STEP_TYPE.CROSSROAD_DUMMY
          );
        });
    });
  };

  return (
    <ReactFlow
      ref={flowRef}
      nodes={steps2nodes(steps)}
      onNodesChange={handleNodesChange}
      edges={steps2edges(steps, onRemoveEdge)}
      onEdgesChange={() => {}}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      fitView
      className="workflow-builder-container"
      onLoadCapture={() => {
        if (mode === LAYOUT_MODE.VIEW) {
          setTimeout(() => {
            fitView();
          }, 50);
        }
      }}
      onConnect={onConnect}
    >
      <Background />
    </ReactFlow>
  );
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchParams } from 'shared/models/data/common-search.model';
import { RootState } from '../../Common/store';
import {
  AutomationRule,
  AutomationRuleGroup,
  AutomationRuleValue,
} from '../../shared/models/data/automation-rule-group.model';

function addRuleToMap(configuration: {
  mapKeyword: 'create' | 'update' | 'delete';
  rule: AutomationRule;
  findingType: string;
  state: AutomationManagementState;
}) {
  const { mapKeyword, rule, findingType, state } = configuration;

  if (state.deltaMaps[mapKeyword][findingType]) {
    const createDeltaMapItem = state.deltaMaps[mapKeyword][findingType];

    createDeltaMapItem[rule.id] = rule;

    state.deltaMaps[mapKeyword][findingType] = createDeltaMapItem;
  } else {
    const createDeltaMapItem = {
      [rule.id]: rule,
    };

    state.deltaMaps[mapKeyword][findingType] = createDeltaMapItem;
  }
}

export type DeltaMap = {
  [key: string]: AutomationRule;
};

export type DeltaGroupMap = {
  [key: string]: DeltaMap;
};

export interface AutomationManagementRuleGroupSearchParams
  extends SearchParams {
  showOnlyRuleDefined?: boolean;
}

// state definition
interface AutomationManagementState {
  ruleGroups: Array<AutomationRuleGroup>;
  ruleGropSearchParams: AutomationManagementRuleGroupSearchParams;
  deltaMaps: {
    create: DeltaGroupMap;
    update: DeltaGroupMap;
    delete: DeltaGroupMap;
  };
  options: {
    businessUnitIds: Array<AutomationRuleValue>;
    businessEnvironmentTypes: Array<AutomationRuleValue>;
  };
  refresh: boolean;
}

// initial state
const initialState: AutomationManagementState = {
  ruleGroups: [],
  ruleGropSearchParams: {
    pagination: {
      skip: 0,
      take: 25,
    },
  },
  deltaMaps: {
    create: {},
    update: {},
    delete: {},
  },
  options: {
    businessEnvironmentTypes: [],
    businessUnitIds: [],
  },
  refresh: true,
};

// slice
export const automationManagementSlice = createSlice({
  name: 'automationManagement',
  initialState,
  reducers: {
    setRuleGroups: (
      state,
      payload: PayloadAction<Array<AutomationRuleGroup>>
    ) => {
      state.ruleGroups = payload.payload;
    },
    setRuleGroupsSearchParams: (
      state,
      payload: PayloadAction<AutomationManagementRuleGroupSearchParams>
    ) => {
      state.ruleGropSearchParams = {
        ...state.ruleGropSearchParams,
        ...payload.payload,
      };
    },
    setGlobalOptions: (
      state,
      payload: PayloadAction<{
        options: {
          businessEnvironmentTypes?: Array<AutomationRuleValue>;
          businessUnitIds?: Array<AutomationRuleValue>;
        };
      }>
    ) => {
      state.options = {
        ...state.options,
        ...payload.payload.options,
      };
    },
    setRefresh: (state, payload: PayloadAction<boolean>) => {
      state.refresh = payload.payload;
    },
    storeRuleToAdd: (
      state,
      payload: PayloadAction<{
        findingType: string;
        rule: AutomationRule;
      }>
    ) => {
      const { findingType, rule } = payload.payload;

      state.ruleGroups = state.ruleGroups.map(
        (ruleGroup: AutomationRuleGroup) => {
          if (ruleGroup.findingType?.name === payload.payload.findingType) {
            return {
              ...ruleGroup,
              rules: [...ruleGroup.rules, payload.payload.rule],
            };
          }
          return ruleGroup;
        }
      );

      addRuleToMap({ findingType, rule, state, mapKeyword: 'create' });
    },
    storeRuleToUpdate: (
      state,
      payload: PayloadAction<{
        rule: AutomationRule;
        findingType: string;
      }>
    ) => {
      const { rule, findingType } = payload.payload;

      state.ruleGroups = state.ruleGroups.map(
        (ruleGroup: AutomationRuleGroup) => {
          if (ruleGroup?.findingType?.name === rule.findingType) {
            return {
              ...ruleGroup,
              rules: ruleGroup.rules.map((ruleItem: AutomationRule) => {
                if (ruleItem?.id === rule?.id) {
                  return rule;
                }
                return ruleItem;
              }),
            };
          }

          return ruleGroup;
        }
      );

      if (rule.draft) {
        addRuleToMap({ findingType, rule, state, mapKeyword: 'create' });
      } else {
        addRuleToMap({ findingType, rule, state, mapKeyword: 'update' });
      }
    },
    clearDeltaRuleGroup: (
      state,
      payload: PayloadAction<{
        findingType: string;
      }>
    ) => {
      const { findingType } = payload.payload;

      state.deltaMaps.create[findingType] = {};
      state.deltaMaps.delete[findingType] = {};
      state.deltaMaps.update[findingType] = {};
    },
    storeRuleToDelete: (
      state,
      payload: PayloadAction<{
        rule: AutomationRule;
        findingType: string;
      }>
    ) => {
      const { rule, findingType } = payload.payload;

      state.ruleGroups = state.ruleGroups.map(
        (ruleGroup: AutomationRuleGroup) => {
          if (ruleGroup?.findingType?.name === findingType) {
            return {
              ...ruleGroup,
              rules: ruleGroup?.rules?.filter((ruleItem: AutomationRule) => {
                return ruleItem?.id !== rule.id;
              }),
            };
          }
          return ruleGroup;
        }
      );

      if (rule.draft) {
        const createMapItem = state.deltaMaps?.create[findingType];

        delete createMapItem[rule.id];

        state.deltaMaps.create[findingType] = createMapItem;
      } else {
        addRuleToMap({ findingType, rule, state, mapKeyword: 'delete' });
      }
    },
  },
});

// Actions

export const {
  setRuleGroups,
  setRefresh,
  storeRuleToAdd,
  setGlobalOptions,
  storeRuleToUpdate,
  clearDeltaRuleGroup,
  storeRuleToDelete,
  setRuleGroupsSearchParams,
} = automationManagementSlice.actions;

// selectors

export const selectRuleGroups = (state: RootState) =>
  state.automationManagement.ruleGroups;

export const selectDeltaMaps = (state: RootState) =>
  state.automationManagement.deltaMaps;

export const selectGlobalOptions = (state: RootState) =>
  state.automationManagement.options;

export const selectRefresh = (state: RootState) =>
  state.automationManagement.refresh;

export const selectRuleGroupsSearchParams = (state: RootState) =>
  state.automationManagement.ruleGropSearchParams;

// exporting reducer
const automationManagementReducer = automationManagementSlice.reducer;

export default automationManagementReducer;

import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';

const integrationsApi = createApi({
  reducerPath: 'integrationsApi',
  baseQuery: axiosFetchBaseQuery({
    baseUrl: commonConfig.bffBaseUrl,
  }),
  tagTypes: ['Risks'],
  endpoints: (builder) => ({
    getConnectionByCategory: builder.mutation({
      query: (category) => ({
        url: `integrations/category/${category}/connections`,
        method: 'GET',
      }),
    }),
  }),
});

export default integrationsApi;

export const { useGetConnectionByCategoryMutation } = integrationsApi;

import React, { useState } from 'react';
import Chip from 'shared/components/FilterChip/Chip';
import FilterDropdown from 'shared/components/FilterDropdown/FilterDropdown';

const ChipDropdown: React.FC<any> = ({
  item,
  allOption,
  data: dropdownData,
  mutationProps,
  handleItemSelect,
  setListFilter,
  listMapper,
}) => {
  const [anchorElEdit, setAnchorElEdit] = useState<{
    [key: string]: HTMLButtonElement | null;
  }>({});
  const [filteredData, setFilteredData] = useState<boolean>(false);

  const editChip = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemData: any
  ) => {
    setAnchorElEdit({
      [itemData.id]: event.currentTarget.parentElement as HTMLButtonElement,
    });
  };
  const [menu, setMenu] = useState<any>([]);

  const handleCloseEditMenu = () => {
    setAnchorElEdit({});
    setFilteredData(false);
    setMenu(dropdownData);
  };

  const getFilteredItems = () => {
    const filteredMenu = dropdownData.find(
      (dataMenu: { id: any }) => dataMenu.id === menu.id
    );
    if (!filteredMenu) return { ...menu };
    const updatedItems = menu.items.map((menuItem: { id: any }) => {
      const matchingDataItem = filteredMenu.items.find(
        (dataItem: { id: any }) => dataItem.id === menuItem.id
      );
      return matchingDataItem
        ? { ...menuItem, checked: matchingDataItem.checked }
        : menuItem;
    });

    return { ...filteredMenu, items: updatedItems };
  };

  const getListItems = (id: string) => {
    if (!Array.isArray(menu) && menu.items.length) {
      return getFilteredItems();
    }
    return dropdownData.find((item: { id: string }) => item.id === id);
  };

  return (
    <div>
      <Chip
        key={item.id}
        item={item}
        closeEnabled={false}
        editChip={editChip}
        label={item.name}
        removeChip={() => {}}
        aria-describedby={`chip-edit-popover-${item.id}`}
      />
      <FilterDropdown
        isSelectAllCheckboxEnabled={false}
        data={dropdownData}
        anchorEl={anchorElEdit[item.id]}
        isEdit
        open={Boolean(anchorElEdit[item.id])}
        allOption={allOption}
        handleClose={handleCloseEditMenu}
        isSubMenu={true}
        storeSynchronization={true}
        id={`chip-edit-popover-${item.id}`}
        listItems={getListItems(item.id)}
        mutationProps={mutationProps}
        setListFilter={setListFilter}
        handleItemSelect={handleItemSelect}
        setListItems={setMenu}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        listMapper={listMapper}
      />
    </div>
  );
};

export default ChipDropdown;

import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { UserDetailsService } from 'shared/services/users/user-details.service';
import { useAuthUser } from '@frontegg/react';
import { Role } from 'Auth/store/api';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { AssetsTabType } from 'Assets/interfaces/AssetsTabType';
import { useFetchAssetsCountQuery } from 'Assets/store/api';

export interface AssetsTabListItem {
  id: any;
  label: string;
  props?: any;
  permissions?: ApplicationPermission[];
  roles?: Role[];
  icon: SVG_ICON_TYPES;
  value?: string;
}

interface AssetsTabListProps extends BaseComponentProps {
  items: Array<AssetsTabListItem>;
  onSelect?: (id: any) => void;
  activeItemId: any;
  label?: string | JSX.Element;
  isUnderlined?: boolean;
}

const userDetailsService = new UserDetailsService();

export const AssetsTabList: FunctionComponent<AssetsTabListProps> = ({
  items,
  onSelect,
  activeItemId,
}) => {
  const user = useAuthUser();
  const { data: assetsCount } = useFetchAssetsCountQuery();

  const onHandleSelect = (id: AssetsTabType) => {
    onSelect && onSelect(id);
  };

  const getCountForTab = (id: string) => {
    if (id === AssetsTabType.ALL) {
      return assetsCount
        ? Object.values(assetsCount).reduce(
            (sum: any, value: any) => sum + value,
            0
          )
        : 0;
    }
    return assetsCount ? assetsCount[id] : 0;
  };

  return (
    <div className="assets-tab-list-container">
      <div className={`tab-list-body`}>
        {items.map((item: AssetsTabListItem) => {
          const hasAllRoles = (item.roles || []).every((role) =>
            userDetailsService.hasRoleWithId(user, role)
          );

          return hasAllRoles ? (
            <div
              key={item.id}
              className={`tab-list-item ${
                activeItemId === item.id ? 'tab-list-item-active' : ''
              }`}
              onClick={() => onHandleSelect(item.id)}
            >
              <OpusSvgIcon type={item.icon} />
              <div className="tab-label">
                <p className="body-1">{item.label}</p>
                <p className="label-4">{`(${getCountForTab(item.id)})`}</p>
              </div>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

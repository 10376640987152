import { Switch } from '@mui/material';
import { FunctionComponent, useEffect } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface VisibilityControlContentProps extends BaseComponentProps {
  label: string;
  visible: boolean;
  onVisibilityChange: (visible: boolean) => void;
  disabled?: boolean;
}

export const VisibilityControlContent: FunctionComponent<
  VisibilityControlContentProps
> = ({ label, visible, onVisibilityChange, children, disabled = false }) => {
  const visibilityChangeHandler = () => {
    onVisibilityChange(!visible);
  };
  return (
    <div className="visibility-control-content-container">
      <div className="visibility-control-content-header">
        <div className="visibility-control-content-label">{label}</div>
        <div className="visibility-control-content">
          <Switch
            disabled={disabled}
            classes={{
              root: 'opus-switch-field-1',
            }}
            checked={visible}
            defaultChecked={visible}
            onClick={visibilityChangeHandler}
          />
        </div>
      </div>

      {visible ? (
        <div className="visibility-control-content-body">{children}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

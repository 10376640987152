import { FunctionComponent, useState, useEffect } from 'react';
import { CircularProgress, Button } from '@mui/material';
import CommonCheckbox from '../CommonCheckbox';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { SystemLabel } from '../LabelManagementDropdown/LabelManagementDropdown';

interface LabelListManagerProps extends BaseComponentProps {
  labels: SystemLabel[];
  selectedLabels: SystemLabel[];
  onLabelSelect: (label: SystemLabel) => void;
  loading: boolean;
  title: string;
}

export const LabelListManager: FunctionComponent<LabelListManagerProps> = ({
  labels,
  selectedLabels,
  onLabelSelect,
  loading,
  title,
}) => {
  const renderBody = () => {
    if (loading) {
      return (
        <div className="label-management-dropdown-no-data">
          <CircularProgress size={20} />
        </div>
      );
    }

    if (labels.length === 0) {
      return (
        <div className="label-management-dropdown-no-data">
          No labels available
        </div>
      );
    }

    return labels.map((label) => {
      const isSelected = selectedLabels.some(
        (selected) => selected.label === label.label
      );

      return (
        <div
          key={label.label}
          className="label-management-dropdown-list-item"
          onClick={() => onLabelSelect(label)}
        >
          <div
            className="label-management-dropdown-list-item-title"
            title={label.label}
          >
            <CommonCheckbox checked={isSelected} readOnly />
            <span className="label-management-dropdown-list-item-title-text">
              {label.label}
            </span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="label-management-dropdown-list-section">
      {title.length ? (
        <div className="label-management-dropdown-list-section-title body-5">
          {title}
        </div>
      ) : (
        <></>
      )}

      {renderBody()}
    </div>
  );
};

import { AvailableUser } from 'FindingDetails/interfaces/Users';
import { AutocompleteOption } from 'FindingDetails/store/api';
import ConnectionItem from 'Integrations/Connection/interfaces/item';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import {
  CommunicationApp,
  CommunicationAppMappings,
} from 'Settings/components/TeamsList/enums/communication-app.enum';
import { CloudWorkspace } from 'Settings/interfaces/CloudWorkspace';
import { Team } from 'Settings/interfaces/Teams';
import { ConfigurationFieldValue } from 'shared/components/MultiSelectConfigurationField/MultiSelectConfigurationField';
import { MultiTextRowValue } from 'shared/components/MultiTextConfigurationField/MultiTextConfigurationField';
import {
  BusinessUnitRiskAttribute,
  BusinessUnitRole,
} from 'shared/enums/business-unit.enum';
import { businessUnitStakeholdersDefaultValues } from 'shared/fixtures/data/business-unit.data';
import {
  BusinessUnitAssociationRule,
  BusinessUnitRuleTypeEnum,
  ExtendedBusinessUnitCreateModel,
  ExtendedBusinessUnitFormModel,
  ExtendedBusinessUnitModel,
} from 'shared/models/data/extended-business-unit.model';

export class BusinessUnitDataHandler {
  transformCloudWorkspaceDataToAutocompleteOptions(
    cloudWorkspaces: Array<CloudWorkspace>
  ): Array<AutocompleteOption> {
    return cloudWorkspaces.map((cloudWorkspace: CloudWorkspace) => ({
      value: cloudWorkspace.id,
      label: `${cloudWorkspace.alias} [${cloudWorkspace.externalId}]`,
      iconUrl: cloudWorkspace.cloudProviderLogo,
    }));
  }

  transformOrganizationNodesDataToAutocompleteOptions(
    orgNodes: Array<OrganizationNode>
  ): Array<AutocompleteOption> {
    return [
      ...orgNodes.map((node: OrganizationNode) => ({
        value: node.id,
        label: node.name,
      })),
    ];
  }

  transformTeamListDataToAutocompleteOptions(
    teams: Array<Team>
  ): Array<AutocompleteOption> {
    return [
      {
        value: 'None',
        label: 'None',
      },
      ...teams.map((team: Team) => ({
        value: team.id,
        label: team.name,
      })),
    ];
  }

  transformUserListDataToAutocompleteOptions(
    users: Array<AvailableUser>
  ): Array<AutocompleteOption> {
    return users.map((user: AvailableUser) => ({
      value: user.id,
      label: user.name,
      iconUrl: user.profilePictureUrl,
      configurationParameters: {
        email: user.email,
      },
    }));
  }

  transformConnectionListDataToAutocompleteOptions(
    connections: Array<ConnectionItem>,
    useApplicationId?: boolean
  ): Array<AutocompleteOption> {
    return connections.map((connection: ConnectionItem) => ({
      value: connection.id,
      label: useApplicationId ? connection.applicationId : connection.name,
      iconUrl: connection.applicationLogo,
    }));
  }

  transformBusinessUnitDataToDefaultFormValues(
    businessUnit: ExtendedBusinessUnitModel
  ): ExtendedBusinessUnitFormModel {
    let stakeholders: Array<ConfigurationFieldValue> = [];

    if (businessUnit.stakeholders?.length) {
      let hasOneApplicationOwner = false;

      for (const businessUnitStakeholder of businessUnit.stakeholders) {
        if (
          businessUnitStakeholder.role === BusinessUnitRole.APPLICATION_OWNER &&
          !hasOneApplicationOwner
        ) {
          hasOneApplicationOwner = true;
          stakeholders.push({
            disableDelete: true,
            values: [
              {
                value: businessUnitStakeholder.role,
                label: businessUnitStakeholder.role,
                disabled: true,
              },
              {
                value: businessUnitStakeholder.id,
                label: businessUnitStakeholder.fullName,
                iconUrl: businessUnitStakeholder.avatarUrl,
              },
            ],
          });
        } else {
          stakeholders.push({
            disableDelete: false,
            values: [
              {
                value: businessUnitStakeholder.role as string,
                label: businessUnitStakeholder.role,
              },
              {
                value: businessUnitStakeholder.id,
                label: businessUnitStakeholder.fullName,
                iconUrl: businessUnitStakeholder.avatarUrl,
              },
            ],
          });
        }
      }

      if (hasOneApplicationOwner === false) {
        stakeholders = [
          ...businessUnitStakeholdersDefaultValues,
          ...stakeholders,
        ];
      }
    } else {
      stakeholders = [...businessUnitStakeholdersDefaultValues];
    }

    const associationRuleValues: Array<MultiTextRowValue> =
      businessUnit.associationRules
        ?.find((rules) => rules.type === BusinessUnitRuleTypeEnum.RESOURCE_TAGS)
        ?.rules?.map((rule) => [rule.key, rule.value]) || [];

    return {
      name: businessUnit?.name || '',
      description: businessUnit?.description || '',
      businessUnitImpact: businessUnit?.businessUnitImpact
        ? ({
            value: businessUnit.businessUnitImpact,
            label: businessUnit.businessUnitImpact,
          } as AutocompleteOption)
        : undefined,
      associatedRepos:
        businessUnit?.associatedRepos?.map((codeRepo) => ({
          value: codeRepo.id,
          label: codeRepo.name,
          iconUrl: codeRepo.logoUrl,
        })) || [],
      associatedAccounts:
        businessUnit?.associatedAccounts?.map((associatedAccount) => ({
          value: associatedAccount.workspaceId as string,
          label: associatedAccount.alias,
          iconUrl: associatedAccount.cloudProviderLogo,
        })) || [],
      associatedDomains: businessUnit?.associatedDomains?.join(','),
      stakeholders,
      ticketingSystem: businessUnit?.ticketingSystems?.length
        ? {
            value: businessUnit?.ticketingSystems[0]?.id,
            label: businessUnit?.ticketingSystems[0]?.name,
            iconUrl: businessUnit?.ticketingSystems[0].logoUrl,
          }
        : undefined,
      projectName: businessUnit.ticketingSystems?.length
        ? businessUnit.ticketingSystems[0]?.projectName || ''
        : '',
      team: businessUnit?.team
        ? {
            value: businessUnit?.team?.id,
            label: businessUnit?.team?.name,
          }
        : {
            value: 'None',
            label: 'None',
          },
      riskAttributes: businessUnit?.riskAttributes?.filter(
        (riskAttribute: BusinessUnitRiskAttribute) =>
          Object.values(BusinessUnitRiskAttribute).includes(riskAttribute)
      ),
      complianceRequirements: businessUnit?.complianceRequirements,
      communicationAppId:
        CommunicationApp[
          businessUnit.communicationAppId as keyof typeof CommunicationApp
        ],
      tags: businessUnit?.tags,
      assetGroups: businessUnit?.assetGroups?.join(',') || '',
      groupNodeId: businessUnit?.orgStructureInfo?.groupInfo?.id
        ? {
            value: businessUnit?.orgStructureInfo?.groupInfo?.id,
            label: businessUnit?.orgStructureInfo?.groupInfo?.name,
          }
        : undefined,
      orgScopeId: businessUnit?.orgStructureInfo?.scopeInfo?.id
        ? {
            value: businessUnit?.orgStructureInfo?.scopeInfo?.id,
            label: businessUnit?.orgStructureInfo?.scopeInfo?.name,
          }
        : undefined,
      associationRules: associationRuleValues,
    };
  }

  transformBusinessUnitFormValuesToCreateModel(
    formData: ExtendedBusinessUnitFormModel,
    businessUnitData?: ExtendedBusinessUnitModel
  ): ExtendedBusinessUnitCreateModel {
    const applicationOwner = formData.stakeholders?.find(
      (stakeholder) =>
        stakeholder.values[0]?.value === BusinessUnitRole.APPLICATION_OWNER
    );

    const associatedAccounts = formData?.associatedAccounts?.map((account) => {
      return account.value;
    });

    const associationRules: Array<BusinessUnitAssociationRule> = formData
      .associationRules?.length
      ? [
          {
            type: BusinessUnitRuleTypeEnum.RESOURCE_TAGS,
            rules: formData.associationRules.map((rule) => ({
              key: rule[0],
              value: rule[1],
            })),
          },
        ]
      : [];

    const createModel: ExtendedBusinessUnitCreateModel = {
      name: formData.name,
      unitOwner:
        applicationOwner?.values[1]?.configurationParameters?.email ||
        businessUnitData?.unitOwner,
      businessUnitCriticality: formData.businessUnitImpact?.value,
      associatedAccounts: associatedAccounts || [],
      associatedDomains: formData.associatedDomains?.length
        ? formData.associatedDomains?.split(',')
        : [],
      associatedRepos:
        formData.associatedRepos?.map(
          (associatedRepo) => associatedRepo.value
        ) || [],
      description: formData.description?.length
        ? formData.description
        : undefined,
      riskAttributes: formData.riskAttributes || [],
      complianceRequirements: formData.complianceRequirements || [],
      teamId:
        formData.team?.value !== 'None' ? formData.team?.value : undefined,
      users: formData?.stakeholders?.length
        ? formData?.stakeholders?.map((stakeholderItem) => ({
            id: stakeholderItem.values[1]?.value,
            role: stakeholderItem.values[0]?.value as BusinessUnitRole,
          }))
        : [],
      ticketingSystems: formData?.ticketingSystem
        ? [
            {
              connectionId: formData.ticketingSystem?.value,
              projectName: formData.projectName as string,
            },
          ]
        : [],
      communicationAppId: formData.communicationAppId
        ? CommunicationAppMappings[
            formData.communicationAppId as keyof typeof CommunicationApp
          ] || formData.communicationAppId
        : undefined,
      tags: formData.tags,
      parentId: formData?.groupNodeId?.value,
      assetGroups: formData.assetGroups?.length
        ? formData.assetGroups?.split(',')
        : [],
      associationRules,
    };

    return createModel;
  }
}

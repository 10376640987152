import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';

interface Props {
  modelName: string;
}

export default function IntegrationEmpty(props: Props) {
  const { t: translation } = useTranslation();

  return (
    <div data-testid="ConnectionEmpty">
      <FormattedMessage
        id="connections.details.noConnectionYet"
        defaultMessage="No connection yet"
        params={{
          param1: props.modelName,
        }}
      />
      .
    </div>
  );
}

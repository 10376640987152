import { FunctionComponent } from 'react';
import moment from 'moment';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import ContentSection from 'shared/components/ContentSection';
import FindingItem from 'shared/components/FindingItem';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { IaCItemDetails } from 'IaCFindings/interfaces/IaCFinding';
import { useTranslation } from 'react-i18next';
import { Grid, Skeleton } from '@mui/material';
import FormattedMessage from 'shared/components/FormattedMessage';
import { CodeThemeTypes } from 'shared/models/props/iac-props.model';
import CodePreview from 'IaCFindings/components/CodePreview/CodePreview';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';
import { dateTimeFormat } from 'shared/services/date.service';

interface Cloud2CodeDetailsProps extends BaseComponentProps {
  codeEventData?: IaCItemDetails;
  codeEventDataLoading?: boolean;
}

export const Cloud2CodeDetails: FunctionComponent<Cloud2CodeDetailsProps> = ({
  codeEventData,
  codeEventDataLoading,
}) => {
  const [urlSearchParams] = useQueryParams();

  const { t: translation } = useTranslation();

  const renderBody = () => {
    if (codeEventDataLoading) {
      return (
        <div className="finding-content-no-data">
          <Skeleton variant="rectangular" height={100} width={'100%'} />
        </div>
      );
    }

    if (!codeEventDataLoading && !codeEventData) {
      return <NoDataToDisplayCard displayDescription={false} />;
    }

    if (codeEventData)
      return (
        <div className="finding-content-cloud-to-code-sub-section-body">
          <div className="cloud-to-code-content-section-row">
            <FindingItem title={translation(`assets.details.fileName`)}>
              <InteractiveLabel
                label={codeEventData?.fileName}
                link={codeEventData?.fileLink}
              />
            </FindingItem>
            <FindingItem title={translation(`assets.details.codeRepo`)}>
              <InteractiveLabel
                label={codeEventData?.repositoryName}
                icon={
                  <div className="finding-content-application-icon">
                    <img src={codeEventData?.repositoryLogoUrl}></img>
                  </div>
                }
              />
            </FindingItem>

            <FindingItem title={translation(`assets.details.branch`)}>
              <p>{codeEventData?.repositoryBranch}</p>
            </FindingItem>

            <FindingItem title={translation(`assets.details.lastModifier`)}>
              <p>{codeEventData.lastModifier}</p>
            </FindingItem>
            <FindingItem title={translation(`assets.details.commit`)}>
              <InteractiveLabel
                label={codeEventData?.commitId}
                link={codeEventData.commitLink}
              />
            </FindingItem>
            <FindingItem title={translation(`assets.details.modificationTime`)}>
              <p>
                {moment(codeEventData?.lastModified).format(dateTimeFormat)}
              </p>
            </FindingItem>
          </div>
          <div>
            <Grid container display="flex" direction={'row'} gap={'20px'}>
              {codeEventData.originalCodeSegment && (
                <Grid item xs={codeEventData.codeFixSuggestion ? 5.8 : 12}>
                  <CodePreview
                    eventId={codeEventData.id}
                    theme={CodeThemeTypes.CUSTOM}
                    label={
                      <FormattedMessage
                        id="assets.details.codeSnippet"
                        defaultMessage="Code Snippet"
                      ></FormattedMessage>
                    }
                    copyButton
                    fileName={codeEventData.fileName}
                    code={codeEventData.originalCodeSegment}
                  ></CodePreview>
                </Grid>
              )}
              {codeEventData.codeFixSuggestion && (
                <Grid item xs={5.8}>
                  <CodePreview
                    theme={CodeThemeTypes.CUSTOM}
                    eventId={codeEventData.id}
                    label={
                      <FormattedMessage
                        id="assets.details.codeFixSnippet"
                        defaultMessage="Code Fix Snippet"
                      ></FormattedMessage>
                    }
                    code={codeEventData?.codeFixSuggestion}
                    copyButton
                    fileName={codeEventData.fileName}
                  ></CodePreview>
                </Grid>
              )}
            </Grid>
          </div>
        </div>
      );

    return <></>;
  };

  return (
    <ContentSection
      iconClassName="finding-content-section-icon"
      rootClassName="finding-content-section"
      icon={<OpusSvgIcon type={SVG_ICON_TYPES.CODE_ICON} />}
      title={translation('assets.details.codeDetails')}
    >
      {renderBody()}
    </ContentSection>
  );
};

import * as yup from 'yup';
import { useAuthUser } from '@frontegg/react';
import { Alert, Box, CircularProgress, Snackbar, Stack } from '@mui/material';
import OpusAvatarIcon from 'shared/components/IconComponents/OpusAvatarIcon';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import {
  CommonButton,
  CommonButtonType,
} from '../../../shared/components/CommonButton/CommonButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useEffect } from 'react';

interface Props {
  id: string;
  onNewComment?: (userId: string, comment: string) => void;
  useMutation: UseMutation<any>;
}

export default function TimelineEventAddComment({
  id,
  onNewComment,
  useMutation,
}: Props) {
  const { t: translation } = useTranslation();

  const { id: userId, profilePictureUrl, email } = useAuthUser();

  const [
    addTimelineComment,
    {
      isLoading,
      error: addTimelineCommentError,
      reset: resetAddTimelineCommentPayload,
    },
  ] = useMutation();

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        message: yup.string().required(),
      })
    ),
    defaultValues: {
      message: '',
    },
  });

  const messageValue = watch('message');

  useEffect(() => {
    if (addTimelineCommentError) {
      setTimeout(() => {
        resetAddTimelineCommentPayload();
      }, 4000);
    }
  }, [addTimelineCommentError]);

  const formSubmitHandler = (event: any) => {
    event.preventDefault();
    handleSubmit(formValidHandler)();
  };

  const formValidHandler = (formValues: any) => {
    addTimelineComment({
      id: id,
      body: {
        message: formValues.message,
        applicationId: 'Opus',
      },
    });
    onNewComment && onNewComment(userId, formValues.message);
    resetForm();
  };

  const renderErrorSnackbar = () => {
    return (
      <Snackbar
        open={Boolean(addTimelineCommentError)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={3000}
        onClose={() => resetAddTimelineCommentPayload()}
      >
        <Alert severity={'error'} sx={{ minWidth: '300px !important' }}>
          {(addTimelineCommentError as any)?.data?.message
            ? (addTimelineCommentError as any).data?.message[0]
            : ''}
        </Alert>
      </Snackbar>
    );
  };

  const renderCharacterLimitWarning = () => {
    if (messageValue.length > 1000)
      return (
        <Alert severity="warning">
          {translation(`findings.details.timelineCharacterLimitWarningMessage`)}
        </Alert>
      );

    return <></>;
  };

  return (
    <Box className="add-comment-container">
      <div className="comment-indicator default-indicator" />
      <form onSubmit={formSubmitHandler}>
        <Stack direction="row" spacing={1}>
          <OpusAvatarIcon
            alt={email}
            src={profilePictureUrl as string}
            title={email}
            sx={{ width: '24px', height: '24px' }}
          />
          <textarea
            rows={4}
            className={'text-area-field-1 add-comment'}
            placeholder={translation(`findings.details.postComment`)}
            {...register('message')}
          ></textarea>
        </Stack>
        <Box mb="1rem" />
        <Stack direction="row" justifyContent="flex-end">
          <CommonButton
            type={CommonButtonType.PRIMARY}
            buttonType={'submit'}
            disabled={messageValue.length > 1000}
          >
            <FormattedMessage
              id="common.comment"
              defaultMessage="Comment"
              capitalize
            />
          </CommonButton>
        </Stack>
      </form>
      {renderCharacterLimitWarning()}
      {isLoading && <CircularProgress size={24} />}
      {renderErrorSnackbar()}
    </Box>
  );
}

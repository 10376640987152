import { Icon } from '@mui/material';
import classNames from 'classnames';
interface CommonIconProps {
  icon: string;
  color?: string;
  size?: number;
  isOutlined?: boolean;
  styleIcon?: string;
}

/**
 * Generic Font Awesome icon component.
 *
 * This component was created to bypass a TypeScript error which occurs when
 * using Font Awesome icon set of version 5 with Font Awesome React library
 * of version 6.
 */
export default function CommonIconMui({
  icon,
  color = 'inherit',
  styleIcon,
  size = 30,
  isOutlined = false,
}: CommonIconProps) {
  return (
    <Icon
      className={classNames([
        !isOutlined ? 'material-icons' : 'material-icons-outlined',
        styleIcon,
      ])}
      sx={{ color, fontSize: size }}
    >
      {icon}
    </Icon>
  );
}

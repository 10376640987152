import { Box, Grid } from '@mui/material';
import CardWrapper from 'Common/components/CardWrapper';
import ForbiddenPage from 'Common/components/ForbiddenPage';
import SearchField from 'Common/components/SearchField/SearchField';
import { ApplicationPermission } from 'shared/enums/permission.enum';
import { usePermissions } from 'shared/hooks/usePermissionActions';
import { OrganizationTree } from '../OrganizationTree/OrganizationTree';

export const OrganizationView = () => {
  const userPermissions = usePermissions();
  const hasUsersReadPermission = userPermissions.includes(
    ApplicationPermission.SETTINGS_ORGANIZATION_READ
  );
  if (!hasUsersReadPermission) return <ForbiddenPage />;

  return (
    <Box className="organization-view">
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <CardWrapper>
            <SearchField
              setTableItems={function (value: unknown[]): void {
                throw new Error('Function not implemented.');
              }}
              filterPropertyName={''}
            ></SearchField>
          </CardWrapper>
        </Grid> */}
        <Grid item xs={12}>
          <CardWrapper>
            <OrganizationTree />
          </CardWrapper>
        </Grid>
      </Grid>
    </Box>
  );
};

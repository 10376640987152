import { OpusPngIconProps } from 'shared/icons/interface';
import { IconFactory } from '../../../icons/utils';
import IconWrapper from '../IconWrapper';
import _ from 'lodash';

const iconFactory = new IconFactory();

interface OpusIconProps extends OpusPngIconProps {
  children?: JSX.Element;
  title?: string;
  className?: string;
}

export const OpusPngIcon = (props: OpusIconProps): JSX.Element => {
  const { title, className } = props;

  return (
    <IconWrapper title={title} className={className}>
      {iconFactory.getPngIcon(_.omit(props, ['title', 'children']))}
    </IconWrapper>
  );
};

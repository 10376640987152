import moment from 'moment';
import { Box, Stack, Typography } from '@mui/material';
import FindingEvent, { TimeLineActorTypes } from './interfaces/finding-event';
import TimelineEventAvatar from './TimelineEventAvatar';
import { dateTimeFormat } from 'shared/services/date.service';

interface Props {
  event: FindingEvent;
}

export default function TimelineEvent({ event }: Props) {
  const eventDate = moment(event.createdAt).format(dateTimeFormat);

  return (
    <Stack direction="row" className="activity-item">
      <div className="comment-indicator" />
      <TimelineEventAvatar event={event} />
      <Box className="event-content">
        <Box>
          <Typography component="span" className="event-text">
            {event.actorType === TimeLineActorTypes.USER
              ? `${event.actorName} left a comment`
              : event.message}
          </Typography>
          <Typography component="span" className="event-date">
            {eventDate}
          </Typography>
        </Box>
        {event.actorType === TimeLineActorTypes.USER && (
          <Typography className="event-text user-comment">
            {event.message}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}

import { SystemFeatureFlag } from 'shared/components/EntitledContent/EntitledContent';
import { ApplicationPermission } from 'shared/enums/permission.enum';

export interface MenuConfigType {
  id: number;
  title: string;
  to: string;
  icon: string;
  isDisabled?: boolean;
  iconSvg?: any;
  iconAvatar?: any;
  callBackFunction?: (data?: any) => void;
  submenu?: MenuConfigType[];
  permissions?: Array<ApplicationPermission>;
  featureFlag?: SystemFeatureFlag;
}

export enum MenuTabsRoutes {
  DASHBOARDS = '/dashboards',
  FINDINGS = '/findings',
  RISKS = '/risks',
  CAMPAIGNS = '/campaigns',
  FLOWS = '/flows',
  // CONNECTIONS = '/connections',
  INTEGRATIONS = '/connections',
  SETTINGS = '/settings',
  ASSETS = '/iac',
  BUILDER = '/builder',
  'AUTOMATION MANAGEMENT' = '/automation-management',
}

export type MenuTab = {
  [key in keyof typeof MenuTabsRoutes]?: typeof MenuTabsRoutes[key];
};

import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';

const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  endpoints: (builder) => ({
    updateOnBoardingTenantDetails: builder.mutation({
      query: (body) => ({
        url: 'tenants/me/actions/update',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export default onboardingApi;

export const { useUpdateOnBoardingTenantDetailsMutation } = onboardingApi;

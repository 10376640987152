import { Role } from 'Auth/store/api';
import { User } from 'shared/models/data/authenticated-user.model';
import { JWTService } from '../jwt/jwt.service';
import { UserDetails } from './user-details.model';

export class UserDetailsService {
  private details: UserDetails;

  constructor() {
    this.details = this.buildUserDetails();
  }

  getFullName() {
    return this.details.fullName;
  }

  getOrganization() {
    return this.details.organization;
  }

  private buildUserDetails() {
    const jetService = new JWTService();
    const claimsPayload = jetService.getPayload();
    let organization = claimsPayload?.email
      ? claimsPayload?.email.substring(
          claimsPayload.email.indexOf('@') + 1,
          claimsPayload.email.lastIndexOf('.')
        )
      : '';
    organization =
      organization.length > 0
        ? organization.charAt(0).toUpperCase() + organization.slice(1)
        : '';

    return {
      tenantId: claimsPayload?.tenantId || '',
      fullName: claimsPayload?.name || '',
      email: claimsPayload?.email || '',
      organization: organization,
      profilePictureUrl: claimsPayload?.profilePictureUrl || '',
    };
  }

  hasRoleWithId(user: User, roleId: Role) {
    return user.roles.some((role) => role.key === roleId);
  }
}

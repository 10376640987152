import { FunctionComponent, ReactNode } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface DashboardReportsWidgetContainerProps extends BaseComponentProps {
  title: string;
  titleIcon?: ReactNode;
}

export const DashboardReportsWidgetContainer: FunctionComponent<
  DashboardReportsWidgetContainerProps
> = ({ title, titleIcon, children }) => {
  const renderTitleIcon = () => {
    if (titleIcon)
      return (
        <div className="dashboard-reports-widget-title-icon">{titleIcon}</div>
      );
    return <></>;
  };

  return (
    <div className="dashboard-reports-widget-container">
      <div className="dashboard-reports-widget-title">
        {renderTitleIcon()}
        <div className="dashboard-reports-widget-title-text">{title}</div>
      </div>
      <div className="dashboard-reports-widget-body">{children}</div>
    </div>
  );
};

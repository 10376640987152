import CampaignTrackerHeader from 'CampaignDetails/components/CampaignTrackerHeader';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import CampaignFindingsGrid from '../CampaignFindingsGrid';
import PageContainer from 'shared/components/PageContainer';
import CampaignBreakdownStatistics from '../CampaignBreakdownStatistics';

interface CampaignTrackerPageProps extends BaseComponentProps {}

export const CampaignTrackerPage: FunctionComponent<
  CampaignTrackerPageProps
> = () => {
  return (
    <PageContainer
      title="Campaign Tracker"
      containerClass="campaign-tracker-page-container"
    >
      <div className="campaigns-tracker-page">
        <CampaignTrackerHeader />
        <CampaignBreakdownStatistics />
        <CampaignFindingsGrid />
      </div>
    </PageContainer>
  );
};

import { DataFilterOption } from './data-filters.model';

export interface RiskTableFilters {
  riskScore?: DataFilterOption[];
  severity?: DataFilterOption[];
  eventSource?: DataFilterOption[];
  resource?: DataFilterOption[];
  application?: DataFilterOption[];
  status?: DataFilterOption[];
  cloudProvider?: DataFilterOption[];
  assignee?: DataFilterOption[];
}

export interface TicketCreateFormValues {
  projectKey: string;
  assigneeEmail?: string;
  notifyTicketAssignee?: boolean;
  ticketType: TicketTypes;
  ticketApplication: string;
}

export type TicketCreateFormErrors = Record<
  keyof TicketCreateFormValues,
  string | null
>;
export enum TicketTypes {
  SINGLE_FINDING_TICKET = 'finding',
  CODE_EVENT_TICKET = 'codeEvent',
  MULTI_FINDINGS_SINGLE_TICKET = 'multiFinding',
  UNLINK_TICKET = 'unlink_ticket',
}

import { Button } from '@mui/material';
import { FunctionComponent } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export enum CommonButtonType {
  PRIMARY,
  SECONDARY,
  TEXT,
  OUTLINE_PRIMARY,
}

interface CommonButtonProps extends BaseComponentProps {
  type: CommonButtonType;
  onClick?: () => void;
  buttonType?: 'submit' | 'button' | 'reset';
  disabled?: boolean;
}

export const CommonButton: FunctionComponent<CommonButtonProps> = ({
  type,
  onClick,
  buttonType = 'button',
  children,
  disabled,
}) => {
  const getButtonRootClassnameByType = (): string => {
    switch (type) {
      case CommonButtonType.PRIMARY:
        return 'common-button-primary';
      case CommonButtonType.SECONDARY:
        return 'common-button-secondary';
      case CommonButtonType.TEXT:
        return 'common-button-text';
      case CommonButtonType.OUTLINE_PRIMARY:
        return 'common-button-outline-primary';
    }
  };

  return (
    <Button
      classes={{
        root: `common-button ${getButtonRootClassnameByType()}`,
      }}
      type={buttonType}
      onClick={() => {
        onClick && onClick();
      }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

import { FunctionComponent } from 'react';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import {
  IntelligenceWeaknessesItem,
  IntelligenceWeaknessesItemProps,
} from '../IntelligenceWeaknessesItem/IntelligenceWeaknessesItem';
import TimelineComponent from 'Common/components/TimelineComponent/TimelineComponent';
import { VulnerabilityMetadataWeakness } from 'shared/models/data/vulnerability.model';
import NoDataToDisplayCard from 'Dashboard/components/NoDataCard';

interface FindingContentIntelligenceWeaknessesProps extends BaseComponentProps {
  weaknesses: Array<VulnerabilityMetadataWeakness>;
}

export const FindingContentIntelligenceWeaknesses: FunctionComponent<
  FindingContentIntelligenceWeaknessesProps
> = ({ weaknesses }) => {
  const renderBody = () => {
    if (weaknesses.length === 0)
      return <NoDataToDisplayCard displayDescription={false} />;
    return (
      <TimelineComponent
        data={weaknesses}
        // isLoading={isLoading}
        noEventsMessageId="findings.details.noEvents"
        noEventsDefaultMessage="No events"
        renderEvent={(eventItem) => {
          return <IntelligenceWeaknessesItem {...eventItem} />;
        }}
        isLoading={false}
      />
    );
  };

  return (
    <div className="finding-content-item finding-intelligence-weaknesses">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName="finding-content-section finding-content-section-risks"
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.BROKEN_CHAIN_ICON} />}
        title="Weaknesses"
      >
        {renderBody()}
      </ContentSection>
    </div>
  );
};

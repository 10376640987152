import React from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

import { useTranslation } from 'react-i18next';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import InteractiveLabel from 'shared/components/InteractiveLabel';
import TextOverflow from 'shared/components/TextOverflowComponent';
import {
  OverflowTextDisplay,
  OverflowDirection,
} from 'shared/components/TextOverflowComponent/TextOverflow';
import { Grid } from '@mui/material';
import { VulnerabilityMetadataThreatActor } from 'shared/models/data/vulnerability.model';

interface IntelligenceThreatActorsItemrops
  extends BaseComponentProps,
    VulnerabilityMetadataThreatActor {}

export const IntelligenceThreatActorsItem: React.FC<
  IntelligenceThreatActorsItemrops
> = ({ name, associatedGroups }) => {
  const { t: translation } = useTranslation();

  return (
    <div className="finding-threat-actors-item-container">
      <div className="comment-indicator"></div>
      <div className="threat-actors-content">
        <p className="label-4">{name}</p>
        <div className="threat-actors-details">
          <div>
            <p className="opus-body-text-1">Associated Groups</p>
          </div>
          <div>
            <TextOverflow
              type={OverflowTextDisplay.ELLIPSIS}
              direction={OverflowDirection.WIDTH}
            >
              <p className="opus-body-text-1 description">
                {associatedGroups.join(',')}
              </p>
            </TextOverflow>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};
export default IntelligenceThreatActorsItem;

import { ChangeEvent, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import {
  selectActiveTool,
  selectAfterTool,
  selectBeforeNode,
  setActiveTool,
  setAfterTool,
  setBeforeNode,
} from 'WorkflowBuilder/store';
import List from '../List';
import {
  useFetchConnectionByFiltersQuery,
  useFetchApplicationByIdQuery,
  useFetchActionTemplateByIdQuery,
} from '../../store/api';
import {
  useFetchApplicationsQuery,
  useFetchActionTemplatesByParamsQuery,
} from '../../../shared/store/api';
import Slider from '../Slider';
import { Search } from '@mui/icons-material';
import { useState } from 'react';
import DynamicForm from '../DynamicForm';
import { useForm } from 'react-hook-form';
import './ListPanel.css';
import DiagramStep from 'WorkflowBuilder/interfaces/step';
import AlertDialog from 'WorkflowBuilder/components/AlertDialog';
import { useTranslation } from 'react-i18next';
import FormattedMessage from 'shared/components/FormattedMessage';
import { AutomationRuleStatus } from 'shared/models/data/automation-rule-group.model';
import { IListItem } from '../List/List';
import { DiagramStepContext } from '../Builder/Builder';
import PopoverWrapper from 'shared/components/PopoverWrapper/PopoverWrapper';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

interface ModalStep {
  index: number;
  label: string;
  searchLabel?: string;
  onFilter?: (filterValue: string) => void;
  filterValue?: string;
  children: any;
  icon?: any;
}

interface Props {
  title?: string;
  onToolAddOrEdit?: (
    payload: {
      label?: string;
      logo?: string;
      application?: any;
      actionTemplate?: any;
      connectionData?: any;
      activeTool?: number | null;
    },
    afterTool: number | null,
    beforeNode: number | null,
    mode: 'edit' | 'create'
  ) => void;
  onRemoveNode?: (seqId: number) => void;
  onHandleSearch?: () => void;
  closeCrossroadPanel?: () => void;
  steps: Array<DiagramStep>;
  setSteps: (steps: Array<DiagramStep>) => void;
}

export default function ListPanel({
  onToolAddOrEdit = () => {},
  onRemoveNode,
  steps,
}: Props) {
  const { t: translation } = useTranslation();

  const [initialLoadingController, setInitialLoadingController] =
    useState<boolean>(false);
  const [timelineEventConfig, setTimelineEventConfig] = useState<{
    status?: boolean;
    value?: string;
  }>({});
  const [continueWorkflowIfFail, setContinueWorkflowIfFail] =
    useState<boolean>(true);

  const activeTool = useCommonSelector(selectActiveTool);

  const activeToolNode = activeTool
    ? steps?.find((step: DiagramStep) => {
        return step.workflowSeqId === activeTool;
      })
    : null;

  const { data: fetchedActiveApplication } = useFetchApplicationByIdQuery(
    activeToolNode && activeToolNode?.applicationId
      ? activeToolNode?.applicationId
      : null,
    {
      skip: !activeToolNode,
    }
  );

  const { data: fetchedActiveActionTemplate } = useFetchActionTemplateByIdQuery(
    activeToolNode && activeToolNode?.actionTemplateId
      ? activeToolNode?.actionTemplateId
      : null,
    {
      skip: !activeToolNode,
    }
  );

  const { data: fetchedActiveConnectionList } =
    useFetchConnectionByFiltersQuery(
      {
        applicationId:
          activeToolNode && activeToolNode?.applicationId
            ? activeToolNode?.applicationId
            : null,
      },
      {
        skip: !activeToolNode,
      }
    );

  const activeToolNodePayload = activeToolNode && {
    application: fetchedActiveApplication,
    actionTemplate: fetchedActiveActionTemplate,
    connection: {
      list: fetchedActiveConnectionList,
      activeConnection: activeToolNode.connectionId,
      parameterValues: activeToolNode?.parameters
        ? [
            ...activeToolNode.parameters,
            { name: 'connection', value: activeToolNode.connectionId },
          ]
        : [],
    },
  };

  const { data: applicationData, isLoading: isApplicationDataLoading } =
    useFetchApplicationsQuery('');

  const [activeApplication, setActiveApplication] = useState<any>(null);

  const [activeActionTemplate, setActiveActionTemplate] = useState<any>(null);

  const [applicationNameFilter, setApplicationNameFilter] =
    useState<string>('');

  const [actionTemplateNameFilter, setActionTemplateNameFilter] =
    useState<string>('');

  const { data: actionTemplateData, isLoading: isActionTemplateDataLoading } =
    useFetchActionTemplatesByParamsQuery(
      {
        data: { applicationId: activeApplication?.id },
      },
      {
        skip: !activeApplication,
      }
    );

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const dispatch = useCommonDispatch();
  const afterTool = useCommonSelector(selectAfterTool);

  const beforeNode = useCommonSelector(selectBeforeNode);
  const display = afterTool !== null || activeTool !== null ? 'block' : 'none';

  const parameterFormState = useForm({
    defaultValues: activeToolNodePayload
      ? {
          connection: activeToolNodePayload.connection,
          ...activeToolNodePayload.connection?.parameterValues?.reduce(
            (accumulator: any, currentValue) => {
              if (currentValue.name) {
                accumulator[currentValue.name] = currentValue.value;
              }
              return accumulator;
            },
            { 'system::name': activeToolNode?.name }
          ),
        }
      : {
          'system::name':
            activeActionTemplate?.rawItem?.name ||
            translation(`common.missingValue`),
        },
  });

  useEffect(() => {
    if (activeTool && activeToolNode) {
      setInitialLoadingController(true);
      setActiveSlideIndex(2);
      setTimelineEventConfig({
        status:
          activeToolNode?.timelineEvent?.status ===
          AutomationRuleStatus.ENABLED,
        value: activeToolNode?.timelineEvent?.value,
      });
      setContinueWorkflowIfFail(
        activeToolNode?.workFlowAffectOnFail === 'continue'
      );
      setTimeout(() => {
        setInitialLoadingController(false);
      }, 500);
    }
  }, [activeTool, display]);

  function handleSubmit(data: any) {
    let activeNodeTemplate =
      activeActionTemplate?.rawItem || fetchedActiveActionTemplate;
    activeNodeTemplate = {
      ...activeNodeTemplate,
      timelineEvent: {
        status: timelineEventConfig.status
          ? AutomationRuleStatus.ENABLED
          : AutomationRuleStatus.DISABLED,
        value: timelineEventConfig.value,
      },
      workFlowAffectOnFail: continueWorkflowIfFail ? 'continue' : 'stop',
    };
    if (afterTool !== null || activeTool !== null) {
      onToolAddOrEdit(
        {
          label: activeActionTemplate?.rawItem?.name,
          logo: activeApplication?.rawItem?.logo,
          application: activeApplication?.rawItem,
          actionTemplate: activeNodeTemplate,
          connectionData: data,
          activeTool,
        },
        afterTool,
        beforeNode,
        activeTool ? 'edit' : 'create'
      );
      handleClose();
      parameterFormState.reset();
      parameterFormState.clearErrors();
    }
  }

  const handleTimelineEventChange = (status: boolean, value: string) => {
    setTimelineEventConfig({
      status: status,
      value: value,
    });
  };

  const handleContinueWorkflowIfFailChange = (status: boolean) => {
    setContinueWorkflowIfFail(status);
  };

  const handleOnSelectAction = (actionTemplate: IListItem) => {
    setActiveActionTemplate(actionTemplate);
    setActiveSlideIndex(2);
    setTimelineEventConfig({
      status:
        actionTemplate?.rawItem?.timelineEvent.status ===
        AutomationRuleStatus.ENABLED,
      value: actionTemplate?.rawItem?.timelineEvent.value,
    });
    setContinueWorkflowIfFail(
      actionTemplate?.rawItem?.timelineEvent.status === 'continue'
    );
  };

  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);

  const ConnectionButtonGroup = () => {
    return (
      <div className="workflow-builder-panel-footer">
        {activeTool ? (
          <Button
            variant="outlined"
            color="inherit"
            type="button"
            sx={{ padding: '0.5rem 2rem' }}
            onClick={() => {
              setOpenAlertDialog(true);
            }}
            className="opus-secondary-button workflow-builder-panel-close-button"
          >
            <OpusSvgIcon type={SVG_ICON_TYPES.TRASH_ICON} />
            <FormattedMessage
              id="flows.details.deleteAction"
              defaultMessage="Delete Action"
            />
          </Button>
        ) : (
          <Box></Box>
        )}
        <Button
          variant="contained"
          sx={{ padding: '0.5rem 2rem' }}
          type="submit"
          className="opus-primary-button workflow-builder-panel-save-button"
        >
          <FormattedMessage id="common.save" defaultMessage="Save" capitalize />
        </Button>
      </div>
    );
  };

  function handleClose() {
    setActiveApplication(null);
    setActiveActionTemplate(null);
    setApplicationNameFilter('');
    setActionTemplateNameFilter('');
    setActiveSlideIndex(0);
    dispatch(setAfterTool(null));
    dispatch(setBeforeNode(null));
    dispatch(setActiveTool(null));
  }

  const renderActiveApplication = () => {
    if (!activeApplication || activeSlideIndex !== 1) return <></>;

    return (
      <div className="workflow-builder-step-panel-active-application">
        <div className="workflow-builder-step-panel-active-application-logo">
          <img
            height={24}
            src={activeApplication.imageMetadata?.src}
            alt={activeApplication.imageMetadata?.alt}
          ></img>
        </div>
        <div className="workflow-builder-step-panel-active-application-title">
          {activeApplication?.label}
        </div>
      </div>
    );
  };

  const modalSteps: Array<ModalStep> = [
    {
      index: 0,
      label: activeToolNodePayload
        ? translation(`flows.details.updateTool`)
        : translation(`flows.details.addTool`),
      searchLabel: translation(`flows.details.selectTool`),
      onFilter: (filterValue: string) => {
        setApplicationNameFilter(filterValue);
      },
      filterValue: applicationNameFilter,
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.SCREWDRIVER_WRENCH_ICON} />,
      children: (
        <List
          items={
            applicationData &&
            applicationData
              .filter((rawApplication: any) =>
                new RegExp(applicationNameFilter).test(
                  rawApplication.name.toLowerCase()
                )
              )
              .map((rawApplication: any) => {
                return {
                  id: rawApplication.id,
                  label: rawApplication.name,
                  imageMetadata: {
                    src: rawApplication.logo,
                    alt: rawApplication.name,
                  },
                  rawItem: rawApplication,
                };
              })
          }
          onSelect={(application) => {
            setActiveApplication(application);
            setActiveSlideIndex(1);
          }}
          itemsLoading={isApplicationDataLoading}
        />
      ),
    },
    {
      index: 1,
      label: translation(`flows.details.chooseAction`),
      searchLabel: translation(`flows.details.selectAction`),
      onFilter: (filterValue: string) => {
        setActionTemplateNameFilter(filterValue);
      },
      filterValue: actionTemplateNameFilter,
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.BOLT_LIGHTNING_ICON} />,
      children: (
        <List
          items={
            actionTemplateData &&
            actionTemplateData
              .filter((rawActionTemplate: any) =>
                new RegExp(actionTemplateNameFilter).test(
                  rawActionTemplate.name.toLowerCase()
                )
              )
              .map((rawActionTemplate: any) => {
                return {
                  id: rawActionTemplate.id,
                  label: rawActionTemplate.name,
                  imageMetadata: activeApplication?.imageMetadata,
                  rawItem: rawActionTemplate,
                };
              })
          }
          onSelect={(actionTemplate) => {
            handleOnSelectAction(actionTemplate);
          }}
          itemsLoading={isActionTemplateDataLoading}
        />
      ),
    },
    {
      index: 2,
      label: translation(`flows.details.additionalSettings`),
      icon: <OpusSvgIcon type={SVG_ICON_TYPES.SLIDERS_ICON} />,
      children: (
        <>
          <DynamicForm
            handleTimelineEventChange={handleTimelineEventChange}
            handleContinueWorkflowIfFailChange={
              handleContinueWorkflowIfFailChange
            }
            continueWorkflowIfFail={continueWorkflowIfFail}
            timelineEventConfig={timelineEventConfig}
            formClassName={
              activeToolNodePayload ? 'limitedHeight' : 'fullHeight'
            }
            formState={parameterFormState}
            onSubmit={handleSubmit}
            initialValues={
              activeToolNodePayload
                ? {
                    connection: activeToolNodePayload.connection,
                    ...activeToolNodePayload.connection?.parameterValues?.reduce(
                      (accumulator: any, currentValue) => {
                        if (currentValue.name) {
                          accumulator[currentValue.name] = currentValue.value;
                        }
                        return accumulator;
                      },
                      { 'system::name': activeToolNode?.name }
                    ),
                  }
                : {
                    'system::name':
                      activeActionTemplate?.rawItem?.name ||
                      translation('common.missingValue'),
                  }
            }
            fields={
              activeActionTemplate || fetchedActiveActionTemplate
                ? [
                    {
                      type: 'text',
                      name: 'system::name',
                      label: translation(`common.name`),
                      className: 'list-panel-input',
                    },
                    ...(
                      activeActionTemplate?.rawItem ||
                      fetchedActiveActionTemplate
                    ).parameters?.map((parameter: any) => {
                      return {
                        name: parameter.name,
                        type: parameter.type,
                        label: parameter.displayName,
                        options: parameter.options,
                        className: 'list-panel-input',
                      };
                    }),
                  ]
                : []
            }
            FormButtons={ConnectionButtonGroup}
          />
        </>
      ),
    },
  ];

  return (
    <DiagramStepContext.Provider
      value={{
        steps,
      }}
    >
      <PopoverWrapper
        handleClose={handleClose}
        showPopover={display === 'block'}
        hideBackdrop
        classes="workflow-builder-panel"
      >
        <div className="workflow-builder-panel-content workflow-builder-step-panel-content">
          <div className="workflow-builder-panel-body">
            {initialLoadingController ? (
              <div className="workflow-builder-panel-body-loading">
                <CircularProgress size={20} />
              </div>
            ) : (
              <>
                {modalSteps.map((modalStep: ModalStep, index: number) => {
                  if (modalStep.index === activeSlideIndex) {
                    return (
                      <>
                        {' '}
                        <div className="workflow-builder-panel-header">
                          <div className="workflow-builder-panel-header-icon">
                            {modalStep.icon}
                          </div>
                          <div className="workflow-builder-panel-header-text">
                            {modalStep.label}
                          </div>
                        </div>
                        <div className="workflow-builder-panel-slider">
                          <Slider
                            onAfterChange={(index) => {
                              !activeTool && setActiveSlideIndex(index);
                            }}
                            activeIndex={activeSlideIndex}
                            marks={[0, 1, 2]}
                          />
                        </div>
                        {renderActiveApplication()}
                        {activeToolNodePayload && (
                          <div className="workflow-builder-step-panel-active-tool-container">
                            <div className="workflow-builder-step-panel-active-tool-icon-with-labels">
                              <div className="workflow-builder-step-panel-active-tool-icon">
                                <img
                                  height={24}
                                  src={activeToolNodePayload.application?.logo}
                                  alt={activeToolNodePayload.actionTemplate}
                                />
                              </div>
                              <div className="workflow-builder-step-panel-active-tool-labels">
                                <p className="workflow-builder-step-panel-active-tool-label">
                                  {activeToolNodePayload.actionTemplate?.name}
                                </p>
                                <p className="workflow-builder-step-panel-active-tool-sub-label">
                                  {activeToolNodePayload.application?.name}
                                </p>
                              </div>
                            </div>
                            <div className="workflow-builder-step-panel-active-tool-id">
                              {activeTool ? (
                                <>
                                  <FormattedMessage
                                    id="flows.details.id"
                                    defaultMessage="ID"
                                  />
                                  <span>{activeTool}</span>
                                </>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )}
                        {modalStep.searchLabel && (
                          <div className="workflow-builder-panel-search-container">
                            <TextField
                              placeholder={translation(`common.search`)}
                              className="workflow-builder-panel-input"
                              value={modalStep.filterValue}
                              InputProps={{
                                startAdornment: (
                                  <OpusSvgIcon
                                    type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON}
                                  />
                                ),
                              }}
                              onChange={(
                                event: ChangeEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >
                              ) => {
                                modalStep.onFilter &&
                                  modalStep.onFilter(event.target.value);
                              }}
                            />
                          </div>
                        )}
                        {modalStep.children}
                      </>
                    );
                  }

                  return <div key={index}></div>;
                })}

                <AlertDialog
                  open={openAlertDialog}
                  text={`${translation(`flows.details.removeActionWarning`)}`}
                  onHandleConfirm={() => {
                    onRemoveNode && activeTool && onRemoveNode(activeTool);
                    setActiveSlideIndex(0);
                    setOpenAlertDialog(false);
                  }}
                  onHandleClose={() => {
                    setOpenAlertDialog(false);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </PopoverWrapper>
    </DiagramStepContext.Provider>
  );
}

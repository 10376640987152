import { FunctionComponent, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useCommonSelector from 'Common/utils/use-selector';
import { DashboardFilterType } from 'Dashboard/interfaces/Dashboard';
import {
  getFilterState,
  getFindingTypes,
  getInitialFilterLoad,
  selectdashboardFilter,
} from 'Dashboard/store';
import { useGetDashboardPostureAnalyticsMutation } from 'Dashboard/store/api';
import { PostureWidgetAnalyticsType } from 'shared/models/data/operational-widget-data.model';
import DashboardWidgetCard from '../DashboardWidgetCard';
import { NumberFormatHandler } from 'shared/handlers/number-format.handler';

export interface PostureWidgetCardProps {
  title: string;
  icon: ReactNode;
  type: PostureWidgetAnalyticsType;
  onClick?: () => void;
}

const numberFormatHandler = new NumberFormatHandler();

export const PostureWidgetCard: FunctionComponent<PostureWidgetCardProps> = ({
  title,
  icon,
  type,
  onClick,
}) => {
  const dashboardFilter: DashboardFilterType = useCommonSelector(
    selectdashboardFilter
  );

  const filterState = useCommonSelector(getFilterState);

  const findingTypes = useMemo(() => {
    return getFindingTypes(filterState);
  }, [filterState['findingType']]);

  const dashboardFilterInitialLoad: boolean =
    useCommonSelector(getInitialFilterLoad);

  const [
    getDashboardAnalytics,
    { data: dashboardAnalytics, isLoading: dashboardAnalyticsLoading },
  ] = useGetDashboardPostureAnalyticsMutation();

  useEffect(() => {
    if (dashboardFilterInitialLoad)
      getDashboardAnalytics({
        findingTypes: findingTypes,
        businessUnitIds: dashboardFilter.businessUnitId,
        type,
      });
  }, [
    dashboardFilterInitialLoad,
    dashboardFilter?.businessUnitId,
    findingTypes,
  ]);

  const transformedDashboardAnalytics = useMemo(() => {
    if (dashboardAnalytics) {
      if (Number.isNaN(dashboardAnalytics.value)) return dashboardAnalytics;

      return {
        value: numberFormatHandler.formatNumberToString(
          dashboardAnalytics.value
        ),
      };
    }

    return undefined;
  }, [dashboardAnalytics]);

  return (
    <DashboardWidgetCard
      icon={icon}
      title={title}
      data={transformedDashboardAnalytics}
      isLoading={dashboardAnalyticsLoading}
      onClick={onClick}
      tooltip={dashboardAnalytics?.value}
    />
  );
};

import { createApi } from '@reduxjs/toolkit/query/react';
import axiosFetchBaseQuery from 'Common/utils/axios-base-query';
import commonConfig from 'Config';
import { SystemLabel } from 'shared/components/LabelManagementDropdown/LabelManagementDropdown';

const sharedApi = createApi({
  reducerPath: 'sharedApi',
  baseQuery: axiosFetchBaseQuery({ baseUrl: commonConfig.bffBaseUrl }),
  endpoints: (builder) => ({
    fetchFilterByType: builder.mutation({
      query: ({ type }) => ({ url: `${type}`, method: 'POST' }),
    }),
    fetchApplications: builder.query({
      query: () => `applications`,
    }),
    fetchTriggers: builder.query({
      query: () => `config/triggers`,
    }),
    fetchApplicationsMetadataByType: builder.query({
      query: ({ type }) => `applications/metadata/${type}`,
    }),
    fetchActionTemplatesByParams: builder.query({
      query: ({ data }) => ({
        url: 'actions/templates/search',
        method: 'POST',
        body: data,
      }),
    }),
    fetchDataBasedOnParameters: builder.mutation({
      query: ({ url, data, method }) => ({
        url,
        method,
        body: data,
      }),
    }),
    getLabels: builder.mutation({
      query: ({ queryString }) => ({
        url: `labels/filters/options?fieldName=label${
          queryString ? `&${queryString}` : ''
        }`,
        method: 'GET',
      }),
    }),
    searchLabels: builder.mutation({
      query: (body) => ({
        url: 'labels/search',
        method: 'POST',
        body,
      }),
    }),
    saveLabels: builder.mutation({
      query: ({ items, itemId, itemType }) => ({
        url: `labels/${itemType}/${itemId}/save`,
        method: 'POST',
        body: items,
      }),
    }),
    bulkAddLabels: builder.mutation({
      query: (body) => ({
        url: `labels/bulk/add`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export default sharedApi;

export const {
  useFetchFilterByTypeMutation,
  useFetchTriggersQuery,
  useFetchApplicationsQuery,
  useFetchApplicationsMetadataByTypeQuery,
  useFetchActionTemplatesByParamsQuery,
  useFetchDataBasedOnParametersMutation,
  useGetLabelsMutation,
  useSearchLabelsMutation,
  useSaveLabelsMutation,
  useBulkAddLabelsMutation,
} = sharedApi;

import {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { DropdownProps } from '../DataFilters/CategoryDropdown/CategoryDropdown';
import {
  Button,
  IconButton,
  Popover,
  PopoverProps,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import OpusSvgIcon from '../IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { debounce } from 'lodash';
import LabelListManager from '../LabelListManager';

export enum SystemLabelType {
  RESOURCE = 'resource',
  FINDING = 'finding',
  CODE_EVENT = 'code-event',
  ASSET = 'asset',
}

export interface SystemLabel {
  itemType?: SystemLabelType;
  itemId?: string;
  label: string;
}

interface LabelManagementDropdownProps
  extends BaseComponentProps,
    DropdownProps {
  onLabelSelect: (label: SystemLabel) => void;
  selectedLabels: SystemLabel[];
  filteredRecentLabels: SystemLabel[];
  filteredAllLabels: SystemLabel[];
  loading: boolean;
  refetch: () => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  addLabel: (labelName: string) => void;
  saveLabels: (labels: Array<SystemLabel>) => void;
  displaySaveButton: boolean;
  savingLabels: boolean;
  savingLabelsSuccess?: boolean;
  rootClassName?: string;
}

export const LabelManagementDropdown: FunctionComponent<
  LabelManagementDropdownProps
> = ({
  open,
  anchorEl,
  handleClose,
  anchorOrigin,
  onLabelSelect,
  selectedLabels,
  filteredAllLabels,
  filteredRecentLabels,
  refetch,
  loading,
  searchTerm,
  setSearchTerm,
  addLabel,
  saveLabels,
  displaySaveButton,
  savingLabels,
  savingLabelsSuccess,
  rootClassName = '',
}) => {
  const { t: translation } = useTranslation();

  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const addInputRef = useRef<HTMLInputElement | null>(null);
  const itemListRef = useRef<HTMLDivElement | null>(null);

  const [displayInput, setDisplayInput] = useState<boolean>(false);

  useEffect(() => {
    if (savingLabelsSuccess) {
      resetHandler();
    }
  }, [savingLabelsSuccess]);

  const resetInputs = () => {
    if (searchInputRef.current) searchInputRef.current.value = '';
    if (addInputRef.current) addInputRef.current.value = '';
    setSearchTerm('');
    setDisplayInput(false);
  };

  const searchInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchTerm(value);
  };

  const addLabelHandler = () => {
    const inputValue = addInputRef.current?.value;

    if (inputValue) {
      addLabel(inputValue);
      if (itemListRef.current) itemListRef.current.scroll({ top: 0 });
    }

    resetInputs();
  };

  const resetHandler = () => {
    resetInputs();
    refetch();
  };

  const renderSearchInput = () => {
    return (
      <TextField
        inputRef={searchInputRef}
        className="filter-input filter-main-input"
        placeholder={translation(`common.searchPlaceholder`)}
        onChange={debounce(searchInputHandler, 300)}
        InputProps={{
          className: 'search-filter-input',
          startAdornment: (
            <OpusSvgIcon type={SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON} />
          ),
        }}
      />
    );
  };

  const renderRecentList = () => {
    return (
      <LabelListManager
        labels={filteredRecentLabels || []}
        selectedLabels={selectedLabels || []}
        onLabelSelect={onLabelSelect}
        loading={loading}
        title="Recent Labels"
      />
    );
  };

  const renderAllList = () => {
    return (
      <LabelListManager
        labels={filteredAllLabels || []}
        selectedLabels={selectedLabels || []}
        onLabelSelect={onLabelSelect}
        loading={loading}
        title={searchTerm.length ? '' : 'All Labels'}
      />
    );
  };

  const renderList = () => {
    if (searchTerm.length) {
      return (
        <div className="label-management-dropdown-list-section">
          {renderAllList()}
        </div>
      );
    }

    return (
      <>
        {renderRecentList()}
        {renderAllList()}
      </>
    );
  };

  const renderInput = () => {
    if (displayInput) {
      return (
        <div className="label-management-dropdown-list-item-add">
          <input
            ref={addInputRef}
            type="text"
            className="text-field-1"
            autoFocus
          ></input>
          <div className="label-management-dropdown-list-item-add-controls">
            <IconButton
              onClick={() => {
                setDisplayInput(false);
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.XMARK_ICON} />
            </IconButton>
            <IconButton
              className="label-management-dropdown-list-item-add-button"
              onClick={() => {
                addLabelHandler();
              }}
            >
              <OpusSvgIcon type={SVG_ICON_TYPES.CHECK_ICON} />
            </IconButton>
          </div>
        </div>
      );
    }

    return (
      <Button
        onClick={() => {
          setDisplayInput(true);
        }}
        className="label-management-dropdown-list-item-new"
      >
        <OpusSvgIcon type={SVG_ICON_TYPES.PLUS_ICON} />{' '}
        <span>{translation('labels.newLabel')}</span>
      </Button>
    );
  };

  const renderSaveButton = () => {
    if (displaySaveButton) {
      return (
        <Button
          className="label-management-dropdown-save-button"
          onClick={() => {
            saveLabels(selectedLabels);
          }}
        >
          {savingLabels ? 'Saving...' : 'Save'}
        </Button>
      );
    }

    return <></>;
  };

  return (
    <Popover
      id="label-management-dropdown"
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        debounce(resetInputs, 300)();
        handleClose();
      }}
      anchorOrigin={anchorOrigin}
      className={rootClassName}
      PaperProps={{
        style: {
          width: anchorEl ? anchorEl.offsetWidth : 'auto',
        },
      }}
    >
      <div className="label-management-dropdown-content">
        <div className="label-management-dropdown-body">
          <div className="label-management-dropdown-search">
            {renderSearchInput()}
          </div>
          <div className="label-management-dropdown-list" ref={itemListRef}>
            {renderList()}
          </div>
        </div>

        <div className="label-management-dropdown-footer">
          <div className="label-management-dropdown-input">{renderInput()}</div>
          <div className="label-management-dropdown-save">
            {renderSaveButton()}
          </div>
        </div>
      </div>
    </Popover>
  );
};

import { ApplicationTypes } from 'Application/interfaces/applications.enum';
import {
  OpusSvgIconProps,
  OpusPngIconProps,
  OpusAvatarIconProps,
  OpusImageIconProps,
} from '../interface';
import { SVG_ICON_TYPES } from '../enums';
import { ReactComponent as AlertShieldIcon } from '../alert-shield-icon.svg';
import { ReactComponent as AWSConfigMainIcon } from '../aws-config-main-icon.svg';
import { ReactComponent as AWSMainIcon } from '../aws-main-icon.svg';
import { ReactComponent as AzureMainIcon } from '../azure-main-icon.svg';
import { ReactComponent as ContainerIcon } from '../container-icon.svg';
import { ReactComponent as GoogleCloudMainIcon } from '../google-cloud-main-icon.svg';
import { ReactComponent as GroupWithPeopleIcon } from '../group-with-people-icon.svg';
import { ReactComponent as OpusMainIcon } from '../opus-main-icon.svg';
import { ReactComponent as PlatformIcon } from '../platform-icon.svg';
import { ReactComponent as RedFlagIcon } from '../red-flag-icon.svg';
import { ReactComponent as RhombusWithAHoleIcon } from '../rhombus-with-a-hole-icon.svg';
import { ReactComponent as SettingsCogIcon } from '../settings-cog-icon.svg';
import { ReactComponent as SettingsWheelsIcon } from '../settings-wheels-icon.svg';
import { ReactComponent as StackIcon } from '../stack-icon.svg';
import { ReactComponent as SuccessCheckIcon } from '../success-check-icon.svg';
import { ReactComponent as TimesIcon } from '../times-icon.svg';
import { ReactComponent as CheckedIcon } from '../checked-icon.svg';
import { ReactComponent as UncheckedIcon } from '../unchecked-icon.svg';
import { ReactComponent as TriggerIcon } from '../trigger-icon.svg';
import { ReactComponent as EmptyTriggerIcon } from '../empty-trigger-icon.svg';
import { ReactComponent as SelectedTriggerIcon } from '../selected-trigger-icon.svg';
import { ReactComponent as InfoIcon } from '../info-icon.svg';
import { ReactComponent as PlusButtonIcon } from '../plus-button-icon.svg';
import { ReactComponent as PlusIcon } from '../plus-icon.svg';
import { ReactComponent as RuleControlIcon } from '../rule-control-icon.svg';
import { ReactComponent as ShieldCheckIcon } from '../shield-check-icon.svg';
import { ReactComponent as TrashIcon } from '../trash-icon.svg';
import { ReactComponent as GitIcon } from '../git-icon.svg';
import { ReactComponent as GitPullRequestIcon } from '../git-pull-request-icon.svg';
import { ReactComponent as TerraformIcon } from '../terraform-icon.svg';
import { ReactComponent as IacSourceIcon } from '../iac-source-icon.svg';
import { ReactComponent as CloudSourceIcon } from '../cloud-source-icon.svg';
import { ReactComponent as SlackMainIcon } from '../slack-main-icon.svg';
import { ReactComponent as GmailMainIcon } from '../gmail-main-icon.svg';
import { ReactComponent as OneLoginIcon } from '../one-login-icon.svg';
import { ReactComponent as AnchorIcon } from '../anchor-icon.svg';
import { ReactComponent as ApplicationIcon } from '../application-icon.svg';
import { ReactComponent as GuardRailsCounterIcon } from '../guard-rails-counter-icon.svg';
import { ReactComponent as FindingsCounterIcon } from '../findings-counter-icon.svg';
import { ReactComponent as CodeVsCloudCounterIcon } from '../code-vs-cloud-counter-icon.svg';
import { ReactComponent as SlaCounterIcon } from '../sla-counter-icon.svg';
import { ReactComponent as ErrorBaseIcon } from '../error-base-icon.svg';
import { ReactComponent as SuccessBaseIcon } from '../success-base-icon.svg';
import { ReactComponent as CaretRightIcon } from '../caret-right-icon.svg';
import { ReactComponent as SortGroupIcon } from '../sort-group-icon.svg';
import { ReactComponent as FilterToggleIcon } from '../filter-toggle-icon.svg';
import { ReactComponent as CircularPlusButtonIcon } from '../circular-plus-button-icon.svg';
import { ReactComponent as SortAscendingIcon } from '../sort-ascending-icon.svg';
import { ReactComponent as SortDescendingIcon } from '../sort-descending-icon.svg';
import { ReactComponent as FilterCloseIcon } from '../filter-close-icon.svg';
import { ReactComponent as TriangleExclamationIcon } from '../triangle-exclamation-icon.svg';
import { ReactComponent as JiraMainIcon } from '../jira-main-icon.svg';
import { ReactComponent as GithubMainIcon } from '../github-main-icon.svg';
import { ReactComponent as CaretDownIcon } from '../caret-down-icon.svg';
import { ReactComponent as MediaPlayIcon } from '../media-play-icon.svg';
import { ReactComponent as AzureDevopsIcon } from '../azure-devops.svg';
import { ReactComponent as BrakeWarningIcon } from '../brake-warning-icon.svg';
import { ReactComponent as CaretLeftIcon } from '../caret-left-icon.svg';
import { ReactComponent as CalendarStarIcon } from '../calendar-star-icon.svg';
import { ReactComponent as CalendarIcon } from '../calendar-icon.svg';
import { ReactComponent as CheckIcon } from '../check-icon.svg';
import { ReactComponent as NotesIcon } from '../notes-icon.svg';
import { ReactComponent as ToolsIcon } from '../tools-icon.svg';
import { ReactComponent as ArrowDownIcon } from '../arrow-down-icon.svg';
import { ReactComponent as ArrowUpIcon } from '../arrow-up-icon.svg';
import { ReactComponent as SimpleCalendarIcon } from '../simple-calendar-icon.svg';
import { ReactComponent as MagnifyingZoom } from '../magnifying-glass-zoom.svg';
import { ReactComponent as GearIcon } from '../gear-icon.svg';
import { ReactComponent as ChevronUpIcon } from '../chevron-up-icon.svg';
import { ReactComponent as ChevronDownIcon } from '../chevron-down-icon.svg';
import { ReactComponent as UncheckedCheckboxIcon } from '../unchecked-checkbox.svg';
import { ReactComponent as CheckedCheckboxIcon } from '../checked-checkbox.svg';
import { ReactComponent as IndeterminateCheckboxIcon } from '../indeterminate-checkbox.svg';
import { ReactComponent as ExpandMoreIcon } from '../expand-more-icon.svg';
import { ReactComponent as EllipsisIcon } from '../ellipsis-icon.svg';
import { ReactComponent as ClockIcon } from '../clock-icon.svg';
import { ReactComponent as BucketIcon } from '../bucket-icon.svg';
import { ReactComponent as CampaignIcon } from '../campaign-icon.svg';
import { ReactComponent as TicketIcon } from '../ticket-icon.svg';
import { ReactComponent as TicketSecondaryIcon } from '../times-secondary-icon.svg';
import { ReactComponent as CircleTimesIcon } from '../circle-times-icon.svg';
import { ReactComponent as StopwatchIcon } from '../stopwatch-icon.svg';
import { ReactComponent as MagnifyingGlassIcon } from '../magnifying-glass-icon.svg';
import { ReactComponent as LifecycleCodeIcon } from '../lifecycle-code-icon.svg';
import { ReactComponent as LifecycleArtifactIcon } from '../lifecycle-artifact-icon.svg';
import { ReactComponent as LifecycleRuntimeIcon } from '../lifecycle-runtime-icon.svg';
import { ReactComponent as CodeForkIcon } from '../code-fork-icon.svg';
import { ReactComponent as UserIcon } from '../user-icon.svg';
import { ReactComponent as UserAddIcon } from '../user-add-icon.svg';
import { ReactComponent as ChartNetworkIcon } from '../chart-network-icon.svg';
import { ReactComponent as DatabaseIcon } from '../database-icon.svg';
import { ReactComponent as SatelliteDishIcon } from '../satellite-dish-icon.svg';
import { ReactComponent as UserGroupIcon } from '../user-group-icon.svg';
import { ReactComponent as PeopleGroupIcon } from '../people-group-icon.svg';
import { ReactComponent as HexagonExclamationIcon } from '../hexagon-exclamation-icon.svg';
import { ReactComponent as HugIcon } from '../hug-icon.svg';
import { ReactComponent as InfoCircleIcon } from '../info-circle-icon.svg';
import { ReactComponent as CloseIcon } from '../close-icon.svg';
import { ReactComponent as AlternativePlusIcon } from '../alternative-plus-icon.svg';
import { ReactComponent as AlternativeTrashIcon } from '../alternative-trash-icon.svg';
import { ReactComponent as CircleExclamationIcon } from '../circle-exclamation-icon.svg';
import { ReactComponent as CircleExclamationBaseIcon } from '../circle-exclamation-base-icon.svg';
import { ReactComponent as CrowdStrikeIcon } from '../crowdstrike-icon.svg';
import { ReactComponent as CyeraIcon } from '../cyera-icon.svg';
import { ReactComponent as AddTeamIcon } from '../add-team-icon.svg';
import { ReactComponent as CopyIcon } from '../copy-icon.svg';
import { ReactComponent as CopyIconGray } from '../copy-icon-gray.svg';
import { ReactComponent as TrashIconOutlined } from '../trash-icon-outlined.svg';
import { ReactComponent as ArrowUp } from '../arrow-up.svg';
import { ReactComponent as ArrowDown } from '../arrow-down.svg';
import { ReactComponent as AlternativeCloseIcon } from '../close.svg';
import { ReactComponent as RadioButtonChecked } from '../radio-button-checked.svg';
import { ReactComponent as RadioButtonUnchecked } from '../radio-button-unchecked.svg';
import { ReactComponent as MessageIcon } from '../message-icon.svg';
import { ReactComponent as CloudIcon } from '../cloud-icon.svg';
import { ReactComponent as ShieldVirusIcon } from '../shield_virus.svg';
import { ReactComponent as ClipboardListCheckIcon } from '../clipboard-list-check-icon.svg';
import { ReactComponent as EnvelopeIcon } from '../envelope-icon.svg';
import { ReactComponent as ChevronRightIcon } from '../chevron-right-icon.svg';
import { ReactComponent as ArrowLeftIcon } from '../arrow-left-icon.svg';
import { ReactComponent as ListCheckIcon } from '../list-check-icon.svg';
import { ReactComponent as BadgeCheckIcon } from '../badge-check-icon.svg';
import { ReactComponent as EllipsisStrokeIcon } from '../ellipsis-stroke-icon.svg';
import { ReactComponent as SecurityHubIcon } from '../security-hub-icon.svg';
import { ReactComponent as BookSparklesIcon } from '../book-sparkles-icon.svg';
import { ReactComponent as ClipboardCopyIcon } from '../clipboard-copy-icon.svg';
import { ReactComponent as WizIcon } from '../wiz-icon.svg';
import { ReactComponent as OrcaIcon } from '../orca-icon.svg';
import { ReactComponent as GithubIcon } from '../github-icon.svg';
import { ReactComponent as JiraIcon } from '../jira-icon.svg';
import { ReactComponent as ShieldKeyholeIcon } from '../shield-keyhole-icon.svg';
import { ReactComponent as EditIcon } from '../edit-icon.svg';
import { ReactComponent as StarIcon } from '../star-icon.svg';
import { ReactComponent as DeployIcon } from '../deploy-icon.svg';
import { ReactComponent as PlugIcon } from '../plug-icon.svg';
import { ReactComponent as BriefcaseIcon } from '../briefcase-icon.svg';
import { ReactComponent as CrosshairsIcons } from '../crosshairs-icon.svg';
import { ReactComponent as SquareSmallIcon } from '../square-small-icon.svg';
import { ReactComponent as SlidersIcon } from '../sliders-icon.svg';
import { ReactComponent as MicrosoftTeamsIcon } from '../microsoft-teams-icon.svg';
import { ReactComponent as SitemapIcon } from '../sitemap-icon.svg';
import { ReactComponent as SitemapBlackIcon } from '../sitemap-black-icon.svg';
import { ReactComponent as LightEmergencyIcon } from '../light-emergency-icon.svg';
import { ReactComponent as CircleInfoIcon } from '../circle-info-icon.svg';
import { ReactComponent as RadioButtonUncheckedIcon } from '../radio-button-unchecked-icon.svg';
import { ReactComponent as RadioButtonCheckedIcon } from '../radio-button-checked-icon.svg';
import { ReactComponent as RerunIcon } from '../rerun-icon.svg';
import { ReactComponent as LightBulbIcon } from '../lightbulb-icon.svg';
import { ReactComponent as HourGlassIcon } from '../hourglass-icon.svg';
import { ReactComponent as RadarIcon } from '../radar-icon.svg';
import { ReactComponent as RadarGrayIcon } from '../radar-gray-icon.svg';
import { ReactComponent as LinkSlashIcon } from '../link-slash-icon.svg';
import { ReactComponent as TagsIcon } from '../tags-icon.svg';
import { ReactComponent as ChartPieIcon } from '../chart-pie-icon.svg';
import { ReactComponent as LockOpenIcon } from '../lock-open-icon.svg';
import { ReactComponent as ScrewdriverWrenchIcon } from '../screwdriver-wrench-icon.svg';
import { ReactComponent as SirenOnIcon } from '../siren-on-icon.svg';
import { ReactComponent as TelescopeIcon } from '../telescope-icon.svg';
import { ReactComponent as ChartSimpleIcon } from '../chart-simple-icon.svg';
import { ReactComponent as BullhornIcon } from '../bullhorn-icon.svg';
import { ReactComponent as CheckToSlotIcon } from '../check-to-slot-icon.svg';
import { ReactComponent as FileExclamationIcon } from '../file-exclamation-icon.svg';
import { ReactComponent as BallotCheckIcon } from '../ballot-check-icon.svg';
import { ReactComponent as ClipboardCheckIcon } from '../clipboard-check-icon.svg';
import { ReactComponent as PercentageIcon } from '../percent-icon.svg';
import { ReactComponent as PostalIcon } from '../postal-icon.svg';
import { ReactComponent as BugIcon } from '../bug-icon.svg';
import { ReactComponent as CubesIcon } from '../cubes-icon.svg';
import { ReactComponent as FingerprintIcon } from '../fingerprint-icon.svg';
import { ReactComponent as GraduationCapIcon } from '../graduation-cap-icon.svg';
import { ReactComponent as SensorIcon } from '../sensor-icon.svg';
import { ReactComponent as BoxIcon } from '../box-icon.svg';
import { ReactComponent as SquareCodeIcon } from '../square-code-icon.svg';
import { ReactComponent as FileCodeIcon } from '../file-code-icon.svg';
import { ReactComponent as UserPenIcon } from '../user-pen-icon.svg';
import { ReactComponent as VisibilityIcon } from '../visibility-icon.svg';
import { ReactComponent as MultiTicketIcon } from '../multi-ticket-icon.svg';
import { ReactComponent as FeatherIcon } from '../feather-icon.svg';
import { ReactComponent as CirlceCheckIcon } from '../circle-check-icon.svg';
import { ReactComponent as CalendarClockIcon } from '../calendar-clock-icon.svg';
import { ReactComponent as HourglassClockIcon } from '../hourglass-clock-icon.svg';
import { ReactComponent as GaugeCircleBoltIcon } from '../gauge-circle-bolt-icon.svg';
import { ReactComponent as ArrowUpRightFromSquareIcon } from '../arrow-up-right-from-square-icon.svg';
import { ReactComponent as EyesIcon } from '../eyes-icon.svg';
import { ReactComponent as SquareInfoIcon } from '../square-info-icon.svg';
import { ReactComponent as CodeIcon } from '../code-icon.svg';
import { ReactComponent as SquareTerminalIcon } from '../square-terminal-icon.svg';
import { ReactComponent as OctagonCheckIcon } from '../octagon-check-icon.svg';
import { ReactComponent as CurlyBracketsIcon } from '../curly-brackets-icon.svg';
import { ReactComponent as CaretBoldDownIcon } from '../caret-bold-down-icon.svg';
import { ReactComponent as CaretBoldRightIcon } from '../caret-bold-right-icon.svg';
import { ReactComponent as StarChristmasIcon } from '../star-christmas-icon.svg';
import { ReactComponent as CubeIcon } from '../cube-icon.svg';
import { ReactComponent as ServerIcon } from '../server-icon.svg';
import { ReactComponent as BoltLightningIcon } from '../bolt-lightning-icon.svg';
import { ReactComponent as SquareDashedCirclePlusIcon } from '../square-dashed-circle-plus-icon.svg';
import { ReactComponent as FloppyDiskIcon } from '../floppy-disk-icon.svg';
import { ReactComponent as NetworkWiredIcon } from '../network-wired-icon.svg';
import { ReactComponent as SensorCloudIcon } from '../sensor-cloud-icon.svg';
import { ReactComponent as CircleDotIcon } from '../circle-dot-icon.svg';
import { ReactComponent as CircleIcon } from '../circle-icon.svg';
import { ReactComponent as PlayIcon } from '../play-icon.svg';
import { ReactComponent as FileCsvIcon } from '../file-csv-icon.svg';
import { ReactComponent as FileImportIcon } from '../file-import-icon.svg';
import { ReactComponent as UploadIcon } from '../upload-icon.svg';
import { ReactComponent as RibbonIcon } from '../ribbon-icon.svg';
import { ReactComponent as ArrowTrendUpIcon } from '../arrow-trend-up-icon.svg';
import { ReactComponent as ShieldExclamationICon } from '../shield-exclamation-icon.svg';
import { ReactComponent as MessagesIcon } from '../messages-icon.svg';
import { ReactComponent as LaptopCodeIcon } from '../laptop-code-icon.svg';
import { ReactComponent as UnlockIcon } from '../unlock-icon.svg';
import { ReactComponent as BiohazardIcon } from '../biohazard-icon.svg';
import { ReactComponent as SkullIcon } from '../skull-icon.svg';
import { ReactComponent as HoodCloakIcon } from '../hood-cloak-icon.svg';
import { ReactComponent as SackDollarIcon } from '../sack-dollar-icon.svg';
import { ReactComponent as WrenchIcon } from '../wrench-icon.svg';
import { ReactComponent as EllipseIcon } from '../ellipse-icon.svg';
import { ReactComponent as ClipboardListIcon } from '../clipboard-list-icon.svg';
import { ReactComponent as FileIcon } from '../file-icon.svg';
import { ReactComponent as BombIcon } from '../bomb-icon.svg';
import { ReactComponent as BrokenChainIcon } from '../broken-chain-icon.svg';
import { ReactComponent as MaskIcon } from '../mask-icon.svg';
import { ReactComponent as IntelligenceIcon } from '../intelligence-icon.svg';
import { ReactComponent as BuildingsIcon } from '../buildings-icon.svg';
import { ReactComponent as CisaIcon } from '../cisa-icon.svg';
import { ReactComponent as GlobeIcon } from '../globe-icon.svg';
import { ReactComponent as MonitorWaveformIcon } from '../monitor-waveform-icon.svg';
import { ReactComponent as CrownIcon } from '../crown-icon.svg';
import { ReactComponent as XmarkIcon } from '../xmark-icon.svg';
import { ReactComponent as PuzzlePieceIcon } from '../puzzle-piece-icon.svg';
import { ReactComponent as RobotIcon } from '../robot-icon.svg';
import { ReactComponent as WavePulseIcon } from '../wave-pulse-icon.svg';
import { ReactComponent as PowerOffIcon } from '../power-off-icon.svg';
import { ReactComponent as DiagramProjectIcon } from '../diagram-project-icon.svg';
import { ReactComponent as OctagonExclamationIcon } from '../octagon-exclamation.svg';

import SeverityIcon from 'FindingDetails/components/Severity/SeverityIcon';
import CommonIconMui from 'Common/components/IconMui/Icon';
import { Avatar } from '@mui/material';
import _ from 'lodash';

export class IconFactory {
  getSvgIconByAppType(
    type: ApplicationTypes,
    props: OpusSvgIconProps
  ): JSX.Element {
    switch (type) {
      case ApplicationTypes.GITHUB:
        return <GithubIcon {...props} />;
      case ApplicationTypes.JIRA_CLOUD_PLATFORM:
        return <JiraIcon {...props} />;
      case ApplicationTypes.AZURE_DEVOPS:
        return <AzureDevopsIcon {...props} />;
      default:
        return <></>;
    }
  }

  getSvgIconByType(type: SVG_ICON_TYPES, props: OpusSvgIconProps): JSX.Element {
    switch (type) {
      case SVG_ICON_TYPES.ALERT_SHIELD_ICON:
        return <AlertShieldIcon {...props} />;
      case SVG_ICON_TYPES.AWS_CONFIG_MAIN_ICON:
        return <AWSConfigMainIcon {...props} />;
      case SVG_ICON_TYPES.AWS_MAIN_ICON:
        return <AWSMainIcon {...props} />;
      case SVG_ICON_TYPES.AZURE_MAIN_ICON:
        return <AzureMainIcon {...props} />;
      case SVG_ICON_TYPES.CONTAINER_ICON:
        return <ContainerIcon {...props} />;
      case SVG_ICON_TYPES.GOOGLE_CLOUD_MAIN_ICON:
        return <GoogleCloudMainIcon {...props} />;
      case SVG_ICON_TYPES.GROUP_WITH_PEOPLE_ICON:
        return <GroupWithPeopleIcon {...props} />;
      case SVG_ICON_TYPES.OPUS_MAIN_ICON:
        return <OpusMainIcon {...props} />;
      case SVG_ICON_TYPES.PLATFORM_ICON:
        return <PlatformIcon {...props} />;
      case SVG_ICON_TYPES.RED_FLAG_ICON:
        return <RedFlagIcon {...props} />;
      case SVG_ICON_TYPES.RHOMBUS_WITH_A_HOLE_ICON:
        return <RhombusWithAHoleIcon {...props} />;
      case SVG_ICON_TYPES.SETTINGS_COG_ICON:
        return <SettingsCogIcon {...props} />;
      case SVG_ICON_TYPES.SETTINGS_WHEEL_ICON:
        return <SettingsWheelsIcon {...props} />;
      case SVG_ICON_TYPES.STACK_ICON:
        return <StackIcon {...props} />;
      case SVG_ICON_TYPES.SUCCESS_CHECK_ICON:
        return <SuccessCheckIcon {...props} />;
      case SVG_ICON_TYPES.TIMES_ICON:
        return <TimesIcon {...props} />;
      case SVG_ICON_TYPES.SEVERITY_ICON:
        return <SeverityIcon {...props} />;
      case SVG_ICON_TYPES.CHECKED_ICON:
        return <CheckedIcon {...props} />;
      case SVG_ICON_TYPES.UNCHECKED_ICON:
        return <UncheckedIcon {...props} />;
      case SVG_ICON_TYPES.TRIGGER_ICON:
        return <TriggerIcon {...props} />;
      case SVG_ICON_TYPES.EMPTY_TRIGGER_ICON:
        return <EmptyTriggerIcon {...props} />;
      case SVG_ICON_TYPES.SELECTED_TRIGGER_ICON:
        return <SelectedTriggerIcon {...props} />;
      case SVG_ICON_TYPES.INFO_ICON:
        return <InfoIcon {...props} />;
      case SVG_ICON_TYPES.PLUS_BUTTON_ICON:
        return <PlusButtonIcon {...props} />;
      case SVG_ICON_TYPES.PLUS_ICON:
        return <PlusIcon {...props} />;
      case SVG_ICON_TYPES.RULE_CONTROL_ICON:
        return <RuleControlIcon {...props} />;
      case SVG_ICON_TYPES.SHIELD_CHECK_ICON:
        return <ShieldCheckIcon {...props} />;
      case SVG_ICON_TYPES.TRASH_ICON:
        return <TrashIcon {...props} />;
      case SVG_ICON_TYPES.GIT_ICON:
        return <GitIcon {...props} />;
      case SVG_ICON_TYPES.GIT_PULL_REQUEST_ICON:
        return <GitPullRequestIcon {...props} />;
      case SVG_ICON_TYPES.TERRAFORM_ICON:
        return <TerraformIcon {...props} />;
      case SVG_ICON_TYPES.IAC_SOURCE_ICON:
        return <IacSourceIcon {...props} />;
      case SVG_ICON_TYPES.CLOUD_SOURCE_ICON:
        return <CloudSourceIcon {...props} />;
      case SVG_ICON_TYPES.SLACK_MAIN_ICON:
        return <SlackMainIcon {...props} />;
      case SVG_ICON_TYPES.GMAIL_MAIN_ICON:
        return <GmailMainIcon {...props} />;
      case SVG_ICON_TYPES.ONE_LOGIN_ICON:
        return <OneLoginIcon {...props} />;
      case SVG_ICON_TYPES.ANCHOR_ICON:
        return <AnchorIcon {...props} />;
      case SVG_ICON_TYPES.APPLICATION_ICON:
        return <ApplicationIcon {...props} />;
      case SVG_ICON_TYPES.GUARD_RAILS_COUNTER_ICON:
        return <GuardRailsCounterIcon {...props} />;
      case SVG_ICON_TYPES.FINDINGS_COUNTER_ICON:
        return <FindingsCounterIcon {...props} />;
      case SVG_ICON_TYPES.CODE_VS_CLOUD_COUNTER_ICON:
        return <CodeVsCloudCounterIcon {...props} />;
      case SVG_ICON_TYPES.SLA_COUNTER_ICON:
        return <SlaCounterIcon {...props} />;
      case SVG_ICON_TYPES.ERROR_BASE_ICON:
        return <ErrorBaseIcon {...props} />;
      case SVG_ICON_TYPES.SUCCESS_BASE_ICON:
        return <SuccessBaseIcon {...props} />;
      case SVG_ICON_TYPES.CARET_RIGHT_ICON:
        return <CaretRightIcon {...props} />;
      case SVG_ICON_TYPES.SORT_GROUP_ICON:
        return <SortGroupIcon {...props} />;
      case SVG_ICON_TYPES.FILTER_TOGGLE_ICON:
        return <FilterToggleIcon {...props} />;
      case SVG_ICON_TYPES.CIRCULAR_PLUS_BUTTON_ICON:
        return <CircularPlusButtonIcon {...props} />;
      case SVG_ICON_TYPES.SORT_ASCENDING_ICON:
        return <SortAscendingIcon {...props} />;
      case SVG_ICON_TYPES.SORT_DESCENDING_ICON:
        return <SortDescendingIcon {...props} />;
      case SVG_ICON_TYPES.FILTER_CLOSE_ICON:
        return <FilterCloseIcon {...props} />;
      case SVG_ICON_TYPES.TRIANGLE_EXCLAMATION_ICON:
        return <TriangleExclamationIcon {...props} />;
      case SVG_ICON_TYPES.JIRA_MAIN_ICON:
        return <JiraMainIcon {...props} />;
      case SVG_ICON_TYPES.GITHUB_MAIN_ICON:
        return <GithubMainIcon {...props} />;
      case SVG_ICON_TYPES.CARET_DOWN_ICON:
        return <CaretDownIcon {...props} />;
      case SVG_ICON_TYPES.MEDIA_PLAY_ICON:
        return <MediaPlayIcon {...props} />;
      case SVG_ICON_TYPES.AZURE_DEVOPS_ICON:
        return <AzureDevopsIcon {...props} />;
      case SVG_ICON_TYPES.BRAKE_WARNING_ICON:
        return <BrakeWarningIcon {...props} />;
      case SVG_ICON_TYPES.CARET_LEFT_ICON:
        return <CaretLeftIcon {...props} />;
      case SVG_ICON_TYPES.CALENDAR_ICON:
        return <CalendarIcon {...props} />;
      case SVG_ICON_TYPES.CALENDAR_STAR_ICON:
        return <CalendarStarIcon {...props} />;
      case SVG_ICON_TYPES.CHECK_ICON:
        return <CheckIcon {...props} />;
      case SVG_ICON_TYPES.NOTES_ICON:
        return <NotesIcon {...props} />;
      case SVG_ICON_TYPES.TOOLS_ICON:
        return <ToolsIcon {...props} />;
      case SVG_ICON_TYPES.ARROW_UP_ICON:
        return <ArrowUpIcon {...props} />;
      case SVG_ICON_TYPES.ARROW_DOWN_ICON:
        return <ArrowDownIcon {...props} />;
      case SVG_ICON_TYPES.SIMPLE_CALENDAR_ICON:
        return <SimpleCalendarIcon {...props} />;
      case SVG_ICON_TYPES.MAGNIFYING_ZOOM:
        return <MagnifyingZoom {...props} />;
      case SVG_ICON_TYPES.GEAR_ICON:
        return <GearIcon {...props} />;
      case SVG_ICON_TYPES.CHEVRON_UP_ICON:
        return <ChevronUpIcon {...props} />;
      case SVG_ICON_TYPES.CHEVRON_DOWN_ICON:
        return <ChevronDownIcon {...props} />;
      case SVG_ICON_TYPES.CHECKED_CHECKBOX_ICON:
        return <CheckedCheckboxIcon {...props} />;
      case SVG_ICON_TYPES.INDETERMINATE_CHECKBOX_ICON:
        return <IndeterminateCheckboxIcon {...props} />;
      case SVG_ICON_TYPES.UNCHECKED_CHECKBOX_ICON:
        return <UncheckedCheckboxIcon {...props} />;
      case SVG_ICON_TYPES.EXPAND_MORE_ICON:
        return <ExpandMoreIcon {...props} />;
      case SVG_ICON_TYPES.BUCKET_ICON:
        return <BucketIcon {...props} />;
      case SVG_ICON_TYPES.CLOCK_ICON:
        return <ClockIcon {...props} />;
      case SVG_ICON_TYPES.ELLIPSIS_ICON:
        return <EllipsisIcon {...props} />;
      case SVG_ICON_TYPES.CAMPAIGN_ICON:
        return <CampaignIcon {...props} />;
      case SVG_ICON_TYPES.TICKET_ICON:
        return <TicketIcon {...props} />;
      case SVG_ICON_TYPES.TIMES_SECONDARY_ICON:
        return <TicketSecondaryIcon {...props} />;
      case SVG_ICON_TYPES.CIRCLE_TIMES_ICON:
        return <CircleTimesIcon {...props} />;
      case SVG_ICON_TYPES.STOP_WATCH_ICON:
        return <StopwatchIcon {...props} />;
      case SVG_ICON_TYPES.MAGNIFYING_GLASS_ICON:
        return <MagnifyingGlassIcon {...props} />;
      case SVG_ICON_TYPES.LIFECYCLE_ARTIFACT_ICON:
        return <LifecycleArtifactIcon {...props} />;
      case SVG_ICON_TYPES.LIFECYCLE_CODE_ICON:
        return <LifecycleCodeIcon {...props} />;
      case SVG_ICON_TYPES.LIFECYCLE_RUNTIME_ICON:
        return <LifecycleRuntimeIcon {...props} />;
      case SVG_ICON_TYPES.CODE_FORK_ICON:
        return <CodeForkIcon {...props} />;
      case SVG_ICON_TYPES.USER_ICON:
        return <UserIcon {...props} />;
      case SVG_ICON_TYPES.USER_ADD_ICON:
        return <UserAddIcon {...props} />;
      case SVG_ICON_TYPES.CHART_NETWORK_ICON:
        return <ChartNetworkIcon {...props} />;
      case SVG_ICON_TYPES.DATABASE_ICON:
        return <DatabaseIcon {...props} />;
      case SVG_ICON_TYPES.SATELLITE_DISH_ICON:
        return <SatelliteDishIcon {...props} />;
      case SVG_ICON_TYPES.USER_GROUP_ICON:
        return <UserGroupIcon {...props} />;
      case SVG_ICON_TYPES.PEOPLE_GROUP_ICON:
        return <PeopleGroupIcon {...props} />;
      case SVG_ICON_TYPES.HEXAGON_EXCLAMATION_ICON:
        return <HexagonExclamationIcon {...props} />;
      case SVG_ICON_TYPES.HUG_ICON:
        return <HugIcon {...props} />;
      case SVG_ICON_TYPES.INFO_CIRCLE_ICON:
        return <InfoCircleIcon {...props} />;
      case SVG_ICON_TYPES.CLOSE_ICON:
        return <CloseIcon {...props} />;
      case SVG_ICON_TYPES.ALTERNATIVE_PLUS_ICON:
        return <AlternativePlusIcon {...props} />;
      case SVG_ICON_TYPES.ALTERNATIVE_TRASH_ICON:
        return <AlternativeTrashIcon {...props} />;
      case SVG_ICON_TYPES.CIRCLE_EXCLAMATION_ICON:
        return <CircleExclamationIcon {...props} />;
      case SVG_ICON_TYPES.CIRCLE_EXCLAMATION_BASE_ICON:
        return <CircleExclamationBaseIcon {...props} />;
      case SVG_ICON_TYPES.CROWDSTRIKE_ICON:
        return <CrowdStrikeIcon {...props} />;
      case SVG_ICON_TYPES.CYERA_ICON:
        return <CyeraIcon {...props} />;
      case SVG_ICON_TYPES.ADD_TEAM_ICON:
        return <AddTeamIcon {...props} />;
      case SVG_ICON_TYPES.COPY_ICON:
        return <CopyIcon {...props} />;
      case SVG_ICON_TYPES.COPY_ICON_GRAY:
        return <CopyIconGray {...props} />;
      case SVG_ICON_TYPES.TRASH_ICON_OUTLINED:
        return <TrashIconOutlined {...props} />;
      case SVG_ICON_TYPES.CLOSE:
        return <AlternativeCloseIcon {...props} />;
      case SVG_ICON_TYPES.ARROW_UP:
        return <ArrowUp {...props} />;
      case SVG_ICON_TYPES.ARROW_DOWN:
        return <ArrowDown {...props} />;
      case SVG_ICON_TYPES.RADIO_BUTTON_CHECKED:
        return <RadioButtonChecked {...props} />;
      case SVG_ICON_TYPES.RADIO_BUTTON_UNCHECKED:
        return <RadioButtonUnchecked {...props} />;
      case SVG_ICON_TYPES.RERUN_ICON:
        return <RerunIcon {...props} />;
      case SVG_ICON_TYPES.MESSAGE_ICON:
        return <MessageIcon {...props} />;
      case SVG_ICON_TYPES.CLOUD_ICON:
        return <CloudIcon {...props} />;
      case SVG_ICON_TYPES.SHIELD_VIRUS_ICON:
        return <ShieldVirusIcon {...props} />;
      case SVG_ICON_TYPES.CLIPBOARD_LIST_CHECK_ICON:
        return <ClipboardListCheckIcon {...props} />;
      case SVG_ICON_TYPES.ENVELOPE_ICON:
        return <EnvelopeIcon {...props} />;
      case SVG_ICON_TYPES.CHEVRON_RIGHT_ICON:
        return <ChevronRightIcon {...props} />;
      case SVG_ICON_TYPES.ARROW_LEFT_ICON:
        return <ArrowLeftIcon {...props} />;
      case SVG_ICON_TYPES.LIST_CHECK_ICON:
        return <ListCheckIcon {...props} />;
      case SVG_ICON_TYPES.BADGE_CHECK_ICON:
        return <BadgeCheckIcon {...props} />;
      case SVG_ICON_TYPES.ELLIPSIS_STROKE_ICON:
        return <EllipsisStrokeIcon {...props} />;
      case SVG_ICON_TYPES.SECURITY_HUB_ICON:
        return <SecurityHubIcon {...props} />;
      case SVG_ICON_TYPES.BOOK_SPARKLES_ICON:
        return <BookSparklesIcon {...props} />;
      case SVG_ICON_TYPES.CLIPBOARD_COPY_ICON:
        return <ClipboardCopyIcon {...props} />;
      case SVG_ICON_TYPES.JIRA_ICON:
        return <JiraIcon {...props} />;
      case SVG_ICON_TYPES.GITHUB_ICON:
        return <GithubIcon {...props} />;
      case SVG_ICON_TYPES.ORCA_ICON:
        return <OrcaIcon {...props} />;
      case SVG_ICON_TYPES.WIZ_ICON:
        return <WizIcon {...props} />;
      case SVG_ICON_TYPES.SHIELD_KEYHOLE_ICON:
        return <ShieldKeyholeIcon {...props} />;
      case SVG_ICON_TYPES.EDIT_ICON:
        return <EditIcon {...props} />;
      case SVG_ICON_TYPES.STAR_ICON:
        return <StarIcon {...props} />;
      case SVG_ICON_TYPES.DEPLOY_ICON:
        return <DeployIcon {...props} />;
      case SVG_ICON_TYPES.PLUG_ICON:
        return <PlugIcon {...props} />;
      case SVG_ICON_TYPES.BRIEFCASE_ICON:
        return <BriefcaseIcon {...props} />;
      case SVG_ICON_TYPES.CROSSHAIRS_ICON:
        return <CrosshairsIcons {...props} />;
      case SVG_ICON_TYPES.SQUARE_SMALL_ICON:
        return <SquareSmallIcon {...props} />;
      case SVG_ICON_TYPES.SLIDERS_ICON:
        return <SlidersIcon {...props} />;
      case SVG_ICON_TYPES.MICROSOFT_TEAMS_ICON:
        return <MicrosoftTeamsIcon {...props} />;
      case SVG_ICON_TYPES.SITEMAP_ICON:
        return <SitemapIcon {...props} />;
      case SVG_ICON_TYPES.SITEMAP_BLACK_ICON:
        return <SitemapBlackIcon {...props} />;
      case SVG_ICON_TYPES.LIGHT_EMERGENCY_ICON:
        return <LightEmergencyIcon {...props} />;
      case SVG_ICON_TYPES.CIRCLE_INFO_ICON:
        return <CircleInfoIcon {...props} />;
      case SVG_ICON_TYPES.RADIO_BUTTON_CHECKED_ICON:
        return <RadioButtonCheckedIcon {...props} />;
      case SVG_ICON_TYPES.RADIO_BUTTON_UNCHECKED_ICON:
        return <RadioButtonUncheckedIcon {...props} />;
      case SVG_ICON_TYPES.HOURGLASS_ICON:
        return <HourGlassIcon {...props} />;
      case SVG_ICON_TYPES.LIGHTBULB_ICON:
        return <LightBulbIcon {...props} />;
      case SVG_ICON_TYPES.RADAR_ICON:
        return <RadarIcon {...props} />;
      case SVG_ICON_TYPES.RADAR_GRAY_ICON:
        return <RadarGrayIcon {...props} />;
      case SVG_ICON_TYPES.LINK_SLASH_ICON:
        return <LinkSlashIcon {...props} />;
      case SVG_ICON_TYPES.TAGS_ICON:
        return <TagsIcon {...props} />;
      case SVG_ICON_TYPES.CHART_PIE_ICON:
        return <ChartPieIcon {...props} />;
      case SVG_ICON_TYPES.LOCK_OPEN_ICON:
        return <LockOpenIcon {...props} />;
      case SVG_ICON_TYPES.SCREWDRIVER_WRENCH_ICON:
        return <ScrewdriverWrenchIcon {...props} />;
      case SVG_ICON_TYPES.SIREN_ON_ICON:
        return <SirenOnIcon {...props} />;
      case SVG_ICON_TYPES.TELESCOPE_ICON:
        return <TelescopeIcon {...props} />;
      case SVG_ICON_TYPES.CHART_SIMPLE_ICON:
        return <ChartSimpleIcon {...props} />;
      case SVG_ICON_TYPES.BULLHORN_ICON:
        return <BullhornIcon {...props} />;
      case SVG_ICON_TYPES.TELESCOPE_ICON:
        return <TelescopeIcon {...props} />;
      case SVG_ICON_TYPES.CHECK_TO_SLOT_ICON:
        return <CheckToSlotIcon {...props} />;
      case SVG_ICON_TYPES.FILE_EXCLAMATION_ICON:
        return <FileExclamationIcon {...props} />;
      case SVG_ICON_TYPES.BALLOT_CHECK_ICON:
        return <BallotCheckIcon {...props} />;
      case SVG_ICON_TYPES.CLIPBOARD_CHECK_ICON:
        return <ClipboardCheckIcon {...props} />;
      case SVG_ICON_TYPES.PERCENTAGE_ICON:
        return <PercentageIcon {...props} />;
      case SVG_ICON_TYPES.POSTAL_ICON:
        return <PostalIcon {...props} />;
      case SVG_ICON_TYPES.BUG_ICON:
        return <BugIcon {...props} />;
      case SVG_ICON_TYPES.CUBES_ICON:
        return <CubesIcon {...props} />;
      case SVG_ICON_TYPES.FINGERPRINT_ICON:
        return <FingerprintIcon {...props} />;
      case SVG_ICON_TYPES.GRADUATION_CAP_ICON:
        return <GraduationCapIcon {...props} />;
      case SVG_ICON_TYPES.SENSOR_ICON:
        return <SensorIcon {...props} />;
      case SVG_ICON_TYPES.BOX_ICON:
        return <BoxIcon {...props} />;
      case SVG_ICON_TYPES.SQUARE_CODE_ICON:
        return <SquareCodeIcon {...props} />;
      case SVG_ICON_TYPES.FILE_CODE_ICON:
        return <FileCodeIcon {...props} />;
      case SVG_ICON_TYPES.USER_PEN_ICON:
        return <UserPenIcon {...props} />;
      case SVG_ICON_TYPES.VISIBILITY_ICON:
        return <VisibilityIcon {...props} />;
      case SVG_ICON_TYPES.MULTI_TICKET_ICON:
        return <MultiTicketIcon {...props} />;
      case SVG_ICON_TYPES.FEATHER_ICON:
        return <FeatherIcon {...props} />;
      case SVG_ICON_TYPES.CIRCLE_CHECK_ICON:
        return <CirlceCheckIcon {...props} />;
      case SVG_ICON_TYPES.CALENDAR_CLOCK_ICON:
        return <CalendarClockIcon {...props} />;
      case SVG_ICON_TYPES.HOURGLASS_CLOCK_ICON:
        return <HourglassClockIcon {...props} />;
      case SVG_ICON_TYPES.GAUGE_CIRCLE_BOLT_ICON:
        return <GaugeCircleBoltIcon {...props} />;
      case SVG_ICON_TYPES.ARROW_UP_RIGHT_FROM_SQUARE_ICON:
        return <ArrowUpRightFromSquareIcon {...props} />;
      case SVG_ICON_TYPES.EYES_ICON:
        return <EyesIcon {...props} />;
      case SVG_ICON_TYPES.SQUARE_INFO_ICON:
        return <SquareInfoIcon {...props} />;
      case SVG_ICON_TYPES.CODE_ICON:
        return <CodeIcon {...props} />;
      case SVG_ICON_TYPES.SQUARE_TERMINAL_ICON:
        return <SquareTerminalIcon {...props} />;
      case SVG_ICON_TYPES.OCTAGON_CHECK_ICON:
        return <OctagonCheckIcon {...props} />;
      case SVG_ICON_TYPES.CURLY_BRACKETS_ICON:
        return <CurlyBracketsIcon {...props} />;
      case SVG_ICON_TYPES.CARET_BOLD_RIGHT_ICON:
        return <CaretBoldRightIcon />;
      case SVG_ICON_TYPES.CARET_BOLD_DOWN_ICON:
        return <CaretBoldDownIcon />;
      case SVG_ICON_TYPES.STAR_CHRISTMAS_ICON:
        return <StarChristmasIcon {...props} />;
      case SVG_ICON_TYPES.CUBE_ICON:
        return <CubeIcon {...props} />;
      case SVG_ICON_TYPES.SERVER_ICON:
        return <ServerIcon {...props} />;
      case SVG_ICON_TYPES.BOLT_LIGHTNING_ICON:
        return <BoltLightningIcon {...props} />;
      case SVG_ICON_TYPES.SQUARE_DASHED_CIRCLE_PLUS_ICON:
        return <SquareDashedCirclePlusIcon {...props} />;
      case SVG_ICON_TYPES.FLOPPY_DISK_ICON:
        return <FloppyDiskIcon {...props} />;
      case SVG_ICON_TYPES.NETWORK_WIRED_ICON:
        return <NetworkWiredIcon {...props} />;
      case SVG_ICON_TYPES.SENSOR_CLOUD_ICON:
        return <SensorCloudIcon {...props} />;
      case SVG_ICON_TYPES.CIRCLE_DOT_ICON:
        return <CircleDotIcon {...props} />;
      case SVG_ICON_TYPES.CIRCLE_ICON:
        return <CircleIcon {...props} />;
      case SVG_ICON_TYPES.PLAY_ICON:
        return <PlayIcon {...props} />;
      case SVG_ICON_TYPES.FILE_CSV_ICON:
        return <FileCsvIcon {...props} />;
      case SVG_ICON_TYPES.FILE_IMPORT_ICON:
        return <FileImportIcon {...props} />;
      case SVG_ICON_TYPES.UPLOAD_ICON:
        return <UploadIcon {...props} />;
      case SVG_ICON_TYPES.RIBBON_ICON:
        return <RibbonIcon {...props} />;
      case SVG_ICON_TYPES.ARROW_TREND_UP:
        return <ArrowTrendUpIcon {...props} />;
      case SVG_ICON_TYPES.SHIELD_EXLAMATION_ICON:
        return <ShieldExclamationICon {...props} />;
      case SVG_ICON_TYPES.SHIELD_EXLAMATION_ICON:
        return <ShieldExclamationICon {...props} />;
      case SVG_ICON_TYPES.MESSAGES_ICON:
        return <MessagesIcon {...props} />;
      case SVG_ICON_TYPES.LAPTOP_CODE_ICON:
        return <LaptopCodeIcon {...props} />;
      case SVG_ICON_TYPES.UNLOCK_ICON:
        return <UnlockIcon {...props} />;
      case SVG_ICON_TYPES.BIOHAZARD_ICON:
        return <BiohazardIcon {...props} />;
      case SVG_ICON_TYPES.SKULL_ICON:
        return <SkullIcon {...props} />;
      case SVG_ICON_TYPES.HOOD_CLOAK_ICON:
        return <HoodCloakIcon {...props} />;
      case SVG_ICON_TYPES.SACK_DOLLAR_ICON:
        return <SackDollarIcon {...props} />;
      case SVG_ICON_TYPES.WRENCH_ICON:
        return <WrenchIcon {...props} />;
      case SVG_ICON_TYPES.ELLIPSE_ICON:
        return <EllipseIcon {...props} />;
      case SVG_ICON_TYPES.CLIPBOARD_LIST_ICON:
        return <ClipboardListIcon {...props} />;
      case SVG_ICON_TYPES.FILE_ICON:
        return <FileIcon {...props} />;
      case SVG_ICON_TYPES.BOMB_ICON:
        return <BombIcon {...props} />;
      case SVG_ICON_TYPES.BROKEN_CHAIN_ICON:
        return <BrokenChainIcon {...props} />;
      case SVG_ICON_TYPES.MASK_ICON:
        return <MaskIcon {...props} />;
      case SVG_ICON_TYPES.INTELLIGENCE_ICON:
        return <IntelligenceIcon {...props} />;
      case SVG_ICON_TYPES.BUILDINGS_ICON:
        return <BuildingsIcon {...props} />;
      case SVG_ICON_TYPES.CISA_ICON:
        return <CisaIcon {...props} />;
      case SVG_ICON_TYPES.GLOBE_ICON:
        return <GlobeIcon {...props} />;
      case SVG_ICON_TYPES.MONITOR_WAVEFORM_ICON:
        return <MonitorWaveformIcon {...props} />;
      case SVG_ICON_TYPES.CROWN_ICON:
        return <CrownIcon {...props} />;
      case SVG_ICON_TYPES.XMARK_ICON:
        return <XmarkIcon {...props} />;
      case SVG_ICON_TYPES.PUZZLE_PIECE_ICON:
        return <PuzzlePieceIcon {...props} />;
      case SVG_ICON_TYPES.ROBOT_ICON:
        return <RobotIcon {...props} />;
      case SVG_ICON_TYPES.WAVE_PULSE_ICON:
        return <WavePulseIcon {...props} />;
      case SVG_ICON_TYPES.POWER_OFF_ICON:
        return <PowerOffIcon {...props} />;
      case SVG_ICON_TYPES.DIAGRAM_PROJECT_ICON:
        return <DiagramProjectIcon {...props} />;
      case SVG_ICON_TYPES.OCTAGON_EXCLAMATION_ICON:
        return <OctagonExclamationIcon {...props} />;
      default:
        return <></>;
    }
  }

  getPngIcon(props: OpusPngIconProps) {
    return <CommonIconMui {...props} />;
  }

  getAvatarIcon(props: OpusAvatarIconProps) {
    const { children, avatarClassName } = props;
    return children ? (
      <Avatar
        className={avatarClassName}
        {..._.omit(props, 'children', 'avatarClassName')}
      >
        {children}
      </Avatar>
    ) : (
      <Avatar
        className={avatarClassName}
        {..._.omit(props, 'avatarClassName')}
      />
    );
  }

  getImageIcon(props: OpusImageIconProps) {
    const { imageClassName } = props;
    return (
      <img {..._.omit(props, 'imageClassName')} className={imageClassName} />
    );
  }
}

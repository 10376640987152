import {
  GridRowSelectionProps,
  GridSelectionProps,
  GridSelectionType,
  GridType,
} from 'Risk/store';

export interface GridSelectionBffDto {
  gridType: GridType;
  selectedFindingIds: Array<string>;
  unselectedFindingIds: Array<string>;
  selectedFindingTypes: Array<string>;
  indeterminateFindingTypes: Array<string>;
}

export class GridSelectionDtoHandler {
  transformGridSelectionPropsToBffDto(
    gridType: GridType,
    gridSelectionProps: GridSelectionProps,
    actionType?: 'item' | 'campaign'
  ): GridSelectionBffDto {
    const initialGridSelectionBffDto: GridSelectionBffDto = {
      gridType,
      indeterminateFindingTypes: [],
      selectedFindingIds: [],
      selectedFindingTypes: [],
      unselectedFindingIds: [],
    };

    if (gridType === GridType.None) {
      initialGridSelectionBffDto.selectedFindingIds =
        gridSelectionProps.selectedChildren as Array<string>;
    }

    if (gridType === GridType.RootCause) {
      let updatedSelections: Array<string> = [];

      if (actionType === 'campaign') {
        updatedSelections = (
          gridSelectionProps.selectedChildren as Array<string>
        )
          .map((selection: any) => selection.split(','))
          .flat();
      } else {
        updatedSelections = (
          gridSelectionProps.selectedChildren as Array<string>
        ).map((selection: any) => selection.split(',')[0]);
      }

      initialGridSelectionBffDto.selectedFindingIds = updatedSelections;
    }

    if (
      gridType === GridType.Risk ||
      gridType === GridType.ResourceName ||
      GridType.CodeOrigin
    ) {
      initialGridSelectionBffDto.selectedFindingTypes =
        gridSelectionProps.selectedChildren.filter((selectedChild) => {
          const existingChildEntry = gridSelectionProps.rows?.find(
            (rowItem: GridRowSelectionProps) => rowItem.value === selectedChild
          );

          if (!existingChildEntry) {
            return true;
          }

          if (existingChildEntry?.unselectedChildren?.length === 0) {
            return true;
          } else {
            if (existingChildEntry?.selection === GridSelectionType.MANUAL) {
              initialGridSelectionBffDto.selectedFindingIds =
                initialGridSelectionBffDto.selectedFindingIds.concat(
                  existingChildEntry.availableChildren.filter(
                    (child) =>
                      !existingChildEntry.unselectedChildren.includes(child)
                  ) as Array<string>
                );
            } else {
              initialGridSelectionBffDto.indeterminateFindingTypes.push(
                selectedChild as string
              );
              initialGridSelectionBffDto.unselectedFindingIds =
                initialGridSelectionBffDto.unselectedFindingIds.concat(
                  existingChildEntry.unselectedChildren as Array<string>
                );
            }
          }

          return false;
        }) as Array<string>;
    }

    return initialGridSelectionBffDto;
  }
}

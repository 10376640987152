import { FunctionComponent, useEffect } from 'react';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';
import { useSearchConnectionTemplatesMutation } from 'ConnectionTemplate/store/api';
import { CloudProviderType } from 'shared/models/data/integrations.model';
import { useGetCloudConnectionTemplatesMutation } from 'Integrations/Connection/store/api';
import { CommonIntegrationModalProps } from 'Integrations/Connection/components/IntegrationModal/IntegrationModal';
import { AwsIntegrationModalEditForm } from 'Integrations/Connection/components/IntegrationModal/components/AwsIntegrationModalEditForm/AwsIntegrationModalEditForm';
import { AwsIntegrationModalCreateForm } from 'Integrations/Connection/components/IntegrationModal/components/AwsIntegrationModalCreateForm/AwsIntegrationModalCreateForm';
import { IntegrationItem } from 'Integrations/interfaces/IntegrationItem.model';

interface OnboardingAwsFormProps
  extends BaseComponentProps,
    CommonIntegrationModalProps {}

export const OnboardingAwsForm: FunctionComponent<OnboardingAwsFormProps> = ({
  open,
  connectionId,
  onCreateSuccess,
  ...otherProps
}) => {
  const [
    searchConnectionTemplates,
    { data: connectionTemplateData, isLoading: connectionTemplateDataLoading },
  ] = useGetCloudConnectionTemplatesMutation();

  useEffect(() => {
    if (open) searchConnectionTemplates(CloudProviderType.AWS);
  }, [open]);

  const handleAfterCreateConnection = (connectionId: string): void => {
    if (onCreateSuccess) onCreateSuccess(connectionId);
  };

  const renderModalForm = () => {
    if (connectionId) {
      return (
        <AwsIntegrationModalEditForm
          onCreateSuccess={handleAfterCreateConnection}
          {...{
            open,
            connectionId,
            connectionTemplateData,
            connectionTemplateDataLoading,
            ...otherProps,
          }}
          rootClassName="onboarding-cloud-integration-form"
        />
      );
    }

    return (
      <AwsIntegrationModalCreateForm
        onCreateSuccess={handleAfterCreateConnection}
        {...{
          open,
          connectionTemplateData,
          connectionTemplateDataLoading,
          ...otherProps,
        }}
        rootClassName="onboarding-cloud-integration-form"
      />
    );
  };

  return renderModalForm();
};

import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useCommonDispatch from 'Common/utils/use-dispatch';
import useCommonSelector from 'Common/utils/use-selector';
import CardWrapper from 'Common/components/CardWrapper';
import { AutocompleteOption } from 'FindingDetails/store/api';
import Autocomplete from 'Common/components/Autocomplete';
import {
  getFilterList,
  selectComparativeFilters,
  setComparativeFilters,
  setSelectedFindingTypes,
} from 'Dashboard/Comparative/store';
import {
  ComparativeFilterKey,
  ComparativeFindingFilter,
  comparativeFindingsFilterOptions,
} from 'Dashboard/Comparative/interfaces/fixtures/data/comparative-dashboard-filter-option.data';
import { presentationalConfigurationForWidgets } from 'Common/components/Widgets/fixtures/widget-data';
import VerticalStackedBarChart from 'shared/components/VerticalStackedBarChart';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import {
  AgGridEvent,
  GetRowIdParams,
  RowClickedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import CommonPagination from 'Common/components/Pagination';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import {
  useFetchComparativeDashboardWidgetDataMutation,
  useGetUserAvailableNodesOfTypeMutation,
} from 'Dashboard/store/api';
import { ComparativeFilterType } from 'Dashboard/Comparative/interfaces/Comparative';
import { ChartDataItem } from 'shared/models/data/chart.model';
import { OrderState, OrderType } from '../../../../Common/utils/sort';
import { MenuItems } from 'DashboardFilter/store';
import { DashboardFilterType } from 'Dashboard/interfaces/DashboardFilterType.enum';
import DashboardFilterHandler from 'shared/handlers/dashboard-filter.handler';
import { NavigationHandler } from 'shared/handlers/navigation.handler';
import { GridType } from 'Risk/store';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { OrganizationNode } from 'Organization/interfaces/OrganizationNode.interface';
import { FilterOption } from 'shared/components/SearchFilter/LegacyAdvanceFilter';
import { FindingState } from 'shared/fixtures/data/risk-grid.data';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

export interface AgGridRowData {
  id: string;
  [key: string]: string | number;
}

interface ComparativeContentProps extends BaseComponentProps {
  disableTable?: boolean;
  title?: string;
  disableComparativeData?: boolean;
  externalWidgetData?: any;
  isExternalWidgetDataLoading?: boolean;
  onPropertyChange?: (propertyOption: AutocompleteOption) => void;
  selectedProperty?: AutocompleteOption;
  categoryOptions?: Array<AutocompleteOption>;
}

const navigationHandler = new NavigationHandler();

export const ComparativeContent: FunctionComponent<ComparativeContentProps> = ({
  disableTable,
  title,
  externalWidgetData,
  isExternalWidgetDataLoading,
  onPropertyChange,
  disableComparativeData,
  categoryOptions,
  selectedProperty,
}) => {
  const comparativeFilter: ComparativeFilterType = useCommonSelector(
    selectComparativeFilters
  );

  const dispatch = useCommonDispatch();
  const [fetchWidgetData, { data: apiWidgetData, isLoading }] =
    useFetchComparativeDashboardWidgetDataMutation();
  const [widgetData, setWidgetData] = useState<any>();
  const [rowData, setRowData] = useState<any[]>([]);
  const [columnData, setColumnData] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [totalPaginatedItems, setTotalPaginatedItems] = useState<number>();
  const [totalCurrentPage, setTotalCurrentPage] = useState<number>();
  const [totalItems, setTotalItems] = useState<number>(0);
  const [paginatedRows, setPaginatedRows] = useState<any[]>([]);
  const [paginatedWidgetData, setPaginatedWidgetData] = useState<any>();

  const [
    getUserAvailableNodesOfType,
    { data: nodeData, isLoading: nodeDataLoading },
  ] = useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    const nodeType = getOrganizationNodeType();

    getUserAvailableNodesOfType({
      nodesType: nodeType,
    });
  }, [comparativeFilter.selectedFilter]);

  const allDataMenu: MenuItems = useCommonSelector(getFilterList);

  const gridRef = useRef<AgGridEvent | null>(null);
  const defaultSort: OrderState = {
    field: 'total',
    type: OrderType.DESC,
  };

  const selectFindingFilter = (
    findingFilter: keyof typeof ComparativeFindingFilter
  ) => {
    dispatch(
      setComparativeFilters({
        ...comparativeFilter,
        selectedFindingsFilter: findingFilter,
      })
    );
  };

  const resetColumnState = () => {
    if (gridRef.current?.columnApi) {
      gridRef.current.columnApi.resetColumnState();
      gridRef.current.columnApi.applyColumnState({
        state: [
          {
            colId: defaultSort.field,
            sort: defaultSort.type.toLocaleLowerCase() as 'desc' | 'asc',
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (externalWidgetData) {
      resetColumnState();
      setPage(1);
      setWidgetData(externalWidgetData);
    }
  }, [externalWidgetData]);

  useEffect(() => {
    if (!disableComparativeData)
      fetchWidgetData({
        scopeNodeId: comparativeFilter.selectedScope[0],
        groupNodeId: comparativeFilter.selectedGroup[0],
        filterEntity: comparativeFilter.selectedFilter,
        type: comparativeFilter.selectedFindingsFilter,
        findingTypes: comparativeFilter.findingTypes,
      });
  }, [
    comparativeFilter.selectedFilter,
    comparativeFilter.selectedFindingsFilter,
    comparativeFilter.selectedGroup[0],
    comparativeFilter.selectedScope[0],
    comparativeFilter.findingTypes?.join(),
    disableComparativeData,
  ]);

  useEffect(() => {
    if (apiWidgetData) {
      resetColumnState();
      setPage(1);
      setWidgetData(apiWidgetData);
    }
  }, [apiWidgetData]);

  const getStyle = () => {
    const filter =
      selectedProperty?.value || comparativeFilter.selectedFindingsFilter;
    return presentationalConfigurationForWidgets[`comparative_${filter}`]
      ?.style;
  };

  const getExtraFields = (rowData: any): object => {
    switch (comparativeFilter.selectedFindingsFilter) {
      case 'accumulative_open_findings_by_sla_status':
        let sla_attain_rate_pct = 0;
        if (rowData['outside_sla'] && rowData['total'] > 0) {
          const within_sla = rowData['total'] - (rowData['outside_sla'] || 0);
          sla_attain_rate_pct = (within_sla / rowData['total']) * 100;
        }
        return { sla_attain_rate_pct: `${sla_attain_rate_pct.toFixed(1)}%` };
      default:
        return {};
    }
  };

  const getOrganizationNodeType = () => {
    switch (comparativeFilter.selectedFilter) {
      case ComparativeFilterKey.GROUP:
        return OrganizationNodeType.LOGICAL;
      case ComparativeFilterKey.SERVICE:
        return OrganizationNodeType.DATA;
      case ComparativeFilterKey.SCOPE:
        return OrganizationNodeType.SCOPE;
    }
  };

  const getDataKeyBasedOnFilterType = () => {
    switch (comparativeFilter.selectedFilter) {
      case ComparativeFilterKey.GROUP:
        return 'groups';
      case ComparativeFilterKey.SERVICE:
        return 'services';
      case ComparativeFilterKey.SCOPE:
        return 'groups';
    }
  };

  const getFilterKeyBasedOnFilterType = () => {
    switch (comparativeFilter.selectedFilter) {
      case ComparativeFilterKey.GROUP:
        return 'groupId';
      case ComparativeFilterKey.SERVICE:
        return 'businessUnitId';
      case ComparativeFilterKey.SCOPE:
        return 'scopeId';
    }
  };

  const getRowData = (data: any): AgGridRowData[] => {
    const agGridRowData: AgGridRowData[] = data.labels.map(
      (label: string, rowIndex: number) => {
        let rowData: AgGridRowData = {
          id: uuidv4(),
          [comparativeFilter.selectedFilter === 'service'
            ? 'services'
            : 'groups']: label,
        };
        let rowSum = 0;
        data.datasets.forEach((dataset: any) => {
          if (dataset.label === 'No SLA') return;
          const columnName = dataset.label.toLowerCase().replace(' ', '_');
          rowData[columnName] = dataset.data[rowIndex];
          rowSum += dataset.data[rowIndex];
        });

        rowData['total'] = rowSum;
        rowData = { ...rowData, ...getExtraFields(rowData) };
        return rowData;
      }
    );

    return agGridRowData;
  };

  const getWidgetData = (data: Array<AgGridRowData>) => {
    const labelField =
      comparativeFilter.selectedFilter === 'service' ? 'services' : 'groups';

    const excludedFieldTypes: Array<string> = [
      labelField,
      'id',
      'total',
      'sla_attain_rate_pct',
    ];

    const labels = data.map((rowItem: any) => rowItem[labelField]);

    const datasets = data.reduce(
      (accumulator: Array<ChartDataItem>, currentValue: AgGridRowData) => {
        const egligbleLabels = Object.keys(currentValue).filter(
          (key) => !excludedFieldTypes.includes(key)
        );

        let updatedAccumulator: Array<ChartDataItem> = [];

        for (const eglibleKey of egligbleLabels) {
          const label = apiWidgetData.datasets?.find(
            (dataset: ChartDataItem) => {
              return (
                dataset.label?.toLowerCase().replace(' ', '_') === eglibleKey
              );
            }
          )?.label;

          const chartItem = accumulator.find((item) => item.label === label);

          if (chartItem) {
            const updatedChartItem: ChartDataItem = {
              ...chartItem,
              data: [
                ...(chartItem?.data || []),
                currentValue[eglibleKey] as number,
              ],
            };

            updatedAccumulator.push(updatedChartItem);
          } else {
            const chartItem: ChartDataItem = {
              label,
              data: [currentValue[eglibleKey] as number],
            };

            updatedAccumulator.push(chartItem);
          }
        }

        return updatedAccumulator;
      },
      []
    );

    return {
      labels,
      datasets,
    };
  };

  const displayExtraFields = () => {
    const extraFields = [];
    switch (comparativeFilter.selectedFindingsFilter) {
      case 'accumulative_open_findings_by_sla_status':
        extraFields.push({
          headerName: 'SLA Attain Rate',
          field: 'sla_attain_rate_pct',
          resizable: true,
        });
        break;
    }
    extraFields.push({
      headerName: 'Total',
      field: 'total',
      resizable: true,
    });
    return extraFields;
  };

  const getFirstColumn = () => {
    let fieldName, headerName;
    switch (comparativeFilter.selectedFilter) {
      case 'service':
        headerName = 'Service';
        fieldName = 'services';
        break;
      case 'group':
        headerName = 'Group';
        fieldName = 'groups';
        break;
      case 'scope':
        headerName = 'Scope';
        fieldName = 'groups';
        break;
    }
    return {
      headerName: headerName,
      field: fieldName,
      resizable: true,
      comparator: () => 0,
    };
  };

  useEffect(() => {
    if (!isLoading && (apiWidgetData || externalWidgetData)) {
      const data = externalWidgetData || apiWidgetData;
      const agGridRowData: AgGridRowData[] = getRowData(data);
      const columnDefs = [
        getFirstColumn(),
        ...data.datasets
          .filter((dataset: any) => dataset.label !== 'No SLA')
          .map((dataset: any) => {
            return {
              headerName: dataset.label,
              field: dataset.label.toLowerCase().replace(' ', '_'),
              resizable: true,
              comparator: () => 0,
            };
          }),
        ...displayExtraFields(),
      ];
      setColumnData(columnDefs);
      setRowData(agGridRowData);
      setTotalItems(agGridRowData?.length);
    }
  }, [isLoading, apiWidgetData, externalWidgetData]);

  const onPageChange = (selectedPage: number) => {
    setPage(selectedPage);
  };

  const calculatePaginatedRows = (size: number, numb: number) => {
    const startRowIndex = (numb - 1) * size;
    const endRowIndex = startRowIndex + size;
    const paginatedRows =
      rowData && rowData.length
        ? rowData.slice(startRowIndex, endRowIndex)
        : [];
    const firstItemInPage = (page - 1) * pageSize + 1;
    const lastItemInPageBySize = firstItemInPage + pageSize - 1;
    const lastItemInPage =
      lastItemInPageBySize > totalItems ? totalItems : lastItemInPageBySize;
    setTotalCurrentPage(firstItemInPage);
    setTotalPaginatedItems(lastItemInPage);
    setPaginatedRows(paginatedRows);
  };
  const calculatePaginatedWidgetData = (size: number, numb: number) => {
    const startRowIndex = (numb - 1) * size;
    const endRowIndex = startRowIndex + size;
    const paginatedWidgetData = {
      labels: widgetData.labels.slice(startRowIndex, endRowIndex),
      datasets: widgetData.datasets.map((dataset: any) => ({
        label: dataset.label,
        data: dataset.data.slice(startRowIndex, endRowIndex),
      })),
    };

    setPaginatedWidgetData(paginatedWidgetData);
  };

  useEffect(() => {
    if (widgetData) {
      calculatePaginatedWidgetData(pageSize, page);
    }
  }, [pageSize, page, widgetData]);

  useEffect(() => {
    if (rowData) {
      calculatePaginatedRows(pageSize, page);
    }
  }, [pageSize, page, rowData]);

  useEffect(() => {
    const findingTypeMenu = allDataMenu.find(
      (item) => item.id === DashboardFilterType.FINDING_TYPE
    );
    if (findingTypeMenu) {
      const selectedFindingTypes = DashboardFilterHandler.getFilterValues(
        findingTypeMenu.items || []
      );
      if (
        selectedFindingTypes.length !== 0 ||
        comparativeFilter.findingTypes?.length !== 0
      ) {
        dispatch(setSelectedFindingTypes(selectedFindingTypes));
      }
    }
  }, [allDataMenu]);

  const sortWidgetData = (sortedRowData: Array<any>) => {
    setWidgetData(getWidgetData(sortedRowData));
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setPage(1);
  };

  const getSortItemComparatorByType = (
    itemA: string | number,
    itemB: string | number,
    sortOrder?: 'asc' | 'desc' | null
  ): number => {
    if (typeof itemA === 'string' && typeof itemB === 'string') {
      const itemsPercentageCompare =
        itemA.lastIndexOf('%') === itemA.length - 1 &&
        itemB.lastIndexOf('%') === itemB.length - 1;
      if (itemsPercentageCompare) {
        const itemANum = itemA.slice(0, -1);
        const itemBNum = itemB.slice(0, -1);
        return sortOrder === 'desc'
          ? +itemBNum - +itemANum
          : +itemANum - +itemBNum;
      }
      return sortOrder === 'desc'
        ? itemB.toLowerCase() > itemA.toLowerCase()
          ? -1
          : 1
        : itemA.toLowerCase() > itemB.toLowerCase()
        ? -1
        : 1;
    }

    if (typeof itemA === 'number' && typeof itemB === 'number') {
      return sortOrder === 'desc' ? itemB - itemA : itemA - itemB;
    }

    return 0;
  };

  const sortRowDataByColumnId = (
    columnId: string,
    sortOrder?: 'asc' | 'desc' | null
  ) => {
    const sortedRowData = getRowData(apiWidgetData).sort(
      (rowItemA, rowItemB) => {
        return getSortItemComparatorByType(
          rowItemA[columnId],
          rowItemB[columnId],
          sortOrder
        );
      }
    );

    setRowData(sortedRowData);
    return sortedRowData;
  };

  const handleSorting = (event: SortChangedEvent) => {
    const allColumnState = event.columnApi.getColumnState();
    const sortedColumns = allColumnState.filter((column) => column.sort);
    if (sortedColumns.length > 0) {
      const sortedRowData = sortRowDataByColumnId(
        sortedColumns[0].colId,
        sortedColumns[0].sort
      );

      sortWidgetData(sortedRowData);
    } else {
      setRowData(getRowData(apiWidgetData));
      setWidgetData(apiWidgetData);
    }
  };

  const renderTable = () => {
    if (disableTable) return <></>;

    return (
      <>
        <Box>
          <CommonSimpleDataGrid
            rowData={paginatedRows}
            columnDefs={columnData}
            rowSelection="multiple"
            defaultColDef={{
              resizable: true,
              sortable: true,
            }}
            onGridReady={(event) => event.api.sizeColumnsToFit()}
            onSortChanged={handleSorting}
            sortModel={defaultSort}
            gridRef={gridRef}
            containerClassName="settings-data-grid"
            suppressRowClickSelection
            onRowClicked={(event: RowClickedEvent) => {
              const nodeFilterKey = getFilterKeyBasedOnFilterType() as string;

              const node = nodeData?.find(
                (nodeDataItem: OrganizationNode) =>
                  event.data[getDataKeyBasedOnFilterType() as string] ===
                  nodeDataItem.name
              );

              navigationHandler.handleRiskNavigation(GridType.None, {
                state: {
                  id: FindingState.ACTIVE,
                  name: FindingState.ACTIVE,
                },
                [nodeFilterKey]:
                  nodeFilterKey === 'businessUnitId'
                    ? ([node] as Array<FilterOption>)
                    : (node as FilterOption),
              });
            }}
            visibilityControlProps={{
              enableVisibilityControls: false,
              columns: [],
            }}
            isLoading={isLoading}
            keepCurrentSelections
            otherComponents={{
              NoDataBackdropComponent: <NoDataBackdrop />,
            }}
            loadingOverlayComponent={() => <CircularProgress />}
            getRowId={(row: GetRowIdParams) => {
              return row.data.id;
            }}
            domLayout="autoHeight"
          />
        </Box>
        <Box mt={2} className="settings-pagination" mx={2}>
          <CommonPagination
            disableChangePageSize
            totalItems={totalItems || 0}
            pageSize={pageSize}
            currentPage={page}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
          />
        </Box>
      </>
    );
  };

  const renderTitle = () => {
    if (title) return title;

    return `Open Findings ${`(${totalCurrentPage}-${totalPaginatedItems}/${totalItems})`}`;
  };

  return (
    <CardWrapper>
      <div className="comparative-dashboard-section-container">
        <div className="comparative-dashboard-section-header">
          <Typography className="header-6" variant="inherit">
            {renderTitle()}
          </Typography>
          <Autocomplete
            model=""
            onChangeCallBack={(model, option) => {
              if (onPropertyChange) {
                onPropertyChange(option as AutocompleteOption);
              } else {
                selectFindingFilter(
                  (option as AutocompleteOption)
                    .value as keyof typeof ComparativeFindingFilter
                );
              }
            }}
            classes={{
              root: 'multi-select-field-1 ',
              paper: 'multi-select-field-paper-1',
              inputRoot:
                'comparative-dashboard-section-timeline-filter-input-root',
            }}
            optionList={categoryOptions || comparativeFindingsFilterOptions}
            single
            enableCheckbox
            placeholder="Select Timeline"
            initialSelectedValues={
              selectedProperty || {
                value: comparativeFilter.selectedFindingsFilter,
                label:
                  ComparativeFindingFilter[
                    comparativeFilter.selectedFindingsFilter
                  ],
              }
            }
          />
        </div>
        <Box className="open-findings-widget">
          <VerticalStackedBarChart
            fetchWidgetData={() => {}}
            isWidgetDataLoading={
              isLoading || Boolean(isExternalWidgetDataLoading)
            }
            widgetData={paginatedWidgetData}
            style={getStyle()}
            enableTooltip={false}
            displayExternalTooltip
            displayLegend
            legendPosition="bottom"
            isResponsive
            aspectRatio={2.5}
            options={{
              maintainAspectRatio: false,
            }}
            customLegendContainerId={'comparative-widget-legend-container'}
          />
        </Box>
        <Divider className="widget-divider" />
        <Box mt={2} className="settings-pagination" mx={2}>
          <CommonPagination
            disableChangePageSize
            totalItems={totalItems || 0}
            pageSize={pageSize}
            currentPage={page}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            classes={{
              root: 'advanced-data-grid-pagination-root',
              pages: 'advanced-data-grid-pagination-pages',
              results: 'advanced-data-grid-pagination-results',
              rowsPerPageSelect:
                'advanced-data-grid-pagination-rows-per-page-select',
              rowsPerPageSelectItem:
                'advanced-data-grid-pagination-rows-per-page-select-item',
              rowsPerPageText:
                'advanced-data-grid-pagination-rows-per-page-text',
            }}
            icons={{
              ExpandIcon: (
                <OpusSvgIcon type={SVG_ICON_TYPES.EXPAND_MORE_ICON} />
              ),
            }}
          />
        </Box>
        {renderTable()}
      </div>
    </CardWrapper>
  );
};

import { CircularProgress } from '@mui/material';
import {
  AgGridEvent,
  GetRowIdParams,
  RowClickedEvent,
} from 'ag-grid-community';
import { OrderState, OrderType } from 'Common/utils/sort';
import { useFetchFindingsWithResourceMutation } from 'FindingDetails/store/api';
import { FunctionComponent, useRef, useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CommonSimpleDataGrid from 'shared/components/CommonSimpleDataGrid';
import ContentSection from 'shared/components/ContentSection';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import NoDataBackdrop from 'shared/components/NoDataBackdrop';
import { relatedFindingsTableColumns } from 'shared/fixtures/data/related-findings-c2c-grid.data';
import { FilterSearchParamsHandler } from 'shared/handlers/filter-search-params.handler';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { SVG_ICON_TYPES } from 'shared/icons/enums';
import { BaseComponentProps } from 'shared/models/props/base-component-props.model';

interface Cloud2CodeRelatedFindingsProps extends BaseComponentProps {
  onFindingClick: (event: RowClickedEvent) => void;
}

const riskSearchParamsHandler = new FilterSearchParamsHandler();

export const Cloud2CodeRelatedFindings: FunctionComponent<
  Cloud2CodeRelatedFindingsProps
> = ({ onFindingClick }) => {
  const gridRef = useRef<AgGridEvent | null>(null);
  const { t: translation } = useTranslation();

  const [urlSearchParams, setUrlSearchParams] = useQueryParams();
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [orderParams, setOrderParams] = useState<OrderState | null>({
    field: 'riskScore',
    type: OrderType.DESC,
  });

  const onHandleColumnOrderChange = (columnOrderPayload: Array<OrderState>) => {
    if (columnOrderPayload.length) {
      const newOrderParams = {
        field: columnOrderPayload[0].field,
        type: columnOrderPayload[0].type,
      };

      setOrderParams(newOrderParams);
    } else {
      setOrderParams(null);
    }
  };

  useEffect(() => {
    fetchFindingsWithResource({
      filter: {
        rootCauseId: [
          {
            value: urlSearchParams.openCodeEventId,
            label: urlSearchParams.openCodeEventId,
          },
        ],
      },
      order: orderParams as OrderState,
      take: pageSize,
      skip: currentPage && pageSize ? (currentPage - 1) * pageSize : 0,
    });
  }, [currentPage, pageSize, orderParams]);

  const [fetchFindingsWithResource, { isLoading, data: findingsWithResource }] =
    useFetchFindingsWithResourceMutation();

  const onPageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage);
  };

  const onPageSizeChange = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
  };

  return (
    <div className="finding-content-item ">
      <ContentSection
        iconClassName="finding-content-section-icon"
        rootClassName={`finding-content-section finding-content-table-body ${
          isLoading ? 'finding-content-table-body-loading' : ''
        }`}
        icon={<OpusSvgIcon type={SVG_ICON_TYPES.RADAR_ICON} />}
        title={translation(`assets.details.iacRelatedFindings`)}
      >
        <CommonSimpleDataGrid
          rowData={findingsWithResource?.data}
          columnDefs={relatedFindingsTableColumns}
          defaultColDef={{
            resizable: true,
            sortable: true,
          }}
          domLayout="autoHeight"
          suppressRowClickSelection
          visibilityControlProps={{
            enableVisibilityControls: false,
            columns: [],
          }}
          paginationProps={{
            pageSize: pageSize,
            currentPage: currentPage,
            onPageChange,
            onPageSizeChange,
            totalItems: findingsWithResource?.totalItems || 0,
          }}
          isLoading={isLoading}
          onRowClicked={(event: RowClickedEvent) => {
            onFindingClick(event);
          }}
          onGridReady={(event) => event.api.sizeColumnsToFit()}
          onSort={onHandleColumnOrderChange}
          selectionModel={[] as Array<string>}
          sortModel={orderParams as OrderState}
          keepCurrentSelections
          otherComponents={{
            NoDataBackdropComponent: <NoDataBackdrop />,
          }}
          loadingOverlayComponent={() => (
            <div className="finding-content-table-body-loading-wrapper">
              <CircularProgress />
            </div>
          )}
          getRowId={(row: GetRowIdParams) => {
            return row.data.findingId;
          }}
          gridRef={gridRef}
          isRowNavigable
        />
      </ContentSection>
    </div>
  );
};

import React from 'react';
import OpusSvgIcon from 'shared/components/IconComponents/OpusSvgIcon';
import { SVG_ICON_TYPES } from 'shared/icons/enums';

export interface FindingsTagWithIconProps {
  label?: string;
  icon?: SVG_ICON_TYPES;
  value?: string | number;
}

const FindingsTagWithIcon: React.FC<FindingsTagWithIconProps> = ({
  label,
  icon,
  value,
}) => {
  return (
    <div className="common-tag-component">
      {icon && (
        <div className="icon">
          <OpusSvgIcon type={icon} />
        </div>
      )}
      {label && (
        <div className="label">
          <p className="body-1">{label}</p>
        </div>
      )}
      {Number.isNaN(value) ? (
        <></>
      ) : (
        <div className="value">
          <p className="label-4">{value}</p>
        </div>
      )}
    </div>
  );
};

export default FindingsTagWithIcon;

import _ from 'lodash';
import { useLazyFetchConnectionByFiltersQuery } from 'WorkflowBuilder/store/api';
import {
  AutocompleteOption,
  useFetchAvailableUsersToAssignMutation,
} from 'FindingDetails/store/api';
import ConnectionItem from 'Integrations/Connection/interfaces/item';
import { CloudWorkspaceApiType } from 'Settings/interfaces/CloudWorkspaceType.enum';
import {
  useFetchCloudWorkspacesMutation,
  useFetchTeamsMutation,
  useSearchCloudWorkspacesMutation,
} from 'Settings/store/api';
import { useEffect, useMemo } from 'react';
import {
  businessUnitEnvironmentTypesOptionList,
  businessUnitImpactOptionList,
  businessUnitRoleOptionList,
} from 'shared/fixtures/data/business-unit.data';
import { BusinessUnitDataHandler } from 'shared/handlers/business-unit-data.handler';
import { CommunicationApp } from 'Settings/components/TeamsList/enums/communication-app.enum';
import { OrganizationNodeType } from 'Organization/interfaces/OrganizationNodeType.enum';
import { useGetUserAvailableNodesOfTypeMutation } from 'Dashboard/store/api';
import { OrganizationNodeDataHandler } from 'shared/handlers/organization-node-data.handler';
import { ApplicationTypes } from '../../Application/interfaces/applications.enum';

type BusinessUnitFormOptionItems = Record<
  | 'businessImpact'
  | 'codeRepo'
  | 'cloudSubscription'
  | 'environmentType'
  | 'role'
  | 'user'
  | 'ticketingSystem'
  | 'relatedTeam'
  | 'relatedCommunicationChannels'
  | 'scopes'
  | 'groups',
  Array<AutocompleteOption | any>
> & {
  codeReposLoading: boolean;
  cloudSubscriptionsLoading: boolean;
  getGroups: (scopeId: string) => void;
  getCodeRepos: (scopeId: string, searchKey?: string) => void;
  getCloudSubscriptions: (scopeId: string, searchKey?: string) => void;
};

const businessUnitDataHandler = new BusinessUnitDataHandler();
const organizationNodeDataHandler = new OrganizationNodeDataHandler();

export function useFetchBusinessUnitFormOptionItems(): BusinessUnitFormOptionItems {
  const [fetchCodeRepos, { data: codeRepos, isLoading: codeReposLoading }] =
    useSearchCloudWorkspacesMutation();

  const [
    fetchCloudSubscriptions,
    { data: cloudSubscriptions, isLoading: cloudSubscriptionsLoading },
  ] = useSearchCloudWorkspacesMutation();

  const [fetchTeams, { data: teams, isLoading: teamsLoading }] =
    useFetchTeamsMutation();

  const [fetchUserList, { data: userList, isLoading: userListLoading }] =
    useFetchAvailableUsersToAssignMutation();

  const [fetchScopeList, { data: scopeList, isLoading: scopeListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  const [fetchGroupList, { data: groupList, isLoading: groupListLoading }] =
    useGetUserAvailableNodesOfTypeMutation();

  useEffect(() => {
    fetchMultiSelectOptions();
  }, []);

  const fetchMultiSelectOptions = () => {
    fetchTeams();
    fetchUserList({});
    fetchScopeList({
      nodesType: OrganizationNodeType.SCOPE,
    });
    fetchGroupList({
      nodesType: OrganizationNodeType.LOGICAL,
    });
    fetchJiraConnection({
      applicationId: 'JiraCloudPlatform',
      connectionTemplateId: 'JiraCloudPlatform_ApiToken',
    });
    fetchJiraServerConnection({
      applicationId: ApplicationTypes.JIRA_SERVER_PLATFORM,
      connectionTemplateId: `${ApplicationTypes.JIRA_SERVER_PLATFORM}_ApiToken`,
    });
    fetchAzureDevOpsConnection({
      applicationId: 'AzureDevops',
      connectionTemplateId: 'AzureDevops_ApiToken',
    });
    fetchSlackConnection({
      applicationId: 'Slack',
      connectionTemplateId: 'Slack_BotUserOAuthToken',
    });

    fetchTeamsConnection({
      applicationId: 'MicrosoftTeams',
      connectionTemplateId: 'MicrosoftTeams_ApiToken',
    });
  };

  const [
    fetchJiraConnection,
    { data: jiraConnections, isFetching: jiraConnectionUninitalized },
  ] = useLazyFetchConnectionByFiltersQuery();

  const [
    fetchJiraServerConnection,
    {
      data: jiraServerConnections,
      isFetching: jiraServerConnectionUninitalized,
    },
  ] = useLazyFetchConnectionByFiltersQuery();

  const [
    fetchSlackConnection,
    { data: slackConnection, isFetching: slackConnectionUninitalized },
  ] = useLazyFetchConnectionByFiltersQuery();

  const [
    fetchTeamsConnection,
    { data: teamsConnection, isFetching: teamsConnectionUninitalized },
  ] = useLazyFetchConnectionByFiltersQuery();

  const [
    fetchAzureDevOpsConnection,
    {
      data: azureDevOpsConnections,
      isFetching: azureDevOpsConnectionUninitalized,
    },
  ] = useLazyFetchConnectionByFiltersQuery();

  const codeRepoOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (codeRepos) {
      return businessUnitDataHandler.transformCloudWorkspaceDataToAutocompleteOptions(
        codeRepos?.data
      );
    }

    return [];
  }, [codeRepos]);

  const cloudSubscriptionOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (cloudSubscriptions) {
      return businessUnitDataHandler.transformCloudWorkspaceDataToAutocompleteOptions(
        cloudSubscriptions?.data
      );
    }

    return [];
  }, [cloudSubscriptions]);

  const relatedCommunicationChannelsList = useMemo<
    Array<AutocompleteOption>
  >(() => {
    let communicationChannelList: Array<ConnectionItem> = [];

    if (slackConnection?.length) {
      communicationChannelList = [
        ...communicationChannelList.concat(slackConnection[0]),
      ];
    }

    if (teamsConnection?.length) {
      communicationChannelList = [
        ...communicationChannelList.concat(teamsConnection[0]),
      ];
    }

    let communicationChannels =
      businessUnitDataHandler.transformConnectionListDataToAutocompleteOptions(
        communicationChannelList,
        true
      );

    return communicationChannels.map((connectionItem) => {
      return {
        ...connectionItem,
        name: connectionItem.label,
        label:
          CommunicationApp[
            connectionItem.label as keyof typeof CommunicationApp
          ],
      };
    });
  }, [slackConnection, teamsConnection]);

  const teamOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (teams) {
      return businessUnitDataHandler.transformTeamListDataToAutocompleteOptions(
        teams
      );
    }

    return [];
  }, [teams]);

  const userOptionList = useMemo<Array<AutocompleteOption>>(() => {
    if (userList) {
      return businessUnitDataHandler.transformUserListDataToAutocompleteOptions(
        userList
      );
    }

    return [];
  }, [userList]);

  const connectionOptionList = useMemo<Array<AutocompleteOption>>(() => {
    let connectionList: Array<ConnectionItem> = [];

    if (jiraConnections?.length) {
      connectionList = [...connectionList.concat(jiraConnections)];
    }

    if (jiraServerConnections?.length) {
      connectionList = [...connectionList.concat(jiraServerConnections)];
    }

    if (azureDevOpsConnections?.length) {
      connectionList = [...connectionList.concat(azureDevOpsConnections)];
    }

    return businessUnitDataHandler.transformConnectionListDataToAutocompleteOptions(
      connectionList
    );
  }, [jiraConnections, jiraServerConnections, azureDevOpsConnections]);

  const fetchGroupsByScopeId = (scopeId: string) => {
    fetchGroupList({
      nodesType: OrganizationNodeType.LOGICAL,
      startNodeId: scopeId,
    });
  };

  const getCodeRepos = (scopeId: string, searchKey?: string) => {
    fetchCodeRepos({
      skip: 0,
      take: 300,
      filter: {
        type: [
          {
            value: CloudWorkspaceApiType.CODE_MANAGEMENT,
          },
        ],
        scopeId: [
          {
            value: scopeId,
          },
        ],
        alias: { value: searchKey || '' },
      },
    });
  };

  const getCloudSubscriptions = (scopeId: string, searchKey?: string) => {
    fetchCloudSubscriptions({
      skip: 0,
      take: 300,
      filter: {
        type: [
          {
            value: CloudWorkspaceApiType.CLOUD_ENVIRONMENT,
          },
          {
            value: CloudWorkspaceApiType.ON_PREM,
          },
          {
            value: CloudWorkspaceApiType.DOMAIN,
          },
        ],
        scopeId: [
          {
            value: scopeId,
          },
        ],
        alias: { value: searchKey || '' },
      },
    });
  };

  return {
    businessImpact: businessUnitImpactOptionList,
    role: businessUnitRoleOptionList,
    environmentType: businessUnitEnvironmentTypesOptionList,
    cloudSubscription: cloudSubscriptionOptionList,
    codeRepo: codeRepoOptionList,
    relatedTeam: teamOptionList,
    ticketingSystem: connectionOptionList,
    user: userOptionList,
    relatedCommunicationChannels: relatedCommunicationChannelsList,
    scopes: scopeList
      ? organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          scopeList
        )
      : [],
    groups: groupList
      ? organizationNodeDataHandler.transformOrganizationNodesToAutocompleteOptions(
          groupList
        )
      : [],
    getGroups: fetchGroupsByScopeId,
    getCloudSubscriptions,
    getCodeRepos,
    codeReposLoading,
    cloudSubscriptionsLoading,
  };
}
